import { TPromotionListInfo } from "types/promotion";
import { useCustomParams, FilterData } from "comp-lib";

export const promotionStatusMap: Record<number, string[]> = {
  1: ["예정", "notStarted"],
  2: ["진행중", "inProgress"],
  3: ["종료", "ended"], // status: 3 => 철회상태지만 상태 필터로 보여줄 땐 종료로 표시
  4: ["종료", "ended"],
};

export default function usePromotionFilter(data: TPromotionListInfo[]) {
  const result = data.map((item) => {
    return { ...item };
  });

  const [{ from, to, keyword, status, pageNum }] = useCustomParams({
    from: "",
    to: "",
    keyword: "",
    status: "all",
    pageNum: "1",
  });

  const dataForTableNav = new FilterData(result)
    .filterByDate("target.promotion_not_before", from, to)
    .filterByKeyword("title", keyword)
    .getResult();

  const paramStatus = status === "all" ? "" : status;
  const filteredData = new FilterData(result)
    .filterByDate("target.promotion_not_before", from, to)
    .filterByKeyword("title", keyword)
    .filterByStatus("target.promotion_status", paramStatus, promotionStatusMap)
    .getResult()
    .reverse();

  const len = filteredData.length;

  const dataWithNo = filteredData.map((item, idx) => ({
    ...item,
    no: len - idx,
  }));

  const dataForTableRows = new FilterData(dataWithNo).filterByPage(Number(pageNum)).getResult();
  const totalPages = Math.floor(filteredData.length / 10) + (filteredData.length % 10 && 1) || 1;

  return { dataForTableNav, dataForTableRows, totalPages };
}
