import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, ErrorModal, Modal, Panel, Title } from "comp-lib";
import cx from "classnames";

import { Portal } from "common/other";
import { TBanner } from "types/banner";
import Radio from "../@tmp/Radio";
import styles from "styles/pages/banner/bannerCreator.module.scss";
import FormBanner from "./FormBanner";
import FormProposal from "./FormProposal";
import FormPurpose from "./FormPurpose";
import { uploadImg } from "api/banner";
import { useMutation } from "react-query";

type TProps = {
  data?: TBanner;
  onSubmit: (arg: TBanner) => void;
  onClose: () => void;
};

export default function BannerCreator({ data, onSubmit, onClose }: TProps) {
  const formMethods = useForm<TBanner>({
    defaultValues: data || {
      type: "1",
      device: "1",
      isToken: "true",
      isVisibleTitle: "false",
    },
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    watch,
  } = formMethods;

  useEffect(() => {
    document.body.style.cssText = "overflow: hidden";
    return () => {
      document.body.style.cssText = "overflow: auto";
    };
  }, []);

  const [modal, setModal] = useState<"confirm" | "noti" | "error" | "">("");

  const { mutateAsync: uploadImgMutation } = useMutation(uploadImg);

  const handleSubmitModalOpen = () => {
    setModal("confirm");
  };

  const handleSubmitModalClose = () => {
    setModal("");
  };

  const handleInnerSubmit = async () => {
    const banner = getValues();

    // upload new image file
    if (typeof banner.imgFileStr !== "string") {
      await uploadImgMutation(banner.imgFileStr, {
        onSuccess: (el) => {
          if (data) {
            onSubmit({ ...banner, imgFileStr: el.fullUrlPath }); // modify
          } else {
            onSubmit({ ...banner, imgFileStr: el.fullUrlPath, id: Math.random().toString(36).substring(2, 9) }); // create
          }
          setModal("noti");
        },
        onError: () => {
          setModal("error");
        },
      });
    } else {
      if (data) {
        onSubmit({ ...banner }); // modify
      } else {
        onSubmit({ ...banner, id: Math.random().toString(36).substring(2, 9) }); // create
      }
      setModal("noti");
    }
  };

  const handleNotiModalClose = () => {
    setModal("");
    onClose();
  };

  return (
    <Portal>
      <div className={styles.overlay}>
        <FormProvider {...formMethods}>
          <form className={styles.wrapper} onSubmit={handleSubmit(handleSubmitModalOpen)}>
            <Title text={data ? "배너 수정" : "배너 생성"} />

            <Panel className={styles.pannel}>
              <div className={styles.input_box}>
                <div className={styles.title}>
                  구분
                  <span className={styles.required}>*</span>
                </div>

                <div className={styles.content}>
                  <label>
                    <Radio value="1" className={styles.type_radio} {...register("type")} />
                    <span>일반</span>
                  </label>

                  <label>
                    <Radio value="2" className={styles.type_radio} {...register("type")} />
                    <span>거래 Proposal</span>
                  </label>

                  <label>
                    <Radio value="3" className={styles.type_radio} {...register("type")} />
                    <span>기능 Proposal</span>
                  </label>
                </div>
              </div>

              <div className={styles.input_box}>
                <div className={styles.title}>
                  디바이스
                  <span className={styles.required}>*</span>
                </div>

                <div className={styles.content}>
                  <label>
                    <Radio value="1" className={styles.type_radio} {...register("device")} />
                    <span>ALL</span>
                  </label>

                  <label>
                    <Radio value="3" className={styles.type_radio} {...register("device")} />
                    <span>iOS</span>
                  </label>

                  <label>
                    <Radio value="2" className={styles.type_radio} {...register("device")} />
                    <span>Android</span>
                  </label>
                </div>
              </div>

              <label className={styles.input_box} aria-invalid={errors.title ? "true" : "false"}>
                <div className={styles.title}>
                  제목
                  <span className={styles.required}>*</span>
                </div>

                <div className={styles.content}>
                  <input
                    type="text"
                    placeholder="제목을 입력해주세요 (최대 40자까지 작성 가능합니다)"
                    className={styles.title_text}
                    maxLength={40}
                    {...register("title", {
                      required: true,
                    })}
                  />
                </div>
              </label>

              <div className={cx(styles.input_box, styles.tooltip_box)}>
                <div className={styles.title}>
                  앱 상단 <br /> 네비게이션
                  <span className={styles.required}>*</span>
                </div>

                <div className={cx(styles.content, styles.tooltip_content, styles.title_visible)}>
                  <div className={styles.title_visible_radio}>
                    <label>
                      <Radio value="true" className={styles.type_radio} {...register("isVisibleTitle")} />
                      <span>노출함</span>
                    </label>

                    <label>
                      <Radio value="false" className={styles.type_radio} {...register("isVisibleTitle")} />
                      <span>노출 안함</span>
                    </label>
                  </div>

                  <ul className={styles.tooltip}>
                    <li>
                      {`연결 URL 페이지 내 자체 상단 네비게이션이 포함된 경우 중복해서 나타나지 않도록 '노출 안 함'을 선택해 주세요.`}
                    </li>
                  </ul>
                </div>
              </div>

              {watch("type") === "1" && <FormBanner />}
              {watch("type") === "2" && <FormProposal />}
              {watch("type") === "3" && <FormPurpose />}
            </Panel>

            <div className={styles.footer}>
              <Button type="button" size="long" mode="lineGray" onClick={onClose}>
                취소
              </Button>
              <Button type="submit" size="long">
                {data ? "수정" : "생성"}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>

      {modal === "confirm" && (
        <Modal
          title={data ? "배너를 수정하시겠습니까?" : "배너를 추가하시겠습니까?"}
          description={data ? "입력한 정보로 배너가 수정됩니다." : "입력한 정보로 배너가 그룹에 추가됩니다."}
          btnType="submit"
          handleSubmit={handleInnerSubmit}
          handleClose={handleSubmitModalClose}
        />
      )}

      {modal === "noti" && (
        <Modal
          description={data ? "배너가 수정되었습니다." : "배너가 추가되었습니다"}
          btnType="confirm"
          handleClose={handleNotiModalClose}
        />
      )}

      {modal === "error" && (
        <ErrorModal
          message={`이미지 등록 중 오류가 발생했습니다.\n이미지 파일을 다시 등록해주세요.`}
          onConfirmHandler={handleSubmitModalClose}
        />
      )}
    </Portal>
  );
}
