import { AxiosError } from "axios";
import { Axios } from "./base";
import ErrorCode from "constants/errorcode";
import { getErrorMeg } from "./errorHandling";

// [POST] 비밀번호 확인
export const postConfirmPassword = async (json: { password: string }): Promise<{ message: string; status: number }> => {
  try {
    const { data } = await Axios.post(`/api/v2/password/confirm`, { json });
    return data;
  } catch (error) {
    if ((error as AxiosError).message === ErrorCode.ERR_INPUT_PARAMETER) {
      throw Error(`비밀번호가 일치하지 않습니다. 다시 확인해 주세요.`);
    } else {
      throw error;
    }
  }
};

// [POST] 비밀번호 변경
export const changePassword = async (json: { password: string; passwordCheck: string }) => {
  const { password, passwordCheck } = json;

  try {
    const { data } = await Axios.post(`/api/v2/mypage/password/change`, { password, passwordCheck });
    return data;
  } catch (error) {
    if ((error as AxiosError).message === ErrorCode.ERR_PASSWORD_NOT_CHANGED) {
      throw Error(getErrorMeg(ErrorCode.ERR_PASSWORD_NOT_CHANGED));
    } else {
      throw Error(`비밀번호를 변경하지 못했습니다.\n다시 시도하시고 동일한 문제가 반복될 경우 CS센터에 문의해 주세요.`);
    }
  }
};
