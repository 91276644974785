import { useFormContext } from "react-hook-form";

import styles from "styles/pages/appPush/write/preview/previewAOS.module.scss";

import { TPushFormValue } from "types/appPush";

import { MyDLogoIcon } from "assets/svgs";

export default function PreviewAOS() {
  const { watch } = useFormContext<TPushFormValue>();

  const { title, body, imagePath, messagePurpose } = watch();

  const isPurposeMarketing = messagePurpose === "MARKETING_ADVERTISE";

  return (
    <div className={styles.wrapper}>
      <h3>안드로이드 미리보기</h3>

      {imagePath ? (
        <section className={styles.section}>
          <div className={styles.box}>
            <MyDLogoIcon className={styles.logo_big} />

            <div className={styles.content} style={{ paddingLeft: "40px" }}>
              <div className={styles.header}>
                <h2>마이디</h2>
                <span>오후 6:00</span>
              </div>

              <div className={styles.body}>
                <h2 className={styles.title}>{title}</h2>
                {isPurposeMarketing ? (
                  <p>
                    (광고) {body}
                    <br />* 수신거부 : 홈 &gt; MY &gt; 푸시 알람 설정
                  </p>
                ) : (
                  <p>{body}</p>
                )}
                <img className={styles.img_big} src={imagePath} alt="preview" />
              </div>
            </div>
          </div>
          <div className={styles.imgWrapper}>
            <img className={styles.img} src={imagePath} alt="preview" />
          </div>
        </section>
      ) : (
        <section className={styles.section}>
          <div className={styles.box}>
            <MyDLogoIcon className={styles.logo_small} />

            <div className={styles.content}>
              <div className={styles.header}>
                <h2>마이디</h2>
                <span>오후 6:00</span>
              </div>

              <div className={styles.body}>
                <h2 className={styles.title}>{title}</h2>
                {isPurposeMarketing ? (
                  <p>
                    (광고) {body}
                    <br />* 수신거부 : 홈 &gt; MY &gt; 푸시 알람 설정
                  </p>
                ) : (
                  <p>{body}</p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
