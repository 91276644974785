import { Button, ErrorModal, LoadingModal, Modal } from "comp-lib";
import { useMutation } from "react-query";
import { acceptRecharge, rejectRecharge } from "api/recharge";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type TProps = {
  id: number;
};

export default function RechargeActionBtn({ id }: TProps) {
  const navigate = useNavigate();
  const acceptMuta = useMutation(acceptRecharge);
  const rejectMuta = useMutation(rejectRecharge);
  const [modalType, setModalType] = useState<"accept" | "reject" | "">("");

  const handleBtnClick = (type: "accept" | "reject") => () => {
    setModalType(type);
  };

  const handleAccept = async () => {
    setModalType("");
    await acceptMuta.mutateAsync(id);
    navigate("/point/approve");
  };

  const handleReject = async () => {
    setModalType("");
    await rejectMuta.mutateAsync(id);
    navigate("/point/approve");
  };

  const handleModalClose = () => {
    setModalType("");
  };

  const handleError = () => {
    acceptMuta.reset();
    rejectMuta.reset();
  };

  return (
    <>
      <Button type="button" size="long" mode="lineGray" onClick={handleBtnClick("reject")}>
        지급 거절
      </Button>
      <Button type="button" size="long" onClick={handleBtnClick("accept")}>
        지급 승인
      </Button>

      {modalType === "accept" && (
        <Modal
          title="포인트를 지급하시겠습니까?"
          description="변경이 어려우니 신중히 선택해주세요."
          btnType="submit"
          handleClose={handleModalClose}
          handleSubmit={handleAccept}
        />
      )}

      {modalType === "reject" && (
        <Modal
          title="포인트 지급을 거절하시겠습니까?"
          description="변경이 어려우니 신중히 선택해주세요."
          btnType="submit"
          handleClose={handleModalClose}
          handleSubmit={handleReject}
        />
      )}

      {(acceptMuta.isError || rejectMuta.isError) && (
        <ErrorModal message="서버에 문제가 발생했습니다." onConfirmHandler={handleError} />
      )}

      {(acceptMuta.isLoading || rejectMuta.isLoading) && <LoadingModal />}
    </>
  );
}
