import { Axios } from "./base";
import { TRechargeItem, TRechargeResponse } from "types/recharge";

type TRechargeParam = {
  pageNum: number;
  status: string;
};

export const getRechargeList = async ({ pageNum, status }: TRechargeParam): Promise<TRechargeResponse> => {
  const response = await Axios.get(`/api/admin/reward?pageNum=${pageNum}&status=${status}`);

  return response?.data.data.result;
};

export const getRechargeItem = async (id: number): Promise<TRechargeItem> => {
  const response = await Axios.get(`/api/admin/reward/detail?id=${id}`);

  return response.data.data.result;
};

export const acceptRecharge = (id: number) => {
  return Axios.post("/api/admin/reward/approval", { id });
};

export const rejectRecharge = (id: number) => {
  return Axios.post("/api/admin/reward/disapproval", { id });
};
