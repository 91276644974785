import Advertisement from "pages/advertisement";
import AdminAdDetail from "pages/advertisement/detail/AdminAdDetail";

import AppPush from "pages/appPush";
import AppPushDetail from "pages/appPush/detail/AppPushDetail";

// import { CodeStatus, ProgressList, CreatePage, DetailPage } from "pages/friendReferral";

import AppPushWrite from "pages/appPush/write/AppPushWrite";
import GroupBannerDetail from "pages/banner/GroupBannerDetail";
import GroupBannerList from "pages/banner/GroupBannerList";
import BannerGroupCreator from "pages/banner/create/BannerGroupCreator";
import { CompanyCreate, CompanyInfo, CompanyList, CompanyModify, StaffCreate, StaffInfo } from "pages/company";
import { Challenge, ChallengeReport } from "pages/contentsManage/challenge";
import ChallengeDetail from "pages/contentsManage/challenge/detail/ChallengeDetail";
import QuickMenuList from "pages/quickMenu/QuickMenuList";

import ChallengeWrite from "pages/contentsManage/challenge/wirte/ChallengeWrite";
import { ContPrForm, MultiQuestionWrapper, Question, Vote, VoteDetail } from "pages/contentsManage/vote";
import SpamDetail from "pages/contentsManage/vote/detail/SpamDetail";
import GoodsDetail from "pages/goods/GoodsDetail";
import GoodsList from "pages/goods/GoodsList";
import GoodsRegister from "pages/goods/GoodsRegister";
import Home from "pages/home/Home";
import { Login, Password } from "pages/login";
import { ManagerDetail, ManagerList, ManagerModify, ManagerRegister } from "pages/manager";
import MydInquiry from "pages/mydInquiry";
import MydInquiryDetail from "pages/mydInquiry/detail/MydInquiryDetail";
import { AdminMyInfo, AdminMypage } from "pages/mypage";
import { NoticeDetail, NoticeList, NoticeWrite } from "pages/notice";
import LegacyOutlet from "pages/notice/LegacyOutlet";
import PublicRoute from "PublicRoute";
import PrivateRoute from "PrivateRoute";
import MenuAccess from "pages/MenuAccess";
import { PromotionCreate, PromotionDetail, PromotionList } from "pages/promotion";

import { MintBurnManagement, RechargeDetail, RechargeList } from "pages/reward";
import TransactionDetail from "pages/transaction/TransactionDetail";
import TransactionList from "pages/transaction/TransactionList";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="login" element={<Login />} />

          <Route path="password">
            <Route path="find" element={<Password />} />
            <Route path="change" element={<Password />} />
          </Route>
        </Route>

        <Route element={<PrivateRoute />}>
          <Route index element={<Home />} />

          <Route path="company" element={<MenuAccess authority="기업관리" />}>
            <Route index element={<CompanyList />} />
            <Route path="create" element={<CompanyCreate />} />
            <Route path="modify/:id" element={<CompanyModify />} />
            <Route path="info/:id" element={<CompanyInfo />} />
            <Route path="manager">
              <Route path="create" element={<StaffCreate />} />
              <Route path="info/:id" element={<StaffInfo />} />
            </Route>
          </Route>

          <Route path="point">
            <Route path="approve" element={<MenuAccess authority="충전승인" />}>
              <Route index element={<RechargeList />} />
              <Route path="detail/:id" element={<RechargeDetail />} />
            </Route>
            <Route path="mint-burn" element={<MintBurnManagement />} />
          </Route>

          <Route path="service">
            <Route path="transaction" element={<MenuAccess authority="데이터거래" />}>
              <Route index element={<TransactionList />} />
              <Route path="detail/:id" element={<TransactionDetail />} />
            </Route>

            <Route path="quickMenu" element={<QuickMenuList />} />
          </Route>

          <Route path="contents" element={<MenuAccess authority="콘텐츠관리" />}>
            <Route path="vote">
              <Route index element={<Vote />} />
              <Route path="writeContPr" element={<ContPrForm />} />
              <Route path="writeSingleQuestion" element={<Question />} />
              <Route path="writeMultiQuestion" element={<MultiQuestionWrapper />} />
              <Route path="detail/:id" element={<VoteDetail />} />
              <Route path="spam/:id" element={<SpamDetail />} />
            </Route>

            <Route path="promotion" element={<MenuAccess authority="프로모션" />}>
              <Route index element={<PromotionList />} />
              <Route path="create" element={<PromotionCreate />} />
              <Route path="detail/:id" element={<PromotionDetail />} />
            </Route>

            <Route path="banner" element={<div>홈 배너</div>} />

            <Route path="challenge">
              <Route index element={<Challenge />} />
              <Route path="report" element={<ChallengeReport />} />
              <Route path="write" element={<ChallengeWrite />} />
              <Route path="modify/:id" element={<ChallengeWrite />} />
              <Route path="detail/:id" element={<ChallengeDetail />} />
            </Route>
          </Route>

          <Route path="message" element={<MenuAccess authority="커뮤니케이션" />}>
            <Route path="appPush">
              <Route index element={<AppPush />} />
              <Route path="write" element={<AppPushWrite />} />
              <Route path="detail/:id" element={<AppPushDetail />} />
            </Route>

            <Route path="homePopup" element={<LegacyOutlet />}>
              <Route index element={<NoticeList />} />
              <Route path="write" element={<NoticeWrite />} />
              <Route path="detail/:id" element={<NoticeDetail />} />
            </Route>
          </Route>

          <Route path="event">
            <Route path="banner">
              <Route index element={<GroupBannerList />} />
              <Route path="create" element={<BannerGroupCreator />} />
              <Route path="detail/:no" element={<GroupBannerDetail />} />
            </Route>

            <Route path="advertise" element={<MenuAccess authority="광고" />}>
              <Route index element={<Advertisement />} />
              <Route path="detail/:proposalId" element={<AdminAdDetail />} />
            </Route>
          </Route>

          <Route path="cs">
            <Route path="mydInquiry">
              <Route index element={<MydInquiry />} />
              <Route path="detail/:id" element={<MydInquiryDetail />} />
            </Route>
          </Route>

          <Route path="users" element={<MenuAccess authority="ADMIN" />}>
            <Route index element={<ManagerList />} />
            <Route path=":id" element={<ManagerDetail />} />
            <Route path="modify/:id" element={<ManagerModify />} />
            <Route path="register" element={<ManagerRegister />} />
          </Route>

          <Route path="goods" element={<MenuAccess authority="상품관리" />}>
            <Route index element={<GoodsList />} />
            <Route path="register" element={<GoodsRegister />} />
            <Route path="detail/:code" element={<GoodsDetail />} />
          </Route>

          <Route path="mypage">
            <Route index element={<AdminMypage />} />
            <Route path="info" element={<AdminMyInfo />} />
          </Route>

          {/* <Route path="friendReferral" element={<PrivateRoute authority="친구추천" />}>
              <Route path="progress">
                <Route index element={<ProgressList />} />
                <Route path="create" element={<CreatePage />} />
                <Route path="detail/:id" element={<DetailPage />} />
              </Route>
              <Route path="codeStatus" element={<CodeStatus />} />
            </Route> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
