/* eslint-disable no-alert */
import cx from "classnames";
import { Modal } from "comp-lib";
import { ChangeEventHandler, useRef, useState } from "react";
import { Controller, FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import styles from "styles/common/input/FileInput.module.scss";

type TProps = React.ComponentProps<"input"> & {
  id: Path<FieldValues>;
  children?: string; // 버튼 내 텍스트
  isRequired?: boolean; // 필수값 여부
  validateMsg?: string; // validation 메시지
  accept?: string; // 가능 확장자
  sizeLimit?: number; // 이미지 사이즈 제한
  disabled?: boolean; // 업로드 버튼 disabled
  onClick?: () => void; // 파일 이름 클릭 이벤트
  limitRatio?: [number, number]; // 이미지 크기 비율 제한
  limitPxSize?: [number, number]; // 이미지 가로 사이즈 최소, 최대 px 제한
};

/**
 * @params id - file id
 * @params children - text in button (default: '이미지 선택')
 * @params isRequired - must require (default: true)
 * @params accept - file extension (default: '.jpg, .jpeg, .png')
 * @params sizeLimit - size limit(mb)
 * @params disabled - isDisabled (default: true)
 * @params onClick - click file event
 * @params limitRatio - ratio size limit (e.g. [1, 1] -> 1:1 비율)
 * @params limitPxSize - minimum, maximum size limit (e.g. [60, 240] -> 이미지 가로 사이즈가 60px ~ 최대 240px 제한)
 *                       limitMinMax 세로 사이즈도 제한하고자 할 때 limitRatio를 통해 비율 제한을 추가하면 세로 사이즈는 계산하여 자동 계산
 *                       limitRatio 속성 없이 limitPxSize만 단독으로 사용시 1:1 비율로 계산하여 이미지 w,h 사이즈 제한
 *  */
export default function FileInput({
  id,
  children = "이미지 선택",
  isRequired = true,
  validateMsg,
  accept = ".jpg, .jpeg, .png",
  sizeLimit,
  disabled,
  onClick,
  limitRatio,
  limitPxSize,
}: TProps) {
  const { control, setValue } = useFormContext();

  const allowedFileTypes = accept.split(", ").map((ext) => {
    const extension = ext.replace(/^\./, "");
    const type = extension === "pdf" ? "application" : "image";
    return `${type}/${extension}`;
  });

  const fileRef = useRef<HTMLInputElement>(null);
  const imgData = useWatch({ control, name: id });

  const [errorMsg, setErrorMsg] = useState("");

  const isCdnUrl = typeof imgData === "string";

  const checkFileType = (allowedTypes: string[], type: string) => {
    const svgIdx = allowedTypes.indexOf("image/svg");
    if (svgIdx !== -1) allowedTypes[svgIdx] = "image/svg+xml";

    if (!allowedTypes.includes(type)) {
      setErrorMsg(`${accept}\n파일 형식만 업로드 가능합니다`);
      return false;
    }
    return true;
  };

  // 이미지 사이즈 validation
  const validateSize = (image: HTMLImageElement) => {
    const { width, height } = image;
    const [w, h] = limitRatio || [0, 0];
    const [px, multiple] = limitPxSize || [0, 0];

    const isValidRatio = width * h === height * w;
    const isValidPxSize = (() => {
      return Array.from({ length: multiple }, (_, i) => i + 1).some((i) => width === px * i && height === px * i);
    })();

    if (limitRatio && !isValidRatio) {
      setErrorMsg(`${w}:${h} 비율 사이즈의 이미지만 등록 가능합니다.`);
      return false;
    }

    if (limitPxSize && !isValidPxSize) {
      setErrorMsg(`${px}*${px}px의 ${multiple}배수 사이즈까지 등록 가능합니다.`);
      return false;
    }

    return true;
  };

  const handleChangeImg: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { files } = e.currentTarget;
    if (!files) return;
    const file = files[0];

    if (sizeLimit) {
      const isUploadableSize = file.size <= megbytesToBytes(sizeLimit);
      const sizeLimitErrorMsg = `${sizeLimit}MB 이상의 파일은 올릴 수 없습니다.`;

      if (!isUploadableSize) {
        setErrorMsg(sizeLimitErrorMsg);
        throw Error(sizeLimitErrorMsg);
      }
    }

    if (!checkFileType(allowedFileTypes, file?.type)) return;

    if (limitRatio || limitPxSize) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = (event) => {
        const image = new Image();
        image.src = event.target?.result as string;

        image.onload = () => {
          if (validateSize(image)) {
            // 이미지 크기 유효성 검사 통과한 경우 파일 업로드
            setValue(e.target.id, file, { shouldValidate: true, shouldDirty: true });
            return;
          } else {
            // 이미지 크기 유효성 검사 통과하지 못한 경우
            e.target.value = ""; // 동일한 실패 케이스 이미지 파일을 계속 업로드 시도할 때마다 에러 모달을 보여주기 위함
          }
        };
      };
      return;
    }

    setValue(e.target.id, file, { shouldValidate: true, shouldDirty: true });
  };

  // 파일 업로드 삭제
  const handleClickCancel = () => {
    if (!fileRef?.current) return;

    setValue(id, null, { shouldValidate: true, shouldDirty: true });
    fileRef.current!.value = "";
  };

  return (
    <div className={styles.upload_box}>
      <label htmlFor={id} className={cx({ [styles.disabled]: disabled })}>
        {children}
      </label>

      <Controller
        name={id}
        control={control}
        rules={{ required: validateMsg || isRequired }}
        render={() => (
          <input
            type="file"
            id={id}
            ref={fileRef}
            accept={accept || "image/*"}
            disabled={disabled}
            onChange={handleChangeImg}
          />
        )}
      />

      {imgData && (
        <div className={styles.img_text_box}>
          <span className={cx({ [styles.name_btn]: onClick })} onClick={onClick}>
            {isCdnUrl ? imgData.split("/").pop() : imgData?.name}
          </span>
          <button type="button" className={styles.cancel_btn} onClick={handleClickCancel}>
            &times;
          </button>
        </div>
      )}

      {errorMsg && <Modal description={errorMsg} btnType="confirm" handleClose={() => setErrorMsg("")} />}
    </div>
  );
}

function megbytesToBytes(megabytes: number) {
  return megabytes * 1024 * 1024;
}
