import { ChangeEvent, useRef, useState } from "react";

import Picker from "@emoji-mart/react";

import { EmojiIcon } from "assets/svgs/icon";
import useOnClickOutside from "hooks/useOnClickOutside";

type TProps = {
  type: string;
  text: string;
  maxLength: number;
  handleSetValue: (el: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

function EmojiPicker({ type, text, maxLength, handleSetValue }: TProps) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const ref = useRef(null);

  const onToggleEmoji = () => {
    setIsPickerOpen((prev) => !prev);
  };

  const onEmojiSelect = (emoji: any) => {
    if (text.length < maxLength) {
      handleSetValue(type, emoji);
    }

    onToggleEmoji();
  };

  useOnClickOutside(ref, onToggleEmoji);

  return (
    <>
      <EmojiIcon name={type} onClick={onToggleEmoji} />

      {isPickerOpen && (
        <div ref={ref} style={{ position: "absolute", top: "40px", right: "0px", zIndex: "10" }}>
          <Picker onEmojiSelect={onEmojiSelect} theme="light" />
        </div>
      )}
    </>
  );
}
export default EmojiPicker;
