import styles from "styles/pages/notice/list.module.scss";
import tableStyles from "styles/common/table/Table.module.scss";
import Search from "common/table/TableTop/Search";
import { Title } from "common/other";
import { Pagination } from "common/navigation";
import { WriteBtn } from "common/button";
import { deleteNotice, getNoticeList } from "api/admin/notice";
import { useMutation } from "react-query";
import { useEffect, useState } from "react";
import { NoticeInfo } from "types/notice";
import dayjs from "dayjs";
import { ListNav, moveDstArr, noticeTypeList, platform, statusLabel } from "constants/notice";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { IPagination } from "types/common";
import useModal from "hooks/useModal";
import { MiddlewareError } from "utils/errorPackage";
import { ErrorModal, LoadingModal, Modal } from "comp-lib";

const DELETE_ERROR_MSG = "서버에 문제가 생겨 삭제 되지 않습니다.";
const REPLY_ERROR_MSG = `홈 팝업 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`;

export default function List() {
  const noticeListMutation = useMutation(getNoticeList);
  const deleteMutation = useMutation(deleteNotice);
  const [list, setList] = useState<IPagination>();
  const [searchParams] = useSearchParams();
  const pageNum = Number(searchParams.get("pageNum")) || 1;
  const searchKeyword = searchParams.get("keyword") || "";
  const [isShowing, handleToggleModal] = useModal();
  const [targetId, setTargetId] = useState<number>(0);
  const navigator = useNavigate();

  useEffect(() => {
    const data: {
      number: number;
      searchKeyword?: string;
    } = { number: pageNum, searchKeyword };

    if (data.searchKeyword === "") delete data.searchKeyword;

    noticeListMutation.mutate({ ...data }, { onSuccess: (res) => setList(res) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, searchKeyword]);

  const getMoveTo = (moveTo: string | undefined) => {
    if (!moveTo) return "";

    const { moveType, moveDst } = JSON.parse(moveTo);

    if (moveType === "TAB") {
      if (moveDstArr.filter((move) => move.value !== moveDst)[0].label) {
        return moveDst;
      }
      return moveDstArr.filter((move) => move.value === moveDst)[0].label;
    }
    return "URL";
  };

  const handleClickDel = (id: number) => {
    handleToggleModal();
    setTargetId(id);
  };

  const handleSubmitModal = () => {
    deleteMutation.mutate(targetId, {
      onSuccess: (res) => {
        handleToggleModal();

        if (res) navigator(0);
      },
    });
  };

  if (noticeListMutation.isLoading) return <LoadingModal />;

  return (
    <article>
      <Title text="목록" location={ListNav} />

      <section className={styles.wrapper}>
        <div className={styles.top}>
          <p>
            전체 <span>{list?.allCount}</span>건
          </p>
          <Search />
          <WriteBtn />
        </div>

        <table className={tableStyles.wrapper}>
          <thead>
            <tr>
              <td>No</td>
              <td>상태</td>
              <td>제목</td>
              <td>노출 기간</td>
              <td>OS 타겟팅</td>
              <td>공지 유형</td>
              <td>이동 위치</td>
              <td className={tableStyles.del} />
            </tr>
          </thead>
          <tbody>
            {list?.page?.content?.map((content: NoticeInfo, idx: number) => (
              <tr key={content.id}>
                <td>{list.allCount - idx - (Number(pageNum) - 1) * 10}</td>
                <td>
                  <span className={`${styles.label} ${styles[statusLabel[content.status].status]}`}>
                    {statusLabel[content.status].label}
                  </span>
                </td>
                <td>
                  <Link to={`detail/${content.id}`}>{content.title}</Link>
                </td>
                <td>{`${dayjs(content.notBefore).format("YYYY.MM.DD HH:mm:ss")} ~ ${dayjs(content.notAfter).format(
                  "YYYY.MM.DD HH:mm:ss",
                )}`}</td>
                <td>{platform.filter((p) => p.value === content.platform)[0].label}</td>
                <td>{noticeTypeList.filter((type) => type.value === content.noticeType)[0].label}</td>
                <td>{getMoveTo(content.moveTo)}</td>
                <td>
                  {content.status > 1 ? (
                    "-"
                  ) : (
                    <button className={styles["revoke-btn"]} type="button" onClick={() => handleClickDel(content.id)}>
                      삭제
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          data={{
            pageSize: 10,
            totalPages: list?.page?.totalPages || 1,
          }}
        />

        {isShowing && (
          <Modal
            title="공지를 삭제하겠습니까?"
            description="삭제하면 복구가 어렵습니다."
            btnType="submit"
            submitBtnName="삭제"
            handleClose={handleToggleModal}
            handleSubmit={handleSubmitModal}
          />
        )}

        {(noticeListMutation.isError || deleteMutation.isError) && (
          <ErrorModal
            error={(noticeListMutation.error as MiddlewareError) || (deleteMutation.error as MiddlewareError)}
            message={noticeListMutation.isError ? REPLY_ERROR_MSG : DELETE_ERROR_MSG}
            onConfirmHandler={() => navigator("/")}
          />
        )}
      </section>
    </article>
  );
}
