import { FilterData, useCustomParams } from "comp-lib";
import { TMintResult } from "types/mintBurn";
import { statusMap } from "./useTableNavData";

export default function useFilter(data: { success: TMintResult; fail: TMintResult }) {
  const successResult = data.success.map((item) => {
    return {
      did: item,
      status: 0,
    };
  });
  const failResult = data.fail.map((item) => {
    return {
      did: item,
      status: 1,
    };
  });
  const combinedResult = [...successResult, ...failResult];

  const [{ status, keyword, pageNum }] = useCustomParams({
    keyword: "",
    status: "all",
    pageNum: "1",
  });

  const dataForTableNav = new FilterData(combinedResult).filterByKeyword("did", keyword).getResult();

  const paramStatus = status === "all" ? "" : status;

  const filteredData = new FilterData(combinedResult)
    .filterByKeyword("did", keyword)
    .filterByStatus("status", paramStatus, statusMap)
    .getResult();

  const len = filteredData.length;

  const dataWithNo = filteredData.map((item, idx) => ({
    ...item,
    no: len - idx,
  }));

  const dataForTableRows = new FilterData(dataWithNo).filterByPage(Number(pageNum)).getResult();

  const totalPages = Math.floor(filteredData.length / 10) + (filteredData.length % 10 && 1) || 1;

  return { dataForTableNav, dataForTableRows, totalPages };
}
