import { TBanner } from "types/banner";
import styles from "styles/pages/banner/tableData.module.scss";
import { PURPOSE_MAP } from "constants/banner";

export default function useBannerTableData(data: TBanner[], onBannerOpen: (banner: TBanner) => void) {
  const columns = [
    { text: "순서", width: "62px" },
    { text: "구분", width: "80px" },
    { text: "배너", width: "286px" },
    { text: "배너명" },
    { text: "디바이스", width: "100px" },
    { text: "노출 기간", width: "200px" },
    { text: "클릭수", width: "100px" },
  ];

  const newData = getDataWithNo(data);

  const rows = newData.map((item) => {
    const typeTag = (() => {
      if (item.type === "2") {
        return "거래";
      }

      if (item.type === "3") {
        const purpose = item.purpose ? Number(item.purpose) : 0;
        return PURPOSE_MAP[purpose] || "기타";
      }
      return "일반";
    })();

    const dateTag = (() => {
      if (!item.startDateTime || !item.endDateTime) {
        return "-";
      }

      return `${item.startDateTime.split("T")[0]} ~ ${item.endDateTime.split("T")[0]}`;
    })();

    return [
      item.no,
      typeTag,
      convertImgTag(item),
      <button type="button" key={`title-${item.id}`} className={styles.btn_title} onClick={() => onBannerOpen(item)}>
        {item.title}
      </button>,
      getDevice(item.device),
      dateTag,
      0,
    ];
  });

  return { columns, rows };
}

function getDataWithNo<T extends any[]>(dataList: T): (T extends (infer U)[] ? U & { no: number } : never)[] {
  return dataList.map((item, idx) => ({
    ...item,
    no: idx + 1,
  }));
}

function convertImgTag(item: TBanner) {
  if (!item.imgFileStr) {
    return <div className={styles.img_box}>-</div>;
  }

  return (
    <div className={styles.img_box}>
      <img key={`img-${item.id}`} src={item.imgFileStr} alt="banner" />
    </div>
  );
}

function getDevice(num: string) {
  if (num === "2") {
    return "Android";
  }

  if (num === "3") {
    return "iOS";
  }

  return "ALL";
}
