import styles from "styles/pages/reward/mintBurn/didPreview.module.scss";

type TProps = {
  didList: string[];
  handleRemoveDidClick: React.MouseEventHandler<HTMLButtonElement>;
};

export default function DidPreview({ didList, handleRemoveDidClick }: TProps) {
  const getDidWithOutPrefix = (did: string) => did.split(":")[2];

  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>
        {didList.map((did, i) => (
          <div key={i} className={styles.grid_item}>
            {getDidWithOutPrefix(did) || "잘못된 did 입니다"}
            <button onClick={handleRemoveDidClick} id={`${i}`} type="button" className={styles.close}>
              삭제
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
