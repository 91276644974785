export default {
  GET_USERINFO: ["GET_USERINFO"],
  PUBLIC_ROUTE_TOKEN: ["PUBLIC_ROUTE_TOKEN"],
  PRIVATE_ROUTE_TOKEN: ["PRIVATE_ROUTE_TOKEN"],

  GET_ROLE: "GET_ROLE",
  GET_OWN: ["GET_OWN"],

  // 기업 상세 정보
  GET_ADMIN_COMPANY_INFO: "GET_ADMIN_COMPANY_INFO",

  // 기업 목록 리스트
  GET_ADMIN_COMPANY_LIST: "GET_ADMIN_COMPANY_LIST",

  // management/company
  GET_DETAIL_COMPANY: "GET_DETAIL_COMPANY",

  // 친구추천 진행 목록
  GET_FRIEND_REFERRAL_PROGRESS_LIST: "GET_FRIEND_REFERRAL_PROGRESS_LIST",

  // 친구추천 상세 정보
  GET_FRIEND_REFERRAL_DETAIL: "GET_FRIEND_REFERRAL_DETAIL",

  // 친구추천 지급 포인트
  GET_REFERRAL_PAYMENT_POINTS: "GET_REFERRAL_PAYMENT_POINTS",

  // 추천코드 발급 통계
  GET_REFERRAL_CODE_STATISTICS: "GET_REFERRAL_CODE_STATISTICS",

  // 추천코드 발급 목록
  GET_REFERRAL_CODE_ISSUANCE_LISET: "GET_REFERRAL_CODE_ISSUANCE_LISET",

  // 사용자 관리
  GET_ADMIN_MANAGER_LIST: "GET_ADMIN_MANAGER_LIST",
  GET_ADMIN_MANAGER_DETAIL: "GET_ADMIN_MANAGER_DETAIL",

  // 대표 담당자 상세정보 조회
  GET_STAFF_DETAIL: "GET_STAFF_DETAIL",

  // 기업 리스트 조회
  GET_COMPANY_LIST: "GET_COMPANY_LIST",

  // 프로모션 리스트 조회
  GET_PROMOTION_LIST: "GET_PROMOTION_LIST",

  // 프로모션 상세 정보 조회
  GET_PROMOTION_DETAIL_INFO: "GET_PROMOTION_DETAIL_INFO",

  // 프로모션 생성시 필요한 기업 목록 조회
  GET_PROMOTION_COMPANY_LIST: "GET_PROMOTION_COMPANY_LIST",

  // 앱 푸시 목록 조회
  GET_PUSH_LIST: "GET_PUSH_LIST",

  // 앱 푸시 상세 정보 조회
  GET_PUSH_DETAIL: "GET_PUSH_DETAIL",

  // 마이디 투표
  GET_POLL_LIST: "GET_POLL_LIST",
  GET_POLL_DETAIL: "GET_POLL_DETAIL",
  GET_SPAM_LIST: "GET_SPAM_LIST",

  // 그룹루틴 챌린지
  GET_CHALLENGE_LIST: "GET_CHALLENGE_LIST",
  GET_CHALLENGE_COUNT: "GET_CHALLENGE_COUNT",
  GET_CHALLENGE_DETAIL: "GET_CHALLENGE_DETAIL",
  GET_CHALLENGE_STATISTICS: "GET_CHALLENGE_STATISTICS",
  GET_CHALLENGE_REPORT_LIST: "GET_CHALLENGE_REPORT_LIST",
  GET_CHALLENGE_IMAGE: "GET_CHALLENGE_IMAGE",
  GET_CHALLENGE_IMAGE_LIST: "GET_CHALLENGE_IMAGE_LIST",

  // 퀵메뉴 리스트 조회
  GET_QUICK_MENU_LIST: "GET_QUICK_MENU_LIST",

  // 문의 관리
  GET_MYD_INQUIRY_LIST: "GET_MYD_INQUIRY_LIST",
  GET_MYD_INQUIRY_LIST_COUNT: "GET_MYD_INQUIRY_LIST_COUNT",
  GET_MYD_INQUIRY_DETAIL: "GET_MYD_INQUIRY_DETAIL",
  GET_MYD_INQUIRY_JIRA_ISSUE: "GET_MYD_INQUIRY_JIRA_ISSUE",
  GET_MYD_INQUIRY_COMMENTS: "GET_MYD_INQUIRY_COMMENTS",
  GET_MYD_INQUIRY_IMGS: "GET_MYD_INQUIRY_IMGS",
};
