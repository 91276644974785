import dayjs from "dayjs";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import styles from "styles/pages/notice/write.module.scss";
import { NoticeData, NoticeDate } from "types/notice";
import DateInput from "./DateInput";

export default function ShowPeriod() {
  const {
    formState: { errors },
    watch,
    trigger,
  } = useFormContext<NoticeData>();

  // vP_1 에서 숫자(1)만 뽑아온다
  const selectedInvisiblePeriod = watch("invisiblePeriod")?.toString().split("vP_").filter(Boolean).map(Number)[0] || 0;

  const validateFn = (value: NoticeDate) => {
    const { date, h, m } = value;

    if (!date || !h || !m) {
      return "노출기간을 확인해주세요";
    }

    // // vP_1 에서 숫자(1)만 뽑아온다
    const selectedInvisiblePeriod =
      watch("invisiblePeriod")?.toString().split("vP_").filter(Boolean).map(Number)[0] || 0;

    const notBeforeTime = dayjs(watch("notBefore.date"))
      .hour(Number(watch("notBefore.h")))
      .minute(Number(watch("notBefore.m")));
    const notAfterTime = dayjs(watch("notAfter.date"))
      .hour(Number(watch("notAfter.h")))
      .minute(Number(watch("notAfter.m")));
    const durationDiff = notAfterTime.diff(notBeforeTime, "day");

    const isNotBeforeFilled = Boolean(watch("notBefore.date") && watch("notBefore.h") && watch("notBefore.m"));
    const isNotAfterFilled = Boolean(watch("notAfter.date") && watch("notAfter.h") && watch("notAfter.m"));

    const shouldValidateVisiblePeriod = isNotBeforeFilled && isNotAfterFilled;
    const isValidVisiblePeriod = durationDiff >= selectedInvisiblePeriod;

    return !shouldValidateVisiblePeriod || isValidVisiblePeriod;
  };

  useEffect(() => {
    if (watch("notBefore") && watch("notAfter")) {
      trigger("notBefore");
      trigger("notAfter");
    }
  }, [watch("notBefore"), watch("notAfter"), watch("invisiblePeriod")]);

  return (
    <>
      <div>
        <DateInput
          watchName="notBefore"
          isDateTime
          isError={Boolean(errors.notBefore)}
          requiredMsg="노출시작 시간을 확인해주세요"
          validateFn={validateFn}
        />
        <span>~</span>
        <DateInput
          watchName="notAfter"
          isDateTime
          isError={Boolean(errors.notAfter)}
          requiredMsg="노출종료 시간을 확인해주세요"
          validateFn={validateFn}
        />
      </div>
      {(errors.notBefore || errors.notAfter) && (
        <p className={styles.warning}>
          {errors.notBefore?.message ||
            errors.notAfter?.message ||
            `노출 기간은 최소 ${selectedInvisiblePeriod}일로 설정해야 합니다`}
        </p>
      )}
    </>
  );
}
