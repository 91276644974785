import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import isBetween from "dayjs/plugin/isBetween";
import StatusFilter from "./StatusFilter";
import { SpamInfo } from "protobuf/OpenApiServerV3";
import { uint8ArrayToString } from "utils/uint8Array";

dayjs.extend(isBetween);

/**
 * @description searchParams를 기반으로 data를 필터링해주는 훅입니다. (searchParams 사용 예시: /market?status=all&pageNum=1&from=2023-06-08&to=2023-06-09&keyword=타이틀)
 * 페이지 별 data와 status가 다르니 해당 파일을 복사하여 개별적으로 관리하는 것을 추천합니다.
 *
 * useFilterTableData는 api 요청을 통해 받은 data를 조건에 맞춰 필터링 해주는 훅 입니다.
 * useFilterTableNav는 필터링된 data를 common component <TableNav />에 필요한 data로 만들어 줍니다.
 */

export const useFilterReportTableNav = ({ nav, reportSize }: TNavProps): TNavData => {
  const navData: TNavData = {};
  if (!reportSize) return {};
  nav.forEach(({ label, status }) => {
    navData[label] = {
      num: reportSize[status] || 0,
      status,
    };
  });

  return navData;
};

export const useFilterReportTableData = (
  data?: SpamInfo[], // any를 data 타입에 맞춰 수정하는 것을 권장합니다.
  navMap?: {
    label: string;
    status: string;
  }[]
): {
  filteredData?: SpamInfo[];
  size?: { [key: string | number]: number };
  totalPages: number;
} => {
  const [searchParams] = useSearchParams();
  if (!data) return { totalPages: 1 };
  let result = data;

  /* 필요한 searchParams 선언 */
  // 기한 선언
  const [from, to] = [searchParams.get("from"), searchParams.get("to")];

  // 상태 선언
  const status = searchParams.get("status") || "all";
  const statusMap: { [key: string]: number } = navMap
    ? Object.fromEntries(navMap.map(({ status: navStatus }) => [navStatus, 0]))
    : {};

  // 검색어 선언
  const keyword = searchParams.get("searchKeyword");

  // 날짜 필터링
  if (from) {
    result = result.filter(({ spamReportTime }) => {
      if (spamReportTime && dayjs(spamReportTime).isBetween(from || "0000-00-00", to || "9999-12-12", "day", "[]"))
        return true;
      return false;
    });
  }

  // 키워드 필터링
  if (keyword) {
    result = result.filter(({ spamReportContent }) => {
      const { commentInput }: { commentInput: string } = JSON.parse(uint8ArrayToString(spamReportContent));
      return commentInput?.toLowerCase().includes(keyword.toLowerCase());
    });
  }

  // 상태 필터링 전 size 계산
  if (status && navMap) {
    result.forEach((adData) => {
      statusMap[StatusFilter(adData)]++;
      statusMap[navMap[0].status]++; // "전체" size 계산
    });
  }

  // 상태 필터링
  if (status && navMap && status !== navMap[0].status) {
    result = result.filter((adData) => {
      // "전체"
      if (status === navMap[0].status) {
        return true;
      }
      return status === StatusFilter(adData);
    });
  }

  // 생성일 기준 정렬
  result.sort((a, b) => dayjs(b.spamReportTime).unix() - dayjs(a.spamReportTime).unix());

  const totalPages = Math.floor(result.length / 10) + (result.length % 10 && 1) || 1;

  return { filteredData: result, size: statusMap, totalPages };
};

type TNavProps = {
  nav: {
    label: string;
    status: string;
  }[];
  reportSize?: { [key: string | number]: number };
};

type TNavData = {
  [key: string]: {
    num: number;
    status: string;
  };
};
