import { useNavigate } from "react-router-dom";
import { useForm, FieldErrors, FieldValues } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { LabelInput, TextInput, DateSelect } from "./createUI";
import { ControllerDropdown } from "common/dropdown";
import { postPromotion } from "api/promotion";
import { getCompanyList } from "api/admin/company";
import { TPromotionInfo } from "types/promotion";
import queryKey from "constants/queryKey";
import useModal from "hooks/useModal";
import { Title, Footer, Button, Content, Panel, Modal, ErrorModal } from "comp-lib";

import styles from "styles/pages/promotion/create/promotionCreate.module.scss";

dayjs.extend(isSameOrBefore);

export default function PromotionCreate() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const dateFormat = (date: string) => dayjs(date).format();

  const { handleSubmit, control, reset } = useForm<FieldValues>({ mode: "onChange" });

  const [validateCheckMsg, setValidateCheckMsg] = useState(""); // 필수값 유효성 체크 메세지
  const [isValidPeriod, setIsValidPeriod] = useState(""); // 진행기간|유효기간 유효성 검사 체크 메세지
  const [isCheckModal, setIsCheckModal] = useModal(); // 등록 확인 모달
  const [isConfirmModal, setIsConfirmModal] = useModal(); // 등록 완료 모달
  const [isErrorShowing, setIsErrorShowing] = useModal(); // 에러 모달

  // [GET] 기업 리스트 조회
  const { data: companyData = [] } = useQuery([queryKey.GET_PROMOTION_COMPANY_LIST], getCompanyList);

  const companyNameList = companyData.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)).map((el) => el.name); // 기업명 목록 리스트

  // [POST] 프로모션 등록
  const { mutate, error: postError } = useMutation(postPromotion);

  // 진행기간|유효기간 유효성 검사 체크
  const handleValidCheckDate = (input: FieldValues) => {
    const { promotionNotBefore, promotionNotAfter, codeNotBefore, codeNotAfter } = input;

    if (dayjs(promotionNotAfter).isBefore(dayjs(promotionNotBefore))) {
      return setIsValidPeriod("진행 기간을 다시 선택해주세요");
    }

    if (
      dayjs(codeNotAfter).isBefore(dayjs(codeNotBefore)) ||
      dayjs(codeNotBefore).isBefore(dayjs(promotionNotBefore)) ||
      dayjs(codeNotAfter).isBefore(dayjs(promotionNotAfter)) ||
      dayjs(promotionNotAfter).isBefore(dayjs(codeNotBefore))
    ) {
      return setIsValidPeriod("유효 기간을 다시 선택해주세요");
    }

    setIsCheckModal();
  };

  const onSubmit = (input: FieldValues) => {
    const { companyName, title, promotionNotBefore, promotionNotAfter, promotionCode, codeNotBefore, codeNotAfter } =
      input;

    const companyId = companyData.filter((el) => el.name === companyName).map((el) => el.id)[0];

    const body = {
      companyId,
      title,
      promotionCode,
      promotionNotBefore: dateFormat(promotionNotBefore),
      promotionNotAfter: dateFormat(promotionNotAfter),
      codeNotBefore: dateFormat(codeNotBefore),
      codeNotAfter: dateFormat(codeNotAfter),
    } as TPromotionInfo;

    setIsCheckModal();

    mutate(body, {
      onSuccess: () => {
        setIsConfirmModal();
        queryClient.invalidateQueries([queryKey.GET_PROMOTION_LIST]);
      },
      onError: setIsErrorShowing,
    });
  };

  const onErrors = (error: FieldErrors) => {
    for (const fieldName in error) {
      if (error[fieldName]) {
        setValidateCheckMsg(String(error[fieldName]?.message));
      }
    }
  };

  return (
    <Content>
      <form onSubmit={handleSubmit(handleValidCheckDate, onErrors)} className={styles.wrapper}>
        <Title text="프로모션 설정" />

        <Panel className={styles.panel}>
          <LabelInput label="적용 기업">
            <ControllerDropdown
              name="companyName"
              control={control}
              placeholder="프로모션 적용할 기업을 선택하세요"
              dataList={companyNameList}
              width="452px"
              borderColor="soft"
              mode="secondary"
              maxHeight="320px"
            />
          </LabelInput>

          <LabelInput label="프로모션명">
            <TextInput name="title" control={control} placeholder="프로모션명을 입력하세요" />
          </LabelInput>

          <LabelInput label="진행기간">
            <div className={styles.dateBox}>
              <DateSelect name="promotionNotBefore" control={control} />
              ~
              <DateSelect name="promotionNotAfter" control={control} />
            </div>
          </LabelInput>
        </Panel>

        <Title text="코드 설정" />

        <Panel className={styles.panel}>
          <LabelInput label="코드">
            <TextInput
              name="promotionCode"
              control={control}
              placeholder="프로모션 코드를 입력하세요 (최대 길이 20byte 이내)"
              maxLength={20}
            />
          </LabelInput>

          <LabelInput label="유효기간">
            <div className={styles.dateBox}>
              <DateSelect name="codeNotBefore" control={control} />
              ~
              <DateSelect name="codeNotAfter" control={control} />
            </div>
          </LabelInput>
        </Panel>

        <Footer>
          <Button type="button" size="long" mode="lineGray" onClick={() => reset({ name: "", code: "" })}>
            초기화
          </Button>
          <Button type="submit" size="long">
            등록
          </Button>
        </Footer>
      </form>

      {/* Modals */}
      {isCheckModal && (
        <Modal
          title="프로모션을 등록하시겠습니까?"
          description="즉시 등록됩니다"
          btnType="submit"
          submitBtnName="등록"
          cancelBtnName="아니오"
          handleClose={setIsCheckModal}
          handleSubmit={handleSubmit(onSubmit, onErrors)}
        />
      )}

      {isConfirmModal && (
        <Modal description={`등록이 완료되었습니다.\n\n`} btnType="confirm" handleClose={() => navigate(-1)} />
      )}

      {validateCheckMsg && (
        <Modal description={validateCheckMsg} btnType="confirm" handleClose={() => setValidateCheckMsg("")} />
      )}

      {isValidPeriod && (
        <Modal
          title={isValidPeriod}
          description="적용할 수 없는 기간입니다"
          btnType="confirm"
          handleClose={() => setIsValidPeriod("")}
        />
      )}

      {isErrorShowing && <ErrorModal error={postError} onConfirmHandler={setIsErrorShowing} />}
    </Content>
  );
}
