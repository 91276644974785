import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import { Panel, Title, Button, Footer, Content, Modal } from "comp-lib";

import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";

import styles from "styles/pages/appPush/write/appPushWrite.module.scss";

import SetMessage from "./SetMessage";
import SetSendInfo from "./SetSendInfo";
import JsonResult from "./JSONResult";
import PreviewModal from "./preview/PreviewModal";
import PreviewContent from "./preview/PreviewContent";

import { TOs, TPushFormValue } from "types/appPush";
import { useMutation } from "react-query";
import { postPush } from "api/appPush";
import { uploadImgApi } from "api/common";
import { useAccessRole } from "hooks/useAccessRole";
import LoadingModal from "../@shared/LoadingModal";
import useModalContents from "hooks/useModalContents";
import useModal from "hooks/useModal";

dayjs.extend(utcPlugin);

export default function Write() {
  const accessInfo = useAccessRole()?.data;
  const navigate = useNavigate();

  const {
    watch,
    setValue,
    formState: { isValid, isDirty },
    handleSubmit,
  } = useFormContext<TPushFormValue>();

  const [isPreviewOpen, handleTogglePreview] = useModal();
  const [isModalOpen, handleToggleModal] = useModal();

  const [isLoading, setIsLoading] = useState(false);
  const [modalContents, setModalContents] = useModalContents();

  const isPurposeeMarketingAd = watch("messagePurpose") === "MARKETING_ADVERTISE";
  const isReserved = watch("reserveTime") === "RESERVATION";
  const isFormValid = isValid && watch("jsonOrigin") && (isReserved ? watch("date") : true);

  const formattedBody = isPurposeeMarketingAd
    ? `(광고) ${watch("body")}\n*수신거부 : MY > 푸시 알람 설정`
    : `${watch("body")}\n*수신거부 : 기기 내 설정 > 알림`;

  const postPushMuta = useMutation(({ reqBody }: { reqBody: FormData }) => postPush({ reqBody }), {
    onMutate: () => setIsLoading(true),
    onSettled: () => setIsLoading(false),
    onSuccess: (res) => {
      if (res.status === 400) {
        setModalContents({
          title: "전송(예약) 불가",
          description: `발송 대상에 유효하지 않은 DID가 포함되어 있어 전송\n(예약)할 수 없습니다. 발송 대상을 다시 확인해 주세요. `,
          btnType: "confirm",
          handleClose: handleToggleModal,
        });

        handleToggleModal();

        return;
      }

      if (res.status !== 200 && res.status !== 400) {
        setModalContents({
          title: "전송(예약) 불가",
          description: `전송에 실패했습니다.\n잠시 후에 다시 시도해주세요.`,
          btnType: "confirm",
          handleClose: handleToggleModal,
        });

        handleToggleModal();

        return;
      }

      setModalContents({
        description: "푸시 발송이 시작되었습니다.",
        btnType: "confirm",
        handleClose: () => navigate("/message/appPush"),
      });

      handleToggleModal();
    },
  });

  const uploadImgMuta = useMutation((body: { did: string; token: string; file: File }) => uploadImgApi(body), {
    onSuccess: (res) => {
      setValue("imagePath", res.fullUrlPath);
    },
  });

  const goToList = () => {
    navigate(-1);
  };

  const onClickCancel = () => {
    if (isDirty) {
      setModalContents({
        title: "목록으로 이동하시겠습니까?",
        description: "작성 중인 내용은 저장되지 않습니다.",
        btnType: "submit",
        handleClose: handleToggleModal,
        handleSubmit: () => goToList(),
      });

      handleToggleModal();
    } else {
      goToList();
    }
  };

  const handleSubmitClick = () => {
    setModalContents({
      title: "푸시를 발송하시겠습니까?",
      description: "‘예' 버튼을 누르면 푸시가 발송됩니다.\n도중에 중단할 수 없으니 확인 후 진행해 주세요",
      btnType: "submit",
      handleClose: handleToggleModal,
      handleSubmit: handleConfirmClick,
    });

    handleToggleModal();
  };

  const createPush = () => {
    const formId = document.getElementById("writeForm") as HTMLFormElement;
    const formData = new FormData(formId);

    // 필수
    formData.set("targetType", watch("targetType") || "OS");
    formData.set("messagePlatformStatus", watch("messagePlatformStatus") || "ALL");
    formData.set("agreeType", watch("agreeType") || "ALL");
    formData.set("jsonOrigin", watch("jsonOrigin"));
    formData.set("body", formattedBody);

    formData.delete("date");
    formData.delete("hour");
    formData.delete("minute");
    formData.delete("targetValue");

    // PROPOSAL
    if (watch("targetType") === "PROPOSAL") {
      formData.set("proposalId", watch("targetInput"));
    }

    // DID 직접 입력
    if (watch("targetValue") === "DID") {
      formData.set("didList", watch("targetInput"));
    }

    // DID 파일
    if (watch("targetValue") === "DID_FILE") {
      formData.set("didListFile", watch("didListFile"));
    }

    // 이미지
    if (watch("imagePath")) {
      formData.set("imagePath", watch("imagePath"));
    }

    // 발송 일시
    if (isReserved) {
      formData.set("reserveTime", watch("reserveTime"));
    } else {
      formData.delete("reserveTime");
    }

    postPushMuta.mutateAsync({ reqBody: formData });
  };

  const handleConfirmClick = async () => {
    // 야간 발송 제한
    if (watch("agreeType") === "MARKETING_ALL") {
      const nowHour = dayjs().hour();
      const nowMinute = dayjs().minute();

      const reservedHour = Number(watch("hour"));
      const reservedMinute = Number(watch("minute"));

      const isNowNightTime = nowHour < 8 || nowHour >= 21 || (nowHour === 8 && nowMinute < 30);
      const isReservedNightTime =
        isReserved && (reservedHour < 8 || reservedHour >= 21 || (reservedHour === 8 && reservedMinute < 30));

      if (isNowNightTime || isReservedNightTime) {
        setModalContents({
          title: "전송(예약) 불가",
          description: `주간 수신 동의자의 경우 21:00~08:00에 전송\n(예약)할 수 없습니다. 발송 일시를 확인해주세요`,
          btnType: "confirm",
          handleClose: handleToggleModal,
        });

        return;
      }
    }

    // 예약 시간 검증
    if (isReserved) {
      const todayNowTime = dayjs().format("YYYY-MM-DDTHH:mm:ss");
      const reservedDateTime = `${watch("date")}T${watch("hour")}:${watch("minute")}:00`;

      if (todayNowTime > reservedDateTime) {
        setModalContents({
          title: "예약 시간을 확인해주세요",
          description: "예약 발송은 현재 시간 이후만 가능합니다.",
          btnType: "confirm",
          handleClose: handleToggleModal,
        });

        return;
      }

      setValue("reserveTime", reservedDateTime);
    }

    // 이미지 업로드
    if (watch("imageFile")) {
      const file = watch("imageFile");
      const body = { did: accessInfo?.did!, token: accessInfo?.token!, file };

      await uploadImgMuta.mutateAsync(body);
    }

    await createPush();

    handleToggleModal();
  };

  return (
    <>
      <Content isWithFooter>
        <Title text="신규 등록" />
        <form id="writeForm" onSubmit={handleSubmit(handleSubmitClick)}>
          <Panel className={styles.wrapper}>
            <SetSendInfo />
          </Panel>

          <Panel className={styles.wrapper}>
            <SetMessage />
          </Panel>

          {watch("jsonOrigin") !== "" && (
            <Panel className={styles.wrapper}>
              <JsonResult
                os={(watch("messagePlatformStatus") || "ALL") as TOs}
                title={watch("title")}
                body={formattedBody}
                moveTo={watch("moveTo")}
              />
            </Panel>
          )}
        </form>
      </Content>

      <Footer>
        <div className={styles.footer}>
          <Button onClick={onClickCancel} className={styles.cancel} size="long" type="button">
            취소
          </Button>
          <Button onClick={handleTogglePreview} className={styles.preview} size="long" type="button">
            미리보기
          </Button>
          <Button form="writeForm" size="long" type="submit" disabled={!isFormValid}>
            전송하기
          </Button>
        </div>
      </Footer>

      {isPreviewOpen && (
        <PreviewModal onTogglePreviewModal={handleTogglePreview}>
          <PreviewContent />
        </PreviewModal>
      )}

      {isModalOpen && <Modal {...modalContents} submitBtnName="예" cancelBtnName="아니오" />}

      {isLoading && <LoadingModal />}
    </>
  );
}
