enum ErrorCode {
  // common
  INVALID_ARGUMENT = "INVALID_ARGUMENT",
  RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND",
  UNSUPPORTED_HTTPVERB = "UNSUPPORTED_HTTPVERB",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  NOT_LOGIN = "NOT_LOGIN",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  ERR_NOT_FOUND_DATA = "ERR_NOT_FOUND_DATA",
  ERR_NOT_DELETE = "ERR_NOT_DELETE",
  ERR_URL_FORMAT = "ERR_URL_FORMAT",
  UNAUTHORIZED = "UNAUTHORIZED",
  REQUEST_TIMEOUT_EXCEEDED = "REQUEST_TIMEOUT_EXCEEDED",
  ACCESS_DENIED = "ACCESS_DENIED",
  ERR_INPUT_PARAMETER = "ERR_INPUT_PARAMETER",

  // admin/notice
  ERR_UNREGISTER_NOTICE = "ERR_UNREGISTER_NOTICE",
  ERR_GET_DETAIL_NOTICE = "ERR_GET_DETAIL_NOTICE",
  ERR_NOTICE_COUNT_EXCEED = "ERR_NOTICE_COUNT_EXCEED",
  ERR_EXCEED_NOTAFTER = "ERR_EXCEED_NOTAFTER",
  ERR_NOT_BETWEEN_INVISIBLEPERIOD = "ERR_NOT_BETWEEN_INVISIBLEPERIOD",
  ERR_EVENT_NOTICE_URL_FORMAT = "ERR_EVENT_NOTICE_URL_FORMAT",

  // admin/users
  ERR_NOT_UPDATE = "ERR_NOT_UPDATE",
  ERR_DATA_DUPLICATED = "ERR_DATA_DUPLICATED",
  ERR_PASSWORD_NOT_CHANGED = "ERR_PASSWORD_NOT_CHANGED",

  ERR_ALREADY_QUICK_MENU = "ERR_ALREADY_QUICK_MENU",
}

export default ErrorCode;
