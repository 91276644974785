import { IError } from "types/common";

export class MiddlewareError implements IError {
  data: {
    result: any;
  };
  message: string;
  status: number;

  constructor({ data, message, status }: IError) {
    this.message = message;
    this.status = status;
    this.data = data;
  }
}
