import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import Search from "common/table/TableTop/Search";
import styles from "styles/pages/manager/listPage.module.scss";
import { Button, Content, ErrorModal, LoadingModal, Pagination, Panel, Table, TableNav, Title } from "comp-lib";
import queryKey from "constants/queryKey";
import { getManagerList } from "api/admin/manager";
import {
  createManagerTableColumn,
  createManagerTableNav,
  createManagerTableRows,
} from "./components/createManagerTable";
import { TManagerListResponse } from "types/manager";
import { isAdmin } from "./managerHelper";

export default function ListPage() {
  const navigate = useNavigate();
  const { data, isError, error, isLoading } = useQuery(queryKey.GET_ADMIN_MANAGER_LIST, getManagerList);
  const [searchParam, setSearchParam] = useSearchParams();
  const page = searchParam.get("pageNum") ? Number(searchParam.get("pageNum")) : 1;
  const keyword = searchParam.get("keyword");
  const role = searchParam.get("status");

  const filterBySearch = (targetData?: TManagerListResponse[]) =>
    keyword && targetData
      ? targetData.filter(
          (target) =>
            target.name?.toLowerCase()?.includes(keyword.toLowerCase()) ||
            target.department?.toLowerCase()?.includes(keyword.toLowerCase()),
        )
      : targetData;

  const filterByRole = (targetData?: TManagerListResponse[]) =>
    role && role !== "all" && targetData
      ? targetData.filter((target) => (role === "admin" ? isAdmin(target.role) : !isAdmin(target.role)))
      : targetData;

  if (isLoading) return <LoadingModal />;
  return (
    <Content>
      <Title text="사용자 관리 목록" />
      <Panel>
        <div className={styles.tableTopWrapper}>
          <TableNav dataList={createManagerTableNav(filterBySearch(data))} />
          <Search placeholder="이름 혹은 부서명으로 검색해 주세요" searchIcon={false} />
        </div>
        <div className={styles.tableWrapper}>
          <Table
            columns={createManagerTableColumn}
            rows={createManagerTableRows(filterByRole(filterBySearch(data))).slice(10 * (page - 1), 10 * page)}
          />
        </div>
        <div className={styles.registerButtonWrapper}>
          <Link to="register">
            <Button>사용자 등록</Button>
          </Link>
        </div>
        <Pagination totalPages={Math.ceil((filterByRole(filterBySearch(data)) || []).length / 10)} />
      </Panel>

      {isError && <ErrorModal error={error} onConfirmHandler={() => navigate("/")} />}
    </Content>
  );
}
