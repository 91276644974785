import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { Controller, FieldErrors, FieldValues, useForm } from "react-hook-form";

import { LabelInput } from "pages/company/create/createUI";
import InfoItem from "pages/company/detail/InfoItem";
import { RadioBox } from "common/input";
import useModal from "hooks/useModal";
import queryKey from "constants/queryKey";
import { getAdminCompanyDetail, modifyAdminCompany } from "api/admin/company";
import { Button, Content, Footer, Panel, Title, LoadingModal, ErrorModal, Modal } from "comp-lib";
import ManagerBtn from "../@shared/ManagerBtn";

import styles from "styles/pages/company/modify/companyModify.module.scss";

type TPrivacyPolicyInfo = {
  privacyPolicyOfficer: string; // 개인정보 보호 책임자 명
  privacyPolicyOfficerTel: string; // 개인정보 보호 책임자 연락처
  problemHandlingDept: string; // 고충처리 담당부서명
  problemHandlingDeptTel: string; // 고충처리 담당부서 연락처
};

export default function CompanyModify() {
  const { id = "" } = useParams();
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");
  const [isConfirmModal, setIsConfirmModal] = useModal();
  const [isCancelModal, setIsCancelModal] = useModal();
  const [isCheckModal, setIsCheckModal] = useModal();
  const [isError, setIsErrorShowing] = useModal();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FieldValues>({ mode: "onChange" });

  // 기업 상세 정보 조회 API 연동
  const { data, isFetching } = useQuery([queryKey.GET_ADMIN_COMPANY_INFO, id], () => getAdminCompanyDetail(+id));

  let privacyPolicyInfo = {} as TPrivacyPolicyInfo;

  if (data?.company?.privacyPolicyInfo) {
    privacyPolicyInfo = JSON.parse(data?.company?.privacyPolicyInfo);
  }

  const { privacyPolicyOfficer, privacyPolicyOfficerTel, problemHandlingDept, problemHandlingDeptTel } =
    privacyPolicyInfo;

  // 기업 정보 수정 API 연동
  const { mutate: updateMutation, error } = useMutation(modifyAdminCompany);

  // 기업 정보 수정
  const handleModifyCompany = (input: FieldValues) => {
    const { name, businessType, piiUrl } = input;
    const body = { id, name, businessType, piiUrl };

    updateMutation(body, { onSuccess: setIsConfirmModal, onError: setIsErrorShowing });
  };

  // 필수값 & URL 유효성 검사 에러 모달
  const handleErrorModal = (err: FieldErrors) => {
    for (const fieldName in err) {
      if (err[fieldName]) {
        setErrorMsg(String(err[fieldName]?.message));
      }
    }

    setIsCheckModal();
  };

  const formatBankAccount = (bankAccountNumber: string, idx: number) => {
    return bankAccountNumber.split(",")[idx];
  };

  const imgDiv = (url: string) => {
    const imgUrlSplit = (url || "").split("/");
    const imgFileName = imgUrlSplit[imgUrlSplit.length - 1];

    return (
      <Link to={url} target="_blank" rel="noopener noreferrer">
        <div className={styles.value}>{imgFileName}</div>
      </Link>
    );
  };

  if (isFetching) return <LoadingModal />;

  return (
    <Content isWithFooter>
      <Title text="기업 정보 수정" />

      {data && (
        <Panel>
          <form className={styles.innerPannel} onSubmit={handleSubmit(handleModifyCompany, handleErrorModal)}>
            <LabelInput
              label="기업명"
              id="name"
              defaultValue={data?.company?.name}
              placeholder="기업명을 입력하세요"
              type="text"
              register={register}
              isNull={!!errors.name}
            />

            <div className={styles.typeRadioBox}>
              <label htmlFor="businessType" className={styles.typeLabel}>
                소재지<span>*</span>
              </label>
              <div className={styles.typeController}>
                <Controller
                  defaultValue={data?.company?.businessType}
                  name="businessType"
                  control={control}
                  rules={{ required: "소재지를 선택해주세요" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <RadioBox name="businessType" dataList={businessTypeList} value={value} handleCheck={onChange} />
                    );
                  }}
                />
              </div>
            </div>

            <InfoItem label="기업 DID">{data?.company.did}</InfoItem>

            <LabelInput
              label="데이터 수집 서버 URL"
              id="piiUrl"
              defaultValue={data?.company?.piiUrl}
              placeholder="데이터를 수집할 서버 주소를 입력하세요"
              type="text"
              register={register}
              isNull={!!errors.piiUrl}
              validate="url"
            />

            <InfoItem label="대표 담당자">
              <ManagerBtn
                staffId={data.staff[0]?.id}
                staffName={data.staff[0]?.name}
                status={data.staff[0]?.status}
                enabled={data.company.enabled}
                companyName={data.company.name}
                companyId={data.company.id}
                staffEnabled={data.staff[0]?.enabled}
              />
            </InfoItem>

            {data?.company.tutorialStatus && (
              <>
                <InfoItem label="사업자등록증">{imgDiv(data?.company.businessRegistrationImageUrl)}</InfoItem>

                <InfoItem label="사업자등록번호">{data?.company.businessNumber}</InfoItem>

                <InfoItem label="주소">{data?.company.businessHeadOfficeAddress}</InfoItem>

                <InfoItem label="대표자명">{data?.company.businessRepresentativeName}</InfoItem>

                <InfoItem label="대표번호">{data?.company.tel}</InfoItem>

                <InfoItem label="개인정보 처리방침 URL">{data?.company.privacyPolicyUrl}</InfoItem>

                <InfoItem label="개인정보 보호 책임자명">{privacyPolicyOfficer}</InfoItem>

                <InfoItem label="개인정보 보호 책임자 연락처">{privacyPolicyOfficerTel}</InfoItem>

                <InfoItem label="고충처리 담당부서명">{problemHandlingDept}</InfoItem>

                <InfoItem label="고충처리 담당부서 연락처">{problemHandlingDeptTel}</InfoItem>

                <InfoItem label="통장 사본">{imgDiv(data?.company.bankbookCopyImageUrl)}</InfoItem>

                <InfoItem label="은행명">{formatBankAccount(data?.company.bankAccountNumber, 0)}</InfoItem>

                <InfoItem label="예금주명">{formatBankAccount(data?.company.bankAccountNumber, 1)}</InfoItem>

                <InfoItem label="계좌번호">{formatBankAccount(data?.company.bankAccountNumber, 2)}</InfoItem>

                <InfoItem label="전자세금계산서용 이메일 주소">{data?.company.businessDigitalTaxInvoiceEmail}</InfoItem>

                <InfoItem label="사업자 단위 과세 적용사업자 여부">
                  {data?.company.businessUnitTaxation ? "여" : "부"}
                </InfoItem>

                <InfoItem label="홈페이지 URL">{data?.company.homepageUrl}</InfoItem>

                <InfoItem label="기업 로고">{imgDiv(data?.company.logoUrl)}</InfoItem>
              </>
            )}
          </form>
        </Panel>
      )}

      <Footer>
        <Button size="long" mode="lineGray" onClick={setIsCancelModal}>
          취소
        </Button>
        <Button size="long" onClick={handleSubmit(handleModifyCompany, handleErrorModal)}>
          수정 등록
        </Button>
      </Footer>

      {isCancelModal && (
        <Modal
          title="기업 정보 수정을 취소하시겠습니까?"
          description="지금까지 작성한 내용은 저장되지 않습니다."
          btnType="submit"
          cancelBtnName="나가기"
          submitBtnName="계속하기"
          handleClose={() => navigate("/company")}
          handleSubmit={setIsCancelModal}
        />
      )}

      {isConfirmModal && (
        <Modal
          description="기업 정보 수정 등록이 완료되었습니다."
          btnType="confirm"
          handleClose={() => {
            navigate(`/company/info/${data?.company.id}`);
          }}
        />
      )}

      {isCheckModal && <Modal description={errorMsg} btnType="confirm" handleClose={setIsCheckModal} />}

      {isError && <ErrorModal error={error} onConfirmHandler={setIsErrorShowing} />}
    </Content>
  );
}

export const businessTypeList = [
  {
    label: "국내",
    value: "0",
  },
  {
    label: "해외",
    value: "1",
  },
];
