import { Axios } from "./base";
import { TGoods, TResGoodsList } from "types/goods";

const END_POINT = "/api/pointmall/manage/goods";

export const getGoodsList = async (pageNum: number): Promise<TResGoodsList> => {
  const response = await Axios.get(`${END_POINT}/list?pageNum=${pageNum}`);

  return response.data.data.result;
};

export const getGoodsDetail = async (code: string): Promise<TGoods> => {
  const response = await Axios.get(`${END_POINT}/detail?code=${code}`);

  return response.data.data.result;
};

type TGoodsCode = {
  code: string;
};

export const createGoods = async (goodsList: TGoodsCode[]) => {
  await Axios.post(`${END_POINT}/create`, goodsList);
};

export const deleteGoods = async (codeList: string[]) => {
  await Axios.post(`${END_POINT}/delete`, codeList);
};
