import { Dispatch, SetStateAction, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useFormContext } from "react-hook-form";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { QuickMenuEditor } from "./index";
import { ErrorModal, ToggleBtn } from "./components";
import { deleteQuickMenu } from "api/quickMenu";
import useModal from "hooks/useModal";
import { TQuickMenuItem } from "types/quickMenu";
import queryKey from "constants/queryKey";
import { Modal } from "comp-lib";

import { DeleteIcon, EditIcon } from "assets/svgs/quickMenu";
import styles from "styles/pages/quickMenu/quickMenuItem.module.scss";

type TProps = {
  index: number;
  row: TQuickMenuItem;
  setItems: Dispatch<SetStateAction<TQuickMenuItem[]>>;
  menuNameArr: string[];
};

export default function QuickMenuItem({ index, row, setItems, menuNameArr }: TProps) {
  const queryClient = useQueryClient();
  const { control } = useFormContext();
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: row.menuNo,
    data: { index },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 2 : 1,
    boxShadow: isDragging ? `rgba(0, 0, 0, 0.1) 0px 4px 12px` : "none",
    cursor: isDragging ? "grabbing" : "grab",
  };

  const { menuNo, menuName, menuImg, applyDevelop, applyProd } = row;
  const defaultValue = { applyDevelop, applyProd };

  const [isEditorModal, setIsEditorMdal] = useModal();
  const [isDeleteModal, setIsDeleteModal] = useModal();

  // 퀵메뉴 삭제
  const { mutate: deleteMutation, isError, error } = useMutation(deleteQuickMenu);

  const handleClickDeleteBtn = () => {
    deleteMutation(menuNo, {
      onSettled: setIsDeleteModal,
      onSuccess: () => queryClient.invalidateQueries([queryKey.GET_QUICK_MENU_LIST]),
    });
  };

  return (
    <div className={styles.item_box} style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <div>
        <img src={menuImg} alt="menu-icon" />
      </div>

      <div className={styles.name}>{menuName}</div>

      <div className={styles.test_version}>
        <ToggleBtn mode="applyDevelop" name={`${menuNo}`} control={control} defaultValue={defaultValue} />
      </div>

      <div className={styles.prod_version}>
        <ToggleBtn mode="applyProd" name={`${menuNo}`} control={control} defaultValue={defaultValue} />
      </div>

      <div style={{ width: "111px" }}>
        <EditIcon onClick={setIsEditorMdal} />
      </div>

      <div style={{ width: "109px" }}>
        <DeleteIcon onClick={setIsDeleteModal} />
      </div>

      {isEditorModal && (
        <QuickMenuEditor
          mode="edit"
          handleClick={setIsEditorMdal}
          item={row}
          setItems={setItems}
          menuNameArr={menuNameArr}
        />
      )}

      {isDeleteModal && (
        <Modal
          btnType="submit"
          title="메뉴 삭제"
          description="선택한 메뉴가 삭제됩니다."
          handleClose={setIsDeleteModal}
          handleSubmit={handleClickDeleteBtn}
        />
      )}

      {isError && <ErrorModal error={error} />}
    </div>
  );
}
