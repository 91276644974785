import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import styles from "styles/pages/appPush/list/dateSearchBox/dateSearchBox.module.scss";

import { RerollIcon } from "assets/svgs/icon";
import useDateRange from "hooks/useDateRange";
import SearchKeywordInput from "pages/contentsManage/vote/@shared/SearchKeywordInput";
import DateRange from "common/input/DatePicker";

/**
 * 날짜 필터 컴포넌트
 */
export default function DateSearchBox() {
  dayjs.extend(isBetween);
  const { endDate, onChangeDate, startDate, resetDate } = useDateRange();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get("searchKeyword");

  const handleResetDate = () => {
    resetDate();
    setSearchParams((params) => {
      params.delete("searchKeyword");
      return params;
    });
  };

  return (
    <div className={styles.wrapper}>
      <DateRange
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeDate}
        setParams
        placeholder={"발송 시작일 기준 날짜 검색"}
      />

      <SearchKeywordInput placeholder="검색어를 입력하세요" />

      <div className={styles.reset}>
        {(startDate || searchText) && (
          <button type="button" className={styles.searchClearBtn} onClick={handleResetDate}>
            <RerollIcon width="24px" height="24px" />
            초기화하기
          </button>
        )}
      </div>
    </div>
  );
}
