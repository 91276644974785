import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import QuickMenuDnd from "./QuickMenuDnd";
import { QuickMenuEditor, QuickMenuPreview } from "./index";
import { Button, Content, Footer, LoadingModal, Modal, Panel, Title } from "comp-lib";
import { TQuickMenuItem, TQuickVersionObj } from "types/quickMenu";
import { getQuickMenuList, updateQuickMenuList } from "api/quickMenu";
import useModal from "hooks/useModal";
import queryKey from "constants/queryKey";
import { onKeyDownPreventOnClick } from "utils";
import { ErrorModal } from "./components";

import styles from "styles/pages/quickMenu/quickMenuList.module.scss";

export default function QuickMenuList() {
  const queryClient = useQueryClient();

  const [items, setItems] = useState<TQuickMenuItem[]>([]);
  const [isCreatePopup, setIsCreatePopup] = useModal();
  const [isPreviewPopup, setIsPreviewPopup] = useModal();
  const [isSaveModal, setIsSaveModal] = useModal();
  const [isMaxCreateNotiModal, setIsMaxCreateNotiModal] = useModal();

  // 퀵메뉴 리스트 조회
  const { data = [], isLoading } = useQuery([queryKey.GET_QUICK_MENU_LIST], getQuickMenuList);

  // 퀵메뉴 그룹 저장
  const { mutate: updateMutation, isError, error } = useMutation(updateQuickMenuList);

  // 버전 상태 기본 값
  const defaultValues = data.reduce((acc: TQuickVersionObj, menuItem: TQuickMenuItem) => {
    const { menuNo, applyDevelop, applyProd } = menuItem;
    acc[menuNo] = { applyDevelop, applyProd };
    return acc;
  }, {});

  const methods = useForm({ defaultValues });
  const { handleSubmit, getValues, watch } = methods;

  useEffect(() => {
    data.length > 0 && setItems(data);
  }, [data]);

  const isOverMax = data.length >= 10; // 퀵메뉴가 10개 이상인지 여부

  // 미리보기 데이터
  const previewData = items.map((item) => {
    return { ...item, ...getValues(`${item.menuNo}`) };
  });

  // 퀵메뉴 순서가 변경되었는지 비교
  const transSeqArr = (data: TQuickMenuItem[]) => {
    // seq 순서대로 배열 내 id 순서 정렬
    const sortedMenuNoArr = data.sort((a, b) => a.seq - b.seq).map(({ menuNo }) => menuNo);
    return JSON.stringify(sortedMenuNoArr);
  };

  // 변경된 버전 상태 값
  const watchedValues = (() => {
    return data.reduce((values: TQuickVersionObj, { menuNo }) => {
      values[menuNo] = watch(String(menuNo));
      return values;
    }, {});
  })();

  // 버전 상태가 변경되었는지 비교
  const compareVerison = (defaultValues: TQuickVersionObj, watchedValues: TQuickVersionObj) => {
    const defaultKeys = Object.keys(defaultValues); // id 값들만 담긴 배열

    for (const key of defaultKeys) {
      const obj1 = defaultValues[key];
      const obj2 = watchedValues[key];

      if (!obj1 || !obj2) return true;
      if (obj1.applyDevelop !== obj2.applyDevelop || obj1.applyProd !== obj2.applyProd) {
        return false; // 버전 상태가 변경되었을 경우 false
      }
    }

    return true; // 버전 상태가 변경되지 않았을 경우 true
  };

  const isEqualSeq = transSeqArr(data) === transSeqArr(items);
  const isEqualVersion = compareVerison(defaultValues, watchedValues);

  // 메뉴 순서 및 버전 상태 변경 저장 이벤트
  const onSubmit = (input: TQuickVersionObj) => {
    const body = items.map(({ menuNo, seq }) => {
      const { applyDevelop, applyProd } = input[menuNo];
      return { menuNo, seq, applyDevelop, applyProd };
    });

    updateMutation(body, {
      onSettled: setIsSaveModal,
      onSuccess: () => queryClient.invalidateQueries([queryKey.GET_QUICK_MENU_LIST]),
    });
  };

  const handleCreateBtn = () => {
    isOverMax ? setIsMaxCreateNotiModal() : setIsCreatePopup();
  };

  const menuNameArr = data.map(({ menuName }) => menuName);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(onSubmit);
  };

  if (isLoading) return <LoadingModal />;

  return (
    <Content isWithFooter>
      <Title text="퀵메뉴 관리" subText={`서비스 관리   /   퀵메뉴관리   /   테스트 버전 관리`} />

      <Panel className={styles.panel_container}>
        <div className={styles.thead_container}>
          {columns.map((column) => (
            <div key={column.text} className={styles.th} style={{ width: column.width || "219px" }}>
              {column.text}
            </div>
          ))}
        </div>

        <FormProvider {...methods}>
          <form onSubmit={(e) => handleFormSubmit(e)}>
            <QuickMenuDnd items={items} setItems={setItems} menuNameArr={menuNameArr} />
          </form>
        </FormProvider>

        <div className={styles.createBtn_box}>
          <Button type="button" onClick={handleCreateBtn} {...onKeyDownPreventOnClick}>
            신규 등록
          </Button>
        </div>
      </Panel>

      <Footer className={styles.footer_box}>
        <Button
          type="button"
          onClick={setIsSaveModal}
          disabled={isEqualSeq && isEqualVersion}
          {...onKeyDownPreventOnClick}
        >
          저장
        </Button>
        <Button type="button" onClick={setIsPreviewPopup}>
          미리보기
        </Button>
      </Footer>

      {isCreatePopup && (
        <QuickMenuEditor
          mode="create"
          handleClick={setIsCreatePopup}
          length={data.length}
          setItems={setItems}
          menuNameArr={menuNameArr}
        />
      )}

      {isPreviewPopup && <QuickMenuPreview handleClick={setIsPreviewPopup} data={previewData} />}

      {isMaxCreateNotiModal && (
        <Modal
          btnType="confirm"
          title="퀵메뉴 등록안내"
          description={`퀵메뉴는 최대 10개까지만 등록가능합니다.\n먼저 등록된 메뉴를 삭제 후 등록해주세요`}
          handleClose={setIsMaxCreateNotiModal}
        />
      )}

      {isSaveModal && (
        <Modal
          btnType="submit"
          title="퀵메뉴 설정 저장"
          description="설정하신 퀵메뉴가 저장됩니다."
          handleClose={setIsSaveModal}
          handleSubmit={handleSubmit(onSubmit)}
        />
      )}

      {isError && <ErrorModal error={error} />}
    </Content>
  );
}

const columns = [
  { text: "메뉴 아이콘" },
  { text: "메뉴 이름" },
  { text: "테스트 버전" },
  { text: "상용 버전" },
  { text: "수정", width: "111px" },
  { text: "삭제", width: "109px" },
];
