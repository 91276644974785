import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import InfoItem from "./InfoItem";
import ManagerBtn from "../@shared/ManagerBtn";
import { getAdminCompanyDetail } from "api/admin/company";
import queryKey from "constants/queryKey";
import { Title, Button, Content, Footer, Panel, LoadingModal } from "comp-lib";

import styles from "styles/pages/company/detail/companyInfo.module.scss";

type TPrivacyPolicyInfo = {
  privacyPolicyOfficer: string; // 개인정보 보호 책임자 명
  privacyPolicyOfficerTel: string; // 개인정보 보호 책임자 연락처
  problemHandlingDept: string; // 고충처리 담당부서명
  problemHandlingDeptTel: string; // 고충처리 담당부서 연락처
};

export default function CompanyInfo() {
  const { id = "" } = useParams();
  const navigate = useNavigate();

  // 기업 상세 정보 조회 API 연동
  const { data, isFetching } = useQuery([queryKey.GET_ADMIN_COMPANY_INFO, id], () => getAdminCompanyDetail(+id));

  let privacyPolicyInfo = {} as TPrivacyPolicyInfo;

  if (data?.company?.privacyPolicyInfo) {
    privacyPolicyInfo = JSON.parse(data?.company?.privacyPolicyInfo);
  }

  const { privacyPolicyOfficer, privacyPolicyOfficerTel, problemHandlingDept, problemHandlingDeptTel } =
    privacyPolicyInfo;

  // image url -> file name
  const getImageFileNameFromUrl = (imgUrl: string) => {
    const urlSplit = (imgUrl || "").split("/")!;
    return urlSplit[urlSplit.length - 1];
  };

  const getBusinessTypeLabel = (businessType: string) => {
    if (businessType === "0") return "국내";
    if (businessType === "1") return "해외";
    return "-";
  };

  const formatBankAccount = (bankAccountNumber: string, idx: number) => {
    return (bankAccountNumber || "").split(",")[idx];
  };

  if (isFetching) return <LoadingModal />;

  return (
    <Content isWithFooter>
      <Title text="기업 상세 정보" />

      {data && (
        <Panel className={styles.panel}>
          <div className={styles.infoWrapper}>
            <InfoItem label="기업명">{data?.company.name}</InfoItem>

            <InfoItem label="소재지">{getBusinessTypeLabel(data?.company.businessType!)}</InfoItem>

            <InfoItem label="기업 DID">{data?.company.did}</InfoItem>

            <InfoItem label="데이터 수집 서버 URL">{data?.company.piiUrl}</InfoItem>

            <InfoItem label="대표 담당자">
              <ManagerBtn
                staffId={data.staff[0]?.id}
                staffName={data.staff[0]?.name}
                status={data.staff[0]?.status}
                enabled={data.company.enabled}
                companyName={data.company.name}
                companyId={data.company.id}
                staffEnabled={data.staff[0]?.enabled}
              />
            </InfoItem>

            {data?.company.tutorialStatus && (
              <>
                <InfoItem label="사업자등록증">
                  <Link to={data?.company?.businessRegistrationImageUrl} target="_blank" rel="noopener noreferrer">
                    <div className={styles.value}>
                      {getImageFileNameFromUrl(data?.company.businessRegistrationImageUrl)}
                    </div>
                  </Link>
                </InfoItem>

                <InfoItem label="사업자등록번호">{data?.company.businessNumber}</InfoItem>

                <InfoItem label="주소">{data?.company.businessHeadOfficeAddress}</InfoItem>

                <InfoItem label="대표자명">{data?.company.businessRepresentativeName}</InfoItem>

                <InfoItem label="대표번호">{data?.company.tel}</InfoItem>

                <InfoItem label="개인정보 처리방침 URL">{data?.company?.privacyPolicyUrl}</InfoItem>

                <InfoItem label="개인정보 보호 책임자명">{privacyPolicyOfficer}</InfoItem>

                <InfoItem label="개인정보 보호 책임자 연락처">{privacyPolicyOfficerTel}</InfoItem>

                <InfoItem label="고충처리 담당부서명">{problemHandlingDept}</InfoItem>

                <InfoItem label="고충처리 담당부서 연락처">{problemHandlingDeptTel}</InfoItem>

                <InfoItem label="통장 사본">
                  <Link to={data?.company?.bankbookCopyImageUrl} target="_blank" rel="noopener noreferrer">
                    <div className={styles.value}>{getImageFileNameFromUrl(data?.company.bankbookCopyImageUrl)}</div>
                  </Link>
                </InfoItem>

                <InfoItem label="은행명">{formatBankAccount(data?.company?.bankAccountNumber, 0)}</InfoItem>

                <InfoItem label="예금주명">{formatBankAccount(data?.company?.bankAccountNumber, 1)}</InfoItem>

                <InfoItem label="계좌번호">{formatBankAccount(data?.company?.bankAccountNumber, 2)}</InfoItem>

                <InfoItem label="전자세금계산서용 이메일 주소">{data?.company.businessDigitalTaxInvoiceEmail}</InfoItem>

                <InfoItem label="사업자 단위 과세 적용사업자 여부">
                  {data?.company.businessUnitTaxation ? "여" : "부"}
                </InfoItem>

                <InfoItem label="홈페이지 URL">{data?.company.homepageUrl || "-"}</InfoItem>

                <InfoItem label="기업 로고">
                  <Link to={data?.company?.logoUrl} target="_blank" rel="noopener noreferrer">
                    <div className={styles.value}>{getImageFileNameFromUrl(data?.company.logoUrl)}</div>
                  </Link>
                </InfoItem>
              </>
            )}
          </div>

          <div className={styles.dateInfoWrapper}>
            <div>최초등록 {dayjs(data?.company?.createdAt).format("YYYY.MM.DD")}</div>
            <div>업데이트 {dayjs(data?.company?.updatedAt).format("YYYY.MM.DD")}</div>
          </div>
        </Panel>
      )}

      <Footer>
        <Button size="long" mode="lineGray" onClick={() => navigate(-1)}>
          목록으로
        </Button>
        <Button size="long" onClick={() => navigate(`/company/modify/${data?.company.id}`)}>
          수정
        </Button>
      </Footer>
    </Content>
  );
}
