import { Controller, useFormContext } from "react-hook-form";
import ReactQuill from "react-quill";

import RadioGroup from "pages/contentsManage/vote/@shared/form/RadioGroup";
import PollRegisterImg from "pages/contentsManage/vote/@shared/form/PollRegisterImg";
import CustomDropdown from "pages/contentsManage/vote/@shared/form/CustomDropdown";

import { CATEGORY_DATA_LIST, QUE_TYPE_LIST, TYPE_LIST } from "constants/vote";
import { TContent } from "types/contentsManage/vote";

import "react-quill/dist/quill.snow.css";
import "styles/pages/contentsManage/vote/write/quill.custom.css";
import styles from "styles/pages/contentsManage/vote/write/content.module.scss";
import cx from "classnames";

const MODULES = {
  toolbar: [[{ header: [1, 2, false] }], ["bold", "italic", "underline"], ["link"]],
};

const FORMATS = ["header", "bold", "italic", "underline", "link"];

export default function Content() {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<TContent>();

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <div className={styles.title}>
          유형 <sup>*</sup>
        </div>

        <div>
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomDropdown dataList={TYPE_LIST} value={value} onChange={onChange} />
            )}
          />
        </div>
      </div>
      <div className={styles.label} aria-invalid={errors.question ? "true" : "false"}>
        <div className={styles.title}>
          문항 수 <sup>*</sup>
        </div>

        <Controller
          name="question"
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <RadioGroup dataList={QUE_TYPE_LIST} name={name} value={value} onChange={onChange} />
          )}
          rules={{
            required: "문항 수를 선택해 주세요",
          }}
        />
      </div>
      <div className={styles.label}>
        <div className={styles.title}>
          카테고리 <sup>*</sup>
        </div>

        <div>
          <Controller
            name="category"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomDropdown dataList={CATEGORY_DATA_LIST} value={value} onChange={onChange} />
            )}
          />
        </div>
      </div>
      <label aria-invalid={errors.title ? "true" : "false"}>
        <div className={styles.title}>
          제목 <sup>*</sup>
        </div>

        <input
          type="text"
          placeholder="콘텐츠 제목을 입력해주세요 (최대 50자)"
          maxLength={50}
          {...register("title", {
            validate: { required: (text) => (text.trim() ? true : "콘텐츠 제목을 입력해주세요") },
            maxLength: {
              value: 50,
              message: "최대 50자까지 입력해주세요.",
            },
          })}
        />

        {errors.title?.type === "maxLength" && <p className={styles.errorMsg}>{errors.title.message}</p>}
      </label>

      <div className={styles.content}>
        <label htmlFor="content" aria-invalid={errors.content ? "true" : "false"}>
          <div className={styles.title}>
            본문 <sup>*</sup>
          </div>
        </label>

        <Controller
          name="content"
          control={control}
          defaultValue=""
          rules={{
            validate: {
              required: (value) =>
                (value && value !== "<p><br></p>" && !/<p>\s*<\/p>/.test(value)) || "본문을 입력해주세요",
            },
            maxLength: {
              value: 500,
              message: "본문은 최대 500자까지 입력 가능합니다.",
            },
          }}
          render={({ field }) => (
            <ReactQuill
              id="content"
              theme="snow"
              modules={MODULES}
              formats={FORMATS}
              value={field.value}
              className={styles.quill}
              onChange={(content) => field.onChange(content)}
              placeholder="콘텐츠 본문을 입력해주세요 (최대 500자)"
            />
          )}
        />
        <p className={cx(styles.noticeMsg, { [styles.error]: watch("content").length > 500 })}>{`작성 가능한 글자 수 ${
          watch("content").length
        } / 500`}</p>
      </div>

      <div className={styles.label}>
        <div className={styles.title}>
          대표 이미지를 등록해 주세요 <sup>*</sup>
        </div>

        <div>
          <PollRegisterImg />
        </div>

        <ul className={styles.descrip}>
          <li>배너 이미지는 360*360px 이상의 정사각형(1:1)만 등록 가능합니다.</li>
          <li>파일 형식은 .jpg .jpeg .png 만 가능합니다.</li>
        </ul>
      </div>
    </div>
  );
}
