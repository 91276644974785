import { useFormContext } from "react-hook-form";

import styles from "styles/common/input/textArea.module.scss";

import { getCommasInNum } from "utils";

type TProps = {
  id: string;
  maxLength: number;
  width?: string;
  height?: string;
  placeholder?: string;
  required?: boolean;
  lengthCount?: boolean;
  disabled?: boolean;
};

export default function TextArea({
  id,
  maxLength,
  width = "100%",
  height = "100px",
  placeholder,
  required,
  lengthCount,
  disabled,
}: TProps) {
  const { register, watch } = useFormContext();

  return (
    <div className={styles.wrapper}>
      <textarea
        {...register(id, { required })}
        id={id}
        style={{ width, height }}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
      />

      {lengthCount && <span>{`글자수 ${getCommasInNum(watch(id).length)}/${getCommasInNum(maxLength)}`}</span>}
    </div>
  );
}
