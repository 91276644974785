import styles from "styles/pages/contentsManage/challenge/detail/detailInfo.module.scss";
import dayjs from "dayjs";
import { getCommasInNum } from "utils";
import { Panel, Title } from "comp-lib";
import { useEffect, useState } from "react";
import { TCreateChallengeResponse } from "types/contentsManage/challenge";
import { CHALLENGE_CATEGORY_ENG, CHALLENGE_STATUS_MAP } from "../constants";

export default function DetailInfo({ data }: { data: TCreateChallengeResponse }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const closePopup = () => setIsPopupOpen(false);
    isPopupOpen && document.addEventListener("click", closePopup, true);
    return () => {
      document.removeEventListener("click", closePopup, true);
    };
  }, [isPopupOpen]);

  const challengeBefore = [
    { name: "카테고리", value: CHALLENGE_CATEGORY_ENG[data.category], type: "dd" },
    {
      name: "모집인원",
      value: `${getCommasInNum(data.currentParticipantsCount)}/${getCommasInNum(data.maxParticipantsCount)}`,
      type: "dd",
    },
    { name: "제목", value: data.title, type: data.status === "CANCELED" ? "hidden" : "dd" },
    { name: "챌린지 설명", value: data.description, type: data.status === "CANCELED" ? "hidden" : "dd" },
    { name: "참여방법", value: data.proofMethod, type: data.status === "CANCELED" ? "hidden" : "dd" },
    { name: "유의사항", value: data.caution, type: "p" },
    { name: "이미지", value: data.imageId, type: "link" },
    { name: "진행상태", value: CHALLENGE_STATUS_MAP[data.status], type: "dd" },
  ];

  const challengeStart = [
    { name: "제목", value: data.title, type: "dd" },
    { name: "챌린지 설명", value: data.description, type: "dd" },
    { name: "참여방법", value: data.proofMethod, type: "dd" },
    {
      name: "모집인원",
      value: `${getCommasInNum(data.currentParticipantsCount)}/${getCommasInNum(data.maxParticipantsCount)}`,
      type: "dd",
    },
    { name: "카테고리", value: CHALLENGE_CATEGORY_ENG[data.category], type: "dd" },
    {
      name: "모집기간",
      value: `${dayjs(data.applyStartDate).format("YYYY.MM.DD")} ~ ${dayjs(data.applyEndDate).format("YYYY.MM.DD")}`,
      type: "dd",
    },
    {
      name: "진행기간",
      value: `${dayjs(data.startDate).format("YYYY.MM.DD")} ~ ${dayjs(data.endDate).format("YYYY.MM.DD")}`,
      type: "dd",
    },
    { name: "1인당 참가포인트", value: `${getCommasInNum(data.applyPointPerPerson)} 포인트 차감`, type: "dd" },
    {
      name: "총 참가포인트",
      value: `${getCommasInNum(data.applyPointPerPerson * data.currentParticipantsCount)} 포인트`,
      type: "dd",
    },
    { name: "성공시 1인당 지급포인트", value: `${getCommasInNum(data.rewardPointPerPerson)} 포인트`, type: "dd" },
    {
      name: "성공시 총 지급포인트",
      value: `${getCommasInNum(data.rewardPointPerPerson * data.currentParticipantsCount)} 포인트`,
      type: "dd",
    },
    {
      name: "진행상태", // 강제종료 정보(challengeCancel)가 넘어오면 강제종료 상태
      value:
        data.status === "ENDED" && data?.challengeCancel?.createdAt ? "강제종료" : CHALLENGE_STATUS_MAP[data.status],
      type: "dd",
    },
    {
      name: "강제종료 사유",
      value: data?.challengeCancel?.reason || "-",
      type: data?.challengeCancel?.createdAt ? "dd" : "hidden",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Title text={"챌린지 상세보기"} />
      <Panel>
        <dl>
          {(["PREOPEN", "CANCELED"].includes(data.status) ? challengeBefore : challengeStart).map(
            ({ name, value, type }) => {
              if (type === "hidden") return;
              return (
                <div key={name} className={styles.info}>
                  <dt>{name}</dt>
                  {type === "link" && (
                    <dd onClick={() => setIsPopupOpen(true)} className={styles.link}>
                      {data.fileName || "대표 이미지"}
                    </dd>
                  )}
                  {type === "dd" && <dd>{value}</dd>}
                  {type === "p" && (
                    <dd>
                      <p>{value}</p>
                    </dd>
                  )}
                </div>
              );
            },
          )}
        </dl>
      </Panel>
      {isPopupOpen && (
        <div className={styles.popup}>
          <div>
            <img src={`/api/file-service/download-file/${data.imageId}`} />
          </div>
        </div>
      )}
    </div>
  );
}
