import styles from "styles/pages/mydInquiry/detail/imgModal.module.scss";

import { Modal } from "comp-lib";

type TProps = {
  localImg?: string[];
  serverImg?: string[];
  onToggleModal: () => void;
};

export default function ImgModal({ localImg, serverImg, onToggleModal }: TProps) {
  return (
    <Modal
      btnType="confirm"
      title="이미지(팝업)"
      width="fit-content"
      height="fit-content"
      handleClose={onToggleModal}
      description={
        <div className={styles.wrapper}>
          <div className={styles.imgs}>
            {/* 로컬에서 <input type="file"/>로 등록한 이미지 */}
            {localImg &&
              localImg.map((src, idx) => {
                return <img key={idx} src={src} />;
              })}

            {/* 서버에서 받아온 이미지 id */}
            {serverImg &&
              serverImg.map((id, idx) => {
                return <img key={idx} src={`/api/file-service/download-file/${id}`} />;
              })}
          </div>
        </div>
      }
    />
  );
}
