import { ComponentProps, MouseEvent, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { DropdownContext, useDropdownContext } from "./Context";
import useOnClickOutside from "../../useOnClickOutside";

type TProps = {
  value: string;
  onChange: (item: string) => void;
  children: ReactNode;
  className?: string;
};

function Main({ value, onChange, children, className }: TProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(
    (arg: string) => {
      onChange(arg);
      setIsOpen(false);
    },
    [onChange] // TODO: 랜더링 성능 영향?
  );

  const contextValue = useMemo(
    () => ({
      value,
      isOpen,
      onChange: handleChange,
      onOpen: () => setIsOpen(true),
      onClose: () => setIsOpen(false),
      onToggle: () => setIsOpen((prev) => !prev),
    }),
    [handleChange, isOpen, value]
  );

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <DropdownContext.Provider value={contextValue}>
      <div className={className} ref={ref}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

function Trigger({ children, onClick, style, ...props }: ComponentProps<"button">) {
  const { isOpen, onToggle } = useDropdownContext();

  const handleToggle = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }

    onToggle();
  };

  return (
    <button
      type="button"
      onClick={handleToggle}
      data-state={isOpen ? "open" : "close"}
      style={{ width: "160px" }}
      {...props}
    >
      {children}
    </button>
  );
}

function Options({ children, ...props }: ComponentProps<"ul">) {
  const { isOpen } = useDropdownContext();

  if (!isOpen) return null;
  return <ul {...props}>{children}</ul>;
}

type TOptionProps = {
  value: string;
} & Omit<ComponentProps<"li">, "value">;

function Option({ value, children, className, ...props }: TOptionProps) {
  const { value: currentValue, onChange, onClose } = useDropdownContext();

  const handleClick = () => {
    onChange(value);
    onClose();
  };

  const isSelected = currentValue === value;

  return (
    <li {...props}>
      <button
        type="button"
        onClick={handleClick}
        className={className}
        style={{ width: "100%" }}
        data-state={isSelected ? "checked" : "unchecked"}
      >
        {children || value}
      </button>
    </li>
  );
}

const Dropdown = Object.assign(Main, {
  Trigger,
  Options,
  Option,
});
export default Dropdown;
