import React, { HtmlHTMLAttributes } from "react";

import styles from "styles/pages/appPush/write/requiredMark.module.scss";

interface RequiredMarkProps extends HtmlHTMLAttributes<HTMLDivElement> {
  require?: boolean;
  children: React.ReactNode;
}

function RequiredMark({ require = true, children, ...props }: RequiredMarkProps) {
  return (
    <div {...props} className={`${props.className} ${styles.container}`}>
      {children}
      {require && <div className={styles.require}>*</div>}
    </div>
  );
}

export default RequiredMark;
