import { useFormContext } from "react-hook-form";

import styles from "styles/pages/mydInquiry/detail/write.module.scss";

import { Checkbox, Panel } from "comp-lib";

import ImgModal from "./ImgModal";
import FileInputMultiple from "common/input/FileInputMultiple";

import useModal from "hooks/useModal";
import { getCommasInNum } from "utils";
import InfoItem from "common/other/InfoItem";
import { QuestionIcon } from "assets/svgs/icon";
import { TextArea } from "common/input";

const MAX_LENGTH = 1000;

type TProps = {
  isCompleteChecked?: boolean;
  onChangeCheckBtn?: () => void;
};

export default function Write({ isCompleteChecked, onChangeCheckBtn }: TProps) {
  const { getValues } = useFormContext();
  const [isModalOpen, onToggleModal] = useModal();

  const localImgArr = getValues("imgFile").map((img: { file: File; url: string }) => img.url);

  return (
    <>
      <Panel className={styles.panel}>
        <form>
          <h2 className={styles.title}>답변 등록</h2>

          <TextArea
            id="body"
            maxLength={MAX_LENGTH}
            placeholder={`답변을 입력해 주세요 (최대 ${getCommasInNum(MAX_LENGTH)}자)`}
            required
            lengthCount
            disabled={isCompleteChecked}
          />

          <InfoItem
            isLast
            title="이미지 등록"
            value={
              <FileInputMultiple
                id="imgFile"
                sizeLimit={5}
                maxLength={3}
                disabled={isCompleteChecked}
                onClick={onToggleModal}
              >
                파일 선택
              </FileInputMultiple>
            }
          />

          <div className={styles.checkBox}>
            <Checkbox id="complete" onChange={onChangeCheckBtn!} />
            <label htmlFor="complete">해당 문의 완료하기</label>
          </div>
        </form>

        {isModalOpen && <ImgModal localImg={localImgArr} onToggleModal={onToggleModal} />}
      </Panel>

      <div className={styles.desc}>
        <QuestionIcon />
        이미지 등록 : 최대 3개까지 업로드, 1개 파일 최대 용량은 5MB
      </div>
    </>
  );
}
