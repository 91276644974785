import { marketStatusMap } from "constants/transaction";
import { TTransactionItem } from "types/transaction";

export default function useTableNavData(data: TTransactionItem[]) {
  return [
    {
      label: "전체",
      status: "all",
      count: data.length,
    },
    {
      label: "진행중",
      status: marketStatusMap[0][1], // inProgress
      count: data.filter(({ status }) => status === 0).length,
    },
    {
      label: "예정",
      status: marketStatusMap[1][1], // notStarted
      count: data.filter(({ status }) => status === 1).length,
    },
    {
      label: "종료",
      status: marketStatusMap[3][1], // ended
      count: data.filter(({ status }) => status === 3 || status === 4).length,
    },
    {
      label: "철회",
      status: marketStatusMap[2][1], // revoked
      count: data.filter(({ status }) => status === 2).length,
    },
  ];
}
