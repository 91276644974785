import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import dayjs from "dayjs";

import InfoItem from "./InfoItem";
import queryKey from "constants/queryKey";
import { getPromotionDetailInfo } from "api/promotion";
import { TPromotionDetailInfo } from "types/promotion";
import { Button, Content, Footer, Panel, Title, LoadingModal } from "comp-lib";

import styles from "styles/pages/promotion/detail/promotionDetail.module.scss";

export default function PromotionDetail() {
  const navigate = useNavigate();
  const { id = "" } = useParams();

  const { data, isLoading } = useQuery([queryKey.GET_PROMOTION_DETAIL_INFO, id], () => getPromotionDetailInfo(+id));

  const { name, did, title, promotionCode, promotionNotBefore, promotionNotAfter, codeNotBefore, codeNotAfter } =
    (data as TPromotionDetailInfo) || {};

  const dateFormatting = (date: string) => (date ? dayjs(date).format("YYYY.MM.DD") : "");

  if (isLoading) return <LoadingModal />;

  return (
    <Content>
      <Title text="프로모션 상세 정보" />

      <Panel className={styles.panel}>
        <InfoItem label="적용 기업">
          {name}({did})
        </InfoItem>

        <InfoItem label="프로모션명">{title}</InfoItem>

        <InfoItem label="진행기간">
          {dateFormatting(promotionNotBefore)}~{dateFormatting(promotionNotAfter)}
        </InfoItem>

        <InfoItem label="코드">{promotionCode}</InfoItem>

        <InfoItem label="유효기간">
          {dateFormatting(codeNotBefore)}~{dateFormatting(codeNotAfter)}
        </InfoItem>
      </Panel>

      <Footer>
        <Button size="long" onClick={() => navigate(-1)}>
          확인
        </Button>
      </Footer>
    </Content>
  );
}
