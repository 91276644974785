import { ComponentProps } from "react";

import styles from "styles/pages/reward/mintBurn/progressbar.module.scss";

type TProps = {} & ComponentProps<"div">;

function Progressbar({ ...otherProps }: TProps) {
  return (
    <div className={styles.progress_bar_track}>
      <div className={styles.progress_bar} {...otherProps} />
    </div>
  );
}

export default Progressbar;
