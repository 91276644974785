import { Controller, useFormContext } from "react-hook-form";
import cx from "classnames";

import DateTime from "./DateTime";
import Radio from "../@tmp/Radio";
import { TBanner } from "types/banner";
import styles from "styles/pages/banner/bannerCreator.module.scss";
import { FileInput } from "common/input";

export default function FormBanner() {
  const {
    register,
    control,
    formState: { errors },
    trigger,
    getValues,
  } = useFormContext<TBanner>();

  return (
    <>
      <div className={cx(styles.input_box, styles.tooltip_box)} aria-invalid={errors.imgFileStr ? "true" : "false"}>
        <div className={cx(styles.title, styles.tooltip_title)}>
          배너 이미지
          <span className={styles.required}>*</span>
        </div>

        <div className={cx(styles.content, styles.tooltip_content)}>
          <FileInput id="imgFileStr" aria-invalid={errors.imgFileStr ? "true" : "false"} limitRatio={[328, 80]} />

          <ul className={styles.tooltip}>
            <li>
              이미지는 jpg, jpeg, png 파일만 업로드 가능하며, 이미지 크기는 최소 328 X 80 px 입니다. 최소 3배수 이상
              크기로 등록해 주세요
            </li>
          </ul>
        </div>
      </div>

      <label className={styles.input_box} aria-invalid={errors.url ? "true" : "false"}>
        <div className={styles.title}>
          연결 URL
          <span className={styles.required}>*</span>
        </div>

        <div className={styles.content}>
          <input
            type="text"
            placeholder="URL은 'https://'로 시작해야 합니다."
            className={styles.title_text}
            {...register("url", {
              required: "올바른 URL 형식으로 입력해 주세요.",
              pattern: {
                value: /^https:\/\/([\S]{3,})/,
                message: "올바른 URL 형식으로 입력해 주세요.",
              },
            })}
          />
        </div>
        {errors.url && <p className={styles.error_msg}>{errors.url.message}</p>}
      </label>

      <div className={styles.input_box}>
        <div className={styles.title}>
          토큰 설정
          <span className={styles.required}>*</span>
        </div>

        <div className={styles.content}>
          <label>
            <Radio value="true" className={styles.type_radio} {...register("isToken")} />
            <span>설정함</span>
          </label>

          <label>
            <Radio value="false" className={styles.type_radio} {...register("isToken")} />
            <span>설정 안함</span>
          </label>
        </div>
      </div>

      <div className={styles.input_box} aria-invalid={errors.startDateTime || errors.endDateTime ? "true" : "false"}>
        <div className={styles.title}>
          노출 기간
          <span className={styles.required}>*</span>
        </div>

        <div className={styles.content}>
          <Controller
            name="startDateTime"
            control={control}
            rules={{
              required: true,
              validate: {
                validEndDate: (value) => {
                  const endDateTime = getValues("endDateTime");

                  if (endDateTime && value) {
                    return new Date(endDateTime) >= new Date(value);
                  }

                  return true;
                },
              },
            }}
            render={({ field: { value, onChange } }) => (
              <DateTime
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger("endDateTime"); // 종료 날짜의 유효성 검사를 다시 실행
                }}
                aria-invalid={errors.startDateTime ? "true" : "false"}
              />
            )}
          />
          <div>~</div>
          <Controller
            name="endDateTime"
            control={control}
            rules={{
              required: true,
              validate: {
                validEndDate: (value) => {
                  const startDateTime = getValues("startDateTime");

                  if (startDateTime && value) {
                    return new Date(startDateTime) <= new Date(value);
                  }

                  return true;
                },
              },
            }}
            render={({ field: { value, onChange } }) => (
              <DateTime
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger("startDateTime"); // 시작 날짜의 유효성 검사를 다시 실행
                }}
                aria-invalid={errors.endDateTime ? "true" : "false"}
              />
            )}
          />
        </div>
        {(errors.endDateTime?.type === "validEndDate" || errors.startDateTime?.type === "validEndDate") && (
          <p className={styles.error_msg}>종료 날짜가 시작날짜보다 작습니다.</p>
        )}
      </div>
    </>
  );
}
