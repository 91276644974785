import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Title } from "common/other";
import SidebarLayout from "./mobile/SidebarLayout";
import MobileViewMultiQue from "./mobile/MobileViewMultiQue";
import MultiQuestion from "./MultiQuestion";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";
import ConfirmModal from "pages/contentsManage/vote/@shared/modal/ConfirmModal";

import { contPrFormAtom } from "recoil/vote";
import styles from "styles/pages/contentsManage/vote/write/multiQuestion.module.scss";
import { useAccessRole } from "hooks/useAccessRole";
import { useMutation } from "react-query";
import { QUE_CHOICE_MAP } from "constants/vote";
import { PollRegistRequest } from "protobuf/OpenApiServerV3";
import { createPoll } from "api/contentsManage/poll";
import { uniqueKey } from "utils";
import { TPollQuestionData, TPollMultiQuestion } from "types/contentsManage/vote";
import { formatCorrectAns, formatMultiQuestionAnswer, formatPollRegistForm } from "./pollFormatter";
import { validateCorrectAns } from "./validateCorrectAns";
import { Footer, LoadingModal } from "comp-lib";
import VoteTitle from "../@shared/VoteTitle";
import { uploadCommonImgApi } from "api/common";
import { uint8ArrayToString } from "utils/uint8Array";

export default function MultiQuestioWrapper() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const contPrForm = useRecoilValue(contPrFormAtom);

  const [isNotiModal, setIsNotiModal] = useState(false);
  const [warnModalMsg, setWarnModalMsg] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isSelectAnswerType, setIsSelectAnswerType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: accessRole } = useAccessRole();

  const { mutate } = useMutation((data: PollRegistRequest) => createPoll(data), {
    onSuccess: () => setIsNotiModal(true),
    onError: (err: Error) => setWarnModalMsg(err?.message || "투표 생성 중 오류가 발생했습니다"),
    onMutate: () => {
      setIsLoading(true);
      setIsModal(false);
    },
    onSettled: () => setIsLoading(false),
  });

  const draftQuestion = (isSelectAnsType?: boolean): TPollMultiQuestion[number] => {
    return {
      pageId: uniqueKey(),
      contents: [
        {
          title: "",
          queType: isSelectAnsType ? "정답 단일 선택형" : "단일 선택형",
          answerList: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
          descriptive: "",
          correct_ans: "",
        },
      ],
    };
  };

  const init = { question: [draftQuestion()] };

  const formMethods = useForm<{ question: TPollMultiQuestion }>({ defaultValues: init });

  const { control, handleSubmit, getValues, setValue } = formMethods;

  const { fields, append, remove, replace } = useFieldArray({ control, name: "question" });

  // 저장된 질문설정 데이터를 불러온다.
  useEffect(() => {
    if (!state || !state.multiYn) return;
    let count = 0;

    const prevQuestions: TPollQuestionData[][] = JSON.parse(uint8ArrayToString(state.questions));

    const pointState = state.correctAns ? JSON.parse(state.correctAns || "").point.split("|") : [];
    setIsSelectAnswerType(["3", "4"].includes(prevQuestions[0][0].question_type));
    const defaultValue: TPollMultiQuestion = prevQuestions.map((page) => {
      return {
        pageId: uniqueKey(),
        contents: page.map((question) => {
          const prevQueType = Object.keys(QUE_CHOICE_MAP)[Number(question?.question_type) - 1];
          return {
            title: question.title,
            queType: prevQueType,
            answerList: question.ans.map((v) => {
              return {
                value: v,
              };
            }),
            descriptive: prevQueType === "주관식 단답형" ? question.ans[0] : "",
            correct_ans: /정답 (단일|복수) 선택형/.test(prevQueType) ? question.correct_ans : "",
            point: pointState[count++],
          };
        }),
      };
    });
    setValue("question", defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const pages = getValues("question");
  const isDisabled = pages.length === 1 && pages[0].contents.length === 1;

  const onSubmit = () => {
    setIsModal(true);
  };

  const questionCnt = getValues()
    .question.map((el) => el.contents)
    .flat().length;

  const handlePrevNav = () => {
    const questionAnswer = formatMultiQuestionAnswer(getValues("question"));
    const pollRegistForm = formatPollRegistForm({
      form: contPrForm,
      questionAnswer,
      state,
      correctAns: formatCorrectAns(getValues("question")),
    });
    navigate(`/contents/vote/writeContPr${state ? `?pollId=${state.pollId}` : ""}`, { state: pollRegistForm });
  };

  const handleIsSelectAnswerType = (isAnswerType?: boolean, isReset?: boolean) => {
    setIsSelectAnswerType(!!isAnswerType);
    const handleAnswerTypeUnity = () => {
      const unityFields = getValues("question").map((filed) => {
        return {
          ...filed,
          contents: filed.contents.map((question) =>
            /정답 (단일|복수) 선택형/.test(question.queType) !== isAnswerType
              ? draftQuestion(isAnswerType).contents[0]
              : question,
          ),
        };
      });
      replace(unityFields);
    };
    isReset && handleAnswerTypeUnity();
  };

  const handleDivPage = () => {
    return fields.length < 100
      ? append(draftQuestion(isSelectAnswerType))
      : setWarnModalMsg("100페이지를 초과하여 생성할 수 없습니다");
  };

  const handleModalClose = () => setIsModal(false);

  const handleModalConfirm = async () => {
    try {
      if (!accessRole?.token) return;

      const { question } = getValues();
      const questionAnswer = formatMultiQuestionAnswer(question);
      const { imgFileString } = contPrForm;

      const imgLink =
        typeof imgFileString === "string"
          ? imgFileString
          : await uploadCommonImgApi(accessRole.did, accessRole.token, imgFileString).then((el) => el.fullUrlPath);

      const pollRegistForm = formatPollRegistForm({
        form: contPrForm,
        imgLink,
        questionAnswer,
        state,
        correctAns: formatCorrectAns(question),
      });

      const validateCorrectAnsMsg = validateCorrectAns(question, contPrForm.perPersonPnt);

      if (validateCorrectAnsMsg !== false) {
        setWarnModalMsg(validateCorrectAnsMsg);
        return;
      }

      mutate(pollRegistForm);
    } catch {
      setWarnModalMsg(`이미지 등록 중 오류가 발생했습니다.\n이미지 파일을 다시 등록해주세요.`);
    }
  };

  const handleNotiModalClose = () => {
    setIsNotiModal(false);
    navigate("/contents/vote");
  };
  const handleWarnModalMsgClose = () => {
    setWarnModalMsg("");
    setIsModal(false);
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <VoteTitle text="질문 설정" page={3} />

        {fields.map((item, index) => {
          if (item.contents.length === 0) {
            return null;
          }
          return (
            <div className={styles.wrapperInner} key={item.id}>
              <Title text={`페이지 ${index + 1}`} />

              <section className={styles.box}>
                <MultiQuestion
                  pageIndex={index}
                  fieldLength={fields.length}
                  onAddPage={handleDivPage}
                  onRemovePage={() => remove(index)}
                  isSelectAnswerType={isSelectAnswerType}
                  handleIsSelectAnswerType={handleIsSelectAnswerType}
                  perAmount={contPrForm.perPersonPnt}
                />
              </section>
            </div>
          );
        })}
      </form>

      <Footer className={styles.btn_box}>
        <button type="button" className={styles.out} onClick={handlePrevNav}>
          이전으로
        </button>
        <button type="submit" className={styles.next} disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
          등록
        </button>
      </Footer>

      {isModal && (
        <ConfirmModal
          message={`[${questionCnt}문항] ${contPrForm.title}`}
          subMessage="콘텐츠가 마이디 투표에 등록됩니다."
          onCancle={handleModalClose}
          onConfirm={handleModalConfirm}
        />
      )}

      {isNotiModal && <NotiModal onClose={handleNotiModalClose} message="등록되었습니다." />}
      {warnModalMsg && <NotiModal message={warnModalMsg} onClose={handleWarnModalMsgClose} />}
      {isLoading && <LoadingModal />}

      <SidebarLayout>
        <MobileViewMultiQue />
      </SidebarLayout>
    </FormProvider>
  );
}
