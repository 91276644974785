import { ChangeEventHandler, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import CheckBox from "pages/contentsManage/vote/@shared/form/CheckBox";
import { TProgress } from "types/contentsManage/vote";
import styles from "styles/pages/contentsManage/vote/write/dateBox.module.scss";

dayjs.extend(isSameOrBefore);

export default function DateBox() {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<TProgress>();
  const [startDateState, setStartDateState] = useState<string>();
  const [endDateState, setEndDateState] = useState<string>();
  const [isCheck, setIsCheck] = useState(false);

  const handleCheck: ChangeEventHandler<HTMLInputElement> = () => {
    setValue("endDate", isCheck ? "" : "2999-12-31");
    setIsCheck((v) => !v);
  };

  useEffect(() => {
    setIsCheck(getValues().endDate === "2999-12-31");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues().endDate]);

  return (
    <>
      <div className={styles.wrapper}>
        <input
          type="date"
          min={dayjs().format("YYYY-MM-DD")}
          max={endDateState || "2999-12-31"}
          aria-invalid={errors.startDate ? "true" : "false"}
          {...register("startDate", {
            onChange: (e) => setStartDateState(e.target.value),
            required: "시작 날짜를 입력해주세요!",
            validate: (value) => {
              const isSameOrBeforeToday = dayjs().isSameOrBefore(value, "day");
              return isSameOrBeforeToday || "시작 날짜는 오늘 이후로 설정해주세요!";
            },
          })}
        />

        <span className={styles.separator}>~</span>

        <input
          type="date"
          min={startDateState || dayjs().format("YYYY-MM-DD")}
          max="2999-12-31"
          readOnly={isCheck}
          aria-invalid={errors.endDate ? "true" : "false"}
          {...register("endDate", {
            onChange: (e) => setEndDateState(e.target.value),
            required: "종료 날짜를 입력해주세요!",
            validate: (value) => {
              const isSameOrBeforeStart = dayjs(startDateState).isSameOrBefore(value, "day");
              return isSameOrBeforeStart || "종료 날짜는 시작 날짜 이후로 설정해주세요!";
            },
          })}
        />

        <div className={styles.check_box}>
          <CheckBox text="종료일 미설정" checked={isCheck} onChange={handleCheck} />
        </div>
      </div>

      {errors.startDate && <p className={styles.error}>{errors.startDate.message}</p>}
      {errors.endDate && <p className={styles.error}>{errors.endDate.message}</p>}
    </>
  );
}
