import { useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import styles from "styles/pages/contentsManage/challenge/write/challengeDateBox.module.scss";
import { TChallengeProcess } from "types/contentsManage/challenge";

dayjs.extend(isSameOrBefore);

export default function ChallengeProgressDateBox() {
  const {
    register,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<TChallengeProcess>();

  return (
    <>
      <div className={styles.wrapper}>
        <input
          type="date"
          disabled
          value={dayjs(watch("applyEndDate")).add(1, "day").format("YYYY-MM-DD")}
          aria-invalid={errors["startDate"] ? "true" : "false"}
          {...register("startDate", {
            valueAsDate: true,
            value: dayjs(watch("applyEndDate")).add(1, "day").format("YYYY-MM-DD"),
            required: "시작 날짜를 입력해주세요!",
            validate: (value) => {
              if (!dayjs(value).isAfter(getValues("applyEndDate"))) return "진행기간은 모집기간 이후로 설정해주세요!";
              return true;
            },
          })}
        />

        <span className={styles.separator}>~</span>

        <input
          type="date"
          min={watch("startDate") ? dayjs(watch("startDate")).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD")}
          max="2999-12-31"
          aria-invalid={errors["endDate"] ? "true" : "false"}
          {...register("endDate", {
            valueAsDate: true,
            onChange: () => {
              trigger("startDate");
              trigger("endDate");
            },
            required: "종료 날짜를 입력해주세요!",
            validate: (value) => {
              const diff = dayjs(getValues("startDate")).diff(value, "day");
              const isSameOrBeforeStart = dayjs(getValues("startDate") || undefined).isSameOrBefore(value, "day");
              if (diff > -4 || diff <= -30) return "진행기간은 최소 5일 최대30일 입니다!";
              return isSameOrBeforeStart || "종료 날짜는 시작 날짜 이후로 설정해주세요!";
            },
          })}
        />
        <span className={styles.description}>(진행기간은 최소 5일~최대30일)</span>
      </div>

      {errors["startDate"] && <p className={styles.error}>{errors["startDate"]?.message as string}</p>}
      {errors["endDate"] && <p className={styles.error}>{errors["endDate"]?.message as string}</p>}
    </>
  );
}
