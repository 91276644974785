import { ModalPortal } from "pages/reward/@shared/other";
import { useState } from "react";
import styles from "styles/pages/reward/@shared/modal/errorModal.module.scss";

type Props = {
  error: unknown;
  buttonText?: string;
  onConfirmHandler?: () => void;
};

/**
 * ErrorModal - 에러 메세지를 모달 형식으로 보여줄 때 사용합니다.
 *
 * @component
 * @example
 * const props = {
 *  error: error,
 * }
 *
 * <ErrorModal {...props} />
 */
export default function ErrorModal({ error, buttonText = "닫기", onConfirmHandler }: Props) {
  const errorMessage =
    error instanceof Error && error.message ? error.message : `알 수 없는 오류가 발생하였습니다.\n 다시 시도해주세요.`;

  const [isClick, setIsClick] = useState(false);

  const handleClickConfirm = () => (onConfirmHandler ? onConfirmHandler() : setIsClick(true));

  if (!onConfirmHandler && isClick) return null;

  return (
    <ModalPortal>
      <div className={styles.errorModal}>
        <div className={styles.content}>
          <p>{errorMessage}</p>

          <button type="button" name="confirm" onClick={handleClickConfirm}>
            {buttonText}
          </button>
        </div>
      </div>
    </ModalPortal>
  );
}
