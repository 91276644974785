import styles from "./modal.module.scss";
import cx from "classnames";
import Button from "../Button";
import Portal from "./Portal";
import LoadingModal from "./LoadingModal";

type Props = {
  isLoading?: boolean;
  title?: string;
  description: React.ReactNode;
  cancelBtnName?: string;
  submitBtnName?: string;
  confirmBtnName?: string;
  btnType: "submit" | "confirm";
  handleClose: () => void;
  handleSubmit?: () => void;
  width?: string;
  height?: string;
};

/**
 * @description description에 개행이 필요한 경우 "\n"을 넣어주세요. 예) description={`Test\nTest`}
 */
export default function Modal({
  isLoading = false,
  title,
  submitBtnName = "확인",
  cancelBtnName = "취소",
  confirmBtnName = "확인",
  description,
  btnType,
  handleClose,
  handleSubmit,
  width = "440px",
  height,
}: Props) {
  const btnEle = {
    submit: (
      <>
        <Button type="button" mode="lineGray" onClick={handleClose}>
          {cancelBtnName}
        </Button>
        <Button type="submit" onClick={handleSubmit}>
          {submitBtnName}
        </Button>
      </>
    ),
    confirm: (
      <Button type="button" onClick={handleClose}>
        {confirmBtnName}
      </Button>
    ),
  }[btnType];

  return (
    <Portal>
      <div className={styles.dialog}>
        {!isLoading && (
          <div className={styles.content} style={{ width, height }}>
            <p>{title}</p>
            <div className={cx(styles.description, { [styles.isTitle]: title })}>{description}</div>

            <div className={styles.buttonBox}>{btnEle}</div>
          </div>
        )}

        {isLoading && <LoadingModal />}
      </div>
    </Portal>
  );
}
