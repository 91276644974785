import { TMintRequest, TMintResult } from "types/mintBurn";
import { Axios } from "./base";

export const manualMint = async (mintBody: TMintRequest) => {
  const response = await Axios.post<{ data: TMintResult }>(`/api/admin/manual-mint`, mintBody);

  return response.data.data;
};

export const manualBurn = async (mintBody: TMintRequest) => {
  const response = await Axios.post<{ data: TMintResult }>(`/api/admin/manual-burn`, mintBody);

  return response.data.data;
};
