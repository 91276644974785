import { Link, useNavigate, useSearchParams } from "react-router-dom";
import StatusChip from "./list/table/StatusChip";
import ChallengeTableTop from "./list/table/ChallengeTableTop";
import { Loading } from "common/other";
import { getCommasInNum } from "utils";
import styles from "styles/pages/contentsManage/challenge/challenge.module.scss";
import { Content, Title } from "comp-lib";
import { CHALLENGE_CATEGORY_ENG, CHALLENGE_COLUMNS } from "./constants";
import TableList from "./list/table/TableList";
import Pagination from "./list/Pagination";
import { useChallengeList } from "./list/useChallengeList";
import { TChallengeContentRes } from "types/contentsManage/challenge";

export default function Challenge() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const pageNum = Number(searchParams.get("pageNum")) || 1;
  const pageSize = Number(searchParams.get("size")) || 10;
  const pageStatus = searchParams.get("status") || "ALL";

  const { listData, countData, isError, isLoading } = useChallengeList();

  const handleRegisterNav = () => navigate("/contents/challenge/write");
  const contentsTBody = listData?.content?.map((item: TChallengeContentRes, idx: number) => {
    const { currentParticipantsCount, maxParticipantsCount, id, title, type, category } = item;
    const { startDate, endDate, status, goalParticipantsCount, spamCount } = item;

    const calcRate = ((goalParticipantsCount || 0) / currentParticipantsCount || 0) * 100;
    const responseRate = calcRate === Math.round(calcRate) ? calcRate : Number(calcRate.toFixed(2)); // 응답률이 나누어 떨어지지 않는 경우에만 소수점 2자리까지 표시
    const tableNumber = countData[pageStatus].num;
    return [
      idx + 1,
      tableNumber ? tableNumber - ((pageNum - 1) * pageSize + idx) : "",
      <StatusChip status={status} key={id} />,
      <span>{CHALLENGE_CATEGORY_ENG[category]}</span>,
      <div className={styles.title}>
        {type === "PROMOTION" && <StatusChip status={type} key={id} />}
        <Link to={`/contents/challenge/detail/${id}`}>{title || "-"}</Link>
      </div>,
      `${getCommasInNum(currentParticipantsCount)}/${getCommasInNum(maxParticipantsCount)}`,
      startDate ? `${startDate.replace(/-/g, ".")} ~ ${endDate.replace(/-/g, ".")}` : "-",
      <span key={id}>{goalParticipantsCount ? getCommasInNum(goalParticipantsCount) : "-"}</span>,
      <span key={id}>{`${responseRate}%`}</span>,
      spamCount ? (
        <Link to={`report?id=${id}`} state={status}>
          {spamCount}
        </Link>
      ) : (
        <span>-</span>
      ),
    ];
  });

  return (
    <Content>
      <Title text="그룹루틴 챌린지 목록" />

      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <ChallengeTableTop data={countData} />
          {!isError && isLoading ? (
            <Loading />
          ) : (
            <>
              <TableList columns={CHALLENGE_COLUMNS} dataList={contentsTBody || []} isError={isError} />

              <div className={styles.write_box}>
                <button type="button" onClick={handleRegisterNav}>
                  신규 등록
                </button>
              </div>

              <Pagination totalPages={listData?.totalPages || 1} totalElements={listData?.totalElements || 0} />
            </>
          )}
        </div>
      </div>
    </Content>
  );
}
