import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import CheckInput from "./CheckInput";
import { TLogin } from "types/login";

import styles from "styles/pages/login/login.module.scss";
import IconInput from "./IconInput";
import { MyDAdminLogoIcon } from "assets/svgs";
import { Modal } from "comp-lib";
import { LoginUserIcon } from "assets/svgs/icon";
import { setToken } from "utils/access";
import ROLE from "constants/role";
import { Axios } from "api/base";

export default function Login() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState({ title: "", description: "" });
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [login, setLogin] = useState<TLogin>({
    username: "",
    password: "",
  });

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setLogin({
      ...login,
      [name]: value,
    });
  };

  const handleSubmitForm = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (login.password && login.username) {
      try {
        const response = await axios.post("/api/auth/sign-in", {
          userName: login.username,
          password: login.password,
          rememberMe: isRememberMe,
        });

        const { data } = response.data;

        if (data.role.replace("ROLE_", "") === ROLE.B2B_ADMIN || data.role.replace("ROLE_", "") === ROLE.B2B_MANAGER) {
          setIsUser(true);
          return;
        }

        setToken(data.accessToken);
        navigate("/", { replace: true });
      } catch (error) {
        setErrorMessage({
          title: "로그인 중 오류가 발생했습니다.",
          description: "동일한 현상이 반복될 경우 고객센터로 문의해주세요.",
        });
      }
    } else {
      setErrorMessage({
        title: "",
        description: "로그인과 비밀번호를 모두 입력해주세요.",
      });
    }
  };

  const handleErrorClear = () => {
    setErrorMessage({ title: "", description: "" });
  };

  const handleUserLogin = async () => {
    await Axios.post("/api/auth/sign-out");
    setToken(null);
    window.location.href = window.B2B_URL || "/";
    setIsUser(false);
  };

  const handleInvalidClick = () => {
    navigate("/login", { replace: true });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <form className={styles.loginWrapper} onSubmit={handleSubmitForm}>
            <div className={styles.logo}>
              <MyDAdminLogoIcon />
              <span>Admin</span>
            </div>

            <div className={styles.info}>
              <LoginUserIcon />
              <h2>로그인</h2>
              <p>이메일과 패스워드를 통해 로그인 해주세요</p>
            </div>
            <div className={styles.loginInputBox}>
              <IconInput
                type="text"
                placeholder="E-mail"
                name="username"
                onChange={handleChangeInput}
                value={login.username}
                iconType="email"
              />

              <IconInput
                type="password"
                placeholder="PW"
                name="password"
                onChange={handleChangeInput}
                value={login.password}
              />

              <div className={styles.optionWrapper}>
                <CheckInput
                  name="auto-login"
                  text="자동로그인"
                  value={isRememberMe}
                  handleCheck={() => setIsRememberMe((prev) => !prev)}
                />
                <Link to="/password/find" className={styles.passwordFind}>
                  ID / PW 찾기
                </Link>
              </div>

              <button type="submit">로그인</button>
            </div>
          </form>
        </div>
      </div>

      {errorMessage.description && (
        <Modal
          title={errorMessage.title}
          btnType="confirm"
          handleClose={handleErrorClear}
          description={errorMessage.description}
          height="260px"
        />
      )}

      {isUser && (
        <Modal
          title={"본 계정은 관리자 계정이 아닙니다."}
          btnType="confirm"
          handleClose={handleUserLogin}
          description={"MYD CONNECT 페이지로 이동합니다."}
          height="240px"
        />
      )}

      {window.location.search.includes("invalid-token") && (
        <Modal
          title="로그인 만료"
          btnType="confirm"
          description={`로그인 상태가 만료되었습니다. \n보안을 위해 다시 로그인해 주세요.`}
          height="240px"
          handleClose={handleInvalidClick}
        />
      )}
    </>
  );
}
