import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import Search from "common/table/TableTop/Search";
import queryKey from "constants/queryKey";
import useModal from "hooks/useModal";
import { Button, Content, ErrorModal, Pagination, Panel, TableNav, Title, LoadingModal, Modal } from "comp-lib";
import { useTableData, useTableNavData, useCompanyFilter, CompanyTable } from ".";
import { changeAdminDisabledCompany, changeAdminEnabledCompany, getAdminCompanyList } from "api/admin/company";

import styles from "styles/pages/company/list/companyList.module.scss";

export default function CompanyList() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isErrorShowing, setIsErrorShowing] = useModal();

  // 기업 리스트 조회 API 연동
  const {
    data = [],
    isLoading,
    error: listError,
  } = useQuery([queryKey.GET_ADMIN_COMPANY_LIST], getAdminCompanyList, {
    onError: setIsErrorShowing,
  });

  const { dataForTableNav, dataForTableRows, totalPages } = useCompanyFilter(data);
  const { columns, rows, clickItem, setClickItem } = useTableData(dataForTableRows);
  const tableNavData = useTableNavData(dataForTableNav);

  const { id: clickId, name, enabled } = clickItem;

  // 기업 비활성화 상태로 변경
  const {
    mutate: disabledMutate,
    error: disabledError,
    isLoading: disabledIsLoading,
  } = useMutation(changeAdminDisabledCompany, {
    onSuccess: () => queryClient.invalidateQueries([queryKey.GET_ADMIN_COMPANY_LIST]),
    onError: setIsErrorShowing,
  });

  // 기업 활성화 상태로 변경
  const {
    mutate: enabledMutate,
    error: enabledError,
    isLoading: enabledIsLoading,
  } = useMutation(changeAdminEnabledCompany, {
    onSuccess: () => queryClient.invalidateQueries([queryKey.GET_ADMIN_COMPANY_LIST]),
    onError: setIsErrorShowing,
  });

  const handleCompanyEnabled = () => {
    if (enabled) disabledMutate(clickId); // 활성화 -> 비활성화
    if (!enabled) enabledMutate(clickId); // 비활성화 -> 활성화

    setClickItem({ id: 0, name: "", enabled: true }); // 클릭한 항목 데이터 초기화
  };

  const handleError = () => {
    listError && navigate("/");
    setIsErrorShowing();
  };

  if (isLoading || disabledIsLoading || enabledIsLoading) return <LoadingModal />;

  return (
    <Content>
      <Title text="기업 관리 목록" />

      <Panel className={styles.panel}>
        <div className={styles.tableTopWrapper}>
          <TableNav dataList={tableNavData} />
          <Search placeholder="기업명, DID, 대표 담당자명으로 검색하세요" searchIcon={false} />
        </div>

        <div className={styles.tableWrapper}>
          <CompanyTable columns={columns} rows={rows} />
        </div>

        <div className={styles.buttonBox}>
          <Button onClick={() => navigate("/company/create")}>기업 등록</Button>
        </div>

        <Pagination totalPages={totalPages} />
      </Panel>

      {/* Modals */}
      {clickId !== 0 && (
        <Modal
          title={`${enabled ? "비" : ""}활성화하겠습니까?`}
          description={`${name} 기업을 ${enabled ? "비" : ""}활성화하시겠습니까?\n ${
            enabled
              ? "해당 계정에 등록된 대표 담당자 계정도 함께 비활성화 됩니다."
              : "대표 담당자 설정까지 마쳐야 해당 기업 계정의 커넥트를 다시 사용할 수 있습니다."
          }`}
          btnType="submit"
          submitBtnName="확인"
          handleClose={() => setClickItem({ id: 0, name: "", enabled: true })}
          handleSubmit={handleCompanyEnabled}
        />
      )}

      {isErrorShowing && (
        <ErrorModal error={listError || disabledError || enabledError} onConfirmHandler={handleError} />
      )}
    </Content>
  );
}
