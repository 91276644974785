import { postUserEmail, postPasswordFind, postPasswordChange } from "api/login";
import { useMutation } from "react-query";

export function usePassword() {
  // 비밀번호 찾기
  const {
    mutate: passwordFindMutate,
    isLoading: isPasswordFindLoading,
    isError: isPasswordFindError,
    error: passwordFindError,
  } = useMutation(postPasswordFind);

  // 비밀번호 변경
  const {
    mutate: passwordChangeMutate,
    isLoading: isPasswordChangeLoading,
    isError: isPasswordChangeError,
    error: passwordChangeError,
  } = useMutation(postPasswordChange);

  // 비밀번호 변경 화면 유저 이메일
  const {
    data,
    mutate: userEmailMutate,
    isLoading: isUserLoading,
    isError: isUserError,
    error: userError,
  } = useMutation(postUserEmail);

  return {
    data,
    passwordFindMutate,
    passwordChangeMutate,
    userEmailMutate,
    isLoading: isUserLoading || isPasswordFindLoading || isPasswordChangeLoading,
    isError: isPasswordFindError || isUserError || isPasswordChangeError,
    error: userError || passwordFindError || passwordChangeError,
  };
}
