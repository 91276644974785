import styles from "styles/common/other/ProgressBar.module.scss";

interface Props {
  value: number;
  isTooltip?: boolean;
  height?: number;
  color?: "blue" | "green" | "sky";
}

export default function ProgressBar({ value = 0, height = 8, color = "blue", isTooltip = true }: Props) {
  const colorCode = {
    blue: "#1890ff",
    sky: "#1ab9de",
    green: "#52c41a",
  }[color];

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: `linear-gradient(to right, ${colorCode} ${value}%, #f0f0f0 0%, #f0f0f0 100%)`,
        height: `${height}px`,
      }}
    >
      {isTooltip && (
        <div className={styles.tooltip} style={{ left: `${value}%` }}>
          {value}%
        </div>
      )}
    </div>
  );
}
