import styles from "styles/pages/advertisement/detail/adSidebar/adPreviewCard.module.scss";
import selectImage from "assets/imgs/banner-mobile-preview.png";
import PreviewHeader from "./AdPreviewHeader";
import { translateTargetMap } from "constants/market";
import cx from "classnames";
import { formatDataType } from "./formatDataType";

type TProps = {
  cardData: {
    bannerImageCdn: string;
    title: string;
    rewardPolicyAmount: number;
    privacyState: { personalIdentification: "nonAnonymous" | "anonymous" };
    maxIssuable: number;
    collectTarget: string[];
    company: string;
    advertiseImage: string;
  };
};

export default function PreviewCard({ cardData }: TProps) {
  const {
    bannerImageCdn,
    title,
    rewardPolicyAmount,
    privacyState,
    maxIssuable,
    collectTarget,
    company,
    advertiseImage,
  } = cardData;
  const { personalIdentification: identify } = privacyState;

  const formatCollectTarget = () => {
    if (collectTarget.length === 0) return "수집데이터";
    return `${formatDataType(collectTarget)}데이터`;
  };

  const foramtPurposeType = () => {
    if (identify) {
      return identify === "anonymous" ? "익명거래" : "실명거래";
    }
    return "식별여부";
  };

  return (
    <div className={styles.wrapper}>
      <PreviewHeader />
      <img src={bannerImageCdn || selectImage} alt="배너 이미지" className={styles.bannerImage} />
      <div className={styles.infoWrapper}>
        <div className={styles.company}>{company}</div>
        <div className={styles.title}>{title || "타이틀을 선택해 주세요"}</div>
        <div className={styles.mockButtons}>
          <div className={styles.highlight}>
            {rewardPolicyAmount ? `${rewardPolicyAmount.toLocaleString()}P` : "지급포인트"}
          </div>
          <div className={cx({ [styles.highlight]: identify === "nonAnonymous" })}>{foramtPurposeType()}</div>
          <div>{maxIssuable ? `${maxIssuable.toLocaleString()}명` : "목표인원수"}</div>
          <div>{translateTargetMap[formatCollectTarget()] || formatCollectTarget()}</div>
        </div>
      </div>
      <div className={styles.tabWrapper}>
        <div>상세정보</div>
        <div>기업정보</div>
      </div>
      <div className={styles.advertiseWrapper}>
        <img src={advertiseImage} alt="광고 이미지" className={styles.advertiseImage} />
      </div>
    </div>
  );
}
