import { Controller, useFormContext } from "react-hook-form";
import cx from "classnames";

import DateTime from "./DateTime";
import { TBanner } from "types/banner";
import styles from "styles/pages/banner/bannerCreator.module.scss";
import NumInput from "../@tmp/NumInput";
import { FileInput } from "common/input";

export default function FormPurpose() {
  const {
    control,
    formState: { errors },
  } = useFormContext<TBanner>();

  return (
    <>
      <label className={styles.input_box} aria-invalid={errors.purpose ? "true" : "false"}>
        <div className={styles.title}>
          제안 목적
          <span className={styles.required}>*</span>
        </div>

        <div className={styles.content}>
          <Controller
            name="purpose"
            control={control}
            render={({ field: { value, onChange } }) => (
              <NumInput
                value={value || ""}
                placeholder="노출할 제안서 Status를 입력해 주세요."
                onChange={onChange}
                className={styles.title_text}
              />
            )}
            rules={{
              required: true,
              validate: (value) => value !== "0",
            }}
          />
        </div>
      </label>

      <div className={cx(styles.input_box, styles.tooltip_box)} aria-invalid={errors.imgFileStr ? "true" : "false"}>
        <div className={cx(styles.title, styles.tooltip_title)}>
          배너 이미지
          <span className={styles.required}>*</span>
        </div>

        <div className={cx(styles.content, styles.tooltip_content)}>
          <FileInput id="imgFileStr" aria-invalid={errors.imgFileStr ? "true" : "false"} limitRatio={[328, 80]} />

          <ul className={styles.tooltip}>
            <li>
              이미지는 jpg, jpeg, png 파일만 업로드 가능하며, 이미지 크기는 최소 328 X 80 px 입니다. 최소 3배수 이상
              크기로 등록해 주세요
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.input_box} aria-invalid={errors.startDateTime || errors.endDateTime ? "true" : "false"}>
        <div className={styles.title}>
          노출 기간
          <span className={styles.required}>*</span>
        </div>

        <div className={styles.content}>
          <Controller
            name="startDateTime"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => (
              <DateTime value={value} onChange={onChange} aria-invalid={errors.startDateTime ? "true" : "false"} />
            )}
          />
          <div>~</div>
          <Controller
            name="endDateTime"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => (
              <DateTime value={value} onChange={onChange} aria-invalid={errors.endDateTime ? "true" : "false"} />
            )}
          />
        </div>
      </div>
    </>
  );
}
