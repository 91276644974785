import { useNavigate } from "react-router-dom";
import styles from "styles/pages/contentsManage/challenge/@shared/errorContentPage.module.scss";

type TProps = {
  back?: boolean;
  reload?: boolean;
  message?: string;
};

export const ErrorContentPage = ({ back, reload, message }: TProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <p>앗!</p>
          <p>
            {message ||
              `페이지를 불러오던 중 오류가 발생했습니다.\n다시 시도하시고 동일한 문제가 반복될 경우 CS센터에 문의해 주세요.`}
          </p>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        {back && (
          <button className={styles.backButton} type="button" onClick={() => navigate(-1)}>
            뒤로가기
          </button>
        )}
        {reload && (
          <button className={styles.backButton} type="button" onClick={() => window.location.reload()}>
            새로고침
          </button>
        )}
      </div>
    </div>
  );
};
