import { DoubleAngleBracketIcon as Arrow } from "assets/svgs/icon";
import cx from "classnames";
import SidebarErrorBoundary from "./SidebarErrorBoundary";
import { useState } from "react";
import styles from "styles/pages/advertisement/detail/adSidebar/adSidebarLayout.module.scss";
import PreviewCard from "./AdPreviewCard";
import { AdminAdProposalData } from "types/advertisement";

type TSidebarProps = {
  defaultOpen?: boolean;
  getIsOpen?: (isOpen: boolean) => void;
  adData?: AdminAdProposalData;
};

export default function SidebarLayout({ getIsOpen, defaultOpen = true, adData }: TSidebarProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const handleSidebar = () => {
    getIsOpen && getIsOpen(!isOpen);
    setIsOpen((prev) => !prev);
  };

  const cardData = {
    bannerImageCdn: adData?.proposals.contentObject.imageURL.banner || "",
    title: adData?.advertisements.title || "",
    rewardPolicyAmount: adData?.proposals.rewardPolicyAmount || 0,
    privacyState: {
      personalIdentification:
        adData?.proposals.contentObject?.privacyPolicy?.identifier === 1
          ? ("nonAnonymous" as "nonAnonymous")
          : ("anonymous" as "anonymous"),
    },
    maxIssuable: adData?.proposals.maxIssuable || 0,
    collectTarget: adData?.proposals.selectDataList || [""],
    company: adData?.proposals.staff.company.name || "",
    advertiseImage: adData?.advertisements.fileUrl || "",
  };

  return (
    <div className={cx(styles.wrapper, { [styles.wrapperOpen]: isOpen })}>
      <button type="button" className={cx(styles.sidebarOpenBtn)} onClick={handleSidebar}>
        <Arrow className={cx({ [styles.closeArrow]: isOpen })} />
      </button>
      <SidebarErrorBoundary>
        <PreviewCard cardData={cardData} />
      </SidebarErrorBoundary>
    </div>
  );
}
