import { useEffect, useState } from "react";
import { FormProvider, SubmitErrorHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import ConfirmModal from "pages/contentsManage/vote/@shared/modal/ConfirmModal";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";
import ContPrMobile from "./mobile/ContPrMobile";
import Content from "./Content";
import Progress from "./Progress";
import SidebarLayout from "./mobile/SidebarLayout";

import { contPrFormAtom } from "recoil/vote";
import { TContPrForm } from "types/contentsManage/vote";
import { CATEGORY_DATA_MAP, TYPE_LIST } from "constants/vote";
import styles from "styles/pages/contentsManage/vote/write/contPrForm.module.scss";
import { useQuery } from "react-query";
import queryKey from "constants/queryKey";
import { getPollDetail } from "api/contentsManage/poll";
import { pollInfoCategoryToJSON } from "protobuf/OpenApiServerV3DataTypes";
import { Footer } from "comp-lib";
import VoteTitle from "../@shared/VoteTitle";
import { syncProgressPeriod } from "utils";
import dayjs from "dayjs";
/**
 * content, progress form
 */
export default function ContPrForm() {
  const pollId = useSearchParams()[0].get("pollId");
  const [contPrForm, setContPrForm] = useRecoilState(contPrFormAtom);
  const { state } = useLocation();

  const { data } = useQuery([queryKey.GET_POLL_DETAIL, pollId], () => getPollDetail(pollId!), {
    enabled: !!pollId,
  });

  const detailData = data?.pollReport;

  const formMethods = useForm<TContPrForm>({
    shouldFocusError: false,
    defaultValues: contPrForm,
  });

  // 투표 수정을 위한 데이터를 불러온다
  useEffect(() => {
    if (!detailData || detailData.status !== 2 || state) return;

    const REVERSE_CATEGORY_DATA_MAP = Object.fromEntries(
      Object.entries(CATEGORY_DATA_MAP).map(([key, value]) => [value, key]),
    );
    const questionType = (bool: boolean) => (bool ? "single" : "multi");
    const defaultValues: TContPrForm = {
      type: TYPE_LIST[0],
      question: state?.multiYn
        ? questionType(state.multiYn)
        : questionType(detailData.pagesQuestionsCnt.split("|")[1] === "1"),
      category: state?.category
        ? REVERSE_CATEGORY_DATA_MAP[state.category]
        : REVERSE_CATEGORY_DATA_MAP[pollInfoCategoryToJSON(detailData.category)],
      title: state?.title || detailData.title,
      content: state?.contents || detailData.contents,
      imgFileString: state?.imgLink || detailData.imgLink,
      startDate: state?.startDate.substring(0, 10) || detailData.startDate.split("T")[0],
      endDate: state?.endDate.substring(0, 10) || syncProgressPeriod(dayjs(detailData.endDate)).format("YYYY-MM-DD"),
      filter: {
        gender: "",
        age: "",
        area: "",
      },
      perPersonPnt: state?.perAmount || detailData.perAmount,
      goalPeopleCnt: state?.maxIssuable || detailData.maxIssuable,
    };
    formMethods.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  const navigate = useNavigate();

  const onSubmit = (info: TContPrForm) => {
    if (Number(info.perPersonPnt) * Number(info.goalPeopleCnt) > 1000000000) {
      setErrorMsg("총 사용 예정 포인트를 1,000,000,000 (10억) 포인트 이내로\n설정해 주세요");
      return;
    }
    setContPrForm(info);
    const queCntType = formMethods.getValues("question");
    if (queCntType === "multi") {
      navigate(`/contents/vote/writeMultiQuestion`, {
        state: state || detailData,
      });
    } else {
      navigate(`/contents/vote/writeSingleQuestion`, {
        state: state || detailData,
      });
    }
  };

  const [isModal, setIsModal] = useState(false);
  const handleExit = () => setIsModal(true);
  const handleModalClose = () => setIsModal(false);
  const handleModalConfirm = () => {
    setIsModal(false);
    navigate("/contents/vote");
  };

  const [errorMsg, setErrorMsg] = useState("");
  const handleError: SubmitErrorHandler<TContPrForm> = (e) => {
    const erorrMessages = Object.entries(e)[0][1]?.message || "필수 항목을 모두 입력해 주세요.";
    setErrorMsg(erorrMessages);
  };
  const handleErrorModalClose = () => setErrorMsg("");

  return (
    <FormProvider {...formMethods}>
      <form className={styles.wrapper} id="cont-pr" onSubmit={formMethods.handleSubmit(onSubmit, handleError)}>
        <section>
          <VoteTitle text="콘텐츠 설정" page={1} />
          <Content />
        </section>

        <section>
          <VoteTitle text="진행 설정" page={2} />
          <Progress />
        </section>
      </form>

      <Footer className={styles.btn_box}>
        <button type="button" className={styles.out} onClick={handleExit}>
          나가기
        </button>
        <button type="submit" className={styles.next} form="cont-pr">
          다음
        </button>
      </Footer>

      <SidebarLayout>
        <ContPrMobile />
      </SidebarLayout>

      {isModal && (
        <ConfirmModal
          message="페이지를 나가시겠습니까?"
          subMessage="작성 중인 내용은 저장되지 않습니다."
          onCancle={handleModalClose}
          onConfirm={handleModalConfirm}
        />
      )}
      {errorMsg && <NotiModal message={errorMsg} onClose={handleErrorModalClose} />}
    </FormProvider>
  );
}
