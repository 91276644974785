import styles from "styles/pages/mydInquiry/detail/mydInquiryComments.module.scss";

import { Panel } from "comp-lib";

import InquiryComment from "./MydInquiryComment";

import { TMydInquiryComment, TMydInquiryDetail } from "types/mydInquiry";
import { ProfileBlueIcon } from "assets/svgs/icon";
import { getDateFormat } from "utils/appPush";

const STATUS_MAP: { [key: string]: string } = {
  DONE: "완료",
  REGISTERED: "접수",
  PROCESSING: "진행중",
  INREVIEW: "검토중",
};

type TProps = {
  inquiryData: TMydInquiryDetail;
  commentsData: TMydInquiryComment[];
};

export default function MydInquiryComments({ inquiryData, commentsData }: TProps) {
  const activceComments = commentsData?.filter((e) => e.activated === true);

  const isDone = inquiryData.status === "DONE";
  const isNew = !isDone && activceComments.length === 0;

  return (
    <Panel>
      <h2 className={styles.title}>
        {inquiryData.id ? `[${STATUS_MAP[inquiryData.status]}] ${inquiryData.title}` : "존재하지 않는 문의"}
      </h2>

      <div className={styles.comment}>
        <div className={styles.profile}>
          <ProfileBlueIcon />
          <span>사용자 문의</span>
          <span>{getDateFormat(inquiryData.receivedAt)}</span>
          {isNew && <span className={styles.new}>(new)</span>}
        </div>

        <div className={styles.contents}>{inquiryData.contents}</div>
      </div>

      {activceComments.length !== 0 && <div className={styles.line} />}

      {activceComments?.map((comment, idx) => (
        <div key={idx}>
          <InquiryComment
            data={comment}
            inquiryStatus={inquiryData.status}
            isLastComment={idx === activceComments.length - 1}
          />
          {idx !== activceComments.length - 1 && <div className={styles.line} />}
        </div>
      ))}
    </Panel>
  );
}
