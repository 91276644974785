import { ChangeEventHandler, ComponentProps, useEffect, useState } from "react";
import { CheckboxEmtIcon, CheckboxSkyIcon } from "assets/svgs/vote";
import cx from "classnames";
import styles from "styles/pages/contentsManage/vote/listCheckBox.module.scss";

type TProps = ComponentProps<"input"> & {
  handleCheckedBtns: (btns: string[]) => void;
  checkedBtns: string[];
};

export default function ListCheckBox({ handleCheckedBtns, checkedBtns, disabled, ...props }: TProps) {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.value === e.currentTarget.id) {
      handleCheckedBtns([...checkedBtns, e.currentTarget.id]);
    }

    if (checkedBtns.includes(e.currentTarget.id)) {
      handleCheckedBtns(checkedBtns.filter((item) => item !== e.currentTarget.id));
    }

    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (checkedBtns.length === 0) setIsChecked(false);
  }, [checkedBtns]);

  return (
    <label className={cx(styles.wrapper, disabled ? styles.disabled_box : "")}>
      {isChecked ? <CheckboxSkyIcon /> : <CheckboxEmtIcon />}

      <input onChange={handleChange} type="checkbox" disabled={disabled} {...props} hidden />
    </label>
  );
}
