import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import ErrorModal from "./components/ErrorModal";
import { FileInput } from "common/input";
import { postQuickMenu, updateQuickMenu } from "api/quickMenu";
import { uploadImgApi } from "api/common";
import { useAccessRole } from "hooks/useAccessRole";
import { TQuickMenuItem } from "types/quickMenu";
import { onKeyDownPreventOnClick } from "utils";
import queryKey from "constants/queryKey";
import { Button, Panel, Title, Portal } from "comp-lib";
import { getErrorMeg } from "api/errorHandling";
import ErrorCode from "constants/errorcode";

import { QuestionMarkIcon } from "assets/svgs/quickMenu";
import styles from "styles/pages/quickMenu/quickMenuEditor.module.scss";

type TProps = {
  handleClick: () => void;
  mode: "create" | "edit";
  item?: TQuickMenuItem;
  length?: number;
  setItems: Dispatch<SetStateAction<TQuickMenuItem[]>>;
  menuNameArr: string[];
};

type TQuickMenu = {
  menuName: string;
  menuImg: File | string;
};

export default function QuickMenuEditor({ handleClick, mode, item, length, setItems, menuNameArr }: TProps) {
  const queryClient = useQueryClient();
  const { data: accessRole } = useAccessRole();

  const [errorMsg, setErrorMsg] = useState("");

  const isCreateMode = mode === "create"; // 등록모드
  const isEditMode = mode === "edit"; // 수정모드

  const methods = useForm<TQuickMenu>({
    defaultValues: {
      menuName: item?.menuName,
      menuImg: item?.menuImg,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = methods;

  // 퀵메뉴 추가
  const {
    mutate: postMutation,
    error: postError,
    isError: isPostError,
  } = useMutation(postQuickMenu, {
    onSuccess: () => {
      handleClick();
      queryClient.invalidateQueries([queryKey.GET_QUICK_MENU_LIST]);
    },
  });

  // 퀵메뉴 수정
  const {
    mutate: updateMutation,
    error: updateError,
    isError: isUpdateError,
  } = useMutation(updateQuickMenu, {
    onSuccess: ({ data: { menuName, menuImg } }) => {
      handleClick();

      /**
       * 순서변경 후 저장하지 않고 정보 수정하는 경우 변경한 순서는 유지되어야 한다
       * 만약 쿼리무효화를 할 경우 마지막 저장된 순서로 돌아가게 된다
       * => 쿼리무효화 대신 변경한 순서를 유지하면서 변경된 메뉴 이름, 이미지로 변경하여 보여준다
       */
      setItems((prevCards: TQuickMenuItem[]) =>
        prevCards.map((card) => (card.menuNo === item?.menuNo ? { ...card, menuName, menuImg } : card)),
      );
    },
  });

  // 이미지 업로드
  const { mutateAsync: uploadImgMutation, error: imgError, isError: isImgError } = useMutation(uploadImgApi);

  const onSubmit = async (input: TQuickMenu) => {
    const { menuImg, menuName } = input;
    const menuNameArray = menuNameArr.filter((el) => el !== item?.menuName);

    // api 호출 전 프론트에서 먼저 이미 존재하는 이름인지 체크
    if (menuNameArray.includes(menuName)) {
      setErrorMsg(getErrorMeg(ErrorCode.ERR_ALREADY_QUICK_MENU));
      return;
    }

    const settingSeq = (): number => {
      if (isCreateMode && length) return length + 1;
      if (isEditMode && item?.seq) return item.seq;
      return 0;
    };

    const handleMutation = (body: { menuImg: string; seq: number; menuName: string }) => {
      if (isCreateMode) postMutation(body);
      else if (isEditMode) updateMutation({ ...body, id: item?.menuNo! });
    };

    // 1. 이미 이미지가 cdn 주소인 경우 -> 따로 이미지 업로드할 필요 없음
    if (typeof menuImg === "string") {
      const body = { ...input, menuImg, seq: settingSeq() };
      handleMutation(body);
    }

    // 2. 이미지 타입이 File -> string cdn url로 변경 api 호출
    if (typeof menuImg === "object") {
      if (!accessRole) return;

      const { did, token } = accessRole;
      const imgBody = { did, token, file: menuImg };

      await uploadImgMutation(imgBody, {
        onSuccess: (el) => {
          const body = { ...input, menuImg: el.fullUrlPath, seq: settingSeq() };
          handleMutation(body);
        },
      });
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const MAX_LENGTH = 5;
    const value = e.target.value.replace(/\s/g, "");
    const blankLength = e.target.value.length - value.length;

    // 공백 포함 최대 6자 | 공백 없으면 5자까지 입력 가능
    e.target.maxLength = MAX_LENGTH + (blankLength > 0 ? 1 : 0);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimValue = e.target.value.trim();
    setValue("menuName", trimValue, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <Portal>
      <div className={styles.wrapper} role="presentation">
        <FormProvider {...methods}>
          <form
            className={styles.container}
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          >
            <Title text={`퀵메뉴 ${isEditMode ? "수정" : "등록"}`} />

            <Panel className={styles.panel_container}>
              <input
                placeholder="공백 제외 5자, 공백 포함 6자 까지 입력 가능"
                onInput={handleInput}
                {...register("menuName", { required: true, onBlur: handleBlur })}
              />

              <div className={styles.icon_box}>
                <span>퀵메뉴 아이콘</span>
                <FileInput id="menuImg" accept=".png, .jpg, .jpeg" limitRatio={[1, 1]} limitPxSize={[60, 4]}>
                  파일 선택
                </FileInput>
              </div>
            </Panel>

            <div className={styles.explanation}>
              <QuestionMarkIcon />
              <span>
                {`등록이미지 사이즈 규격은 1:1 비율로 최소 60 x 60 px 사이즈부터 해당 사이즈의 4배수까지 업로드 가능합니다.\n또한, PNG, JPG, JPEG 확장자 파일만 업로드 가능합니다.`}
              </span>
            </div>

            <div className={styles.btn_box}>
              <Button type="button" mode="lineGray" onClick={handleClick}>
                취소
              </Button>
              <Button disabled={!watch("menuName") || !watch("menuImg") || !isDirty} {...onKeyDownPreventOnClick}>
                저장
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>

      {(!!errorMsg || isPostError || isUpdateError || isImgError) && (
        <ErrorModal
          error={postError || updateError || imgError}
          message={errorMsg || (isImgError ? `이미지 업로드에 실패하였습니다.\n잠시 후 다시 시도해주세요` : "")}
          onConfirmHandler={errorMsg ? () => setErrorMsg("") : undefined}
        />
      )}
    </Portal>
  );
}
