import { ReactNode, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cx from "classnames";
import { ArrowLeftIcon } from "assets/svgs";
import styles from "styles/pages/navAccordionMenu.module.scss";

type TProps = {
  title: {
    name: string;
    icon: ReactNode;
  };
  content: {
    path: string;
    name: string;
  }[];
};

/**
 * NavLink를 사용하고 있습니다.
 */
export default function NavAccordionMenu({ title, content }: TProps) {
  const { pathname } = useLocation();
  const isPathIncluded = content.some((item) => pathname.includes(item.path));

  const [isOpen, setIsOpen] = useState(isPathIncluded);
  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <div className={styles.wrapper}>
      <NavLink
        to={content[0].path}
        className={cx(styles.title, { [styles.active]: isOpen }, { [styles.included]: isPathIncluded })}
        onClick={handleToggle}
      >
        <div className={styles.title_content}>
          {title.icon} <span>{title.name}</span>
        </div>

        <div className={styles.arrow}>
          <ArrowLeftIcon />
        </div>
      </NavLink>

      {isOpen && (
        <ul className={styles.content}>
          {content.map(({ path, name }) => (
            <li key={name}>
              <NavLink
                to={path}
                className={({ isActive }) => (isActive ? `${styles.accItem} ${styles.activeItem}` : styles.accItem)}
              >
                <div className={styles.name}>{name}</div>

                <div className={styles.arrow_right}>
                  <ArrowLeftIcon />
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
