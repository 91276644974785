import styles from "./detailInfo.module.scss";

type TProps = {
  dataList: Array<
    { name: string; value: string | JSX.Element | undefined } | Array<{ name: string; value: string | JSX.Element }>
  >;
};

export default function DetailInfo({ dataList }: TProps) {
  return (
    <div className={styles.wrapper}>
      <dl>
        {dataList.map((data) => {
          if (Array.isArray(data)) {
            return (
              <div key={data[0].name} className={styles.info}>
                {data.map((item) => (
                  <div key={`${item.name}-child`}>
                    <dt>{item.name}</dt>
                    <dd>{item.value}</dd>
                  </div>
                ))}
              </div>
            );
          }

          return (
            <div key={data.name} className={styles.info}>
              <dt>{data.name}</dt>
              <dd>{data.value}</dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
}
