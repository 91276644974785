import cx from "classnames";
import styles from "./statusChip.module.scss";

type TProps = {
  text: string;
  color: "red" | "orange" | "yellow" | "green" | "cyan" | "blue" | "magenta" | "gray";
};

/**
 * @red 종료
 * @orange 요청취소, 철회
 * @yellow 승인대기, 예정
 * @green 발행완료
 * @cyan 승인요청
 * @blue 진행중
 * @magenta 반려, 승인거절
 * @gray 임시저장
 */
export default function StatusChip({ text, color }: TProps) {
  return <div className={cx(styles.wrapper, styles[color])}>{text}</div>;
}
