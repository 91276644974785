import { TPollMultiQuestion } from "types/contentsManage/vote";
import { formatMultiQuestionAnswer } from "./pollFormatter";

export const validateCorrectAns = (question: TPollMultiQuestion, point: number): string | false => {
  const questionAnswer = formatMultiQuestionAnswer(question);
  const questionTypes = questionAnswer.map((outer) => outer.map((inner) => inner.question_type)).flat();

  // 정답 선택형 질문이 아닐 때 검증하지 않음
  if (questionTypes.findIndex((v) => ["3", "4"].includes(v)) === -1) return false;
  const pointArray = question.flatMap((page) => page.contents.map((answer) => answer.point));
  const pointSum = pointArray.reduce((a, b) => Number(a) + Number(b), 0);

  for (const que of questionAnswer.flat()) {
    if (que.question_type === "3" && que.correct_ans === "")
      return "정답이 지정되지 않은 단일 선택 문항이 있습니다.\n반드시 1개의 정답을 지정해주세요.";
    if (que.question_type === "4" && !que.correct_ans.includes(","))
      return "정답이 지정되지 않은 복수 선택 문항이 있습니다.\n반드시 2개 이상의 정답을 지정해주세요";
  }
  if (pointArray.findIndex((v) => v === "" || Number(v) === 0) !== -1)
    return "해당 질문에 할당할 포인트를 입력해 주세요";
  if (pointArray.join("").includes("."))
    return "포인트 입력칸에는 소수점을 입력할 수 없습니다.\n정수로 다시 입력해 주세요.";
  if (
    questionTypes.filter((v) => ["3", "4"].includes(v)).length !== 0 &&
    questionTypes.length !== questionTypes.filter((v) => ["3", "4"].includes(v)).length
  ) {
    return "정답 선택형은 다른 질문 타입과\n함께 사용할 수 없습니다.";
  }
  if (pointSum !== +point) {
    return "할당한 포인트의 총합이 기존에 설정한\n1인당 지급 포인트 금액보다 크거나 작습니다.\n설정한 금액과 동일한 총합이 되도록 입력해 주세요.";
  }
  return false;
};
