import { AdminAdProposalListData } from "types/advertisement";
import dayjs from "dayjs";
import { Link, useSearchParams } from "react-router-dom";
import StatusChip from "./StatusChip";

export default function useDataToTable(data?: AdminAdProposalListData["index"]) {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("pageNum") ? Number(searchParams.get("pageNum")) : 1;
  const tableData: {
    columns: (string[] | string)[];
    dataList: never[] | (string | number | JSX.Element | null)[][];
  } = {
    columns: [["NO", "78px"], ["상태", "106px"], ["기업명", "140px"], ["유형", "140px"], "제목", ["노출기간", "316px"]],
    dataList: [],
  };
  if (!data) return tableData;
  const adList = data;
  // 테이블 생성
  tableData.dataList = adList.slice((page - 1) * 10, page * 10)?.map((item, idx: number) => [
    item.id,
    +adList.length - idx - (page - 1) * 10,
    <StatusChip status={item.status} key={item.id} approved={item.approved} />,
    item.loc,
    "배너광고",
    <Link key={item.id} to={`detail/${item.id}`}>
      {item.title}
    </Link>,
    item?.notBefore && item?.notAfter
      ? `${dayjs(item.notBefore).format("YYYY.MM.DD")} ~ ${syncProgressPeriod(dayjs(item.notAfter)).format(
          "YYYY.MM.DD"
        )}`
      : "-",
  ]);

  return tableData;
}

function syncProgressPeriod(date: dayjs.Dayjs, type?: "decode") {
  /** 거래 등록 시 진행기간이 23:59 까지여야 하기 때문에 서버에서 임의로 날짜를 + 1 해서 저장합니다.
   *  때문에 보여줄 때에는 서버에서 받아온 진행기간 notAfter에서 하루를 빼줘야 합니다.  */
  return date.subtract(type === "decode" ? -1 : 1, "day");
}
