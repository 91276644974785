// import styles from "styles/pages/banner/groupBannerDetail.module.scss";
import BannerGroupCreator from "./create/BannerGroupCreator";
import { useLocation, useParams } from "react-router-dom";
import { SavedBuilableResource } from "protobuf/OpenApiServerV3";
import { TBanner, TBannerGroup } from "types/banner";
import GroupBannerFinal from "./final/GroupBannerFinal";

export default function GroupBannerDetail() {
  const location = useLocation();
  const { no } = useParams();
  const groupBanner = location.state.groupBanner as SavedBuilableResource;
  // console.log(id);
  // console.log(groupBanner);
  const { isFinal, isPublished } = groupBanner;

  const bannerList = groupBanner.homeBanner?.cards.map((item) => {
    const result: TBanner = {
      id: Math.random().toString(36).substr(2, 9),
      type: convertNumberToTypeOrDevice(item.type),
      device: convertNumberToTypeOrDevice(item.supportedPlatforms),
      title: item.title,
      isVisibleTitle: item.titleVisible === true ? "true" : "false",
      imgFileStr: item?.bannerImageUrl || "",
      url: item?.landingPageUrl,
      isToken: item?.requiresAccessTokenInGetMethod && item.requiresAccessTokenInGetMethod === true ? "true" : "false",
      startDateTime: item?.notBefore,
      endDateTime: item?.notAfter,
      proposalId: item?.proposalId,
      purpose: item?.purpose === undefined ? undefined : item.purpose.toString(),
    };

    return result;
  });
  const data: TBannerGroup = {
    resourceNo: groupBanner.resourceNo,
    groupName: groupBanner.title,
    bannerList: bannerList || [],
    createdAt: groupBanner.createdAt,
    updatedAt: groupBanner.updatedAt,
    isPublished,
  };

  return (
    <>
      {!isFinal && <BannerGroupCreator data={data} />}
      {isFinal && <GroupBannerFinal data={data} />}
    </>
  );
}

function convertNumberToTypeOrDevice(num: number) {
  if (num === 2) {
    return "2";
  }

  if (num === 3) {
    return "3";
  }

  return "1";
}
