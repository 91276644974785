import cx from "classnames";
import { Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from "styles/pages/notice/DateInput.module.scss";
import { NoticeDate } from "types/notice";
import { getOnlyNumber } from "utils";

interface Props {
  watchName: string;
  isDateTime?: boolean;
  isError?: boolean;
  requiredMsg: string;
  validateFn: (data: NoticeDate) => string | boolean;
}

const time = [
  {
    keyword: "h",
    label: "시",
  },
  {
    keyword: "m",
    label: "분",
  },
];

/**
 * DateInput - 날짜 및 시간 input 컴포넌트
 *
 * @property {string} watchName - formdata에 값을 주입시키기 위함
 * @property {boolean} isDateTime - 시간을 같이 입력 받을 것인지 유무
 *
 * @example
 * ```tsx
 * <DateInput watchName="notBefore" isDateTime />
 * ```
 */
export default function DateInput({ watchName, isDateTime = false, isError = false, requiredMsg, validateFn }: Props) {
  const { control, watch } = useFormContext();

  const dateData = watch(watchName);

  const getFormattedDateInput = (name: string, value: string) => {
    return { ...dateData, [name]: value };
  };

  return (
    <Controller
      name={watchName}
      control={control}
      rules={{ required: requiredMsg, validate: validateFn }}
      render={({ field: { onChange } }) => (
        <>
          <input
            className={cx(styles.wrapper, { [styles.errTextInput]: isError })}
            type="date"
            max="2999-12-31"
            name="date"
            onChange={(e) => onChange(getFormattedDateInput(e.target.name, e.target.value))}
          />

          {isDateTime &&
            time.map((t) => (
              <Fragment key={`${watchName}-${t.keyword}`}>
                <input
                  type="text"
                  placeholder={t.keyword}
                  className={cx({ [styles.errTextInput]: isError })}
                  maxLength={2}
                  name={t.keyword}
                  onChange={(e) => onChange(getFormattedDateInput(e.target.name, e.target.value))}
                  onInput={(e) => {
                    e.currentTarget.value = String(getOnlyNumber(e.currentTarget.value));
                  }}
                />
                <span>{t.label}</span>
              </Fragment>
            ))}
        </>
      )}
    ></Controller>
  );
}
