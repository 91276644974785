import { IPagination } from "types/common";
import { NoticeData, NoticeInfo } from "types/notice";
import { Axios } from "../base";
import { uploadApi } from "api/common";

export const writeNoticeContent = async (data: NoticeData): Promise<boolean> => {
  const res = await Axios.post("/api/admin/notice/write", { ...data });

  return res.data.data;
};

export const getNoticeInfo = async (id: number): Promise<NoticeInfo> => {
  const res = await Axios.get(`/api/admin/notice/detail?id=${id}`);

  return res.data.data.notice;
};

export const setNoticeActivated = async (id: number): Promise<boolean> => {
  const res = await Axios.patch("/api/admin/notice/activation", { id });

  if (res.data.data?.result) return true;

  return false;
};

interface Param {
  number: number;
  searchKeyword?: string;
}

export const getNoticeList = async ({ number, searchKeyword }: Param): Promise<IPagination> => {
  const res = await Axios.post("/api/admin/notice", { number, size: 10, searchKeyword });

  return res.data.data;
};

export const deleteNotice = async (id: number): Promise<boolean> => {
  const res = await Axios.patch(`/api/admin/notice/deletion?id=${id}`);

  if (res.data.data.result) return true;

  return false;
};

export const uploadNoticeFileApi = async (body: { did: string; token: string; file: File; noticeType: string }) => {
  const { did, token, file, noticeType } = body;
  const formData = new FormData();

  formData.append("file", file);
  formData.append("type", "snplab_notice");
  formData.append("category", `notice_${noticeType}`);
  formData.append("did", did);

  return uploadApi(token, formData, `${window.API_URL}/b2b/fileUpload`);
};
