import { TCompanyListItem } from "types/company";

export const companyStatusMap: Record<number, string[]> = {
  0: ["활성", "enabled"],
  1: ["비활성", "disabled"],
};

export default function useTableNavData(data: TCompanyListItem[]) {
  return [
    {
      label: "전체",
      status: "all",
      count: data.length,
      numeral: "",
    },
    {
      label: "활성",
      status: companyStatusMap[0][1],
      count: data.filter((el) => el.enabled).length,
    },
    {
      label: "비활성",
      status: companyStatusMap[1][1],
      count: data.filter((el) => !el.enabled).length,
    },
  ];
}
