import { Content, Title } from "comp-lib";
import { useState } from "react";
import MyCheck from "./MyCheck";
import MyInfo from "./MyInfo";

export default function Mypage() {
  const [checkMessage, setCheckMessage] = useState("");
  const isPwdCheck = checkMessage === "OK";

  const getCheckMessage = (message: string) => {
    message && setCheckMessage(message);
  };

  return (
    <div>
      {isPwdCheck ? (
        <MyInfo />
      ) : (
        <Content>
          <Title text="마이페이지" />
          <MyCheck getCheckMessage={getCheckMessage} />
        </Content>
      )}
    </div>
  );
}
