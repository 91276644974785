import { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Content, Title, Panel, Table, Pagination, Button, ErrorModal, LoadingModal, Modal } from "comp-lib";

import TableCnt from "./TableCnt";
import useFilterData from "./useFilterData";
import useTableData from "./useTableData";
import { getBannerList, removeBannerGroup } from "api/banner";
import styles from "styles/pages/banner/groupBannerList.module.scss";

export default function GroupBannerList() {
  const {
    data = [],
    isError,
    refetch,
    isLoading,
  } = useQuery(["getBannerList"], getBannerList, {
    // cacheTime: 5 * 60 * 1000,
    // staleTime: 5 * 60 * 1000,
    keepPreviousData: true,
  });

  const removeGroupMuta = useMutation(removeBannerGroup, {
    onError: () => {
      handleRemoveModalClose();
    },
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [isModal, setIsModal] = useState(false);
  const noRef = useRef(-1);

  const handleRemoveModalOpen = (no: number) => {
    setIsModal(true);
    noRef.current = no;
  };

  const handleRemoveModalClose = () => {
    setIsModal(false);
    noRef.current = -1;
  };

  const handleRemove = async () => {
    await removeGroupMuta.mutateAsync(noRef.current);
    refetch();
    handleRemoveModalClose();
  };

  const { dataForTableRows, totalPages } = useFilterData(data);
  const { columns, rows } = useTableData(dataForTableRows, handleRemoveModalOpen);

  if (isLoading) return <LoadingModal />;

  return (
    <>
      <Content>
        <Title text="홈 배너 그룹 목록" />

        <Panel>
          <div className={styles.header}>
            <TableCnt text={`전체 ${data.length}건`} />
          </div>

          <Table columns={columns} rows={rows} />

          <div className={styles.footer}>
            <Button as={Link} to={`create${location.search}`} size="medium">
              그룹 생성
            </Button>
          </div>

          <Pagination totalPages={totalPages} />
        </Panel>

        {isModal && (
          <Modal
            title="배너 그룹을 삭제하시겠습니까?"
            description="등록된 배너 그룹이 삭제됩니다."
            btnType="submit"
            handleSubmit={handleRemove}
            handleClose={handleRemoveModalClose}
          />
        )}
      </Content>

      {isError && (
        <ErrorModal
          message={`홈 배너 그룹 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`}
          onConfirmHandler={() => navigate("/")}
        />
      )}

      {removeGroupMuta.isError && (
        <ErrorModal message="서버에 문제가 발생했습니다." onConfirmHandler={() => removeGroupMuta.reset()} />
      )}
    </>
  );
}
