import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import styles from "styles/pages/home/home.module.scss";

import { Panel, Title, Content } from "comp-lib";

import { getCompanyDetail } from "api/company";
import { getRechargeList } from "api/recharge";
import { getAdminAdProposalList } from "api/admin/advertisement";
import { getTransList } from "api/transaction";

import { useFilterAdTableData, useFilterAdTableNav } from "pages/advertisement/useFilterAdTable";
import useFilterData from "pages/transaction/useFilterData";
import useTableNavData from "pages/transaction/useTableNavData";

import { RewardImg, AdImg, TransactionImg, InquiryImg } from "assets/imgs/home";
import { SingleAngleBracketIcon } from "assets/svgs/icon";
import { MyDAdminOldLogoIcon } from "assets/svgs";
import queryKey from "constants/queryKey";

export default function Home() {
  const navigate = useNavigate();

  const { data: companyData, isLoading: isCompanyLoading } = useQuery([queryKey.GET_DETAIL_COMPANY], getCompanyDetail);
  const { data: rewardData = DEFAULT_DATA, isLoading: isRewardLoading } = useQuery(
    ["getRechargeList", 1, "REQUEST"],
    () => getRechargeList({ pageNum: Number(1), status: "REQUEST" }),
    {
      keepPreviousData: true,
    },
  );

  const { data: adData } = useQuery("getAdminAdProposalList", getAdminAdProposalList);
  const { index } = adData || {};
  const { size } = useFilterAdTableData(index, NAV_MAP);
  const tableNavData = useFilterAdTableNav(NAV_MAP, size);

  const { data: transactionData = [], isLoading } = useQuery(["getTransList"], getTransList);
  const { dataForTableNav } = useFilterData(transactionData);
  const filteredTransaction = useTableNavData(dataForTableNav);

  const userName = companyData?.staffName;
  const rewardCount = rewardData.requestCounts.countPending;
  const adCount = tableNavData.대기?.num;
  const transactionCount = filteredTransaction[1].count;

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <Content>
      {!isCompanyLoading && <Title text={`안녕하세요 ${userName || ""}님`} />}
      {!isRewardLoading && (
        <>
          <Panel className={styles.panel}>
            <div className={styles.textBox}>
              <MyDAdminOldLogoIcon className={styles.logo} />
              <h1>새로운 데이터 거래의 시작</h1>
              <p>마이데이터 플랫폼</p>
            </div>
          </Panel>
          <div className={styles.contentBox}>
            <Panel className={styles.panelSmall}>
              <img src={RewardImg} className={styles.img} alt="reward" />
              <p className={styles.text}>
                포인트
                <br />
                충전요청
              </p>
              <p className={styles.count}>{`${rewardCount}건`}</p>
              <button type="button" onClick={() => handleNavigate("point/approve")}>
                <SingleAngleBracketIcon />
              </button>
            </Panel>

            <Panel className={styles.panelSmall}>
              <img src={AdImg} className={styles.img} alt="advertisement" />
              <p className={styles.text}>
                광고
                <br />
                승인요청
              </p>
              <p className={styles.count}>{`${adCount || 0}건`}</p>
              <button type="button" onClick={() => handleNavigate("event/advertise")}>
                <SingleAngleBracketIcon />
              </button>
            </Panel>

            <Panel className={styles.panelSmall}>
              <img src={TransactionImg} className={styles.img} alt="transaction" />
              <p className={styles.text}>
                거래
                <br />
                진행중
              </p>
              <p className={styles.count}>{`${!isLoading ? transactionCount : "-"}건`}</p>
              <button type="button" onClick={() => handleNavigate("service/transaction")}>
                <SingleAngleBracketIcon />
              </button>
            </Panel>

            <Panel className={styles.panelSmall}>
              <img src={InquiryImg} className={styles.img} alt="img" />
              <p className={styles.text}>
                어드민
                <br />
                문의전화
              </p>
              <p>02-2038-2511</p>
            </Panel>
          </div>
        </>
      )}
    </Content>
  );
}
const NAV_MAP = [
  {
    label: "대기",
    status: "progress",
  },
];

const DEFAULT_DATA = {
  requestList: {
    content: [],
  },
  pagination: {
    totalPage: 1,
    totalCount: 1,
  },
  requestCounts: {
    countAccept: 0,
    countPending: 0,
    countReject: 0,
    countAll: 0,
  },
};
