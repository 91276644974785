import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Controller, FieldErrors, FieldValues, useForm } from "react-hook-form";

import { LabelInput } from "./createUI";
import { RadioBox } from "common/input";
import { getManagerRemove, postUserData } from "api/admin/company";
import useModal from "hooks/useModal";
import { Content, Title, Panel, Footer, Button, Modal, ErrorModal, LoadingModal } from "comp-lib";

import styles from "styles/pages/company/create/staffCreate.module.scss";
import ROLE from "constants/role";

export default function StaffCreate() {
  const navigate = useNavigate();
  const { companyName, companyId, staffId } = useLocation().state;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FieldValues>({ mode: "onChange" });

  const [errorMsg, setErrorMsg] = useState("");
  const [isValidateCheck, setIsValidateCheck] = useModal();
  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [isCheckModal, setIsCheckModal] = useModal();
  const [isConfirmModal, setIsConfirmModal] = useModal();
  const [isCancelModal, setIsCancelModal] = useModal();

  // 기존 대표담당자 삭제(status 2로 변경) API 연동
  const { mutate: removeMutation, error: removeError, isLoading: removeIsLoading } = useMutation(getManagerRemove);

  // 담당자 계정 등록 API 연동
  const { mutate: postMutation, error: createError, isLoading } = useMutation(postUserData);

  const handleSuccessSubmit = (input: FieldValues) => {
    const { staffName, department, jobPosition, email, phone, tel } = input;

    const body = {
      staffName,
      department,
      jobPosition,
      email,
      phone,
      tel,
      companyId,
      role: ROLE.B2B_ADMIN,
    };

    setIsCheckModal();

    if (staffId) {
      removeMutation(staffId, {
        onSuccess: () => postMutation(body, { onSuccess: setIsConfirmModal, onError: setIsErrorShowing }),
      });
    }

    if (!staffId) {
      postMutation(body, { onSuccess: setIsConfirmModal, onError: setIsErrorShowing });
    }
  };

  const handleError = (err: FieldErrors) => {
    for (const fieldName in err) {
      if (err[fieldName]) {
        setErrorMsg(String(err[fieldName]?.message));
      }
    }
    setIsValidateCheck();
  };

  if (removeIsLoading || isLoading) return <LoadingModal />;

  return (
    <Content isWithFooter>
      <Title text="대표 담당자 등록" />

      <Panel>
        <form className={styles.panel} onSubmit={handleSubmit(setIsCheckModal, handleError)}>
          <div className={styles.innerBox}>
            <div className={styles.label}>소속 기업명</div>
            <div>{companyName}</div>
          </div>

          <LabelInput
            label="이름"
            id="staffName"
            placeholder="이름을 입력해 주세요"
            type="text"
            register={register}
            isNull={!!errors.staffName}
          />

          <div className={styles.innerBox}>
            <div className={styles.label}>
              권한<span>*</span>
            </div>
            <Controller
              defaultValue="MANAGER"
              name="role"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <RadioBox
                    name="businessType"
                    dataList={[{ label: "대표 담당자", value: "MANAGER" }]}
                    value={value}
                    handleCheck={onChange}
                  />
                );
              }}
            />
          </div>

          <LabelInput
            label="부서명"
            id="department"
            placeholder="부서명을 입력해 주세요"
            type="text"
            register={register}
            isNull={!!errors.department}
          />

          <LabelInput
            label="직급"
            id="jobPosition"
            placeholder="직급을 입력해 주세요"
            type="text"
            register={register}
            isNull={!!errors.jobPosition}
          />

          <LabelInput
            label="이메일 주소"
            id="email"
            type="email"
            placeholder="비밀번호 설정 링크를 발송할 이메일 주소를 입력해 주세요"
            register={register}
            isNull={!!errors.email}
            validate="email"
          />

          <LabelInput
            label="개별 연락처"
            id="phone"
            type="number"
            placeholder="-를 제외한 숫자만 입력해 주세요"
            register={register}
            isNull={!!errors.phone}
            validate="tel"
          />

          <LabelInput
            label="직통번호"
            id="tel"
            type="number"
            placeholder="'-'를 제외한 숫자만 입력하세요"
            register={register}
            isNull={!!errors.tel}
            validate="tel"
          />

          <Footer>
            <Button size="long" mode="lineGray" type="button" onClick={setIsCancelModal}>
              취소
            </Button>
            <Button size="long" type="submit">
              등록
            </Button>
          </Footer>
        </form>
      </Panel>

      {isCheckModal && (
        <Modal
          title="대표 담당자를 등록하시겠습니까?"
          description={`등록 후 수정이 불가합니다\n입력하신 정보가 맞는지 다시 한 번 확인해 주세요.`}
          btnType="submit"
          submitBtnName="계속하기"
          cancelBtnName="나가기"
          handleClose={() => navigate("/company")}
          handleSubmit={handleSubmit(handleSuccessSubmit, handleError)}
        />
      )}

      {isConfirmModal && (
        <Modal
          title="대표 담당자 등록이 완료되었습니다"
          description={`등록된 이메일 주소로 비밀번호 설정 링크가 발송됩니다.\n비밀번호 설정 링크는 24 시간 후 만료됩니다.`}
          btnType="confirm"
          handleClose={() => navigate(-1)}
        />
      )}

      {isCancelModal && (
        <Modal
          title="대표 담당자 등록을 취소하시겠습니까?"
          description="지금까지 작성한 내용은 저장되지 않습니다."
          btnType="submit"
          submitBtnName="계속하기"
          cancelBtnName="나가기"
          handleClose={() => navigate(-1)}
          handleSubmit={setIsCancelModal}
        />
      )}

      {isValidateCheck && <Modal description={errorMsg} btnType="confirm" handleClose={setIsValidateCheck} />}

      {isErrorShowing && <ErrorModal error={removeError || createError} onConfirmHandler={setIsErrorShowing} />}
    </Content>
  );
}
