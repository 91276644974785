export function stringToUint8Array(str: string): Uint8Array {
  const enc = new TextEncoder();
  return enc.encode(str);
}

export function uint8ArrayToString(input: Uint8Array): string {
  if (!(input instanceof Uint8Array)) {
    return "";
  }
  const decoder = new TextDecoder("utf-8");
  return decoder.decode(input);
}
