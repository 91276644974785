import { ArrowPreviousIcon } from "assets/svgs/icon";
import styles from "styles/pages/advertisement/detail/adSidebar/adPreviewHeader.module.scss";

export default function PreviewHeader() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerTop}>
        <ArrowPreviousIcon width={24} height={24} />
        거래
      </div>
      <div className={styles.progress}>
        <div className={styles.progressBar}>
          <div />
          <p>거래 조회</p>
        </div>
        <div className={styles.progressBar}>
          <div />
          <p>동의</p>
        </div>
        <div className={styles.progressBar}>
          <div />
          <p>거래 완료</p>
        </div>
      </div>
    </div>
  );
}
