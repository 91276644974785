import styles from "styles/pages/reward/mintBurn/mintBurnProgressNotifyContent.module.scss";
import Progressbar from "./Progressbar";

type TProps = {
  currProgressRate: number;
};

export default function MintBurnProgressNotifyContent({ currProgressRate }: TProps) {
  return (
    <div className={styles.container}>
      <div>요청을 진행 중입니다. 잠시만 기다려 주세요.</div>
      <Progressbar style={{ width: `${currProgressRate}%` }} />
      <strong>{currProgressRate} %</strong>
    </div>
  );
}
