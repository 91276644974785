import { FormProvider, useForm } from "react-hook-form";

import Write from "./Write";

import { TDefaultForm } from "types/appPush";
import { MOCK_DEFAULT_FORM } from "constants/appPush";

export default function AppPushWrite() {
  const formMethods = useForm<TDefaultForm>({
    defaultValues: MOCK_DEFAULT_FORM,
  });

  return (
    <FormProvider {...formMethods}>
      <Write />
    </FormProvider>
  );
}
