import { NavLink, useLocation } from "react-router-dom";

import dayjs from "dayjs";

import StatusChip from "./StatusChip";

import { TMydInquiryItem } from "types/mydInquiry";
import { ImgIcon } from "assets/svgs/icon";

const TYPE: { [key: string]: string } = {
  NORMAL: "일반",
  ERROR: "오류 및 불편",
  QUESTION: "이용문의",
  REWARD: "적립",
};

type TProps = {
  data: TMydInquiryItem[];
  length: number;
  pageNo: string;
};

export default function useTableData({ data, length, pageNo }: TProps) {
  const { search } = useLocation();

  const columns = [
    { text: "no", width: "70px" },
    { text: "문의유형", width: "94px" },
    { text: "사용자 DID", width: "262px" },
    { text: "제목", width: "262px" },
    { text: "진행상태", width: "90px" },
    { text: "완료일시", width: "168px" },
    { text: "접수일시", width: "168px" },
  ];

  const dataWithNo = data?.map((arg, idx) => ({
    ...arg,
    no: length - idx - 10 * Number(pageNo),
  }));

  const getDateFormat = (originalDate: string) => {
    if (originalDate) {
      const dateTime = dayjs(originalDate).format("YYYY-MM-DD  HH:mm");

      return dateTime;
    }

    return "-";
  };

  const rows = dataWithNo?.map((item) => {
    return [
      item.no,
      TYPE[item.type],
      item.userId,
      <div style={{ display: "flex", justifyContent: "center", gap: "4px" }}>
        <NavLink
          to={`/cs/mydInquiry/detail/${item.id}${search}`}
          style={{
            display: "flex",
            gap: "8px",
          }}
        >
          {item.fileCount !== 0 && <ImgIcon />}
          <span
            style={{
              textDecoration: "underline",
              color: "#747579",
            }}
          >
            {`${item.title}(${item.commentCount})`}
          </span>
          {item.lastCommentType !== "ANSWER" && <span style={{ color: "red", textDecoration: "none" }}>(new)</span>}
        </NavLink>
      </div>,
      <StatusChip status={item.status} key={item.id} />,
      item.finishedAt ? getDateFormat(item.finishedAt) : "-",
      item.receivedAt ? getDateFormat(item.receivedAt) : "-",
    ];
  });

  return { columns, rows };
}
