export function splitUsers(wholeDidList: string): string[] {
  let result: string[] = [];
  const didArray = splitTextList(wholeDidList);

  didArray.forEach((v) => {
    if (v !== "") {
      result.push(v);
    }
  });
  return result;
}

function splitTextList(str: string): string[] {
  const arr = str.split(",");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].replace(/^\s*/, "").replace(/\s*$/, "");
  }
  return arr;
}
