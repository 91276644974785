import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import styles from "styles/pages/mydInquiry/detail/mydInquiryFooter.module.scss";
import cx from "classnames";

import { Button, ErrorModal, Footer, Modal } from "comp-lib";

import useModal from "hooks/useModal";
import useModalContents from "hooks/useModalContents";

import { completeMydInquiry, postComment } from "api/mydInquiry";
import queryKey from "constants/queryKey";
import { TPostCommentRequest } from "types/mydInquiry";
import { useState } from "react";
import useImgUpload from "hooks/useImgUpload";
import { TUploadImgRes } from "types/common";
import { MODAL_CONTENTS } from "constants/mydInquiry";

type TProps = {
  isDone: boolean;
  isCompleteChecked: boolean;
};

export default function MydInquiryFooter({ isDone, isCompleteChecked }: TProps) {
  const queryClient = useQueryClient();

  const { setValue, reset, getValues } = useFormContext();

  const navigate = useNavigate();
  const { id } = useParams();

  const [isModalOpen, onToggleModal] = useModal();
  const [isErrorModalOpen, onToggleErrorModal] = useState(false);

  const [modalContents, setModalContents] = useModalContents();

  const canSend = Boolean(getValues("body"));
  const hasImg = getValues("imgFile").length !== 0;

  const goToList = () => {
    navigate(-1);
  };

  const { mutateAsync: postCommentMuta, error: postError } = useMutation(
    (arg: TPostCommentRequest) => postComment(arg),
    {
      onSuccess: () => {
        if (isCompleteChecked) {
          completeInquiryMuta();
          return;
        }

        setModalContents({
          ...MODAL_CONTENTS.SEND_CONFIRM,
          handleClose: () => {
            onToggleModal();
            queryClient.invalidateQueries(queryKey.GET_MYD_INQUIRY_COMMENTS);
            queryClient.invalidateQueries(queryKey.GET_MYD_INQUIRY_DETAIL);
            reset();
          },
        });
      },
    },
  );

  const { mutateAsync: completeInquiryMuta, error: completeError } = useMutation(() => completeMydInquiry(Number(id)), {
    onSuccess: () => {
      setModalContents({
        ...MODAL_CONTENTS.COMPLETE_CONFIRM,
        handleClose: () => {
          onToggleModal();
          queryClient.invalidateQueries(queryKey.GET_MYD_INQUIRY_COMMENTS);
          queryClient.invalidateQueries(queryKey.GET_MYD_INQUIRY_DETAIL);
          reset();
        },
      });
    },
  });

  const { uploadImgMuta, uploadImgsMuta, registerImgMuta, imgError, imgsError, registerError } = useImgUpload();

  const handleSubmit = async () => {
    try {
      const imgArr = getValues("imgFile").map((e: { file: File; url: string }) => e.file);

      if (imgArr.length === 0) {
        await postCommentMuta({ id: Number(id), contents: getValues("body"), attachedFileIds: getValues("imgId") });
      }

      // 단일 업로드
      if (imgArr.length === 1) {
        const uploadedImg = await uploadImgMuta({ file: imgArr[0] });
        const registeredImg = await registerImgMuta({ fileId: uploadedImg.fileId });

        if (registeredImg.status === "REGISTERED") {
          setValue("imgId", [registeredImg.fileId]);

          await postCommentMuta({ id: Number(id), contents: getValues("body"), attachedFileIds: getValues("imgId") });
        } else {
          throw new Error(`REGISTERED 상태의 이미지만 등록 가능합니다.`);
        }
      }

      // 다중 업로드
      if (imgArr.length >= 2) {
        const formData = new FormData();
        const arr: string[] = [];

        imgArr.forEach((img: File) => formData.append("files", img));

        const uploadedImgs = await uploadImgsMuta({ files: formData });

        const res = uploadedImgs.map((e: TUploadImgRes) => {
          return registerImgMuta({ fileId: e.fileId });
        });

        const registeredImg = await Promise.all(res);

        const isAllRegistered = registeredImg.every((item) => item.status === "REGISTERED");

        if (isAllRegistered) {
          uploadedImgs.map((e: TUploadImgRes) => {
            arr.push(e.fileId);

            setValue("imgId", arr);
          });

          await postCommentMuta({ id: Number(id), contents: getValues("body"), attachedFileIds: getValues("imgId") });
        } else {
          throw new Error(`REGISTERED 상태의 이미지만 등록 가능합니다.`);
        }
      }
    } catch (error) {
      onToggleErrorModal(true);

      throw new Error("이미지 등록에 실패했습니다.");
    }
  };

  const handleComplete = async () => {
    reset();
    setValue("body", "해당문의가 완료되었습니다.");
    await handleSubmit();
  };

  const getErrMsg = () => {
    if (postError && !isCompleteChecked) {
      return "답변 등록에 실패했습니다.\n다시 시도해주세요";
    }
    if (postError && isCompleteChecked) {
      return "문의 완료에 실패했습니다.\n다시 시도해주세요";
    }

    return "이미지 등록에 실패했습니다.\n다시 시도해주세요.";
  };

  const handleNavigate = () => {
    if (hasImg || canSend) {
      setModalContents({
        ...MODAL_CONTENTS.GO_TO_LIST,
        handleSubmit: goToList,
        handleClose: onToggleModal,
      });

      onToggleModal();
    } else {
      goToList();
    }
  };

  const handleErrorClose = () => {
    onToggleErrorModal(false);

    if (isModalOpen) {
      onToggleModal();
    }
  };

  return (
    <>
      <Footer>
        {isDone ? (
          <Button name="cancel" onClick={goToList} className={styles.confirm} type="button" size="long">
            목록보기
          </Button>
        ) : (
          <>
            <Button
              name="cancel"
              onClick={handleNavigate}
              className={styles.confirm}
              type="button"
              size="long"
              mode="lineGray"
            >
              목록이동
            </Button>

            <Button
              name="sumbit"
              className={cx({ [styles.isSendDisabled]: !canSend || isCompleteChecked })}
              type="submit"
              size="long"
              disabled={!canSend || isCompleteChecked}
              onClick={() => {
                setModalContents({
                  ...MODAL_CONTENTS.SEND_SUBMIT,
                  handleSubmit: handleSubmit,
                  handleClose: onToggleModal,
                });
                onToggleModal();
              }}
            >
              전송하기
            </Button>

            <Button
              name="complete"
              onClick={() => {
                setModalContents({
                  ...MODAL_CONTENTS.COMPLETE_SUBMIT,
                  handleSubmit: handleComplete,
                  handleClose: onToggleModal,
                });
                onToggleModal();
              }}
              className={cx(styles.completeBtn, { [styles.isCompleteDisabled]: !isCompleteChecked })}
              type="button"
              size="long"
              disabled={!isCompleteChecked}
            >
              완료하기
            </Button>
          </>
        )}
      </Footer>

      {isErrorModalOpen && (
        <ErrorModal
          error={postError || imgError || imgsError || registerError}
          message={getErrMsg()}
          onConfirmHandler={handleErrorClose}
        />
      )}

      {isModalOpen && <Modal {...modalContents} />}
    </>
  );
}
