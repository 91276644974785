import cx from "classnames";
import styles from "./tableNav.module.scss";
import useCustomParams from "comp-lib/hooks/useCustomParams";

type TData = {
  label: string;
  status: string;
  count: number;
  numeral?: string;
};

type TProps = {
  dataList: TData[];
};

export default function TableNav({ dataList }: TProps) {
  const [{ status }, setParams] = useCustomParams({
    status: dataList[0].status,
    pageNum: "1",
  });

  const handleClick = (value: string) => () => {
    setParams({ status: value, pageNum: "1" });
  };

  return (
    <section className={styles.wrapper}>
      {dataList.map((item, idx) => (
        <button
          key={item.status}
          type="button"
          className={cx({ [styles.active]: status === item.status })}
          onClick={handleClick(item.status)}
        >
          {item.label}
          &nbsp;
          <span className={cx({ [styles.active]: status === item.status })}>
            {`${item.count}${idx === 0 ? item.numeral ?? "건" : item.numeral ?? ""}`}
          </span>
        </button>
      ))}
    </section>
  );
}
