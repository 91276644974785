import { TChallengeStatus } from "types/contentsManage/challenge";

export const CHALLENGE_CATEGORY = {
  갓생: "GODLIFE",
  자랑: "PROUD",
  환경: "ENVIRONMENT",
  야외활동: "OUTDOOR",
  반려: "COMPANION",
  일상: "DAILYLIFE",
  자기가꾸기: "SELFCARE",
  기타: "ETC",
} as const;

export const CHALLENGE_CATEGORY_ENG = {
  GODLIFE: "갓생",
  PROUD: "자랑",
  ENVIRONMENT: "환경",
  OUTDOOR: "야외활동",
  COMPANION: "반려",
  DAILYLIFE: "일상",
  SELFCARE: "자기가꾸기",
  ETC: "기타",
} as const;

export const CHALLENGE_STATUS_MAP: { [key: string]: string } = {
  PREOPEN: "예정",
  RECRUITING: "모집중",
  PROCEEDING: "진행중",
  ENDED: "종료",
  CALCULATED: "종료",
  FINISHED: "정산",
  CANCELED: "취소",
  DELETED: "삭제",
};

export const DEFAULT_CAUTION = `• 그룹 성공 달성 실패 시 지급되는 보상 포인트는 (참가비)*(참여 인원)/(성공 인원)으로, 최종 참여 인원에 따라 달라질 수 있습니다.
• 보상 포인트는 정수 단위로 지급합니다. (소수점 미지급)
• 최종 참여 인원이 10명 미만일 경우 챌린지가 진행되지 않으며 참가비가 환불됩니다.
• 모집 마감 24시간 전까지만 참여를 취소할 수 있으며, 챌린지 시작 이후에는 참여 취소 및 참가비 환불이 불가능합니다.
• 챌린지 모집 마지막 날 참여 시, 참여 취소가 불가능합니다.
• 챌린지 기간 중 하루라도 인증 실패 시 보상 포인트가 지급되지 않으며, 참가 포인트는 환불되지 않습니다.
• 보상 포인트는 챌린지 종료일 인증 완료 후 영업일 기준 3일 이내에 지급됩니다.
• 챌린지 종료 전후, 관리자 검수 과정에서 부적절하거나 챌린지와 상관없는 이미지 게시가 확인된 경우 판정이 번복될 수 있습니다.
• 부적절한 이미지(광고, 선정적, 비방 등) 적발 즉시 챌린지는 실패로 판정되며, 이후 타챌린지 참여가 제한됩니다.
• 챌린지 참가 시 위 유의사항을 확인 및 동의한 것으로 간주되며, 미숙지로 인한 불이익은 배상하지 않습니다.`;

export const challengeStatusMap = (status: TChallengeStatus) => {
  if (status === "PREOPEN")
    return {
      delete: {
        label: "삭제",
        func: "등록삭제" as const,
      },
      next: {
        label: "수정",
        func: "등록수정" as const,
      },
    };
  if (status === "CANCELED")
    return {
      next: {
        label: "삭제",
        func: "삭제" as const,
      },
    };
  if (status === "PROCEEDING" || status === "RECRUITING")
    return {
      next: {
        label: "종료",
        func: "종료" as const,
      },
    };
  if (status === "ENDED" || status === "CALCULATED")
    return {
      next: {
        label: "정산하기",
        func: "정산하기" as const,
      },
    };
  return {
    delete: {
      label: "삭제",
      func: "삭제" as const,
    },
    next: {
      label: "정산완료",
      func: "정산하기" as const,
    },
  };
};

export const challengeNavData = (): { [key: string]: { num: number; status: string } } => ({
  ALL: {
    num: 0,
    status: "ALL",
  },
  PREOPEN: {
    num: 0,
    status: "PREOPEN",
  },
  RECRUITING: {
    num: 0,
    status: "RECRUITING",
  },
  PROCEEDING: {
    num: 0,
    status: "PROCEEDING",
  },
  ENDED: {
    num: 0,
    status: "ENDED",
  },
  FINISHED: {
    num: 0,
    status: "FINISHED",
  },
  CANCELED: {
    num: 0,
    status: "CANCELED",
  },
});

export const challengeNavStatusMap: { [key: string]: string | undefined } = {
  ALL: undefined,
  PROCEEDING: "PROCEEDING",
  RECRUITING: "RECRUITING",
  PREOPEN: "PREOPEN",
  CANCELED: "CANCELED",
  FINISHED: "FINISHED",
  ENDED: "ENDED, CALCULATED",
};

export const CHALLENGE_COLUMNS = [
  ["NO", "78px"],
  ["상태", "106px"],
  ["카테고리", "111px"],
  ["챌린지제목"],
  ["참여인원", "103px"],
  ["진행 기간", "249px"],
  ["성공인원(명)", "89px"],
  ["그룹성공률", "102px"],
  ["신고건수", "79px"],
];
/** 기존 UI 사용을 위해 사용중인 constants */

export const MOCK_CATEGORY: { [key: number]: string } = {
  1: "전체",
  2: "IT/테크",
  3: "건강",
  4: "금융",
  5: "뷰티/패션",
  6: "사랑",
  7: "소비/앱테크",
  8: "이벤트",
  9: "이슈",
  10: "직장",
  11: "취미",
  99: "기타",
};

export const STATUS_CATEGORY = [
  {
    label: "전체",
    status: "all",
  },
  {
    label: "진행중",
    status: "inProgress",
  },
  {
    label: "예정",
    status: "notStarted",
  },
  {
    label: "종료",
    status: "ended",
  },
];

export const CHALLENGE_REPORT_REQ_MSG = {
  cancel:
    "다른 사용자에게 이미지가 보이지 않으며\n해당 참가자는 당일 챌린지 참여가 리셋됩니다.\n삭제 이후에는 되돌릴 수 없습니다.",
  accept:
    "신고반려시 이미지는 그대로 유지됩니다.\n해당 참가자는 챌린지가 유지됩니다.\n반려 이후에는 되돌릴 수 없습니다.",
  block: "선택한 이미지는 챌린지에서 삭제되며\n해당 참가자는 블랙리스트로 등록되어\n마이디에서 퇴출처리 합니다.",
} as const;

export const CHALLENGE_REPORT_RES_MSG = {
  cancel:
    "해당 이미지 인증이 취소되었습니다.\n다른 사용자에게 이미지가 보이지 않으며\n해당 참가자는 당일 챌린지 참여가 리셋됩니다.",
  accept: "해당 이미지에 대한 신고가 반려처리 되었습니다.\n이미지는 그대로 유지되며 해당 참가자는 챌린지가 유지됩니다.",
  block:
    "차단이 완료되었습니다.\n이미지는 챌린지에서 삭제되며\n해당 참가자는 블랙리스트로 등록되어\n마이디에서 퇴출처리 됩니다.",
} as const;

// 100% 성공시 1인당 지급 포인트 배수 리스트
export const CHALLENGE_REWARD_POINT_MULTIPLIER_LIST = ["2", "3", "4", "5", "6", "7", "8", "9", "10"];
