import cx from "classnames";
import { MouseEventHandler, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";

import Input from "pages/reward/@shared/Input";
import Label from "pages/reward/@shared/Label";

import { TMintBurnMode, TMintForm, TMintResult } from "types/mintBurn";

import useModal from "hooks/useModal";
import ErrorModal from "pages/reward/@shared/modal/ErrorModal";

import {
  Button,
  Content,
  Footer,
  Pagination,
  // Pagination,
  Panel,
  Table,
  TableNav,
  // Table, TableNav,
  Title,
} from "comp-lib";

import Textarea from "pages/reward/@shared/Textarea";
import ConfirmModal from "pages/reward/@shared/modal/ConfirmModal";
import styles from "styles/pages/reward/mintBurn/mintBurnManagement.module.scss";
import { splitUsers } from "utils/mintBurn";

import { manualBurn, manualMint } from "api/mint";
import { RadioBox } from "common/input";
import { MINT_BURN_MODE } from "constants/reward/mint";
import { splitArrayByColCnt } from "utils";
import SearchInput from "../@shared/SearchInput";
import NotifyModal from "../@shared/modal/NotifyModal";
import DidPreview from "./DidPreview";
import MintProgressNotifyContent from "./MintBurnProgressNotifyContent";
import useFilter from "./useFilter";
import useTableData from "./useTableData";
import useTableNavData from "./useTableNavData";

// 기존 mgrconsole 에 있던 포인트 보상, 소멸 기능을 admin 포인트 탭으로 옮김
export default function MintBurnManagement() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
    setValue,
  } = useForm<TMintForm>({
    shouldFocusError: true,
    defaultValues: {
      title: "",
      mintBurnMode: MINT_BURN_MODE[0],
      recipients: "",
      amount: "",
    },
  });

  const { mutateAsync: bulkMintMuta, isLoading: isMintLoading } = useMutation(manualMint, {
    onError: () => {
      setCustomError(new Error("Bulk mint 도중 에러가 발생했습니다."));
      setMintCompleteCnt(0); // reset
      // onToggle();
    },
  });
  const { mutateAsync: bulkBurnMuta, isLoading: isBurnLoading } = useMutation(manualBurn, {
    onError: () => {
      setCustomError(new Error("Bulk burn 도중 에러가 발생했습니다."));
      setMintCompleteCnt(0); // reset
      // onToggle();
    },
  });

  const [mintResults, setMintResults] = useState<{ success: TMintResult; fail: TMintResult }>({
    success: [],
    fail: [],
  });
  const [mintCompleteCnt, setMintCompleteCnt] = useState(0);
  const [customError, setCustomError] = useState<Error | null>(null);
  const [isConfirmModalOpen, onConfirmToggle] = useModal();
  const [isNotifyOpen, onNotifyToggle] = useModal();
  const [isResetOpen, onResetToggle] = useModal();

  const { dataForTableNav, dataForTableRows, totalPages } = useFilter(mintResults);
  const { columns, rows } = useTableData(dataForTableRows);
  const tableNavData = useTableNavData(dataForTableNav);

  const isMintMode = watch("mintBurnMode") === "mint";

  const resetMintResults = () => {
    setMintResults({ success: [], fail: [] });
  };

  const getDedupedDidList = (didList: string[]) => {
    // 중복 제거된 did 리스트
    return [...new Set(didList)];
  };

  const mintRequestSubmit = async (data: TMintForm) => {
    onNotifyToggle(); // to show mint progress on modal
    setMintCompleteCnt(0); // reset progress bar.
    resetMintResults();

    const { amount, title, recipients, note } = data;
    const didList = splitUsers(recipients);
    const recipientsArr = getDedupedDidList(didList);

    const bulkRequestCntLimit = 10;
    const userDidChunks = splitArrayByColCnt(recipientsArr, bulkRequestCntLimit);
    const mintResultsArr: TMintResult = [];

    const actionFunc = isMintMode ? bulkMintMuta : bulkBurnMuta;

    // send 10 items per request
    for await (const userDidChunk of userDidChunks) {
      // 성공한 did 만 Response 로 받는다
      const mintResult = await actionFunc({ didsList: userDidChunk, title, amount: Number(amount), note });
      mintResultsArr.push(...mintResult);
      // to calculate progress rate
      setMintCompleteCnt((prevMintCnt) => prevMintCnt + userDidChunk.length);
    }
    const successResults = mintResultsArr.slice();
    const failedResults = didList.filter((recipient) => !successResults.includes(recipient));

    setMintResults({ success: successResults, fail: failedResults });
  };

  const triggerFormSubmission = () => {
    handleSubmit(mintRequestSubmit)();
    onConfirmToggle();
  };

  const handleReset = () => {
    reset();
    resetMintResults();
    onResetToggle();
  };

  const handleRemoveDidClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    const remainDidList = splitUsers(getValues("recipients")).filter((_, i) => String(i) !== e.currentTarget.id);
    setValue("recipients", remainDidList.join(", "));
  };

  const amount = getValues("amount");
  const recipients = splitUsers(watch("recipients"));
  const totalDidCount = getDedupedDidList(recipients).length;

  const currProgressRate = mintCompleteCnt === 0 ? 0 : Math.floor((mintCompleteCnt / totalDidCount) * 100);

  const isProgressing = (currProgressRate > 0 && currProgressRate < 100) || isMintLoading || isBurnLoading;

  const mintSuccessCount = mintResults.success.length;

  const mintBurnDataList: { label: string; value: TMintBurnMode }[] = [
    { label: "보상", value: "mint" },
    { label: "소멸", value: "burn" },
  ];

  const isSuccessResult = mintResults.success.length !== 0;
  const pointMintBurnModeTxt = isMintMode ? "보상" : "소멸";

  return (
    <div className={styles.container}>
      <Content isWithFooter>
        <Title text={`포인트 보상 · 소멸`} />

        <Panel>
          <form className={styles.panel} onSubmit={handleSubmit(mintRequestSubmit)}>
            <Controller
              name="mintBurnMode"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <div className={styles.input_container}>
                  <Label htmlFor="mintBurnMode" required aria-required>
                    보상 · 소멸 모드
                  </Label>
                  <RadioBox name="mintBurnMode" dataList={mintBurnDataList} value={value} handleCheck={onChange} />
                </div>
              )}
            />

            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className={styles.input_container}>
                  <Label htmlFor="title" required aria-required>
                    제목
                  </Label>

                  <Input
                    {...field}
                    id="title"
                    className={cx({ [styles.error_input]: errors.title })}
                    placeholder={`사용자에게 보여질 포인트 ${pointMintBurnModeTxt} 사유를 입력해 주세요 (30자 이내)`}
                  />
                </div>
              )}
            />

            <Controller
              name="recipients"
              control={control}
              rules={{ required: true }}
              shouldUnregister={false}
              render={({ field }) => (
                <div className={styles.input_container}>
                  <Label htmlFor="recipients" required aria-required>
                    포인트 {pointMintBurnModeTxt} DID
                  </Label>
                  <div className={styles.did_input_container}>
                    <Textarea
                      {...field}
                      id="recipients"
                      className={cx({ [styles.error_input]: errors.recipients })}
                      placeholder={`포인트를 ${pointMintBurnModeTxt}할 DID를 입력해 주세요 (콤마 ","로 구분)\n중복된 DID 는 제외 후 요청합니다`}
                    />
                    <DidPreview didList={recipients} handleRemoveDidClick={handleRemoveDidClick} />
                  </div>
                </div>
              )}
            />
            <Controller
              name="amount"
              control={control}
              rules={{ required: true }}
              shouldUnregister={false}
              render={({ field }) => (
                <div className={styles.input_container}>
                  <Label htmlFor="amount" required aria-required>
                    DID당 {pointMintBurnModeTxt} 포인트
                  </Label>

                  <div className={styles.point_box}>
                    <Input
                      {...field}
                      id="amount"
                      type="number"
                      className={cx(styles.point_input, { [styles.error_input]: errors.amount })}
                      placeholder="포인트를 입력해 주세요"
                    />
                    <span>P</span>
                  </div>
                </div>
              )}
            />
            <Controller
              name="note"
              control={control}
              shouldUnregister={false}
              render={({ field }) => (
                <div className={styles.input_container}>
                  <Label htmlFor="note">비고</Label>

                  <Input
                    {...field}
                    id="note"
                    type="text"
                    placeholder="사용자에게 보여지지 않고 내부적으로만 저장됩니다 (150자 이내)"
                    maxLength={150}
                  />
                </div>
              )}
            />
          </form>
        </Panel>

        <div className={styles.payments_title}>
          <div className={styles.text}>포인트 {pointMintBurnModeTxt} 현황</div>
          <div className={styles.point}>
            {isSuccessResult
              ? `[총 ${pointMintBurnModeTxt} 포인트] ${amount}P X ${mintSuccessCount}건 = ${+amount * mintSuccessCount}P`
              : `[총 ${pointMintBurnModeTxt} 포인트] 0P X 0건 = 0P`}
          </div>
        </div>

        <Panel>
          <div className={cx(styles.input_container, styles.table_container)}>
            <div className={styles.tableTop}>
              <TableNav dataList={tableNavData} />
              <SearchInput placeholder="검색하실 DID를 입력하세요" searchIcon={false} />
            </div>
            <Table columns={columns} rows={rows} />

            <Pagination totalPages={totalPages} />
          </div>
        </Panel>

        <Footer>
          <Button size="long" mode="outlined" onClick={() => onResetToggle()}>
            초기화
          </Button>
          <Button size="long" onClick={() => onConfirmToggle()}>
            {pointMintBurnModeTxt}하기
          </Button>
        </Footer>
      </Content>

      {isNotifyOpen && (
        <NotifyModal isImplemented={!isProgressing} onImplementHandler={onNotifyToggle}>
          <MintProgressNotifyContent currProgressRate={currProgressRate} />
        </NotifyModal>
      )}

      {isConfirmModalOpen && (
        <ConfirmModal
          btnType="submit"
          title={`포인트를 ${pointMintBurnModeTxt} 하시겠습니까?`}
          description={
            <div className={styles.confirm_desc}>
              입력 정보에 따라 포인트 <span className={styles.highlight}>{pointMintBurnModeTxt}</span>을 시작합니다.
              <br />이 동작은 취소할 수 없습니다.
            </div>
          }
          submitBtnName="확인"
          handleClose={() => onConfirmToggle()}
          handleSubmit={triggerFormSubmission}
        />
      )}

      {isResetOpen && (
        <ConfirmModal
          btnType="submit"
          title="페이지를 초기화 하시겠습니까?"
          description={`입력 정보가 초기화되고 진행 중이던 작업이 있을 경우\n중단됩니다. 계속 하시겠습니까?`}
          submitBtnName="확인"
          handleClose={() => onResetToggle()}
          handleSubmit={handleReset}
        />
      )}

      {customError && <ErrorModal error={customError} onConfirmHandler={() => setCustomError(null)} />}
    </div>
  );
}
