import { useQuery } from "react-query";
import { getUserRole } from "api/common";
import queryKey from "constants/queryKey";

/**
 * did, token, role 정보를 받습니다 (memory에 저장해서 사용하기 위함)
 */
export function useAccessRole() {
  const { isLoading, isError, error, data } = useQuery([queryKey.GET_ROLE], getUserRole, {
    cacheTime: 360 * 60 * 1000, // token 유효시간: 6H
    staleTime: 330 * 60 * 1000,
  });

  return { isLoading, isError, error, data };
}
