import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const getCommasInNum = (value: string | number) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const getDelCommas = (value: string) => value.replace(/,/gi, "");
export const getOnlyNumber = (value: string) => Number(value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
// replace(/\D/g, "")

export const range = (start: number, end: number) => {
  const array = [];
  for (let i = start; i <= end; ++i) {
    array.push(i);
  }
  return array;
};

type TPageParams = {
  pageNum: number;
  totalPages: number;
  pageRange: number;
};

export const getPageData = ({ pageNum, totalPages, pageRange }: TPageParams) => {
  const startNumberOfPageRange = Math.trunc((pageNum - 1) / pageRange) * pageRange + 1;
  const tmpEnd = startNumberOfPageRange + (pageRange - 1);
  const endNumberOfPageRange = tmpEnd > totalPages ? totalPages : tmpEnd;

  const isPrev = startNumberOfPageRange > pageRange;
  const isNext = endNumberOfPageRange < totalPages;

  return { startNumberOfPageRange, endNumberOfPageRange, isPrev, isNext };
};

export const getXmlParsing = (xmlText: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlText.trim(), "application/xml");
  const getBlankFalsyChk = (tagName: string) => xmlDoc.querySelector(tagName)?.textContent || "";

  // const id = getBlankFalsyChk("id");
  const title = getBlankFalsyChk("title");
  const description = getBlankFalsyChk("description");
  // const startDate = getBlankFalsyChk("startDate");
  // const endDate = getBlankFalsyChk("endDate");
  // const point = getBlankFalsyChk("point");
  const imageUrl = getBlankFalsyChk("imageUrl");
  const imageName = getBlankFalsyChk("imageName");
  const viewType = getBlankFalsyChk("viewType");
  const answerType = getBlankFalsyChk("answerType");
  const maxChoice = Number(getBlankFalsyChk("maxCheck"));
  const textChoiceList = xmlDoc.querySelector("text-choice")?.querySelectorAll("choice") || [];

  const answerList = [];
  for (const node of textChoiceList) {
    const value = node.querySelector("description")?.textContent || "";
    answerList.push({ name: value });
  }

  const img = {
    filename: imageName,
    url: imageUrl,
  };

  return { title, description, img, viewType, answerType, maxChoice, answerList };
};

export const makeQueryString = (data: any) =>
  Object.entries(data)
    .map((item) => item.join("="))
    .join("&");

/**
 *
 * @param sec ms
 * @returns Promise
 */
// eslint-disable-next-line no-promise-executor-return
export const delay = (sec: number) => new Promise((resolve) => setTimeout(resolve, sec));

export const setB2BEnv = () => {
  const { host } = window.location;

  if (host.includes("dev")) {
    window.RUN_MODE = "dev";
    window.B2B_URL = import.meta.env.VITE_ENV_DEV;
    window.API_URL = import.meta.env.VITE_API_ENV_DEV;
    window.CDN_URL = import.meta.env.VITE_CDN_ENV_DEV;
    window.MYD_WEB_URL = import.meta.env.VITE_MYD_WEB_ENV_DEV;
    window.PUBLIC_KEY = import.meta.env.VITE_WEB_PUBLIC_KEY_DEV;
    return;
  }

  if (host.includes("stag")) {
    window.RUN_MODE = "stag";
    window.B2B_URL = import.meta.env.VITE_ENV_STAG;
    window.API_URL = import.meta.env.VITE_API_ENV_STAG;
    window.CDN_URL = import.meta.env.VITE_CDN_ENV_STAG;
    window.MYD_WEB_URL = import.meta.env.VITE_MYD_WEB_ENV_STAG;
    window.PUBLIC_KEY = import.meta.env.VITE_WEB_PUBLIC_KEY_STAG;
    return;
  }

  if (host.includes("gs")) {
    window.RUN_MODE = "stag";
    window.B2B_URL = import.meta.env.VITE_ENV_STAG;
    window.API_URL = import.meta.env.VITE_API_ENV_GS;
    window.CDN_URL = import.meta.env.VITE_CDN_ENV_GS;
    window.MYD_WEB_URL = import.meta.env.VITE_MYD_WEB_ENV_STAG;
    window.PUBLIC_KEY = import.meta.env.VITE_WEB_PUBLIC_KEY_STAG;
    return;
  }

  if (host.includes("prod")) {
    window.RUN_MODE = "prod";
    window.B2B_URL = import.meta.env.VITE_ENV_PROD;
    window.API_URL = import.meta.env.VITE_API_ENV_PROD;
    window.CDN_URL = import.meta.env.VITE_CDN_ENV_PROD;
    window.MYD_WEB_URL = import.meta.env.VITE_MYD_WEB_ENV_PROD;
    window.PUBLIC_KEY = import.meta.env.VITE_WEB_PUBLIC_KEY_PROD;
    return;
  }

  // localhost
  window.RUN_MODE = "local";
  window.B2B_URL = import.meta.env.VITE_ENV_LOCAL;
  window.API_URL = import.meta.env.VITE_API_ENV_DEV;
  window.CDN_URL = import.meta.env.VITE_CDN_ENV_DEV;
  window.MYD_WEB_URL = import.meta.env.VITE_MYD_WEB_ENV_DEV;
  window.PUBLIC_KEY = import.meta.env.VITE_WEB_PUBLIC_KEY_DEV;
};

/**
 * @ $BLUE: #1890ff;
 * @ $TEAL: #1ab9de;
 */
export const setPrimaryColor = () => {
  const { pathname } = window.location;

  if (pathname.includes("admin")) {
    document.documentElement.style.setProperty("--primary-color", "#1ab9de");
  } else {
    document.documentElement.style.setProperty("--primary-color", "#1890ff");
  }
};

export const isProgressNow = (start: dayjs.Dayjs) => {
  const nowDate = dayjs();
  return nowDate.isSameOrAfter(start);
};

export const syncProgressPeriod = (date: dayjs.Dayjs, type?: "decode") => {
  /** 거래 등록 시 진행기간이 23:59 까지여야 하기 때문에 서버에서 임의로 날짜를 + 1 해서 저장합니다.
   *  때문에 보여줄 때에는 서버에서 받아온 진행기간 notAfter에서 하루를 빼줘야 합니다.  */
  if (date.isSame(dayjs("2999-12-31"), "day")) return date;
  return date.subtract(type === "decode" ? -1 : 1, "day");
};

export const formatDuration = (notBefore: string, notAfter: string) => {
  return `${dayjs(notBefore).format("YYYY.MM.DD")} ~ ${syncProgressPeriod(dayjs(notAfter)).format("YYYY.MM.DD")}`;
};

type JsonObject = { [key: string]: JsonValue };
type JsonArray = JsonValue[];
type JsonValue = string | number | boolean | null | JsonObject | JsonArray;

/**
 * Parses a JSON string and retrieves the desired property.
 * @param jsonString The JSON string to parse.
 * @param propertyPath The path to the desired property (e.g., "user.name.first").
 * @returns The value of the desired property or undefined if not found.
 */
export function getJsonProperty(jsonString: string, propertyPath: string): JsonValue | undefined {
  try {
    const jsonObject: JsonObject = JSON.parse(jsonString);
    const properties = propertyPath.split(".");

    let currentValue: JsonValue = jsonObject;
    for (const property of properties) {
      if (typeof currentValue === "object" && currentValue !== null && property in currentValue) {
        currentValue = (currentValue as JsonObject)[property];
      } else {
        return undefined;
      }
    }
    return currentValue;
  } catch (error) {
    console.error("Failed to parse JSON or retrieve property:", error);
    return undefined;
  }
}

export function uniqueKey() {
  const timestamp = new Date().getTime();
  const randomValue = Math.random().toString(36).substring(2, 10);
  return `${timestamp}-${randomValue}`;
}

export const downloadTxtFile = (data: string, fileName: string) => {
  const element = document.createElement("a");
  const file = new Blob([data], {
    type: "text/plain",
  });

  element.href = URL.createObjectURL(file);
  element.download = fileName;
  element.click();
};

/** 버튼 태그에 onClick 이벤트를 추가할 때 " ", "Enter" 키에 onClick event가 할당되는 것을 막습니다. */
export const onKeyDownPreventOnClick = {
  onKeyDown: (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === " " || event.key === "Enter") event.preventDefault();
  },
};

export const splitArrayByColCnt = <T>(arr: T[], columnCnt: number) => {
  // flat method 의 반대
  const rowsCnt = Math.ceil(arr.length / columnCnt);

  return Array.from({ length: rowsCnt }, (_, index) => arr.slice(index * columnCnt, (index + 1) * columnCnt));
};