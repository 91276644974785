import React from "react";

type TProps = {
  size?: number;
  strokeColor?: string;
  bgColor?: string;
  borderColor?: string;
  strokeWidth?: string;
};

export function CheckCircleComponent({
  size = 24,
  strokeColor = "white",
  bgColor = "#1890FF",
  strokeWidth = "1.4",
  borderColor,
}: TProps) {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx={size * 0.5} cy={size * 0.5} rx={size * 0.5} ry={size * 0.5} fill={bgColor} />
      <path
        d={`M${size * 0.7} ${size * 0.35}L${size * 0.43} ${size * 0.65}L${size * 0.3} ${size * 0.5}`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={size * 0.5}
        cy={size * 0.5}
        r={size * 0.468}
        stroke={borderColor || bgColor}
        strokeWidth={size / 16}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
