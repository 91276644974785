import cx from "classnames";
import { ComponentProps, forwardRef } from "react";

import styles from "styles/pages/reward/@shared/textarea.module.scss";

type TTextarea = {} & ComponentProps<"textarea">;

const Textarea = forwardRef<HTMLTextAreaElement, TTextarea>(function Textarea(
  { className, ...otherProps }: TTextarea,
  ref,
) {
  return <textarea className={cx(styles.textarea, className)} ref={ref} {...otherProps} />;
});

export default Textarea;
