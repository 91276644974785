import styles from "./title.module.scss";

type TProps = {
  text: string;
  subText?: string;
};

export default function Title({ text, subText }: TProps) {
  return (
    <div className={styles.title}>
      <div>{text}</div>
      <div className={styles.subText}>{subText}</div>
    </div>
  );
}
