import ROLE from "constants/role";

export const isAdmin = (role: string) => role === ROLE.SNP_ADMIN || role === ROLE.SYSTEM_ADMIN;

export const ADMIN_ROLE_LIST = [
  {
    label: "관리자",
    value: ROLE.SNP_ADMIN,
  },
  {
    label: "운영자",
    value: ROLE.SNP_MANAGER,
  },
];

export const ADMIN_CHECKBOX_OPTIONS = [
  { label: "충전 승인", value: "1" },
  { label: "기업 관리", value: "2" },
  { label: "데이터 거래", value: "3" },
  { label: "광고", value: "4" },
  { label: "프로모션", value: "5" },
  { label: "콘텐츠 관리", value: "6" },
  { label: "커뮤니케이션", value: "7" },
  { label: "상품 관리", value: "8" },
];

export const authorityMapping = (authority?: string) => {
  const authorityMap = [
    "충전 승인",
    "기업 관리",
    "데이터 거래",
    "광고",
    "프로모션",
    "콘텐츠 관리",
    "커뮤니케이션",
    "상품 관리",
  ];
  if (!authority) return "";
  return authority
    .split(",")
    .map((v) => authorityMap[Number(v) - 1])
    .join(", ");
};
