import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import isBetween from "dayjs/plugin/isBetween";
import { AdminAdProposalListData } from "types/advertisement";
import { statusFilter } from "./statusFilter";

dayjs.extend(isBetween);

/**
 * @description searchParams를 기반으로 data를 필터링해주는 훅입니다. (searchParams 사용 예시: /market?status=all&pageNum=1&from=2023-06-08&to=2023-06-09&keyword=타이틀)
 * 페이지 별 data와 status가 다르니 해당 파일을 복사하여 개별적으로 관리하는 것을 추천합니다.
 *
 * useFilterTableData는 api 요청을 통해 받은 data를 조건에 맞춰 필터링 해주는 훅 입니다.
 * useFilterTableNav는 필터링된 data를 common component <TableNav />에 필요한 data로 만들어 줍니다.
 */

export const useFilterAdTableData = (
  data?: AdminAdProposalListData["index"], // any를 data 타입에 맞춰 수정하는 것을 권장합니다.
  navMap?: {
    label: string;
    status: string;
  }[]
): {
  filteredData?: AdminAdProposalListData["index"];
  size?: { [key: string | number]: number };
  totalPages: number;
} => {
  const [searchParams] = useSearchParams();
  if (!data) return { totalPages: 1 };
  let result = data;

  /* 필요한 searchParams 선언 */
  // 기한 선언
  const [from, to] = [searchParams.get("from"), searchParams.get("to")];

  // 상태 선언
  const status = searchParams.get("status") || "all";
  const statusMap: { [key: string]: number } = navMap
    ? Object.fromEntries(navMap.map(({ status: navStatus }) => [navStatus, 0]))
    : {};

  // 검색어 선언
  const keyword = searchParams.get("keyword");

  // 날짜 필터링
  if (from) {
    // notBefore 기준 필터링
    result = result.filter(({ notBefore }) => {
      if (notBefore && dayjs(notBefore).isBetween(from || "0000-00-00", to || "9999-12-12", "day", "[]")) return true;
      return false;
    });
  }

  // 키워드 필터링
  if (keyword) {
    result = result.filter(({ title }) => title.toLowerCase().includes(keyword.toLowerCase()));
  }

  // 상태 필터링 전 size 계산
  if (status && navMap) {
    result.forEach(({ status: statusData, approved }) => {
      const statusNumber = statusFilter(statusData, approved) as number;
      const arr = ["all", "progress", "accept", "reject"];
      statusMap[arr[statusNumber + 1]]++;
      statusMap[arr[0]]++;
    });
  }

  // 상태 필터링
  if (status && navMap && status !== navMap[0].status) {
    result = result.filter(({ status: statusValue, approved }) => {
      if (status === "progress") {
        return statusFilter(statusValue, approved) === 0;
      }
      if (status === "accept") {
        return statusFilter(statusValue, approved) === 1;
      }
      if (status === "reject") {
        return statusFilter(statusValue, approved) === 2;
      }
      return false;
    });
  }

  // 키워드 필터링
  if (keyword) {
    result = result.filter(({ title }) => title.toLowerCase().includes(keyword.toLowerCase()));
  }

  // 생성일 기준 정렬
  result.sort((a, b) => dayjs(b.createDate).unix() - dayjs(a.createDate).unix());

  const totalPages = Math.floor(result.length / 10) + (result.length % 10 && 1) || 1;

  return { filteredData: result, size: statusMap, totalPages };
};

type TNavData = {
  [key: string]: {
    num: number;
    status: string;
  };
};

export const useFilterAdTableNav = (
  nav: {
    label: string;
    status: string;
  }[],
  size?: { [key: string | number]: number }
): TNavData => {
  const navData: TNavData = {};
  if (!size) return {};
  nav.forEach(({ label, status }) => {
    navData[label] = {
      num: size[status] || 0,
      status,
    };
  });

  return navData;
};
