import { useLocation } from "react-router-dom";
import styles from "styles/pages/contentsManage/challenge/detail/detailProgressList.module.scss";
import { Panel } from "comp-lib";
import { ImageDetailIcon } from "assets/svgs/icon";
import ImagePopup from "./ImagePopup";
import { useState } from "react";
import { useQuery } from "react-query";
import queryKey from "constants/queryKey";
import { getChallengeImageList } from "api/contentsManage/challenge";
import cx from "classnames";

export default function DetailProgressList({ participant }: { participant: number }) {
  const { pathname } = useLocation();
  const id = pathname.split("/").pop() || "";
  const { data, isLoading } = useQuery([queryKey.GET_CHALLENGE_IMAGE_LIST], () => getChallengeImageList(id));
  const [imagePopupDate, setImagePopupDate] = useState("");

  const onClickDate = (targetDate: string) => setImagePopupDate(targetDate);
  return (
    <Panel>
      <div className={styles.wrapper}>
        일별 진행 내역
        <div className={cx(styles.innerWrapper, { [styles.noDataWrapper]: !data?.length })}>
          {isLoading || !data?.length ? (
            <div className={styles.noData}>
              {isLoading ? <div className={styles.loading} /> : "진행내역이 없습니다."}
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <td>일자</td>
                  <td>등록된 사진 수</td>
                  <td>이미지보기</td>
                  <td>그룹 성공률</td>
                </tr>
              </thead>
              <tbody>
                {data?.map(({ proofDate, pictureCount, count }) => (
                  <tr key={proofDate}>
                    <td>{proofDate}</td>
                    <td>{pictureCount}</td>
                    <td>
                      <button onClick={() => onClickDate(proofDate)}>
                        <ImageDetailIcon />
                      </button>
                    </td>
                    <td>{`${Math.round((count / participant) * 100)}%`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {imagePopupDate !== "" && (
        <ImagePopup
          onClose={() => setImagePopupDate("")}
          initialDate={imagePopupDate}
          dateList={data?.map(({ proofDate }) => proofDate) || []}
        />
      )}
    </Panel>
  );
}
