import axios, { AxiosRequestConfig } from "axios";
import { AccessRes, TUploadImgRes, UploadFormData, UploadRes } from "types/common";
import { Axios } from "./base";

type TUserInfo = {
  businessType: string;
  companyId: number;
  companyName: string;
  did: string;
  email: string;
  piiUrl: string;
  roles: string;
  staffId: number;
  staffName: string;
  tutorialStatus: boolean;
  authority: string;
};

export const getUserInfo = async () => {
  const { data } = await Axios.get("/api/auth/user-info");

  return { ...data.data, roles: data.data.roles.replace("ROLE_", "") } as TUserInfo;
};

export const getUserRole = async () => {
  const { data } = await Axios.get<{ data: AccessRes }>("/api/access/role");

  return data.data;
};

export const uploadApi = async (token: string, formData: FormData, endpoint: string): Promise<UploadRes> => {
  const config: AxiosRequestConfig = {
    headers: {
      "X-Auth-Token": token,
    },
  };

  const res = await axios.post(endpoint, formData, config);

  return res.data.data.urlList[0];
};

export const uploadCommonImgApi = async (did: string, token: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("did", did);

  return uploadApi(token, formData, `${window.API_URL}/b2b/advertise/images`);
};

export const uploadImgApi = async (body: { did: string; token: string; file: File }) => {
  const { did, token, file } = body;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("did", did);

  return uploadApi(token, formData, `${window.API_URL}/b2b/advertise/images`);
};

// IMG 업로드(단일)
export const uploadImg = async ({ file }: { file: File }): Promise<TUploadImgRes> => {
  const response = await Axios.post(
    `/api/file-service/attached-file`,
    { file },
    { headers: { "Content-Type": "multipart/form-data" } },
  );
  return response.data.data;
};

// IMG 업로드(다중)
export const uploadImgs = async ({ files }: { files: FormData }): Promise<TUploadImgRes[]> => {
  const response = await Axios.post(`/api/file-service/attached-files`, files, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data.data;
};

// IMG 등록
export const registImg = async ({ fileId }: { fileId: string }): Promise<TUploadImgRes> => {
  const response = await Axios.put(`/api/file-service/register-file/${fileId}`);

  return response.data.data;
};

// IMG 불러오기
export const getImgs = async (id: string | number): Promise<any> => {
  const response = await Axios(`/api/file-service/download-file/${id}`);
  return response.data.data;
};
