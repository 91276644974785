import { useState } from "react";

import { Panel, Title, Content, Pagination, TableNav } from "comp-lib";

import styles from "styles/pages/mydInquiry/index.module.scss";

import Table from "./list/table/Table";
import useTableData from "./list/table/useTableData";
import SearchBox from "./list/SearchBox";

import { useQuery } from "react-query";
import queryKey from "constants/queryKey";
import { getMydInquiryList, getMydInquiryListCount } from "api/mydInquiry";
import { Loading } from "common/other";
import { useSearchParams } from "react-router-dom";

export default function MydInquiry() {
  const defaultTotalCount = { ALL: 0, REGISTERED: 0, PROCESSING: 0, INREVIEW: 0, DONE: 0 };

  const [searchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(defaultTotalCount);

  const status = searchParams.get("status") === "ALL" ? undefined : searchParams.get("status");
  const keyword = searchParams.get("keyword") || undefined;
  const pageNum = Number(searchParams.get("pageNum")) || 1;

  const params = { pageable: { page: pageNum - 1 }, status: status || undefined, keyword: keyword };

  const { data = { customerInquiryPage: { content: [], totalElements: 0, totalPages: 0 } }, isLoading } = useQuery(
    [queryKey.GET_MYD_INQUIRY_LIST, params],
    () => getMydInquiryList(params),
    {
      keepPreviousData: true,
    },
  );

  const { isLoading: isCountLoading } = useQuery(
    [queryKey.GET_MYD_INQUIRY_LIST_COUNT, keyword],
    () => getMydInquiryListCount({ keyword }),
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        setTotalCount(defaultTotalCount);

        let all = 0;

        res.statusBoard.map((e) => {
          all += e.count;
          setTotalCount((prev) => ({ ...prev, ALL: all, [e.status]: e.count }));
        });
      },
    },
  );

  const { columns, rows } = useTableData({
    data: data?.customerInquiryPage?.content!,
    length: data?.customerInquiryPage?.totalElements!,
    pageNo: String(params.pageable.page),
  });

  const navData = [
    {
      label: "전체",
      status: "ALL",
      count: totalCount.ALL,
      numeral: "건",
    },
    {
      label: "접수",
      status: "REGISTERED",
      count: totalCount.REGISTERED,
    },
    {
      label: "진행중",
      status: "PROCESSING",
      count: totalCount.PROCESSING,
    },
    {
      label: "검토중",
      status: "INREVIEW",
      count: totalCount.INREVIEW,
    },
    {
      label: "완료",
      status: "DONE",
      count: totalCount.DONE,
    },
  ];

  if (isLoading || isCountLoading) return <Loading />;

  return (
    <Content>
      <Title text="마이디 문의관리" subText="CS 관리 / 마이디 문의관리" />

      <Panel className={styles.panel}>
        <div className={styles.header}>
          <TableNav dataList={navData} />
          <SearchBox />
        </div>

        <Table columns={columns} rows={rows} />

        <Pagination totalPages={data.customerInquiryPage.totalPages} />
      </Panel>
    </Content>
  );
}
