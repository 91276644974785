import { Axios } from "../base";

import { AdminAdProposalData, AdminAdProposalListData } from "types/advertisement";

// 광고 거래 목록 proposal list 조회
export const getAdminAdProposalList = async (): Promise<AdminAdProposalListData> => {
  const { data } = await Axios(`/api/admin/advertisement/v2/index`);
  return data.data;
};

// 광고 거래 proposal 조회
export const getAdminAdProposal = async (id: string | number): Promise<AdminAdProposalData> => {
  const { data } = await Axios(`/api/admin/advertisement/v2/getAdInfo?id=${id}`);
  return data.data;
};

// 광고 거래 승인
export const acceptAdminAdProposal = async (id: string | number) => {
  const { data } = await Axios.post(`/api/admin/advertisement/v2/acceptAd?id=${id}`);
  return data.data;
};

// 광고 거래 반려
export const rejectAdminAdProposal = async (payload: { id: string | number; reason: string }) => {
  const { data } = await Axios.post(`/api/admin/advertisement/v2/rejectAd`, { ...payload });
  return data.data;
};
