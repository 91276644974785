import { createContext, useContext } from "react";

type TContext = {
  value: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  onChange: (item: string) => void;
};

export const DropdownContext = createContext<TContext>({
  value: "",
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  onToggle: () => {},
  onChange: () => {},
});

export const useDropdownContext = () => useContext(DropdownContext);
