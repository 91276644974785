import { ReactNode } from "react";
import styles from "./table.module.scss";

/**
 * columns 배열과 dataList를 배열로 받아 표를 구현
 * columns: ["column명" | ["column명", "width"]]
 * dataList: [["td" | <td />]]
 */

interface Props {
  columns: (string | string[])[];
  dataList: (string | ReactNode)[][];
}

export default function Table({ columns, dataList }: Props) {
  return (
    <table className={styles.wrapper}>
      <thead>
        <tr>
          {columns.map((column) => {
            if (column === "") return <td key={column} className={styles.blank} />;
            if (typeof column === "string") return <td key={column}>{column}</td>;
            return (
              <td key={column[0]} style={{ width: `${column[1]}` }}>
                {column[0]}
              </td>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {dataList.map((datas) => {
          const key = `table-${datas[0]}`;

          const tdList = datas.map((data, index) => {
            if (index === 0) return null;
            if (index > 8) return null; // 8커럼 이후는 그리지 않음
            const key2 = `${key}-${index}`;
            return <td key={key2}>{data}</td>;
          });
          return <tr key={key}>{tdList}</tr>;
        })}
      </tbody>
    </table>
  );
}
