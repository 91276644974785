import { Axios } from "./base";
import { TCompanyList, TPromotionInfo, TPromotionListInfo, TPromotionDetailInfo } from "types/promotion";

// admin 프로모션 리스트 조회
export const getPromotionList = async (): Promise<TPromotionListInfo[]> => {
  try {
    const { data } = await Axios.get(`/api/v2/admin/promotion/index`);
    return data.data.promotionList;
  } catch {
    throw Error("프로모션 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};

// admin 프로모션 생성
export const postPromotion = async (body: TPromotionInfo) => {
  const { data } = await Axios.post(`/api/v2/admin/promotion/create`, body);
  return data;
};

// admin 프로모션 상세 정보 조회
export const getPromotionDetailInfo = async (id: number): Promise<TPromotionDetailInfo> => {
  const { data } = await Axios.get(`/api/v2/admin/promotion/detail`, { params: { id } });
  return data.data.promotionDetail;
};

// admin 프로모션 철회
export const getPromotionRevoke = async (id: number) => {
  const { data } = await Axios.get(`/api/v2/admin/promotion/revoke`, { params: { id } });
  return data;
};

// admin 프로모션 삭제
export const getPromotionDelete = async (id: number) => {
  const { data } = await Axios.get(`/api/v2/admin/promotion/remove`, { params: { id } });
  return data;
};

// 기업 목록 조회
export const getCompanyList = async (): Promise<TCompanyList[]> => {
  const { data } = await Axios.get(`/api/v2/admin/promotion/company/list`);
  return data.data.companyList;
};
