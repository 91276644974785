import styles from "styles/pages/contentsManage/challenge/detail/detailProgressSetting.module.scss";
import dayjs from "dayjs";
import { getCommasInNum } from "utils";
import { Panel, Title } from "comp-lib";
import { TCreateChallengeResponse } from "types/contentsManage/challenge";

export default function DetailProgressSetting({ data }: { data: TCreateChallengeResponse }) {
  const challengeBefore = [
    {
      name: "모집기간",
      value: `${dayjs(data.applyStartDate).format("YYYY.MM.DD")} ~ ${dayjs(data.applyEndDate).format("YYYY.MM.DD")}`,
    },
    {
      name: "진행기간",
      value: `${dayjs(data.startDate).format("YYYY.MM.DD")} ~ ${dayjs(data.endDate).format("YYYY.MM.DD")}`,
    },
    { name: "1인당 참가포인트", value: `${getCommasInNum(data.applyPointPerPerson)} 포인트 차감` },
    {
      name: "총 참가포인트",
      value: `${getCommasInNum(data.applyPointPerPerson * data.currentParticipantsCount)} 포인트`,
    },
    { name: "성공시 1인당 지급포인트", value: `${getCommasInNum(data.rewardPointPerPerson)} 포인트` },
  ];

  return (
    <div className={styles.wrapper}>
      <Title text={"진행 설정"} />
      <Panel>
        <dl>
          {challengeBefore.map(({ name, value }) => {
            return (
              <div key={name} className={styles.info}>
                <dt>{name}</dt>
                <dd>{value}</dd>
              </div>
            );
          })}
        </dl>
      </Panel>
    </div>
  );
}
