import { ChangeEventHandler, useRef, useState } from "react";
import styles from "./pollRegisterImg.module.scss";
import NotiModal from "../modal/NotiModal";
import { Controller, useFormContext, useWatch } from "react-hook-form";

export default function RegisterImg() {
  const { control, setValue } = useFormContext();

  const accept = ".jpg, .jpeg, .png";
  const allowedFileTypes = accept.split(", ").map((ext) => ext.replace(/^\./, "image/")); // e.g. ['image/jpg', 'image/png', ...]

  const fileRef = useRef<HTMLInputElement>(null);
  const imgData = useWatch({ control, name: "imgFileString" });

  const [errorMsg, setErrorMsg] = useState("");

  const isCdnUrl = typeof imgData === "string";

  const handleChangeImg: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.currentTarget.files?.[0];

    if (!file) {
      setErrorMsg("이미지 파일이 존재하지 않습니다. 다시 등록해주세요.");
      return;
    }

    if (accept && !allowedFileTypes.includes(file.type)) {
      setErrorMsg(`이미지 형식이 올바르지 않습니다.\n이미지는 ${accept} 형식만 가능합니다.`);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (event) => {
      const image = new Image();
      image.src = event.target?.result as string;

      image.onload = () => {
        const { width, height } = image;

        if (width >= 360 && height >= 360 && width % height === 0) {
          setValue(e.target.id, file);
        } else {
          setErrorMsg(`360*360px 이상의 정사각형(1:1) 사이즈\n이미지만 등록 가능합니다.`);
        }

        e.target.value = ""; // 동일한 파일 업로드 가능하도록 파일 정보 초기화
      };
    };
  };

  const handleClickCancel = () => {
    if (!fileRef.current) return;

    setValue("imgFileString", null);
    fileRef.current.value = "";
  };

  return (
    <div className={styles.wrapper}>
      <label htmlFor="imgFileString" className={styles.file_btn}>
        이미지 선택
      </label>

      <Controller
        name="imgFileString"
        control={control}
        rules={{ required: "대표 이미지를 등록해 주세요" }}
        render={() => <input type="file" id="imgFileString" accept={accept} ref={fileRef} onChange={handleChangeImg} />}
      />

      {imgData && (
        <div className={styles.img_text_box}>
          <span> {isCdnUrl ? imgData.split("/").pop() : imgData?.name}</span>

          <button type="button" onClick={handleClickCancel}>
            &times;
          </button>
        </div>
      )}

      {errorMsg && <NotiModal onClose={() => setErrorMsg("")} message={errorMsg} />}
    </div>
  );
}