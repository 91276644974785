import cx from "classnames";
import { ChangeEvent, ComponentPropsWithRef, useState } from "react";
import { CheckIcon } from "assets/svgs/icon";
import styles from "./checkbox.module.scss";

type TProps = Omit<ComponentPropsWithRef<"input">, "type"> & {
  mode?: "square" | "circle";
};

export default function Checkbox({ mode = "square", disabled, onChange, ...props }: TProps) {
  const [isChecked, setIsChecked] = useState(() => {
    if (disabled) {
      return false;
    }

    return props.checked || false;
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    setIsChecked(e.target.checked);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={cx(styles.wrapper, styles[mode], { [styles.active]: isChecked, [styles.disabled]: disabled })}>
      <input type="checkbox" onChange={handleChange} disabled={disabled} {...props} />
      {(disabled || isChecked) && <CheckIcon />}
    </div>
  );
}
