import { getCommasInNum } from "utils";
import { fetchProtobuf, getAccessData } from "../baseFetch";
import {
  PollInfoListResponse,
  PollInfoItem,
  PollDetailResponse,
  PollDetailRequest,
  PollReport,
  PollStatusChangeRequest,
  SpamInfoListResponse,
  SpamInfo,
  SpamStatusChangeRequest,
  SpamStatusChangeResponse,
  PollRegistRequest,
} from "protobuf/OpenApiServerV3";

// 마이디 투표 목록 조회
export const getPollList = async (): Promise<PollInfoItem[]> => {
  const result = await fetchProtobuf({
    url: "/b2b/v3/poll/list",
    init: { method: "GET" },
  });

  const res = PollInfoListResponse.decode(result);

  return res.pollInfoItem;
};

// 투표 상세 정보 조회
export const getPollDetail = async (pollId: string): Promise<PollDetailResponse> => {
  const request = PollDetailRequest.create({ pollId });
  const protobuf = PollDetailRequest.encode(request).finish();

  const result = await fetchProtobuf({
    url: "/b2b/v3/poll/detail",
    data: protobuf,
  });

  const res = PollDetailResponse.decode(result);

  return res;
};

// 투표 생성 및 수정
export const createPoll = async (data: PollRegistRequest) => {
  const pollRegistRequest = PollRegistRequest.create(data);
  const protobuf = PollRegistRequest.encode(pollRegistRequest).finish();

  const result = await fetchProtobuf({
    url: "/b2b/v3/poll/register",
    data: protobuf,
  });

  const res = PollDetailResponse.decode(result);
  if (res.code === 2020)
    throw Error(
      `잔액이 부족하여 생성할 수 없습니다${
        typeof res.companyBalance === "number"
          ? `\n\n총 사용 예정 포인트 ${getCommasInNum(
              data.maxIssuable * data.perAmount,
            )}P\n보유 포인트 ${getCommasInNum(res.companyBalance)}P`
          : ""
      }`,
    );
  if (res.code !== 0) throw Error("투표 생성 중 오류가 발생했습니다");
  if (data.questions.byteLength > 16777215)
    throw Error("투표 크기로 인해 생성할 수 없습니다.\n질문 데이터의 총 길이는 16,777,215자를 넘을 수 없습니다.");

  return res;
};

// 투표 상태 변경
export const patchPollStatus = async (body: PollStatusChangeRequest): Promise<PollReport | undefined> => {
  const request = PollStatusChangeRequest.create(body);
  const protobuf = PollStatusChangeRequest.encode(request).finish();

  const result = await fetchProtobuf({
    url: "/b2b/v3/poll/change-status",
    init: { method: "PATCH" },
    data: protobuf,
  });

  const res = PollDetailResponse.decode(result);

  if (res.code !== 0) throw Error(`투표 ${body.status === 7 ? "종료" : "수정"} 중 오류가 발생했습니다`);

  return res.pollReport;
};

// 댓글 신고 리스트 조회
export const getSpamList = async (): Promise<SpamInfo[]> => {
  const result = await fetchProtobuf({
    url: "/b2b/v3/spam/list",
    init: { method: "GET" },
  });

  const res = SpamInfoListResponse.decode(result);

  return res.spamInfo;
};

// 신고 댓글 status 변경
export const patchSpamStatus = async (body: SpamStatusChangeRequest): Promise<string[]> => {
  const request = SpamStatusChangeRequest.create(body);
  const protobuf = SpamStatusChangeRequest.encode(request).finish();

  const result = await fetchProtobuf({
    url: "/b2b/v3/spam/change-status",
    init: { method: "PATCH" },
    data: protobuf,
  });

  const res = SpamStatusChangeResponse.decode(result);

  if (res.code !== 0) throw Error("신고 댓글 처리 중 오류가 발생했습니다");

  return res.spamCommentIdList;
};
