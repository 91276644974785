import { DateInput } from "comp-lib";
import { Control, FieldPath, FieldValues, useController } from "react-hook-form";

type TControl<T extends FieldValues> = React.ComponentProps<"input"> & {
  name: FieldPath<T>;
  control: Control<T>;
};

export default function DateSelect<T extends FieldValues>({ control, name }: TControl<T>) {
  const {
    field: { value, onChange },
  } = useController({ name, control, rules: { required: "필수 항목을 모두 입력해 주세요." } });

  return <DateInput value={value} onChange={onChange} />;
}
