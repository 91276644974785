import { ReactNode } from "react";
import styles from "./table.module.scss";

type TProps = {
  columns: {
    text: string;
    width?: string;
  }[];
  rows: ReactNode[][];
  activeRows?: number[];
};

// TODO: activeRows 추가하는 것보다 새로운 체크 테이블을 만드는 게 좋지 않을까?
export default function Table({ columns, rows, activeRows }: TProps) {
  return (
    <table className={styles.wrapper}>
      <thead>
        <tr>
          {columns.map((column, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <th key={`${column.text}-${idx}`} style={{ width: column.width }}>
              {column.text}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, rIdx) => {
          const key = `${row[0]}-${rIdx}`;
          const isActive = activeRows?.includes(rIdx);

          const tdList = row.map((cell, cellIdx) => {
            const key2 = `${key}-${cellIdx}`;
            return <td key={key2}>{cell}</td>;
          });
          return (
            <tr key={key} className={isActive ? styles.active : ""}>
              {tdList}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
