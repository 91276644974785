import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useResetRecoilState, atom } from "recoil";
import styles from "styles/common/button/WriteBtn.module.scss";

interface BasicInfoData {
  section: string;
  category: string;
  title: string;
  startDate: string;
  endDate: string;
  point: number;
  people: number;
}

interface ContentData {
  title: string;
  description: string;
  img: {
    filename: string;
    url: string;
  };
  viewType: string;
  answerType: string;
  maxChoice: number;
  answerList: { name: string }[];
}

const basicInfoAtom = atom<BasicInfoData>({
  key: "basicInfoAtom",
  default: {
    section: "오늘의 질문",
    category: "일상",
    title: "",
    startDate: "",
    endDate: "",
    point: 0,
    people: 0,
  },
});

const contentAtom = atom<ContentData>({
  key: "contentAtom",
  default: {
    title: "",
    description: "",
    img: {
      filename: "",
      url: "",
    },
    viewType: "choice",
    answerType: "single",
    maxChoice: 0,
    answerList: [{ name: "" }, { name: "" }, { name: "" }, { name: "" }],
  },
});

const xmlStringAtom = atom({
  key: "xmlStringAtom",
  default: "",
});

const detailDateAtom = atom({
  key: "detailDateAtom",
  default: {
    createdAt: "",
    updatedAt: "",
  },
});

export default function WriteBtn() {
  const resetBasicInfo = useResetRecoilState(basicInfoAtom);
  const resetContent = useResetRecoilState(contentAtom);
  const resetDetailDate = useResetRecoilState(detailDateAtom);
  const resetXmlString = useResetRecoilState(xmlStringAtom);

  useEffect(() => {
    resetBasicInfo();
    resetContent();
    resetDetailDate();
    resetXmlString();
  }, [resetBasicInfo, resetContent, resetDetailDate, resetXmlString]);

  return (
    <div className={styles.wrapper}>
      <Link to="write">신규 등록</Link>
    </div>
  );
}
