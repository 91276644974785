import styles from "./errorModal.module.scss";
import cx from "classnames";
import Portal from "./Portal";
import { useState } from "react";
import Button from "../Button";

type Props = {
  title?: string;
  error?: unknown;
  buttonText?: string;
  onConfirmHandler?: () => void;
  height?: string;
  message?: string;
};

export default function ErrorModal({ title, error, buttonText = "확인", onConfirmHandler, message, height }: Props) {
  const errorMessage =
    error instanceof Error && error.message ? error.message : `알 수 없는 오류가 발생하였습니다.\n다시 시도해주세요.`;

  const [isClick, setIsClick] = useState(false);

  const handleClickConfirm = () => (onConfirmHandler ? onConfirmHandler() : setIsClick(true));

  if (!onConfirmHandler && isClick) return null;

  return (
    <Portal>
      <div className={styles.errorModal}>
        <div className={styles.content} style={{ height }}>
          <div>{title}</div>
          <p className={cx({ [styles.isTitle]: title })}>{message || errorMessage}</p>

          <Button type="button" name="confirm" onClick={handleClickConfirm}>
            {buttonText}
          </Button>
        </div>
      </div>
    </Portal>
  );
}
