import { TTransactionDetail, TTransactionItem } from "types/transaction";
import { Axios } from "./base";

export const getTransList = async (): Promise<TTransactionItem[]> => {
  const response = await Axios.get(`/api/admin/market`);

  return response.data.data.result;
};

export const getTransDetail = async (id: string): Promise<TTransactionDetail> => {
  const response = await Axios.get(`/api/admin/market/detail?proposalId=${id}`);

  return response.data.data;
};
