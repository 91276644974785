import { Panel } from "comp-lib";

import styles from "styles/pages/appPush/detail/message.module.scss";

import { TPushDetail } from "types/appPush";

type TProps = {
  data: TPushDetail;
  changeModalContents: () => void;
  onToggleModal: () => void;
};

const MOVETO_MAP: { [key: string]: string } = {
  0: "거래",
  1: "연결",
  2: "조회",
  3: "포인트몰",
  4: "MY",
  5: "출석체크",
  6: "마이디 투표",
};

export default function Message({ data, changeModalContents, onToggleModal }: TProps) {
  const { title, body, imagePath, moveTo } = data.data.pushMessageDetail;

  const openFile = () => {
    const isValidPath = imagePath?.includes("jpg") || imagePath?.includes("jpeg") || imagePath?.includes("png");

    if (imagePath && isValidPath) {
      window.open(imagePath, "_blank");
    } else {
      changeModalContents();
      onToggleModal();
    }
  };

  return (
    <Panel>
      <h2 className={styles.sectionTitle}>발송 메시지</h2>

      <section className={styles.config}>
        <div className={styles.categoryBox}>
          <span className={styles.category}>제목</span>
          <span>{title}</span>
        </div>

        <div className={styles.categoryBox}>
          <span className={styles.category}>내용</span>
          <div className={styles.content}>{body}</div>
        </div>

        <div className={styles.categoryBox}>
          <span className={styles.category}>이미지</span>

          {imagePath ? (
            <button className={styles.file} type="button" onClick={openFile}>
              {imagePath}
            </button>
          ) : (
            "-"
          )}
        </div>

        <div className={styles.categoryBox}>
          <span className={styles.category}>이동 위치</span>
          <span>{MOVETO_MAP[moveTo]}</span>
        </div>
      </section>
    </Panel>
  );
}
