/* eslint-disable no-alert */
import cx from "classnames";
import { RadioBox, SelectInput } from "common/input";
import FileInput from "common/input/FileInput";
import { invisiblePeriod, moveDstArr, moveType, noticeTypeList, platform, priority } from "constants/notice";
import { useEffect, useRef } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import radioStyles from "styles/common/input/RadioBox.module.scss";
import styles from "styles/pages/notice/write.module.scss";
import { NoticeData } from "types/notice";
import { onKeyDownPreventOnClick } from "utils";
import ShowPeriod from "./ShowPeriod";

export default function Content() {
  const navigate = useNavigate();

  const {
    register,
    control,
    resetField,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<NoticeData>();

  const noticeType = useWatch({ control, name: "noticeType" });
  const moveToType = useWatch({ control, name: "moveTo.moveType" });

  const tmpRadioRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (moveToType === "URL") {
      setValue("moveTo.moveDst", "");
      tmpRadioRef.current!.checked = true;
    }

    if (moveToType === "TAB") {
      resetField("moveTo.moveDst");
    }
  }, [moveToType, resetField, setValue]);

  return (
    <>
      <div className={styles.container}>
        <p className={styles.label}>OS 타겟팅</p>

        <Controller
          name="platform"
          control={control}
          rules={{ required: "OS 타켓을 선택해주세요" }}
          render={({ field: { value, onChange } }) => {
            return <RadioBox name="platform" dataList={platform} value={value} handleCheck={onChange} />;
          }}
        />
      </div>

      {errors.platform && <p className={styles.warning}>{errors.platform.message}</p>}

      <div className={styles.container}>
        <p className={styles.label}>공지 유형</p>

        <Controller
          name="noticeType"
          control={control}
          rules={{ required: "공지 유형을 선택해주세요" }}
          render={({ field: { value, onChange } }) => {
            return <RadioBox name="noticeType" dataList={noticeTypeList} value={value} handleCheck={onChange} />;
          }}
        />
      </div>

      {errors.noticeType && <p className={styles.warning}>{errors.noticeType.message}</p>}

      <div className={styles.container}>
        <p className={styles.label} style={{ lineHeight: "36px" }}>
          제목
        </p>

        <input
          type="text"
          placeholder="제목을 입력해주세요"
          className={cx({ [styles.errTextInput]: errors.title })}
          {...register("title", {
            required: "제목을 입력해주세요",
          })}
        />
      </div>

      {noticeType === "system" && (
        <>
          <div className={styles.container}>
            <p className={styles.label} style={{ lineHeight: "36px" }}>
              내용
            </p>

            <textarea
              placeholder="내용을 입력해주세요"
              className={cx({ [styles.errTextInput]: errors.body })}
              {...register("body", {
                required: "내용을 입력해주세요",
              })}
            />
          </div>
        </>
      )}

      {noticeType !== "system" && (
        <>
          <div className={`${styles.container} ${styles["file-container"]}`}>
            <p className={styles.label} style={{ lineHeight: "36px" }}>
              이미지
            </p>

            <FileInput id="img" validateMsg="이미지를 업로드해주세요" />
            {!watch("img") && (
              <p className={cx(styles.img_not_selected_msg, { [styles.errorText]: errors.img })}>선택된 이미지 없음</p>
            )}
          </div>

          <p className={styles.info}>* 파일 형식은 .jpg .jpeg .png 만 가능합니다.</p>
        </>
      )}

      <div className={styles.container}>
        <p className={styles.label}>노출 기간</p>

        <div className={styles.msg_container}>
          <ShowPeriod />
        </div>
      </div>

      {noticeType === "event" && (
        <>
          <div className={styles.container}>
            <p className={styles.label}>이동 유형</p>

            <div className={`${radioStyles.wrapper} ${styles["wrapper-radio"]}`}>
              <Controller
                name="moveTo.moveType"
                control={control}
                rules={{ required: "이동 유형을 선택해주세요" }}
                render={({ field: { value, onChange } }) => {
                  return <RadioBox name="moveTo.moveType" dataList={moveType} value={value} handleCheck={onChange} />;
                }}
              />
            </div>
          </div>

          {errors.moveTo?.moveType && <p className={styles.warning}>{errors.moveTo?.moveType.message}</p>}

          <div className={styles.container}>
            <p className={styles.label} style={{ lineHeight: "36px" }}>
              이동 위치
            </p>

            <div className={`${radioStyles.wrapper} ${styles["wrapper-radio"]}`} style={{ height: "36px" }}>
              {moveToType === "TAB" ? (
                <Controller
                  name="moveTo.moveDst"
                  control={control}
                  rules={{ required: "이동 위치를 선택해주세요" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <RadioBox name="moveTo.moveDst" dataList={moveDstArr} value={value} handleCheck={onChange} />
                    );
                  }}
                />
              ) : (
                <div className={`${radioStyles.radio} ${radioStyles.admin}`} style={{ marginRight: 0 }}>
                  <input type="radio" ref={tmpRadioRef} id="tmp" />
                  <label htmlFor="tmp">URL</label>
                  <input
                    type="text"
                    style={{ width: "100%", margin: "0 0 0 10px" }}
                    placeholder="https://cdn.prod.myd.world/"
                    {...register("moveTo.moveDst", {
                      required: "이동 위치를 입력해주세요",
                    })}
                    onFocus={() => {
                      tmpRadioRef.current!.checked = true;
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          {errors.moveTo?.moveDst && <p className={styles.warning}>{errors.moveTo?.moveDst.message}</p>}
        </>
      )}

      {noticeType === "event" && (
        <>
          <div className={styles.container}>
            <p className={styles.label}>노출 순서</p>

            <div className={`${radioStyles.wrapper} ${styles["wrapper-radio"]}`}>
              <Controller
                name="priority"
                control={control}
                rules={{ required: "노출 순서를 선택해주세요" }}
                render={({ field: { onChange } }) => {
                  return <SelectInput name="priority" dataList={priority} handleCheck={onChange} />;
                }}
              />
            </div>
          </div>

          {errors.priority && <p className={styles.warning}>{errors.priority?.message}</p>}
        </>
      )}

      {noticeType !== "system" && (
        <>
          <div className={styles.container}>
            <p className={styles.label}>보지 않기 유형</p>

            <div className={`${radioStyles.wrapper} ${styles["wrapper-radio"]}`}>
              <Controller
                name="invisiblePeriod"
                control={control}
                rules={{ required: "보지 않기 유형을 선택해주세요" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <RadioBox name="invisiblePeriod" dataList={invisiblePeriod} value={value} handleCheck={onChange} />
                  );
                }}
              />
            </div>
          </div>

          {errors.invisiblePeriod && <p className={styles.warning}>{errors.invisiblePeriod?.message}</p>}
        </>
      )}

      <div className={styles["btn-wrapper"]}>
        <button type="button" onClick={() => navigate(-1)}>
          취소
        </button>
        <button type="submit" {...onKeyDownPreventOnClick}>
          등록
        </button>
      </div>
    </>
  );
}
