import { useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import styles from "styles/pages/contentsManage/challenge/write/challengeDateBox.module.scss";
import { TChallengeProcess } from "types/contentsManage/challenge";

dayjs.extend(isSameOrBefore);

export default function ChallengeApplyDateBox() {
  const {
    register,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<TChallengeProcess>();

  return (
    <>
      <div className={styles.wrapper}>
        <input
          type="date"
          min={dayjs().add(1, "day").format("YYYY-MM-DD")}
          aria-invalid={errors["applyStartDate"] ? "true" : "false"}
          {...register("applyStartDate", {
            valueAsDate: true,
            onChange: () => trigger("applyEndDate"),
            required: "시작 날짜를 입력해주세요!",
            validate: (value) => {
              const isSameOrBeforeToday = dayjs().isSameOrBefore(value, "day");
              return isSameOrBeforeToday || "시작 날짜는 오늘 이후로 설정해주세요!";
            },
          })}
        />

        <span className={styles.separator}>~</span>

        <input
          type="date"
          min={
            watch("applyStartDate") ? dayjs(watch("applyStartDate")).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD")
          }
          max="2999-12-31"
          aria-invalid={errors["applyEndDate"] ? "true" : "false"}
          {...register("applyEndDate", {
            valueAsDate: true,
            onChange: (e) => {
              trigger("applyEndDate");
              if (!e.target.value) return;
              const target = e.target.value;
              setValue("startDate", dayjs(target).add(1, "day").format("YYYY-MM-DD"));
              if (dayjs(getValues("endDate")).diff(dayjs(target), "day") < 5)
                setValue("endDate", dayjs(target).add(5, "day").format("YYYY-MM-DD"));
            },
            required: "종료 날짜를 입력해주세요!",
            validate: (value) => {
              const diff = dayjs(getValues("applyStartDate") || undefined).diff(value, "day");
              const isSameOrBeforeStart = dayjs(getValues("applyStartDate") || undefined).isSameOrBefore(value, "day");
              if (diff > -2) return "진행기간은 최소 3일 이상 설정해야 합니다!";
              return isSameOrBeforeStart || "종료 날짜는 시작 날짜 이후로 설정해주세요!";
            },
          })}
        />
        <span className={styles.description}>
          (모집기간은 챌린지 진행기간과 겹칠수 없으며 챌린지 이전날짜로 설정 최소3일 이상 설정)
        </span>
      </div>

      {errors["applyStartDate"] && <p className={styles.error}>{errors["applyStartDate"]?.message as string}</p>}
      {errors["applyEndDate"] && <p className={styles.error}>{errors["applyEndDate"]?.message as string}</p>}
    </>
  );
}
