/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { CommonStatusCode, commonStatusCodeFromJSON, commonStatusCodeToJSON } from "./CommonStatusCode";
import {
  ElasticSearchQueryResult,
  PollInfoCategory,
  pollInfoCategoryFromJSON,
  pollInfoCategoryToJSON,
  PollInfoStatus,
  pollInfoStatusFromJSON,
  pollInfoStatusToJSON,
  PollRewardStatus,
  pollRewardStatusFromJSON,
  pollRewardStatusToJSON,
  PollType,
  pollTypeFromJSON,
  pollTypeToJSON,
  Proposal,
  ProposalActiveClient,
  ProposalActiveGlobal,
  ProposalStatic,
  QuestionType,
  questionTypeFromJSON,
  questionTypeToJSON,
  ReactionCode,
  reactionCodeFromJSON,
  reactionCodeToJSON,
  RewardTransactionContentForApp,
  SpamStatus,
  spamStatusFromJSON,
  spamStatusToJSON,
} from "./OpenApiServerV3DataTypes";
import { RewardTransactionContent } from "./OpenApiServerV3Middleware";
import Long from "long";

export const protobufPackage = "io.snplab.myd.protocol.v3.schema";

/** Asynchronous request status */
export enum AsyncRequestStatus {
  /** STATUS_ASYNC_REQUEST_BEGIN - place holder for 0 */
  STATUS_ASYNC_REQUEST_BEGIN = 0,
  STATUS_ASYNC_REQUEST_ACCEPTED = 1,
  STATUS_ASYNC_REQUEST_INPROGRESS = 2,
  STATUS_ASYNC_REQUEST_PROCESSED = 3,
  STATUS_ASYNC_REQUEST_FAILED = 4,
  STATUS_ASYNC_REQUEST_REQUEST_NOT_FOUND = 5,
  STATUS_ASYNC_REQUEST_DUPLICATED = 6,
  UNRECOGNIZED = -1,
}

export function asyncRequestStatusFromJSON(object: any): AsyncRequestStatus {
  switch (object) {
    case 0:
    case "STATUS_ASYNC_REQUEST_BEGIN":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_BEGIN;
    case 1:
    case "STATUS_ASYNC_REQUEST_ACCEPTED":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_ACCEPTED;
    case 2:
    case "STATUS_ASYNC_REQUEST_INPROGRESS":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_INPROGRESS;
    case 3:
    case "STATUS_ASYNC_REQUEST_PROCESSED":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_PROCESSED;
    case 4:
    case "STATUS_ASYNC_REQUEST_FAILED":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_FAILED;
    case 5:
    case "STATUS_ASYNC_REQUEST_REQUEST_NOT_FOUND":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_REQUEST_NOT_FOUND;
    case 6:
    case "STATUS_ASYNC_REQUEST_DUPLICATED":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_DUPLICATED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AsyncRequestStatus.UNRECOGNIZED;
  }
}

export function asyncRequestStatusToJSON(object: AsyncRequestStatus): string {
  switch (object) {
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_BEGIN:
      return "STATUS_ASYNC_REQUEST_BEGIN";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_ACCEPTED:
      return "STATUS_ASYNC_REQUEST_ACCEPTED";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_INPROGRESS:
      return "STATUS_ASYNC_REQUEST_INPROGRESS";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_PROCESSED:
      return "STATUS_ASYNC_REQUEST_PROCESSED";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_FAILED:
      return "STATUS_ASYNC_REQUEST_FAILED";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_REQUEST_NOT_FOUND:
      return "STATUS_ASYNC_REQUEST_REQUEST_NOT_FOUND";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_DUPLICATED:
      return "STATUS_ASYNC_REQUEST_DUPLICATED";
    case AsyncRequestStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** List reward history request type */
export enum ListRewardHistoryRequestType {
  /** LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN - place holder for 0 */
  LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN = 0,
  LIST_REWARD_HISTORY_REQUEST_TYPE_ALL = 1,
  LIST_REWARD_HISTORY_REQUEST_TYPE_SAVE = 2,
  LIST_REWARD_HISTORY_REQUEST_TYPE_USE = 3,
  UNRECOGNIZED = -1,
}

export function listRewardHistoryRequestTypeFromJSON(object: any): ListRewardHistoryRequestType {
  switch (object) {
    case 0:
    case "LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN":
      return ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN;
    case 1:
    case "LIST_REWARD_HISTORY_REQUEST_TYPE_ALL":
      return ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_ALL;
    case 2:
    case "LIST_REWARD_HISTORY_REQUEST_TYPE_SAVE":
      return ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_SAVE;
    case 3:
    case "LIST_REWARD_HISTORY_REQUEST_TYPE_USE":
      return ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_USE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListRewardHistoryRequestType.UNRECOGNIZED;
  }
}

export function listRewardHistoryRequestTypeToJSON(object: ListRewardHistoryRequestType): string {
  switch (object) {
    case ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN:
      return "LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN";
    case ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_ALL:
      return "LIST_REWARD_HISTORY_REQUEST_TYPE_ALL";
    case ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_SAVE:
      return "LIST_REWARD_HISTORY_REQUEST_TYPE_SAVE";
    case ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_USE:
      return "LIST_REWARD_HISTORY_REQUEST_TYPE_USE";
    case ListRewardHistoryRequestType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CouponApiType {
  COUPON_API_TYPE_BEGIN = 0,
  COUPON_ISSUE = 1,
  COUPON_REVOKE = 2,
  COUPON_DETAILS = 3,
  UNRECOGNIZED = -1,
}

export function couponApiTypeFromJSON(object: any): CouponApiType {
  switch (object) {
    case 0:
    case "COUPON_API_TYPE_BEGIN":
      return CouponApiType.COUPON_API_TYPE_BEGIN;
    case 1:
    case "COUPON_ISSUE":
      return CouponApiType.COUPON_ISSUE;
    case 2:
    case "COUPON_REVOKE":
      return CouponApiType.COUPON_REVOKE;
    case 3:
    case "COUPON_DETAILS":
      return CouponApiType.COUPON_DETAILS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CouponApiType.UNRECOGNIZED;
  }
}

export function couponApiTypeToJSON(object: CouponApiType): string {
  switch (object) {
    case CouponApiType.COUPON_API_TYPE_BEGIN:
      return "COUPON_API_TYPE_BEGIN";
    case CouponApiType.COUPON_ISSUE:
      return "COUPON_ISSUE";
    case CouponApiType.COUPON_REVOKE:
      return "COUPON_REVOKE";
    case CouponApiType.COUPON_DETAILS:
      return "COUPON_DETAILS";
    case CouponApiType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Coupon status */
export enum CouponStatus {
  /** COUPON_STATUS_BEGIN - place holder for 0 */
  COUPON_STATUS_BEGIN = 0,
  COUPON_STATUS_PUBLISHED = 1,
  COUPON_STATUS_USED = 2,
  COUPON_STATUS_RETURN = 3,
  COUPON_STATUS_MANAGED_DISPOSAL = 4,
  COUPON_STATUS_REFUND = 5,
  COUPON_STATUS_REPUBLISHED = 6,
  COUPON_STATUS_CANCELLED = 7,
  COUPON_STATUS_EXPIRED = 8,
  COUPON_STATUS_INACTIVE_VOUCHER = 9,
  COUPON_STATUS_REFUNDED_10 = 10,
  COUPON_STATUS_EXPIRED_BALANCE_11 = 11,
  COUPON_STATUS_CANCELLED_EXPIRED_12 = 12,
  COUPON_STATUS_EXCHANGED_13 = 13,
  COUPON_STATUS_REFUND_14 = 14,
  COUPON_STATUS_REFUND_15 = 15,
  COUPON_STATUS_CANCELLED_16 = 16,
  COUPON_STATUS_REGISTERED_21 = 21,
  COUPON_STATUS_CANCELLED_22 = 22,
  COUPON_STATUS_OCCUPIED_23 = 23,
  COUPON_STATUS_TEMPORARY_24 = 24,
  COUPON_STATUS_ID_READY = 100,
  UNRECOGNIZED = -1,
}

export function couponStatusFromJSON(object: any): CouponStatus {
  switch (object) {
    case 0:
    case "COUPON_STATUS_BEGIN":
      return CouponStatus.COUPON_STATUS_BEGIN;
    case 1:
    case "COUPON_STATUS_PUBLISHED":
      return CouponStatus.COUPON_STATUS_PUBLISHED;
    case 2:
    case "COUPON_STATUS_USED":
      return CouponStatus.COUPON_STATUS_USED;
    case 3:
    case "COUPON_STATUS_RETURN":
      return CouponStatus.COUPON_STATUS_RETURN;
    case 4:
    case "COUPON_STATUS_MANAGED_DISPOSAL":
      return CouponStatus.COUPON_STATUS_MANAGED_DISPOSAL;
    case 5:
    case "COUPON_STATUS_REFUND":
      return CouponStatus.COUPON_STATUS_REFUND;
    case 6:
    case "COUPON_STATUS_REPUBLISHED":
      return CouponStatus.COUPON_STATUS_REPUBLISHED;
    case 7:
    case "COUPON_STATUS_CANCELLED":
      return CouponStatus.COUPON_STATUS_CANCELLED;
    case 8:
    case "COUPON_STATUS_EXPIRED":
      return CouponStatus.COUPON_STATUS_EXPIRED;
    case 9:
    case "COUPON_STATUS_INACTIVE_VOUCHER":
      return CouponStatus.COUPON_STATUS_INACTIVE_VOUCHER;
    case 10:
    case "COUPON_STATUS_REFUNDED_10":
      return CouponStatus.COUPON_STATUS_REFUNDED_10;
    case 11:
    case "COUPON_STATUS_EXPIRED_BALANCE_11":
      return CouponStatus.COUPON_STATUS_EXPIRED_BALANCE_11;
    case 12:
    case "COUPON_STATUS_CANCELLED_EXPIRED_12":
      return CouponStatus.COUPON_STATUS_CANCELLED_EXPIRED_12;
    case 13:
    case "COUPON_STATUS_EXCHANGED_13":
      return CouponStatus.COUPON_STATUS_EXCHANGED_13;
    case 14:
    case "COUPON_STATUS_REFUND_14":
      return CouponStatus.COUPON_STATUS_REFUND_14;
    case 15:
    case "COUPON_STATUS_REFUND_15":
      return CouponStatus.COUPON_STATUS_REFUND_15;
    case 16:
    case "COUPON_STATUS_CANCELLED_16":
      return CouponStatus.COUPON_STATUS_CANCELLED_16;
    case 21:
    case "COUPON_STATUS_REGISTERED_21":
      return CouponStatus.COUPON_STATUS_REGISTERED_21;
    case 22:
    case "COUPON_STATUS_CANCELLED_22":
      return CouponStatus.COUPON_STATUS_CANCELLED_22;
    case 23:
    case "COUPON_STATUS_OCCUPIED_23":
      return CouponStatus.COUPON_STATUS_OCCUPIED_23;
    case 24:
    case "COUPON_STATUS_TEMPORARY_24":
      return CouponStatus.COUPON_STATUS_TEMPORARY_24;
    case 100:
    case "COUPON_STATUS_ID_READY":
      return CouponStatus.COUPON_STATUS_ID_READY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CouponStatus.UNRECOGNIZED;
  }
}

export function couponStatusToJSON(object: CouponStatus): string {
  switch (object) {
    case CouponStatus.COUPON_STATUS_BEGIN:
      return "COUPON_STATUS_BEGIN";
    case CouponStatus.COUPON_STATUS_PUBLISHED:
      return "COUPON_STATUS_PUBLISHED";
    case CouponStatus.COUPON_STATUS_USED:
      return "COUPON_STATUS_USED";
    case CouponStatus.COUPON_STATUS_RETURN:
      return "COUPON_STATUS_RETURN";
    case CouponStatus.COUPON_STATUS_MANAGED_DISPOSAL:
      return "COUPON_STATUS_MANAGED_DISPOSAL";
    case CouponStatus.COUPON_STATUS_REFUND:
      return "COUPON_STATUS_REFUND";
    case CouponStatus.COUPON_STATUS_REPUBLISHED:
      return "COUPON_STATUS_REPUBLISHED";
    case CouponStatus.COUPON_STATUS_CANCELLED:
      return "COUPON_STATUS_CANCELLED";
    case CouponStatus.COUPON_STATUS_EXPIRED:
      return "COUPON_STATUS_EXPIRED";
    case CouponStatus.COUPON_STATUS_INACTIVE_VOUCHER:
      return "COUPON_STATUS_INACTIVE_VOUCHER";
    case CouponStatus.COUPON_STATUS_REFUNDED_10:
      return "COUPON_STATUS_REFUNDED_10";
    case CouponStatus.COUPON_STATUS_EXPIRED_BALANCE_11:
      return "COUPON_STATUS_EXPIRED_BALANCE_11";
    case CouponStatus.COUPON_STATUS_CANCELLED_EXPIRED_12:
      return "COUPON_STATUS_CANCELLED_EXPIRED_12";
    case CouponStatus.COUPON_STATUS_EXCHANGED_13:
      return "COUPON_STATUS_EXCHANGED_13";
    case CouponStatus.COUPON_STATUS_REFUND_14:
      return "COUPON_STATUS_REFUND_14";
    case CouponStatus.COUPON_STATUS_REFUND_15:
      return "COUPON_STATUS_REFUND_15";
    case CouponStatus.COUPON_STATUS_CANCELLED_16:
      return "COUPON_STATUS_CANCELLED_16";
    case CouponStatus.COUPON_STATUS_REGISTERED_21:
      return "COUPON_STATUS_REGISTERED_21";
    case CouponStatus.COUPON_STATUS_CANCELLED_22:
      return "COUPON_STATUS_CANCELLED_22";
    case CouponStatus.COUPON_STATUS_OCCUPIED_23:
      return "COUPON_STATUS_OCCUPIED_23";
    case CouponStatus.COUPON_STATUS_TEMPORARY_24:
      return "COUPON_STATUS_TEMPORARY_24";
    case CouponStatus.COUPON_STATUS_ID_READY:
      return "COUPON_STATUS_ID_READY";
    case CouponStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Blockchain role */
export enum BlockchainRole {
  /** ADMIN - Blockchain administrator */
  ADMIN = 0,
  /** PLATFORM_MANAGER - MyD platform manager */
  PLATFORM_MANAGER = 1,
  /** PLATFORM_OPERATOR - MyD platform operator */
  PLATFORM_OPERATOR = 2,
  /** ROLE_MAINTAINER - Role maintainer (global) */
  ROLE_MAINTAINER = 3,
  /** ENDORSER - DID endorser (one for each server) */
  ENDORSER = 4,
  /** DATA_VERIFIER - MyD data verifier */
  DATA_VERIFIER = 5,
  /** GAS_STATIONER - Gas stationer */
  GAS_STATIONER = 6,
  /** COMPANY - Company */
  COMPANY = 7,
  /** PROPOSAL - Proposal */
  PROPOSAL = 8,
  /** SNPLAB_BIS - SNPLAB BIS (token holder) */
  SNPLAB_BIS = 9,
  /** BIS_MINTER - BIS MINTER (token spender) */
  BIS_MINTER = 10,
  /** BIS_COUPON - BIS_COUPON (coupon purchaser) */
  BIS_COUPON = 11,
  /** MYD_USER - MYD2, MYD3, SDK users */
  MYD_USER = 20,
  /** UNKNOWN - UNKNOWN */
  UNKNOWN = 99,
  UNRECOGNIZED = -1,
}

export function blockchainRoleFromJSON(object: any): BlockchainRole {
  switch (object) {
    case 0:
    case "ADMIN":
      return BlockchainRole.ADMIN;
    case 1:
    case "PLATFORM_MANAGER":
      return BlockchainRole.PLATFORM_MANAGER;
    case 2:
    case "PLATFORM_OPERATOR":
      return BlockchainRole.PLATFORM_OPERATOR;
    case 3:
    case "ROLE_MAINTAINER":
      return BlockchainRole.ROLE_MAINTAINER;
    case 4:
    case "ENDORSER":
      return BlockchainRole.ENDORSER;
    case 5:
    case "DATA_VERIFIER":
      return BlockchainRole.DATA_VERIFIER;
    case 6:
    case "GAS_STATIONER":
      return BlockchainRole.GAS_STATIONER;
    case 7:
    case "COMPANY":
      return BlockchainRole.COMPANY;
    case 8:
    case "PROPOSAL":
      return BlockchainRole.PROPOSAL;
    case 9:
    case "SNPLAB_BIS":
      return BlockchainRole.SNPLAB_BIS;
    case 10:
    case "BIS_MINTER":
      return BlockchainRole.BIS_MINTER;
    case 11:
    case "BIS_COUPON":
      return BlockchainRole.BIS_COUPON;
    case 20:
    case "MYD_USER":
      return BlockchainRole.MYD_USER;
    case 99:
    case "UNKNOWN":
      return BlockchainRole.UNKNOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BlockchainRole.UNRECOGNIZED;
  }
}

export function blockchainRoleToJSON(object: BlockchainRole): string {
  switch (object) {
    case BlockchainRole.ADMIN:
      return "ADMIN";
    case BlockchainRole.PLATFORM_MANAGER:
      return "PLATFORM_MANAGER";
    case BlockchainRole.PLATFORM_OPERATOR:
      return "PLATFORM_OPERATOR";
    case BlockchainRole.ROLE_MAINTAINER:
      return "ROLE_MAINTAINER";
    case BlockchainRole.ENDORSER:
      return "ENDORSER";
    case BlockchainRole.DATA_VERIFIER:
      return "DATA_VERIFIER";
    case BlockchainRole.GAS_STATIONER:
      return "GAS_STATIONER";
    case BlockchainRole.COMPANY:
      return "COMPANY";
    case BlockchainRole.PROPOSAL:
      return "PROPOSAL";
    case BlockchainRole.SNPLAB_BIS:
      return "SNPLAB_BIS";
    case BlockchainRole.BIS_MINTER:
      return "BIS_MINTER";
    case BlockchainRole.BIS_COUPON:
      return "BIS_COUPON";
    case BlockchainRole.MYD_USER:
      return "MYD_USER";
    case BlockchainRole.UNKNOWN:
      return "UNKNOWN";
    case BlockchainRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Did filtering mode
 * Allowlist: the proposal is hidden by default, only allowed did can see the proposal
 * Blocklist: the proposal is visible by default, only few people cannot see the proposal
 */
export enum DidFilteringMode {
  /** FILTER_BEGIN - place holder for 0 */
  FILTER_BEGIN = 0,
  FILTER_ALLOWLIST = 1,
  FILTER_BLOCKLIST = 2,
  UNRECOGNIZED = -1,
}

export function didFilteringModeFromJSON(object: any): DidFilteringMode {
  switch (object) {
    case 0:
    case "FILTER_BEGIN":
      return DidFilteringMode.FILTER_BEGIN;
    case 1:
    case "FILTER_ALLOWLIST":
      return DidFilteringMode.FILTER_ALLOWLIST;
    case 2:
    case "FILTER_BLOCKLIST":
      return DidFilteringMode.FILTER_BLOCKLIST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DidFilteringMode.UNRECOGNIZED;
  }
}

export function didFilteringModeToJSON(object: DidFilteringMode): string {
  switch (object) {
    case DidFilteringMode.FILTER_BEGIN:
      return "FILTER_BEGIN";
    case DidFilteringMode.FILTER_ALLOWLIST:
      return "FILTER_ALLOWLIST";
    case DidFilteringMode.FILTER_BLOCKLIST:
      return "FILTER_BLOCKLIST";
    case DidFilteringMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Simple user type */
export enum SimpleUserType {
  /** USER_TYPE_BEGIN - place holder for 0 */
  USER_TYPE_BEGIN = 0,
  USER_MYD3 = 1,
  USER_MYD2 = 2,
  USER_NOT_FOUND = 3,
  UNRECOGNIZED = -1,
}

export function simpleUserTypeFromJSON(object: any): SimpleUserType {
  switch (object) {
    case 0:
    case "USER_TYPE_BEGIN":
      return SimpleUserType.USER_TYPE_BEGIN;
    case 1:
    case "USER_MYD3":
      return SimpleUserType.USER_MYD3;
    case 2:
    case "USER_MYD2":
      return SimpleUserType.USER_MYD2;
    case 3:
    case "USER_NOT_FOUND":
      return SimpleUserType.USER_NOT_FOUND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SimpleUserType.UNRECOGNIZED;
  }
}

export function simpleUserTypeToJSON(object: SimpleUserType): string {
  switch (object) {
    case SimpleUserType.USER_TYPE_BEGIN:
      return "USER_TYPE_BEGIN";
    case SimpleUserType.USER_MYD3:
      return "USER_MYD3";
    case SimpleUserType.USER_MYD2:
      return "USER_MYD2";
    case SimpleUserType.USER_NOT_FOUND:
      return "USER_NOT_FOUND";
    case SimpleUserType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum HomeBannerCardType {
  /** TYPE_BEGIN - place holder for 0 */
  TYPE_BEGIN = 0,
  SIMPLE_BANNER_LANDING = 1,
  SINGLE_PROPOSAL = 2,
  CATEGORY_PROPOSAL_PURPOSE = 3,
  UNRECOGNIZED = -1,
}

export function homeBannerCardTypeFromJSON(object: any): HomeBannerCardType {
  switch (object) {
    case 0:
    case "TYPE_BEGIN":
      return HomeBannerCardType.TYPE_BEGIN;
    case 1:
    case "SIMPLE_BANNER_LANDING":
      return HomeBannerCardType.SIMPLE_BANNER_LANDING;
    case 2:
    case "SINGLE_PROPOSAL":
      return HomeBannerCardType.SINGLE_PROPOSAL;
    case 3:
    case "CATEGORY_PROPOSAL_PURPOSE":
      return HomeBannerCardType.CATEGORY_PROPOSAL_PURPOSE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HomeBannerCardType.UNRECOGNIZED;
  }
}

export function homeBannerCardTypeToJSON(object: HomeBannerCardType): string {
  switch (object) {
    case HomeBannerCardType.TYPE_BEGIN:
      return "TYPE_BEGIN";
    case HomeBannerCardType.SIMPLE_BANNER_LANDING:
      return "SIMPLE_BANNER_LANDING";
    case HomeBannerCardType.SINGLE_PROPOSAL:
      return "SINGLE_PROPOSAL";
    case HomeBannerCardType.CATEGORY_PROPOSAL_PURPOSE:
      return "CATEGORY_PROPOSAL_PURPOSE";
    case HomeBannerCardType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum HomeBannerSupportedPlatform {
  /** SUPPORT_BEGIN - place holder for 0 */
  SUPPORT_BEGIN = 0,
  SUPPORT_ALL = 1,
  SUPPORT_ANDROID = 2,
  SUPPORT_IOS = 3,
  UNRECOGNIZED = -1,
}

export function homeBannerSupportedPlatformFromJSON(object: any): HomeBannerSupportedPlatform {
  switch (object) {
    case 0:
    case "SUPPORT_BEGIN":
      return HomeBannerSupportedPlatform.SUPPORT_BEGIN;
    case 1:
    case "SUPPORT_ALL":
      return HomeBannerSupportedPlatform.SUPPORT_ALL;
    case 2:
    case "SUPPORT_ANDROID":
      return HomeBannerSupportedPlatform.SUPPORT_ANDROID;
    case 3:
    case "SUPPORT_IOS":
      return HomeBannerSupportedPlatform.SUPPORT_IOS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HomeBannerSupportedPlatform.UNRECOGNIZED;
  }
}

export function homeBannerSupportedPlatformToJSON(object: HomeBannerSupportedPlatform): string {
  switch (object) {
    case HomeBannerSupportedPlatform.SUPPORT_BEGIN:
      return "SUPPORT_BEGIN";
    case HomeBannerSupportedPlatform.SUPPORT_ALL:
      return "SUPPORT_ALL";
    case HomeBannerSupportedPlatform.SUPPORT_ANDROID:
      return "SUPPORT_ANDROID";
    case HomeBannerSupportedPlatform.SUPPORT_IOS:
      return "SUPPORT_IOS";
    case HomeBannerSupportedPlatform.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum BuildableResourceType {
  RESOURCE_TYPE_BEGIN = 0,
  APP_VERSION = 1,
  HOME_BANNER = 2,
  WEB_RESOURCE = 3,
  APPLICATION_RESOURCE = 4,
  UNRECOGNIZED = -1,
}

export function buildableResourceTypeFromJSON(object: any): BuildableResourceType {
  switch (object) {
    case 0:
    case "RESOURCE_TYPE_BEGIN":
      return BuildableResourceType.RESOURCE_TYPE_BEGIN;
    case 1:
    case "APP_VERSION":
      return BuildableResourceType.APP_VERSION;
    case 2:
    case "HOME_BANNER":
      return BuildableResourceType.HOME_BANNER;
    case 3:
    case "WEB_RESOURCE":
      return BuildableResourceType.WEB_RESOURCE;
    case 4:
    case "APPLICATION_RESOURCE":
      return BuildableResourceType.APPLICATION_RESOURCE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BuildableResourceType.UNRECOGNIZED;
  }
}

export function buildableResourceTypeToJSON(object: BuildableResourceType): string {
  switch (object) {
    case BuildableResourceType.RESOURCE_TYPE_BEGIN:
      return "RESOURCE_TYPE_BEGIN";
    case BuildableResourceType.APP_VERSION:
      return "APP_VERSION";
    case BuildableResourceType.HOME_BANNER:
      return "HOME_BANNER";
    case BuildableResourceType.WEB_RESOURCE:
      return "WEB_RESOURCE";
    case BuildableResourceType.APPLICATION_RESOURCE:
      return "APPLICATION_RESOURCE";
    case BuildableResourceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Request Task about Coupon manager type */
export enum CouponManagerRequestType {
  COUPON_REQUEST_TYPE_BEGIN = 0,
  COUPON_REQUEST_TYPE_LIST = 1,
  COUPON_REQUEST_TYPE_INFO = 2,
  COUPON_REQUEST_TYPE_REVOKE = 3,
  UNRECOGNIZED = -1,
}

export function couponManagerRequestTypeFromJSON(object: any): CouponManagerRequestType {
  switch (object) {
    case 0:
    case "COUPON_REQUEST_TYPE_BEGIN":
      return CouponManagerRequestType.COUPON_REQUEST_TYPE_BEGIN;
    case 1:
    case "COUPON_REQUEST_TYPE_LIST":
      return CouponManagerRequestType.COUPON_REQUEST_TYPE_LIST;
    case 2:
    case "COUPON_REQUEST_TYPE_INFO":
      return CouponManagerRequestType.COUPON_REQUEST_TYPE_INFO;
    case 3:
    case "COUPON_REQUEST_TYPE_REVOKE":
      return CouponManagerRequestType.COUPON_REQUEST_TYPE_REVOKE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CouponManagerRequestType.UNRECOGNIZED;
  }
}

export function couponManagerRequestTypeToJSON(object: CouponManagerRequestType): string {
  switch (object) {
    case CouponManagerRequestType.COUPON_REQUEST_TYPE_BEGIN:
      return "COUPON_REQUEST_TYPE_BEGIN";
    case CouponManagerRequestType.COUPON_REQUEST_TYPE_LIST:
      return "COUPON_REQUEST_TYPE_LIST";
    case CouponManagerRequestType.COUPON_REQUEST_TYPE_INFO:
      return "COUPON_REQUEST_TYPE_INFO";
    case CouponManagerRequestType.COUPON_REQUEST_TYPE_REVOKE:
      return "COUPON_REQUEST_TYPE_REVOKE";
    case CouponManagerRequestType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Coupon request parameter Type */
export enum CouponRequestParameterType {
  COUPON_REQUEST_PARAMETER_BEGIN = 0,
  COUPON_REQUEST_PARAMETER_DID = 1,
  COUPON_REQUEST_PARAMETER_TRANSACTION_ID = 2,
  UNRECOGNIZED = -1,
}

export function couponRequestParameterTypeFromJSON(object: any): CouponRequestParameterType {
  switch (object) {
    case 0:
    case "COUPON_REQUEST_PARAMETER_BEGIN":
      return CouponRequestParameterType.COUPON_REQUEST_PARAMETER_BEGIN;
    case 1:
    case "COUPON_REQUEST_PARAMETER_DID":
      return CouponRequestParameterType.COUPON_REQUEST_PARAMETER_DID;
    case 2:
    case "COUPON_REQUEST_PARAMETER_TRANSACTION_ID":
      return CouponRequestParameterType.COUPON_REQUEST_PARAMETER_TRANSACTION_ID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CouponRequestParameterType.UNRECOGNIZED;
  }
}

export function couponRequestParameterTypeToJSON(object: CouponRequestParameterType): string {
  switch (object) {
    case CouponRequestParameterType.COUPON_REQUEST_PARAMETER_BEGIN:
      return "COUPON_REQUEST_PARAMETER_BEGIN";
    case CouponRequestParameterType.COUPON_REQUEST_PARAMETER_DID:
      return "COUPON_REQUEST_PARAMETER_DID";
    case CouponRequestParameterType.COUPON_REQUEST_PARAMETER_TRANSACTION_ID:
      return "COUPON_REQUEST_PARAMETER_TRANSACTION_ID";
    case CouponRequestParameterType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** DID registration material */
export interface RegisterRequestMaterial {
  /** constant prefix for register request */
  materialPrefix: string;
  /** did */
  did: string;
  /** besu address */
  besuAddress: string;
  /** nonce generated by the server */
  nonce: string;
  /** secp256k1 public key */
  publicKey: Uint8Array;
  /** ed25519 public key; only for the migration case */
  verkey?:
    | Uint8Array
    | undefined;
  /** DID document to register */
  document?: string | undefined;
}

/** Request of the device attestation */
export interface DeviceAttestationRequest {
  /** app version string */
  appVersion: string;
  /** parcel data */
  parcel: string;
  /** client random */
  clientKeyMaterial: Uint8Array;
  /** registration message */
  registrationMessage:
    | RegisterRequestMaterial
    | undefined;
  /** signature of registration message */
  registrationSignature: Uint8Array;
  /** signature of migration message */
  migrationSignature?: Uint8Array | undefined;
}

/** Response to DeviceAttestationRequest */
export interface DeviceAttestationResponse {
  /** status */
  code: CommonStatusCode;
  /** server random */
  serverKeyMaterial: Uint8Array;
  /** ephemeral public key for pre-master secret */
  ephemeralPublicKey: Uint8Array;
  /** encrypted app key */
  encryptedAppKey: Uint8Array;
  /** secret key to protect app configuration */
  encryptedSecretKey: Uint8Array;
  /** iv to protect app configuration */
  encryptedInitialVector: Uint8Array;
}

/** Request of the device registration (for attestation exceptions) */
export interface RegisterDeviceRequest {
  /** app version string */
  appVersion: string;
  /** client key material */
  clientKeyMaterial: Uint8Array;
  /** verification message using app-key */
  verificationMessage: Uint8Array;
  /** registration message */
  registrationMessage:
    | RegisterRequestMaterial
    | undefined;
  /** signature of registration message */
  registrationSignature: Uint8Array;
  /** signature of migration message */
  migrationSignature?: Uint8Array | undefined;
}

/** Response to RegisterDeviceRequest */
export interface RegisterDeviceResponse {
  /** status */
  code: CommonStatusCode;
  /** server random */
  serverKeyMaterial: Uint8Array;
  /** ephemeral public key for pre-master secret */
  ephemeralPublicKey: Uint8Array;
  /** secret key to protect app configuration */
  encryptedSecretKey: Uint8Array;
  /** iv to protect app configuration */
  encryptedInitialVector: Uint8Array;
}

/**
 * Get last RequestId for asynchronous requests
 * RequestId is a sequential 1-based number generated by the DID owner (client).
 * It targets to assure a safe transaction from a network delay, failure or logical faults.
 *
 * The number should be maintained by client unless the application is cleaned up from a device.
 * The client could obtain the last RequestId for the DID when it is lost.
 * Thus, the client should use "last request id + 1" for the next request.
 *
 * X-Auth-Token is required.
 */
export interface GetLastRequestIdRequest {
}

/** Response to GetLastRequestIdRequest */
export interface GetLastRequestIdResponse {
  /** request id used last time */
  lastRequestId: number;
}

/**
 * Client acknowledge the response is received.
 * The server would clear the request information from the database.
 *
 * X-Auth-Token is required.
 */
export interface AckRequestIdRequest {
  requestId: number;
}

/** Response to AckRequestIdRequest */
export interface AckRequestIdResponse {
  status: AsyncRequestStatus;
}

/**
 * Get nonce before requesting the access token
 * The client need to register the DID first.
 */
export interface GetAuthNonceRequest {
  besuAddress: string;
}

/** Response to GetAuthNonceRequest */
export interface GetAuthNonceResponse {
  nonce: string;
}

/**
 * Get an access token from the server.
 * Nonce is requred before asking an access token.
 */
export interface GetAuthAccessTokenRequest {
  appVersion: string;
  did: string;
  besuAddress: string;
  mid?: string | undefined;
  nonce: string;
  /** Hex string */
  verifyCode: string;
  /** Hex string */
  signature: string;
}

/** Response to GetAuthAccessTokenRequest */
export interface GetAuthAccessTokenResponse {
  command: string;
  accessToken: string;
  midCount: number;
  statusCode: CommonStatusCode;
}

/** Get an access token based on the wallet. */
export interface GetAuthWalletBasedAccessTokenRequest {
  besuAddress: string;
  nonce: string;
  signature: string;
}

/** Response to GetAuthWalletBasedAccessTokenRequest */
export interface GetAuthWalletBasedAccessTokenResponse {
  accessToken: string;
}

/**
 * Get an access token to connect On-Premise server (PI)
 *
 * X-Auth-Token is required.
 */
export interface GetPiAccessTokenRequest {
  pdsId: string;
}

/** Response to GetPiAccessTokenRequest */
export interface GetPiAccessTokenResponse {
  piAccessToken: string;
}

/**
 * Get the ticket ID to consume
 * No request argument needed since X-Auth-Token already has the pdsId within.
 *
 * X-Auth-Token is required.
 */
export interface GetTicketIdToConsumeRequest {
}

/** Response to GetTicketIdToConsumeRequest */
export interface GetTicketIdToConsumeResponse {
  ticketId: string;
}

/** List proposal and ticket information */
export interface ListProposalRequest {
  /** empty to fetch all proposals */
  updatedAfter?:
    | string
    | undefined;
  /** fetch static */
  fetchStatic: boolean;
  /** fetch active global */
  fetchActiveGlobal: boolean;
  /** fetch active client */
  fetchActiveClient: boolean;
  /** fetch specific purpose only; fetch all when empty */
  purposes: number[];
  /** fetch specific proposals; fetch all when empty */
  proposalIds: string[];
  /**
   * fetch finished proposals only
   * "Finished" means the proposal is totally completed and refunded so no need to be shown to user
   * it will fetch only the finished proposals, please set it as false if it is not necessary.
   */
  finishedOnly: boolean;
}

/** Response to ListProposalRequest */
export interface ListProposalResponse {
  /** requested proposal information */
  proposals: Proposal[];
}

/** Issue ticket request material */
export interface IssueTicketRequestMaterial {
  materialPrefix: string;
  did: string;
  /** Proposal ID to issue a ticket */
  proposalId: string;
  /** Requested created at */
  createdAt: string;
}

/**
 * Issue a ticket for a proposal to be used for the DID.
 *
 * X-Auth-Token is required.
 */
export interface IssueTicketRequest {
  /** message to prove issue ticket message */
  issueTicketRequestMaterial:
    | IssueTicketRequestMaterial
    | undefined;
  /** signature to verify issue request */
  signature: Uint8Array;
}

/** Response to IssueTicketRequest */
export interface IssueTicketResponse {
  /** Proposal ID */
  proposalId: string;
  /** Issue status */
  code?:
    | CommonStatusCode
    | undefined;
  /** status when accepted or done */
  proposalActiveClient?:
    | ProposalActiveClient
    | undefined;
  /** base64 encoded PiUploadAuthData message */
  piUploadAuthData?: string | undefined;
}

export interface PiUploadAuthData {
  piAccessToken?: string | undefined;
  signedTokenWithChain?: Uint8Array | undefined;
}

/**
 * Upload PI request from client to On-Premise server.
 * This is a synchronous call.
 * The server would continue consuming unless the connection is lost BEFORE data upload.
 * It doesn't check the payload for the second REST API call and simply return the acceptance status.
 *
 * X-Auth-Token is required.
 */
export interface UploadPiRequest {
  /** Onetime data ID identifies each data upload request */
  pdsId: string;
  /** Proposal ID for the data upload request */
  proposalId: string;
  /** GZipped json-data payload */
  gzippedPayload: Uint8Array;
}

/** Response to UploadPiRequest */
export interface UploadPiResponse {
  /** Onetime data ID uploaded */
  pdsId: string;
  /** Issue status */
  code: CommonStatusCode;
  /** error message */
  message?: string | undefined;
}

/** Preparing ConsumeTicketRequest from on-premise side */
export interface PreConsumePayload {
  /** timestamp when the payload is generated and signed */
  timestamp: number;
  /** pdsId */
  pdsId: string;
}

/**
 * Preparing ConsumeTicketRequest from on-premise side
 * No X-Auth-Token required. Authentication is done by signature.
 */
export interface PreConsumeRequest {
  /** pdsId and timestamp */
  preConsumePayload:
    | PreConsumePayload
    | undefined;
  /** address of the on-premise wallet */
  besuAddress: string;
  /** signature of preConsumePayload generated by the on-premise wallet */
  signature: Uint8Array;
}

/** Pds information and blockchain nonce to be used for the consuming. */
export interface PreConsumeResponse {
  blockchainNonce: number;
  nextRequestId: number;
  contractAddress: string;
  pdsId: string;
  ticketId: string;
  purpose: number;
}

/**
 * Consume ticket request from On-Premise server to Middleware.
 * This is a syncrhonous call since On-Premise and Middleware are always online.
 * No X-Auth-Token required. Authentication is done by signature.
 */
export interface ConsumeTicketRequest {
  /**
   * RequestId should be managed by client and unique for the requests happening simultaneously.
   * Use GetLastRequestIdRequest if the client doesn't have the previous number.
   */
  requestId: number;
  /** onetime id */
  pdsId: string;
  /** blockchain raw transaction */
  rawTransaction: Uint8Array;
  /** besu address */
  besuAddress: string;
}

/** Response to ConsumeTicketRequest */
export interface ConsumeTicketResponse {
  /** async-request status */
  status: AsyncRequestStatus;
  /** Issue status */
  code?:
    | CommonStatusCode
    | undefined;
  /**
   * Parameter to recreate RegisterDidRequest when the blockchain-nonce is too far or too low
   * Please recreate RegisterDidRequest when retryWithNewBlockChainNonce is true with NEW-REQUEST-ID
   */
  retryWithNewBlockChainNonce: boolean;
  /**
   * New blockchain nonce to recreate RegisterDidRequest
   * Ignore when retryWithNewBlockChainNonce is false
   */
  newBlockChainNonce: number;
  /** Duplicated request id when STATUS_ASYNC_REQUEST_DUPLICATED is returned */
  duplicatedRequestId?: number | undefined;
}

/** Data upload receipt payload */
export interface DataReceiptPayload {
  /** timestamp when the data is received */
  timestamp: number;
  /** pdsId */
  pdsId: string;
}

/** Request to inform the data upload receipt from the data-server */
export interface DataReceiptNotification {
  /** data receipt payload */
  payload:
    | DataReceiptPayload
    | undefined;
  /** address of the on-premise wallet */
  besuAddress: string;
  /** signature of DataReceiptPayload generated by the on-premise wallet */
  signature: Uint8Array;
}

/** Response to DataReceiptNotification */
export interface DataReceiptNotificationResponse {
  /** data upload receipt status */
  code: CommonStatusCode;
  message?: string | undefined;
}

/** List of DataReceiptNotification */
export interface DataReceiptNotificationList {
  notifications: DataReceiptNotification[];
}

/**
 * Request to list the data receipt up.
 * Middleware sends this request to On-Premise server
 */
export interface ListDataReceiptRequest {
  pdsIds: string[];
}

/** Response to ListDataReceiptRequest */
export interface ListDataReceiptResponse {
  /** data upload receipts */
  notifications: DataReceiptNotification[];
}

/** List reward history of the user */
export interface ListRewardHistoryRequest {
  /** did of the user */
  did: string;
  /** RFC-3339 start date (lower date) */
  startAt: string;
  /** RFC-3339 end date (greater date) */
  endAt: string;
  /** requested history type */
  requestType: ListRewardHistoryRequestType;
  /** page size */
  pageSize: number;
  /** page number */
  pageNum: number;
}

/** Response to the ListRewardHistoryRequest */
export interface ListRewardHistoryResponse {
  /** did of the user */
  did: string;
  /** total count of the content */
  totalCount: number;
  /** page size */
  pageSize: number;
  /** page number */
  pageNum: number;
  /** balance of the did */
  balance: number;
  /** amount expires within 1month */
  amountExpiresWithin1m: number;
  /** list of content */
  contents: RewardTransactionContentForApp[];
}

/** Coupon id request */
export interface GetCouponIdRequest {
  /** did of the user */
  did: string;
}

/** Response to GetCouponIdRequest */
export interface GetCouponIdResponse {
  /** did of the user */
  did: string;
  /** coupon transaction id for external shop */
  couponTransactionId: string;
  /** blockchain request id */
  blockchainRequestId: Uint8Array;
  /** blockchain reward transfer nonce */
  blockchainRewardTransferNonce: number;
  /** blockchain reward receiver address */
  blockchainRewardReceiverAddress: string;
  /** blockchain reward contract address */
  blockchainRewardContractAddress: string;
  /** status code */
  code: CommonStatusCode;
}

/** Issue coupon request */
export interface IssueCouponRequest {
  /** did of the user */
  did: string;
  /** purchase request material = abi.encode("world.myd.purchase-coupon", blockchainRewardContractAddress, besuAddress, blockchainRewardReceiverAddress, amount, blockchainRewardTransferNonce, blockchainRequestId) */
  purchaseRequestMaterial: Uint8Array;
  /** signature for the blockchain includes r, s, v */
  purchaseRequestMaterialSignature: Uint8Array;
  /** goods code to purchase */
  goodsCode: string;
  /** client random */
  clientKeyMaterial: Uint8Array;
}

/** Response to IssueCouponRequest */
export interface IssueCouponResponse {
  /** did of the user */
  did: string;
  /** coupon transaction id for external shop */
  couponTransactionId: string;
  /** status code */
  code: CommonStatusCode;
  /** server random */
  serverKeyMaterial: Uint8Array;
  /** ephemeral public key for pre-master secret */
  ephemeralPublicKey: Uint8Array;
  /** encrypted coupon data */
  encryptedCouponData: Uint8Array;
}

/** List coupon request */
export interface ListCouponRequest {
  /** did of the user */
  did: string;
  /** list of coupon transaction id; 0 element to get all coupons */
  couponTransactionIds: string[];
}

export interface CouponInfoDetails {
  /** goods code */
  goodsCode: string;
  /** pin status code */
  pinStatusCode: string;
  /** goods name */
  goodsName: string;
  /** selling price */
  sellPriceAmount: string;
  /** tel no of sender */
  senderTelNo: string;
  /** normal sales price */
  cnsmPriceAmount: string;
  /** transaction no */
  sendRstCode: string;
  /** pin status code name */
  pinStatusName: string;
  /** brand thumbnail img */
  mmsBrandThumImg: string;
  /** brand name */
  brandName: string;
  /** send status msg */
  sendRstMsg: string;
  /** change date */
  correcDtm: string;
  /** receiver tel no */
  recverTelNo: string;
  /** expiration date */
  expirationAt: string;
  /** basic code */
  sendBasicCode: string;
  /** send status code */
  sendStatusCode: string;
}

export interface ListCouponDetailsResponse {
  /** status code */
  code: CommonStatusCode;
  details: CouponInfoDetails[];
}

/** get the latest coupon details (from KT biz) */
export interface ListCouponDetailsRequest {
  /** did of the user */
  did: string;
  /** coupon transaction id */
  couponTransactionIds: string[];
}

/** Coupon info from ListCouponRequest */
export interface CouponInfo {
  /** did of the user */
  did: string;
  /** encrypted pin code */
  encryptedPinCode: string;
  /** encrypted image url */
  encryptedImageUrl: string;
  /** encrypted raw response from the shop */
  encryptedInfo: string;
  /** coupon transaction id */
  couponTransactionId: string;
  /** coupon order number */
  orderNo: string;
  /** price of the coupon */
  price: number;
  /** published timestamp in RFC-3339 */
  publishedAt: string;
  /** disposed timestamp in RFC-3339 */
  disposedAt: string;
  /**
   * status of the coupon
   * updated at every 24:00, so might be different from the actual status
   */
  status: CouponStatus;
  /** coupon cancel requested by API */
  cancelRequested: boolean;
  /** client key material */
  clientKeyMaterial: Uint8Array;
  /** server key material */
  serverKeyMaterial: Uint8Array;
  /** ephemeral public key for pre-master secret */
  ephemeralPublicKey: Uint8Array;
  /** goods code of coupon */
  goodsCode: string;
}

/** Response to ListCouponRequest */
export interface ListCouponResponse {
  /** status code */
  code: CommonStatusCode;
  /** coupon info */
  coupons: CouponInfo[];
}

export interface Myd2CouponInfo {
  couponTransactionId: string;
  goodsCode: string;
  encryptedInfo: Uint8Array;
  issuedAt: string;
  revokedAt: string;
  status: string;
}

/** List myd2 coupon request */
export interface ListMyd2CouponRequest {
  /** did of the user */
  did: string;
  /** list of coupon transaction id; 0 element to get all coupons */
  couponTransactionIds: string[];
}

/** Response to ListMyd2CouponRequest */
export interface ListMyd2CouponResponse {
  /** status code */
  code: CommonStatusCode;
  /** coupon info */
  coupons: Myd2CouponInfo[];
}

/** Self-managed wallet address */
export interface SelfManagedAddress {
  address: string;
  role: BlockchainRole;
  createdAt: string;
  team: string;
  username: string;
  isDeleted: boolean;
}

/** Response to get self-managed-addresses */
export interface GetAllSelfManagedAddressesResponse {
  selfManagedAddresses: SelfManagedAddress[];
}

/**
 * Create or update self-managed wallet address.
 * The key is address.
 */
export interface CreateOrUpdateSelfManagedWalletRequest {
  wallet: SelfManagedAddress | undefined;
}

/** Response to CreateOrUpdateSelfManagedWalletRequest */
export interface CreateOrUpdateSelfManagedWalletResponse {
  /** status code */
  code: CommonStatusCode;
}

/** Create a wallet key pair for a role for which no wallet key pair has been created. */
export interface CreateWalletKeyPairRequest {
  did: string;
}

/** Response of key pair creation. */
export interface CreateWalletKeyPairResponse {
  isCreated: boolean;
  address: string;
}

/** get list of companies */
export interface GetCompaniesResponse {
  companies: Companies[];
}

export interface Companies {
  did: string;
  name: string;
  address: string;
}

/**
 * Managed wallet address.
 * The key is address.
 */
export interface ManagedAddress {
  address: string;
  role: BlockchainRole;
  publicKey: string;
  hostname: string;
  createdAt: string;
}

/** Response to get managed-addresses */
export interface GetAllManagedAddressesResponse {
  managedAddresses: ManagedAddress[];
}

/**
 * Create or update managed wallet address.
 * The key is address.
 */
export interface CreateOrUpdateManagedWalletRequest {
  wallet: ManagedAddress | undefined;
}

/** Response to CreateOrUpdateManagedWalletRequest */
export interface CreateOrUpdateManagedWalletResponse {
  /** status code */
  code: CommonStatusCode;
}

/** Declare transaction content to be committed */
export interface DeclareRewardTransactionContentRequest {
  content: RewardTransactionContent | undefined;
  blockchainRequestId: Uint8Array;
}

/** Response to DeclareRewardTransactionContentRequest */
export interface DeclareRewardTransactionContentResponse {
  /** status code */
  code: CommonStatusCode;
}

/**
 * Create proposal from the admin console (wallet based)
 * This proposal doesn't have company entity.
 */
export interface CreateSpecialProposalRequest {
  proposal: ProposalStatic | undefined;
}

/** Response to CreateSpecialProposalRequest */
export interface CreateSpecialProposalResponse {
  /** status code */
  code: CommonStatusCode;
  /** proposal id */
  proposalId: string;
}

export interface InviteFriendHostRequest {
  did: string;
  mid: string;
}

export interface InviteFriendGuestRequest {
  did: string;
  mid: string;
  invitationCode: string;
  proposalId: string;
}

export interface InviteFriendHostResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
  invitationCode?: string | undefined;
  usedCount?: number | undefined;
}

export interface InviteFriendGuestResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
  date?: string | undefined;
  dueTo?: string | undefined;
  tokenId?: string | undefined;
  amount?: number | undefined;
  invitationCode?: string | undefined;
}

export interface AttendanceRollCallRequest {
  did: string;
  mid: string;
  proposalId: string;
}

export interface AttendanceSubmitRequest {
  did: string;
  mid: string;
  proposalId: string;
}

export interface AttendanceRollCallResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
  dayofweeks: boolean[];
  complete?: boolean | undefined;
}

export interface AttendanceSubmitResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
  reward?: number | undefined;
}

export interface ChristmasSubmitRequest {
  did: string;
  mid: string;
}

export interface ChristmasSubmitResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
}

export interface ChristmasIsParticipationRequest {
  did: string;
  mid: string;
}

export interface ChristmasIsParticipationResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
  isParticipation: boolean;
}

export interface ChristmasParticipationRateRequest {
  did: string;
  mid: string;
}

export interface ChristmasParticipationRateResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
  participationRate: number;
  totalparticipations: number;
}

export interface LikeGoodsResponse {
  code: CommonStatusCode;
}

export interface LikeGoodsRequest {
  did: string;
  goodsCodes: string[];
}

export interface ListGoodsLikeResponse {
  code: CommonStatusCode;
  goodsCodes: string[];
}

export interface ListGoodsLikeRequest {
  did: string;
}

/** Registering did filtering request */
export interface RegisterDidFilteringRequest {
  /** proposal ID to apply filtering */
  proposalId: string;
  /** allowlist or blocklist */
  mode: DidFilteringMode;
  /** add specific dids to the filtering */
  didFilter: string[];
  /** add dids whose owner issued any ticket to specific proposals */
  proposalFilter: string[];
}

/** Response to RegisterDidFilteringRequest */
export interface RegisterDidFilteringResponse {
  statusCode: CommonStatusCode;
  filteringId: number;
}

/** User info for bulk mint */
export interface SimpleUserInfo {
  /** did of the user */
  did: string;
  /** user type if it is myd3 user or not */
  userType: SimpleUserType;
  /** null for USER_MYD2 or USER_NOT_FOUND */
  besuAddress?: string | undefined;
}

/** List address of users for the given dids */
export interface LookupUsersRequest {
  dids: string[];
}

/** Response to LookupUsersRequest */
export interface LookupUsersResponse {
  users: SimpleUserInfo[];
}

/** Bulk mint (transfer) to the specific users */
export interface BulkMintRequest {
  reqs: DeclareRewardTransactionContentRequest[];
}

/** Response to BulkMintRequest */
export interface BulkMintResponse {
  resps: DeclareRewardTransactionContentResponse[];
}

export interface AppVersionConfig {
  androidVersion: string;
  androidForcedUpdate: boolean;
  iosVersion: string;
  iosForcedUpdate: boolean;
  needNotification: boolean;
}

/** App version config publish request */
export interface AppVersionConfigPublishRequest {
  appVersionConfig: AppVersionConfig | undefined;
}

/** Response to AppVersionConfigPublishRequest */
export interface AppVersionConfigPublishResponse {
  code: CommonStatusCode;
}

export interface HomeBannerCard {
  /** mandatory */
  type: HomeBannerCardType;
  enabled: boolean;
  supportedPlatforms: HomeBannerSupportedPlatform;
  title: string;
  titleVisible: boolean;
  /** SIMPLE_BANNER_LANDING */
  bannerImageUrl?: string | undefined;
  landingPageUrl?: string | undefined;
  notBefore?: string | undefined;
  notAfter?:
    | string
    | undefined;
  /** need to pass access token via url (only myd.world url) */
  requiresAccessTokenInGetMethod?:
    | boolean
    | undefined;
  /** SINGLE_PROPOSAL */
  proposalId?:
    | string
    | undefined;
  /** CATEGORY_PROPOSAL_PURPOSE */
  purpose?: number | undefined;
}

export interface HomeBannerConfig {
  cards: HomeBannerCard[];
}

/** Save or update home banner config */
export interface HomeBannerSaveRequest {
  /** null for new banner */
  bannerNo?:
    | number
    | undefined;
  /** content to save */
  config:
    | HomeBannerConfig
    | undefined;
  /** read only */
  setReadOnly: boolean;
  /** set hidden */
  setHidden: boolean;
}

/** Response to HomeBannerSaveRequest */
export interface HomeBannerSaveResponse {
  code: CommonStatusCode;
  bannerNo: number;
}

/** Saved home banner config */
export interface SavedHomeBannerConfig {
  bannerNo: number;
  isReadOnly: boolean;
  isHidden: boolean;
  createdBy: string;
  config?: HomeBannerConfig | undefined;
}

/** Home banner list request */
export interface HomeBannerListRequest {
  /** page size */
  pageSize: number;
  /** page number */
  pageNum: number;
}

/** Response to HomeBannerListRequest */
export interface HomeBannerListResponse {
  configNumbers: SavedHomeBannerConfig[];
}

/**
 * Home banner publish request
 * Published banner will become read-only
 */
export interface HomeBannerPublishRequest {
  bannerNo: number;
}

/** Response to HomeBannerPublishRequest */
export interface HomeBannerPublishResponse {
  code: CommonStatusCode;
}

export interface ProposalWithDid {
  proposal: Proposal | undefined;
  did: string;
}

export interface ElasticSearchQueryTemplate {
  elasticSearchIndex: string;
  /** elastic search query body */
  baseQuery: string;
  /** {placeholder expression, json path of the element in baseQuery} */
  arguments: { [key: string]: string };
  resultJsonPath: string;
  /** string, long, boolean */
  resultType: string;
}

export interface ElasticSearchQueryTemplate_ArgumentsEntry {
  key: string;
  value: string;
}

export interface ApplicationResource {
  elasticSearchQueryTemplate?: ElasticSearchQueryTemplate | undefined;
}

/** Burn point request */
export interface BurnPointRequest {
  /** myd3 burn candidates */
  candidates: { [key: string]: number };
  /** myd2 burn candidates */
  candidatesInMyd2: { [key: string]: number };
  /** encrypted operator key */
  encryptedOperatorKeyInBase64: string;
}

export interface BurnPointRequest_CandidatesEntry {
  key: string;
  value: number;
}

export interface BurnPointRequest_CandidatesInMyd2Entry {
  key: string;
  value: number;
}

/** Response Burn Point And DID List */
export interface BurnPointResponse {
  /** myd3 burn candidates */
  processed: { [key: string]: CommonStatusCode };
  /** myd2 burn candidates */
  processedInMyd2: { [key: string]: CommonStatusCode };
  transactionStatus: CommonStatusCode;
  transactionMessage?: string | undefined;
}

export interface BurnPointResponse_ProcessedEntry {
  key: string;
  value: CommonStatusCode;
}

export interface BurnPointResponse_ProcessedInMyd2Entry {
  key: string;
  value: CommonStatusCode;
}

export interface QueryBurnPointsCandidatesRequest {
  /** query for myd3 */
  queryCandidates: boolean;
  /** query for myd2 */
  queryCandidatesInMyd2: boolean;
  /** myd3 burn start index */
  startIdx: number;
  /** myd3 burn end index */
  endIdx: number;
}

/** Response to QueryBurnPointsCandidatesRequest */
export interface QueryBurnPointCandidatesResponse {
  /** myd3 burn candidates */
  candidates: { [key: string]: number };
  /** myd2 burn candidates */
  candidatesInMyd2: { [key: string]: number };
}

export interface QueryBurnPointCandidatesResponse_CandidatesEntry {
  key: string;
  value: number;
}

export interface QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry {
  key: string;
  value: number;
}

export interface RegisterProposalRequest {
  proposalRequestId: number;
  proposalStatic: ProposalStatic | undefined;
}

export interface RegisterProposalResponse {
  statusCode: CommonStatusCode;
  /** only for STATUS_SUCCESS */
  proposalId: string;
}

export interface WebResourceConfig {
  fileUrlPath: string[];
}

export interface SavedBuilableResource {
  resourceNo: number;
  resourceId: string;
  title: string;
  isFinal: boolean;
  isHidden: boolean;
  isPublished: boolean;
  createdBy: string;
  resourceType: BuildableResourceType;
  destinationPath?: string | undefined;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  appVersion?: AppVersionConfig | undefined;
  homeBanner?: HomeBannerConfig | undefined;
  webResource?: WebResourceConfig | undefined;
  applicationResource?: ApplicationResource | undefined;
}

export interface BuildableResourceSaveRequest {
  resourceNo?: number | undefined;
  title: string;
  isFinal: boolean;
  isHidden: boolean;
  resourceType: BuildableResourceType;
  destinationPath?: string | undefined;
  appVersion?: AppVersionConfig | undefined;
  homeBanner?: HomeBannerConfig | undefined;
  applicationResource?: ApplicationResource | undefined;
}

export interface BuildableResourceSaveResponse {
  statusCode: CommonStatusCode;
  savedBuilableResource?: SavedBuilableResource | undefined;
}

/** Web resource list request */
export interface BuildableResourceListReqeust {
  /** Find all when it is empty */
  buildableResourceType: BuildableResourceType[];
  /** page size */
  pageSize: number;
  /** page number */
  pageNum: number;
}

export interface BuildableResourceListResponse {
  statusCode: CommonStatusCode;
  savedBuilableResource: SavedBuilableResource[];
}

export interface BuildableResourcePublishRequest {
  resourceNo: number;
}

export interface BuildableResourcePublishResponse {
  code: CommonStatusCode;
}

/** Request to CouponManagerRequest */
export interface CouponManagerRequest {
  couponRequestType: CouponManagerRequestType;
  couponRequestParameterType: CouponRequestParameterType;
  parameterList: string[];
}

/** Response to Coupon RevokeInfo */
export interface CouponManagerRevokeInfo {
  code: string;
  message: string;
  couponTransactionId: string;
  couponInfo: CouponInfo | undefined;
}

/** Response to CouponManagerResponse */
export interface CouponManagerResponse {
  /** status code */
  code: CommonStatusCode;
  couponDetails: CouponInfoDetails[];
  couponInfoList: CouponInfo[];
  couponManagerRevokeInfo: CouponManagerRevokeInfo[];
}

/** Request to run ElasticSearchQuery with data in json */
export interface ElasticSearchQueryRunnerRequest {
  template: ElasticSearchQueryTemplate | undefined;
  dataInJson: string;
}

/** Response to ElasticSearchQueryRunnerRequest */
export interface ElasticSearchQueryRunnerResponse {
  code: CommonStatusCode;
  result?: ElasticSearchQueryResult | undefined;
  unusedPatterns: string[];
  targetQuery?: string | undefined;
}

export interface DailyReportRequest {
  notBefore: string;
  notAfter: string;
}

export interface DailyReportResponse {
  code: CommonStatusCode;
  proposalIdList: string[];
}

export interface ProposalReportRequest {
  proposalIds: string[];
}

export interface ProposalReportResponse {
  code: CommonStatusCode;
  proposalReport: ProposalReport[];
}

export interface ProposalManagementRedisCleanupRequest {
  proposalId: string;
}

export interface ProposalManagementRedisCleanupResponse {
  code: CommonStatusCode;
  message?: string | undefined;
  ticketReportInRedis: TicketReportInRedis | undefined;
}

export interface ProposalReport {
  proposalId: string;
  proposalStatic: ProposalStatic | undefined;
  proposalActivateGlobal: ProposalActiveGlobal | undefined;
  ticketReportInRedis: TicketReportInRedis | undefined;
  ticketReportInDb: TicketReportInDb[];
}

export interface TicketReportInRedis {
  redisTicketCount: number;
  values: string[];
}

export interface TicketReportInDb {
  owner: string;
  activeClient: ProposalActiveClient | undefined;
}

/** Request to get DID - besu address mapping */
export interface DidBesuAddressInfoRequest {
  dids: string[];
  besuAddresses: string[];
  mids: string[];
}

export interface DidBesuAddressUnit {
  did?: string | undefined;
  besuAddress?: string | undefined;
  createdAt?: string | undefined;
  role?:
    | BlockchainRole
    | undefined;
  /** special users, company and proposal */
  name?:
    | string
    | undefined;
  /** only for the special users */
  description?:
    | string
    | undefined;
  /** only for proposal */
  proposalId?:
    | string
    | undefined;
  /** for MYD_USER */
  mid?:
    | string
    | undefined;
  /** for MYD_USER, myd2 public key */
  myd2Verkey?:
    | string
    | undefined;
  /** for MYD_USER, myd3 public key */
  besuPublicKey?:
    | string
    | undefined;
  /** for MYD_USER, device type */
  userAgent?:
    | string
    | undefined;
  /** for MYD_USER, last connected date */
  updatedAt?:
    | string
    | undefined;
  /** for MYD_USER, connect count */
  connectCount?: number | undefined;
}

/** Response to DidBesuAddressInfoRequest */
export interface DidBesuAddressInfoResponse {
  code: CommonStatusCode;
  results: DidBesuAddressUnit[];
  notFoundDids: string[];
  notFoundBesuAddresses: string[];
  notFoundMids: string[];
}

/** Request to break away dids */
export interface BreakAwayDidRequest {
  dids: string[];
}

/** Response to BreakAwayDidRequest */
export interface BreakAwayDidResponse {
  code: CommonStatusCode;
}

/** DID report request */
export interface DidReportRequest {
  dids: string[];
  listOption: ListProposalRequest | undefined;
}

export interface DidReport {
  did: string;
  userStatInJson: string;
  member: DidBesuAddressUnit | undefined;
  listResponse: ListProposalResponse | undefined;
  attendance?: AttendanceRollCallResponse | undefined;
  wordpressUserDbId?: number | undefined;
  myd2AttendanceByDid?: AttendanceRollCallResponse | undefined;
  myd2AttendanceByMid?: AttendanceRollCallResponse | undefined;
  myd2WordpressUserDbId?: number | undefined;
  myd2Point?: number | undefined;
}

/** DID report response */
export interface DidReportResponse {
  didReports: DidReport[];
}

/** Manipulate the proposal static payload */
export interface AdminProposalUpdateRequest {
  proposalStatic: ProposalStatic | undefined;
}

export interface AdminProposalUpdateResponse {
  statusCode: CommonStatusCode;
}

/** Manually migrate user information if needed */
export interface ManualMyd2MigrationRequest {
  did: string;
  besuAddress: string;
  mintPoint: boolean;
  attendance: boolean;
  wordressUser: boolean;
  rewardHistory: boolean;
}

export interface ManualMyd2MigrationResponse {
  code: CommonStatusCode;
}

/** User migration report request */
export interface UserMigrationReportRequest {
  createdAtGreaterThan: string;
  createdAtLessThan?:
    | string
    | undefined;
  /** ISO-8601 P1D for 1day, PT1M for 1minute */
  histogramUnit: string;
  /** ISO-8601 P30D for 1day, P7D for 1week */
  activeUserJudgementCriteria: string;
}

/**
 * This is a unit of date histogram of user migration report
 * It starts from createdAtGreaterThan and ends with createdAtLessThan
 */
export interface UserMigrationReportUnit {
  startTime: string;
  endTime: string;
  /** startTime - activeUserJudgementCriteriaInDays < updatedAt && createdAt < endTime */
  activeMyd2Users: number;
  activeMyd3Users: number;
  /** startTime < createdAt < endTime */
  newMyd2Users: number;
  newMyd3Users: number;
  /**
   * startTime < createdAt < endTime and verkey exists in myd3
   * migratedUsers is a subset of newMyd3Users
   */
  migratedUsers: number;
  /**
   * createdAt < endTime and verkey exists in myd3
   * migratedUsers is NOT a subset of newMyd3Users
   */
  accumulatedMigratedUsers: number;
}

/** Response to UserMigrationReportRequest */
export interface UserMigrationReportResponse {
  reports: UserMigrationReportUnit[];
}

export interface GMORMemberInfoRequest {
  did: string;
  mid: string;
}

export interface GMORMemberInfoResponse {
  memberInfo:
    | GMORMemberInfo
    | undefined;
  /** status code */
  code: CommonStatusCode;
}

export interface GMORMemberInfo {
  did: string;
  memberId: string;
  mid: string;
}

export interface PollInfoCountResponse {
  code: CommonStatusCode;
  notStarted: number;
  inProgress: number;
  ended: number;
  temporary: number;
  deleted: number;
  revoked: number;
  hidden: number;
}

export interface PollInfoListResponse {
  code: CommonStatusCode;
  pollInfoItem: PollInfoItem[];
}

export interface PollInfoMobileListResponse {
  code: CommonStatusCode;
  pollInfoMobileItem: PollInfoMobileItem[];
}

export interface PollInfoMobileItem {
  isParticipant: boolean;
  isProvidePoint: PollRewardStatus;
  pollInfoItem: PollInfoItem | undefined;
}

export interface PollInfoItem {
  id: number;
  pollId: string;
  category: PollInfoCategory;
  title: string;
  imgLink: string;
  status: PollInfoStatus;
  startDate: string;
  endDate: string;
  participant: number;
  maxIssuable: number;
  perAmount: number;
  createdAt: string;
}

export interface PollReport {
  id: number;
  pollId: string;
  pollType: PollType;
  multiYn: boolean;
  category: PollInfoCategory;
  title: string;
  contents: string;
  questions: Uint8Array;
  correctAns?: string | undefined;
  pagesQuestionsCnt: string;
  imgLink: string;
  perAmount: number;
  maxIssuable: number;
  status: PollInfoStatus;
  startDate: string;
  endDate: string;
  endNotSet: boolean;
  participants: number;
  createdAt: string;
  updatedAt: string;
}

export interface PollRegistRequest {
  pollId: string;
  pollType: PollType;
  multiYn: boolean;
  category: PollInfoCategory;
  title: string;
  contents: string;
  questions: Uint8Array;
  correctAns?: string | undefined;
  pagesQuestionsCnt: string;
  imgLink: string;
  perAmount: number;
  maxIssuable: number;
  startDate: string;
  endDate: string;
  endNotSet: boolean;
}

export interface PollDetailRequest {
  pollId: string;
}

export interface PollDetailResponse {
  code: CommonStatusCode;
  pollReport: PollReport | undefined;
  exhaustionPoint?: number | undefined;
  companyBalance?: number | undefined;
}

export interface PollDetailMobileRequest {
  pollId: string;
  shortAnswerCount?: number | undefined;
}

export interface PollDetailMobileResponse {
  code: CommonStatusCode;
  pollReport:
    | PollReport
    | undefined;
  /** 나의 답변 */
  pollAnswerInfo: string;
  /** 통계 */
  pollStatistics?: PollStatistics | undefined;
  commentCount: number;
}

export interface PollResultRequest {
  id: number;
  shortAnswerCount?: number | undefined;
}

export interface PollResultResponse {
  code: CommonStatusCode;
  pollReport: PollReport | undefined;
  pollStatistics: PollStatistics | undefined;
  commentCount: number;
}

export interface PollStatistics {
  /** 참여자 성별, 연령대 평균 */
  pollParticipantInfoStatistics:
    | PollParticipantInfoStatistics
    | undefined;
  /** 질문에 대한 통계 (주관식 제외) */
  pollAnswerStatisticsInfo: PollAnswerStatisticsInfo[];
  /** 주관식 질문에 대한 정보 */
  pollShortAnswerInfoList: PollShortAnswerInfo[];
}

export interface PollAnswerStatisticsInfo {
  questionId: number;
  pageNum: number;
  questionType: QuestionType;
  participants: number;
  statistics: string;
  updatedAt: string;
}

export interface PollShortAnswerInfo {
  questionId: number;
  shortAnswerList: string[];
}

export interface PollParticipantInfoStatistics {
  genderStatistics: PollGenderStatistics | undefined;
  AgeStatistics: PollAgeStatistics | undefined;
}

export interface PollGenderStatistics {
  male: number;
  female: number;
}

export interface PollAgeStatistics {
  teenager: number;
  twenties: number;
  thirties: number;
  forties: number;
  fifties: number;
  sixties: number;
}

export interface PollStatusChangeRequest {
  pollId: string;
  status: PollInfoStatus;
}

export interface PollParticipationRequest {
  pollId: string;
}

export interface PollParticipationResponse {
  code: CommonStatusCode;
  pollReport: PollReport | undefined;
  pollAnswerStatistics?: string | undefined;
}

export interface PollAnswerRequest {
  pollId: string;
  pollAnswerList: string;
  gender: boolean;
  ageYear: number;
}

export interface PollAnswerResponse {
  code: CommonStatusCode;
  answerId: string;
  providePoint: number;
}

export interface PollProvidePointRequest {
  pollId: string;
  answerId: string;
}

export interface PollProvidePointResponse {
  code: CommonStatusCode;
}

export interface SpamCountResponse {
  code: CommonStatusCode;
  spamCount: SpamCount | undefined;
}

export interface SpamCount {
  waitingCnt: number;
  acceptCnt: number;
  nonAcceptCnt: number;
}

export interface SpamInfoListResponse {
  code: CommonStatusCode;
  spamInfo: SpamInfo[];
}

export interface SpamInfo {
  spamNum: number;
  spamStatus: SpamStatus;
  spamReportTime: string;
  pollId: string;
  pollTitle: string;
  spamReportId: string;
  isReply: boolean;
  spamReportContent: Uint8Array;
  reportCount: number;
  spamManager: string;
}

export interface SpamStatusChangeRequest {
  spamCommentIdList: string[];
  spamReplyIdList: string[];
  status: SpamStatus;
}

export interface SpamStatusChangeResponse {
  code: CommonStatusCode;
  spamCommentIdList: string[];
  spamReplyIdList: string[];
}

export interface CommentRegisterRequest {
  pollId: string;
  parentId?: string | undefined;
  commentId?: string | undefined;
  comment: string;
}

export interface CommentRegisterResponse {
  code: CommonStatusCode;
}

export interface CommentDeleteRequest {
  pollId: string;
  reportId: string;
}

export interface CommentDeleteResponse {
  code: CommonStatusCode;
}

export interface CommentReactionRequest {
  pollId: string;
  reactionId: string;
  reactionCode: ReactionCode;
  isReply: boolean;
}

export interface CommentReactionResponse {
  code: CommonStatusCode;
}

export interface PollCommentListRequest {
  pollId: string;
}

export interface PollCommentListResponse {
  code: CommonStatusCode;
  pollCommentInfo: PollCommentInfo[];
}

export interface PollCommentInfo {
  did: string;
  pollId: string;
  commentId: string;
  comments: Uint8Array;
  delYn: boolean;
  spamStatus: SpamStatus;
  replyYn: boolean;
  reactionCnt: string;
  myReaction: string;
  createdAt: string;
  updatedAt: string;
  replyCommentInfoList: PollReplyCommentInfo[];
}

export interface PollReplyCommentInfo {
  did: string;
  pollId: string;
  commentId: string;
  replyId: string;
  replyComment: Uint8Array;
  delYn: boolean;
  spamStatus: SpamStatus;
  reactionCnt: string;
  myReaction: string;
  createdAt: string;
  updatedAt: string;
}

export interface PollMyCommentResponse {
  code: CommonStatusCode;
  pollMyComment: PollMyComment[];
}

export interface PollMyComment {
  did: string;
  pollId: string;
  commentId: string;
  comments: Uint8Array;
  delYn: boolean;
  spamStatus: SpamStatus;
  isReply: boolean;
  reactionCnt: string;
  replyCnt: number;
  createdAt: string;
  updatedAt: string;
}

export interface MyProfileRequest {
  profileImg: number;
}

export interface MyProfileResponse {
  code: CommonStatusCode;
  myProfileInfo: MyProfileInfo | undefined;
}

export interface MyProfileInfo {
  profileImg: number;
  updatedAt: string;
}

export interface UnpaidPollAnswerRequest {
  unpaidPollAnswerInfo: UnpaidPollAnswerInfo[];
}

export interface UnpaidPollAnswerResponse {
  code: CommonStatusCode;
}

export interface UnpaidPollAnswerInfo {
  did: string;
  pollId: number;
  pollTitle: string;
  reward: number;
}

function createBaseRegisterRequestMaterial(): RegisterRequestMaterial {
  return {
    materialPrefix: "",
    did: "",
    besuAddress: "",
    nonce: "",
    publicKey: new Uint8Array(0),
    verkey: undefined,
    document: undefined,
  };
}

export const RegisterRequestMaterial = {
  encode(message: RegisterRequestMaterial, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.materialPrefix !== "") {
      writer.uint32(10).string(message.materialPrefix);
    }
    if (message.did !== "") {
      writer.uint32(18).string(message.did);
    }
    if (message.besuAddress !== "") {
      writer.uint32(26).string(message.besuAddress);
    }
    if (message.nonce !== "") {
      writer.uint32(34).string(message.nonce);
    }
    if (message.publicKey.length !== 0) {
      writer.uint32(42).bytes(message.publicKey);
    }
    if (message.verkey !== undefined) {
      writer.uint32(50).bytes(message.verkey);
    }
    if (message.document !== undefined) {
      writer.uint32(58).string(message.document);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterRequestMaterial {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterRequestMaterial();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.materialPrefix = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.did = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nonce = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.publicKey = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.verkey = reader.bytes();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.document = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterRequestMaterial {
    return {
      materialPrefix: isSet(object.materialPrefix) ? globalThis.String(object.materialPrefix) : "",
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      besuAddress: isSet(object.besuAddress) ? globalThis.String(object.besuAddress) : "",
      nonce: isSet(object.nonce) ? globalThis.String(object.nonce) : "",
      publicKey: isSet(object.publicKey) ? bytesFromBase64(object.publicKey) : new Uint8Array(0),
      verkey: isSet(object.verkey) ? bytesFromBase64(object.verkey) : undefined,
      document: isSet(object.document) ? globalThis.String(object.document) : undefined,
    };
  },

  toJSON(message: RegisterRequestMaterial): unknown {
    const obj: any = {};
    if (message.materialPrefix !== "") {
      obj.materialPrefix = message.materialPrefix;
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.nonce !== "") {
      obj.nonce = message.nonce;
    }
    if (message.publicKey.length !== 0) {
      obj.publicKey = base64FromBytes(message.publicKey);
    }
    if (message.verkey !== undefined) {
      obj.verkey = base64FromBytes(message.verkey);
    }
    if (message.document !== undefined) {
      obj.document = message.document;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterRequestMaterial>, I>>(base?: I): RegisterRequestMaterial {
    return RegisterRequestMaterial.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterRequestMaterial>, I>>(object: I): RegisterRequestMaterial {
    const message = createBaseRegisterRequestMaterial();
    message.materialPrefix = object.materialPrefix ?? "";
    message.did = object.did ?? "";
    message.besuAddress = object.besuAddress ?? "";
    message.nonce = object.nonce ?? "";
    message.publicKey = object.publicKey ?? new Uint8Array(0);
    message.verkey = object.verkey ?? undefined;
    message.document = object.document ?? undefined;
    return message;
  },
};

function createBaseDeviceAttestationRequest(): DeviceAttestationRequest {
  return {
    appVersion: "",
    parcel: "",
    clientKeyMaterial: new Uint8Array(0),
    registrationMessage: undefined,
    registrationSignature: new Uint8Array(0),
    migrationSignature: undefined,
  };
}

export const DeviceAttestationRequest = {
  encode(message: DeviceAttestationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.appVersion !== "") {
      writer.uint32(10).string(message.appVersion);
    }
    if (message.parcel !== "") {
      writer.uint32(18).string(message.parcel);
    }
    if (message.clientKeyMaterial.length !== 0) {
      writer.uint32(26).bytes(message.clientKeyMaterial);
    }
    if (message.registrationMessage !== undefined) {
      RegisterRequestMaterial.encode(message.registrationMessage, writer.uint32(34).fork()).ldelim();
    }
    if (message.registrationSignature.length !== 0) {
      writer.uint32(42).bytes(message.registrationSignature);
    }
    if (message.migrationSignature !== undefined) {
      writer.uint32(50).bytes(message.migrationSignature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceAttestationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceAttestationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.appVersion = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parcel = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientKeyMaterial = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.registrationMessage = RegisterRequestMaterial.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.registrationSignature = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.migrationSignature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceAttestationRequest {
    return {
      appVersion: isSet(object.appVersion) ? globalThis.String(object.appVersion) : "",
      parcel: isSet(object.parcel) ? globalThis.String(object.parcel) : "",
      clientKeyMaterial: isSet(object.clientKeyMaterial)
        ? bytesFromBase64(object.clientKeyMaterial)
        : new Uint8Array(0),
      registrationMessage: isSet(object.registrationMessage)
        ? RegisterRequestMaterial.fromJSON(object.registrationMessage)
        : undefined,
      registrationSignature: isSet(object.registrationSignature)
        ? bytesFromBase64(object.registrationSignature)
        : new Uint8Array(0),
      migrationSignature: isSet(object.migrationSignature) ? bytesFromBase64(object.migrationSignature) : undefined,
    };
  },

  toJSON(message: DeviceAttestationRequest): unknown {
    const obj: any = {};
    if (message.appVersion !== "") {
      obj.appVersion = message.appVersion;
    }
    if (message.parcel !== "") {
      obj.parcel = message.parcel;
    }
    if (message.clientKeyMaterial.length !== 0) {
      obj.clientKeyMaterial = base64FromBytes(message.clientKeyMaterial);
    }
    if (message.registrationMessage !== undefined) {
      obj.registrationMessage = RegisterRequestMaterial.toJSON(message.registrationMessage);
    }
    if (message.registrationSignature.length !== 0) {
      obj.registrationSignature = base64FromBytes(message.registrationSignature);
    }
    if (message.migrationSignature !== undefined) {
      obj.migrationSignature = base64FromBytes(message.migrationSignature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceAttestationRequest>, I>>(base?: I): DeviceAttestationRequest {
    return DeviceAttestationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceAttestationRequest>, I>>(object: I): DeviceAttestationRequest {
    const message = createBaseDeviceAttestationRequest();
    message.appVersion = object.appVersion ?? "";
    message.parcel = object.parcel ?? "";
    message.clientKeyMaterial = object.clientKeyMaterial ?? new Uint8Array(0);
    message.registrationMessage = (object.registrationMessage !== undefined && object.registrationMessage !== null)
      ? RegisterRequestMaterial.fromPartial(object.registrationMessage)
      : undefined;
    message.registrationSignature = object.registrationSignature ?? new Uint8Array(0);
    message.migrationSignature = object.migrationSignature ?? undefined;
    return message;
  },
};

function createBaseDeviceAttestationResponse(): DeviceAttestationResponse {
  return {
    code: 0,
    serverKeyMaterial: new Uint8Array(0),
    ephemeralPublicKey: new Uint8Array(0),
    encryptedAppKey: new Uint8Array(0),
    encryptedSecretKey: new Uint8Array(0),
    encryptedInitialVector: new Uint8Array(0),
  };
}

export const DeviceAttestationResponse = {
  encode(message: DeviceAttestationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      writer.uint32(18).bytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      writer.uint32(26).bytes(message.ephemeralPublicKey);
    }
    if (message.encryptedAppKey.length !== 0) {
      writer.uint32(34).bytes(message.encryptedAppKey);
    }
    if (message.encryptedSecretKey.length !== 0) {
      writer.uint32(42).bytes(message.encryptedSecretKey);
    }
    if (message.encryptedInitialVector.length !== 0) {
      writer.uint32(50).bytes(message.encryptedInitialVector);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceAttestationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceAttestationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverKeyMaterial = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ephemeralPublicKey = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.encryptedAppKey = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.encryptedSecretKey = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.encryptedInitialVector = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceAttestationResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      serverKeyMaterial: isSet(object.serverKeyMaterial)
        ? bytesFromBase64(object.serverKeyMaterial)
        : new Uint8Array(0),
      ephemeralPublicKey: isSet(object.ephemeralPublicKey)
        ? bytesFromBase64(object.ephemeralPublicKey)
        : new Uint8Array(0),
      encryptedAppKey: isSet(object.encryptedAppKey) ? bytesFromBase64(object.encryptedAppKey) : new Uint8Array(0),
      encryptedSecretKey: isSet(object.encryptedSecretKey)
        ? bytesFromBase64(object.encryptedSecretKey)
        : new Uint8Array(0),
      encryptedInitialVector: isSet(object.encryptedInitialVector)
        ? bytesFromBase64(object.encryptedInitialVector)
        : new Uint8Array(0),
    };
  },

  toJSON(message: DeviceAttestationResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      obj.serverKeyMaterial = base64FromBytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      obj.ephemeralPublicKey = base64FromBytes(message.ephemeralPublicKey);
    }
    if (message.encryptedAppKey.length !== 0) {
      obj.encryptedAppKey = base64FromBytes(message.encryptedAppKey);
    }
    if (message.encryptedSecretKey.length !== 0) {
      obj.encryptedSecretKey = base64FromBytes(message.encryptedSecretKey);
    }
    if (message.encryptedInitialVector.length !== 0) {
      obj.encryptedInitialVector = base64FromBytes(message.encryptedInitialVector);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceAttestationResponse>, I>>(base?: I): DeviceAttestationResponse {
    return DeviceAttestationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceAttestationResponse>, I>>(object: I): DeviceAttestationResponse {
    const message = createBaseDeviceAttestationResponse();
    message.code = object.code ?? 0;
    message.serverKeyMaterial = object.serverKeyMaterial ?? new Uint8Array(0);
    message.ephemeralPublicKey = object.ephemeralPublicKey ?? new Uint8Array(0);
    message.encryptedAppKey = object.encryptedAppKey ?? new Uint8Array(0);
    message.encryptedSecretKey = object.encryptedSecretKey ?? new Uint8Array(0);
    message.encryptedInitialVector = object.encryptedInitialVector ?? new Uint8Array(0);
    return message;
  },
};

function createBaseRegisterDeviceRequest(): RegisterDeviceRequest {
  return {
    appVersion: "",
    clientKeyMaterial: new Uint8Array(0),
    verificationMessage: new Uint8Array(0),
    registrationMessage: undefined,
    registrationSignature: new Uint8Array(0),
    migrationSignature: undefined,
  };
}

export const RegisterDeviceRequest = {
  encode(message: RegisterDeviceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.appVersion !== "") {
      writer.uint32(10).string(message.appVersion);
    }
    if (message.clientKeyMaterial.length !== 0) {
      writer.uint32(18).bytes(message.clientKeyMaterial);
    }
    if (message.verificationMessage.length !== 0) {
      writer.uint32(26).bytes(message.verificationMessage);
    }
    if (message.registrationMessage !== undefined) {
      RegisterRequestMaterial.encode(message.registrationMessage, writer.uint32(34).fork()).ldelim();
    }
    if (message.registrationSignature.length !== 0) {
      writer.uint32(42).bytes(message.registrationSignature);
    }
    if (message.migrationSignature !== undefined) {
      writer.uint32(50).bytes(message.migrationSignature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterDeviceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterDeviceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.appVersion = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientKeyMaterial = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.verificationMessage = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.registrationMessage = RegisterRequestMaterial.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.registrationSignature = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.migrationSignature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterDeviceRequest {
    return {
      appVersion: isSet(object.appVersion) ? globalThis.String(object.appVersion) : "",
      clientKeyMaterial: isSet(object.clientKeyMaterial)
        ? bytesFromBase64(object.clientKeyMaterial)
        : new Uint8Array(0),
      verificationMessage: isSet(object.verificationMessage)
        ? bytesFromBase64(object.verificationMessage)
        : new Uint8Array(0),
      registrationMessage: isSet(object.registrationMessage)
        ? RegisterRequestMaterial.fromJSON(object.registrationMessage)
        : undefined,
      registrationSignature: isSet(object.registrationSignature)
        ? bytesFromBase64(object.registrationSignature)
        : new Uint8Array(0),
      migrationSignature: isSet(object.migrationSignature) ? bytesFromBase64(object.migrationSignature) : undefined,
    };
  },

  toJSON(message: RegisterDeviceRequest): unknown {
    const obj: any = {};
    if (message.appVersion !== "") {
      obj.appVersion = message.appVersion;
    }
    if (message.clientKeyMaterial.length !== 0) {
      obj.clientKeyMaterial = base64FromBytes(message.clientKeyMaterial);
    }
    if (message.verificationMessage.length !== 0) {
      obj.verificationMessage = base64FromBytes(message.verificationMessage);
    }
    if (message.registrationMessage !== undefined) {
      obj.registrationMessage = RegisterRequestMaterial.toJSON(message.registrationMessage);
    }
    if (message.registrationSignature.length !== 0) {
      obj.registrationSignature = base64FromBytes(message.registrationSignature);
    }
    if (message.migrationSignature !== undefined) {
      obj.migrationSignature = base64FromBytes(message.migrationSignature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterDeviceRequest>, I>>(base?: I): RegisterDeviceRequest {
    return RegisterDeviceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterDeviceRequest>, I>>(object: I): RegisterDeviceRequest {
    const message = createBaseRegisterDeviceRequest();
    message.appVersion = object.appVersion ?? "";
    message.clientKeyMaterial = object.clientKeyMaterial ?? new Uint8Array(0);
    message.verificationMessage = object.verificationMessage ?? new Uint8Array(0);
    message.registrationMessage = (object.registrationMessage !== undefined && object.registrationMessage !== null)
      ? RegisterRequestMaterial.fromPartial(object.registrationMessage)
      : undefined;
    message.registrationSignature = object.registrationSignature ?? new Uint8Array(0);
    message.migrationSignature = object.migrationSignature ?? undefined;
    return message;
  },
};

function createBaseRegisterDeviceResponse(): RegisterDeviceResponse {
  return {
    code: 0,
    serverKeyMaterial: new Uint8Array(0),
    ephemeralPublicKey: new Uint8Array(0),
    encryptedSecretKey: new Uint8Array(0),
    encryptedInitialVector: new Uint8Array(0),
  };
}

export const RegisterDeviceResponse = {
  encode(message: RegisterDeviceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      writer.uint32(18).bytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      writer.uint32(26).bytes(message.ephemeralPublicKey);
    }
    if (message.encryptedSecretKey.length !== 0) {
      writer.uint32(34).bytes(message.encryptedSecretKey);
    }
    if (message.encryptedInitialVector.length !== 0) {
      writer.uint32(42).bytes(message.encryptedInitialVector);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterDeviceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterDeviceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverKeyMaterial = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ephemeralPublicKey = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.encryptedSecretKey = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.encryptedInitialVector = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterDeviceResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      serverKeyMaterial: isSet(object.serverKeyMaterial)
        ? bytesFromBase64(object.serverKeyMaterial)
        : new Uint8Array(0),
      ephemeralPublicKey: isSet(object.ephemeralPublicKey)
        ? bytesFromBase64(object.ephemeralPublicKey)
        : new Uint8Array(0),
      encryptedSecretKey: isSet(object.encryptedSecretKey)
        ? bytesFromBase64(object.encryptedSecretKey)
        : new Uint8Array(0),
      encryptedInitialVector: isSet(object.encryptedInitialVector)
        ? bytesFromBase64(object.encryptedInitialVector)
        : new Uint8Array(0),
    };
  },

  toJSON(message: RegisterDeviceResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      obj.serverKeyMaterial = base64FromBytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      obj.ephemeralPublicKey = base64FromBytes(message.ephemeralPublicKey);
    }
    if (message.encryptedSecretKey.length !== 0) {
      obj.encryptedSecretKey = base64FromBytes(message.encryptedSecretKey);
    }
    if (message.encryptedInitialVector.length !== 0) {
      obj.encryptedInitialVector = base64FromBytes(message.encryptedInitialVector);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterDeviceResponse>, I>>(base?: I): RegisterDeviceResponse {
    return RegisterDeviceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterDeviceResponse>, I>>(object: I): RegisterDeviceResponse {
    const message = createBaseRegisterDeviceResponse();
    message.code = object.code ?? 0;
    message.serverKeyMaterial = object.serverKeyMaterial ?? new Uint8Array(0);
    message.ephemeralPublicKey = object.ephemeralPublicKey ?? new Uint8Array(0);
    message.encryptedSecretKey = object.encryptedSecretKey ?? new Uint8Array(0);
    message.encryptedInitialVector = object.encryptedInitialVector ?? new Uint8Array(0);
    return message;
  },
};

function createBaseGetLastRequestIdRequest(): GetLastRequestIdRequest {
  return {};
}

export const GetLastRequestIdRequest = {
  encode(_: GetLastRequestIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLastRequestIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLastRequestIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetLastRequestIdRequest {
    return {};
  },

  toJSON(_: GetLastRequestIdRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLastRequestIdRequest>, I>>(base?: I): GetLastRequestIdRequest {
    return GetLastRequestIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLastRequestIdRequest>, I>>(_: I): GetLastRequestIdRequest {
    const message = createBaseGetLastRequestIdRequest();
    return message;
  },
};

function createBaseGetLastRequestIdResponse(): GetLastRequestIdResponse {
  return { lastRequestId: 0 };
}

export const GetLastRequestIdResponse = {
  encode(message: GetLastRequestIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lastRequestId !== 0) {
      writer.uint32(8).uint32(message.lastRequestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLastRequestIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLastRequestIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.lastRequestId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLastRequestIdResponse {
    return { lastRequestId: isSet(object.lastRequestId) ? globalThis.Number(object.lastRequestId) : 0 };
  },

  toJSON(message: GetLastRequestIdResponse): unknown {
    const obj: any = {};
    if (message.lastRequestId !== 0) {
      obj.lastRequestId = Math.round(message.lastRequestId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLastRequestIdResponse>, I>>(base?: I): GetLastRequestIdResponse {
    return GetLastRequestIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLastRequestIdResponse>, I>>(object: I): GetLastRequestIdResponse {
    const message = createBaseGetLastRequestIdResponse();
    message.lastRequestId = object.lastRequestId ?? 0;
    return message;
  },
};

function createBaseAckRequestIdRequest(): AckRequestIdRequest {
  return { requestId: 0 };
}

export const AckRequestIdRequest = {
  encode(message: AckRequestIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AckRequestIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAckRequestIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AckRequestIdRequest {
    return { requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0 };
  },

  toJSON(message: AckRequestIdRequest): unknown {
    const obj: any = {};
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AckRequestIdRequest>, I>>(base?: I): AckRequestIdRequest {
    return AckRequestIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AckRequestIdRequest>, I>>(object: I): AckRequestIdRequest {
    const message = createBaseAckRequestIdRequest();
    message.requestId = object.requestId ?? 0;
    return message;
  },
};

function createBaseAckRequestIdResponse(): AckRequestIdResponse {
  return { status: 0 };
}

export const AckRequestIdResponse = {
  encode(message: AckRequestIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AckRequestIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAckRequestIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AckRequestIdResponse {
    return { status: isSet(object.status) ? asyncRequestStatusFromJSON(object.status) : 0 };
  },

  toJSON(message: AckRequestIdResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = asyncRequestStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AckRequestIdResponse>, I>>(base?: I): AckRequestIdResponse {
    return AckRequestIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AckRequestIdResponse>, I>>(object: I): AckRequestIdResponse {
    const message = createBaseAckRequestIdResponse();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseGetAuthNonceRequest(): GetAuthNonceRequest {
  return { besuAddress: "" };
}

export const GetAuthNonceRequest = {
  encode(message: GetAuthNonceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.besuAddress !== "") {
      writer.uint32(10).string(message.besuAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthNonceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthNonceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthNonceRequest {
    return { besuAddress: isSet(object.besuAddress) ? globalThis.String(object.besuAddress) : "" };
  },

  toJSON(message: GetAuthNonceRequest): unknown {
    const obj: any = {};
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthNonceRequest>, I>>(base?: I): GetAuthNonceRequest {
    return GetAuthNonceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthNonceRequest>, I>>(object: I): GetAuthNonceRequest {
    const message = createBaseGetAuthNonceRequest();
    message.besuAddress = object.besuAddress ?? "";
    return message;
  },
};

function createBaseGetAuthNonceResponse(): GetAuthNonceResponse {
  return { nonce: "" };
}

export const GetAuthNonceResponse = {
  encode(message: GetAuthNonceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nonce !== "") {
      writer.uint32(10).string(message.nonce);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthNonceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthNonceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nonce = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthNonceResponse {
    return { nonce: isSet(object.nonce) ? globalThis.String(object.nonce) : "" };
  },

  toJSON(message: GetAuthNonceResponse): unknown {
    const obj: any = {};
    if (message.nonce !== "") {
      obj.nonce = message.nonce;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthNonceResponse>, I>>(base?: I): GetAuthNonceResponse {
    return GetAuthNonceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthNonceResponse>, I>>(object: I): GetAuthNonceResponse {
    const message = createBaseGetAuthNonceResponse();
    message.nonce = object.nonce ?? "";
    return message;
  },
};

function createBaseGetAuthAccessTokenRequest(): GetAuthAccessTokenRequest {
  return { appVersion: "", did: "", besuAddress: "", mid: undefined, nonce: "", verifyCode: "", signature: "" };
}

export const GetAuthAccessTokenRequest = {
  encode(message: GetAuthAccessTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.appVersion !== "") {
      writer.uint32(10).string(message.appVersion);
    }
    if (message.did !== "") {
      writer.uint32(18).string(message.did);
    }
    if (message.besuAddress !== "") {
      writer.uint32(26).string(message.besuAddress);
    }
    if (message.mid !== undefined) {
      writer.uint32(34).string(message.mid);
    }
    if (message.nonce !== "") {
      writer.uint32(42).string(message.nonce);
    }
    if (message.verifyCode !== "") {
      writer.uint32(50).string(message.verifyCode);
    }
    if (message.signature !== "") {
      writer.uint32(58).string(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthAccessTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthAccessTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.appVersion = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.did = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mid = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nonce = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.verifyCode = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.signature = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthAccessTokenRequest {
    return {
      appVersion: isSet(object.appVersion) ? globalThis.String(object.appVersion) : "",
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      besuAddress: isSet(object.besuAddress) ? globalThis.String(object.besuAddress) : "",
      mid: isSet(object.mid) ? globalThis.String(object.mid) : undefined,
      nonce: isSet(object.nonce) ? globalThis.String(object.nonce) : "",
      verifyCode: isSet(object.verifyCode) ? globalThis.String(object.verifyCode) : "",
      signature: isSet(object.signature) ? globalThis.String(object.signature) : "",
    };
  },

  toJSON(message: GetAuthAccessTokenRequest): unknown {
    const obj: any = {};
    if (message.appVersion !== "") {
      obj.appVersion = message.appVersion;
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.mid !== undefined) {
      obj.mid = message.mid;
    }
    if (message.nonce !== "") {
      obj.nonce = message.nonce;
    }
    if (message.verifyCode !== "") {
      obj.verifyCode = message.verifyCode;
    }
    if (message.signature !== "") {
      obj.signature = message.signature;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthAccessTokenRequest>, I>>(base?: I): GetAuthAccessTokenRequest {
    return GetAuthAccessTokenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthAccessTokenRequest>, I>>(object: I): GetAuthAccessTokenRequest {
    const message = createBaseGetAuthAccessTokenRequest();
    message.appVersion = object.appVersion ?? "";
    message.did = object.did ?? "";
    message.besuAddress = object.besuAddress ?? "";
    message.mid = object.mid ?? undefined;
    message.nonce = object.nonce ?? "";
    message.verifyCode = object.verifyCode ?? "";
    message.signature = object.signature ?? "";
    return message;
  },
};

function createBaseGetAuthAccessTokenResponse(): GetAuthAccessTokenResponse {
  return { command: "", accessToken: "", midCount: 0, statusCode: 0 };
}

export const GetAuthAccessTokenResponse = {
  encode(message: GetAuthAccessTokenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.command !== "") {
      writer.uint32(10).string(message.command);
    }
    if (message.accessToken !== "") {
      writer.uint32(18).string(message.accessToken);
    }
    if (message.midCount !== 0) {
      writer.uint32(24).uint64(message.midCount);
    }
    if (message.statusCode !== 0) {
      writer.uint32(32).int32(message.statusCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthAccessTokenResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthAccessTokenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.command = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accessToken = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.midCount = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthAccessTokenResponse {
    return {
      command: isSet(object.command) ? globalThis.String(object.command) : "",
      accessToken: isSet(object.accessToken) ? globalThis.String(object.accessToken) : "",
      midCount: isSet(object.midCount) ? globalThis.Number(object.midCount) : 0,
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
    };
  },

  toJSON(message: GetAuthAccessTokenResponse): unknown {
    const obj: any = {};
    if (message.command !== "") {
      obj.command = message.command;
    }
    if (message.accessToken !== "") {
      obj.accessToken = message.accessToken;
    }
    if (message.midCount !== 0) {
      obj.midCount = Math.round(message.midCount);
    }
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthAccessTokenResponse>, I>>(base?: I): GetAuthAccessTokenResponse {
    return GetAuthAccessTokenResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthAccessTokenResponse>, I>>(object: I): GetAuthAccessTokenResponse {
    const message = createBaseGetAuthAccessTokenResponse();
    message.command = object.command ?? "";
    message.accessToken = object.accessToken ?? "";
    message.midCount = object.midCount ?? 0;
    message.statusCode = object.statusCode ?? 0;
    return message;
  },
};

function createBaseGetAuthWalletBasedAccessTokenRequest(): GetAuthWalletBasedAccessTokenRequest {
  return { besuAddress: "", nonce: "", signature: "" };
}

export const GetAuthWalletBasedAccessTokenRequest = {
  encode(message: GetAuthWalletBasedAccessTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.besuAddress !== "") {
      writer.uint32(10).string(message.besuAddress);
    }
    if (message.nonce !== "") {
      writer.uint32(18).string(message.nonce);
    }
    if (message.signature !== "") {
      writer.uint32(26).string(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthWalletBasedAccessTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthWalletBasedAccessTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nonce = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signature = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthWalletBasedAccessTokenRequest {
    return {
      besuAddress: isSet(object.besuAddress) ? globalThis.String(object.besuAddress) : "",
      nonce: isSet(object.nonce) ? globalThis.String(object.nonce) : "",
      signature: isSet(object.signature) ? globalThis.String(object.signature) : "",
    };
  },

  toJSON(message: GetAuthWalletBasedAccessTokenRequest): unknown {
    const obj: any = {};
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.nonce !== "") {
      obj.nonce = message.nonce;
    }
    if (message.signature !== "") {
      obj.signature = message.signature;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthWalletBasedAccessTokenRequest>, I>>(
    base?: I,
  ): GetAuthWalletBasedAccessTokenRequest {
    return GetAuthWalletBasedAccessTokenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthWalletBasedAccessTokenRequest>, I>>(
    object: I,
  ): GetAuthWalletBasedAccessTokenRequest {
    const message = createBaseGetAuthWalletBasedAccessTokenRequest();
    message.besuAddress = object.besuAddress ?? "";
    message.nonce = object.nonce ?? "";
    message.signature = object.signature ?? "";
    return message;
  },
};

function createBaseGetAuthWalletBasedAccessTokenResponse(): GetAuthWalletBasedAccessTokenResponse {
  return { accessToken: "" };
}

export const GetAuthWalletBasedAccessTokenResponse = {
  encode(message: GetAuthWalletBasedAccessTokenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accessToken !== "") {
      writer.uint32(18).string(message.accessToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthWalletBasedAccessTokenResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthWalletBasedAccessTokenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accessToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthWalletBasedAccessTokenResponse {
    return { accessToken: isSet(object.accessToken) ? globalThis.String(object.accessToken) : "" };
  },

  toJSON(message: GetAuthWalletBasedAccessTokenResponse): unknown {
    const obj: any = {};
    if (message.accessToken !== "") {
      obj.accessToken = message.accessToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthWalletBasedAccessTokenResponse>, I>>(
    base?: I,
  ): GetAuthWalletBasedAccessTokenResponse {
    return GetAuthWalletBasedAccessTokenResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthWalletBasedAccessTokenResponse>, I>>(
    object: I,
  ): GetAuthWalletBasedAccessTokenResponse {
    const message = createBaseGetAuthWalletBasedAccessTokenResponse();
    message.accessToken = object.accessToken ?? "";
    return message;
  },
};

function createBaseGetPiAccessTokenRequest(): GetPiAccessTokenRequest {
  return { pdsId: "" };
}

export const GetPiAccessTokenRequest = {
  encode(message: GetPiAccessTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pdsId !== "") {
      writer.uint32(10).string(message.pdsId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPiAccessTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPiAccessTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdsId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPiAccessTokenRequest {
    return { pdsId: isSet(object.pdsId) ? globalThis.String(object.pdsId) : "" };
  },

  toJSON(message: GetPiAccessTokenRequest): unknown {
    const obj: any = {};
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPiAccessTokenRequest>, I>>(base?: I): GetPiAccessTokenRequest {
    return GetPiAccessTokenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPiAccessTokenRequest>, I>>(object: I): GetPiAccessTokenRequest {
    const message = createBaseGetPiAccessTokenRequest();
    message.pdsId = object.pdsId ?? "";
    return message;
  },
};

function createBaseGetPiAccessTokenResponse(): GetPiAccessTokenResponse {
  return { piAccessToken: "" };
}

export const GetPiAccessTokenResponse = {
  encode(message: GetPiAccessTokenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.piAccessToken !== "") {
      writer.uint32(10).string(message.piAccessToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPiAccessTokenResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPiAccessTokenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.piAccessToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPiAccessTokenResponse {
    return { piAccessToken: isSet(object.piAccessToken) ? globalThis.String(object.piAccessToken) : "" };
  },

  toJSON(message: GetPiAccessTokenResponse): unknown {
    const obj: any = {};
    if (message.piAccessToken !== "") {
      obj.piAccessToken = message.piAccessToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPiAccessTokenResponse>, I>>(base?: I): GetPiAccessTokenResponse {
    return GetPiAccessTokenResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPiAccessTokenResponse>, I>>(object: I): GetPiAccessTokenResponse {
    const message = createBaseGetPiAccessTokenResponse();
    message.piAccessToken = object.piAccessToken ?? "";
    return message;
  },
};

function createBaseGetTicketIdToConsumeRequest(): GetTicketIdToConsumeRequest {
  return {};
}

export const GetTicketIdToConsumeRequest = {
  encode(_: GetTicketIdToConsumeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTicketIdToConsumeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTicketIdToConsumeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetTicketIdToConsumeRequest {
    return {};
  },

  toJSON(_: GetTicketIdToConsumeRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTicketIdToConsumeRequest>, I>>(base?: I): GetTicketIdToConsumeRequest {
    return GetTicketIdToConsumeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTicketIdToConsumeRequest>, I>>(_: I): GetTicketIdToConsumeRequest {
    const message = createBaseGetTicketIdToConsumeRequest();
    return message;
  },
};

function createBaseGetTicketIdToConsumeResponse(): GetTicketIdToConsumeResponse {
  return { ticketId: "" };
}

export const GetTicketIdToConsumeResponse = {
  encode(message: GetTicketIdToConsumeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ticketId !== "") {
      writer.uint32(10).string(message.ticketId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTicketIdToConsumeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTicketIdToConsumeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ticketId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTicketIdToConsumeResponse {
    return { ticketId: isSet(object.ticketId) ? globalThis.String(object.ticketId) : "" };
  },

  toJSON(message: GetTicketIdToConsumeResponse): unknown {
    const obj: any = {};
    if (message.ticketId !== "") {
      obj.ticketId = message.ticketId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTicketIdToConsumeResponse>, I>>(base?: I): GetTicketIdToConsumeResponse {
    return GetTicketIdToConsumeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTicketIdToConsumeResponse>, I>>(object: I): GetTicketIdToConsumeResponse {
    const message = createBaseGetTicketIdToConsumeResponse();
    message.ticketId = object.ticketId ?? "";
    return message;
  },
};

function createBaseListProposalRequest(): ListProposalRequest {
  return {
    updatedAfter: undefined,
    fetchStatic: false,
    fetchActiveGlobal: false,
    fetchActiveClient: false,
    purposes: [],
    proposalIds: [],
    finishedOnly: false,
  };
}

export const ListProposalRequest = {
  encode(message: ListProposalRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updatedAfter !== undefined) {
      writer.uint32(10).string(message.updatedAfter);
    }
    if (message.fetchStatic === true) {
      writer.uint32(16).bool(message.fetchStatic);
    }
    if (message.fetchActiveGlobal === true) {
      writer.uint32(24).bool(message.fetchActiveGlobal);
    }
    if (message.fetchActiveClient === true) {
      writer.uint32(32).bool(message.fetchActiveClient);
    }
    writer.uint32(42).fork();
    for (const v of message.purposes) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.proposalIds) {
      writer.uint32(50).string(v!);
    }
    if (message.finishedOnly === true) {
      writer.uint32(64).bool(message.finishedOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListProposalRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListProposalRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updatedAfter = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fetchStatic = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.fetchActiveGlobal = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.fetchActiveClient = reader.bool();
          continue;
        case 5:
          if (tag === 40) {
            message.purposes.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.purposes.push(reader.uint32());
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.proposalIds.push(reader.string());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.finishedOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListProposalRequest {
    return {
      updatedAfter: isSet(object.updatedAfter) ? globalThis.String(object.updatedAfter) : undefined,
      fetchStatic: isSet(object.fetchStatic) ? globalThis.Boolean(object.fetchStatic) : false,
      fetchActiveGlobal: isSet(object.fetchActiveGlobal) ? globalThis.Boolean(object.fetchActiveGlobal) : false,
      fetchActiveClient: isSet(object.fetchActiveClient) ? globalThis.Boolean(object.fetchActiveClient) : false,
      purposes: globalThis.Array.isArray(object?.purposes) ? object.purposes.map((e: any) => globalThis.Number(e)) : [],
      proposalIds: globalThis.Array.isArray(object?.proposalIds)
        ? object.proposalIds.map((e: any) => globalThis.String(e))
        : [],
      finishedOnly: isSet(object.finishedOnly) ? globalThis.Boolean(object.finishedOnly) : false,
    };
  },

  toJSON(message: ListProposalRequest): unknown {
    const obj: any = {};
    if (message.updatedAfter !== undefined) {
      obj.updatedAfter = message.updatedAfter;
    }
    if (message.fetchStatic === true) {
      obj.fetchStatic = message.fetchStatic;
    }
    if (message.fetchActiveGlobal === true) {
      obj.fetchActiveGlobal = message.fetchActiveGlobal;
    }
    if (message.fetchActiveClient === true) {
      obj.fetchActiveClient = message.fetchActiveClient;
    }
    if (message.purposes?.length) {
      obj.purposes = message.purposes.map((e) => Math.round(e));
    }
    if (message.proposalIds?.length) {
      obj.proposalIds = message.proposalIds;
    }
    if (message.finishedOnly === true) {
      obj.finishedOnly = message.finishedOnly;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListProposalRequest>, I>>(base?: I): ListProposalRequest {
    return ListProposalRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListProposalRequest>, I>>(object: I): ListProposalRequest {
    const message = createBaseListProposalRequest();
    message.updatedAfter = object.updatedAfter ?? undefined;
    message.fetchStatic = object.fetchStatic ?? false;
    message.fetchActiveGlobal = object.fetchActiveGlobal ?? false;
    message.fetchActiveClient = object.fetchActiveClient ?? false;
    message.purposes = object.purposes?.map((e) => e) || [];
    message.proposalIds = object.proposalIds?.map((e) => e) || [];
    message.finishedOnly = object.finishedOnly ?? false;
    return message;
  },
};

function createBaseListProposalResponse(): ListProposalResponse {
  return { proposals: [] };
}

export const ListProposalResponse = {
  encode(message: ListProposalResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.proposals) {
      Proposal.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListProposalResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListProposalResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposals.push(Proposal.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListProposalResponse {
    return {
      proposals: globalThis.Array.isArray(object?.proposals)
        ? object.proposals.map((e: any) => Proposal.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListProposalResponse): unknown {
    const obj: any = {};
    if (message.proposals?.length) {
      obj.proposals = message.proposals.map((e) => Proposal.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListProposalResponse>, I>>(base?: I): ListProposalResponse {
    return ListProposalResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListProposalResponse>, I>>(object: I): ListProposalResponse {
    const message = createBaseListProposalResponse();
    message.proposals = object.proposals?.map((e) => Proposal.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueTicketRequestMaterial(): IssueTicketRequestMaterial {
  return { materialPrefix: "", did: "", proposalId: "", createdAt: "" };
}

export const IssueTicketRequestMaterial = {
  encode(message: IssueTicketRequestMaterial, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.materialPrefix !== "") {
      writer.uint32(10).string(message.materialPrefix);
    }
    if (message.did !== "") {
      writer.uint32(18).string(message.did);
    }
    if (message.proposalId !== "") {
      writer.uint32(26).string(message.proposalId);
    }
    if (message.createdAt !== "") {
      writer.uint32(34).string(message.createdAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTicketRequestMaterial {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTicketRequestMaterial();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.materialPrefix = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.did = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTicketRequestMaterial {
    return {
      materialPrefix: isSet(object.materialPrefix) ? globalThis.String(object.materialPrefix) : "",
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
    };
  },

  toJSON(message: IssueTicketRequestMaterial): unknown {
    const obj: any = {};
    if (message.materialPrefix !== "") {
      obj.materialPrefix = message.materialPrefix;
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTicketRequestMaterial>, I>>(base?: I): IssueTicketRequestMaterial {
    return IssueTicketRequestMaterial.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueTicketRequestMaterial>, I>>(object: I): IssueTicketRequestMaterial {
    const message = createBaseIssueTicketRequestMaterial();
    message.materialPrefix = object.materialPrefix ?? "";
    message.did = object.did ?? "";
    message.proposalId = object.proposalId ?? "";
    message.createdAt = object.createdAt ?? "";
    return message;
  },
};

function createBaseIssueTicketRequest(): IssueTicketRequest {
  return { issueTicketRequestMaterial: undefined, signature: new Uint8Array(0) };
}

export const IssueTicketRequest = {
  encode(message: IssueTicketRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issueTicketRequestMaterial !== undefined) {
      IssueTicketRequestMaterial.encode(message.issueTicketRequestMaterial, writer.uint32(90).fork()).ldelim();
    }
    if (message.signature.length !== 0) {
      writer.uint32(98).bytes(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTicketRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTicketRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 11:
          if (tag !== 90) {
            break;
          }

          message.issueTicketRequestMaterial = IssueTicketRequestMaterial.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.signature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTicketRequest {
    return {
      issueTicketRequestMaterial: isSet(object.issueTicketRequestMaterial)
        ? IssueTicketRequestMaterial.fromJSON(object.issueTicketRequestMaterial)
        : undefined,
      signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(0),
    };
  },

  toJSON(message: IssueTicketRequest): unknown {
    const obj: any = {};
    if (message.issueTicketRequestMaterial !== undefined) {
      obj.issueTicketRequestMaterial = IssueTicketRequestMaterial.toJSON(message.issueTicketRequestMaterial);
    }
    if (message.signature.length !== 0) {
      obj.signature = base64FromBytes(message.signature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTicketRequest>, I>>(base?: I): IssueTicketRequest {
    return IssueTicketRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueTicketRequest>, I>>(object: I): IssueTicketRequest {
    const message = createBaseIssueTicketRequest();
    message.issueTicketRequestMaterial =
      (object.issueTicketRequestMaterial !== undefined && object.issueTicketRequestMaterial !== null)
        ? IssueTicketRequestMaterial.fromPartial(object.issueTicketRequestMaterial)
        : undefined;
    message.signature = object.signature ?? new Uint8Array(0);
    return message;
  },
};

function createBaseIssueTicketResponse(): IssueTicketResponse {
  return { proposalId: "", code: undefined, proposalActiveClient: undefined, piUploadAuthData: undefined };
}

export const IssueTicketResponse = {
  encode(message: IssueTicketResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId !== "") {
      writer.uint32(18).string(message.proposalId);
    }
    if (message.code !== undefined) {
      writer.uint32(24).int32(message.code);
    }
    if (message.proposalActiveClient !== undefined) {
      ProposalActiveClient.encode(message.proposalActiveClient, writer.uint32(34).fork()).ldelim();
    }
    if (message.piUploadAuthData !== undefined) {
      writer.uint32(42).string(message.piUploadAuthData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTicketResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTicketResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposalActiveClient = ProposalActiveClient.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.piUploadAuthData = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTicketResponse {
    return {
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : undefined,
      proposalActiveClient: isSet(object.proposalActiveClient)
        ? ProposalActiveClient.fromJSON(object.proposalActiveClient)
        : undefined,
      piUploadAuthData: isSet(object.piUploadAuthData) ? globalThis.String(object.piUploadAuthData) : undefined,
    };
  },

  toJSON(message: IssueTicketResponse): unknown {
    const obj: any = {};
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.code !== undefined) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.proposalActiveClient !== undefined) {
      obj.proposalActiveClient = ProposalActiveClient.toJSON(message.proposalActiveClient);
    }
    if (message.piUploadAuthData !== undefined) {
      obj.piUploadAuthData = message.piUploadAuthData;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTicketResponse>, I>>(base?: I): IssueTicketResponse {
    return IssueTicketResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueTicketResponse>, I>>(object: I): IssueTicketResponse {
    const message = createBaseIssueTicketResponse();
    message.proposalId = object.proposalId ?? "";
    message.code = object.code ?? undefined;
    message.proposalActiveClient = (object.proposalActiveClient !== undefined && object.proposalActiveClient !== null)
      ? ProposalActiveClient.fromPartial(object.proposalActiveClient)
      : undefined;
    message.piUploadAuthData = object.piUploadAuthData ?? undefined;
    return message;
  },
};

function createBasePiUploadAuthData(): PiUploadAuthData {
  return { piAccessToken: undefined, signedTokenWithChain: undefined };
}

export const PiUploadAuthData = {
  encode(message: PiUploadAuthData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.piAccessToken !== undefined) {
      writer.uint32(10).string(message.piAccessToken);
    }
    if (message.signedTokenWithChain !== undefined) {
      writer.uint32(18).bytes(message.signedTokenWithChain);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PiUploadAuthData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePiUploadAuthData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.piAccessToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.signedTokenWithChain = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PiUploadAuthData {
    return {
      piAccessToken: isSet(object.piAccessToken) ? globalThis.String(object.piAccessToken) : undefined,
      signedTokenWithChain: isSet(object.signedTokenWithChain)
        ? bytesFromBase64(object.signedTokenWithChain)
        : undefined,
    };
  },

  toJSON(message: PiUploadAuthData): unknown {
    const obj: any = {};
    if (message.piAccessToken !== undefined) {
      obj.piAccessToken = message.piAccessToken;
    }
    if (message.signedTokenWithChain !== undefined) {
      obj.signedTokenWithChain = base64FromBytes(message.signedTokenWithChain);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PiUploadAuthData>, I>>(base?: I): PiUploadAuthData {
    return PiUploadAuthData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PiUploadAuthData>, I>>(object: I): PiUploadAuthData {
    const message = createBasePiUploadAuthData();
    message.piAccessToken = object.piAccessToken ?? undefined;
    message.signedTokenWithChain = object.signedTokenWithChain ?? undefined;
    return message;
  },
};

function createBaseUploadPiRequest(): UploadPiRequest {
  return { pdsId: "", proposalId: "", gzippedPayload: new Uint8Array(0) };
}

export const UploadPiRequest = {
  encode(message: UploadPiRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pdsId !== "") {
      writer.uint32(10).string(message.pdsId);
    }
    if (message.proposalId !== "") {
      writer.uint32(18).string(message.proposalId);
    }
    if (message.gzippedPayload.length !== 0) {
      writer.uint32(26).bytes(message.gzippedPayload);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadPiRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadPiRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdsId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.gzippedPayload = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadPiRequest {
    return {
      pdsId: isSet(object.pdsId) ? globalThis.String(object.pdsId) : "",
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
      gzippedPayload: isSet(object.gzippedPayload) ? bytesFromBase64(object.gzippedPayload) : new Uint8Array(0),
    };
  },

  toJSON(message: UploadPiRequest): unknown {
    const obj: any = {};
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.gzippedPayload.length !== 0) {
      obj.gzippedPayload = base64FromBytes(message.gzippedPayload);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadPiRequest>, I>>(base?: I): UploadPiRequest {
    return UploadPiRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadPiRequest>, I>>(object: I): UploadPiRequest {
    const message = createBaseUploadPiRequest();
    message.pdsId = object.pdsId ?? "";
    message.proposalId = object.proposalId ?? "";
    message.gzippedPayload = object.gzippedPayload ?? new Uint8Array(0);
    return message;
  },
};

function createBaseUploadPiResponse(): UploadPiResponse {
  return { pdsId: "", code: 0, message: undefined };
}

export const UploadPiResponse = {
  encode(message: UploadPiResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pdsId !== "") {
      writer.uint32(10).string(message.pdsId);
    }
    if (message.code !== 0) {
      writer.uint32(24).int32(message.code);
    }
    if (message.message !== undefined) {
      writer.uint32(34).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadPiResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadPiResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdsId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadPiResponse {
    return {
      pdsId: isSet(object.pdsId) ? globalThis.String(object.pdsId) : "",
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
    };
  },

  toJSON(message: UploadPiResponse): unknown {
    const obj: any = {};
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadPiResponse>, I>>(base?: I): UploadPiResponse {
    return UploadPiResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadPiResponse>, I>>(object: I): UploadPiResponse {
    const message = createBaseUploadPiResponse();
    message.pdsId = object.pdsId ?? "";
    message.code = object.code ?? 0;
    message.message = object.message ?? undefined;
    return message;
  },
};

function createBasePreConsumePayload(): PreConsumePayload {
  return { timestamp: 0, pdsId: "" };
}

export const PreConsumePayload = {
  encode(message: PreConsumePayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== 0) {
      writer.uint32(8).uint64(message.timestamp);
    }
    if (message.pdsId !== "") {
      writer.uint32(18).string(message.pdsId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PreConsumePayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePreConsumePayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestamp = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pdsId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PreConsumePayload {
    return {
      timestamp: isSet(object.timestamp) ? globalThis.Number(object.timestamp) : 0,
      pdsId: isSet(object.pdsId) ? globalThis.String(object.pdsId) : "",
    };
  },

  toJSON(message: PreConsumePayload): unknown {
    const obj: any = {};
    if (message.timestamp !== 0) {
      obj.timestamp = Math.round(message.timestamp);
    }
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PreConsumePayload>, I>>(base?: I): PreConsumePayload {
    return PreConsumePayload.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PreConsumePayload>, I>>(object: I): PreConsumePayload {
    const message = createBasePreConsumePayload();
    message.timestamp = object.timestamp ?? 0;
    message.pdsId = object.pdsId ?? "";
    return message;
  },
};

function createBasePreConsumeRequest(): PreConsumeRequest {
  return { preConsumePayload: undefined, besuAddress: "", signature: new Uint8Array(0) };
}

export const PreConsumeRequest = {
  encode(message: PreConsumeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preConsumePayload !== undefined) {
      PreConsumePayload.encode(message.preConsumePayload, writer.uint32(10).fork()).ldelim();
    }
    if (message.besuAddress !== "") {
      writer.uint32(18).string(message.besuAddress);
    }
    if (message.signature.length !== 0) {
      writer.uint32(26).bytes(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PreConsumeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePreConsumeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.preConsumePayload = PreConsumePayload.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PreConsumeRequest {
    return {
      preConsumePayload: isSet(object.preConsumePayload)
        ? PreConsumePayload.fromJSON(object.preConsumePayload)
        : undefined,
      besuAddress: isSet(object.besuAddress) ? globalThis.String(object.besuAddress) : "",
      signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(0),
    };
  },

  toJSON(message: PreConsumeRequest): unknown {
    const obj: any = {};
    if (message.preConsumePayload !== undefined) {
      obj.preConsumePayload = PreConsumePayload.toJSON(message.preConsumePayload);
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.signature.length !== 0) {
      obj.signature = base64FromBytes(message.signature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PreConsumeRequest>, I>>(base?: I): PreConsumeRequest {
    return PreConsumeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PreConsumeRequest>, I>>(object: I): PreConsumeRequest {
    const message = createBasePreConsumeRequest();
    message.preConsumePayload = (object.preConsumePayload !== undefined && object.preConsumePayload !== null)
      ? PreConsumePayload.fromPartial(object.preConsumePayload)
      : undefined;
    message.besuAddress = object.besuAddress ?? "";
    message.signature = object.signature ?? new Uint8Array(0);
    return message;
  },
};

function createBasePreConsumeResponse(): PreConsumeResponse {
  return { blockchainNonce: 0, nextRequestId: 0, contractAddress: "", pdsId: "", ticketId: "", purpose: 0 };
}

export const PreConsumeResponse = {
  encode(message: PreConsumeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.blockchainNonce !== 0) {
      writer.uint32(8).uint64(message.blockchainNonce);
    }
    if (message.nextRequestId !== 0) {
      writer.uint32(16).uint32(message.nextRequestId);
    }
    if (message.contractAddress !== "") {
      writer.uint32(26).string(message.contractAddress);
    }
    if (message.pdsId !== "") {
      writer.uint32(34).string(message.pdsId);
    }
    if (message.ticketId !== "") {
      writer.uint32(42).string(message.ticketId);
    }
    if (message.purpose !== 0) {
      writer.uint32(48).int32(message.purpose);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PreConsumeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePreConsumeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.blockchainNonce = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.nextRequestId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contractAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pdsId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ticketId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.purpose = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PreConsumeResponse {
    return {
      blockchainNonce: isSet(object.blockchainNonce) ? globalThis.Number(object.blockchainNonce) : 0,
      nextRequestId: isSet(object.nextRequestId) ? globalThis.Number(object.nextRequestId) : 0,
      contractAddress: isSet(object.contractAddress) ? globalThis.String(object.contractAddress) : "",
      pdsId: isSet(object.pdsId) ? globalThis.String(object.pdsId) : "",
      ticketId: isSet(object.ticketId) ? globalThis.String(object.ticketId) : "",
      purpose: isSet(object.purpose) ? globalThis.Number(object.purpose) : 0,
    };
  },

  toJSON(message: PreConsumeResponse): unknown {
    const obj: any = {};
    if (message.blockchainNonce !== 0) {
      obj.blockchainNonce = Math.round(message.blockchainNonce);
    }
    if (message.nextRequestId !== 0) {
      obj.nextRequestId = Math.round(message.nextRequestId);
    }
    if (message.contractAddress !== "") {
      obj.contractAddress = message.contractAddress;
    }
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    if (message.ticketId !== "") {
      obj.ticketId = message.ticketId;
    }
    if (message.purpose !== 0) {
      obj.purpose = Math.round(message.purpose);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PreConsumeResponse>, I>>(base?: I): PreConsumeResponse {
    return PreConsumeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PreConsumeResponse>, I>>(object: I): PreConsumeResponse {
    const message = createBasePreConsumeResponse();
    message.blockchainNonce = object.blockchainNonce ?? 0;
    message.nextRequestId = object.nextRequestId ?? 0;
    message.contractAddress = object.contractAddress ?? "";
    message.pdsId = object.pdsId ?? "";
    message.ticketId = object.ticketId ?? "";
    message.purpose = object.purpose ?? 0;
    return message;
  },
};

function createBaseConsumeTicketRequest(): ConsumeTicketRequest {
  return { requestId: 0, pdsId: "", rawTransaction: new Uint8Array(0), besuAddress: "" };
}

export const ConsumeTicketRequest = {
  encode(message: ConsumeTicketRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.pdsId !== "") {
      writer.uint32(18).string(message.pdsId);
    }
    if (message.rawTransaction.length !== 0) {
      writer.uint32(26).bytes(message.rawTransaction);
    }
    if (message.besuAddress !== "") {
      writer.uint32(34).string(message.besuAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConsumeTicketRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumeTicketRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pdsId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rawTransaction = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConsumeTicketRequest {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      pdsId: isSet(object.pdsId) ? globalThis.String(object.pdsId) : "",
      rawTransaction: isSet(object.rawTransaction) ? bytesFromBase64(object.rawTransaction) : new Uint8Array(0),
      besuAddress: isSet(object.besuAddress) ? globalThis.String(object.besuAddress) : "",
    };
  },

  toJSON(message: ConsumeTicketRequest): unknown {
    const obj: any = {};
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    if (message.rawTransaction.length !== 0) {
      obj.rawTransaction = base64FromBytes(message.rawTransaction);
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConsumeTicketRequest>, I>>(base?: I): ConsumeTicketRequest {
    return ConsumeTicketRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConsumeTicketRequest>, I>>(object: I): ConsumeTicketRequest {
    const message = createBaseConsumeTicketRequest();
    message.requestId = object.requestId ?? 0;
    message.pdsId = object.pdsId ?? "";
    message.rawTransaction = object.rawTransaction ?? new Uint8Array(0);
    message.besuAddress = object.besuAddress ?? "";
    return message;
  },
};

function createBaseConsumeTicketResponse(): ConsumeTicketResponse {
  return {
    status: 0,
    code: undefined,
    retryWithNewBlockChainNonce: false,
    newBlockChainNonce: 0,
    duplicatedRequestId: undefined,
  };
}

export const ConsumeTicketResponse = {
  encode(message: ConsumeTicketResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.code !== undefined) {
      writer.uint32(24).int32(message.code);
    }
    if (message.retryWithNewBlockChainNonce === true) {
      writer.uint32(40).bool(message.retryWithNewBlockChainNonce);
    }
    if (message.newBlockChainNonce !== 0) {
      writer.uint32(48).uint64(message.newBlockChainNonce);
    }
    if (message.duplicatedRequestId !== undefined) {
      writer.uint32(56).uint32(message.duplicatedRequestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConsumeTicketResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumeTicketResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.retryWithNewBlockChainNonce = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.newBlockChainNonce = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.duplicatedRequestId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConsumeTicketResponse {
    return {
      status: isSet(object.status) ? asyncRequestStatusFromJSON(object.status) : 0,
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : undefined,
      retryWithNewBlockChainNonce: isSet(object.retryWithNewBlockChainNonce)
        ? globalThis.Boolean(object.retryWithNewBlockChainNonce)
        : false,
      newBlockChainNonce: isSet(object.newBlockChainNonce) ? globalThis.Number(object.newBlockChainNonce) : 0,
      duplicatedRequestId: isSet(object.duplicatedRequestId)
        ? globalThis.Number(object.duplicatedRequestId)
        : undefined,
    };
  },

  toJSON(message: ConsumeTicketResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = asyncRequestStatusToJSON(message.status);
    }
    if (message.code !== undefined) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.retryWithNewBlockChainNonce === true) {
      obj.retryWithNewBlockChainNonce = message.retryWithNewBlockChainNonce;
    }
    if (message.newBlockChainNonce !== 0) {
      obj.newBlockChainNonce = Math.round(message.newBlockChainNonce);
    }
    if (message.duplicatedRequestId !== undefined) {
      obj.duplicatedRequestId = Math.round(message.duplicatedRequestId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConsumeTicketResponse>, I>>(base?: I): ConsumeTicketResponse {
    return ConsumeTicketResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConsumeTicketResponse>, I>>(object: I): ConsumeTicketResponse {
    const message = createBaseConsumeTicketResponse();
    message.status = object.status ?? 0;
    message.code = object.code ?? undefined;
    message.retryWithNewBlockChainNonce = object.retryWithNewBlockChainNonce ?? false;
    message.newBlockChainNonce = object.newBlockChainNonce ?? 0;
    message.duplicatedRequestId = object.duplicatedRequestId ?? undefined;
    return message;
  },
};

function createBaseDataReceiptPayload(): DataReceiptPayload {
  return { timestamp: 0, pdsId: "" };
}

export const DataReceiptPayload = {
  encode(message: DataReceiptPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== 0) {
      writer.uint32(8).uint64(message.timestamp);
    }
    if (message.pdsId !== "") {
      writer.uint32(18).string(message.pdsId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataReceiptPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataReceiptPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestamp = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pdsId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataReceiptPayload {
    return {
      timestamp: isSet(object.timestamp) ? globalThis.Number(object.timestamp) : 0,
      pdsId: isSet(object.pdsId) ? globalThis.String(object.pdsId) : "",
    };
  },

  toJSON(message: DataReceiptPayload): unknown {
    const obj: any = {};
    if (message.timestamp !== 0) {
      obj.timestamp = Math.round(message.timestamp);
    }
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataReceiptPayload>, I>>(base?: I): DataReceiptPayload {
    return DataReceiptPayload.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataReceiptPayload>, I>>(object: I): DataReceiptPayload {
    const message = createBaseDataReceiptPayload();
    message.timestamp = object.timestamp ?? 0;
    message.pdsId = object.pdsId ?? "";
    return message;
  },
};

function createBaseDataReceiptNotification(): DataReceiptNotification {
  return { payload: undefined, besuAddress: "", signature: new Uint8Array(0) };
}

export const DataReceiptNotification = {
  encode(message: DataReceiptNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.payload !== undefined) {
      DataReceiptPayload.encode(message.payload, writer.uint32(10).fork()).ldelim();
    }
    if (message.besuAddress !== "") {
      writer.uint32(18).string(message.besuAddress);
    }
    if (message.signature.length !== 0) {
      writer.uint32(26).bytes(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataReceiptNotification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataReceiptNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payload = DataReceiptPayload.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataReceiptNotification {
    return {
      payload: isSet(object.payload) ? DataReceiptPayload.fromJSON(object.payload) : undefined,
      besuAddress: isSet(object.besuAddress) ? globalThis.String(object.besuAddress) : "",
      signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(0),
    };
  },

  toJSON(message: DataReceiptNotification): unknown {
    const obj: any = {};
    if (message.payload !== undefined) {
      obj.payload = DataReceiptPayload.toJSON(message.payload);
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.signature.length !== 0) {
      obj.signature = base64FromBytes(message.signature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataReceiptNotification>, I>>(base?: I): DataReceiptNotification {
    return DataReceiptNotification.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataReceiptNotification>, I>>(object: I): DataReceiptNotification {
    const message = createBaseDataReceiptNotification();
    message.payload = (object.payload !== undefined && object.payload !== null)
      ? DataReceiptPayload.fromPartial(object.payload)
      : undefined;
    message.besuAddress = object.besuAddress ?? "";
    message.signature = object.signature ?? new Uint8Array(0);
    return message;
  },
};

function createBaseDataReceiptNotificationResponse(): DataReceiptNotificationResponse {
  return { code: 0, message: undefined };
}

export const DataReceiptNotificationResponse = {
  encode(message: DataReceiptNotificationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataReceiptNotificationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataReceiptNotificationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataReceiptNotificationResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
    };
  },

  toJSON(message: DataReceiptNotificationResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataReceiptNotificationResponse>, I>>(base?: I): DataReceiptNotificationResponse {
    return DataReceiptNotificationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataReceiptNotificationResponse>, I>>(
    object: I,
  ): DataReceiptNotificationResponse {
    const message = createBaseDataReceiptNotificationResponse();
    message.code = object.code ?? 0;
    message.message = object.message ?? undefined;
    return message;
  },
};

function createBaseDataReceiptNotificationList(): DataReceiptNotificationList {
  return { notifications: [] };
}

export const DataReceiptNotificationList = {
  encode(message: DataReceiptNotificationList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notifications) {
      DataReceiptNotification.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataReceiptNotificationList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataReceiptNotificationList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notifications.push(DataReceiptNotification.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataReceiptNotificationList {
    return {
      notifications: globalThis.Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => DataReceiptNotification.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DataReceiptNotificationList): unknown {
    const obj: any = {};
    if (message.notifications?.length) {
      obj.notifications = message.notifications.map((e) => DataReceiptNotification.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataReceiptNotificationList>, I>>(base?: I): DataReceiptNotificationList {
    return DataReceiptNotificationList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataReceiptNotificationList>, I>>(object: I): DataReceiptNotificationList {
    const message = createBaseDataReceiptNotificationList();
    message.notifications = object.notifications?.map((e) => DataReceiptNotification.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListDataReceiptRequest(): ListDataReceiptRequest {
  return { pdsIds: [] };
}

export const ListDataReceiptRequest = {
  encode(message: ListDataReceiptRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.pdsIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDataReceiptRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDataReceiptRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdsIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDataReceiptRequest {
    return {
      pdsIds: globalThis.Array.isArray(object?.pdsIds) ? object.pdsIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ListDataReceiptRequest): unknown {
    const obj: any = {};
    if (message.pdsIds?.length) {
      obj.pdsIds = message.pdsIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListDataReceiptRequest>, I>>(base?: I): ListDataReceiptRequest {
    return ListDataReceiptRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListDataReceiptRequest>, I>>(object: I): ListDataReceiptRequest {
    const message = createBaseListDataReceiptRequest();
    message.pdsIds = object.pdsIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseListDataReceiptResponse(): ListDataReceiptResponse {
  return { notifications: [] };
}

export const ListDataReceiptResponse = {
  encode(message: ListDataReceiptResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notifications) {
      DataReceiptNotification.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDataReceiptResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDataReceiptResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notifications.push(DataReceiptNotification.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDataReceiptResponse {
    return {
      notifications: globalThis.Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => DataReceiptNotification.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListDataReceiptResponse): unknown {
    const obj: any = {};
    if (message.notifications?.length) {
      obj.notifications = message.notifications.map((e) => DataReceiptNotification.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListDataReceiptResponse>, I>>(base?: I): ListDataReceiptResponse {
    return ListDataReceiptResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListDataReceiptResponse>, I>>(object: I): ListDataReceiptResponse {
    const message = createBaseListDataReceiptResponse();
    message.notifications = object.notifications?.map((e) => DataReceiptNotification.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListRewardHistoryRequest(): ListRewardHistoryRequest {
  return { did: "", startAt: "", endAt: "", requestType: 0, pageSize: 0, pageNum: 0 };
}

export const ListRewardHistoryRequest = {
  encode(message: ListRewardHistoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.startAt !== "") {
      writer.uint32(18).string(message.startAt);
    }
    if (message.endAt !== "") {
      writer.uint32(26).string(message.endAt);
    }
    if (message.requestType !== 0) {
      writer.uint32(32).int32(message.requestType);
    }
    if (message.pageSize !== 0) {
      writer.uint32(40).uint32(message.pageSize);
    }
    if (message.pageNum !== 0) {
      writer.uint32(48).uint32(message.pageNum);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRewardHistoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRewardHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endAt = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.requestType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.pageNum = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRewardHistoryRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      startAt: isSet(object.startAt) ? globalThis.String(object.startAt) : "",
      endAt: isSet(object.endAt) ? globalThis.String(object.endAt) : "",
      requestType: isSet(object.requestType) ? listRewardHistoryRequestTypeFromJSON(object.requestType) : 0,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageNum: isSet(object.pageNum) ? globalThis.Number(object.pageNum) : 0,
    };
  },

  toJSON(message: ListRewardHistoryRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.startAt !== "") {
      obj.startAt = message.startAt;
    }
    if (message.endAt !== "") {
      obj.endAt = message.endAt;
    }
    if (message.requestType !== 0) {
      obj.requestType = listRewardHistoryRequestTypeToJSON(message.requestType);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNum !== 0) {
      obj.pageNum = Math.round(message.pageNum);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListRewardHistoryRequest>, I>>(base?: I): ListRewardHistoryRequest {
    return ListRewardHistoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListRewardHistoryRequest>, I>>(object: I): ListRewardHistoryRequest {
    const message = createBaseListRewardHistoryRequest();
    message.did = object.did ?? "";
    message.startAt = object.startAt ?? "";
    message.endAt = object.endAt ?? "";
    message.requestType = object.requestType ?? 0;
    message.pageSize = object.pageSize ?? 0;
    message.pageNum = object.pageNum ?? 0;
    return message;
  },
};

function createBaseListRewardHistoryResponse(): ListRewardHistoryResponse {
  return { did: "", totalCount: 0, pageSize: 0, pageNum: 0, balance: 0, amountExpiresWithin1m: 0, contents: [] };
}

export const ListRewardHistoryResponse = {
  encode(message: ListRewardHistoryResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.totalCount !== 0) {
      writer.uint32(16).uint64(message.totalCount);
    }
    if (message.pageSize !== 0) {
      writer.uint32(24).uint32(message.pageSize);
    }
    if (message.pageNum !== 0) {
      writer.uint32(32).uint32(message.pageNum);
    }
    if (message.balance !== 0) {
      writer.uint32(48).uint64(message.balance);
    }
    if (message.amountExpiresWithin1m !== 0) {
      writer.uint32(56).uint64(message.amountExpiresWithin1m);
    }
    for (const v of message.contents) {
      RewardTransactionContentForApp.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRewardHistoryResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRewardHistoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalCount = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.pageNum = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.balance = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.amountExpiresWithin1m = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.contents.push(RewardTransactionContentForApp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRewardHistoryResponse {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      totalCount: isSet(object.totalCount) ? globalThis.Number(object.totalCount) : 0,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageNum: isSet(object.pageNum) ? globalThis.Number(object.pageNum) : 0,
      balance: isSet(object.balance) ? globalThis.Number(object.balance) : 0,
      amountExpiresWithin1m: isSet(object.amountExpiresWithin1m) ? globalThis.Number(object.amountExpiresWithin1m) : 0,
      contents: globalThis.Array.isArray(object?.contents)
        ? object.contents.map((e: any) => RewardTransactionContentForApp.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListRewardHistoryResponse): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.totalCount !== 0) {
      obj.totalCount = Math.round(message.totalCount);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNum !== 0) {
      obj.pageNum = Math.round(message.pageNum);
    }
    if (message.balance !== 0) {
      obj.balance = Math.round(message.balance);
    }
    if (message.amountExpiresWithin1m !== 0) {
      obj.amountExpiresWithin1m = Math.round(message.amountExpiresWithin1m);
    }
    if (message.contents?.length) {
      obj.contents = message.contents.map((e) => RewardTransactionContentForApp.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListRewardHistoryResponse>, I>>(base?: I): ListRewardHistoryResponse {
    return ListRewardHistoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListRewardHistoryResponse>, I>>(object: I): ListRewardHistoryResponse {
    const message = createBaseListRewardHistoryResponse();
    message.did = object.did ?? "";
    message.totalCount = object.totalCount ?? 0;
    message.pageSize = object.pageSize ?? 0;
    message.pageNum = object.pageNum ?? 0;
    message.balance = object.balance ?? 0;
    message.amountExpiresWithin1m = object.amountExpiresWithin1m ?? 0;
    message.contents = object.contents?.map((e) => RewardTransactionContentForApp.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCouponIdRequest(): GetCouponIdRequest {
  return { did: "" };
}

export const GetCouponIdRequest = {
  encode(message: GetCouponIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCouponIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCouponIdRequest {
    return { did: isSet(object.did) ? globalThis.String(object.did) : "" };
  },

  toJSON(message: GetCouponIdRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCouponIdRequest>, I>>(base?: I): GetCouponIdRequest {
    return GetCouponIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCouponIdRequest>, I>>(object: I): GetCouponIdRequest {
    const message = createBaseGetCouponIdRequest();
    message.did = object.did ?? "";
    return message;
  },
};

function createBaseGetCouponIdResponse(): GetCouponIdResponse {
  return {
    did: "",
    couponTransactionId: "",
    blockchainRequestId: new Uint8Array(0),
    blockchainRewardTransferNonce: 0,
    blockchainRewardReceiverAddress: "",
    blockchainRewardContractAddress: "",
    code: 0,
  };
}

export const GetCouponIdResponse = {
  encode(message: GetCouponIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.couponTransactionId !== "") {
      writer.uint32(18).string(message.couponTransactionId);
    }
    if (message.blockchainRequestId.length !== 0) {
      writer.uint32(26).bytes(message.blockchainRequestId);
    }
    if (message.blockchainRewardTransferNonce !== 0) {
      writer.uint32(32).uint64(message.blockchainRewardTransferNonce);
    }
    if (message.blockchainRewardReceiverAddress !== "") {
      writer.uint32(42).string(message.blockchainRewardReceiverAddress);
    }
    if (message.blockchainRewardContractAddress !== "") {
      writer.uint32(50).string(message.blockchainRewardContractAddress);
    }
    if (message.code !== 0) {
      writer.uint32(56).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCouponIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.couponTransactionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.blockchainRequestId = reader.bytes();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.blockchainRewardTransferNonce = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.blockchainRewardReceiverAddress = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.blockchainRewardContractAddress = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCouponIdResponse {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      couponTransactionId: isSet(object.couponTransactionId) ? globalThis.String(object.couponTransactionId) : "",
      blockchainRequestId: isSet(object.blockchainRequestId)
        ? bytesFromBase64(object.blockchainRequestId)
        : new Uint8Array(0),
      blockchainRewardTransferNonce: isSet(object.blockchainRewardTransferNonce)
        ? globalThis.Number(object.blockchainRewardTransferNonce)
        : 0,
      blockchainRewardReceiverAddress: isSet(object.blockchainRewardReceiverAddress)
        ? globalThis.String(object.blockchainRewardReceiverAddress)
        : "",
      blockchainRewardContractAddress: isSet(object.blockchainRewardContractAddress)
        ? globalThis.String(object.blockchainRewardContractAddress)
        : "",
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
    };
  },

  toJSON(message: GetCouponIdResponse): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.couponTransactionId !== "") {
      obj.couponTransactionId = message.couponTransactionId;
    }
    if (message.blockchainRequestId.length !== 0) {
      obj.blockchainRequestId = base64FromBytes(message.blockchainRequestId);
    }
    if (message.blockchainRewardTransferNonce !== 0) {
      obj.blockchainRewardTransferNonce = Math.round(message.blockchainRewardTransferNonce);
    }
    if (message.blockchainRewardReceiverAddress !== "") {
      obj.blockchainRewardReceiverAddress = message.blockchainRewardReceiverAddress;
    }
    if (message.blockchainRewardContractAddress !== "") {
      obj.blockchainRewardContractAddress = message.blockchainRewardContractAddress;
    }
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCouponIdResponse>, I>>(base?: I): GetCouponIdResponse {
    return GetCouponIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCouponIdResponse>, I>>(object: I): GetCouponIdResponse {
    const message = createBaseGetCouponIdResponse();
    message.did = object.did ?? "";
    message.couponTransactionId = object.couponTransactionId ?? "";
    message.blockchainRequestId = object.blockchainRequestId ?? new Uint8Array(0);
    message.blockchainRewardTransferNonce = object.blockchainRewardTransferNonce ?? 0;
    message.blockchainRewardReceiverAddress = object.blockchainRewardReceiverAddress ?? "";
    message.blockchainRewardContractAddress = object.blockchainRewardContractAddress ?? "";
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseIssueCouponRequest(): IssueCouponRequest {
  return {
    did: "",
    purchaseRequestMaterial: new Uint8Array(0),
    purchaseRequestMaterialSignature: new Uint8Array(0),
    goodsCode: "",
    clientKeyMaterial: new Uint8Array(0),
  };
}

export const IssueCouponRequest = {
  encode(message: IssueCouponRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.purchaseRequestMaterial.length !== 0) {
      writer.uint32(18).bytes(message.purchaseRequestMaterial);
    }
    if (message.purchaseRequestMaterialSignature.length !== 0) {
      writer.uint32(26).bytes(message.purchaseRequestMaterialSignature);
    }
    if (message.goodsCode !== "") {
      writer.uint32(34).string(message.goodsCode);
    }
    if (message.clientKeyMaterial.length !== 0) {
      writer.uint32(42).bytes(message.clientKeyMaterial);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueCouponRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueCouponRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.purchaseRequestMaterial = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.purchaseRequestMaterialSignature = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.goodsCode = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.clientKeyMaterial = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueCouponRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      purchaseRequestMaterial: isSet(object.purchaseRequestMaterial)
        ? bytesFromBase64(object.purchaseRequestMaterial)
        : new Uint8Array(0),
      purchaseRequestMaterialSignature: isSet(object.purchaseRequestMaterialSignature)
        ? bytesFromBase64(object.purchaseRequestMaterialSignature)
        : new Uint8Array(0),
      goodsCode: isSet(object.goodsCode) ? globalThis.String(object.goodsCode) : "",
      clientKeyMaterial: isSet(object.clientKeyMaterial)
        ? bytesFromBase64(object.clientKeyMaterial)
        : new Uint8Array(0),
    };
  },

  toJSON(message: IssueCouponRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.purchaseRequestMaterial.length !== 0) {
      obj.purchaseRequestMaterial = base64FromBytes(message.purchaseRequestMaterial);
    }
    if (message.purchaseRequestMaterialSignature.length !== 0) {
      obj.purchaseRequestMaterialSignature = base64FromBytes(message.purchaseRequestMaterialSignature);
    }
    if (message.goodsCode !== "") {
      obj.goodsCode = message.goodsCode;
    }
    if (message.clientKeyMaterial.length !== 0) {
      obj.clientKeyMaterial = base64FromBytes(message.clientKeyMaterial);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueCouponRequest>, I>>(base?: I): IssueCouponRequest {
    return IssueCouponRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueCouponRequest>, I>>(object: I): IssueCouponRequest {
    const message = createBaseIssueCouponRequest();
    message.did = object.did ?? "";
    message.purchaseRequestMaterial = object.purchaseRequestMaterial ?? new Uint8Array(0);
    message.purchaseRequestMaterialSignature = object.purchaseRequestMaterialSignature ?? new Uint8Array(0);
    message.goodsCode = object.goodsCode ?? "";
    message.clientKeyMaterial = object.clientKeyMaterial ?? new Uint8Array(0);
    return message;
  },
};

function createBaseIssueCouponResponse(): IssueCouponResponse {
  return {
    did: "",
    couponTransactionId: "",
    code: 0,
    serverKeyMaterial: new Uint8Array(0),
    ephemeralPublicKey: new Uint8Array(0),
    encryptedCouponData: new Uint8Array(0),
  };
}

export const IssueCouponResponse = {
  encode(message: IssueCouponResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.couponTransactionId !== "") {
      writer.uint32(18).string(message.couponTransactionId);
    }
    if (message.code !== 0) {
      writer.uint32(24).int32(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      writer.uint32(34).bytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      writer.uint32(42).bytes(message.ephemeralPublicKey);
    }
    if (message.encryptedCouponData.length !== 0) {
      writer.uint32(50).bytes(message.encryptedCouponData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueCouponResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueCouponResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.couponTransactionId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.serverKeyMaterial = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ephemeralPublicKey = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.encryptedCouponData = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueCouponResponse {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      couponTransactionId: isSet(object.couponTransactionId) ? globalThis.String(object.couponTransactionId) : "",
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      serverKeyMaterial: isSet(object.serverKeyMaterial)
        ? bytesFromBase64(object.serverKeyMaterial)
        : new Uint8Array(0),
      ephemeralPublicKey: isSet(object.ephemeralPublicKey)
        ? bytesFromBase64(object.ephemeralPublicKey)
        : new Uint8Array(0),
      encryptedCouponData: isSet(object.encryptedCouponData)
        ? bytesFromBase64(object.encryptedCouponData)
        : new Uint8Array(0),
    };
  },

  toJSON(message: IssueCouponResponse): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.couponTransactionId !== "") {
      obj.couponTransactionId = message.couponTransactionId;
    }
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      obj.serverKeyMaterial = base64FromBytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      obj.ephemeralPublicKey = base64FromBytes(message.ephemeralPublicKey);
    }
    if (message.encryptedCouponData.length !== 0) {
      obj.encryptedCouponData = base64FromBytes(message.encryptedCouponData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueCouponResponse>, I>>(base?: I): IssueCouponResponse {
    return IssueCouponResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueCouponResponse>, I>>(object: I): IssueCouponResponse {
    const message = createBaseIssueCouponResponse();
    message.did = object.did ?? "";
    message.couponTransactionId = object.couponTransactionId ?? "";
    message.code = object.code ?? 0;
    message.serverKeyMaterial = object.serverKeyMaterial ?? new Uint8Array(0);
    message.ephemeralPublicKey = object.ephemeralPublicKey ?? new Uint8Array(0);
    message.encryptedCouponData = object.encryptedCouponData ?? new Uint8Array(0);
    return message;
  },
};

function createBaseListCouponRequest(): ListCouponRequest {
  return { did: "", couponTransactionIds: [] };
}

export const ListCouponRequest = {
  encode(message: ListCouponRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    for (const v of message.couponTransactionIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCouponRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCouponRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.couponTransactionIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCouponRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      couponTransactionIds: globalThis.Array.isArray(object?.couponTransactionIds)
        ? object.couponTransactionIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ListCouponRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.couponTransactionIds?.length) {
      obj.couponTransactionIds = message.couponTransactionIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCouponRequest>, I>>(base?: I): ListCouponRequest {
    return ListCouponRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCouponRequest>, I>>(object: I): ListCouponRequest {
    const message = createBaseListCouponRequest();
    message.did = object.did ?? "";
    message.couponTransactionIds = object.couponTransactionIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseCouponInfoDetails(): CouponInfoDetails {
  return {
    goodsCode: "",
    pinStatusCode: "",
    goodsName: "",
    sellPriceAmount: "",
    senderTelNo: "",
    cnsmPriceAmount: "",
    sendRstCode: "",
    pinStatusName: "",
    mmsBrandThumImg: "",
    brandName: "",
    sendRstMsg: "",
    correcDtm: "",
    recverTelNo: "",
    expirationAt: "",
    sendBasicCode: "",
    sendStatusCode: "",
  };
}

export const CouponInfoDetails = {
  encode(message: CouponInfoDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.goodsCode !== "") {
      writer.uint32(10).string(message.goodsCode);
    }
    if (message.pinStatusCode !== "") {
      writer.uint32(18).string(message.pinStatusCode);
    }
    if (message.goodsName !== "") {
      writer.uint32(26).string(message.goodsName);
    }
    if (message.sellPriceAmount !== "") {
      writer.uint32(34).string(message.sellPriceAmount);
    }
    if (message.senderTelNo !== "") {
      writer.uint32(42).string(message.senderTelNo);
    }
    if (message.cnsmPriceAmount !== "") {
      writer.uint32(50).string(message.cnsmPriceAmount);
    }
    if (message.sendRstCode !== "") {
      writer.uint32(58).string(message.sendRstCode);
    }
    if (message.pinStatusName !== "") {
      writer.uint32(66).string(message.pinStatusName);
    }
    if (message.mmsBrandThumImg !== "") {
      writer.uint32(74).string(message.mmsBrandThumImg);
    }
    if (message.brandName !== "") {
      writer.uint32(82).string(message.brandName);
    }
    if (message.sendRstMsg !== "") {
      writer.uint32(90).string(message.sendRstMsg);
    }
    if (message.correcDtm !== "") {
      writer.uint32(98).string(message.correcDtm);
    }
    if (message.recverTelNo !== "") {
      writer.uint32(106).string(message.recverTelNo);
    }
    if (message.expirationAt !== "") {
      writer.uint32(114).string(message.expirationAt);
    }
    if (message.sendBasicCode !== "") {
      writer.uint32(122).string(message.sendBasicCode);
    }
    if (message.sendStatusCode !== "") {
      writer.uint32(130).string(message.sendStatusCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CouponInfoDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCouponInfoDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.goodsCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pinStatusCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.goodsName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sellPriceAmount = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.senderTelNo = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.cnsmPriceAmount = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sendRstCode = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.pinStatusName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.mmsBrandThumImg = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.brandName = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.sendRstMsg = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.correcDtm = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.recverTelNo = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.expirationAt = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.sendBasicCode = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.sendStatusCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CouponInfoDetails {
    return {
      goodsCode: isSet(object.goodsCode) ? globalThis.String(object.goodsCode) : "",
      pinStatusCode: isSet(object.pinStatusCode) ? globalThis.String(object.pinStatusCode) : "",
      goodsName: isSet(object.goodsName) ? globalThis.String(object.goodsName) : "",
      sellPriceAmount: isSet(object.sellPriceAmount) ? globalThis.String(object.sellPriceAmount) : "",
      senderTelNo: isSet(object.senderTelNo) ? globalThis.String(object.senderTelNo) : "",
      cnsmPriceAmount: isSet(object.cnsmPriceAmount) ? globalThis.String(object.cnsmPriceAmount) : "",
      sendRstCode: isSet(object.sendRstCode) ? globalThis.String(object.sendRstCode) : "",
      pinStatusName: isSet(object.pinStatusName) ? globalThis.String(object.pinStatusName) : "",
      mmsBrandThumImg: isSet(object.mmsBrandThumImg) ? globalThis.String(object.mmsBrandThumImg) : "",
      brandName: isSet(object.brandName) ? globalThis.String(object.brandName) : "",
      sendRstMsg: isSet(object.sendRstMsg) ? globalThis.String(object.sendRstMsg) : "",
      correcDtm: isSet(object.correcDtm) ? globalThis.String(object.correcDtm) : "",
      recverTelNo: isSet(object.recverTelNo) ? globalThis.String(object.recverTelNo) : "",
      expirationAt: isSet(object.expirationAt) ? globalThis.String(object.expirationAt) : "",
      sendBasicCode: isSet(object.sendBasicCode) ? globalThis.String(object.sendBasicCode) : "",
      sendStatusCode: isSet(object.sendStatusCode) ? globalThis.String(object.sendStatusCode) : "",
    };
  },

  toJSON(message: CouponInfoDetails): unknown {
    const obj: any = {};
    if (message.goodsCode !== "") {
      obj.goodsCode = message.goodsCode;
    }
    if (message.pinStatusCode !== "") {
      obj.pinStatusCode = message.pinStatusCode;
    }
    if (message.goodsName !== "") {
      obj.goodsName = message.goodsName;
    }
    if (message.sellPriceAmount !== "") {
      obj.sellPriceAmount = message.sellPriceAmount;
    }
    if (message.senderTelNo !== "") {
      obj.senderTelNo = message.senderTelNo;
    }
    if (message.cnsmPriceAmount !== "") {
      obj.cnsmPriceAmount = message.cnsmPriceAmount;
    }
    if (message.sendRstCode !== "") {
      obj.sendRstCode = message.sendRstCode;
    }
    if (message.pinStatusName !== "") {
      obj.pinStatusName = message.pinStatusName;
    }
    if (message.mmsBrandThumImg !== "") {
      obj.mmsBrandThumImg = message.mmsBrandThumImg;
    }
    if (message.brandName !== "") {
      obj.brandName = message.brandName;
    }
    if (message.sendRstMsg !== "") {
      obj.sendRstMsg = message.sendRstMsg;
    }
    if (message.correcDtm !== "") {
      obj.correcDtm = message.correcDtm;
    }
    if (message.recverTelNo !== "") {
      obj.recverTelNo = message.recverTelNo;
    }
    if (message.expirationAt !== "") {
      obj.expirationAt = message.expirationAt;
    }
    if (message.sendBasicCode !== "") {
      obj.sendBasicCode = message.sendBasicCode;
    }
    if (message.sendStatusCode !== "") {
      obj.sendStatusCode = message.sendStatusCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CouponInfoDetails>, I>>(base?: I): CouponInfoDetails {
    return CouponInfoDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CouponInfoDetails>, I>>(object: I): CouponInfoDetails {
    const message = createBaseCouponInfoDetails();
    message.goodsCode = object.goodsCode ?? "";
    message.pinStatusCode = object.pinStatusCode ?? "";
    message.goodsName = object.goodsName ?? "";
    message.sellPriceAmount = object.sellPriceAmount ?? "";
    message.senderTelNo = object.senderTelNo ?? "";
    message.cnsmPriceAmount = object.cnsmPriceAmount ?? "";
    message.sendRstCode = object.sendRstCode ?? "";
    message.pinStatusName = object.pinStatusName ?? "";
    message.mmsBrandThumImg = object.mmsBrandThumImg ?? "";
    message.brandName = object.brandName ?? "";
    message.sendRstMsg = object.sendRstMsg ?? "";
    message.correcDtm = object.correcDtm ?? "";
    message.recverTelNo = object.recverTelNo ?? "";
    message.expirationAt = object.expirationAt ?? "";
    message.sendBasicCode = object.sendBasicCode ?? "";
    message.sendStatusCode = object.sendStatusCode ?? "";
    return message;
  },
};

function createBaseListCouponDetailsResponse(): ListCouponDetailsResponse {
  return { code: 0, details: [] };
}

export const ListCouponDetailsResponse = {
  encode(message: ListCouponDetailsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.details) {
      CouponInfoDetails.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCouponDetailsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCouponDetailsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.details.push(CouponInfoDetails.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCouponDetailsResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      details: globalThis.Array.isArray(object?.details)
        ? object.details.map((e: any) => CouponInfoDetails.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListCouponDetailsResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.details?.length) {
      obj.details = message.details.map((e) => CouponInfoDetails.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCouponDetailsResponse>, I>>(base?: I): ListCouponDetailsResponse {
    return ListCouponDetailsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCouponDetailsResponse>, I>>(object: I): ListCouponDetailsResponse {
    const message = createBaseListCouponDetailsResponse();
    message.code = object.code ?? 0;
    message.details = object.details?.map((e) => CouponInfoDetails.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListCouponDetailsRequest(): ListCouponDetailsRequest {
  return { did: "", couponTransactionIds: [] };
}

export const ListCouponDetailsRequest = {
  encode(message: ListCouponDetailsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    for (const v of message.couponTransactionIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCouponDetailsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCouponDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.couponTransactionIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCouponDetailsRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      couponTransactionIds: globalThis.Array.isArray(object?.couponTransactionIds)
        ? object.couponTransactionIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ListCouponDetailsRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.couponTransactionIds?.length) {
      obj.couponTransactionIds = message.couponTransactionIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCouponDetailsRequest>, I>>(base?: I): ListCouponDetailsRequest {
    return ListCouponDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCouponDetailsRequest>, I>>(object: I): ListCouponDetailsRequest {
    const message = createBaseListCouponDetailsRequest();
    message.did = object.did ?? "";
    message.couponTransactionIds = object.couponTransactionIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseCouponInfo(): CouponInfo {
  return {
    did: "",
    encryptedPinCode: "",
    encryptedImageUrl: "",
    encryptedInfo: "",
    couponTransactionId: "",
    orderNo: "",
    price: 0,
    publishedAt: "",
    disposedAt: "",
    status: 0,
    cancelRequested: false,
    clientKeyMaterial: new Uint8Array(0),
    serverKeyMaterial: new Uint8Array(0),
    ephemeralPublicKey: new Uint8Array(0),
    goodsCode: "",
  };
}

export const CouponInfo = {
  encode(message: CouponInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.encryptedPinCode !== "") {
      writer.uint32(18).string(message.encryptedPinCode);
    }
    if (message.encryptedImageUrl !== "") {
      writer.uint32(26).string(message.encryptedImageUrl);
    }
    if (message.encryptedInfo !== "") {
      writer.uint32(34).string(message.encryptedInfo);
    }
    if (message.couponTransactionId !== "") {
      writer.uint32(42).string(message.couponTransactionId);
    }
    if (message.orderNo !== "") {
      writer.uint32(50).string(message.orderNo);
    }
    if (message.price !== 0) {
      writer.uint32(56).uint64(message.price);
    }
    if (message.publishedAt !== "") {
      writer.uint32(82).string(message.publishedAt);
    }
    if (message.disposedAt !== "") {
      writer.uint32(90).string(message.disposedAt);
    }
    if (message.status !== 0) {
      writer.uint32(96).int32(message.status);
    }
    if (message.cancelRequested === true) {
      writer.uint32(104).bool(message.cancelRequested);
    }
    if (message.clientKeyMaterial.length !== 0) {
      writer.uint32(162).bytes(message.clientKeyMaterial);
    }
    if (message.serverKeyMaterial.length !== 0) {
      writer.uint32(170).bytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      writer.uint32(178).bytes(message.ephemeralPublicKey);
    }
    if (message.goodsCode !== "") {
      writer.uint32(186).string(message.goodsCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CouponInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCouponInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.encryptedPinCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.encryptedImageUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.encryptedInfo = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.couponTransactionId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orderNo = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.price = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.publishedAt = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.disposedAt = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.cancelRequested = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.clientKeyMaterial = reader.bytes();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.serverKeyMaterial = reader.bytes();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.ephemeralPublicKey = reader.bytes();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.goodsCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CouponInfo {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      encryptedPinCode: isSet(object.encryptedPinCode) ? globalThis.String(object.encryptedPinCode) : "",
      encryptedImageUrl: isSet(object.encryptedImageUrl) ? globalThis.String(object.encryptedImageUrl) : "",
      encryptedInfo: isSet(object.encryptedInfo) ? globalThis.String(object.encryptedInfo) : "",
      couponTransactionId: isSet(object.couponTransactionId) ? globalThis.String(object.couponTransactionId) : "",
      orderNo: isSet(object.orderNo) ? globalThis.String(object.orderNo) : "",
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
      publishedAt: isSet(object.publishedAt) ? globalThis.String(object.publishedAt) : "",
      disposedAt: isSet(object.disposedAt) ? globalThis.String(object.disposedAt) : "",
      status: isSet(object.status) ? couponStatusFromJSON(object.status) : 0,
      cancelRequested: isSet(object.cancelRequested) ? globalThis.Boolean(object.cancelRequested) : false,
      clientKeyMaterial: isSet(object.clientKeyMaterial)
        ? bytesFromBase64(object.clientKeyMaterial)
        : new Uint8Array(0),
      serverKeyMaterial: isSet(object.serverKeyMaterial)
        ? bytesFromBase64(object.serverKeyMaterial)
        : new Uint8Array(0),
      ephemeralPublicKey: isSet(object.ephemeralPublicKey)
        ? bytesFromBase64(object.ephemeralPublicKey)
        : new Uint8Array(0),
      goodsCode: isSet(object.goodsCode) ? globalThis.String(object.goodsCode) : "",
    };
  },

  toJSON(message: CouponInfo): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.encryptedPinCode !== "") {
      obj.encryptedPinCode = message.encryptedPinCode;
    }
    if (message.encryptedImageUrl !== "") {
      obj.encryptedImageUrl = message.encryptedImageUrl;
    }
    if (message.encryptedInfo !== "") {
      obj.encryptedInfo = message.encryptedInfo;
    }
    if (message.couponTransactionId !== "") {
      obj.couponTransactionId = message.couponTransactionId;
    }
    if (message.orderNo !== "") {
      obj.orderNo = message.orderNo;
    }
    if (message.price !== 0) {
      obj.price = Math.round(message.price);
    }
    if (message.publishedAt !== "") {
      obj.publishedAt = message.publishedAt;
    }
    if (message.disposedAt !== "") {
      obj.disposedAt = message.disposedAt;
    }
    if (message.status !== 0) {
      obj.status = couponStatusToJSON(message.status);
    }
    if (message.cancelRequested === true) {
      obj.cancelRequested = message.cancelRequested;
    }
    if (message.clientKeyMaterial.length !== 0) {
      obj.clientKeyMaterial = base64FromBytes(message.clientKeyMaterial);
    }
    if (message.serverKeyMaterial.length !== 0) {
      obj.serverKeyMaterial = base64FromBytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      obj.ephemeralPublicKey = base64FromBytes(message.ephemeralPublicKey);
    }
    if (message.goodsCode !== "") {
      obj.goodsCode = message.goodsCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CouponInfo>, I>>(base?: I): CouponInfo {
    return CouponInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CouponInfo>, I>>(object: I): CouponInfo {
    const message = createBaseCouponInfo();
    message.did = object.did ?? "";
    message.encryptedPinCode = object.encryptedPinCode ?? "";
    message.encryptedImageUrl = object.encryptedImageUrl ?? "";
    message.encryptedInfo = object.encryptedInfo ?? "";
    message.couponTransactionId = object.couponTransactionId ?? "";
    message.orderNo = object.orderNo ?? "";
    message.price = object.price ?? 0;
    message.publishedAt = object.publishedAt ?? "";
    message.disposedAt = object.disposedAt ?? "";
    message.status = object.status ?? 0;
    message.cancelRequested = object.cancelRequested ?? false;
    message.clientKeyMaterial = object.clientKeyMaterial ?? new Uint8Array(0);
    message.serverKeyMaterial = object.serverKeyMaterial ?? new Uint8Array(0);
    message.ephemeralPublicKey = object.ephemeralPublicKey ?? new Uint8Array(0);
    message.goodsCode = object.goodsCode ?? "";
    return message;
  },
};

function createBaseListCouponResponse(): ListCouponResponse {
  return { code: 0, coupons: [] };
}

export const ListCouponResponse = {
  encode(message: ListCouponResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.coupons) {
      CouponInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCouponResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCouponResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.coupons.push(CouponInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCouponResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      coupons: globalThis.Array.isArray(object?.coupons) ? object.coupons.map((e: any) => CouponInfo.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListCouponResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.coupons?.length) {
      obj.coupons = message.coupons.map((e) => CouponInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCouponResponse>, I>>(base?: I): ListCouponResponse {
    return ListCouponResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCouponResponse>, I>>(object: I): ListCouponResponse {
    const message = createBaseListCouponResponse();
    message.code = object.code ?? 0;
    message.coupons = object.coupons?.map((e) => CouponInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMyd2CouponInfo(): Myd2CouponInfo {
  return {
    couponTransactionId: "",
    goodsCode: "",
    encryptedInfo: new Uint8Array(0),
    issuedAt: "",
    revokedAt: "",
    status: "",
  };
}

export const Myd2CouponInfo = {
  encode(message: Myd2CouponInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.couponTransactionId !== "") {
      writer.uint32(10).string(message.couponTransactionId);
    }
    if (message.goodsCode !== "") {
      writer.uint32(18).string(message.goodsCode);
    }
    if (message.encryptedInfo.length !== 0) {
      writer.uint32(26).bytes(message.encryptedInfo);
    }
    if (message.issuedAt !== "") {
      writer.uint32(34).string(message.issuedAt);
    }
    if (message.revokedAt !== "") {
      writer.uint32(42).string(message.revokedAt);
    }
    if (message.status !== "") {
      writer.uint32(50).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Myd2CouponInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMyd2CouponInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.couponTransactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.goodsCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.encryptedInfo = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.issuedAt = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.revokedAt = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Myd2CouponInfo {
    return {
      couponTransactionId: isSet(object.couponTransactionId) ? globalThis.String(object.couponTransactionId) : "",
      goodsCode: isSet(object.goodsCode) ? globalThis.String(object.goodsCode) : "",
      encryptedInfo: isSet(object.encryptedInfo) ? bytesFromBase64(object.encryptedInfo) : new Uint8Array(0),
      issuedAt: isSet(object.issuedAt) ? globalThis.String(object.issuedAt) : "",
      revokedAt: isSet(object.revokedAt) ? globalThis.String(object.revokedAt) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
    };
  },

  toJSON(message: Myd2CouponInfo): unknown {
    const obj: any = {};
    if (message.couponTransactionId !== "") {
      obj.couponTransactionId = message.couponTransactionId;
    }
    if (message.goodsCode !== "") {
      obj.goodsCode = message.goodsCode;
    }
    if (message.encryptedInfo.length !== 0) {
      obj.encryptedInfo = base64FromBytes(message.encryptedInfo);
    }
    if (message.issuedAt !== "") {
      obj.issuedAt = message.issuedAt;
    }
    if (message.revokedAt !== "") {
      obj.revokedAt = message.revokedAt;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Myd2CouponInfo>, I>>(base?: I): Myd2CouponInfo {
    return Myd2CouponInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Myd2CouponInfo>, I>>(object: I): Myd2CouponInfo {
    const message = createBaseMyd2CouponInfo();
    message.couponTransactionId = object.couponTransactionId ?? "";
    message.goodsCode = object.goodsCode ?? "";
    message.encryptedInfo = object.encryptedInfo ?? new Uint8Array(0);
    message.issuedAt = object.issuedAt ?? "";
    message.revokedAt = object.revokedAt ?? "";
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseListMyd2CouponRequest(): ListMyd2CouponRequest {
  return { did: "", couponTransactionIds: [] };
}

export const ListMyd2CouponRequest = {
  encode(message: ListMyd2CouponRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    for (const v of message.couponTransactionIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMyd2CouponRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMyd2CouponRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.couponTransactionIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMyd2CouponRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      couponTransactionIds: globalThis.Array.isArray(object?.couponTransactionIds)
        ? object.couponTransactionIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ListMyd2CouponRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.couponTransactionIds?.length) {
      obj.couponTransactionIds = message.couponTransactionIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMyd2CouponRequest>, I>>(base?: I): ListMyd2CouponRequest {
    return ListMyd2CouponRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMyd2CouponRequest>, I>>(object: I): ListMyd2CouponRequest {
    const message = createBaseListMyd2CouponRequest();
    message.did = object.did ?? "";
    message.couponTransactionIds = object.couponTransactionIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseListMyd2CouponResponse(): ListMyd2CouponResponse {
  return { code: 0, coupons: [] };
}

export const ListMyd2CouponResponse = {
  encode(message: ListMyd2CouponResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.coupons) {
      Myd2CouponInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMyd2CouponResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMyd2CouponResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.coupons.push(Myd2CouponInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMyd2CouponResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      coupons: globalThis.Array.isArray(object?.coupons)
        ? object.coupons.map((e: any) => Myd2CouponInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListMyd2CouponResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.coupons?.length) {
      obj.coupons = message.coupons.map((e) => Myd2CouponInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMyd2CouponResponse>, I>>(base?: I): ListMyd2CouponResponse {
    return ListMyd2CouponResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMyd2CouponResponse>, I>>(object: I): ListMyd2CouponResponse {
    const message = createBaseListMyd2CouponResponse();
    message.code = object.code ?? 0;
    message.coupons = object.coupons?.map((e) => Myd2CouponInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSelfManagedAddress(): SelfManagedAddress {
  return { address: "", role: 0, createdAt: "", team: "", username: "", isDeleted: false };
}

export const SelfManagedAddress = {
  encode(message: SelfManagedAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.role !== 0) {
      writer.uint32(16).int32(message.role);
    }
    if (message.createdAt !== "") {
      writer.uint32(26).string(message.createdAt);
    }
    if (message.team !== "") {
      writer.uint32(34).string(message.team);
    }
    if (message.username !== "") {
      writer.uint32(42).string(message.username);
    }
    if (message.isDeleted === true) {
      writer.uint32(48).bool(message.isDeleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelfManagedAddress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelfManagedAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.team = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.username = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isDeleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelfManagedAddress {
    return {
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      role: isSet(object.role) ? blockchainRoleFromJSON(object.role) : 0,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      team: isSet(object.team) ? globalThis.String(object.team) : "",
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      isDeleted: isSet(object.isDeleted) ? globalThis.Boolean(object.isDeleted) : false,
    };
  },

  toJSON(message: SelfManagedAddress): unknown {
    const obj: any = {};
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.role !== 0) {
      obj.role = blockchainRoleToJSON(message.role);
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.team !== "") {
      obj.team = message.team;
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.isDeleted === true) {
      obj.isDeleted = message.isDeleted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SelfManagedAddress>, I>>(base?: I): SelfManagedAddress {
    return SelfManagedAddress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SelfManagedAddress>, I>>(object: I): SelfManagedAddress {
    const message = createBaseSelfManagedAddress();
    message.address = object.address ?? "";
    message.role = object.role ?? 0;
    message.createdAt = object.createdAt ?? "";
    message.team = object.team ?? "";
    message.username = object.username ?? "";
    message.isDeleted = object.isDeleted ?? false;
    return message;
  },
};

function createBaseGetAllSelfManagedAddressesResponse(): GetAllSelfManagedAddressesResponse {
  return { selfManagedAddresses: [] };
}

export const GetAllSelfManagedAddressesResponse = {
  encode(message: GetAllSelfManagedAddressesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.selfManagedAddresses) {
      SelfManagedAddress.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllSelfManagedAddressesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllSelfManagedAddressesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selfManagedAddresses.push(SelfManagedAddress.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllSelfManagedAddressesResponse {
    return {
      selfManagedAddresses: globalThis.Array.isArray(object?.selfManagedAddresses)
        ? object.selfManagedAddresses.map((e: any) => SelfManagedAddress.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAllSelfManagedAddressesResponse): unknown {
    const obj: any = {};
    if (message.selfManagedAddresses?.length) {
      obj.selfManagedAddresses = message.selfManagedAddresses.map((e) => SelfManagedAddress.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllSelfManagedAddressesResponse>, I>>(
    base?: I,
  ): GetAllSelfManagedAddressesResponse {
    return GetAllSelfManagedAddressesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllSelfManagedAddressesResponse>, I>>(
    object: I,
  ): GetAllSelfManagedAddressesResponse {
    const message = createBaseGetAllSelfManagedAddressesResponse();
    message.selfManagedAddresses = object.selfManagedAddresses?.map((e) => SelfManagedAddress.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateOrUpdateSelfManagedWalletRequest(): CreateOrUpdateSelfManagedWalletRequest {
  return { wallet: undefined };
}

export const CreateOrUpdateSelfManagedWalletRequest = {
  encode(message: CreateOrUpdateSelfManagedWalletRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wallet !== undefined) {
      SelfManagedAddress.encode(message.wallet, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrUpdateSelfManagedWalletRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateSelfManagedWalletRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wallet = SelfManagedAddress.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateSelfManagedWalletRequest {
    return { wallet: isSet(object.wallet) ? SelfManagedAddress.fromJSON(object.wallet) : undefined };
  },

  toJSON(message: CreateOrUpdateSelfManagedWalletRequest): unknown {
    const obj: any = {};
    if (message.wallet !== undefined) {
      obj.wallet = SelfManagedAddress.toJSON(message.wallet);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrUpdateSelfManagedWalletRequest>, I>>(
    base?: I,
  ): CreateOrUpdateSelfManagedWalletRequest {
    return CreateOrUpdateSelfManagedWalletRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrUpdateSelfManagedWalletRequest>, I>>(
    object: I,
  ): CreateOrUpdateSelfManagedWalletRequest {
    const message = createBaseCreateOrUpdateSelfManagedWalletRequest();
    message.wallet = (object.wallet !== undefined && object.wallet !== null)
      ? SelfManagedAddress.fromPartial(object.wallet)
      : undefined;
    return message;
  },
};

function createBaseCreateOrUpdateSelfManagedWalletResponse(): CreateOrUpdateSelfManagedWalletResponse {
  return { code: 0 };
}

export const CreateOrUpdateSelfManagedWalletResponse = {
  encode(message: CreateOrUpdateSelfManagedWalletResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrUpdateSelfManagedWalletResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateSelfManagedWalletResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateSelfManagedWalletResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: CreateOrUpdateSelfManagedWalletResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrUpdateSelfManagedWalletResponse>, I>>(
    base?: I,
  ): CreateOrUpdateSelfManagedWalletResponse {
    return CreateOrUpdateSelfManagedWalletResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrUpdateSelfManagedWalletResponse>, I>>(
    object: I,
  ): CreateOrUpdateSelfManagedWalletResponse {
    const message = createBaseCreateOrUpdateSelfManagedWalletResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseCreateWalletKeyPairRequest(): CreateWalletKeyPairRequest {
  return { did: "" };
}

export const CreateWalletKeyPairRequest = {
  encode(message: CreateWalletKeyPairRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateWalletKeyPairRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWalletKeyPairRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateWalletKeyPairRequest {
    return { did: isSet(object.did) ? globalThis.String(object.did) : "" };
  },

  toJSON(message: CreateWalletKeyPairRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateWalletKeyPairRequest>, I>>(base?: I): CreateWalletKeyPairRequest {
    return CreateWalletKeyPairRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateWalletKeyPairRequest>, I>>(object: I): CreateWalletKeyPairRequest {
    const message = createBaseCreateWalletKeyPairRequest();
    message.did = object.did ?? "";
    return message;
  },
};

function createBaseCreateWalletKeyPairResponse(): CreateWalletKeyPairResponse {
  return { isCreated: false, address: "" };
}

export const CreateWalletKeyPairResponse = {
  encode(message: CreateWalletKeyPairResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isCreated === true) {
      writer.uint32(8).bool(message.isCreated);
    }
    if (message.address !== "") {
      writer.uint32(18).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateWalletKeyPairResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWalletKeyPairResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isCreated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateWalletKeyPairResponse {
    return {
      isCreated: isSet(object.isCreated) ? globalThis.Boolean(object.isCreated) : false,
      address: isSet(object.address) ? globalThis.String(object.address) : "",
    };
  },

  toJSON(message: CreateWalletKeyPairResponse): unknown {
    const obj: any = {};
    if (message.isCreated === true) {
      obj.isCreated = message.isCreated;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateWalletKeyPairResponse>, I>>(base?: I): CreateWalletKeyPairResponse {
    return CreateWalletKeyPairResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateWalletKeyPairResponse>, I>>(object: I): CreateWalletKeyPairResponse {
    const message = createBaseCreateWalletKeyPairResponse();
    message.isCreated = object.isCreated ?? false;
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetCompaniesResponse(): GetCompaniesResponse {
  return { companies: [] };
}

export const GetCompaniesResponse = {
  encode(message: GetCompaniesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.companies) {
      Companies.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCompaniesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCompaniesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.companies.push(Companies.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCompaniesResponse {
    return {
      companies: globalThis.Array.isArray(object?.companies)
        ? object.companies.map((e: any) => Companies.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetCompaniesResponse): unknown {
    const obj: any = {};
    if (message.companies?.length) {
      obj.companies = message.companies.map((e) => Companies.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCompaniesResponse>, I>>(base?: I): GetCompaniesResponse {
    return GetCompaniesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCompaniesResponse>, I>>(object: I): GetCompaniesResponse {
    const message = createBaseGetCompaniesResponse();
    message.companies = object.companies?.map((e) => Companies.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCompanies(): Companies {
  return { did: "", name: "", address: "" };
}

export const Companies = {
  encode(message: Companies, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.address !== "") {
      writer.uint32(26).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Companies {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompanies();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Companies {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      address: isSet(object.address) ? globalThis.String(object.address) : "",
    };
  },

  toJSON(message: Companies): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Companies>, I>>(base?: I): Companies {
    return Companies.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Companies>, I>>(object: I): Companies {
    const message = createBaseCompanies();
    message.did = object.did ?? "";
    message.name = object.name ?? "";
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseManagedAddress(): ManagedAddress {
  return { address: "", role: 0, publicKey: "", hostname: "", createdAt: "" };
}

export const ManagedAddress = {
  encode(message: ManagedAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.role !== 0) {
      writer.uint32(16).int32(message.role);
    }
    if (message.publicKey !== "") {
      writer.uint32(26).string(message.publicKey);
    }
    if (message.hostname !== "") {
      writer.uint32(34).string(message.hostname);
    }
    if (message.createdAt !== "") {
      writer.uint32(42).string(message.createdAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagedAddress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagedAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.publicKey = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.hostname = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagedAddress {
    return {
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      role: isSet(object.role) ? blockchainRoleFromJSON(object.role) : 0,
      publicKey: isSet(object.publicKey) ? globalThis.String(object.publicKey) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
    };
  },

  toJSON(message: ManagedAddress): unknown {
    const obj: any = {};
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.role !== 0) {
      obj.role = blockchainRoleToJSON(message.role);
    }
    if (message.publicKey !== "") {
      obj.publicKey = message.publicKey;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagedAddress>, I>>(base?: I): ManagedAddress {
    return ManagedAddress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagedAddress>, I>>(object: I): ManagedAddress {
    const message = createBaseManagedAddress();
    message.address = object.address ?? "";
    message.role = object.role ?? 0;
    message.publicKey = object.publicKey ?? "";
    message.hostname = object.hostname ?? "";
    message.createdAt = object.createdAt ?? "";
    return message;
  },
};

function createBaseGetAllManagedAddressesResponse(): GetAllManagedAddressesResponse {
  return { managedAddresses: [] };
}

export const GetAllManagedAddressesResponse = {
  encode(message: GetAllManagedAddressesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.managedAddresses) {
      ManagedAddress.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllManagedAddressesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllManagedAddressesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.managedAddresses.push(ManagedAddress.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllManagedAddressesResponse {
    return {
      managedAddresses: globalThis.Array.isArray(object?.managedAddresses)
        ? object.managedAddresses.map((e: any) => ManagedAddress.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAllManagedAddressesResponse): unknown {
    const obj: any = {};
    if (message.managedAddresses?.length) {
      obj.managedAddresses = message.managedAddresses.map((e) => ManagedAddress.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllManagedAddressesResponse>, I>>(base?: I): GetAllManagedAddressesResponse {
    return GetAllManagedAddressesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllManagedAddressesResponse>, I>>(
    object: I,
  ): GetAllManagedAddressesResponse {
    const message = createBaseGetAllManagedAddressesResponse();
    message.managedAddresses = object.managedAddresses?.map((e) => ManagedAddress.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateOrUpdateManagedWalletRequest(): CreateOrUpdateManagedWalletRequest {
  return { wallet: undefined };
}

export const CreateOrUpdateManagedWalletRequest = {
  encode(message: CreateOrUpdateManagedWalletRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wallet !== undefined) {
      ManagedAddress.encode(message.wallet, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrUpdateManagedWalletRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateManagedWalletRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wallet = ManagedAddress.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateManagedWalletRequest {
    return { wallet: isSet(object.wallet) ? ManagedAddress.fromJSON(object.wallet) : undefined };
  },

  toJSON(message: CreateOrUpdateManagedWalletRequest): unknown {
    const obj: any = {};
    if (message.wallet !== undefined) {
      obj.wallet = ManagedAddress.toJSON(message.wallet);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrUpdateManagedWalletRequest>, I>>(
    base?: I,
  ): CreateOrUpdateManagedWalletRequest {
    return CreateOrUpdateManagedWalletRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrUpdateManagedWalletRequest>, I>>(
    object: I,
  ): CreateOrUpdateManagedWalletRequest {
    const message = createBaseCreateOrUpdateManagedWalletRequest();
    message.wallet = (object.wallet !== undefined && object.wallet !== null)
      ? ManagedAddress.fromPartial(object.wallet)
      : undefined;
    return message;
  },
};

function createBaseCreateOrUpdateManagedWalletResponse(): CreateOrUpdateManagedWalletResponse {
  return { code: 0 };
}

export const CreateOrUpdateManagedWalletResponse = {
  encode(message: CreateOrUpdateManagedWalletResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrUpdateManagedWalletResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateManagedWalletResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateManagedWalletResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: CreateOrUpdateManagedWalletResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrUpdateManagedWalletResponse>, I>>(
    base?: I,
  ): CreateOrUpdateManagedWalletResponse {
    return CreateOrUpdateManagedWalletResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrUpdateManagedWalletResponse>, I>>(
    object: I,
  ): CreateOrUpdateManagedWalletResponse {
    const message = createBaseCreateOrUpdateManagedWalletResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseDeclareRewardTransactionContentRequest(): DeclareRewardTransactionContentRequest {
  return { content: undefined, blockchainRequestId: new Uint8Array(0) };
}

export const DeclareRewardTransactionContentRequest = {
  encode(message: DeclareRewardTransactionContentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.content !== undefined) {
      RewardTransactionContent.encode(message.content, writer.uint32(10).fork()).ldelim();
    }
    if (message.blockchainRequestId.length !== 0) {
      writer.uint32(18).bytes(message.blockchainRequestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeclareRewardTransactionContentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeclareRewardTransactionContentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.content = RewardTransactionContent.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.blockchainRequestId = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeclareRewardTransactionContentRequest {
    return {
      content: isSet(object.content) ? RewardTransactionContent.fromJSON(object.content) : undefined,
      blockchainRequestId: isSet(object.blockchainRequestId)
        ? bytesFromBase64(object.blockchainRequestId)
        : new Uint8Array(0),
    };
  },

  toJSON(message: DeclareRewardTransactionContentRequest): unknown {
    const obj: any = {};
    if (message.content !== undefined) {
      obj.content = RewardTransactionContent.toJSON(message.content);
    }
    if (message.blockchainRequestId.length !== 0) {
      obj.blockchainRequestId = base64FromBytes(message.blockchainRequestId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeclareRewardTransactionContentRequest>, I>>(
    base?: I,
  ): DeclareRewardTransactionContentRequest {
    return DeclareRewardTransactionContentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeclareRewardTransactionContentRequest>, I>>(
    object: I,
  ): DeclareRewardTransactionContentRequest {
    const message = createBaseDeclareRewardTransactionContentRequest();
    message.content = (object.content !== undefined && object.content !== null)
      ? RewardTransactionContent.fromPartial(object.content)
      : undefined;
    message.blockchainRequestId = object.blockchainRequestId ?? new Uint8Array(0);
    return message;
  },
};

function createBaseDeclareRewardTransactionContentResponse(): DeclareRewardTransactionContentResponse {
  return { code: 0 };
}

export const DeclareRewardTransactionContentResponse = {
  encode(message: DeclareRewardTransactionContentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeclareRewardTransactionContentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeclareRewardTransactionContentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeclareRewardTransactionContentResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: DeclareRewardTransactionContentResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeclareRewardTransactionContentResponse>, I>>(
    base?: I,
  ): DeclareRewardTransactionContentResponse {
    return DeclareRewardTransactionContentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeclareRewardTransactionContentResponse>, I>>(
    object: I,
  ): DeclareRewardTransactionContentResponse {
    const message = createBaseDeclareRewardTransactionContentResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseCreateSpecialProposalRequest(): CreateSpecialProposalRequest {
  return { proposal: undefined };
}

export const CreateSpecialProposalRequest = {
  encode(message: CreateSpecialProposalRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposal !== undefined) {
      ProposalStatic.encode(message.proposal, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSpecialProposalRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSpecialProposalRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposal = ProposalStatic.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSpecialProposalRequest {
    return { proposal: isSet(object.proposal) ? ProposalStatic.fromJSON(object.proposal) : undefined };
  },

  toJSON(message: CreateSpecialProposalRequest): unknown {
    const obj: any = {};
    if (message.proposal !== undefined) {
      obj.proposal = ProposalStatic.toJSON(message.proposal);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSpecialProposalRequest>, I>>(base?: I): CreateSpecialProposalRequest {
    return CreateSpecialProposalRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSpecialProposalRequest>, I>>(object: I): CreateSpecialProposalRequest {
    const message = createBaseCreateSpecialProposalRequest();
    message.proposal = (object.proposal !== undefined && object.proposal !== null)
      ? ProposalStatic.fromPartial(object.proposal)
      : undefined;
    return message;
  },
};

function createBaseCreateSpecialProposalResponse(): CreateSpecialProposalResponse {
  return { code: 0, proposalId: "" };
}

export const CreateSpecialProposalResponse = {
  encode(message: CreateSpecialProposalResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.proposalId !== "") {
      writer.uint32(18).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSpecialProposalResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSpecialProposalResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSpecialProposalResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
    };
  },

  toJSON(message: CreateSpecialProposalResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSpecialProposalResponse>, I>>(base?: I): CreateSpecialProposalResponse {
    return CreateSpecialProposalResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSpecialProposalResponse>, I>>(
    object: I,
  ): CreateSpecialProposalResponse {
    const message = createBaseCreateSpecialProposalResponse();
    message.code = object.code ?? 0;
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseInviteFriendHostRequest(): InviteFriendHostRequest {
  return { did: "", mid: "" };
}

export const InviteFriendHostRequest = {
  encode(message: InviteFriendHostRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteFriendHostRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteFriendHostRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteFriendHostRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      mid: isSet(object.mid) ? globalThis.String(object.mid) : "",
    };
  },

  toJSON(message: InviteFriendHostRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteFriendHostRequest>, I>>(base?: I): InviteFriendHostRequest {
    return InviteFriendHostRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InviteFriendHostRequest>, I>>(object: I): InviteFriendHostRequest {
    const message = createBaseInviteFriendHostRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    return message;
  },
};

function createBaseInviteFriendGuestRequest(): InviteFriendGuestRequest {
  return { did: "", mid: "", invitationCode: "", proposalId: "" };
}

export const InviteFriendGuestRequest = {
  encode(message: InviteFriendGuestRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    if (message.invitationCode !== "") {
      writer.uint32(26).string(message.invitationCode);
    }
    if (message.proposalId !== "") {
      writer.uint32(34).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteFriendGuestRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteFriendGuestRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.invitationCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteFriendGuestRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      mid: isSet(object.mid) ? globalThis.String(object.mid) : "",
      invitationCode: isSet(object.invitationCode) ? globalThis.String(object.invitationCode) : "",
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
    };
  },

  toJSON(message: InviteFriendGuestRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    if (message.invitationCode !== "") {
      obj.invitationCode = message.invitationCode;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteFriendGuestRequest>, I>>(base?: I): InviteFriendGuestRequest {
    return InviteFriendGuestRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InviteFriendGuestRequest>, I>>(object: I): InviteFriendGuestRequest {
    const message = createBaseInviteFriendGuestRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    message.invitationCode = object.invitationCode ?? "";
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseInviteFriendHostResponse(): InviteFriendHostResponse {
  return { statusCode: 0, message: undefined, invitationCode: undefined, usedCount: undefined };
}

export const InviteFriendHostResponse = {
  encode(message: InviteFriendHostResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(24).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(34).string(message.message);
    }
    if (message.invitationCode !== undefined) {
      writer.uint32(10).string(message.invitationCode);
    }
    if (message.usedCount !== undefined) {
      writer.uint32(16).uint32(message.usedCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteFriendHostResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteFriendHostResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 24) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invitationCode = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.usedCount = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteFriendHostResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
      invitationCode: isSet(object.invitationCode) ? globalThis.String(object.invitationCode) : undefined,
      usedCount: isSet(object.usedCount) ? globalThis.Number(object.usedCount) : undefined,
    };
  },

  toJSON(message: InviteFriendHostResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.invitationCode !== undefined) {
      obj.invitationCode = message.invitationCode;
    }
    if (message.usedCount !== undefined) {
      obj.usedCount = Math.round(message.usedCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteFriendHostResponse>, I>>(base?: I): InviteFriendHostResponse {
    return InviteFriendHostResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InviteFriendHostResponse>, I>>(object: I): InviteFriendHostResponse {
    const message = createBaseInviteFriendHostResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    message.invitationCode = object.invitationCode ?? undefined;
    message.usedCount = object.usedCount ?? undefined;
    return message;
  },
};

function createBaseInviteFriendGuestResponse(): InviteFriendGuestResponse {
  return {
    statusCode: 0,
    message: undefined,
    date: undefined,
    dueTo: undefined,
    tokenId: undefined,
    amount: undefined,
    invitationCode: undefined,
  };
}

export const InviteFriendGuestResponse = {
  encode(message: InviteFriendGuestResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(48).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(58).string(message.message);
    }
    if (message.date !== undefined) {
      writer.uint32(10).string(message.date);
    }
    if (message.dueTo !== undefined) {
      writer.uint32(18).string(message.dueTo);
    }
    if (message.tokenId !== undefined) {
      writer.uint32(26).string(message.tokenId);
    }
    if (message.amount !== undefined) {
      writer.uint32(32).uint32(message.amount);
    }
    if (message.invitationCode !== undefined) {
      writer.uint32(42).string(message.invitationCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteFriendGuestResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteFriendGuestResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          if (tag !== 48) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.message = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dueTo = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.amount = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.invitationCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteFriendGuestResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
      date: isSet(object.date) ? globalThis.String(object.date) : undefined,
      dueTo: isSet(object.dueTo) ? globalThis.String(object.dueTo) : undefined,
      tokenId: isSet(object.tokenId) ? globalThis.String(object.tokenId) : undefined,
      amount: isSet(object.amount) ? globalThis.Number(object.amount) : undefined,
      invitationCode: isSet(object.invitationCode) ? globalThis.String(object.invitationCode) : undefined,
    };
  },

  toJSON(message: InviteFriendGuestResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.date !== undefined) {
      obj.date = message.date;
    }
    if (message.dueTo !== undefined) {
      obj.dueTo = message.dueTo;
    }
    if (message.tokenId !== undefined) {
      obj.tokenId = message.tokenId;
    }
    if (message.amount !== undefined) {
      obj.amount = Math.round(message.amount);
    }
    if (message.invitationCode !== undefined) {
      obj.invitationCode = message.invitationCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteFriendGuestResponse>, I>>(base?: I): InviteFriendGuestResponse {
    return InviteFriendGuestResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InviteFriendGuestResponse>, I>>(object: I): InviteFriendGuestResponse {
    const message = createBaseInviteFriendGuestResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    message.date = object.date ?? undefined;
    message.dueTo = object.dueTo ?? undefined;
    message.tokenId = object.tokenId ?? undefined;
    message.amount = object.amount ?? undefined;
    message.invitationCode = object.invitationCode ?? undefined;
    return message;
  },
};

function createBaseAttendanceRollCallRequest(): AttendanceRollCallRequest {
  return { did: "", mid: "", proposalId: "" };
}

export const AttendanceRollCallRequest = {
  encode(message: AttendanceRollCallRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    if (message.proposalId !== "") {
      writer.uint32(26).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttendanceRollCallRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttendanceRollCallRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttendanceRollCallRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      mid: isSet(object.mid) ? globalThis.String(object.mid) : "",
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
    };
  },

  toJSON(message: AttendanceRollCallRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttendanceRollCallRequest>, I>>(base?: I): AttendanceRollCallRequest {
    return AttendanceRollCallRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttendanceRollCallRequest>, I>>(object: I): AttendanceRollCallRequest {
    const message = createBaseAttendanceRollCallRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseAttendanceSubmitRequest(): AttendanceSubmitRequest {
  return { did: "", mid: "", proposalId: "" };
}

export const AttendanceSubmitRequest = {
  encode(message: AttendanceSubmitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    if (message.proposalId !== "") {
      writer.uint32(26).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttendanceSubmitRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttendanceSubmitRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttendanceSubmitRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      mid: isSet(object.mid) ? globalThis.String(object.mid) : "",
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
    };
  },

  toJSON(message: AttendanceSubmitRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttendanceSubmitRequest>, I>>(base?: I): AttendanceSubmitRequest {
    return AttendanceSubmitRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttendanceSubmitRequest>, I>>(object: I): AttendanceSubmitRequest {
    const message = createBaseAttendanceSubmitRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseAttendanceRollCallResponse(): AttendanceRollCallResponse {
  return { statusCode: 0, message: undefined, dayofweeks: [], complete: undefined };
}

export const AttendanceRollCallResponse = {
  encode(message: AttendanceRollCallResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    writer.uint32(26).fork();
    for (const v of message.dayofweeks) {
      writer.bool(v);
    }
    writer.ldelim();
    if (message.complete !== undefined) {
      writer.uint32(32).bool(message.complete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttendanceRollCallResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttendanceRollCallResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.dayofweeks.push(reader.bool());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.dayofweeks.push(reader.bool());
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.complete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttendanceRollCallResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
      dayofweeks: globalThis.Array.isArray(object?.dayofweeks)
        ? object.dayofweeks.map((e: any) => globalThis.Boolean(e))
        : [],
      complete: isSet(object.complete) ? globalThis.Boolean(object.complete) : undefined,
    };
  },

  toJSON(message: AttendanceRollCallResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.dayofweeks?.length) {
      obj.dayofweeks = message.dayofweeks;
    }
    if (message.complete !== undefined) {
      obj.complete = message.complete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttendanceRollCallResponse>, I>>(base?: I): AttendanceRollCallResponse {
    return AttendanceRollCallResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttendanceRollCallResponse>, I>>(object: I): AttendanceRollCallResponse {
    const message = createBaseAttendanceRollCallResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    message.dayofweeks = object.dayofweeks?.map((e) => e) || [];
    message.complete = object.complete ?? undefined;
    return message;
  },
};

function createBaseAttendanceSubmitResponse(): AttendanceSubmitResponse {
  return { statusCode: 0, message: undefined, reward: undefined };
}

export const AttendanceSubmitResponse = {
  encode(message: AttendanceSubmitResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    if (message.reward !== undefined) {
      writer.uint32(24).uint32(message.reward);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttendanceSubmitResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttendanceSubmitResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.reward = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttendanceSubmitResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
      reward: isSet(object.reward) ? globalThis.Number(object.reward) : undefined,
    };
  },

  toJSON(message: AttendanceSubmitResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.reward !== undefined) {
      obj.reward = Math.round(message.reward);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttendanceSubmitResponse>, I>>(base?: I): AttendanceSubmitResponse {
    return AttendanceSubmitResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttendanceSubmitResponse>, I>>(object: I): AttendanceSubmitResponse {
    const message = createBaseAttendanceSubmitResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    message.reward = object.reward ?? undefined;
    return message;
  },
};

function createBaseChristmasSubmitRequest(): ChristmasSubmitRequest {
  return { did: "", mid: "" };
}

export const ChristmasSubmitRequest = {
  encode(message: ChristmasSubmitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChristmasSubmitRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChristmasSubmitRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChristmasSubmitRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      mid: isSet(object.mid) ? globalThis.String(object.mid) : "",
    };
  },

  toJSON(message: ChristmasSubmitRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChristmasSubmitRequest>, I>>(base?: I): ChristmasSubmitRequest {
    return ChristmasSubmitRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChristmasSubmitRequest>, I>>(object: I): ChristmasSubmitRequest {
    const message = createBaseChristmasSubmitRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    return message;
  },
};

function createBaseChristmasSubmitResponse(): ChristmasSubmitResponse {
  return { statusCode: 0, message: undefined };
}

export const ChristmasSubmitResponse = {
  encode(message: ChristmasSubmitResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChristmasSubmitResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChristmasSubmitResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChristmasSubmitResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
    };
  },

  toJSON(message: ChristmasSubmitResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChristmasSubmitResponse>, I>>(base?: I): ChristmasSubmitResponse {
    return ChristmasSubmitResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChristmasSubmitResponse>, I>>(object: I): ChristmasSubmitResponse {
    const message = createBaseChristmasSubmitResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    return message;
  },
};

function createBaseChristmasIsParticipationRequest(): ChristmasIsParticipationRequest {
  return { did: "", mid: "" };
}

export const ChristmasIsParticipationRequest = {
  encode(message: ChristmasIsParticipationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChristmasIsParticipationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChristmasIsParticipationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChristmasIsParticipationRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      mid: isSet(object.mid) ? globalThis.String(object.mid) : "",
    };
  },

  toJSON(message: ChristmasIsParticipationRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChristmasIsParticipationRequest>, I>>(base?: I): ChristmasIsParticipationRequest {
    return ChristmasIsParticipationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChristmasIsParticipationRequest>, I>>(
    object: I,
  ): ChristmasIsParticipationRequest {
    const message = createBaseChristmasIsParticipationRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    return message;
  },
};

function createBaseChristmasIsParticipationResponse(): ChristmasIsParticipationResponse {
  return { statusCode: 0, message: undefined, isParticipation: false };
}

export const ChristmasIsParticipationResponse = {
  encode(message: ChristmasIsParticipationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    if (message.isParticipation === true) {
      writer.uint32(24).bool(message.isParticipation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChristmasIsParticipationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChristmasIsParticipationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isParticipation = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChristmasIsParticipationResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
      isParticipation: isSet(object.isParticipation) ? globalThis.Boolean(object.isParticipation) : false,
    };
  },

  toJSON(message: ChristmasIsParticipationResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.isParticipation === true) {
      obj.isParticipation = message.isParticipation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChristmasIsParticipationResponse>, I>>(
    base?: I,
  ): ChristmasIsParticipationResponse {
    return ChristmasIsParticipationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChristmasIsParticipationResponse>, I>>(
    object: I,
  ): ChristmasIsParticipationResponse {
    const message = createBaseChristmasIsParticipationResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    message.isParticipation = object.isParticipation ?? false;
    return message;
  },
};

function createBaseChristmasParticipationRateRequest(): ChristmasParticipationRateRequest {
  return { did: "", mid: "" };
}

export const ChristmasParticipationRateRequest = {
  encode(message: ChristmasParticipationRateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChristmasParticipationRateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChristmasParticipationRateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChristmasParticipationRateRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      mid: isSet(object.mid) ? globalThis.String(object.mid) : "",
    };
  },

  toJSON(message: ChristmasParticipationRateRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChristmasParticipationRateRequest>, I>>(
    base?: I,
  ): ChristmasParticipationRateRequest {
    return ChristmasParticipationRateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChristmasParticipationRateRequest>, I>>(
    object: I,
  ): ChristmasParticipationRateRequest {
    const message = createBaseChristmasParticipationRateRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    return message;
  },
};

function createBaseChristmasParticipationRateResponse(): ChristmasParticipationRateResponse {
  return { statusCode: 0, message: undefined, participationRate: 0, totalparticipations: 0 };
}

export const ChristmasParticipationRateResponse = {
  encode(message: ChristmasParticipationRateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    if (message.participationRate !== 0) {
      writer.uint32(24).uint32(message.participationRate);
    }
    if (message.totalparticipations !== 0) {
      writer.uint32(32).uint32(message.totalparticipations);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChristmasParticipationRateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChristmasParticipationRateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.participationRate = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.totalparticipations = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChristmasParticipationRateResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
      participationRate: isSet(object.participationRate) ? globalThis.Number(object.participationRate) : 0,
      totalparticipations: isSet(object.totalparticipations) ? globalThis.Number(object.totalparticipations) : 0,
    };
  },

  toJSON(message: ChristmasParticipationRateResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.participationRate !== 0) {
      obj.participationRate = Math.round(message.participationRate);
    }
    if (message.totalparticipations !== 0) {
      obj.totalparticipations = Math.round(message.totalparticipations);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChristmasParticipationRateResponse>, I>>(
    base?: I,
  ): ChristmasParticipationRateResponse {
    return ChristmasParticipationRateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChristmasParticipationRateResponse>, I>>(
    object: I,
  ): ChristmasParticipationRateResponse {
    const message = createBaseChristmasParticipationRateResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    message.participationRate = object.participationRate ?? 0;
    message.totalparticipations = object.totalparticipations ?? 0;
    return message;
  },
};

function createBaseLikeGoodsResponse(): LikeGoodsResponse {
  return { code: 0 };
}

export const LikeGoodsResponse = {
  encode(message: LikeGoodsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LikeGoodsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLikeGoodsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LikeGoodsResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: LikeGoodsResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LikeGoodsResponse>, I>>(base?: I): LikeGoodsResponse {
    return LikeGoodsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LikeGoodsResponse>, I>>(object: I): LikeGoodsResponse {
    const message = createBaseLikeGoodsResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseLikeGoodsRequest(): LikeGoodsRequest {
  return { did: "", goodsCodes: [] };
}

export const LikeGoodsRequest = {
  encode(message: LikeGoodsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    for (const v of message.goodsCodes) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LikeGoodsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLikeGoodsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.goodsCodes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LikeGoodsRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      goodsCodes: globalThis.Array.isArray(object?.goodsCodes)
        ? object.goodsCodes.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: LikeGoodsRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.goodsCodes?.length) {
      obj.goodsCodes = message.goodsCodes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LikeGoodsRequest>, I>>(base?: I): LikeGoodsRequest {
    return LikeGoodsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LikeGoodsRequest>, I>>(object: I): LikeGoodsRequest {
    const message = createBaseLikeGoodsRequest();
    message.did = object.did ?? "";
    message.goodsCodes = object.goodsCodes?.map((e) => e) || [];
    return message;
  },
};

function createBaseListGoodsLikeResponse(): ListGoodsLikeResponse {
  return { code: 0, goodsCodes: [] };
}

export const ListGoodsLikeResponse = {
  encode(message: ListGoodsLikeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.goodsCodes) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListGoodsLikeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListGoodsLikeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.goodsCodes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListGoodsLikeResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      goodsCodes: globalThis.Array.isArray(object?.goodsCodes)
        ? object.goodsCodes.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ListGoodsLikeResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.goodsCodes?.length) {
      obj.goodsCodes = message.goodsCodes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListGoodsLikeResponse>, I>>(base?: I): ListGoodsLikeResponse {
    return ListGoodsLikeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListGoodsLikeResponse>, I>>(object: I): ListGoodsLikeResponse {
    const message = createBaseListGoodsLikeResponse();
    message.code = object.code ?? 0;
    message.goodsCodes = object.goodsCodes?.map((e) => e) || [];
    return message;
  },
};

function createBaseListGoodsLikeRequest(): ListGoodsLikeRequest {
  return { did: "" };
}

export const ListGoodsLikeRequest = {
  encode(message: ListGoodsLikeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListGoodsLikeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListGoodsLikeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListGoodsLikeRequest {
    return { did: isSet(object.did) ? globalThis.String(object.did) : "" };
  },

  toJSON(message: ListGoodsLikeRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListGoodsLikeRequest>, I>>(base?: I): ListGoodsLikeRequest {
    return ListGoodsLikeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListGoodsLikeRequest>, I>>(object: I): ListGoodsLikeRequest {
    const message = createBaseListGoodsLikeRequest();
    message.did = object.did ?? "";
    return message;
  },
};

function createBaseRegisterDidFilteringRequest(): RegisterDidFilteringRequest {
  return { proposalId: "", mode: 0, didFilter: [], proposalFilter: [] };
}

export const RegisterDidFilteringRequest = {
  encode(message: RegisterDidFilteringRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId !== "") {
      writer.uint32(10).string(message.proposalId);
    }
    if (message.mode !== 0) {
      writer.uint32(16).int32(message.mode);
    }
    for (const v of message.didFilter) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.proposalFilter) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterDidFilteringRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterDidFilteringRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.mode = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.didFilter.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposalFilter.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterDidFilteringRequest {
    return {
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
      mode: isSet(object.mode) ? didFilteringModeFromJSON(object.mode) : 0,
      didFilter: globalThis.Array.isArray(object?.didFilter)
        ? object.didFilter.map((e: any) => globalThis.String(e))
        : [],
      proposalFilter: globalThis.Array.isArray(object?.proposalFilter)
        ? object.proposalFilter.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: RegisterDidFilteringRequest): unknown {
    const obj: any = {};
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.mode !== 0) {
      obj.mode = didFilteringModeToJSON(message.mode);
    }
    if (message.didFilter?.length) {
      obj.didFilter = message.didFilter;
    }
    if (message.proposalFilter?.length) {
      obj.proposalFilter = message.proposalFilter;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterDidFilteringRequest>, I>>(base?: I): RegisterDidFilteringRequest {
    return RegisterDidFilteringRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterDidFilteringRequest>, I>>(object: I): RegisterDidFilteringRequest {
    const message = createBaseRegisterDidFilteringRequest();
    message.proposalId = object.proposalId ?? "";
    message.mode = object.mode ?? 0;
    message.didFilter = object.didFilter?.map((e) => e) || [];
    message.proposalFilter = object.proposalFilter?.map((e) => e) || [];
    return message;
  },
};

function createBaseRegisterDidFilteringResponse(): RegisterDidFilteringResponse {
  return { statusCode: 0, filteringId: 0 };
}

export const RegisterDidFilteringResponse = {
  encode(message: RegisterDidFilteringResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.filteringId !== 0) {
      writer.uint32(16).uint64(message.filteringId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterDidFilteringResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterDidFilteringResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.filteringId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterDidFilteringResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      filteringId: isSet(object.filteringId) ? globalThis.Number(object.filteringId) : 0,
    };
  },

  toJSON(message: RegisterDidFilteringResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.filteringId !== 0) {
      obj.filteringId = Math.round(message.filteringId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterDidFilteringResponse>, I>>(base?: I): RegisterDidFilteringResponse {
    return RegisterDidFilteringResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterDidFilteringResponse>, I>>(object: I): RegisterDidFilteringResponse {
    const message = createBaseRegisterDidFilteringResponse();
    message.statusCode = object.statusCode ?? 0;
    message.filteringId = object.filteringId ?? 0;
    return message;
  },
};

function createBaseSimpleUserInfo(): SimpleUserInfo {
  return { did: "", userType: 0, besuAddress: undefined };
}

export const SimpleUserInfo = {
  encode(message: SimpleUserInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.userType !== 0) {
      writer.uint32(16).int32(message.userType);
    }
    if (message.besuAddress !== undefined) {
      writer.uint32(26).string(message.besuAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleUserInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SimpleUserInfo {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      userType: isSet(object.userType) ? simpleUserTypeFromJSON(object.userType) : 0,
      besuAddress: isSet(object.besuAddress) ? globalThis.String(object.besuAddress) : undefined,
    };
  },

  toJSON(message: SimpleUserInfo): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.userType !== 0) {
      obj.userType = simpleUserTypeToJSON(message.userType);
    }
    if (message.besuAddress !== undefined) {
      obj.besuAddress = message.besuAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SimpleUserInfo>, I>>(base?: I): SimpleUserInfo {
    return SimpleUserInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SimpleUserInfo>, I>>(object: I): SimpleUserInfo {
    const message = createBaseSimpleUserInfo();
    message.did = object.did ?? "";
    message.userType = object.userType ?? 0;
    message.besuAddress = object.besuAddress ?? undefined;
    return message;
  },
};

function createBaseLookupUsersRequest(): LookupUsersRequest {
  return { dids: [] };
}

export const LookupUsersRequest = {
  encode(message: LookupUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dids) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookupUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookupUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dids.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookupUsersRequest {
    return { dids: globalThis.Array.isArray(object?.dids) ? object.dids.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: LookupUsersRequest): unknown {
    const obj: any = {};
    if (message.dids?.length) {
      obj.dids = message.dids;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookupUsersRequest>, I>>(base?: I): LookupUsersRequest {
    return LookupUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookupUsersRequest>, I>>(object: I): LookupUsersRequest {
    const message = createBaseLookupUsersRequest();
    message.dids = object.dids?.map((e) => e) || [];
    return message;
  },
};

function createBaseLookupUsersResponse(): LookupUsersResponse {
  return { users: [] };
}

export const LookupUsersResponse = {
  encode(message: LookupUsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      SimpleUserInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookupUsersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookupUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(SimpleUserInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookupUsersResponse {
    return {
      users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => SimpleUserInfo.fromJSON(e)) : [],
    };
  },

  toJSON(message: LookupUsersResponse): unknown {
    const obj: any = {};
    if (message.users?.length) {
      obj.users = message.users.map((e) => SimpleUserInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookupUsersResponse>, I>>(base?: I): LookupUsersResponse {
    return LookupUsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookupUsersResponse>, I>>(object: I): LookupUsersResponse {
    const message = createBaseLookupUsersResponse();
    message.users = object.users?.map((e) => SimpleUserInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBulkMintRequest(): BulkMintRequest {
  return { reqs: [] };
}

export const BulkMintRequest = {
  encode(message: BulkMintRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.reqs) {
      DeclareRewardTransactionContentRequest.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkMintRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkMintRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.reqs.push(DeclareRewardTransactionContentRequest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkMintRequest {
    return {
      reqs: globalThis.Array.isArray(object?.reqs)
        ? object.reqs.map((e: any) => DeclareRewardTransactionContentRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BulkMintRequest): unknown {
    const obj: any = {};
    if (message.reqs?.length) {
      obj.reqs = message.reqs.map((e) => DeclareRewardTransactionContentRequest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkMintRequest>, I>>(base?: I): BulkMintRequest {
    return BulkMintRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkMintRequest>, I>>(object: I): BulkMintRequest {
    const message = createBaseBulkMintRequest();
    message.reqs = object.reqs?.map((e) => DeclareRewardTransactionContentRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBulkMintResponse(): BulkMintResponse {
  return { resps: [] };
}

export const BulkMintResponse = {
  encode(message: BulkMintResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.resps) {
      DeclareRewardTransactionContentResponse.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkMintResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkMintResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.resps.push(DeclareRewardTransactionContentResponse.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkMintResponse {
    return {
      resps: globalThis.Array.isArray(object?.resps)
        ? object.resps.map((e: any) => DeclareRewardTransactionContentResponse.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BulkMintResponse): unknown {
    const obj: any = {};
    if (message.resps?.length) {
      obj.resps = message.resps.map((e) => DeclareRewardTransactionContentResponse.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkMintResponse>, I>>(base?: I): BulkMintResponse {
    return BulkMintResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkMintResponse>, I>>(object: I): BulkMintResponse {
    const message = createBaseBulkMintResponse();
    message.resps = object.resps?.map((e) => DeclareRewardTransactionContentResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAppVersionConfig(): AppVersionConfig {
  return {
    androidVersion: "",
    androidForcedUpdate: false,
    iosVersion: "",
    iosForcedUpdate: false,
    needNotification: false,
  };
}

export const AppVersionConfig = {
  encode(message: AppVersionConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.androidVersion !== "") {
      writer.uint32(10).string(message.androidVersion);
    }
    if (message.androidForcedUpdate === true) {
      writer.uint32(16).bool(message.androidForcedUpdate);
    }
    if (message.iosVersion !== "") {
      writer.uint32(26).string(message.iosVersion);
    }
    if (message.iosForcedUpdate === true) {
      writer.uint32(32).bool(message.iosForcedUpdate);
    }
    if (message.needNotification === true) {
      writer.uint32(40).bool(message.needNotification);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AppVersionConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAppVersionConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.androidVersion = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.androidForcedUpdate = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.iosVersion = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.iosForcedUpdate = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.needNotification = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AppVersionConfig {
    return {
      androidVersion: isSet(object.androidVersion) ? globalThis.String(object.androidVersion) : "",
      androidForcedUpdate: isSet(object.androidForcedUpdate) ? globalThis.Boolean(object.androidForcedUpdate) : false,
      iosVersion: isSet(object.iosVersion) ? globalThis.String(object.iosVersion) : "",
      iosForcedUpdate: isSet(object.iosForcedUpdate) ? globalThis.Boolean(object.iosForcedUpdate) : false,
      needNotification: isSet(object.needNotification) ? globalThis.Boolean(object.needNotification) : false,
    };
  },

  toJSON(message: AppVersionConfig): unknown {
    const obj: any = {};
    if (message.androidVersion !== "") {
      obj.androidVersion = message.androidVersion;
    }
    if (message.androidForcedUpdate === true) {
      obj.androidForcedUpdate = message.androidForcedUpdate;
    }
    if (message.iosVersion !== "") {
      obj.iosVersion = message.iosVersion;
    }
    if (message.iosForcedUpdate === true) {
      obj.iosForcedUpdate = message.iosForcedUpdate;
    }
    if (message.needNotification === true) {
      obj.needNotification = message.needNotification;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AppVersionConfig>, I>>(base?: I): AppVersionConfig {
    return AppVersionConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AppVersionConfig>, I>>(object: I): AppVersionConfig {
    const message = createBaseAppVersionConfig();
    message.androidVersion = object.androidVersion ?? "";
    message.androidForcedUpdate = object.androidForcedUpdate ?? false;
    message.iosVersion = object.iosVersion ?? "";
    message.iosForcedUpdate = object.iosForcedUpdate ?? false;
    message.needNotification = object.needNotification ?? false;
    return message;
  },
};

function createBaseAppVersionConfigPublishRequest(): AppVersionConfigPublishRequest {
  return { appVersionConfig: undefined };
}

export const AppVersionConfigPublishRequest = {
  encode(message: AppVersionConfigPublishRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.appVersionConfig !== undefined) {
      AppVersionConfig.encode(message.appVersionConfig, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AppVersionConfigPublishRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAppVersionConfigPublishRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.appVersionConfig = AppVersionConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AppVersionConfigPublishRequest {
    return {
      appVersionConfig: isSet(object.appVersionConfig) ? AppVersionConfig.fromJSON(object.appVersionConfig) : undefined,
    };
  },

  toJSON(message: AppVersionConfigPublishRequest): unknown {
    const obj: any = {};
    if (message.appVersionConfig !== undefined) {
      obj.appVersionConfig = AppVersionConfig.toJSON(message.appVersionConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AppVersionConfigPublishRequest>, I>>(base?: I): AppVersionConfigPublishRequest {
    return AppVersionConfigPublishRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AppVersionConfigPublishRequest>, I>>(
    object: I,
  ): AppVersionConfigPublishRequest {
    const message = createBaseAppVersionConfigPublishRequest();
    message.appVersionConfig = (object.appVersionConfig !== undefined && object.appVersionConfig !== null)
      ? AppVersionConfig.fromPartial(object.appVersionConfig)
      : undefined;
    return message;
  },
};

function createBaseAppVersionConfigPublishResponse(): AppVersionConfigPublishResponse {
  return { code: 0 };
}

export const AppVersionConfigPublishResponse = {
  encode(message: AppVersionConfigPublishResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AppVersionConfigPublishResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAppVersionConfigPublishResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AppVersionConfigPublishResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: AppVersionConfigPublishResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AppVersionConfigPublishResponse>, I>>(base?: I): AppVersionConfigPublishResponse {
    return AppVersionConfigPublishResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AppVersionConfigPublishResponse>, I>>(
    object: I,
  ): AppVersionConfigPublishResponse {
    const message = createBaseAppVersionConfigPublishResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseHomeBannerCard(): HomeBannerCard {
  return {
    type: 0,
    enabled: false,
    supportedPlatforms: 0,
    title: "",
    titleVisible: false,
    bannerImageUrl: undefined,
    landingPageUrl: undefined,
    notBefore: undefined,
    notAfter: undefined,
    requiresAccessTokenInGetMethod: undefined,
    proposalId: undefined,
    purpose: undefined,
  };
}

export const HomeBannerCard = {
  encode(message: HomeBannerCard, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.enabled === true) {
      writer.uint32(16).bool(message.enabled);
    }
    if (message.supportedPlatforms !== 0) {
      writer.uint32(24).int32(message.supportedPlatforms);
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.titleVisible === true) {
      writer.uint32(40).bool(message.titleVisible);
    }
    if (message.bannerImageUrl !== undefined) {
      writer.uint32(82).string(message.bannerImageUrl);
    }
    if (message.landingPageUrl !== undefined) {
      writer.uint32(90).string(message.landingPageUrl);
    }
    if (message.notBefore !== undefined) {
      writer.uint32(106).string(message.notBefore);
    }
    if (message.notAfter !== undefined) {
      writer.uint32(114).string(message.notAfter);
    }
    if (message.requiresAccessTokenInGetMethod !== undefined) {
      writer.uint32(96).bool(message.requiresAccessTokenInGetMethod);
    }
    if (message.proposalId !== undefined) {
      writer.uint32(162).string(message.proposalId);
    }
    if (message.purpose !== undefined) {
      writer.uint32(240).uint32(message.purpose);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerCard {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerCard();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.supportedPlatforms = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.titleVisible = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.bannerImageUrl = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.landingPageUrl = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.notBefore = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.notAfter = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.requiresAccessTokenInGetMethod = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.purpose = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerCard {
    return {
      type: isSet(object.type) ? homeBannerCardTypeFromJSON(object.type) : 0,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      supportedPlatforms: isSet(object.supportedPlatforms)
        ? homeBannerSupportedPlatformFromJSON(object.supportedPlatforms)
        : 0,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      titleVisible: isSet(object.titleVisible) ? globalThis.Boolean(object.titleVisible) : false,
      bannerImageUrl: isSet(object.bannerImageUrl) ? globalThis.String(object.bannerImageUrl) : undefined,
      landingPageUrl: isSet(object.landingPageUrl) ? globalThis.String(object.landingPageUrl) : undefined,
      notBefore: isSet(object.notBefore) ? globalThis.String(object.notBefore) : undefined,
      notAfter: isSet(object.notAfter) ? globalThis.String(object.notAfter) : undefined,
      requiresAccessTokenInGetMethod: isSet(object.requiresAccessTokenInGetMethod)
        ? globalThis.Boolean(object.requiresAccessTokenInGetMethod)
        : undefined,
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : undefined,
      purpose: isSet(object.purpose) ? globalThis.Number(object.purpose) : undefined,
    };
  },

  toJSON(message: HomeBannerCard): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = homeBannerCardTypeToJSON(message.type);
    }
    if (message.enabled === true) {
      obj.enabled = message.enabled;
    }
    if (message.supportedPlatforms !== 0) {
      obj.supportedPlatforms = homeBannerSupportedPlatformToJSON(message.supportedPlatforms);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.titleVisible === true) {
      obj.titleVisible = message.titleVisible;
    }
    if (message.bannerImageUrl !== undefined) {
      obj.bannerImageUrl = message.bannerImageUrl;
    }
    if (message.landingPageUrl !== undefined) {
      obj.landingPageUrl = message.landingPageUrl;
    }
    if (message.notBefore !== undefined) {
      obj.notBefore = message.notBefore;
    }
    if (message.notAfter !== undefined) {
      obj.notAfter = message.notAfter;
    }
    if (message.requiresAccessTokenInGetMethod !== undefined) {
      obj.requiresAccessTokenInGetMethod = message.requiresAccessTokenInGetMethod;
    }
    if (message.proposalId !== undefined) {
      obj.proposalId = message.proposalId;
    }
    if (message.purpose !== undefined) {
      obj.purpose = Math.round(message.purpose);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerCard>, I>>(base?: I): HomeBannerCard {
    return HomeBannerCard.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerCard>, I>>(object: I): HomeBannerCard {
    const message = createBaseHomeBannerCard();
    message.type = object.type ?? 0;
    message.enabled = object.enabled ?? false;
    message.supportedPlatforms = object.supportedPlatforms ?? 0;
    message.title = object.title ?? "";
    message.titleVisible = object.titleVisible ?? false;
    message.bannerImageUrl = object.bannerImageUrl ?? undefined;
    message.landingPageUrl = object.landingPageUrl ?? undefined;
    message.notBefore = object.notBefore ?? undefined;
    message.notAfter = object.notAfter ?? undefined;
    message.requiresAccessTokenInGetMethod = object.requiresAccessTokenInGetMethod ?? undefined;
    message.proposalId = object.proposalId ?? undefined;
    message.purpose = object.purpose ?? undefined;
    return message;
  },
};

function createBaseHomeBannerConfig(): HomeBannerConfig {
  return { cards: [] };
}

export const HomeBannerConfig = {
  encode(message: HomeBannerConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.cards) {
      HomeBannerCard.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cards.push(HomeBannerCard.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerConfig {
    return {
      cards: globalThis.Array.isArray(object?.cards) ? object.cards.map((e: any) => HomeBannerCard.fromJSON(e)) : [],
    };
  },

  toJSON(message: HomeBannerConfig): unknown {
    const obj: any = {};
    if (message.cards?.length) {
      obj.cards = message.cards.map((e) => HomeBannerCard.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerConfig>, I>>(base?: I): HomeBannerConfig {
    return HomeBannerConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerConfig>, I>>(object: I): HomeBannerConfig {
    const message = createBaseHomeBannerConfig();
    message.cards = object.cards?.map((e) => HomeBannerCard.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHomeBannerSaveRequest(): HomeBannerSaveRequest {
  return { bannerNo: undefined, config: undefined, setReadOnly: false, setHidden: false };
}

export const HomeBannerSaveRequest = {
  encode(message: HomeBannerSaveRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bannerNo !== undefined) {
      writer.uint32(8).uint64(message.bannerNo);
    }
    if (message.config !== undefined) {
      HomeBannerConfig.encode(message.config, writer.uint32(18).fork()).ldelim();
    }
    if (message.setReadOnly === true) {
      writer.uint32(24).bool(message.setReadOnly);
    }
    if (message.setHidden === true) {
      writer.uint32(32).bool(message.setHidden);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerSaveRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerSaveRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.bannerNo = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.config = HomeBannerConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.setReadOnly = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.setHidden = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerSaveRequest {
    return {
      bannerNo: isSet(object.bannerNo) ? globalThis.Number(object.bannerNo) : undefined,
      config: isSet(object.config) ? HomeBannerConfig.fromJSON(object.config) : undefined,
      setReadOnly: isSet(object.setReadOnly) ? globalThis.Boolean(object.setReadOnly) : false,
      setHidden: isSet(object.setHidden) ? globalThis.Boolean(object.setHidden) : false,
    };
  },

  toJSON(message: HomeBannerSaveRequest): unknown {
    const obj: any = {};
    if (message.bannerNo !== undefined) {
      obj.bannerNo = Math.round(message.bannerNo);
    }
    if (message.config !== undefined) {
      obj.config = HomeBannerConfig.toJSON(message.config);
    }
    if (message.setReadOnly === true) {
      obj.setReadOnly = message.setReadOnly;
    }
    if (message.setHidden === true) {
      obj.setHidden = message.setHidden;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerSaveRequest>, I>>(base?: I): HomeBannerSaveRequest {
    return HomeBannerSaveRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerSaveRequest>, I>>(object: I): HomeBannerSaveRequest {
    const message = createBaseHomeBannerSaveRequest();
    message.bannerNo = object.bannerNo ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? HomeBannerConfig.fromPartial(object.config)
      : undefined;
    message.setReadOnly = object.setReadOnly ?? false;
    message.setHidden = object.setHidden ?? false;
    return message;
  },
};

function createBaseHomeBannerSaveResponse(): HomeBannerSaveResponse {
  return { code: 0, bannerNo: 0 };
}

export const HomeBannerSaveResponse = {
  encode(message: HomeBannerSaveResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.bannerNo !== 0) {
      writer.uint32(16).uint64(message.bannerNo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerSaveResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerSaveResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bannerNo = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerSaveResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      bannerNo: isSet(object.bannerNo) ? globalThis.Number(object.bannerNo) : 0,
    };
  },

  toJSON(message: HomeBannerSaveResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.bannerNo !== 0) {
      obj.bannerNo = Math.round(message.bannerNo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerSaveResponse>, I>>(base?: I): HomeBannerSaveResponse {
    return HomeBannerSaveResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerSaveResponse>, I>>(object: I): HomeBannerSaveResponse {
    const message = createBaseHomeBannerSaveResponse();
    message.code = object.code ?? 0;
    message.bannerNo = object.bannerNo ?? 0;
    return message;
  },
};

function createBaseSavedHomeBannerConfig(): SavedHomeBannerConfig {
  return { bannerNo: 0, isReadOnly: false, isHidden: false, createdBy: "", config: undefined };
}

export const SavedHomeBannerConfig = {
  encode(message: SavedHomeBannerConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bannerNo !== 0) {
      writer.uint32(8).uint64(message.bannerNo);
    }
    if (message.isReadOnly === true) {
      writer.uint32(16).bool(message.isReadOnly);
    }
    if (message.isHidden === true) {
      writer.uint32(24).bool(message.isHidden);
    }
    if (message.createdBy !== "") {
      writer.uint32(34).string(message.createdBy);
    }
    if (message.config !== undefined) {
      HomeBannerConfig.encode(message.config, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SavedHomeBannerConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSavedHomeBannerConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.bannerNo = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isReadOnly = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isHidden = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.config = HomeBannerConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SavedHomeBannerConfig {
    return {
      bannerNo: isSet(object.bannerNo) ? globalThis.Number(object.bannerNo) : 0,
      isReadOnly: isSet(object.isReadOnly) ? globalThis.Boolean(object.isReadOnly) : false,
      isHidden: isSet(object.isHidden) ? globalThis.Boolean(object.isHidden) : false,
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      config: isSet(object.config) ? HomeBannerConfig.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: SavedHomeBannerConfig): unknown {
    const obj: any = {};
    if (message.bannerNo !== 0) {
      obj.bannerNo = Math.round(message.bannerNo);
    }
    if (message.isReadOnly === true) {
      obj.isReadOnly = message.isReadOnly;
    }
    if (message.isHidden === true) {
      obj.isHidden = message.isHidden;
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.config !== undefined) {
      obj.config = HomeBannerConfig.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SavedHomeBannerConfig>, I>>(base?: I): SavedHomeBannerConfig {
    return SavedHomeBannerConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SavedHomeBannerConfig>, I>>(object: I): SavedHomeBannerConfig {
    const message = createBaseSavedHomeBannerConfig();
    message.bannerNo = object.bannerNo ?? 0;
    message.isReadOnly = object.isReadOnly ?? false;
    message.isHidden = object.isHidden ?? false;
    message.createdBy = object.createdBy ?? "";
    message.config = (object.config !== undefined && object.config !== null)
      ? HomeBannerConfig.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseHomeBannerListRequest(): HomeBannerListRequest {
  return { pageSize: 0, pageNum: 0 };
}

export const HomeBannerListRequest = {
  encode(message: HomeBannerListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).uint32(message.pageSize);
    }
    if (message.pageNum !== 0) {
      writer.uint32(16).uint32(message.pageNum);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageNum = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerListRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageNum: isSet(object.pageNum) ? globalThis.Number(object.pageNum) : 0,
    };
  },

  toJSON(message: HomeBannerListRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNum !== 0) {
      obj.pageNum = Math.round(message.pageNum);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerListRequest>, I>>(base?: I): HomeBannerListRequest {
    return HomeBannerListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerListRequest>, I>>(object: I): HomeBannerListRequest {
    const message = createBaseHomeBannerListRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageNum = object.pageNum ?? 0;
    return message;
  },
};

function createBaseHomeBannerListResponse(): HomeBannerListResponse {
  return { configNumbers: [] };
}

export const HomeBannerListResponse = {
  encode(message: HomeBannerListResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.configNumbers) {
      SavedHomeBannerConfig.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerListResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.configNumbers.push(SavedHomeBannerConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerListResponse {
    return {
      configNumbers: globalThis.Array.isArray(object?.configNumbers)
        ? object.configNumbers.map((e: any) => SavedHomeBannerConfig.fromJSON(e))
        : [],
    };
  },

  toJSON(message: HomeBannerListResponse): unknown {
    const obj: any = {};
    if (message.configNumbers?.length) {
      obj.configNumbers = message.configNumbers.map((e) => SavedHomeBannerConfig.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerListResponse>, I>>(base?: I): HomeBannerListResponse {
    return HomeBannerListResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerListResponse>, I>>(object: I): HomeBannerListResponse {
    const message = createBaseHomeBannerListResponse();
    message.configNumbers = object.configNumbers?.map((e) => SavedHomeBannerConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHomeBannerPublishRequest(): HomeBannerPublishRequest {
  return { bannerNo: 0 };
}

export const HomeBannerPublishRequest = {
  encode(message: HomeBannerPublishRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bannerNo !== 0) {
      writer.uint32(8).uint64(message.bannerNo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerPublishRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerPublishRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.bannerNo = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerPublishRequest {
    return { bannerNo: isSet(object.bannerNo) ? globalThis.Number(object.bannerNo) : 0 };
  },

  toJSON(message: HomeBannerPublishRequest): unknown {
    const obj: any = {};
    if (message.bannerNo !== 0) {
      obj.bannerNo = Math.round(message.bannerNo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerPublishRequest>, I>>(base?: I): HomeBannerPublishRequest {
    return HomeBannerPublishRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerPublishRequest>, I>>(object: I): HomeBannerPublishRequest {
    const message = createBaseHomeBannerPublishRequest();
    message.bannerNo = object.bannerNo ?? 0;
    return message;
  },
};

function createBaseHomeBannerPublishResponse(): HomeBannerPublishResponse {
  return { code: 0 };
}

export const HomeBannerPublishResponse = {
  encode(message: HomeBannerPublishResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerPublishResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerPublishResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerPublishResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: HomeBannerPublishResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerPublishResponse>, I>>(base?: I): HomeBannerPublishResponse {
    return HomeBannerPublishResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerPublishResponse>, I>>(object: I): HomeBannerPublishResponse {
    const message = createBaseHomeBannerPublishResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseProposalWithDid(): ProposalWithDid {
  return { proposal: undefined, did: "" };
}

export const ProposalWithDid = {
  encode(message: ProposalWithDid, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposal !== undefined) {
      Proposal.encode(message.proposal, writer.uint32(10).fork()).ldelim();
    }
    if (message.did !== "") {
      writer.uint32(18).string(message.did);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalWithDid {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalWithDid();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposal = Proposal.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.did = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalWithDid {
    return {
      proposal: isSet(object.proposal) ? Proposal.fromJSON(object.proposal) : undefined,
      did: isSet(object.did) ? globalThis.String(object.did) : "",
    };
  },

  toJSON(message: ProposalWithDid): unknown {
    const obj: any = {};
    if (message.proposal !== undefined) {
      obj.proposal = Proposal.toJSON(message.proposal);
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalWithDid>, I>>(base?: I): ProposalWithDid {
    return ProposalWithDid.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalWithDid>, I>>(object: I): ProposalWithDid {
    const message = createBaseProposalWithDid();
    message.proposal = (object.proposal !== undefined && object.proposal !== null)
      ? Proposal.fromPartial(object.proposal)
      : undefined;
    message.did = object.did ?? "";
    return message;
  },
};

function createBaseElasticSearchQueryTemplate(): ElasticSearchQueryTemplate {
  return { elasticSearchIndex: "", baseQuery: "", arguments: {}, resultJsonPath: "", resultType: "" };
}

export const ElasticSearchQueryTemplate = {
  encode(message: ElasticSearchQueryTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.elasticSearchIndex !== "") {
      writer.uint32(10).string(message.elasticSearchIndex);
    }
    if (message.baseQuery !== "") {
      writer.uint32(18).string(message.baseQuery);
    }
    Object.entries(message.arguments).forEach(([key, value]) => {
      ElasticSearchQueryTemplate_ArgumentsEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    if (message.resultJsonPath !== "") {
      writer.uint32(34).string(message.resultJsonPath);
    }
    if (message.resultType !== "") {
      writer.uint32(42).string(message.resultType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ElasticSearchQueryTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElasticSearchQueryTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.elasticSearchIndex = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseQuery = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = ElasticSearchQueryTemplate_ArgumentsEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.arguments[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.resultJsonPath = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.resultType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ElasticSearchQueryTemplate {
    return {
      elasticSearchIndex: isSet(object.elasticSearchIndex) ? globalThis.String(object.elasticSearchIndex) : "",
      baseQuery: isSet(object.baseQuery) ? globalThis.String(object.baseQuery) : "",
      arguments: isObject(object.arguments)
        ? Object.entries(object.arguments).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      resultJsonPath: isSet(object.resultJsonPath) ? globalThis.String(object.resultJsonPath) : "",
      resultType: isSet(object.resultType) ? globalThis.String(object.resultType) : "",
    };
  },

  toJSON(message: ElasticSearchQueryTemplate): unknown {
    const obj: any = {};
    if (message.elasticSearchIndex !== "") {
      obj.elasticSearchIndex = message.elasticSearchIndex;
    }
    if (message.baseQuery !== "") {
      obj.baseQuery = message.baseQuery;
    }
    if (message.arguments) {
      const entries = Object.entries(message.arguments);
      if (entries.length > 0) {
        obj.arguments = {};
        entries.forEach(([k, v]) => {
          obj.arguments[k] = v;
        });
      }
    }
    if (message.resultJsonPath !== "") {
      obj.resultJsonPath = message.resultJsonPath;
    }
    if (message.resultType !== "") {
      obj.resultType = message.resultType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ElasticSearchQueryTemplate>, I>>(base?: I): ElasticSearchQueryTemplate {
    return ElasticSearchQueryTemplate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ElasticSearchQueryTemplate>, I>>(object: I): ElasticSearchQueryTemplate {
    const message = createBaseElasticSearchQueryTemplate();
    message.elasticSearchIndex = object.elasticSearchIndex ?? "";
    message.baseQuery = object.baseQuery ?? "";
    message.arguments = Object.entries(object.arguments ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.resultJsonPath = object.resultJsonPath ?? "";
    message.resultType = object.resultType ?? "";
    return message;
  },
};

function createBaseElasticSearchQueryTemplate_ArgumentsEntry(): ElasticSearchQueryTemplate_ArgumentsEntry {
  return { key: "", value: "" };
}

export const ElasticSearchQueryTemplate_ArgumentsEntry = {
  encode(message: ElasticSearchQueryTemplate_ArgumentsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ElasticSearchQueryTemplate_ArgumentsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElasticSearchQueryTemplate_ArgumentsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ElasticSearchQueryTemplate_ArgumentsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ElasticSearchQueryTemplate_ArgumentsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ElasticSearchQueryTemplate_ArgumentsEntry>, I>>(
    base?: I,
  ): ElasticSearchQueryTemplate_ArgumentsEntry {
    return ElasticSearchQueryTemplate_ArgumentsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ElasticSearchQueryTemplate_ArgumentsEntry>, I>>(
    object: I,
  ): ElasticSearchQueryTemplate_ArgumentsEntry {
    const message = createBaseElasticSearchQueryTemplate_ArgumentsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseApplicationResource(): ApplicationResource {
  return { elasticSearchQueryTemplate: undefined };
}

export const ApplicationResource = {
  encode(message: ApplicationResource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.elasticSearchQueryTemplate !== undefined) {
      ElasticSearchQueryTemplate.encode(message.elasticSearchQueryTemplate, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplicationResource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplicationResource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.elasticSearchQueryTemplate = ElasticSearchQueryTemplate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApplicationResource {
    return {
      elasticSearchQueryTemplate: isSet(object.elasticSearchQueryTemplate)
        ? ElasticSearchQueryTemplate.fromJSON(object.elasticSearchQueryTemplate)
        : undefined,
    };
  },

  toJSON(message: ApplicationResource): unknown {
    const obj: any = {};
    if (message.elasticSearchQueryTemplate !== undefined) {
      obj.elasticSearchQueryTemplate = ElasticSearchQueryTemplate.toJSON(message.elasticSearchQueryTemplate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApplicationResource>, I>>(base?: I): ApplicationResource {
    return ApplicationResource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApplicationResource>, I>>(object: I): ApplicationResource {
    const message = createBaseApplicationResource();
    message.elasticSearchQueryTemplate =
      (object.elasticSearchQueryTemplate !== undefined && object.elasticSearchQueryTemplate !== null)
        ? ElasticSearchQueryTemplate.fromPartial(object.elasticSearchQueryTemplate)
        : undefined;
    return message;
  },
};

function createBaseBurnPointRequest(): BurnPointRequest {
  return { candidates: {}, candidatesInMyd2: {}, encryptedOperatorKeyInBase64: "" };
}

export const BurnPointRequest = {
  encode(message: BurnPointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.candidates).forEach(([key, value]) => {
      BurnPointRequest_CandidatesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    Object.entries(message.candidatesInMyd2).forEach(([key, value]) => {
      BurnPointRequest_CandidatesInMyd2Entry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    if (message.encryptedOperatorKeyInBase64 !== "") {
      writer.uint32(26).string(message.encryptedOperatorKeyInBase64);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BurnPointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBurnPointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = BurnPointRequest_CandidatesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.candidates[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = BurnPointRequest_CandidatesInMyd2Entry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.candidatesInMyd2[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.encryptedOperatorKeyInBase64 = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BurnPointRequest {
    return {
      candidates: isObject(object.candidates)
        ? Object.entries(object.candidates).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      candidatesInMyd2: isObject(object.candidatesInMyd2)
        ? Object.entries(object.candidatesInMyd2).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      encryptedOperatorKeyInBase64: isSet(object.encryptedOperatorKeyInBase64)
        ? globalThis.String(object.encryptedOperatorKeyInBase64)
        : "",
    };
  },

  toJSON(message: BurnPointRequest): unknown {
    const obj: any = {};
    if (message.candidates) {
      const entries = Object.entries(message.candidates);
      if (entries.length > 0) {
        obj.candidates = {};
        entries.forEach(([k, v]) => {
          obj.candidates[k] = Math.round(v);
        });
      }
    }
    if (message.candidatesInMyd2) {
      const entries = Object.entries(message.candidatesInMyd2);
      if (entries.length > 0) {
        obj.candidatesInMyd2 = {};
        entries.forEach(([k, v]) => {
          obj.candidatesInMyd2[k] = Math.round(v);
        });
      }
    }
    if (message.encryptedOperatorKeyInBase64 !== "") {
      obj.encryptedOperatorKeyInBase64 = message.encryptedOperatorKeyInBase64;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BurnPointRequest>, I>>(base?: I): BurnPointRequest {
    return BurnPointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BurnPointRequest>, I>>(object: I): BurnPointRequest {
    const message = createBaseBurnPointRequest();
    message.candidates = Object.entries(object.candidates ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.candidatesInMyd2 = Object.entries(object.candidatesInMyd2 ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.encryptedOperatorKeyInBase64 = object.encryptedOperatorKeyInBase64 ?? "";
    return message;
  },
};

function createBaseBurnPointRequest_CandidatesEntry(): BurnPointRequest_CandidatesEntry {
  return { key: "", value: 0 };
}

export const BurnPointRequest_CandidatesEntry = {
  encode(message: BurnPointRequest_CandidatesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BurnPointRequest_CandidatesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBurnPointRequest_CandidatesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BurnPointRequest_CandidatesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: BurnPointRequest_CandidatesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BurnPointRequest_CandidatesEntry>, I>>(
    base?: I,
  ): BurnPointRequest_CandidatesEntry {
    return BurnPointRequest_CandidatesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BurnPointRequest_CandidatesEntry>, I>>(
    object: I,
  ): BurnPointRequest_CandidatesEntry {
    const message = createBaseBurnPointRequest_CandidatesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseBurnPointRequest_CandidatesInMyd2Entry(): BurnPointRequest_CandidatesInMyd2Entry {
  return { key: "", value: 0 };
}

export const BurnPointRequest_CandidatesInMyd2Entry = {
  encode(message: BurnPointRequest_CandidatesInMyd2Entry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BurnPointRequest_CandidatesInMyd2Entry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBurnPointRequest_CandidatesInMyd2Entry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BurnPointRequest_CandidatesInMyd2Entry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: BurnPointRequest_CandidatesInMyd2Entry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BurnPointRequest_CandidatesInMyd2Entry>, I>>(
    base?: I,
  ): BurnPointRequest_CandidatesInMyd2Entry {
    return BurnPointRequest_CandidatesInMyd2Entry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BurnPointRequest_CandidatesInMyd2Entry>, I>>(
    object: I,
  ): BurnPointRequest_CandidatesInMyd2Entry {
    const message = createBaseBurnPointRequest_CandidatesInMyd2Entry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseBurnPointResponse(): BurnPointResponse {
  return { processed: {}, processedInMyd2: {}, transactionStatus: 0, transactionMessage: undefined };
}

export const BurnPointResponse = {
  encode(message: BurnPointResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.processed).forEach(([key, value]) => {
      BurnPointResponse_ProcessedEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    Object.entries(message.processedInMyd2).forEach(([key, value]) => {
      BurnPointResponse_ProcessedInMyd2Entry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    if (message.transactionStatus !== 0) {
      writer.uint32(24).int32(message.transactionStatus);
    }
    if (message.transactionMessage !== undefined) {
      writer.uint32(34).string(message.transactionMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BurnPointResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBurnPointResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = BurnPointResponse_ProcessedEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.processed[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = BurnPointResponse_ProcessedInMyd2Entry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.processedInMyd2[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.transactionStatus = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.transactionMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BurnPointResponse {
    return {
      processed: isObject(object.processed)
        ? Object.entries(object.processed).reduce<{ [key: string]: CommonStatusCode }>((acc, [key, value]) => {
          acc[key] = commonStatusCodeFromJSON(value);
          return acc;
        }, {})
        : {},
      processedInMyd2: isObject(object.processedInMyd2)
        ? Object.entries(object.processedInMyd2).reduce<{ [key: string]: CommonStatusCode }>((acc, [key, value]) => {
          acc[key] = commonStatusCodeFromJSON(value);
          return acc;
        }, {})
        : {},
      transactionStatus: isSet(object.transactionStatus) ? commonStatusCodeFromJSON(object.transactionStatus) : 0,
      transactionMessage: isSet(object.transactionMessage) ? globalThis.String(object.transactionMessage) : undefined,
    };
  },

  toJSON(message: BurnPointResponse): unknown {
    const obj: any = {};
    if (message.processed) {
      const entries = Object.entries(message.processed);
      if (entries.length > 0) {
        obj.processed = {};
        entries.forEach(([k, v]) => {
          obj.processed[k] = commonStatusCodeToJSON(v);
        });
      }
    }
    if (message.processedInMyd2) {
      const entries = Object.entries(message.processedInMyd2);
      if (entries.length > 0) {
        obj.processedInMyd2 = {};
        entries.forEach(([k, v]) => {
          obj.processedInMyd2[k] = commonStatusCodeToJSON(v);
        });
      }
    }
    if (message.transactionStatus !== 0) {
      obj.transactionStatus = commonStatusCodeToJSON(message.transactionStatus);
    }
    if (message.transactionMessage !== undefined) {
      obj.transactionMessage = message.transactionMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BurnPointResponse>, I>>(base?: I): BurnPointResponse {
    return BurnPointResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BurnPointResponse>, I>>(object: I): BurnPointResponse {
    const message = createBaseBurnPointResponse();
    message.processed = Object.entries(object.processed ?? {}).reduce<{ [key: string]: CommonStatusCode }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value as CommonStatusCode;
        }
        return acc;
      },
      {},
    );
    message.processedInMyd2 = Object.entries(object.processedInMyd2 ?? {}).reduce<{ [key: string]: CommonStatusCode }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value as CommonStatusCode;
        }
        return acc;
      },
      {},
    );
    message.transactionStatus = object.transactionStatus ?? 0;
    message.transactionMessage = object.transactionMessage ?? undefined;
    return message;
  },
};

function createBaseBurnPointResponse_ProcessedEntry(): BurnPointResponse_ProcessedEntry {
  return { key: "", value: 0 };
}

export const BurnPointResponse_ProcessedEntry = {
  encode(message: BurnPointResponse_ProcessedEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BurnPointResponse_ProcessedEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBurnPointResponse_ProcessedEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BurnPointResponse_ProcessedEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? commonStatusCodeFromJSON(object.value) : 0,
    };
  },

  toJSON(message: BurnPointResponse_ProcessedEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = commonStatusCodeToJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BurnPointResponse_ProcessedEntry>, I>>(
    base?: I,
  ): BurnPointResponse_ProcessedEntry {
    return BurnPointResponse_ProcessedEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BurnPointResponse_ProcessedEntry>, I>>(
    object: I,
  ): BurnPointResponse_ProcessedEntry {
    const message = createBaseBurnPointResponse_ProcessedEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseBurnPointResponse_ProcessedInMyd2Entry(): BurnPointResponse_ProcessedInMyd2Entry {
  return { key: "", value: 0 };
}

export const BurnPointResponse_ProcessedInMyd2Entry = {
  encode(message: BurnPointResponse_ProcessedInMyd2Entry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BurnPointResponse_ProcessedInMyd2Entry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBurnPointResponse_ProcessedInMyd2Entry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BurnPointResponse_ProcessedInMyd2Entry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? commonStatusCodeFromJSON(object.value) : 0,
    };
  },

  toJSON(message: BurnPointResponse_ProcessedInMyd2Entry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = commonStatusCodeToJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BurnPointResponse_ProcessedInMyd2Entry>, I>>(
    base?: I,
  ): BurnPointResponse_ProcessedInMyd2Entry {
    return BurnPointResponse_ProcessedInMyd2Entry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BurnPointResponse_ProcessedInMyd2Entry>, I>>(
    object: I,
  ): BurnPointResponse_ProcessedInMyd2Entry {
    const message = createBaseBurnPointResponse_ProcessedInMyd2Entry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseQueryBurnPointsCandidatesRequest(): QueryBurnPointsCandidatesRequest {
  return { queryCandidates: false, queryCandidatesInMyd2: false, startIdx: 0, endIdx: 0 };
}

export const QueryBurnPointsCandidatesRequest = {
  encode(message: QueryBurnPointsCandidatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.queryCandidates === true) {
      writer.uint32(8).bool(message.queryCandidates);
    }
    if (message.queryCandidatesInMyd2 === true) {
      writer.uint32(16).bool(message.queryCandidatesInMyd2);
    }
    if (message.startIdx !== 0) {
      writer.uint32(24).uint64(message.startIdx);
    }
    if (message.endIdx !== 0) {
      writer.uint32(32).uint64(message.endIdx);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBurnPointsCandidatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBurnPointsCandidatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.queryCandidates = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.queryCandidatesInMyd2 = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.startIdx = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.endIdx = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryBurnPointsCandidatesRequest {
    return {
      queryCandidates: isSet(object.queryCandidates) ? globalThis.Boolean(object.queryCandidates) : false,
      queryCandidatesInMyd2: isSet(object.queryCandidatesInMyd2)
        ? globalThis.Boolean(object.queryCandidatesInMyd2)
        : false,
      startIdx: isSet(object.startIdx) ? globalThis.Number(object.startIdx) : 0,
      endIdx: isSet(object.endIdx) ? globalThis.Number(object.endIdx) : 0,
    };
  },

  toJSON(message: QueryBurnPointsCandidatesRequest): unknown {
    const obj: any = {};
    if (message.queryCandidates === true) {
      obj.queryCandidates = message.queryCandidates;
    }
    if (message.queryCandidatesInMyd2 === true) {
      obj.queryCandidatesInMyd2 = message.queryCandidatesInMyd2;
    }
    if (message.startIdx !== 0) {
      obj.startIdx = Math.round(message.startIdx);
    }
    if (message.endIdx !== 0) {
      obj.endIdx = Math.round(message.endIdx);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryBurnPointsCandidatesRequest>, I>>(
    base?: I,
  ): QueryBurnPointsCandidatesRequest {
    return QueryBurnPointsCandidatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryBurnPointsCandidatesRequest>, I>>(
    object: I,
  ): QueryBurnPointsCandidatesRequest {
    const message = createBaseQueryBurnPointsCandidatesRequest();
    message.queryCandidates = object.queryCandidates ?? false;
    message.queryCandidatesInMyd2 = object.queryCandidatesInMyd2 ?? false;
    message.startIdx = object.startIdx ?? 0;
    message.endIdx = object.endIdx ?? 0;
    return message;
  },
};

function createBaseQueryBurnPointCandidatesResponse(): QueryBurnPointCandidatesResponse {
  return { candidates: {}, candidatesInMyd2: {} };
}

export const QueryBurnPointCandidatesResponse = {
  encode(message: QueryBurnPointCandidatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.candidates).forEach(([key, value]) => {
      QueryBurnPointCandidatesResponse_CandidatesEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    Object.entries(message.candidatesInMyd2).forEach(([key, value]) => {
      QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBurnPointCandidatesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBurnPointCandidatesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = QueryBurnPointCandidatesResponse_CandidatesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.candidates[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.candidatesInMyd2[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryBurnPointCandidatesResponse {
    return {
      candidates: isObject(object.candidates)
        ? Object.entries(object.candidates).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      candidatesInMyd2: isObject(object.candidatesInMyd2)
        ? Object.entries(object.candidatesInMyd2).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QueryBurnPointCandidatesResponse): unknown {
    const obj: any = {};
    if (message.candidates) {
      const entries = Object.entries(message.candidates);
      if (entries.length > 0) {
        obj.candidates = {};
        entries.forEach(([k, v]) => {
          obj.candidates[k] = Math.round(v);
        });
      }
    }
    if (message.candidatesInMyd2) {
      const entries = Object.entries(message.candidatesInMyd2);
      if (entries.length > 0) {
        obj.candidatesInMyd2 = {};
        entries.forEach(([k, v]) => {
          obj.candidatesInMyd2[k] = Math.round(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryBurnPointCandidatesResponse>, I>>(
    base?: I,
  ): QueryBurnPointCandidatesResponse {
    return QueryBurnPointCandidatesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryBurnPointCandidatesResponse>, I>>(
    object: I,
  ): QueryBurnPointCandidatesResponse {
    const message = createBaseQueryBurnPointCandidatesResponse();
    message.candidates = Object.entries(object.candidates ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.candidatesInMyd2 = Object.entries(object.candidatesInMyd2 ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseQueryBurnPointCandidatesResponse_CandidatesEntry(): QueryBurnPointCandidatesResponse_CandidatesEntry {
  return { key: "", value: 0 };
}

export const QueryBurnPointCandidatesResponse_CandidatesEntry = {
  encode(
    message: QueryBurnPointCandidatesResponse_CandidatesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBurnPointCandidatesResponse_CandidatesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBurnPointCandidatesResponse_CandidatesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryBurnPointCandidatesResponse_CandidatesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: QueryBurnPointCandidatesResponse_CandidatesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryBurnPointCandidatesResponse_CandidatesEntry>, I>>(
    base?: I,
  ): QueryBurnPointCandidatesResponse_CandidatesEntry {
    return QueryBurnPointCandidatesResponse_CandidatesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryBurnPointCandidatesResponse_CandidatesEntry>, I>>(
    object: I,
  ): QueryBurnPointCandidatesResponse_CandidatesEntry {
    const message = createBaseQueryBurnPointCandidatesResponse_CandidatesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseQueryBurnPointCandidatesResponse_CandidatesInMyd2Entry(): QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry {
  return { key: "", value: 0 };
}

export const QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry = {
  encode(
    message: QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBurnPointCandidatesResponse_CandidatesInMyd2Entry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry>, I>>(
    base?: I,
  ): QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry {
    return QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry>, I>>(
    object: I,
  ): QueryBurnPointCandidatesResponse_CandidatesInMyd2Entry {
    const message = createBaseQueryBurnPointCandidatesResponse_CandidatesInMyd2Entry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseRegisterProposalRequest(): RegisterProposalRequest {
  return { proposalRequestId: 0, proposalStatic: undefined };
}

export const RegisterProposalRequest = {
  encode(message: RegisterProposalRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalRequestId !== 0) {
      writer.uint32(8).uint64(message.proposalRequestId);
    }
    if (message.proposalStatic !== undefined) {
      ProposalStatic.encode(message.proposalStatic, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterProposalRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterProposalRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.proposalRequestId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalStatic = ProposalStatic.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterProposalRequest {
    return {
      proposalRequestId: isSet(object.proposalRequestId) ? globalThis.Number(object.proposalRequestId) : 0,
      proposalStatic: isSet(object.proposalStatic) ? ProposalStatic.fromJSON(object.proposalStatic) : undefined,
    };
  },

  toJSON(message: RegisterProposalRequest): unknown {
    const obj: any = {};
    if (message.proposalRequestId !== 0) {
      obj.proposalRequestId = Math.round(message.proposalRequestId);
    }
    if (message.proposalStatic !== undefined) {
      obj.proposalStatic = ProposalStatic.toJSON(message.proposalStatic);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterProposalRequest>, I>>(base?: I): RegisterProposalRequest {
    return RegisterProposalRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterProposalRequest>, I>>(object: I): RegisterProposalRequest {
    const message = createBaseRegisterProposalRequest();
    message.proposalRequestId = object.proposalRequestId ?? 0;
    message.proposalStatic = (object.proposalStatic !== undefined && object.proposalStatic !== null)
      ? ProposalStatic.fromPartial(object.proposalStatic)
      : undefined;
    return message;
  },
};

function createBaseRegisterProposalResponse(): RegisterProposalResponse {
  return { statusCode: 0, proposalId: "" };
}

export const RegisterProposalResponse = {
  encode(message: RegisterProposalResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.proposalId !== "") {
      writer.uint32(18).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterProposalResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterProposalResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterProposalResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
    };
  },

  toJSON(message: RegisterProposalResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterProposalResponse>, I>>(base?: I): RegisterProposalResponse {
    return RegisterProposalResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterProposalResponse>, I>>(object: I): RegisterProposalResponse {
    const message = createBaseRegisterProposalResponse();
    message.statusCode = object.statusCode ?? 0;
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseWebResourceConfig(): WebResourceConfig {
  return { fileUrlPath: [] };
}

export const WebResourceConfig = {
  encode(message: WebResourceConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fileUrlPath) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebResourceConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebResourceConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fileUrlPath.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebResourceConfig {
    return {
      fileUrlPath: globalThis.Array.isArray(object?.fileUrlPath)
        ? object.fileUrlPath.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: WebResourceConfig): unknown {
    const obj: any = {};
    if (message.fileUrlPath?.length) {
      obj.fileUrlPath = message.fileUrlPath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebResourceConfig>, I>>(base?: I): WebResourceConfig {
    return WebResourceConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebResourceConfig>, I>>(object: I): WebResourceConfig {
    const message = createBaseWebResourceConfig();
    message.fileUrlPath = object.fileUrlPath?.map((e) => e) || [];
    return message;
  },
};

function createBaseSavedBuilableResource(): SavedBuilableResource {
  return {
    resourceNo: 0,
    resourceId: "",
    title: "",
    isFinal: false,
    isHidden: false,
    isPublished: false,
    createdBy: "",
    resourceType: 0,
    destinationPath: undefined,
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    appVersion: undefined,
    homeBanner: undefined,
    webResource: undefined,
    applicationResource: undefined,
  };
}

export const SavedBuilableResource = {
  encode(message: SavedBuilableResource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resourceNo !== 0) {
      writer.uint32(8).uint64(message.resourceNo);
    }
    if (message.resourceId !== "") {
      writer.uint32(18).string(message.resourceId);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.isFinal === true) {
      writer.uint32(32).bool(message.isFinal);
    }
    if (message.isHidden === true) {
      writer.uint32(40).bool(message.isHidden);
    }
    if (message.isPublished === true) {
      writer.uint32(48).bool(message.isPublished);
    }
    if (message.createdBy !== "") {
      writer.uint32(58).string(message.createdBy);
    }
    if (message.resourceType !== 0) {
      writer.uint32(64).int32(message.resourceType);
    }
    if (message.destinationPath !== undefined) {
      writer.uint32(74).string(message.destinationPath);
    }
    if (message.createdAt !== "") {
      writer.uint32(82).string(message.createdAt);
    }
    if (message.updatedAt !== "") {
      writer.uint32(90).string(message.updatedAt);
    }
    if (message.publishedAt !== "") {
      writer.uint32(98).string(message.publishedAt);
    }
    if (message.appVersion !== undefined) {
      AppVersionConfig.encode(message.appVersion, writer.uint32(162).fork()).ldelim();
    }
    if (message.homeBanner !== undefined) {
      HomeBannerConfig.encode(message.homeBanner, writer.uint32(170).fork()).ldelim();
    }
    if (message.webResource !== undefined) {
      WebResourceConfig.encode(message.webResource, writer.uint32(178).fork()).ldelim();
    }
    if (message.applicationResource !== undefined) {
      ApplicationResource.encode(message.applicationResource, writer.uint32(186).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SavedBuilableResource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSavedBuilableResource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resourceNo = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.resourceId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isFinal = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isHidden = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isPublished = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.resourceType = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.destinationPath = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.publishedAt = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.appVersion = AppVersionConfig.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.homeBanner = HomeBannerConfig.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.webResource = WebResourceConfig.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.applicationResource = ApplicationResource.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SavedBuilableResource {
    return {
      resourceNo: isSet(object.resourceNo) ? globalThis.Number(object.resourceNo) : 0,
      resourceId: isSet(object.resourceId) ? globalThis.String(object.resourceId) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      isFinal: isSet(object.isFinal) ? globalThis.Boolean(object.isFinal) : false,
      isHidden: isSet(object.isHidden) ? globalThis.Boolean(object.isHidden) : false,
      isPublished: isSet(object.isPublished) ? globalThis.Boolean(object.isPublished) : false,
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      resourceType: isSet(object.resourceType) ? buildableResourceTypeFromJSON(object.resourceType) : 0,
      destinationPath: isSet(object.destinationPath) ? globalThis.String(object.destinationPath) : undefined,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : "",
      publishedAt: isSet(object.publishedAt) ? globalThis.String(object.publishedAt) : "",
      appVersion: isSet(object.appVersion) ? AppVersionConfig.fromJSON(object.appVersion) : undefined,
      homeBanner: isSet(object.homeBanner) ? HomeBannerConfig.fromJSON(object.homeBanner) : undefined,
      webResource: isSet(object.webResource) ? WebResourceConfig.fromJSON(object.webResource) : undefined,
      applicationResource: isSet(object.applicationResource)
        ? ApplicationResource.fromJSON(object.applicationResource)
        : undefined,
    };
  },

  toJSON(message: SavedBuilableResource): unknown {
    const obj: any = {};
    if (message.resourceNo !== 0) {
      obj.resourceNo = Math.round(message.resourceNo);
    }
    if (message.resourceId !== "") {
      obj.resourceId = message.resourceId;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.isFinal === true) {
      obj.isFinal = message.isFinal;
    }
    if (message.isHidden === true) {
      obj.isHidden = message.isHidden;
    }
    if (message.isPublished === true) {
      obj.isPublished = message.isPublished;
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.resourceType !== 0) {
      obj.resourceType = buildableResourceTypeToJSON(message.resourceType);
    }
    if (message.destinationPath !== undefined) {
      obj.destinationPath = message.destinationPath;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.updatedAt !== "") {
      obj.updatedAt = message.updatedAt;
    }
    if (message.publishedAt !== "") {
      obj.publishedAt = message.publishedAt;
    }
    if (message.appVersion !== undefined) {
      obj.appVersion = AppVersionConfig.toJSON(message.appVersion);
    }
    if (message.homeBanner !== undefined) {
      obj.homeBanner = HomeBannerConfig.toJSON(message.homeBanner);
    }
    if (message.webResource !== undefined) {
      obj.webResource = WebResourceConfig.toJSON(message.webResource);
    }
    if (message.applicationResource !== undefined) {
      obj.applicationResource = ApplicationResource.toJSON(message.applicationResource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SavedBuilableResource>, I>>(base?: I): SavedBuilableResource {
    return SavedBuilableResource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SavedBuilableResource>, I>>(object: I): SavedBuilableResource {
    const message = createBaseSavedBuilableResource();
    message.resourceNo = object.resourceNo ?? 0;
    message.resourceId = object.resourceId ?? "";
    message.title = object.title ?? "";
    message.isFinal = object.isFinal ?? false;
    message.isHidden = object.isHidden ?? false;
    message.isPublished = object.isPublished ?? false;
    message.createdBy = object.createdBy ?? "";
    message.resourceType = object.resourceType ?? 0;
    message.destinationPath = object.destinationPath ?? undefined;
    message.createdAt = object.createdAt ?? "";
    message.updatedAt = object.updatedAt ?? "";
    message.publishedAt = object.publishedAt ?? "";
    message.appVersion = (object.appVersion !== undefined && object.appVersion !== null)
      ? AppVersionConfig.fromPartial(object.appVersion)
      : undefined;
    message.homeBanner = (object.homeBanner !== undefined && object.homeBanner !== null)
      ? HomeBannerConfig.fromPartial(object.homeBanner)
      : undefined;
    message.webResource = (object.webResource !== undefined && object.webResource !== null)
      ? WebResourceConfig.fromPartial(object.webResource)
      : undefined;
    message.applicationResource = (object.applicationResource !== undefined && object.applicationResource !== null)
      ? ApplicationResource.fromPartial(object.applicationResource)
      : undefined;
    return message;
  },
};

function createBaseBuildableResourceSaveRequest(): BuildableResourceSaveRequest {
  return {
    resourceNo: undefined,
    title: "",
    isFinal: false,
    isHidden: false,
    resourceType: 0,
    destinationPath: undefined,
    appVersion: undefined,
    homeBanner: undefined,
    applicationResource: undefined,
  };
}

export const BuildableResourceSaveRequest = {
  encode(message: BuildableResourceSaveRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resourceNo !== undefined) {
      writer.uint32(8).uint64(message.resourceNo);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.isFinal === true) {
      writer.uint32(24).bool(message.isFinal);
    }
    if (message.isHidden === true) {
      writer.uint32(32).bool(message.isHidden);
    }
    if (message.resourceType !== 0) {
      writer.uint32(40).int32(message.resourceType);
    }
    if (message.destinationPath !== undefined) {
      writer.uint32(50).string(message.destinationPath);
    }
    if (message.appVersion !== undefined) {
      AppVersionConfig.encode(message.appVersion, writer.uint32(162).fork()).ldelim();
    }
    if (message.homeBanner !== undefined) {
      HomeBannerConfig.encode(message.homeBanner, writer.uint32(170).fork()).ldelim();
    }
    if (message.applicationResource !== undefined) {
      ApplicationResource.encode(message.applicationResource, writer.uint32(186).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuildableResourceSaveRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuildableResourceSaveRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resourceNo = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isFinal = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isHidden = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.resourceType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.destinationPath = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.appVersion = AppVersionConfig.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.homeBanner = HomeBannerConfig.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.applicationResource = ApplicationResource.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BuildableResourceSaveRequest {
    return {
      resourceNo: isSet(object.resourceNo) ? globalThis.Number(object.resourceNo) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      isFinal: isSet(object.isFinal) ? globalThis.Boolean(object.isFinal) : false,
      isHidden: isSet(object.isHidden) ? globalThis.Boolean(object.isHidden) : false,
      resourceType: isSet(object.resourceType) ? buildableResourceTypeFromJSON(object.resourceType) : 0,
      destinationPath: isSet(object.destinationPath) ? globalThis.String(object.destinationPath) : undefined,
      appVersion: isSet(object.appVersion) ? AppVersionConfig.fromJSON(object.appVersion) : undefined,
      homeBanner: isSet(object.homeBanner) ? HomeBannerConfig.fromJSON(object.homeBanner) : undefined,
      applicationResource: isSet(object.applicationResource)
        ? ApplicationResource.fromJSON(object.applicationResource)
        : undefined,
    };
  },

  toJSON(message: BuildableResourceSaveRequest): unknown {
    const obj: any = {};
    if (message.resourceNo !== undefined) {
      obj.resourceNo = Math.round(message.resourceNo);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.isFinal === true) {
      obj.isFinal = message.isFinal;
    }
    if (message.isHidden === true) {
      obj.isHidden = message.isHidden;
    }
    if (message.resourceType !== 0) {
      obj.resourceType = buildableResourceTypeToJSON(message.resourceType);
    }
    if (message.destinationPath !== undefined) {
      obj.destinationPath = message.destinationPath;
    }
    if (message.appVersion !== undefined) {
      obj.appVersion = AppVersionConfig.toJSON(message.appVersion);
    }
    if (message.homeBanner !== undefined) {
      obj.homeBanner = HomeBannerConfig.toJSON(message.homeBanner);
    }
    if (message.applicationResource !== undefined) {
      obj.applicationResource = ApplicationResource.toJSON(message.applicationResource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BuildableResourceSaveRequest>, I>>(base?: I): BuildableResourceSaveRequest {
    return BuildableResourceSaveRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BuildableResourceSaveRequest>, I>>(object: I): BuildableResourceSaveRequest {
    const message = createBaseBuildableResourceSaveRequest();
    message.resourceNo = object.resourceNo ?? undefined;
    message.title = object.title ?? "";
    message.isFinal = object.isFinal ?? false;
    message.isHidden = object.isHidden ?? false;
    message.resourceType = object.resourceType ?? 0;
    message.destinationPath = object.destinationPath ?? undefined;
    message.appVersion = (object.appVersion !== undefined && object.appVersion !== null)
      ? AppVersionConfig.fromPartial(object.appVersion)
      : undefined;
    message.homeBanner = (object.homeBanner !== undefined && object.homeBanner !== null)
      ? HomeBannerConfig.fromPartial(object.homeBanner)
      : undefined;
    message.applicationResource = (object.applicationResource !== undefined && object.applicationResource !== null)
      ? ApplicationResource.fromPartial(object.applicationResource)
      : undefined;
    return message;
  },
};

function createBaseBuildableResourceSaveResponse(): BuildableResourceSaveResponse {
  return { statusCode: 0, savedBuilableResource: undefined };
}

export const BuildableResourceSaveResponse = {
  encode(message: BuildableResourceSaveResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.savedBuilableResource !== undefined) {
      SavedBuilableResource.encode(message.savedBuilableResource, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuildableResourceSaveResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuildableResourceSaveResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.savedBuilableResource = SavedBuilableResource.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BuildableResourceSaveResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      savedBuilableResource: isSet(object.savedBuilableResource)
        ? SavedBuilableResource.fromJSON(object.savedBuilableResource)
        : undefined,
    };
  },

  toJSON(message: BuildableResourceSaveResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.savedBuilableResource !== undefined) {
      obj.savedBuilableResource = SavedBuilableResource.toJSON(message.savedBuilableResource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BuildableResourceSaveResponse>, I>>(base?: I): BuildableResourceSaveResponse {
    return BuildableResourceSaveResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BuildableResourceSaveResponse>, I>>(
    object: I,
  ): BuildableResourceSaveResponse {
    const message = createBaseBuildableResourceSaveResponse();
    message.statusCode = object.statusCode ?? 0;
    message.savedBuilableResource =
      (object.savedBuilableResource !== undefined && object.savedBuilableResource !== null)
        ? SavedBuilableResource.fromPartial(object.savedBuilableResource)
        : undefined;
    return message;
  },
};

function createBaseBuildableResourceListReqeust(): BuildableResourceListReqeust {
  return { buildableResourceType: [], pageSize: 0, pageNum: 0 };
}

export const BuildableResourceListReqeust = {
  encode(message: BuildableResourceListReqeust, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.buildableResourceType) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.pageSize !== 0) {
      writer.uint32(16).uint32(message.pageSize);
    }
    if (message.pageNum !== 0) {
      writer.uint32(24).uint32(message.pageNum);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuildableResourceListReqeust {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuildableResourceListReqeust();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.buildableResourceType.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.buildableResourceType.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pageNum = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BuildableResourceListReqeust {
    return {
      buildableResourceType: globalThis.Array.isArray(object?.buildableResourceType)
        ? object.buildableResourceType.map((e: any) => buildableResourceTypeFromJSON(e))
        : [],
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageNum: isSet(object.pageNum) ? globalThis.Number(object.pageNum) : 0,
    };
  },

  toJSON(message: BuildableResourceListReqeust): unknown {
    const obj: any = {};
    if (message.buildableResourceType?.length) {
      obj.buildableResourceType = message.buildableResourceType.map((e) => buildableResourceTypeToJSON(e));
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNum !== 0) {
      obj.pageNum = Math.round(message.pageNum);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BuildableResourceListReqeust>, I>>(base?: I): BuildableResourceListReqeust {
    return BuildableResourceListReqeust.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BuildableResourceListReqeust>, I>>(object: I): BuildableResourceListReqeust {
    const message = createBaseBuildableResourceListReqeust();
    message.buildableResourceType = object.buildableResourceType?.map((e) => e) || [];
    message.pageSize = object.pageSize ?? 0;
    message.pageNum = object.pageNum ?? 0;
    return message;
  },
};

function createBaseBuildableResourceListResponse(): BuildableResourceListResponse {
  return { statusCode: 0, savedBuilableResource: [] };
}

export const BuildableResourceListResponse = {
  encode(message: BuildableResourceListResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    for (const v of message.savedBuilableResource) {
      SavedBuilableResource.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuildableResourceListResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuildableResourceListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.savedBuilableResource.push(SavedBuilableResource.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BuildableResourceListResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      savedBuilableResource: globalThis.Array.isArray(object?.savedBuilableResource)
        ? object.savedBuilableResource.map((e: any) => SavedBuilableResource.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BuildableResourceListResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.savedBuilableResource?.length) {
      obj.savedBuilableResource = message.savedBuilableResource.map((e) => SavedBuilableResource.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BuildableResourceListResponse>, I>>(base?: I): BuildableResourceListResponse {
    return BuildableResourceListResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BuildableResourceListResponse>, I>>(
    object: I,
  ): BuildableResourceListResponse {
    const message = createBaseBuildableResourceListResponse();
    message.statusCode = object.statusCode ?? 0;
    message.savedBuilableResource = object.savedBuilableResource?.map((e) => SavedBuilableResource.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseBuildableResourcePublishRequest(): BuildableResourcePublishRequest {
  return { resourceNo: 0 };
}

export const BuildableResourcePublishRequest = {
  encode(message: BuildableResourcePublishRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resourceNo !== 0) {
      writer.uint32(8).uint64(message.resourceNo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuildableResourcePublishRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuildableResourcePublishRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resourceNo = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BuildableResourcePublishRequest {
    return { resourceNo: isSet(object.resourceNo) ? globalThis.Number(object.resourceNo) : 0 };
  },

  toJSON(message: BuildableResourcePublishRequest): unknown {
    const obj: any = {};
    if (message.resourceNo !== 0) {
      obj.resourceNo = Math.round(message.resourceNo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BuildableResourcePublishRequest>, I>>(base?: I): BuildableResourcePublishRequest {
    return BuildableResourcePublishRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BuildableResourcePublishRequest>, I>>(
    object: I,
  ): BuildableResourcePublishRequest {
    const message = createBaseBuildableResourcePublishRequest();
    message.resourceNo = object.resourceNo ?? 0;
    return message;
  },
};

function createBaseBuildableResourcePublishResponse(): BuildableResourcePublishResponse {
  return { code: 0 };
}

export const BuildableResourcePublishResponse = {
  encode(message: BuildableResourcePublishResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuildableResourcePublishResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuildableResourcePublishResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BuildableResourcePublishResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: BuildableResourcePublishResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BuildableResourcePublishResponse>, I>>(
    base?: I,
  ): BuildableResourcePublishResponse {
    return BuildableResourcePublishResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BuildableResourcePublishResponse>, I>>(
    object: I,
  ): BuildableResourcePublishResponse {
    const message = createBaseBuildableResourcePublishResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseCouponManagerRequest(): CouponManagerRequest {
  return { couponRequestType: 0, couponRequestParameterType: 0, parameterList: [] };
}

export const CouponManagerRequest = {
  encode(message: CouponManagerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.couponRequestType !== 0) {
      writer.uint32(8).int32(message.couponRequestType);
    }
    if (message.couponRequestParameterType !== 0) {
      writer.uint32(16).int32(message.couponRequestParameterType);
    }
    for (const v of message.parameterList) {
      writer.uint32(90).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CouponManagerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCouponManagerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.couponRequestType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.couponRequestParameterType = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.parameterList.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CouponManagerRequest {
    return {
      couponRequestType: isSet(object.couponRequestType)
        ? couponManagerRequestTypeFromJSON(object.couponRequestType)
        : 0,
      couponRequestParameterType: isSet(object.couponRequestParameterType)
        ? couponRequestParameterTypeFromJSON(object.couponRequestParameterType)
        : 0,
      parameterList: globalThis.Array.isArray(object?.parameterList)
        ? object.parameterList.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: CouponManagerRequest): unknown {
    const obj: any = {};
    if (message.couponRequestType !== 0) {
      obj.couponRequestType = couponManagerRequestTypeToJSON(message.couponRequestType);
    }
    if (message.couponRequestParameterType !== 0) {
      obj.couponRequestParameterType = couponRequestParameterTypeToJSON(message.couponRequestParameterType);
    }
    if (message.parameterList?.length) {
      obj.parameterList = message.parameterList;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CouponManagerRequest>, I>>(base?: I): CouponManagerRequest {
    return CouponManagerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CouponManagerRequest>, I>>(object: I): CouponManagerRequest {
    const message = createBaseCouponManagerRequest();
    message.couponRequestType = object.couponRequestType ?? 0;
    message.couponRequestParameterType = object.couponRequestParameterType ?? 0;
    message.parameterList = object.parameterList?.map((e) => e) || [];
    return message;
  },
};

function createBaseCouponManagerRevokeInfo(): CouponManagerRevokeInfo {
  return { code: "", message: "", couponTransactionId: "", couponInfo: undefined };
}

export const CouponManagerRevokeInfo = {
  encode(message: CouponManagerRevokeInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    if (message.couponTransactionId !== "") {
      writer.uint32(26).string(message.couponTransactionId);
    }
    if (message.couponInfo !== undefined) {
      CouponInfo.encode(message.couponInfo, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CouponManagerRevokeInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCouponManagerRevokeInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.couponTransactionId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.couponInfo = CouponInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CouponManagerRevokeInfo {
    return {
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      couponTransactionId: isSet(object.couponTransactionId) ? globalThis.String(object.couponTransactionId) : "",
      couponInfo: isSet(object.couponInfo) ? CouponInfo.fromJSON(object.couponInfo) : undefined,
    };
  },

  toJSON(message: CouponManagerRevokeInfo): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.couponTransactionId !== "") {
      obj.couponTransactionId = message.couponTransactionId;
    }
    if (message.couponInfo !== undefined) {
      obj.couponInfo = CouponInfo.toJSON(message.couponInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CouponManagerRevokeInfo>, I>>(base?: I): CouponManagerRevokeInfo {
    return CouponManagerRevokeInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CouponManagerRevokeInfo>, I>>(object: I): CouponManagerRevokeInfo {
    const message = createBaseCouponManagerRevokeInfo();
    message.code = object.code ?? "";
    message.message = object.message ?? "";
    message.couponTransactionId = object.couponTransactionId ?? "";
    message.couponInfo = (object.couponInfo !== undefined && object.couponInfo !== null)
      ? CouponInfo.fromPartial(object.couponInfo)
      : undefined;
    return message;
  },
};

function createBaseCouponManagerResponse(): CouponManagerResponse {
  return { code: 0, couponDetails: [], couponInfoList: [], couponManagerRevokeInfo: [] };
}

export const CouponManagerResponse = {
  encode(message: CouponManagerResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.couponDetails) {
      CouponInfoDetails.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.couponInfoList) {
      CouponInfo.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.couponManagerRevokeInfo) {
      CouponManagerRevokeInfo.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CouponManagerResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCouponManagerResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.couponDetails.push(CouponInfoDetails.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.couponInfoList.push(CouponInfo.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.couponManagerRevokeInfo.push(CouponManagerRevokeInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CouponManagerResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      couponDetails: globalThis.Array.isArray(object?.couponDetails)
        ? object.couponDetails.map((e: any) => CouponInfoDetails.fromJSON(e))
        : [],
      couponInfoList: globalThis.Array.isArray(object?.couponInfoList)
        ? object.couponInfoList.map((e: any) => CouponInfo.fromJSON(e))
        : [],
      couponManagerRevokeInfo: globalThis.Array.isArray(object?.couponManagerRevokeInfo)
        ? object.couponManagerRevokeInfo.map((e: any) => CouponManagerRevokeInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CouponManagerResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.couponDetails?.length) {
      obj.couponDetails = message.couponDetails.map((e) => CouponInfoDetails.toJSON(e));
    }
    if (message.couponInfoList?.length) {
      obj.couponInfoList = message.couponInfoList.map((e) => CouponInfo.toJSON(e));
    }
    if (message.couponManagerRevokeInfo?.length) {
      obj.couponManagerRevokeInfo = message.couponManagerRevokeInfo.map((e) => CouponManagerRevokeInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CouponManagerResponse>, I>>(base?: I): CouponManagerResponse {
    return CouponManagerResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CouponManagerResponse>, I>>(object: I): CouponManagerResponse {
    const message = createBaseCouponManagerResponse();
    message.code = object.code ?? 0;
    message.couponDetails = object.couponDetails?.map((e) => CouponInfoDetails.fromPartial(e)) || [];
    message.couponInfoList = object.couponInfoList?.map((e) => CouponInfo.fromPartial(e)) || [];
    message.couponManagerRevokeInfo =
      object.couponManagerRevokeInfo?.map((e) => CouponManagerRevokeInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseElasticSearchQueryRunnerRequest(): ElasticSearchQueryRunnerRequest {
  return { template: undefined, dataInJson: "" };
}

export const ElasticSearchQueryRunnerRequest = {
  encode(message: ElasticSearchQueryRunnerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.template !== undefined) {
      ElasticSearchQueryTemplate.encode(message.template, writer.uint32(10).fork()).ldelim();
    }
    if (message.dataInJson !== "") {
      writer.uint32(18).string(message.dataInJson);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ElasticSearchQueryRunnerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElasticSearchQueryRunnerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.template = ElasticSearchQueryTemplate.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dataInJson = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ElasticSearchQueryRunnerRequest {
    return {
      template: isSet(object.template) ? ElasticSearchQueryTemplate.fromJSON(object.template) : undefined,
      dataInJson: isSet(object.dataInJson) ? globalThis.String(object.dataInJson) : "",
    };
  },

  toJSON(message: ElasticSearchQueryRunnerRequest): unknown {
    const obj: any = {};
    if (message.template !== undefined) {
      obj.template = ElasticSearchQueryTemplate.toJSON(message.template);
    }
    if (message.dataInJson !== "") {
      obj.dataInJson = message.dataInJson;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ElasticSearchQueryRunnerRequest>, I>>(base?: I): ElasticSearchQueryRunnerRequest {
    return ElasticSearchQueryRunnerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ElasticSearchQueryRunnerRequest>, I>>(
    object: I,
  ): ElasticSearchQueryRunnerRequest {
    const message = createBaseElasticSearchQueryRunnerRequest();
    message.template = (object.template !== undefined && object.template !== null)
      ? ElasticSearchQueryTemplate.fromPartial(object.template)
      : undefined;
    message.dataInJson = object.dataInJson ?? "";
    return message;
  },
};

function createBaseElasticSearchQueryRunnerResponse(): ElasticSearchQueryRunnerResponse {
  return { code: 0, result: undefined, unusedPatterns: [], targetQuery: undefined };
}

export const ElasticSearchQueryRunnerResponse = {
  encode(message: ElasticSearchQueryRunnerResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.result !== undefined) {
      ElasticSearchQueryResult.encode(message.result, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.unusedPatterns) {
      writer.uint32(26).string(v!);
    }
    if (message.targetQuery !== undefined) {
      writer.uint32(34).string(message.targetQuery);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ElasticSearchQueryRunnerResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElasticSearchQueryRunnerResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.result = ElasticSearchQueryResult.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.unusedPatterns.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.targetQuery = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ElasticSearchQueryRunnerResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      result: isSet(object.result) ? ElasticSearchQueryResult.fromJSON(object.result) : undefined,
      unusedPatterns: globalThis.Array.isArray(object?.unusedPatterns)
        ? object.unusedPatterns.map((e: any) => globalThis.String(e))
        : [],
      targetQuery: isSet(object.targetQuery) ? globalThis.String(object.targetQuery) : undefined,
    };
  },

  toJSON(message: ElasticSearchQueryRunnerResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.result !== undefined) {
      obj.result = ElasticSearchQueryResult.toJSON(message.result);
    }
    if (message.unusedPatterns?.length) {
      obj.unusedPatterns = message.unusedPatterns;
    }
    if (message.targetQuery !== undefined) {
      obj.targetQuery = message.targetQuery;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ElasticSearchQueryRunnerResponse>, I>>(
    base?: I,
  ): ElasticSearchQueryRunnerResponse {
    return ElasticSearchQueryRunnerResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ElasticSearchQueryRunnerResponse>, I>>(
    object: I,
  ): ElasticSearchQueryRunnerResponse {
    const message = createBaseElasticSearchQueryRunnerResponse();
    message.code = object.code ?? 0;
    message.result = (object.result !== undefined && object.result !== null)
      ? ElasticSearchQueryResult.fromPartial(object.result)
      : undefined;
    message.unusedPatterns = object.unusedPatterns?.map((e) => e) || [];
    message.targetQuery = object.targetQuery ?? undefined;
    return message;
  },
};

function createBaseDailyReportRequest(): DailyReportRequest {
  return { notBefore: "", notAfter: "" };
}

export const DailyReportRequest = {
  encode(message: DailyReportRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.notBefore !== "") {
      writer.uint32(10).string(message.notBefore);
    }
    if (message.notAfter !== "") {
      writer.uint32(18).string(message.notAfter);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DailyReportRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyReportRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notBefore = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.notAfter = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyReportRequest {
    return {
      notBefore: isSet(object.notBefore) ? globalThis.String(object.notBefore) : "",
      notAfter: isSet(object.notAfter) ? globalThis.String(object.notAfter) : "",
    };
  },

  toJSON(message: DailyReportRequest): unknown {
    const obj: any = {};
    if (message.notBefore !== "") {
      obj.notBefore = message.notBefore;
    }
    if (message.notAfter !== "") {
      obj.notAfter = message.notAfter;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyReportRequest>, I>>(base?: I): DailyReportRequest {
    return DailyReportRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyReportRequest>, I>>(object: I): DailyReportRequest {
    const message = createBaseDailyReportRequest();
    message.notBefore = object.notBefore ?? "";
    message.notAfter = object.notAfter ?? "";
    return message;
  },
};

function createBaseDailyReportResponse(): DailyReportResponse {
  return { code: 0, proposalIdList: [] };
}

export const DailyReportResponse = {
  encode(message: DailyReportResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.proposalIdList) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DailyReportResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyReportResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalIdList.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyReportResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      proposalIdList: globalThis.Array.isArray(object?.proposalIdList)
        ? object.proposalIdList.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: DailyReportResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.proposalIdList?.length) {
      obj.proposalIdList = message.proposalIdList;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyReportResponse>, I>>(base?: I): DailyReportResponse {
    return DailyReportResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyReportResponse>, I>>(object: I): DailyReportResponse {
    const message = createBaseDailyReportResponse();
    message.code = object.code ?? 0;
    message.proposalIdList = object.proposalIdList?.map((e) => e) || [];
    return message;
  },
};

function createBaseProposalReportRequest(): ProposalReportRequest {
  return { proposalIds: [] };
}

export const ProposalReportRequest = {
  encode(message: ProposalReportRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.proposalIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalReportRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalReportRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposalIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalReportRequest {
    return {
      proposalIds: globalThis.Array.isArray(object?.proposalIds)
        ? object.proposalIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ProposalReportRequest): unknown {
    const obj: any = {};
    if (message.proposalIds?.length) {
      obj.proposalIds = message.proposalIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalReportRequest>, I>>(base?: I): ProposalReportRequest {
    return ProposalReportRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalReportRequest>, I>>(object: I): ProposalReportRequest {
    const message = createBaseProposalReportRequest();
    message.proposalIds = object.proposalIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseProposalReportResponse(): ProposalReportResponse {
  return { code: 0, proposalReport: [] };
}

export const ProposalReportResponse = {
  encode(message: ProposalReportResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.proposalReport) {
      ProposalReport.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalReportResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalReportResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalReport.push(ProposalReport.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalReportResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      proposalReport: globalThis.Array.isArray(object?.proposalReport)
        ? object.proposalReport.map((e: any) => ProposalReport.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProposalReportResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.proposalReport?.length) {
      obj.proposalReport = message.proposalReport.map((e) => ProposalReport.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalReportResponse>, I>>(base?: I): ProposalReportResponse {
    return ProposalReportResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalReportResponse>, I>>(object: I): ProposalReportResponse {
    const message = createBaseProposalReportResponse();
    message.code = object.code ?? 0;
    message.proposalReport = object.proposalReport?.map((e) => ProposalReport.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProposalManagementRedisCleanupRequest(): ProposalManagementRedisCleanupRequest {
  return { proposalId: "" };
}

export const ProposalManagementRedisCleanupRequest = {
  encode(message: ProposalManagementRedisCleanupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId !== "") {
      writer.uint32(10).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalManagementRedisCleanupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalManagementRedisCleanupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalManagementRedisCleanupRequest {
    return { proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "" };
  },

  toJSON(message: ProposalManagementRedisCleanupRequest): unknown {
    const obj: any = {};
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalManagementRedisCleanupRequest>, I>>(
    base?: I,
  ): ProposalManagementRedisCleanupRequest {
    return ProposalManagementRedisCleanupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalManagementRedisCleanupRequest>, I>>(
    object: I,
  ): ProposalManagementRedisCleanupRequest {
    const message = createBaseProposalManagementRedisCleanupRequest();
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseProposalManagementRedisCleanupResponse(): ProposalManagementRedisCleanupResponse {
  return { code: 0, message: undefined, ticketReportInRedis: undefined };
}

export const ProposalManagementRedisCleanupResponse = {
  encode(message: ProposalManagementRedisCleanupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    if (message.ticketReportInRedis !== undefined) {
      TicketReportInRedis.encode(message.ticketReportInRedis, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalManagementRedisCleanupResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalManagementRedisCleanupResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ticketReportInRedis = TicketReportInRedis.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalManagementRedisCleanupResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
      ticketReportInRedis: isSet(object.ticketReportInRedis)
        ? TicketReportInRedis.fromJSON(object.ticketReportInRedis)
        : undefined,
    };
  },

  toJSON(message: ProposalManagementRedisCleanupResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.ticketReportInRedis !== undefined) {
      obj.ticketReportInRedis = TicketReportInRedis.toJSON(message.ticketReportInRedis);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalManagementRedisCleanupResponse>, I>>(
    base?: I,
  ): ProposalManagementRedisCleanupResponse {
    return ProposalManagementRedisCleanupResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalManagementRedisCleanupResponse>, I>>(
    object: I,
  ): ProposalManagementRedisCleanupResponse {
    const message = createBaseProposalManagementRedisCleanupResponse();
    message.code = object.code ?? 0;
    message.message = object.message ?? undefined;
    message.ticketReportInRedis = (object.ticketReportInRedis !== undefined && object.ticketReportInRedis !== null)
      ? TicketReportInRedis.fromPartial(object.ticketReportInRedis)
      : undefined;
    return message;
  },
};

function createBaseProposalReport(): ProposalReport {
  return {
    proposalId: "",
    proposalStatic: undefined,
    proposalActivateGlobal: undefined,
    ticketReportInRedis: undefined,
    ticketReportInDb: [],
  };
}

export const ProposalReport = {
  encode(message: ProposalReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId !== "") {
      writer.uint32(10).string(message.proposalId);
    }
    if (message.proposalStatic !== undefined) {
      ProposalStatic.encode(message.proposalStatic, writer.uint32(18).fork()).ldelim();
    }
    if (message.proposalActivateGlobal !== undefined) {
      ProposalActiveGlobal.encode(message.proposalActivateGlobal, writer.uint32(26).fork()).ldelim();
    }
    if (message.ticketReportInRedis !== undefined) {
      TicketReportInRedis.encode(message.ticketReportInRedis, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.ticketReportInDb) {
      TicketReportInDb.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalStatic = ProposalStatic.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proposalActivateGlobal = ProposalActiveGlobal.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ticketReportInRedis = TicketReportInRedis.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ticketReportInDb.push(TicketReportInDb.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalReport {
    return {
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
      proposalStatic: isSet(object.proposalStatic) ? ProposalStatic.fromJSON(object.proposalStatic) : undefined,
      proposalActivateGlobal: isSet(object.proposalActivateGlobal)
        ? ProposalActiveGlobal.fromJSON(object.proposalActivateGlobal)
        : undefined,
      ticketReportInRedis: isSet(object.ticketReportInRedis)
        ? TicketReportInRedis.fromJSON(object.ticketReportInRedis)
        : undefined,
      ticketReportInDb: globalThis.Array.isArray(object?.ticketReportInDb)
        ? object.ticketReportInDb.map((e: any) => TicketReportInDb.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProposalReport): unknown {
    const obj: any = {};
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.proposalStatic !== undefined) {
      obj.proposalStatic = ProposalStatic.toJSON(message.proposalStatic);
    }
    if (message.proposalActivateGlobal !== undefined) {
      obj.proposalActivateGlobal = ProposalActiveGlobal.toJSON(message.proposalActivateGlobal);
    }
    if (message.ticketReportInRedis !== undefined) {
      obj.ticketReportInRedis = TicketReportInRedis.toJSON(message.ticketReportInRedis);
    }
    if (message.ticketReportInDb?.length) {
      obj.ticketReportInDb = message.ticketReportInDb.map((e) => TicketReportInDb.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalReport>, I>>(base?: I): ProposalReport {
    return ProposalReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalReport>, I>>(object: I): ProposalReport {
    const message = createBaseProposalReport();
    message.proposalId = object.proposalId ?? "";
    message.proposalStatic = (object.proposalStatic !== undefined && object.proposalStatic !== null)
      ? ProposalStatic.fromPartial(object.proposalStatic)
      : undefined;
    message.proposalActivateGlobal =
      (object.proposalActivateGlobal !== undefined && object.proposalActivateGlobal !== null)
        ? ProposalActiveGlobal.fromPartial(object.proposalActivateGlobal)
        : undefined;
    message.ticketReportInRedis = (object.ticketReportInRedis !== undefined && object.ticketReportInRedis !== null)
      ? TicketReportInRedis.fromPartial(object.ticketReportInRedis)
      : undefined;
    message.ticketReportInDb = object.ticketReportInDb?.map((e) => TicketReportInDb.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTicketReportInRedis(): TicketReportInRedis {
  return { redisTicketCount: 0, values: [] };
}

export const TicketReportInRedis = {
  encode(message: TicketReportInRedis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.redisTicketCount !== 0) {
      writer.uint32(8).uint64(message.redisTicketCount);
    }
    for (const v of message.values) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TicketReportInRedis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTicketReportInRedis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.redisTicketCount = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.values.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TicketReportInRedis {
    return {
      redisTicketCount: isSet(object.redisTicketCount) ? globalThis.Number(object.redisTicketCount) : 0,
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: TicketReportInRedis): unknown {
    const obj: any = {};
    if (message.redisTicketCount !== 0) {
      obj.redisTicketCount = Math.round(message.redisTicketCount);
    }
    if (message.values?.length) {
      obj.values = message.values;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TicketReportInRedis>, I>>(base?: I): TicketReportInRedis {
    return TicketReportInRedis.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TicketReportInRedis>, I>>(object: I): TicketReportInRedis {
    const message = createBaseTicketReportInRedis();
    message.redisTicketCount = object.redisTicketCount ?? 0;
    message.values = object.values?.map((e) => e) || [];
    return message;
  },
};

function createBaseTicketReportInDb(): TicketReportInDb {
  return { owner: "", activeClient: undefined };
}

export const TicketReportInDb = {
  encode(message: TicketReportInDb, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.owner !== "") {
      writer.uint32(10).string(message.owner);
    }
    if (message.activeClient !== undefined) {
      ProposalActiveClient.encode(message.activeClient, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TicketReportInDb {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTicketReportInDb();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.owner = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.activeClient = ProposalActiveClient.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TicketReportInDb {
    return {
      owner: isSet(object.owner) ? globalThis.String(object.owner) : "",
      activeClient: isSet(object.activeClient) ? ProposalActiveClient.fromJSON(object.activeClient) : undefined,
    };
  },

  toJSON(message: TicketReportInDb): unknown {
    const obj: any = {};
    if (message.owner !== "") {
      obj.owner = message.owner;
    }
    if (message.activeClient !== undefined) {
      obj.activeClient = ProposalActiveClient.toJSON(message.activeClient);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TicketReportInDb>, I>>(base?: I): TicketReportInDb {
    return TicketReportInDb.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TicketReportInDb>, I>>(object: I): TicketReportInDb {
    const message = createBaseTicketReportInDb();
    message.owner = object.owner ?? "";
    message.activeClient = (object.activeClient !== undefined && object.activeClient !== null)
      ? ProposalActiveClient.fromPartial(object.activeClient)
      : undefined;
    return message;
  },
};

function createBaseDidBesuAddressInfoRequest(): DidBesuAddressInfoRequest {
  return { dids: [], besuAddresses: [], mids: [] };
}

export const DidBesuAddressInfoRequest = {
  encode(message: DidBesuAddressInfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dids) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.besuAddresses) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.mids) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidBesuAddressInfoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidBesuAddressInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dids.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.besuAddresses.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mids.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DidBesuAddressInfoRequest {
    return {
      dids: globalThis.Array.isArray(object?.dids) ? object.dids.map((e: any) => globalThis.String(e)) : [],
      besuAddresses: globalThis.Array.isArray(object?.besuAddresses)
        ? object.besuAddresses.map((e: any) => globalThis.String(e))
        : [],
      mids: globalThis.Array.isArray(object?.mids) ? object.mids.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: DidBesuAddressInfoRequest): unknown {
    const obj: any = {};
    if (message.dids?.length) {
      obj.dids = message.dids;
    }
    if (message.besuAddresses?.length) {
      obj.besuAddresses = message.besuAddresses;
    }
    if (message.mids?.length) {
      obj.mids = message.mids;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DidBesuAddressInfoRequest>, I>>(base?: I): DidBesuAddressInfoRequest {
    return DidBesuAddressInfoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DidBesuAddressInfoRequest>, I>>(object: I): DidBesuAddressInfoRequest {
    const message = createBaseDidBesuAddressInfoRequest();
    message.dids = object.dids?.map((e) => e) || [];
    message.besuAddresses = object.besuAddresses?.map((e) => e) || [];
    message.mids = object.mids?.map((e) => e) || [];
    return message;
  },
};

function createBaseDidBesuAddressUnit(): DidBesuAddressUnit {
  return {
    did: undefined,
    besuAddress: undefined,
    createdAt: undefined,
    role: undefined,
    name: undefined,
    description: undefined,
    proposalId: undefined,
    mid: undefined,
    myd2Verkey: undefined,
    besuPublicKey: undefined,
    userAgent: undefined,
    updatedAt: undefined,
    connectCount: undefined,
  };
}

export const DidBesuAddressUnit = {
  encode(message: DidBesuAddressUnit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== undefined) {
      writer.uint32(10).string(message.did);
    }
    if (message.besuAddress !== undefined) {
      writer.uint32(18).string(message.besuAddress);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(26).string(message.createdAt);
    }
    if (message.role !== undefined) {
      writer.uint32(32).int32(message.role);
    }
    if (message.name !== undefined) {
      writer.uint32(90).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(98).string(message.description);
    }
    if (message.proposalId !== undefined) {
      writer.uint32(106).string(message.proposalId);
    }
    if (message.mid !== undefined) {
      writer.uint32(170).string(message.mid);
    }
    if (message.myd2Verkey !== undefined) {
      writer.uint32(178).string(message.myd2Verkey);
    }
    if (message.besuPublicKey !== undefined) {
      writer.uint32(186).string(message.besuPublicKey);
    }
    if (message.userAgent !== undefined) {
      writer.uint32(194).string(message.userAgent);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(202).string(message.updatedAt);
    }
    if (message.connectCount !== undefined) {
      writer.uint32(208).uint64(message.connectCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidBesuAddressUnit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidBesuAddressUnit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.name = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.description = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.mid = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.myd2Verkey = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.besuPublicKey = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.userAgent = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.connectCount = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DidBesuAddressUnit {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : undefined,
      besuAddress: isSet(object.besuAddress) ? globalThis.String(object.besuAddress) : undefined,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : undefined,
      role: isSet(object.role) ? blockchainRoleFromJSON(object.role) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : undefined,
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : undefined,
      mid: isSet(object.mid) ? globalThis.String(object.mid) : undefined,
      myd2Verkey: isSet(object.myd2Verkey) ? globalThis.String(object.myd2Verkey) : undefined,
      besuPublicKey: isSet(object.besuPublicKey) ? globalThis.String(object.besuPublicKey) : undefined,
      userAgent: isSet(object.userAgent) ? globalThis.String(object.userAgent) : undefined,
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : undefined,
      connectCount: isSet(object.connectCount) ? globalThis.Number(object.connectCount) : undefined,
    };
  },

  toJSON(message: DidBesuAddressUnit): unknown {
    const obj: any = {};
    if (message.did !== undefined) {
      obj.did = message.did;
    }
    if (message.besuAddress !== undefined) {
      obj.besuAddress = message.besuAddress;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt;
    }
    if (message.role !== undefined) {
      obj.role = blockchainRoleToJSON(message.role);
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    if (message.proposalId !== undefined) {
      obj.proposalId = message.proposalId;
    }
    if (message.mid !== undefined) {
      obj.mid = message.mid;
    }
    if (message.myd2Verkey !== undefined) {
      obj.myd2Verkey = message.myd2Verkey;
    }
    if (message.besuPublicKey !== undefined) {
      obj.besuPublicKey = message.besuPublicKey;
    }
    if (message.userAgent !== undefined) {
      obj.userAgent = message.userAgent;
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt;
    }
    if (message.connectCount !== undefined) {
      obj.connectCount = Math.round(message.connectCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DidBesuAddressUnit>, I>>(base?: I): DidBesuAddressUnit {
    return DidBesuAddressUnit.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DidBesuAddressUnit>, I>>(object: I): DidBesuAddressUnit {
    const message = createBaseDidBesuAddressUnit();
    message.did = object.did ?? undefined;
    message.besuAddress = object.besuAddress ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.role = object.role ?? undefined;
    message.name = object.name ?? undefined;
    message.description = object.description ?? undefined;
    message.proposalId = object.proposalId ?? undefined;
    message.mid = object.mid ?? undefined;
    message.myd2Verkey = object.myd2Verkey ?? undefined;
    message.besuPublicKey = object.besuPublicKey ?? undefined;
    message.userAgent = object.userAgent ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.connectCount = object.connectCount ?? undefined;
    return message;
  },
};

function createBaseDidBesuAddressInfoResponse(): DidBesuAddressInfoResponse {
  return { code: 0, results: [], notFoundDids: [], notFoundBesuAddresses: [], notFoundMids: [] };
}

export const DidBesuAddressInfoResponse = {
  encode(message: DidBesuAddressInfoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.results) {
      DidBesuAddressUnit.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.notFoundDids) {
      writer.uint32(90).string(v!);
    }
    for (const v of message.notFoundBesuAddresses) {
      writer.uint32(98).string(v!);
    }
    for (const v of message.notFoundMids) {
      writer.uint32(106).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidBesuAddressInfoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidBesuAddressInfoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.results.push(DidBesuAddressUnit.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.notFoundDids.push(reader.string());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.notFoundBesuAddresses.push(reader.string());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.notFoundMids.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DidBesuAddressInfoResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      results: globalThis.Array.isArray(object?.results)
        ? object.results.map((e: any) => DidBesuAddressUnit.fromJSON(e))
        : [],
      notFoundDids: globalThis.Array.isArray(object?.notFoundDids)
        ? object.notFoundDids.map((e: any) => globalThis.String(e))
        : [],
      notFoundBesuAddresses: globalThis.Array.isArray(object?.notFoundBesuAddresses)
        ? object.notFoundBesuAddresses.map((e: any) => globalThis.String(e))
        : [],
      notFoundMids: globalThis.Array.isArray(object?.notFoundMids)
        ? object.notFoundMids.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: DidBesuAddressInfoResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.results?.length) {
      obj.results = message.results.map((e) => DidBesuAddressUnit.toJSON(e));
    }
    if (message.notFoundDids?.length) {
      obj.notFoundDids = message.notFoundDids;
    }
    if (message.notFoundBesuAddresses?.length) {
      obj.notFoundBesuAddresses = message.notFoundBesuAddresses;
    }
    if (message.notFoundMids?.length) {
      obj.notFoundMids = message.notFoundMids;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DidBesuAddressInfoResponse>, I>>(base?: I): DidBesuAddressInfoResponse {
    return DidBesuAddressInfoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DidBesuAddressInfoResponse>, I>>(object: I): DidBesuAddressInfoResponse {
    const message = createBaseDidBesuAddressInfoResponse();
    message.code = object.code ?? 0;
    message.results = object.results?.map((e) => DidBesuAddressUnit.fromPartial(e)) || [];
    message.notFoundDids = object.notFoundDids?.map((e) => e) || [];
    message.notFoundBesuAddresses = object.notFoundBesuAddresses?.map((e) => e) || [];
    message.notFoundMids = object.notFoundMids?.map((e) => e) || [];
    return message;
  },
};

function createBaseBreakAwayDidRequest(): BreakAwayDidRequest {
  return { dids: [] };
}

export const BreakAwayDidRequest = {
  encode(message: BreakAwayDidRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dids) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BreakAwayDidRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBreakAwayDidRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dids.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BreakAwayDidRequest {
    return { dids: globalThis.Array.isArray(object?.dids) ? object.dids.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: BreakAwayDidRequest): unknown {
    const obj: any = {};
    if (message.dids?.length) {
      obj.dids = message.dids;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BreakAwayDidRequest>, I>>(base?: I): BreakAwayDidRequest {
    return BreakAwayDidRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BreakAwayDidRequest>, I>>(object: I): BreakAwayDidRequest {
    const message = createBaseBreakAwayDidRequest();
    message.dids = object.dids?.map((e) => e) || [];
    return message;
  },
};

function createBaseBreakAwayDidResponse(): BreakAwayDidResponse {
  return { code: 0 };
}

export const BreakAwayDidResponse = {
  encode(message: BreakAwayDidResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BreakAwayDidResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBreakAwayDidResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BreakAwayDidResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: BreakAwayDidResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BreakAwayDidResponse>, I>>(base?: I): BreakAwayDidResponse {
    return BreakAwayDidResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BreakAwayDidResponse>, I>>(object: I): BreakAwayDidResponse {
    const message = createBaseBreakAwayDidResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseDidReportRequest(): DidReportRequest {
  return { dids: [], listOption: undefined };
}

export const DidReportRequest = {
  encode(message: DidReportRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dids) {
      writer.uint32(10).string(v!);
    }
    if (message.listOption !== undefined) {
      ListProposalRequest.encode(message.listOption, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidReportRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidReportRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dids.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.listOption = ListProposalRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DidReportRequest {
    return {
      dids: globalThis.Array.isArray(object?.dids) ? object.dids.map((e: any) => globalThis.String(e)) : [],
      listOption: isSet(object.listOption) ? ListProposalRequest.fromJSON(object.listOption) : undefined,
    };
  },

  toJSON(message: DidReportRequest): unknown {
    const obj: any = {};
    if (message.dids?.length) {
      obj.dids = message.dids;
    }
    if (message.listOption !== undefined) {
      obj.listOption = ListProposalRequest.toJSON(message.listOption);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DidReportRequest>, I>>(base?: I): DidReportRequest {
    return DidReportRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DidReportRequest>, I>>(object: I): DidReportRequest {
    const message = createBaseDidReportRequest();
    message.dids = object.dids?.map((e) => e) || [];
    message.listOption = (object.listOption !== undefined && object.listOption !== null)
      ? ListProposalRequest.fromPartial(object.listOption)
      : undefined;
    return message;
  },
};

function createBaseDidReport(): DidReport {
  return {
    did: "",
    userStatInJson: "",
    member: undefined,
    listResponse: undefined,
    attendance: undefined,
    wordpressUserDbId: undefined,
    myd2AttendanceByDid: undefined,
    myd2AttendanceByMid: undefined,
    myd2WordpressUserDbId: undefined,
    myd2Point: undefined,
  };
}

export const DidReport = {
  encode(message: DidReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.userStatInJson !== "") {
      writer.uint32(18).string(message.userStatInJson);
    }
    if (message.member !== undefined) {
      DidBesuAddressUnit.encode(message.member, writer.uint32(26).fork()).ldelim();
    }
    if (message.listResponse !== undefined) {
      ListProposalResponse.encode(message.listResponse, writer.uint32(34).fork()).ldelim();
    }
    if (message.attendance !== undefined) {
      AttendanceRollCallResponse.encode(message.attendance, writer.uint32(42).fork()).ldelim();
    }
    if (message.wordpressUserDbId !== undefined) {
      writer.uint32(48).uint32(message.wordpressUserDbId);
    }
    if (message.myd2AttendanceByDid !== undefined) {
      AttendanceRollCallResponse.encode(message.myd2AttendanceByDid, writer.uint32(58).fork()).ldelim();
    }
    if (message.myd2AttendanceByMid !== undefined) {
      AttendanceRollCallResponse.encode(message.myd2AttendanceByMid, writer.uint32(82).fork()).ldelim();
    }
    if (message.myd2WordpressUserDbId !== undefined) {
      writer.uint32(64).uint32(message.myd2WordpressUserDbId);
    }
    if (message.myd2Point !== undefined) {
      writer.uint32(72).uint64(message.myd2Point);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userStatInJson = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.member = DidBesuAddressUnit.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.listResponse = ListProposalResponse.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.attendance = AttendanceRollCallResponse.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.wordpressUserDbId = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.myd2AttendanceByDid = AttendanceRollCallResponse.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.myd2AttendanceByMid = AttendanceRollCallResponse.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.myd2WordpressUserDbId = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.myd2Point = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DidReport {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      userStatInJson: isSet(object.userStatInJson) ? globalThis.String(object.userStatInJson) : "",
      member: isSet(object.member) ? DidBesuAddressUnit.fromJSON(object.member) : undefined,
      listResponse: isSet(object.listResponse) ? ListProposalResponse.fromJSON(object.listResponse) : undefined,
      attendance: isSet(object.attendance) ? AttendanceRollCallResponse.fromJSON(object.attendance) : undefined,
      wordpressUserDbId: isSet(object.wordpressUserDbId) ? globalThis.Number(object.wordpressUserDbId) : undefined,
      myd2AttendanceByDid: isSet(object.myd2AttendanceByDid)
        ? AttendanceRollCallResponse.fromJSON(object.myd2AttendanceByDid)
        : undefined,
      myd2AttendanceByMid: isSet(object.myd2AttendanceByMid)
        ? AttendanceRollCallResponse.fromJSON(object.myd2AttendanceByMid)
        : undefined,
      myd2WordpressUserDbId: isSet(object.myd2WordpressUserDbId)
        ? globalThis.Number(object.myd2WordpressUserDbId)
        : undefined,
      myd2Point: isSet(object.myd2Point) ? globalThis.Number(object.myd2Point) : undefined,
    };
  },

  toJSON(message: DidReport): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.userStatInJson !== "") {
      obj.userStatInJson = message.userStatInJson;
    }
    if (message.member !== undefined) {
      obj.member = DidBesuAddressUnit.toJSON(message.member);
    }
    if (message.listResponse !== undefined) {
      obj.listResponse = ListProposalResponse.toJSON(message.listResponse);
    }
    if (message.attendance !== undefined) {
      obj.attendance = AttendanceRollCallResponse.toJSON(message.attendance);
    }
    if (message.wordpressUserDbId !== undefined) {
      obj.wordpressUserDbId = Math.round(message.wordpressUserDbId);
    }
    if (message.myd2AttendanceByDid !== undefined) {
      obj.myd2AttendanceByDid = AttendanceRollCallResponse.toJSON(message.myd2AttendanceByDid);
    }
    if (message.myd2AttendanceByMid !== undefined) {
      obj.myd2AttendanceByMid = AttendanceRollCallResponse.toJSON(message.myd2AttendanceByMid);
    }
    if (message.myd2WordpressUserDbId !== undefined) {
      obj.myd2WordpressUserDbId = Math.round(message.myd2WordpressUserDbId);
    }
    if (message.myd2Point !== undefined) {
      obj.myd2Point = Math.round(message.myd2Point);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DidReport>, I>>(base?: I): DidReport {
    return DidReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DidReport>, I>>(object: I): DidReport {
    const message = createBaseDidReport();
    message.did = object.did ?? "";
    message.userStatInJson = object.userStatInJson ?? "";
    message.member = (object.member !== undefined && object.member !== null)
      ? DidBesuAddressUnit.fromPartial(object.member)
      : undefined;
    message.listResponse = (object.listResponse !== undefined && object.listResponse !== null)
      ? ListProposalResponse.fromPartial(object.listResponse)
      : undefined;
    message.attendance = (object.attendance !== undefined && object.attendance !== null)
      ? AttendanceRollCallResponse.fromPartial(object.attendance)
      : undefined;
    message.wordpressUserDbId = object.wordpressUserDbId ?? undefined;
    message.myd2AttendanceByDid = (object.myd2AttendanceByDid !== undefined && object.myd2AttendanceByDid !== null)
      ? AttendanceRollCallResponse.fromPartial(object.myd2AttendanceByDid)
      : undefined;
    message.myd2AttendanceByMid = (object.myd2AttendanceByMid !== undefined && object.myd2AttendanceByMid !== null)
      ? AttendanceRollCallResponse.fromPartial(object.myd2AttendanceByMid)
      : undefined;
    message.myd2WordpressUserDbId = object.myd2WordpressUserDbId ?? undefined;
    message.myd2Point = object.myd2Point ?? undefined;
    return message;
  },
};

function createBaseDidReportResponse(): DidReportResponse {
  return { didReports: [] };
}

export const DidReportResponse = {
  encode(message: DidReportResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.didReports) {
      DidReport.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidReportResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidReportResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.didReports.push(DidReport.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DidReportResponse {
    return {
      didReports: globalThis.Array.isArray(object?.didReports)
        ? object.didReports.map((e: any) => DidReport.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DidReportResponse): unknown {
    const obj: any = {};
    if (message.didReports?.length) {
      obj.didReports = message.didReports.map((e) => DidReport.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DidReportResponse>, I>>(base?: I): DidReportResponse {
    return DidReportResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DidReportResponse>, I>>(object: I): DidReportResponse {
    const message = createBaseDidReportResponse();
    message.didReports = object.didReports?.map((e) => DidReport.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdminProposalUpdateRequest(): AdminProposalUpdateRequest {
  return { proposalStatic: undefined };
}

export const AdminProposalUpdateRequest = {
  encode(message: AdminProposalUpdateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalStatic !== undefined) {
      ProposalStatic.encode(message.proposalStatic, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminProposalUpdateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminProposalUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposalStatic = ProposalStatic.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminProposalUpdateRequest {
    return {
      proposalStatic: isSet(object.proposalStatic) ? ProposalStatic.fromJSON(object.proposalStatic) : undefined,
    };
  },

  toJSON(message: AdminProposalUpdateRequest): unknown {
    const obj: any = {};
    if (message.proposalStatic !== undefined) {
      obj.proposalStatic = ProposalStatic.toJSON(message.proposalStatic);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminProposalUpdateRequest>, I>>(base?: I): AdminProposalUpdateRequest {
    return AdminProposalUpdateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminProposalUpdateRequest>, I>>(object: I): AdminProposalUpdateRequest {
    const message = createBaseAdminProposalUpdateRequest();
    message.proposalStatic = (object.proposalStatic !== undefined && object.proposalStatic !== null)
      ? ProposalStatic.fromPartial(object.proposalStatic)
      : undefined;
    return message;
  },
};

function createBaseAdminProposalUpdateResponse(): AdminProposalUpdateResponse {
  return { statusCode: 0 };
}

export const AdminProposalUpdateResponse = {
  encode(message: AdminProposalUpdateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminProposalUpdateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminProposalUpdateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminProposalUpdateResponse {
    return { statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0 };
  },

  toJSON(message: AdminProposalUpdateResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminProposalUpdateResponse>, I>>(base?: I): AdminProposalUpdateResponse {
    return AdminProposalUpdateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminProposalUpdateResponse>, I>>(object: I): AdminProposalUpdateResponse {
    const message = createBaseAdminProposalUpdateResponse();
    message.statusCode = object.statusCode ?? 0;
    return message;
  },
};

function createBaseManualMyd2MigrationRequest(): ManualMyd2MigrationRequest {
  return { did: "", besuAddress: "", mintPoint: false, attendance: false, wordressUser: false, rewardHistory: false };
}

export const ManualMyd2MigrationRequest = {
  encode(message: ManualMyd2MigrationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.besuAddress !== "") {
      writer.uint32(18).string(message.besuAddress);
    }
    if (message.mintPoint === true) {
      writer.uint32(88).bool(message.mintPoint);
    }
    if (message.attendance === true) {
      writer.uint32(96).bool(message.attendance);
    }
    if (message.wordressUser === true) {
      writer.uint32(104).bool(message.wordressUser);
    }
    if (message.rewardHistory === true) {
      writer.uint32(112).bool(message.rewardHistory);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManualMyd2MigrationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManualMyd2MigrationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.mintPoint = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.attendance = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.wordressUser = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.rewardHistory = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManualMyd2MigrationRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      besuAddress: isSet(object.besuAddress) ? globalThis.String(object.besuAddress) : "",
      mintPoint: isSet(object.mintPoint) ? globalThis.Boolean(object.mintPoint) : false,
      attendance: isSet(object.attendance) ? globalThis.Boolean(object.attendance) : false,
      wordressUser: isSet(object.wordressUser) ? globalThis.Boolean(object.wordressUser) : false,
      rewardHistory: isSet(object.rewardHistory) ? globalThis.Boolean(object.rewardHistory) : false,
    };
  },

  toJSON(message: ManualMyd2MigrationRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.mintPoint === true) {
      obj.mintPoint = message.mintPoint;
    }
    if (message.attendance === true) {
      obj.attendance = message.attendance;
    }
    if (message.wordressUser === true) {
      obj.wordressUser = message.wordressUser;
    }
    if (message.rewardHistory === true) {
      obj.rewardHistory = message.rewardHistory;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManualMyd2MigrationRequest>, I>>(base?: I): ManualMyd2MigrationRequest {
    return ManualMyd2MigrationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManualMyd2MigrationRequest>, I>>(object: I): ManualMyd2MigrationRequest {
    const message = createBaseManualMyd2MigrationRequest();
    message.did = object.did ?? "";
    message.besuAddress = object.besuAddress ?? "";
    message.mintPoint = object.mintPoint ?? false;
    message.attendance = object.attendance ?? false;
    message.wordressUser = object.wordressUser ?? false;
    message.rewardHistory = object.rewardHistory ?? false;
    return message;
  },
};

function createBaseManualMyd2MigrationResponse(): ManualMyd2MigrationResponse {
  return { code: 0 };
}

export const ManualMyd2MigrationResponse = {
  encode(message: ManualMyd2MigrationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManualMyd2MigrationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManualMyd2MigrationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManualMyd2MigrationResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: ManualMyd2MigrationResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManualMyd2MigrationResponse>, I>>(base?: I): ManualMyd2MigrationResponse {
    return ManualMyd2MigrationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManualMyd2MigrationResponse>, I>>(object: I): ManualMyd2MigrationResponse {
    const message = createBaseManualMyd2MigrationResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseUserMigrationReportRequest(): UserMigrationReportRequest {
  return { createdAtGreaterThan: "", createdAtLessThan: undefined, histogramUnit: "", activeUserJudgementCriteria: "" };
}

export const UserMigrationReportRequest = {
  encode(message: UserMigrationReportRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createdAtGreaterThan !== "") {
      writer.uint32(10).string(message.createdAtGreaterThan);
    }
    if (message.createdAtLessThan !== undefined) {
      writer.uint32(18).string(message.createdAtLessThan);
    }
    if (message.histogramUnit !== "") {
      writer.uint32(26).string(message.histogramUnit);
    }
    if (message.activeUserJudgementCriteria !== "") {
      writer.uint32(82).string(message.activeUserJudgementCriteria);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserMigrationReportRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserMigrationReportRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.createdAtGreaterThan = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAtLessThan = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.histogramUnit = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.activeUserJudgementCriteria = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserMigrationReportRequest {
    return {
      createdAtGreaterThan: isSet(object.createdAtGreaterThan) ? globalThis.String(object.createdAtGreaterThan) : "",
      createdAtLessThan: isSet(object.createdAtLessThan) ? globalThis.String(object.createdAtLessThan) : undefined,
      histogramUnit: isSet(object.histogramUnit) ? globalThis.String(object.histogramUnit) : "",
      activeUserJudgementCriteria: isSet(object.activeUserJudgementCriteria)
        ? globalThis.String(object.activeUserJudgementCriteria)
        : "",
    };
  },

  toJSON(message: UserMigrationReportRequest): unknown {
    const obj: any = {};
    if (message.createdAtGreaterThan !== "") {
      obj.createdAtGreaterThan = message.createdAtGreaterThan;
    }
    if (message.createdAtLessThan !== undefined) {
      obj.createdAtLessThan = message.createdAtLessThan;
    }
    if (message.histogramUnit !== "") {
      obj.histogramUnit = message.histogramUnit;
    }
    if (message.activeUserJudgementCriteria !== "") {
      obj.activeUserJudgementCriteria = message.activeUserJudgementCriteria;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserMigrationReportRequest>, I>>(base?: I): UserMigrationReportRequest {
    return UserMigrationReportRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserMigrationReportRequest>, I>>(object: I): UserMigrationReportRequest {
    const message = createBaseUserMigrationReportRequest();
    message.createdAtGreaterThan = object.createdAtGreaterThan ?? "";
    message.createdAtLessThan = object.createdAtLessThan ?? undefined;
    message.histogramUnit = object.histogramUnit ?? "";
    message.activeUserJudgementCriteria = object.activeUserJudgementCriteria ?? "";
    return message;
  },
};

function createBaseUserMigrationReportUnit(): UserMigrationReportUnit {
  return {
    startTime: "",
    endTime: "",
    activeMyd2Users: 0,
    activeMyd3Users: 0,
    newMyd2Users: 0,
    newMyd3Users: 0,
    migratedUsers: 0,
    accumulatedMigratedUsers: 0,
  };
}

export const UserMigrationReportUnit = {
  encode(message: UserMigrationReportUnit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== "") {
      writer.uint32(10).string(message.startTime);
    }
    if (message.endTime !== "") {
      writer.uint32(18).string(message.endTime);
    }
    if (message.activeMyd2Users !== 0) {
      writer.uint32(88).uint64(message.activeMyd2Users);
    }
    if (message.activeMyd3Users !== 0) {
      writer.uint32(96).uint64(message.activeMyd3Users);
    }
    if (message.newMyd2Users !== 0) {
      writer.uint32(104).uint64(message.newMyd2Users);
    }
    if (message.newMyd3Users !== 0) {
      writer.uint32(112).uint64(message.newMyd3Users);
    }
    if (message.migratedUsers !== 0) {
      writer.uint32(120).uint64(message.migratedUsers);
    }
    if (message.accumulatedMigratedUsers !== 0) {
      writer.uint32(128).uint64(message.accumulatedMigratedUsers);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserMigrationReportUnit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserMigrationReportUnit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTime = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endTime = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.activeMyd2Users = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.activeMyd3Users = longToNumber(reader.uint64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.newMyd2Users = longToNumber(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.newMyd3Users = longToNumber(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.migratedUsers = longToNumber(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.accumulatedMigratedUsers = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserMigrationReportUnit {
    return {
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : "",
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : "",
      activeMyd2Users: isSet(object.activeMyd2Users) ? globalThis.Number(object.activeMyd2Users) : 0,
      activeMyd3Users: isSet(object.activeMyd3Users) ? globalThis.Number(object.activeMyd3Users) : 0,
      newMyd2Users: isSet(object.newMyd2Users) ? globalThis.Number(object.newMyd2Users) : 0,
      newMyd3Users: isSet(object.newMyd3Users) ? globalThis.Number(object.newMyd3Users) : 0,
      migratedUsers: isSet(object.migratedUsers) ? globalThis.Number(object.migratedUsers) : 0,
      accumulatedMigratedUsers: isSet(object.accumulatedMigratedUsers)
        ? globalThis.Number(object.accumulatedMigratedUsers)
        : 0,
    };
  },

  toJSON(message: UserMigrationReportUnit): unknown {
    const obj: any = {};
    if (message.startTime !== "") {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== "") {
      obj.endTime = message.endTime;
    }
    if (message.activeMyd2Users !== 0) {
      obj.activeMyd2Users = Math.round(message.activeMyd2Users);
    }
    if (message.activeMyd3Users !== 0) {
      obj.activeMyd3Users = Math.round(message.activeMyd3Users);
    }
    if (message.newMyd2Users !== 0) {
      obj.newMyd2Users = Math.round(message.newMyd2Users);
    }
    if (message.newMyd3Users !== 0) {
      obj.newMyd3Users = Math.round(message.newMyd3Users);
    }
    if (message.migratedUsers !== 0) {
      obj.migratedUsers = Math.round(message.migratedUsers);
    }
    if (message.accumulatedMigratedUsers !== 0) {
      obj.accumulatedMigratedUsers = Math.round(message.accumulatedMigratedUsers);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserMigrationReportUnit>, I>>(base?: I): UserMigrationReportUnit {
    return UserMigrationReportUnit.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserMigrationReportUnit>, I>>(object: I): UserMigrationReportUnit {
    const message = createBaseUserMigrationReportUnit();
    message.startTime = object.startTime ?? "";
    message.endTime = object.endTime ?? "";
    message.activeMyd2Users = object.activeMyd2Users ?? 0;
    message.activeMyd3Users = object.activeMyd3Users ?? 0;
    message.newMyd2Users = object.newMyd2Users ?? 0;
    message.newMyd3Users = object.newMyd3Users ?? 0;
    message.migratedUsers = object.migratedUsers ?? 0;
    message.accumulatedMigratedUsers = object.accumulatedMigratedUsers ?? 0;
    return message;
  },
};

function createBaseUserMigrationReportResponse(): UserMigrationReportResponse {
  return { reports: [] };
}

export const UserMigrationReportResponse = {
  encode(message: UserMigrationReportResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.reports) {
      UserMigrationReportUnit.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserMigrationReportResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserMigrationReportResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.reports.push(UserMigrationReportUnit.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserMigrationReportResponse {
    return {
      reports: globalThis.Array.isArray(object?.reports)
        ? object.reports.map((e: any) => UserMigrationReportUnit.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UserMigrationReportResponse): unknown {
    const obj: any = {};
    if (message.reports?.length) {
      obj.reports = message.reports.map((e) => UserMigrationReportUnit.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserMigrationReportResponse>, I>>(base?: I): UserMigrationReportResponse {
    return UserMigrationReportResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserMigrationReportResponse>, I>>(object: I): UserMigrationReportResponse {
    const message = createBaseUserMigrationReportResponse();
    message.reports = object.reports?.map((e) => UserMigrationReportUnit.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGMORMemberInfoRequest(): GMORMemberInfoRequest {
  return { did: "", mid: "" };
}

export const GMORMemberInfoRequest = {
  encode(message: GMORMemberInfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GMORMemberInfoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGMORMemberInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GMORMemberInfoRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      mid: isSet(object.mid) ? globalThis.String(object.mid) : "",
    };
  },

  toJSON(message: GMORMemberInfoRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GMORMemberInfoRequest>, I>>(base?: I): GMORMemberInfoRequest {
    return GMORMemberInfoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GMORMemberInfoRequest>, I>>(object: I): GMORMemberInfoRequest {
    const message = createBaseGMORMemberInfoRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    return message;
  },
};

function createBaseGMORMemberInfoResponse(): GMORMemberInfoResponse {
  return { memberInfo: undefined, code: 0 };
}

export const GMORMemberInfoResponse = {
  encode(message: GMORMemberInfoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.memberInfo !== undefined) {
      GMORMemberInfo.encode(message.memberInfo, writer.uint32(10).fork()).ldelim();
    }
    if (message.code !== 0) {
      writer.uint32(16).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GMORMemberInfoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGMORMemberInfoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.memberInfo = GMORMemberInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GMORMemberInfoResponse {
    return {
      memberInfo: isSet(object.memberInfo) ? GMORMemberInfo.fromJSON(object.memberInfo) : undefined,
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
    };
  },

  toJSON(message: GMORMemberInfoResponse): unknown {
    const obj: any = {};
    if (message.memberInfo !== undefined) {
      obj.memberInfo = GMORMemberInfo.toJSON(message.memberInfo);
    }
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GMORMemberInfoResponse>, I>>(base?: I): GMORMemberInfoResponse {
    return GMORMemberInfoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GMORMemberInfoResponse>, I>>(object: I): GMORMemberInfoResponse {
    const message = createBaseGMORMemberInfoResponse();
    message.memberInfo = (object.memberInfo !== undefined && object.memberInfo !== null)
      ? GMORMemberInfo.fromPartial(object.memberInfo)
      : undefined;
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseGMORMemberInfo(): GMORMemberInfo {
  return { did: "", memberId: "", mid: "" };
}

export const GMORMemberInfo = {
  encode(message: GMORMemberInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.memberId !== "") {
      writer.uint32(18).string(message.memberId);
    }
    if (message.mid !== "") {
      writer.uint32(26).string(message.mid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GMORMemberInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGMORMemberInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.memberId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GMORMemberInfo {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      memberId: isSet(object.memberId) ? globalThis.String(object.memberId) : "",
      mid: isSet(object.mid) ? globalThis.String(object.mid) : "",
    };
  },

  toJSON(message: GMORMemberInfo): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.memberId !== "") {
      obj.memberId = message.memberId;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GMORMemberInfo>, I>>(base?: I): GMORMemberInfo {
    return GMORMemberInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GMORMemberInfo>, I>>(object: I): GMORMemberInfo {
    const message = createBaseGMORMemberInfo();
    message.did = object.did ?? "";
    message.memberId = object.memberId ?? "";
    message.mid = object.mid ?? "";
    return message;
  },
};

function createBasePollInfoCountResponse(): PollInfoCountResponse {
  return { code: 0, notStarted: 0, inProgress: 0, ended: 0, temporary: 0, deleted: 0, revoked: 0, hidden: 0 };
}

export const PollInfoCountResponse = {
  encode(message: PollInfoCountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.notStarted !== 0) {
      writer.uint32(16).uint32(message.notStarted);
    }
    if (message.inProgress !== 0) {
      writer.uint32(24).uint32(message.inProgress);
    }
    if (message.ended !== 0) {
      writer.uint32(32).uint32(message.ended);
    }
    if (message.temporary !== 0) {
      writer.uint32(40).uint32(message.temporary);
    }
    if (message.deleted !== 0) {
      writer.uint32(48).uint32(message.deleted);
    }
    if (message.revoked !== 0) {
      writer.uint32(56).uint32(message.revoked);
    }
    if (message.hidden !== 0) {
      writer.uint32(64).uint32(message.hidden);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollInfoCountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollInfoCountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.notStarted = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.inProgress = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.ended = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.temporary = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.deleted = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.revoked = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.hidden = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollInfoCountResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      notStarted: isSet(object.notStarted) ? globalThis.Number(object.notStarted) : 0,
      inProgress: isSet(object.inProgress) ? globalThis.Number(object.inProgress) : 0,
      ended: isSet(object.ended) ? globalThis.Number(object.ended) : 0,
      temporary: isSet(object.temporary) ? globalThis.Number(object.temporary) : 0,
      deleted: isSet(object.deleted) ? globalThis.Number(object.deleted) : 0,
      revoked: isSet(object.revoked) ? globalThis.Number(object.revoked) : 0,
      hidden: isSet(object.hidden) ? globalThis.Number(object.hidden) : 0,
    };
  },

  toJSON(message: PollInfoCountResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.notStarted !== 0) {
      obj.notStarted = Math.round(message.notStarted);
    }
    if (message.inProgress !== 0) {
      obj.inProgress = Math.round(message.inProgress);
    }
    if (message.ended !== 0) {
      obj.ended = Math.round(message.ended);
    }
    if (message.temporary !== 0) {
      obj.temporary = Math.round(message.temporary);
    }
    if (message.deleted !== 0) {
      obj.deleted = Math.round(message.deleted);
    }
    if (message.revoked !== 0) {
      obj.revoked = Math.round(message.revoked);
    }
    if (message.hidden !== 0) {
      obj.hidden = Math.round(message.hidden);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollInfoCountResponse>, I>>(base?: I): PollInfoCountResponse {
    return PollInfoCountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollInfoCountResponse>, I>>(object: I): PollInfoCountResponse {
    const message = createBasePollInfoCountResponse();
    message.code = object.code ?? 0;
    message.notStarted = object.notStarted ?? 0;
    message.inProgress = object.inProgress ?? 0;
    message.ended = object.ended ?? 0;
    message.temporary = object.temporary ?? 0;
    message.deleted = object.deleted ?? 0;
    message.revoked = object.revoked ?? 0;
    message.hidden = object.hidden ?? 0;
    return message;
  },
};

function createBasePollInfoListResponse(): PollInfoListResponse {
  return { code: 0, pollInfoItem: [] };
}

export const PollInfoListResponse = {
  encode(message: PollInfoListResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.pollInfoItem) {
      PollInfoItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollInfoListResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollInfoListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollInfoItem.push(PollInfoItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollInfoListResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      pollInfoItem: globalThis.Array.isArray(object?.pollInfoItem)
        ? object.pollInfoItem.map((e: any) => PollInfoItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PollInfoListResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.pollInfoItem?.length) {
      obj.pollInfoItem = message.pollInfoItem.map((e) => PollInfoItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollInfoListResponse>, I>>(base?: I): PollInfoListResponse {
    return PollInfoListResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollInfoListResponse>, I>>(object: I): PollInfoListResponse {
    const message = createBasePollInfoListResponse();
    message.code = object.code ?? 0;
    message.pollInfoItem = object.pollInfoItem?.map((e) => PollInfoItem.fromPartial(e)) || [];
    return message;
  },
};

function createBasePollInfoMobileListResponse(): PollInfoMobileListResponse {
  return { code: 0, pollInfoMobileItem: [] };
}

export const PollInfoMobileListResponse = {
  encode(message: PollInfoMobileListResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.pollInfoMobileItem) {
      PollInfoMobileItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollInfoMobileListResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollInfoMobileListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollInfoMobileItem.push(PollInfoMobileItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollInfoMobileListResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      pollInfoMobileItem: globalThis.Array.isArray(object?.pollInfoMobileItem)
        ? object.pollInfoMobileItem.map((e: any) => PollInfoMobileItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PollInfoMobileListResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.pollInfoMobileItem?.length) {
      obj.pollInfoMobileItem = message.pollInfoMobileItem.map((e) => PollInfoMobileItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollInfoMobileListResponse>, I>>(base?: I): PollInfoMobileListResponse {
    return PollInfoMobileListResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollInfoMobileListResponse>, I>>(object: I): PollInfoMobileListResponse {
    const message = createBasePollInfoMobileListResponse();
    message.code = object.code ?? 0;
    message.pollInfoMobileItem = object.pollInfoMobileItem?.map((e) => PollInfoMobileItem.fromPartial(e)) || [];
    return message;
  },
};

function createBasePollInfoMobileItem(): PollInfoMobileItem {
  return { isParticipant: false, isProvidePoint: 0, pollInfoItem: undefined };
}

export const PollInfoMobileItem = {
  encode(message: PollInfoMobileItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isParticipant === true) {
      writer.uint32(8).bool(message.isParticipant);
    }
    if (message.isProvidePoint !== 0) {
      writer.uint32(16).int32(message.isProvidePoint);
    }
    if (message.pollInfoItem !== undefined) {
      PollInfoItem.encode(message.pollInfoItem, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollInfoMobileItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollInfoMobileItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isParticipant = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isProvidePoint = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pollInfoItem = PollInfoItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollInfoMobileItem {
    return {
      isParticipant: isSet(object.isParticipant) ? globalThis.Boolean(object.isParticipant) : false,
      isProvidePoint: isSet(object.isProvidePoint) ? pollRewardStatusFromJSON(object.isProvidePoint) : 0,
      pollInfoItem: isSet(object.pollInfoItem) ? PollInfoItem.fromJSON(object.pollInfoItem) : undefined,
    };
  },

  toJSON(message: PollInfoMobileItem): unknown {
    const obj: any = {};
    if (message.isParticipant === true) {
      obj.isParticipant = message.isParticipant;
    }
    if (message.isProvidePoint !== 0) {
      obj.isProvidePoint = pollRewardStatusToJSON(message.isProvidePoint);
    }
    if (message.pollInfoItem !== undefined) {
      obj.pollInfoItem = PollInfoItem.toJSON(message.pollInfoItem);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollInfoMobileItem>, I>>(base?: I): PollInfoMobileItem {
    return PollInfoMobileItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollInfoMobileItem>, I>>(object: I): PollInfoMobileItem {
    const message = createBasePollInfoMobileItem();
    message.isParticipant = object.isParticipant ?? false;
    message.isProvidePoint = object.isProvidePoint ?? 0;
    message.pollInfoItem = (object.pollInfoItem !== undefined && object.pollInfoItem !== null)
      ? PollInfoItem.fromPartial(object.pollInfoItem)
      : undefined;
    return message;
  },
};

function createBasePollInfoItem(): PollInfoItem {
  return {
    id: 0,
    pollId: "",
    category: 0,
    title: "",
    imgLink: "",
    status: 0,
    startDate: "",
    endDate: "",
    participant: 0,
    maxIssuable: 0,
    perAmount: 0,
    createdAt: "",
  };
}

export const PollInfoItem = {
  encode(message: PollInfoItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.pollId !== "") {
      writer.uint32(18).string(message.pollId);
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.imgLink !== "") {
      writer.uint32(42).string(message.imgLink);
    }
    if (message.status !== 0) {
      writer.uint32(48).int32(message.status);
    }
    if (message.startDate !== "") {
      writer.uint32(58).string(message.startDate);
    }
    if (message.endDate !== "") {
      writer.uint32(66).string(message.endDate);
    }
    if (message.participant !== 0) {
      writer.uint32(72).uint64(message.participant);
    }
    if (message.maxIssuable !== 0) {
      writer.uint32(80).uint64(message.maxIssuable);
    }
    if (message.perAmount !== 0) {
      writer.uint32(88).uint64(message.perAmount);
    }
    if (message.createdAt !== "") {
      writer.uint32(98).string(message.createdAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollInfoItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollInfoItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imgLink = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.startDate = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.endDate = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.participant = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.maxIssuable = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.perAmount = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.createdAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollInfoItem {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      category: isSet(object.category) ? pollInfoCategoryFromJSON(object.category) : 0,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      imgLink: isSet(object.imgLink) ? globalThis.String(object.imgLink) : "",
      status: isSet(object.status) ? pollInfoStatusFromJSON(object.status) : 0,
      startDate: isSet(object.startDate) ? globalThis.String(object.startDate) : "",
      endDate: isSet(object.endDate) ? globalThis.String(object.endDate) : "",
      participant: isSet(object.participant) ? globalThis.Number(object.participant) : 0,
      maxIssuable: isSet(object.maxIssuable) ? globalThis.Number(object.maxIssuable) : 0,
      perAmount: isSet(object.perAmount) ? globalThis.Number(object.perAmount) : 0,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
    };
  },

  toJSON(message: PollInfoItem): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.category !== 0) {
      obj.category = pollInfoCategoryToJSON(message.category);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.imgLink !== "") {
      obj.imgLink = message.imgLink;
    }
    if (message.status !== 0) {
      obj.status = pollInfoStatusToJSON(message.status);
    }
    if (message.startDate !== "") {
      obj.startDate = message.startDate;
    }
    if (message.endDate !== "") {
      obj.endDate = message.endDate;
    }
    if (message.participant !== 0) {
      obj.participant = Math.round(message.participant);
    }
    if (message.maxIssuable !== 0) {
      obj.maxIssuable = Math.round(message.maxIssuable);
    }
    if (message.perAmount !== 0) {
      obj.perAmount = Math.round(message.perAmount);
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollInfoItem>, I>>(base?: I): PollInfoItem {
    return PollInfoItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollInfoItem>, I>>(object: I): PollInfoItem {
    const message = createBasePollInfoItem();
    message.id = object.id ?? 0;
    message.pollId = object.pollId ?? "";
    message.category = object.category ?? 0;
    message.title = object.title ?? "";
    message.imgLink = object.imgLink ?? "";
    message.status = object.status ?? 0;
    message.startDate = object.startDate ?? "";
    message.endDate = object.endDate ?? "";
    message.participant = object.participant ?? 0;
    message.maxIssuable = object.maxIssuable ?? 0;
    message.perAmount = object.perAmount ?? 0;
    message.createdAt = object.createdAt ?? "";
    return message;
  },
};

function createBasePollReport(): PollReport {
  return {
    id: 0,
    pollId: "",
    pollType: 0,
    multiYn: false,
    category: 0,
    title: "",
    contents: "",
    questions: new Uint8Array(0),
    correctAns: undefined,
    pagesQuestionsCnt: "",
    imgLink: "",
    perAmount: 0,
    maxIssuable: 0,
    status: 0,
    startDate: "",
    endDate: "",
    endNotSet: false,
    participants: 0,
    createdAt: "",
    updatedAt: "",
  };
}

export const PollReport = {
  encode(message: PollReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.pollId !== "") {
      writer.uint32(18).string(message.pollId);
    }
    if (message.pollType !== 0) {
      writer.uint32(24).int32(message.pollType);
    }
    if (message.multiYn === true) {
      writer.uint32(32).bool(message.multiYn);
    }
    if (message.category !== 0) {
      writer.uint32(40).int32(message.category);
    }
    if (message.title !== "") {
      writer.uint32(50).string(message.title);
    }
    if (message.contents !== "") {
      writer.uint32(58).string(message.contents);
    }
    if (message.questions.length !== 0) {
      writer.uint32(66).bytes(message.questions);
    }
    if (message.correctAns !== undefined) {
      writer.uint32(74).string(message.correctAns);
    }
    if (message.pagesQuestionsCnt !== "") {
      writer.uint32(82).string(message.pagesQuestionsCnt);
    }
    if (message.imgLink !== "") {
      writer.uint32(90).string(message.imgLink);
    }
    if (message.perAmount !== 0) {
      writer.uint32(96).uint32(message.perAmount);
    }
    if (message.maxIssuable !== 0) {
      writer.uint32(104).uint32(message.maxIssuable);
    }
    if (message.status !== 0) {
      writer.uint32(112).int32(message.status);
    }
    if (message.startDate !== "") {
      writer.uint32(122).string(message.startDate);
    }
    if (message.endDate !== "") {
      writer.uint32(130).string(message.endDate);
    }
    if (message.endNotSet === true) {
      writer.uint32(136).bool(message.endNotSet);
    }
    if (message.participants !== 0) {
      writer.uint32(144).uint64(message.participants);
    }
    if (message.createdAt !== "") {
      writer.uint32(154).string(message.createdAt);
    }
    if (message.updatedAt !== "") {
      writer.uint32(162).string(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pollType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.multiYn = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.title = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.contents = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.questions = reader.bytes();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.correctAns = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagesQuestionsCnt = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.imgLink = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.perAmount = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.maxIssuable = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.startDate = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.endDate = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.endNotSet = reader.bool();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.participants = longToNumber(reader.uint64() as Long);
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollReport {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      pollType: isSet(object.pollType) ? pollTypeFromJSON(object.pollType) : 0,
      multiYn: isSet(object.multiYn) ? globalThis.Boolean(object.multiYn) : false,
      category: isSet(object.category) ? pollInfoCategoryFromJSON(object.category) : 0,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      contents: isSet(object.contents) ? globalThis.String(object.contents) : "",
      questions: isSet(object.questions) ? bytesFromBase64(object.questions) : new Uint8Array(0),
      correctAns: isSet(object.correctAns) ? globalThis.String(object.correctAns) : undefined,
      pagesQuestionsCnt: isSet(object.pagesQuestionsCnt) ? globalThis.String(object.pagesQuestionsCnt) : "",
      imgLink: isSet(object.imgLink) ? globalThis.String(object.imgLink) : "",
      perAmount: isSet(object.perAmount) ? globalThis.Number(object.perAmount) : 0,
      maxIssuable: isSet(object.maxIssuable) ? globalThis.Number(object.maxIssuable) : 0,
      status: isSet(object.status) ? pollInfoStatusFromJSON(object.status) : 0,
      startDate: isSet(object.startDate) ? globalThis.String(object.startDate) : "",
      endDate: isSet(object.endDate) ? globalThis.String(object.endDate) : "",
      endNotSet: isSet(object.endNotSet) ? globalThis.Boolean(object.endNotSet) : false,
      participants: isSet(object.participants) ? globalThis.Number(object.participants) : 0,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : "",
    };
  },

  toJSON(message: PollReport): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.pollType !== 0) {
      obj.pollType = pollTypeToJSON(message.pollType);
    }
    if (message.multiYn === true) {
      obj.multiYn = message.multiYn;
    }
    if (message.category !== 0) {
      obj.category = pollInfoCategoryToJSON(message.category);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.contents !== "") {
      obj.contents = message.contents;
    }
    if (message.questions.length !== 0) {
      obj.questions = base64FromBytes(message.questions);
    }
    if (message.correctAns !== undefined) {
      obj.correctAns = message.correctAns;
    }
    if (message.pagesQuestionsCnt !== "") {
      obj.pagesQuestionsCnt = message.pagesQuestionsCnt;
    }
    if (message.imgLink !== "") {
      obj.imgLink = message.imgLink;
    }
    if (message.perAmount !== 0) {
      obj.perAmount = Math.round(message.perAmount);
    }
    if (message.maxIssuable !== 0) {
      obj.maxIssuable = Math.round(message.maxIssuable);
    }
    if (message.status !== 0) {
      obj.status = pollInfoStatusToJSON(message.status);
    }
    if (message.startDate !== "") {
      obj.startDate = message.startDate;
    }
    if (message.endDate !== "") {
      obj.endDate = message.endDate;
    }
    if (message.endNotSet === true) {
      obj.endNotSet = message.endNotSet;
    }
    if (message.participants !== 0) {
      obj.participants = Math.round(message.participants);
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.updatedAt !== "") {
      obj.updatedAt = message.updatedAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollReport>, I>>(base?: I): PollReport {
    return PollReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollReport>, I>>(object: I): PollReport {
    const message = createBasePollReport();
    message.id = object.id ?? 0;
    message.pollId = object.pollId ?? "";
    message.pollType = object.pollType ?? 0;
    message.multiYn = object.multiYn ?? false;
    message.category = object.category ?? 0;
    message.title = object.title ?? "";
    message.contents = object.contents ?? "";
    message.questions = object.questions ?? new Uint8Array(0);
    message.correctAns = object.correctAns ?? undefined;
    message.pagesQuestionsCnt = object.pagesQuestionsCnt ?? "";
    message.imgLink = object.imgLink ?? "";
    message.perAmount = object.perAmount ?? 0;
    message.maxIssuable = object.maxIssuable ?? 0;
    message.status = object.status ?? 0;
    message.startDate = object.startDate ?? "";
    message.endDate = object.endDate ?? "";
    message.endNotSet = object.endNotSet ?? false;
    message.participants = object.participants ?? 0;
    message.createdAt = object.createdAt ?? "";
    message.updatedAt = object.updatedAt ?? "";
    return message;
  },
};

function createBasePollRegistRequest(): PollRegistRequest {
  return {
    pollId: "",
    pollType: 0,
    multiYn: false,
    category: 0,
    title: "",
    contents: "",
    questions: new Uint8Array(0),
    correctAns: undefined,
    pagesQuestionsCnt: "",
    imgLink: "",
    perAmount: 0,
    maxIssuable: 0,
    startDate: "",
    endDate: "",
    endNotSet: false,
  };
}

export const PollRegistRequest = {
  encode(message: PollRegistRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    if (message.pollType !== 0) {
      writer.uint32(16).int32(message.pollType);
    }
    if (message.multiYn === true) {
      writer.uint32(24).bool(message.multiYn);
    }
    if (message.category !== 0) {
      writer.uint32(32).int32(message.category);
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    if (message.contents !== "") {
      writer.uint32(50).string(message.contents);
    }
    if (message.questions.length !== 0) {
      writer.uint32(58).bytes(message.questions);
    }
    if (message.correctAns !== undefined) {
      writer.uint32(66).string(message.correctAns);
    }
    if (message.pagesQuestionsCnt !== "") {
      writer.uint32(74).string(message.pagesQuestionsCnt);
    }
    if (message.imgLink !== "") {
      writer.uint32(82).string(message.imgLink);
    }
    if (message.perAmount !== 0) {
      writer.uint32(88).uint32(message.perAmount);
    }
    if (message.maxIssuable !== 0) {
      writer.uint32(96).uint32(message.maxIssuable);
    }
    if (message.startDate !== "") {
      writer.uint32(106).string(message.startDate);
    }
    if (message.endDate !== "") {
      writer.uint32(114).string(message.endDate);
    }
    if (message.endNotSet === true) {
      writer.uint32(120).bool(message.endNotSet);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollRegistRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollRegistRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pollType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.multiYn = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.contents = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.questions = reader.bytes();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.correctAns = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.pagesQuestionsCnt = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.imgLink = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.perAmount = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.maxIssuable = reader.uint32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.startDate = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.endDate = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.endNotSet = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollRegistRequest {
    return {
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      pollType: isSet(object.pollType) ? pollTypeFromJSON(object.pollType) : 0,
      multiYn: isSet(object.multiYn) ? globalThis.Boolean(object.multiYn) : false,
      category: isSet(object.category) ? pollInfoCategoryFromJSON(object.category) : 0,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      contents: isSet(object.contents) ? globalThis.String(object.contents) : "",
      questions: isSet(object.questions) ? bytesFromBase64(object.questions) : new Uint8Array(0),
      correctAns: isSet(object.correctAns) ? globalThis.String(object.correctAns) : undefined,
      pagesQuestionsCnt: isSet(object.pagesQuestionsCnt) ? globalThis.String(object.pagesQuestionsCnt) : "",
      imgLink: isSet(object.imgLink) ? globalThis.String(object.imgLink) : "",
      perAmount: isSet(object.perAmount) ? globalThis.Number(object.perAmount) : 0,
      maxIssuable: isSet(object.maxIssuable) ? globalThis.Number(object.maxIssuable) : 0,
      startDate: isSet(object.startDate) ? globalThis.String(object.startDate) : "",
      endDate: isSet(object.endDate) ? globalThis.String(object.endDate) : "",
      endNotSet: isSet(object.endNotSet) ? globalThis.Boolean(object.endNotSet) : false,
    };
  },

  toJSON(message: PollRegistRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.pollType !== 0) {
      obj.pollType = pollTypeToJSON(message.pollType);
    }
    if (message.multiYn === true) {
      obj.multiYn = message.multiYn;
    }
    if (message.category !== 0) {
      obj.category = pollInfoCategoryToJSON(message.category);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.contents !== "") {
      obj.contents = message.contents;
    }
    if (message.questions.length !== 0) {
      obj.questions = base64FromBytes(message.questions);
    }
    if (message.correctAns !== undefined) {
      obj.correctAns = message.correctAns;
    }
    if (message.pagesQuestionsCnt !== "") {
      obj.pagesQuestionsCnt = message.pagesQuestionsCnt;
    }
    if (message.imgLink !== "") {
      obj.imgLink = message.imgLink;
    }
    if (message.perAmount !== 0) {
      obj.perAmount = Math.round(message.perAmount);
    }
    if (message.maxIssuable !== 0) {
      obj.maxIssuable = Math.round(message.maxIssuable);
    }
    if (message.startDate !== "") {
      obj.startDate = message.startDate;
    }
    if (message.endDate !== "") {
      obj.endDate = message.endDate;
    }
    if (message.endNotSet === true) {
      obj.endNotSet = message.endNotSet;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollRegistRequest>, I>>(base?: I): PollRegistRequest {
    return PollRegistRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollRegistRequest>, I>>(object: I): PollRegistRequest {
    const message = createBasePollRegistRequest();
    message.pollId = object.pollId ?? "";
    message.pollType = object.pollType ?? 0;
    message.multiYn = object.multiYn ?? false;
    message.category = object.category ?? 0;
    message.title = object.title ?? "";
    message.contents = object.contents ?? "";
    message.questions = object.questions ?? new Uint8Array(0);
    message.correctAns = object.correctAns ?? undefined;
    message.pagesQuestionsCnt = object.pagesQuestionsCnt ?? "";
    message.imgLink = object.imgLink ?? "";
    message.perAmount = object.perAmount ?? 0;
    message.maxIssuable = object.maxIssuable ?? 0;
    message.startDate = object.startDate ?? "";
    message.endDate = object.endDate ?? "";
    message.endNotSet = object.endNotSet ?? false;
    return message;
  },
};

function createBasePollDetailRequest(): PollDetailRequest {
  return { pollId: "" };
}

export const PollDetailRequest = {
  encode(message: PollDetailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollDetailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollDetailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollDetailRequest {
    return { pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "" };
  },

  toJSON(message: PollDetailRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollDetailRequest>, I>>(base?: I): PollDetailRequest {
    return PollDetailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollDetailRequest>, I>>(object: I): PollDetailRequest {
    const message = createBasePollDetailRequest();
    message.pollId = object.pollId ?? "";
    return message;
  },
};

function createBasePollDetailResponse(): PollDetailResponse {
  return { code: 0, pollReport: undefined, exhaustionPoint: undefined, companyBalance: undefined };
}

export const PollDetailResponse = {
  encode(message: PollDetailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.pollReport !== undefined) {
      PollReport.encode(message.pollReport, writer.uint32(18).fork()).ldelim();
    }
    if (message.exhaustionPoint !== undefined) {
      writer.uint32(24).uint64(message.exhaustionPoint);
    }
    if (message.companyBalance !== undefined) {
      writer.uint32(32).uint64(message.companyBalance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollDetailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollDetailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollReport = PollReport.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.exhaustionPoint = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.companyBalance = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollDetailResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      pollReport: isSet(object.pollReport) ? PollReport.fromJSON(object.pollReport) : undefined,
      exhaustionPoint: isSet(object.exhaustionPoint) ? globalThis.Number(object.exhaustionPoint) : undefined,
      companyBalance: isSet(object.companyBalance) ? globalThis.Number(object.companyBalance) : undefined,
    };
  },

  toJSON(message: PollDetailResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.pollReport !== undefined) {
      obj.pollReport = PollReport.toJSON(message.pollReport);
    }
    if (message.exhaustionPoint !== undefined) {
      obj.exhaustionPoint = Math.round(message.exhaustionPoint);
    }
    if (message.companyBalance !== undefined) {
      obj.companyBalance = Math.round(message.companyBalance);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollDetailResponse>, I>>(base?: I): PollDetailResponse {
    return PollDetailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollDetailResponse>, I>>(object: I): PollDetailResponse {
    const message = createBasePollDetailResponse();
    message.code = object.code ?? 0;
    message.pollReport = (object.pollReport !== undefined && object.pollReport !== null)
      ? PollReport.fromPartial(object.pollReport)
      : undefined;
    message.exhaustionPoint = object.exhaustionPoint ?? undefined;
    message.companyBalance = object.companyBalance ?? undefined;
    return message;
  },
};

function createBasePollDetailMobileRequest(): PollDetailMobileRequest {
  return { pollId: "", shortAnswerCount: undefined };
}

export const PollDetailMobileRequest = {
  encode(message: PollDetailMobileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    if (message.shortAnswerCount !== undefined) {
      writer.uint32(16).uint32(message.shortAnswerCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollDetailMobileRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollDetailMobileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.shortAnswerCount = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollDetailMobileRequest {
    return {
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      shortAnswerCount: isSet(object.shortAnswerCount) ? globalThis.Number(object.shortAnswerCount) : undefined,
    };
  },

  toJSON(message: PollDetailMobileRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.shortAnswerCount !== undefined) {
      obj.shortAnswerCount = Math.round(message.shortAnswerCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollDetailMobileRequest>, I>>(base?: I): PollDetailMobileRequest {
    return PollDetailMobileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollDetailMobileRequest>, I>>(object: I): PollDetailMobileRequest {
    const message = createBasePollDetailMobileRequest();
    message.pollId = object.pollId ?? "";
    message.shortAnswerCount = object.shortAnswerCount ?? undefined;
    return message;
  },
};

function createBasePollDetailMobileResponse(): PollDetailMobileResponse {
  return { code: 0, pollReport: undefined, pollAnswerInfo: "", pollStatistics: undefined, commentCount: 0 };
}

export const PollDetailMobileResponse = {
  encode(message: PollDetailMobileResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.pollReport !== undefined) {
      PollReport.encode(message.pollReport, writer.uint32(18).fork()).ldelim();
    }
    if (message.pollAnswerInfo !== "") {
      writer.uint32(26).string(message.pollAnswerInfo);
    }
    if (message.pollStatistics !== undefined) {
      PollStatistics.encode(message.pollStatistics, writer.uint32(34).fork()).ldelim();
    }
    if (message.commentCount !== 0) {
      writer.uint32(40).uint64(message.commentCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollDetailMobileResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollDetailMobileResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollReport = PollReport.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pollAnswerInfo = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pollStatistics = PollStatistics.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.commentCount = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollDetailMobileResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      pollReport: isSet(object.pollReport) ? PollReport.fromJSON(object.pollReport) : undefined,
      pollAnswerInfo: isSet(object.pollAnswerInfo) ? globalThis.String(object.pollAnswerInfo) : "",
      pollStatistics: isSet(object.pollStatistics) ? PollStatistics.fromJSON(object.pollStatistics) : undefined,
      commentCount: isSet(object.commentCount) ? globalThis.Number(object.commentCount) : 0,
    };
  },

  toJSON(message: PollDetailMobileResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.pollReport !== undefined) {
      obj.pollReport = PollReport.toJSON(message.pollReport);
    }
    if (message.pollAnswerInfo !== "") {
      obj.pollAnswerInfo = message.pollAnswerInfo;
    }
    if (message.pollStatistics !== undefined) {
      obj.pollStatistics = PollStatistics.toJSON(message.pollStatistics);
    }
    if (message.commentCount !== 0) {
      obj.commentCount = Math.round(message.commentCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollDetailMobileResponse>, I>>(base?: I): PollDetailMobileResponse {
    return PollDetailMobileResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollDetailMobileResponse>, I>>(object: I): PollDetailMobileResponse {
    const message = createBasePollDetailMobileResponse();
    message.code = object.code ?? 0;
    message.pollReport = (object.pollReport !== undefined && object.pollReport !== null)
      ? PollReport.fromPartial(object.pollReport)
      : undefined;
    message.pollAnswerInfo = object.pollAnswerInfo ?? "";
    message.pollStatistics = (object.pollStatistics !== undefined && object.pollStatistics !== null)
      ? PollStatistics.fromPartial(object.pollStatistics)
      : undefined;
    message.commentCount = object.commentCount ?? 0;
    return message;
  },
};

function createBasePollResultRequest(): PollResultRequest {
  return { id: 0, shortAnswerCount: undefined };
}

export const PollResultRequest = {
  encode(message: PollResultRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.shortAnswerCount !== undefined) {
      writer.uint32(16).uint32(message.shortAnswerCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollResultRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollResultRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.shortAnswerCount = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollResultRequest {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      shortAnswerCount: isSet(object.shortAnswerCount) ? globalThis.Number(object.shortAnswerCount) : undefined,
    };
  },

  toJSON(message: PollResultRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.shortAnswerCount !== undefined) {
      obj.shortAnswerCount = Math.round(message.shortAnswerCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollResultRequest>, I>>(base?: I): PollResultRequest {
    return PollResultRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollResultRequest>, I>>(object: I): PollResultRequest {
    const message = createBasePollResultRequest();
    message.id = object.id ?? 0;
    message.shortAnswerCount = object.shortAnswerCount ?? undefined;
    return message;
  },
};

function createBasePollResultResponse(): PollResultResponse {
  return { code: 0, pollReport: undefined, pollStatistics: undefined, commentCount: 0 };
}

export const PollResultResponse = {
  encode(message: PollResultResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.pollReport !== undefined) {
      PollReport.encode(message.pollReport, writer.uint32(18).fork()).ldelim();
    }
    if (message.pollStatistics !== undefined) {
      PollStatistics.encode(message.pollStatistics, writer.uint32(26).fork()).ldelim();
    }
    if (message.commentCount !== 0) {
      writer.uint32(32).uint64(message.commentCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollResultResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollResultResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollReport = PollReport.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pollStatistics = PollStatistics.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.commentCount = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollResultResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      pollReport: isSet(object.pollReport) ? PollReport.fromJSON(object.pollReport) : undefined,
      pollStatistics: isSet(object.pollStatistics) ? PollStatistics.fromJSON(object.pollStatistics) : undefined,
      commentCount: isSet(object.commentCount) ? globalThis.Number(object.commentCount) : 0,
    };
  },

  toJSON(message: PollResultResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.pollReport !== undefined) {
      obj.pollReport = PollReport.toJSON(message.pollReport);
    }
    if (message.pollStatistics !== undefined) {
      obj.pollStatistics = PollStatistics.toJSON(message.pollStatistics);
    }
    if (message.commentCount !== 0) {
      obj.commentCount = Math.round(message.commentCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollResultResponse>, I>>(base?: I): PollResultResponse {
    return PollResultResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollResultResponse>, I>>(object: I): PollResultResponse {
    const message = createBasePollResultResponse();
    message.code = object.code ?? 0;
    message.pollReport = (object.pollReport !== undefined && object.pollReport !== null)
      ? PollReport.fromPartial(object.pollReport)
      : undefined;
    message.pollStatistics = (object.pollStatistics !== undefined && object.pollStatistics !== null)
      ? PollStatistics.fromPartial(object.pollStatistics)
      : undefined;
    message.commentCount = object.commentCount ?? 0;
    return message;
  },
};

function createBasePollStatistics(): PollStatistics {
  return { pollParticipantInfoStatistics: undefined, pollAnswerStatisticsInfo: [], pollShortAnswerInfoList: [] };
}

export const PollStatistics = {
  encode(message: PollStatistics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollParticipantInfoStatistics !== undefined) {
      PollParticipantInfoStatistics.encode(message.pollParticipantInfoStatistics, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.pollAnswerStatisticsInfo) {
      PollAnswerStatisticsInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.pollShortAnswerInfoList) {
      PollShortAnswerInfo.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollStatistics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollStatistics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollParticipantInfoStatistics = PollParticipantInfoStatistics.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollAnswerStatisticsInfo.push(PollAnswerStatisticsInfo.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pollShortAnswerInfoList.push(PollShortAnswerInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollStatistics {
    return {
      pollParticipantInfoStatistics: isSet(object.pollParticipantInfoStatistics)
        ? PollParticipantInfoStatistics.fromJSON(object.pollParticipantInfoStatistics)
        : undefined,
      pollAnswerStatisticsInfo: globalThis.Array.isArray(object?.pollAnswerStatisticsInfo)
        ? object.pollAnswerStatisticsInfo.map((e: any) => PollAnswerStatisticsInfo.fromJSON(e))
        : [],
      pollShortAnswerInfoList: globalThis.Array.isArray(object?.pollShortAnswerInfoList)
        ? object.pollShortAnswerInfoList.map((e: any) => PollShortAnswerInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PollStatistics): unknown {
    const obj: any = {};
    if (message.pollParticipantInfoStatistics !== undefined) {
      obj.pollParticipantInfoStatistics = PollParticipantInfoStatistics.toJSON(message.pollParticipantInfoStatistics);
    }
    if (message.pollAnswerStatisticsInfo?.length) {
      obj.pollAnswerStatisticsInfo = message.pollAnswerStatisticsInfo.map((e) => PollAnswerStatisticsInfo.toJSON(e));
    }
    if (message.pollShortAnswerInfoList?.length) {
      obj.pollShortAnswerInfoList = message.pollShortAnswerInfoList.map((e) => PollShortAnswerInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollStatistics>, I>>(base?: I): PollStatistics {
    return PollStatistics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollStatistics>, I>>(object: I): PollStatistics {
    const message = createBasePollStatistics();
    message.pollParticipantInfoStatistics =
      (object.pollParticipantInfoStatistics !== undefined && object.pollParticipantInfoStatistics !== null)
        ? PollParticipantInfoStatistics.fromPartial(object.pollParticipantInfoStatistics)
        : undefined;
    message.pollAnswerStatisticsInfo =
      object.pollAnswerStatisticsInfo?.map((e) => PollAnswerStatisticsInfo.fromPartial(e)) || [];
    message.pollShortAnswerInfoList = object.pollShortAnswerInfoList?.map((e) => PollShortAnswerInfo.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBasePollAnswerStatisticsInfo(): PollAnswerStatisticsInfo {
  return { questionId: 0, pageNum: 0, questionType: 0, participants: 0, statistics: "", updatedAt: "" };
}

export const PollAnswerStatisticsInfo = {
  encode(message: PollAnswerStatisticsInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.questionId !== 0) {
      writer.uint32(8).uint32(message.questionId);
    }
    if (message.pageNum !== 0) {
      writer.uint32(16).uint32(message.pageNum);
    }
    if (message.questionType !== 0) {
      writer.uint32(24).int32(message.questionType);
    }
    if (message.participants !== 0) {
      writer.uint32(32).uint64(message.participants);
    }
    if (message.statistics !== "") {
      writer.uint32(42).string(message.statistics);
    }
    if (message.updatedAt !== "") {
      writer.uint32(50).string(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollAnswerStatisticsInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollAnswerStatisticsInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.questionId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageNum = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.questionType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.participants = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.statistics = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollAnswerStatisticsInfo {
    return {
      questionId: isSet(object.questionId) ? globalThis.Number(object.questionId) : 0,
      pageNum: isSet(object.pageNum) ? globalThis.Number(object.pageNum) : 0,
      questionType: isSet(object.questionType) ? questionTypeFromJSON(object.questionType) : 0,
      participants: isSet(object.participants) ? globalThis.Number(object.participants) : 0,
      statistics: isSet(object.statistics) ? globalThis.String(object.statistics) : "",
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : "",
    };
  },

  toJSON(message: PollAnswerStatisticsInfo): unknown {
    const obj: any = {};
    if (message.questionId !== 0) {
      obj.questionId = Math.round(message.questionId);
    }
    if (message.pageNum !== 0) {
      obj.pageNum = Math.round(message.pageNum);
    }
    if (message.questionType !== 0) {
      obj.questionType = questionTypeToJSON(message.questionType);
    }
    if (message.participants !== 0) {
      obj.participants = Math.round(message.participants);
    }
    if (message.statistics !== "") {
      obj.statistics = message.statistics;
    }
    if (message.updatedAt !== "") {
      obj.updatedAt = message.updatedAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollAnswerStatisticsInfo>, I>>(base?: I): PollAnswerStatisticsInfo {
    return PollAnswerStatisticsInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollAnswerStatisticsInfo>, I>>(object: I): PollAnswerStatisticsInfo {
    const message = createBasePollAnswerStatisticsInfo();
    message.questionId = object.questionId ?? 0;
    message.pageNum = object.pageNum ?? 0;
    message.questionType = object.questionType ?? 0;
    message.participants = object.participants ?? 0;
    message.statistics = object.statistics ?? "";
    message.updatedAt = object.updatedAt ?? "";
    return message;
  },
};

function createBasePollShortAnswerInfo(): PollShortAnswerInfo {
  return { questionId: 0, shortAnswerList: [] };
}

export const PollShortAnswerInfo = {
  encode(message: PollShortAnswerInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.questionId !== 0) {
      writer.uint32(8).uint32(message.questionId);
    }
    for (const v of message.shortAnswerList) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollShortAnswerInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollShortAnswerInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.questionId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.shortAnswerList.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollShortAnswerInfo {
    return {
      questionId: isSet(object.questionId) ? globalThis.Number(object.questionId) : 0,
      shortAnswerList: globalThis.Array.isArray(object?.shortAnswerList)
        ? object.shortAnswerList.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: PollShortAnswerInfo): unknown {
    const obj: any = {};
    if (message.questionId !== 0) {
      obj.questionId = Math.round(message.questionId);
    }
    if (message.shortAnswerList?.length) {
      obj.shortAnswerList = message.shortAnswerList;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollShortAnswerInfo>, I>>(base?: I): PollShortAnswerInfo {
    return PollShortAnswerInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollShortAnswerInfo>, I>>(object: I): PollShortAnswerInfo {
    const message = createBasePollShortAnswerInfo();
    message.questionId = object.questionId ?? 0;
    message.shortAnswerList = object.shortAnswerList?.map((e) => e) || [];
    return message;
  },
};

function createBasePollParticipantInfoStatistics(): PollParticipantInfoStatistics {
  return { genderStatistics: undefined, AgeStatistics: undefined };
}

export const PollParticipantInfoStatistics = {
  encode(message: PollParticipantInfoStatistics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.genderStatistics !== undefined) {
      PollGenderStatistics.encode(message.genderStatistics, writer.uint32(10).fork()).ldelim();
    }
    if (message.AgeStatistics !== undefined) {
      PollAgeStatistics.encode(message.AgeStatistics, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollParticipantInfoStatistics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollParticipantInfoStatistics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.genderStatistics = PollGenderStatistics.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.AgeStatistics = PollAgeStatistics.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollParticipantInfoStatistics {
    return {
      genderStatistics: isSet(object.genderStatistics)
        ? PollGenderStatistics.fromJSON(object.genderStatistics)
        : undefined,
      AgeStatistics: isSet(object.AgeStatistics) ? PollAgeStatistics.fromJSON(object.AgeStatistics) : undefined,
    };
  },

  toJSON(message: PollParticipantInfoStatistics): unknown {
    const obj: any = {};
    if (message.genderStatistics !== undefined) {
      obj.genderStatistics = PollGenderStatistics.toJSON(message.genderStatistics);
    }
    if (message.AgeStatistics !== undefined) {
      obj.AgeStatistics = PollAgeStatistics.toJSON(message.AgeStatistics);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollParticipantInfoStatistics>, I>>(base?: I): PollParticipantInfoStatistics {
    return PollParticipantInfoStatistics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollParticipantInfoStatistics>, I>>(
    object: I,
  ): PollParticipantInfoStatistics {
    const message = createBasePollParticipantInfoStatistics();
    message.genderStatistics = (object.genderStatistics !== undefined && object.genderStatistics !== null)
      ? PollGenderStatistics.fromPartial(object.genderStatistics)
      : undefined;
    message.AgeStatistics = (object.AgeStatistics !== undefined && object.AgeStatistics !== null)
      ? PollAgeStatistics.fromPartial(object.AgeStatistics)
      : undefined;
    return message;
  },
};

function createBasePollGenderStatistics(): PollGenderStatistics {
  return { male: 0, female: 0 };
}

export const PollGenderStatistics = {
  encode(message: PollGenderStatistics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.male !== 0) {
      writer.uint32(8).uint64(message.male);
    }
    if (message.female !== 0) {
      writer.uint32(16).uint64(message.female);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollGenderStatistics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollGenderStatistics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.male = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.female = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollGenderStatistics {
    return {
      male: isSet(object.male) ? globalThis.Number(object.male) : 0,
      female: isSet(object.female) ? globalThis.Number(object.female) : 0,
    };
  },

  toJSON(message: PollGenderStatistics): unknown {
    const obj: any = {};
    if (message.male !== 0) {
      obj.male = Math.round(message.male);
    }
    if (message.female !== 0) {
      obj.female = Math.round(message.female);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollGenderStatistics>, I>>(base?: I): PollGenderStatistics {
    return PollGenderStatistics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollGenderStatistics>, I>>(object: I): PollGenderStatistics {
    const message = createBasePollGenderStatistics();
    message.male = object.male ?? 0;
    message.female = object.female ?? 0;
    return message;
  },
};

function createBasePollAgeStatistics(): PollAgeStatistics {
  return { teenager: 0, twenties: 0, thirties: 0, forties: 0, fifties: 0, sixties: 0 };
}

export const PollAgeStatistics = {
  encode(message: PollAgeStatistics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.teenager !== 0) {
      writer.uint32(8).uint64(message.teenager);
    }
    if (message.twenties !== 0) {
      writer.uint32(16).uint64(message.twenties);
    }
    if (message.thirties !== 0) {
      writer.uint32(24).uint64(message.thirties);
    }
    if (message.forties !== 0) {
      writer.uint32(32).uint64(message.forties);
    }
    if (message.fifties !== 0) {
      writer.uint32(40).uint64(message.fifties);
    }
    if (message.sixties !== 0) {
      writer.uint32(48).uint64(message.sixties);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollAgeStatistics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollAgeStatistics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.teenager = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.twenties = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.thirties = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.forties = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.fifties = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.sixties = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollAgeStatistics {
    return {
      teenager: isSet(object.teenager) ? globalThis.Number(object.teenager) : 0,
      twenties: isSet(object.twenties) ? globalThis.Number(object.twenties) : 0,
      thirties: isSet(object.thirties) ? globalThis.Number(object.thirties) : 0,
      forties: isSet(object.forties) ? globalThis.Number(object.forties) : 0,
      fifties: isSet(object.fifties) ? globalThis.Number(object.fifties) : 0,
      sixties: isSet(object.sixties) ? globalThis.Number(object.sixties) : 0,
    };
  },

  toJSON(message: PollAgeStatistics): unknown {
    const obj: any = {};
    if (message.teenager !== 0) {
      obj.teenager = Math.round(message.teenager);
    }
    if (message.twenties !== 0) {
      obj.twenties = Math.round(message.twenties);
    }
    if (message.thirties !== 0) {
      obj.thirties = Math.round(message.thirties);
    }
    if (message.forties !== 0) {
      obj.forties = Math.round(message.forties);
    }
    if (message.fifties !== 0) {
      obj.fifties = Math.round(message.fifties);
    }
    if (message.sixties !== 0) {
      obj.sixties = Math.round(message.sixties);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollAgeStatistics>, I>>(base?: I): PollAgeStatistics {
    return PollAgeStatistics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollAgeStatistics>, I>>(object: I): PollAgeStatistics {
    const message = createBasePollAgeStatistics();
    message.teenager = object.teenager ?? 0;
    message.twenties = object.twenties ?? 0;
    message.thirties = object.thirties ?? 0;
    message.forties = object.forties ?? 0;
    message.fifties = object.fifties ?? 0;
    message.sixties = object.sixties ?? 0;
    return message;
  },
};

function createBasePollStatusChangeRequest(): PollStatusChangeRequest {
  return { pollId: "", status: 0 };
}

export const PollStatusChangeRequest = {
  encode(message: PollStatusChangeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollStatusChangeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollStatusChangeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollStatusChangeRequest {
    return {
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      status: isSet(object.status) ? pollInfoStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: PollStatusChangeRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.status !== 0) {
      obj.status = pollInfoStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollStatusChangeRequest>, I>>(base?: I): PollStatusChangeRequest {
    return PollStatusChangeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollStatusChangeRequest>, I>>(object: I): PollStatusChangeRequest {
    const message = createBasePollStatusChangeRequest();
    message.pollId = object.pollId ?? "";
    message.status = object.status ?? 0;
    return message;
  },
};

function createBasePollParticipationRequest(): PollParticipationRequest {
  return { pollId: "" };
}

export const PollParticipationRequest = {
  encode(message: PollParticipationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollParticipationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollParticipationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollParticipationRequest {
    return { pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "" };
  },

  toJSON(message: PollParticipationRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollParticipationRequest>, I>>(base?: I): PollParticipationRequest {
    return PollParticipationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollParticipationRequest>, I>>(object: I): PollParticipationRequest {
    const message = createBasePollParticipationRequest();
    message.pollId = object.pollId ?? "";
    return message;
  },
};

function createBasePollParticipationResponse(): PollParticipationResponse {
  return { code: 0, pollReport: undefined, pollAnswerStatistics: undefined };
}

export const PollParticipationResponse = {
  encode(message: PollParticipationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.pollReport !== undefined) {
      PollReport.encode(message.pollReport, writer.uint32(18).fork()).ldelim();
    }
    if (message.pollAnswerStatistics !== undefined) {
      writer.uint32(26).string(message.pollAnswerStatistics);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollParticipationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollParticipationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollReport = PollReport.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pollAnswerStatistics = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollParticipationResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      pollReport: isSet(object.pollReport) ? PollReport.fromJSON(object.pollReport) : undefined,
      pollAnswerStatistics: isSet(object.pollAnswerStatistics)
        ? globalThis.String(object.pollAnswerStatistics)
        : undefined,
    };
  },

  toJSON(message: PollParticipationResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.pollReport !== undefined) {
      obj.pollReport = PollReport.toJSON(message.pollReport);
    }
    if (message.pollAnswerStatistics !== undefined) {
      obj.pollAnswerStatistics = message.pollAnswerStatistics;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollParticipationResponse>, I>>(base?: I): PollParticipationResponse {
    return PollParticipationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollParticipationResponse>, I>>(object: I): PollParticipationResponse {
    const message = createBasePollParticipationResponse();
    message.code = object.code ?? 0;
    message.pollReport = (object.pollReport !== undefined && object.pollReport !== null)
      ? PollReport.fromPartial(object.pollReport)
      : undefined;
    message.pollAnswerStatistics = object.pollAnswerStatistics ?? undefined;
    return message;
  },
};

function createBasePollAnswerRequest(): PollAnswerRequest {
  return { pollId: "", pollAnswerList: "", gender: false, ageYear: 0 };
}

export const PollAnswerRequest = {
  encode(message: PollAnswerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    if (message.pollAnswerList !== "") {
      writer.uint32(18).string(message.pollAnswerList);
    }
    if (message.gender === true) {
      writer.uint32(24).bool(message.gender);
    }
    if (message.ageYear !== 0) {
      writer.uint32(32).uint32(message.ageYear);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollAnswerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollAnswerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollAnswerList = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.gender = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.ageYear = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollAnswerRequest {
    return {
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      pollAnswerList: isSet(object.pollAnswerList) ? globalThis.String(object.pollAnswerList) : "",
      gender: isSet(object.gender) ? globalThis.Boolean(object.gender) : false,
      ageYear: isSet(object.ageYear) ? globalThis.Number(object.ageYear) : 0,
    };
  },

  toJSON(message: PollAnswerRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.pollAnswerList !== "") {
      obj.pollAnswerList = message.pollAnswerList;
    }
    if (message.gender === true) {
      obj.gender = message.gender;
    }
    if (message.ageYear !== 0) {
      obj.ageYear = Math.round(message.ageYear);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollAnswerRequest>, I>>(base?: I): PollAnswerRequest {
    return PollAnswerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollAnswerRequest>, I>>(object: I): PollAnswerRequest {
    const message = createBasePollAnswerRequest();
    message.pollId = object.pollId ?? "";
    message.pollAnswerList = object.pollAnswerList ?? "";
    message.gender = object.gender ?? false;
    message.ageYear = object.ageYear ?? 0;
    return message;
  },
};

function createBasePollAnswerResponse(): PollAnswerResponse {
  return { code: 0, answerId: "", providePoint: 0 };
}

export const PollAnswerResponse = {
  encode(message: PollAnswerResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.answerId !== "") {
      writer.uint32(18).string(message.answerId);
    }
    if (message.providePoint !== 0) {
      writer.uint32(24).uint32(message.providePoint);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollAnswerResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollAnswerResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.answerId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.providePoint = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollAnswerResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      answerId: isSet(object.answerId) ? globalThis.String(object.answerId) : "",
      providePoint: isSet(object.providePoint) ? globalThis.Number(object.providePoint) : 0,
    };
  },

  toJSON(message: PollAnswerResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.answerId !== "") {
      obj.answerId = message.answerId;
    }
    if (message.providePoint !== 0) {
      obj.providePoint = Math.round(message.providePoint);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollAnswerResponse>, I>>(base?: I): PollAnswerResponse {
    return PollAnswerResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollAnswerResponse>, I>>(object: I): PollAnswerResponse {
    const message = createBasePollAnswerResponse();
    message.code = object.code ?? 0;
    message.answerId = object.answerId ?? "";
    message.providePoint = object.providePoint ?? 0;
    return message;
  },
};

function createBasePollProvidePointRequest(): PollProvidePointRequest {
  return { pollId: "", answerId: "" };
}

export const PollProvidePointRequest = {
  encode(message: PollProvidePointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    if (message.answerId !== "") {
      writer.uint32(18).string(message.answerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollProvidePointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollProvidePointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.answerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollProvidePointRequest {
    return {
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      answerId: isSet(object.answerId) ? globalThis.String(object.answerId) : "",
    };
  },

  toJSON(message: PollProvidePointRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.answerId !== "") {
      obj.answerId = message.answerId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollProvidePointRequest>, I>>(base?: I): PollProvidePointRequest {
    return PollProvidePointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollProvidePointRequest>, I>>(object: I): PollProvidePointRequest {
    const message = createBasePollProvidePointRequest();
    message.pollId = object.pollId ?? "";
    message.answerId = object.answerId ?? "";
    return message;
  },
};

function createBasePollProvidePointResponse(): PollProvidePointResponse {
  return { code: 0 };
}

export const PollProvidePointResponse = {
  encode(message: PollProvidePointResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollProvidePointResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollProvidePointResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollProvidePointResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: PollProvidePointResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollProvidePointResponse>, I>>(base?: I): PollProvidePointResponse {
    return PollProvidePointResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollProvidePointResponse>, I>>(object: I): PollProvidePointResponse {
    const message = createBasePollProvidePointResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseSpamCountResponse(): SpamCountResponse {
  return { code: 0, spamCount: undefined };
}

export const SpamCountResponse = {
  encode(message: SpamCountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.spamCount !== undefined) {
      SpamCount.encode(message.spamCount, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpamCountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpamCountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spamCount = SpamCount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpamCountResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      spamCount: isSet(object.spamCount) ? SpamCount.fromJSON(object.spamCount) : undefined,
    };
  },

  toJSON(message: SpamCountResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.spamCount !== undefined) {
      obj.spamCount = SpamCount.toJSON(message.spamCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpamCountResponse>, I>>(base?: I): SpamCountResponse {
    return SpamCountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpamCountResponse>, I>>(object: I): SpamCountResponse {
    const message = createBaseSpamCountResponse();
    message.code = object.code ?? 0;
    message.spamCount = (object.spamCount !== undefined && object.spamCount !== null)
      ? SpamCount.fromPartial(object.spamCount)
      : undefined;
    return message;
  },
};

function createBaseSpamCount(): SpamCount {
  return { waitingCnt: 0, acceptCnt: 0, nonAcceptCnt: 0 };
}

export const SpamCount = {
  encode(message: SpamCount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.waitingCnt !== 0) {
      writer.uint32(8).uint32(message.waitingCnt);
    }
    if (message.acceptCnt !== 0) {
      writer.uint32(16).uint32(message.acceptCnt);
    }
    if (message.nonAcceptCnt !== 0) {
      writer.uint32(24).uint32(message.nonAcceptCnt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpamCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpamCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.waitingCnt = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.acceptCnt = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.nonAcceptCnt = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpamCount {
    return {
      waitingCnt: isSet(object.waitingCnt) ? globalThis.Number(object.waitingCnt) : 0,
      acceptCnt: isSet(object.acceptCnt) ? globalThis.Number(object.acceptCnt) : 0,
      nonAcceptCnt: isSet(object.nonAcceptCnt) ? globalThis.Number(object.nonAcceptCnt) : 0,
    };
  },

  toJSON(message: SpamCount): unknown {
    const obj: any = {};
    if (message.waitingCnt !== 0) {
      obj.waitingCnt = Math.round(message.waitingCnt);
    }
    if (message.acceptCnt !== 0) {
      obj.acceptCnt = Math.round(message.acceptCnt);
    }
    if (message.nonAcceptCnt !== 0) {
      obj.nonAcceptCnt = Math.round(message.nonAcceptCnt);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpamCount>, I>>(base?: I): SpamCount {
    return SpamCount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpamCount>, I>>(object: I): SpamCount {
    const message = createBaseSpamCount();
    message.waitingCnt = object.waitingCnt ?? 0;
    message.acceptCnt = object.acceptCnt ?? 0;
    message.nonAcceptCnt = object.nonAcceptCnt ?? 0;
    return message;
  },
};

function createBaseSpamInfoListResponse(): SpamInfoListResponse {
  return { code: 0, spamInfo: [] };
}

export const SpamInfoListResponse = {
  encode(message: SpamInfoListResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.spamInfo) {
      SpamInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpamInfoListResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpamInfoListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spamInfo.push(SpamInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpamInfoListResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      spamInfo: globalThis.Array.isArray(object?.spamInfo) ? object.spamInfo.map((e: any) => SpamInfo.fromJSON(e)) : [],
    };
  },

  toJSON(message: SpamInfoListResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.spamInfo?.length) {
      obj.spamInfo = message.spamInfo.map((e) => SpamInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpamInfoListResponse>, I>>(base?: I): SpamInfoListResponse {
    return SpamInfoListResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpamInfoListResponse>, I>>(object: I): SpamInfoListResponse {
    const message = createBaseSpamInfoListResponse();
    message.code = object.code ?? 0;
    message.spamInfo = object.spamInfo?.map((e) => SpamInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSpamInfo(): SpamInfo {
  return {
    spamNum: 0,
    spamStatus: 0,
    spamReportTime: "",
    pollId: "",
    pollTitle: "",
    spamReportId: "",
    isReply: false,
    spamReportContent: new Uint8Array(0),
    reportCount: 0,
    spamManager: "",
  };
}

export const SpamInfo = {
  encode(message: SpamInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spamNum !== 0) {
      writer.uint32(8).uint64(message.spamNum);
    }
    if (message.spamStatus !== 0) {
      writer.uint32(16).int32(message.spamStatus);
    }
    if (message.spamReportTime !== "") {
      writer.uint32(26).string(message.spamReportTime);
    }
    if (message.pollId !== "") {
      writer.uint32(34).string(message.pollId);
    }
    if (message.pollTitle !== "") {
      writer.uint32(42).string(message.pollTitle);
    }
    if (message.spamReportId !== "") {
      writer.uint32(50).string(message.spamReportId);
    }
    if (message.isReply === true) {
      writer.uint32(56).bool(message.isReply);
    }
    if (message.spamReportContent.length !== 0) {
      writer.uint32(66).bytes(message.spamReportContent);
    }
    if (message.reportCount !== 0) {
      writer.uint32(72).uint64(message.reportCount);
    }
    if (message.spamManager !== "") {
      writer.uint32(82).string(message.spamManager);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpamInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpamInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.spamNum = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.spamStatus = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.spamReportTime = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.pollTitle = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.spamReportId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isReply = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.spamReportContent = reader.bytes();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.reportCount = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.spamManager = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpamInfo {
    return {
      spamNum: isSet(object.spamNum) ? globalThis.Number(object.spamNum) : 0,
      spamStatus: isSet(object.spamStatus) ? spamStatusFromJSON(object.spamStatus) : 0,
      spamReportTime: isSet(object.spamReportTime) ? globalThis.String(object.spamReportTime) : "",
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      pollTitle: isSet(object.pollTitle) ? globalThis.String(object.pollTitle) : "",
      spamReportId: isSet(object.spamReportId) ? globalThis.String(object.spamReportId) : "",
      isReply: isSet(object.isReply) ? globalThis.Boolean(object.isReply) : false,
      spamReportContent: isSet(object.spamReportContent)
        ? bytesFromBase64(object.spamReportContent)
        : new Uint8Array(0),
      reportCount: isSet(object.reportCount) ? globalThis.Number(object.reportCount) : 0,
      spamManager: isSet(object.spamManager) ? globalThis.String(object.spamManager) : "",
    };
  },

  toJSON(message: SpamInfo): unknown {
    const obj: any = {};
    if (message.spamNum !== 0) {
      obj.spamNum = Math.round(message.spamNum);
    }
    if (message.spamStatus !== 0) {
      obj.spamStatus = spamStatusToJSON(message.spamStatus);
    }
    if (message.spamReportTime !== "") {
      obj.spamReportTime = message.spamReportTime;
    }
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.pollTitle !== "") {
      obj.pollTitle = message.pollTitle;
    }
    if (message.spamReportId !== "") {
      obj.spamReportId = message.spamReportId;
    }
    if (message.isReply === true) {
      obj.isReply = message.isReply;
    }
    if (message.spamReportContent.length !== 0) {
      obj.spamReportContent = base64FromBytes(message.spamReportContent);
    }
    if (message.reportCount !== 0) {
      obj.reportCount = Math.round(message.reportCount);
    }
    if (message.spamManager !== "") {
      obj.spamManager = message.spamManager;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpamInfo>, I>>(base?: I): SpamInfo {
    return SpamInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpamInfo>, I>>(object: I): SpamInfo {
    const message = createBaseSpamInfo();
    message.spamNum = object.spamNum ?? 0;
    message.spamStatus = object.spamStatus ?? 0;
    message.spamReportTime = object.spamReportTime ?? "";
    message.pollId = object.pollId ?? "";
    message.pollTitle = object.pollTitle ?? "";
    message.spamReportId = object.spamReportId ?? "";
    message.isReply = object.isReply ?? false;
    message.spamReportContent = object.spamReportContent ?? new Uint8Array(0);
    message.reportCount = object.reportCount ?? 0;
    message.spamManager = object.spamManager ?? "";
    return message;
  },
};

function createBaseSpamStatusChangeRequest(): SpamStatusChangeRequest {
  return { spamCommentIdList: [], spamReplyIdList: [], status: 0 };
}

export const SpamStatusChangeRequest = {
  encode(message: SpamStatusChangeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.spamCommentIdList) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.spamReplyIdList) {
      writer.uint32(18).string(v!);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpamStatusChangeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpamStatusChangeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spamCommentIdList.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spamReplyIdList.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpamStatusChangeRequest {
    return {
      spamCommentIdList: globalThis.Array.isArray(object?.spamCommentIdList)
        ? object.spamCommentIdList.map((e: any) => globalThis.String(e))
        : [],
      spamReplyIdList: globalThis.Array.isArray(object?.spamReplyIdList)
        ? object.spamReplyIdList.map((e: any) => globalThis.String(e))
        : [],
      status: isSet(object.status) ? spamStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: SpamStatusChangeRequest): unknown {
    const obj: any = {};
    if (message.spamCommentIdList?.length) {
      obj.spamCommentIdList = message.spamCommentIdList;
    }
    if (message.spamReplyIdList?.length) {
      obj.spamReplyIdList = message.spamReplyIdList;
    }
    if (message.status !== 0) {
      obj.status = spamStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpamStatusChangeRequest>, I>>(base?: I): SpamStatusChangeRequest {
    return SpamStatusChangeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpamStatusChangeRequest>, I>>(object: I): SpamStatusChangeRequest {
    const message = createBaseSpamStatusChangeRequest();
    message.spamCommentIdList = object.spamCommentIdList?.map((e) => e) || [];
    message.spamReplyIdList = object.spamReplyIdList?.map((e) => e) || [];
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseSpamStatusChangeResponse(): SpamStatusChangeResponse {
  return { code: 0, spamCommentIdList: [], spamReplyIdList: [] };
}

export const SpamStatusChangeResponse = {
  encode(message: SpamStatusChangeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.spamCommentIdList) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.spamReplyIdList) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpamStatusChangeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpamStatusChangeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spamCommentIdList.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.spamReplyIdList.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpamStatusChangeResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      spamCommentIdList: globalThis.Array.isArray(object?.spamCommentIdList)
        ? object.spamCommentIdList.map((e: any) => globalThis.String(e))
        : [],
      spamReplyIdList: globalThis.Array.isArray(object?.spamReplyIdList)
        ? object.spamReplyIdList.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: SpamStatusChangeResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.spamCommentIdList?.length) {
      obj.spamCommentIdList = message.spamCommentIdList;
    }
    if (message.spamReplyIdList?.length) {
      obj.spamReplyIdList = message.spamReplyIdList;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpamStatusChangeResponse>, I>>(base?: I): SpamStatusChangeResponse {
    return SpamStatusChangeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpamStatusChangeResponse>, I>>(object: I): SpamStatusChangeResponse {
    const message = createBaseSpamStatusChangeResponse();
    message.code = object.code ?? 0;
    message.spamCommentIdList = object.spamCommentIdList?.map((e) => e) || [];
    message.spamReplyIdList = object.spamReplyIdList?.map((e) => e) || [];
    return message;
  },
};

function createBaseCommentRegisterRequest(): CommentRegisterRequest {
  return { pollId: "", parentId: undefined, commentId: undefined, comment: "" };
}

export const CommentRegisterRequest = {
  encode(message: CommentRegisterRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    if (message.parentId !== undefined) {
      writer.uint32(18).string(message.parentId);
    }
    if (message.commentId !== undefined) {
      writer.uint32(26).string(message.commentId);
    }
    if (message.comment !== "") {
      writer.uint32(34).string(message.comment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommentRegisterRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommentRegisterRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.commentId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.comment = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommentRegisterRequest {
    return {
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      parentId: isSet(object.parentId) ? globalThis.String(object.parentId) : undefined,
      commentId: isSet(object.commentId) ? globalThis.String(object.commentId) : undefined,
      comment: isSet(object.comment) ? globalThis.String(object.comment) : "",
    };
  },

  toJSON(message: CommentRegisterRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.parentId !== undefined) {
      obj.parentId = message.parentId;
    }
    if (message.commentId !== undefined) {
      obj.commentId = message.commentId;
    }
    if (message.comment !== "") {
      obj.comment = message.comment;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommentRegisterRequest>, I>>(base?: I): CommentRegisterRequest {
    return CommentRegisterRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommentRegisterRequest>, I>>(object: I): CommentRegisterRequest {
    const message = createBaseCommentRegisterRequest();
    message.pollId = object.pollId ?? "";
    message.parentId = object.parentId ?? undefined;
    message.commentId = object.commentId ?? undefined;
    message.comment = object.comment ?? "";
    return message;
  },
};

function createBaseCommentRegisterResponse(): CommentRegisterResponse {
  return { code: 0 };
}

export const CommentRegisterResponse = {
  encode(message: CommentRegisterResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommentRegisterResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommentRegisterResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommentRegisterResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: CommentRegisterResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommentRegisterResponse>, I>>(base?: I): CommentRegisterResponse {
    return CommentRegisterResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommentRegisterResponse>, I>>(object: I): CommentRegisterResponse {
    const message = createBaseCommentRegisterResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseCommentDeleteRequest(): CommentDeleteRequest {
  return { pollId: "", reportId: "" };
}

export const CommentDeleteRequest = {
  encode(message: CommentDeleteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    if (message.reportId !== "") {
      writer.uint32(18).string(message.reportId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommentDeleteRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommentDeleteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.reportId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommentDeleteRequest {
    return {
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      reportId: isSet(object.reportId) ? globalThis.String(object.reportId) : "",
    };
  },

  toJSON(message: CommentDeleteRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.reportId !== "") {
      obj.reportId = message.reportId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommentDeleteRequest>, I>>(base?: I): CommentDeleteRequest {
    return CommentDeleteRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommentDeleteRequest>, I>>(object: I): CommentDeleteRequest {
    const message = createBaseCommentDeleteRequest();
    message.pollId = object.pollId ?? "";
    message.reportId = object.reportId ?? "";
    return message;
  },
};

function createBaseCommentDeleteResponse(): CommentDeleteResponse {
  return { code: 0 };
}

export const CommentDeleteResponse = {
  encode(message: CommentDeleteResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommentDeleteResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommentDeleteResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommentDeleteResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: CommentDeleteResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommentDeleteResponse>, I>>(base?: I): CommentDeleteResponse {
    return CommentDeleteResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommentDeleteResponse>, I>>(object: I): CommentDeleteResponse {
    const message = createBaseCommentDeleteResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseCommentReactionRequest(): CommentReactionRequest {
  return { pollId: "", reactionId: "", reactionCode: 0, isReply: false };
}

export const CommentReactionRequest = {
  encode(message: CommentReactionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    if (message.reactionId !== "") {
      writer.uint32(18).string(message.reactionId);
    }
    if (message.reactionCode !== 0) {
      writer.uint32(24).int32(message.reactionCode);
    }
    if (message.isReply === true) {
      writer.uint32(32).bool(message.isReply);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommentReactionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommentReactionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.reactionId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.reactionCode = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isReply = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommentReactionRequest {
    return {
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      reactionId: isSet(object.reactionId) ? globalThis.String(object.reactionId) : "",
      reactionCode: isSet(object.reactionCode) ? reactionCodeFromJSON(object.reactionCode) : 0,
      isReply: isSet(object.isReply) ? globalThis.Boolean(object.isReply) : false,
    };
  },

  toJSON(message: CommentReactionRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.reactionId !== "") {
      obj.reactionId = message.reactionId;
    }
    if (message.reactionCode !== 0) {
      obj.reactionCode = reactionCodeToJSON(message.reactionCode);
    }
    if (message.isReply === true) {
      obj.isReply = message.isReply;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommentReactionRequest>, I>>(base?: I): CommentReactionRequest {
    return CommentReactionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommentReactionRequest>, I>>(object: I): CommentReactionRequest {
    const message = createBaseCommentReactionRequest();
    message.pollId = object.pollId ?? "";
    message.reactionId = object.reactionId ?? "";
    message.reactionCode = object.reactionCode ?? 0;
    message.isReply = object.isReply ?? false;
    return message;
  },
};

function createBaseCommentReactionResponse(): CommentReactionResponse {
  return { code: 0 };
}

export const CommentReactionResponse = {
  encode(message: CommentReactionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommentReactionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommentReactionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommentReactionResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: CommentReactionResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommentReactionResponse>, I>>(base?: I): CommentReactionResponse {
    return CommentReactionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommentReactionResponse>, I>>(object: I): CommentReactionResponse {
    const message = createBaseCommentReactionResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBasePollCommentListRequest(): PollCommentListRequest {
  return { pollId: "" };
}

export const PollCommentListRequest = {
  encode(message: PollCommentListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollCommentListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollCommentListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollCommentListRequest {
    return { pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "" };
  },

  toJSON(message: PollCommentListRequest): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollCommentListRequest>, I>>(base?: I): PollCommentListRequest {
    return PollCommentListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollCommentListRequest>, I>>(object: I): PollCommentListRequest {
    const message = createBasePollCommentListRequest();
    message.pollId = object.pollId ?? "";
    return message;
  },
};

function createBasePollCommentListResponse(): PollCommentListResponse {
  return { code: 0, pollCommentInfo: [] };
}

export const PollCommentListResponse = {
  encode(message: PollCommentListResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.pollCommentInfo) {
      PollCommentInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollCommentListResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollCommentListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollCommentInfo.push(PollCommentInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollCommentListResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      pollCommentInfo: globalThis.Array.isArray(object?.pollCommentInfo)
        ? object.pollCommentInfo.map((e: any) => PollCommentInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PollCommentListResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.pollCommentInfo?.length) {
      obj.pollCommentInfo = message.pollCommentInfo.map((e) => PollCommentInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollCommentListResponse>, I>>(base?: I): PollCommentListResponse {
    return PollCommentListResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollCommentListResponse>, I>>(object: I): PollCommentListResponse {
    const message = createBasePollCommentListResponse();
    message.code = object.code ?? 0;
    message.pollCommentInfo = object.pollCommentInfo?.map((e) => PollCommentInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBasePollCommentInfo(): PollCommentInfo {
  return {
    did: "",
    pollId: "",
    commentId: "",
    comments: new Uint8Array(0),
    delYn: false,
    spamStatus: 0,
    replyYn: false,
    reactionCnt: "",
    myReaction: "",
    createdAt: "",
    updatedAt: "",
    replyCommentInfoList: [],
  };
}

export const PollCommentInfo = {
  encode(message: PollCommentInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.pollId !== "") {
      writer.uint32(18).string(message.pollId);
    }
    if (message.commentId !== "") {
      writer.uint32(26).string(message.commentId);
    }
    if (message.comments.length !== 0) {
      writer.uint32(34).bytes(message.comments);
    }
    if (message.delYn === true) {
      writer.uint32(40).bool(message.delYn);
    }
    if (message.spamStatus !== 0) {
      writer.uint32(48).int32(message.spamStatus);
    }
    if (message.replyYn === true) {
      writer.uint32(56).bool(message.replyYn);
    }
    if (message.reactionCnt !== "") {
      writer.uint32(66).string(message.reactionCnt);
    }
    if (message.myReaction !== "") {
      writer.uint32(74).string(message.myReaction);
    }
    if (message.createdAt !== "") {
      writer.uint32(82).string(message.createdAt);
    }
    if (message.updatedAt !== "") {
      writer.uint32(90).string(message.updatedAt);
    }
    for (const v of message.replyCommentInfoList) {
      PollReplyCommentInfo.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollCommentInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollCommentInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.commentId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.comments = reader.bytes();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.delYn = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.spamStatus = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.replyYn = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.reactionCnt = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.myReaction = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.replyCommentInfoList.push(PollReplyCommentInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollCommentInfo {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      commentId: isSet(object.commentId) ? globalThis.String(object.commentId) : "",
      comments: isSet(object.comments) ? bytesFromBase64(object.comments) : new Uint8Array(0),
      delYn: isSet(object.delYn) ? globalThis.Boolean(object.delYn) : false,
      spamStatus: isSet(object.spamStatus) ? spamStatusFromJSON(object.spamStatus) : 0,
      replyYn: isSet(object.replyYn) ? globalThis.Boolean(object.replyYn) : false,
      reactionCnt: isSet(object.reactionCnt) ? globalThis.String(object.reactionCnt) : "",
      myReaction: isSet(object.myReaction) ? globalThis.String(object.myReaction) : "",
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : "",
      replyCommentInfoList: globalThis.Array.isArray(object?.replyCommentInfoList)
        ? object.replyCommentInfoList.map((e: any) => PollReplyCommentInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PollCommentInfo): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.commentId !== "") {
      obj.commentId = message.commentId;
    }
    if (message.comments.length !== 0) {
      obj.comments = base64FromBytes(message.comments);
    }
    if (message.delYn === true) {
      obj.delYn = message.delYn;
    }
    if (message.spamStatus !== 0) {
      obj.spamStatus = spamStatusToJSON(message.spamStatus);
    }
    if (message.replyYn === true) {
      obj.replyYn = message.replyYn;
    }
    if (message.reactionCnt !== "") {
      obj.reactionCnt = message.reactionCnt;
    }
    if (message.myReaction !== "") {
      obj.myReaction = message.myReaction;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.updatedAt !== "") {
      obj.updatedAt = message.updatedAt;
    }
    if (message.replyCommentInfoList?.length) {
      obj.replyCommentInfoList = message.replyCommentInfoList.map((e) => PollReplyCommentInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollCommentInfo>, I>>(base?: I): PollCommentInfo {
    return PollCommentInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollCommentInfo>, I>>(object: I): PollCommentInfo {
    const message = createBasePollCommentInfo();
    message.did = object.did ?? "";
    message.pollId = object.pollId ?? "";
    message.commentId = object.commentId ?? "";
    message.comments = object.comments ?? new Uint8Array(0);
    message.delYn = object.delYn ?? false;
    message.spamStatus = object.spamStatus ?? 0;
    message.replyYn = object.replyYn ?? false;
    message.reactionCnt = object.reactionCnt ?? "";
    message.myReaction = object.myReaction ?? "";
    message.createdAt = object.createdAt ?? "";
    message.updatedAt = object.updatedAt ?? "";
    message.replyCommentInfoList = object.replyCommentInfoList?.map((e) => PollReplyCommentInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBasePollReplyCommentInfo(): PollReplyCommentInfo {
  return {
    did: "",
    pollId: "",
    commentId: "",
    replyId: "",
    replyComment: new Uint8Array(0),
    delYn: false,
    spamStatus: 0,
    reactionCnt: "",
    myReaction: "",
    createdAt: "",
    updatedAt: "",
  };
}

export const PollReplyCommentInfo = {
  encode(message: PollReplyCommentInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.pollId !== "") {
      writer.uint32(18).string(message.pollId);
    }
    if (message.commentId !== "") {
      writer.uint32(26).string(message.commentId);
    }
    if (message.replyId !== "") {
      writer.uint32(34).string(message.replyId);
    }
    if (message.replyComment.length !== 0) {
      writer.uint32(42).bytes(message.replyComment);
    }
    if (message.delYn === true) {
      writer.uint32(48).bool(message.delYn);
    }
    if (message.spamStatus !== 0) {
      writer.uint32(56).int32(message.spamStatus);
    }
    if (message.reactionCnt !== "") {
      writer.uint32(66).string(message.reactionCnt);
    }
    if (message.myReaction !== "") {
      writer.uint32(74).string(message.myReaction);
    }
    if (message.createdAt !== "") {
      writer.uint32(82).string(message.createdAt);
    }
    if (message.updatedAt !== "") {
      writer.uint32(90).string(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollReplyCommentInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollReplyCommentInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.commentId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.replyId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.replyComment = reader.bytes();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.delYn = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.spamStatus = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.reactionCnt = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.myReaction = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollReplyCommentInfo {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      commentId: isSet(object.commentId) ? globalThis.String(object.commentId) : "",
      replyId: isSet(object.replyId) ? globalThis.String(object.replyId) : "",
      replyComment: isSet(object.replyComment) ? bytesFromBase64(object.replyComment) : new Uint8Array(0),
      delYn: isSet(object.delYn) ? globalThis.Boolean(object.delYn) : false,
      spamStatus: isSet(object.spamStatus) ? spamStatusFromJSON(object.spamStatus) : 0,
      reactionCnt: isSet(object.reactionCnt) ? globalThis.String(object.reactionCnt) : "",
      myReaction: isSet(object.myReaction) ? globalThis.String(object.myReaction) : "",
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : "",
    };
  },

  toJSON(message: PollReplyCommentInfo): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.commentId !== "") {
      obj.commentId = message.commentId;
    }
    if (message.replyId !== "") {
      obj.replyId = message.replyId;
    }
    if (message.replyComment.length !== 0) {
      obj.replyComment = base64FromBytes(message.replyComment);
    }
    if (message.delYn === true) {
      obj.delYn = message.delYn;
    }
    if (message.spamStatus !== 0) {
      obj.spamStatus = spamStatusToJSON(message.spamStatus);
    }
    if (message.reactionCnt !== "") {
      obj.reactionCnt = message.reactionCnt;
    }
    if (message.myReaction !== "") {
      obj.myReaction = message.myReaction;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.updatedAt !== "") {
      obj.updatedAt = message.updatedAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollReplyCommentInfo>, I>>(base?: I): PollReplyCommentInfo {
    return PollReplyCommentInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollReplyCommentInfo>, I>>(object: I): PollReplyCommentInfo {
    const message = createBasePollReplyCommentInfo();
    message.did = object.did ?? "";
    message.pollId = object.pollId ?? "";
    message.commentId = object.commentId ?? "";
    message.replyId = object.replyId ?? "";
    message.replyComment = object.replyComment ?? new Uint8Array(0);
    message.delYn = object.delYn ?? false;
    message.spamStatus = object.spamStatus ?? 0;
    message.reactionCnt = object.reactionCnt ?? "";
    message.myReaction = object.myReaction ?? "";
    message.createdAt = object.createdAt ?? "";
    message.updatedAt = object.updatedAt ?? "";
    return message;
  },
};

function createBasePollMyCommentResponse(): PollMyCommentResponse {
  return { code: 0, pollMyComment: [] };
}

export const PollMyCommentResponse = {
  encode(message: PollMyCommentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.pollMyComment) {
      PollMyComment.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollMyCommentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollMyCommentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollMyComment.push(PollMyComment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollMyCommentResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      pollMyComment: globalThis.Array.isArray(object?.pollMyComment)
        ? object.pollMyComment.map((e: any) => PollMyComment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PollMyCommentResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.pollMyComment?.length) {
      obj.pollMyComment = message.pollMyComment.map((e) => PollMyComment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollMyCommentResponse>, I>>(base?: I): PollMyCommentResponse {
    return PollMyCommentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollMyCommentResponse>, I>>(object: I): PollMyCommentResponse {
    const message = createBasePollMyCommentResponse();
    message.code = object.code ?? 0;
    message.pollMyComment = object.pollMyComment?.map((e) => PollMyComment.fromPartial(e)) || [];
    return message;
  },
};

function createBasePollMyComment(): PollMyComment {
  return {
    did: "",
    pollId: "",
    commentId: "",
    comments: new Uint8Array(0),
    delYn: false,
    spamStatus: 0,
    isReply: false,
    reactionCnt: "",
    replyCnt: 0,
    createdAt: "",
    updatedAt: "",
  };
}

export const PollMyComment = {
  encode(message: PollMyComment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.pollId !== "") {
      writer.uint32(18).string(message.pollId);
    }
    if (message.commentId !== "") {
      writer.uint32(26).string(message.commentId);
    }
    if (message.comments.length !== 0) {
      writer.uint32(34).bytes(message.comments);
    }
    if (message.delYn === true) {
      writer.uint32(40).bool(message.delYn);
    }
    if (message.spamStatus !== 0) {
      writer.uint32(48).int32(message.spamStatus);
    }
    if (message.isReply === true) {
      writer.uint32(56).bool(message.isReply);
    }
    if (message.reactionCnt !== "") {
      writer.uint32(66).string(message.reactionCnt);
    }
    if (message.replyCnt !== 0) {
      writer.uint32(72).uint32(message.replyCnt);
    }
    if (message.createdAt !== "") {
      writer.uint32(82).string(message.createdAt);
    }
    if (message.updatedAt !== "") {
      writer.uint32(90).string(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollMyComment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollMyComment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.commentId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.comments = reader.bytes();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.delYn = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.spamStatus = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isReply = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.reactionCnt = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.replyCnt = reader.uint32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollMyComment {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      pollId: isSet(object.pollId) ? globalThis.String(object.pollId) : "",
      commentId: isSet(object.commentId) ? globalThis.String(object.commentId) : "",
      comments: isSet(object.comments) ? bytesFromBase64(object.comments) : new Uint8Array(0),
      delYn: isSet(object.delYn) ? globalThis.Boolean(object.delYn) : false,
      spamStatus: isSet(object.spamStatus) ? spamStatusFromJSON(object.spamStatus) : 0,
      isReply: isSet(object.isReply) ? globalThis.Boolean(object.isReply) : false,
      reactionCnt: isSet(object.reactionCnt) ? globalThis.String(object.reactionCnt) : "",
      replyCnt: isSet(object.replyCnt) ? globalThis.Number(object.replyCnt) : 0,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : "",
    };
  },

  toJSON(message: PollMyComment): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.commentId !== "") {
      obj.commentId = message.commentId;
    }
    if (message.comments.length !== 0) {
      obj.comments = base64FromBytes(message.comments);
    }
    if (message.delYn === true) {
      obj.delYn = message.delYn;
    }
    if (message.spamStatus !== 0) {
      obj.spamStatus = spamStatusToJSON(message.spamStatus);
    }
    if (message.isReply === true) {
      obj.isReply = message.isReply;
    }
    if (message.reactionCnt !== "") {
      obj.reactionCnt = message.reactionCnt;
    }
    if (message.replyCnt !== 0) {
      obj.replyCnt = Math.round(message.replyCnt);
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.updatedAt !== "") {
      obj.updatedAt = message.updatedAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollMyComment>, I>>(base?: I): PollMyComment {
    return PollMyComment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollMyComment>, I>>(object: I): PollMyComment {
    const message = createBasePollMyComment();
    message.did = object.did ?? "";
    message.pollId = object.pollId ?? "";
    message.commentId = object.commentId ?? "";
    message.comments = object.comments ?? new Uint8Array(0);
    message.delYn = object.delYn ?? false;
    message.spamStatus = object.spamStatus ?? 0;
    message.isReply = object.isReply ?? false;
    message.reactionCnt = object.reactionCnt ?? "";
    message.replyCnt = object.replyCnt ?? 0;
    message.createdAt = object.createdAt ?? "";
    message.updatedAt = object.updatedAt ?? "";
    return message;
  },
};

function createBaseMyProfileRequest(): MyProfileRequest {
  return { profileImg: 0 };
}

export const MyProfileRequest = {
  encode(message: MyProfileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.profileImg !== 0) {
      writer.uint32(8).uint32(message.profileImg);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MyProfileRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMyProfileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.profileImg = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MyProfileRequest {
    return { profileImg: isSet(object.profileImg) ? globalThis.Number(object.profileImg) : 0 };
  },

  toJSON(message: MyProfileRequest): unknown {
    const obj: any = {};
    if (message.profileImg !== 0) {
      obj.profileImg = Math.round(message.profileImg);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MyProfileRequest>, I>>(base?: I): MyProfileRequest {
    return MyProfileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MyProfileRequest>, I>>(object: I): MyProfileRequest {
    const message = createBaseMyProfileRequest();
    message.profileImg = object.profileImg ?? 0;
    return message;
  },
};

function createBaseMyProfileResponse(): MyProfileResponse {
  return { code: 0, myProfileInfo: undefined };
}

export const MyProfileResponse = {
  encode(message: MyProfileResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.myProfileInfo !== undefined) {
      MyProfileInfo.encode(message.myProfileInfo, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MyProfileResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMyProfileResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.myProfileInfo = MyProfileInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MyProfileResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      myProfileInfo: isSet(object.myProfileInfo) ? MyProfileInfo.fromJSON(object.myProfileInfo) : undefined,
    };
  },

  toJSON(message: MyProfileResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.myProfileInfo !== undefined) {
      obj.myProfileInfo = MyProfileInfo.toJSON(message.myProfileInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MyProfileResponse>, I>>(base?: I): MyProfileResponse {
    return MyProfileResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MyProfileResponse>, I>>(object: I): MyProfileResponse {
    const message = createBaseMyProfileResponse();
    message.code = object.code ?? 0;
    message.myProfileInfo = (object.myProfileInfo !== undefined && object.myProfileInfo !== null)
      ? MyProfileInfo.fromPartial(object.myProfileInfo)
      : undefined;
    return message;
  },
};

function createBaseMyProfileInfo(): MyProfileInfo {
  return { profileImg: 0, updatedAt: "" };
}

export const MyProfileInfo = {
  encode(message: MyProfileInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.profileImg !== 0) {
      writer.uint32(8).uint32(message.profileImg);
    }
    if (message.updatedAt !== "") {
      writer.uint32(18).string(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MyProfileInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMyProfileInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.profileImg = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MyProfileInfo {
    return {
      profileImg: isSet(object.profileImg) ? globalThis.Number(object.profileImg) : 0,
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : "",
    };
  },

  toJSON(message: MyProfileInfo): unknown {
    const obj: any = {};
    if (message.profileImg !== 0) {
      obj.profileImg = Math.round(message.profileImg);
    }
    if (message.updatedAt !== "") {
      obj.updatedAt = message.updatedAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MyProfileInfo>, I>>(base?: I): MyProfileInfo {
    return MyProfileInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MyProfileInfo>, I>>(object: I): MyProfileInfo {
    const message = createBaseMyProfileInfo();
    message.profileImg = object.profileImg ?? 0;
    message.updatedAt = object.updatedAt ?? "";
    return message;
  },
};

function createBaseUnpaidPollAnswerRequest(): UnpaidPollAnswerRequest {
  return { unpaidPollAnswerInfo: [] };
}

export const UnpaidPollAnswerRequest = {
  encode(message: UnpaidPollAnswerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.unpaidPollAnswerInfo) {
      UnpaidPollAnswerInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnpaidPollAnswerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnpaidPollAnswerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.unpaidPollAnswerInfo.push(UnpaidPollAnswerInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnpaidPollAnswerRequest {
    return {
      unpaidPollAnswerInfo: globalThis.Array.isArray(object?.unpaidPollAnswerInfo)
        ? object.unpaidPollAnswerInfo.map((e: any) => UnpaidPollAnswerInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UnpaidPollAnswerRequest): unknown {
    const obj: any = {};
    if (message.unpaidPollAnswerInfo?.length) {
      obj.unpaidPollAnswerInfo = message.unpaidPollAnswerInfo.map((e) => UnpaidPollAnswerInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnpaidPollAnswerRequest>, I>>(base?: I): UnpaidPollAnswerRequest {
    return UnpaidPollAnswerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnpaidPollAnswerRequest>, I>>(object: I): UnpaidPollAnswerRequest {
    const message = createBaseUnpaidPollAnswerRequest();
    message.unpaidPollAnswerInfo = object.unpaidPollAnswerInfo?.map((e) => UnpaidPollAnswerInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUnpaidPollAnswerResponse(): UnpaidPollAnswerResponse {
  return { code: 0 };
}

export const UnpaidPollAnswerResponse = {
  encode(message: UnpaidPollAnswerResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnpaidPollAnswerResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnpaidPollAnswerResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnpaidPollAnswerResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: UnpaidPollAnswerResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnpaidPollAnswerResponse>, I>>(base?: I): UnpaidPollAnswerResponse {
    return UnpaidPollAnswerResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnpaidPollAnswerResponse>, I>>(object: I): UnpaidPollAnswerResponse {
    const message = createBaseUnpaidPollAnswerResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseUnpaidPollAnswerInfo(): UnpaidPollAnswerInfo {
  return { did: "", pollId: 0, pollTitle: "", reward: 0 };
}

export const UnpaidPollAnswerInfo = {
  encode(message: UnpaidPollAnswerInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.pollId !== 0) {
      writer.uint32(16).uint64(message.pollId);
    }
    if (message.pollTitle !== "") {
      writer.uint32(26).string(message.pollTitle);
    }
    if (message.reward !== 0) {
      writer.uint32(32).uint32(message.reward);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnpaidPollAnswerInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnpaidPollAnswerInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pollId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pollTitle = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.reward = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnpaidPollAnswerInfo {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      pollId: isSet(object.pollId) ? globalThis.Number(object.pollId) : 0,
      pollTitle: isSet(object.pollTitle) ? globalThis.String(object.pollTitle) : "",
      reward: isSet(object.reward) ? globalThis.Number(object.reward) : 0,
    };
  },

  toJSON(message: UnpaidPollAnswerInfo): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.pollId !== 0) {
      obj.pollId = Math.round(message.pollId);
    }
    if (message.pollTitle !== "") {
      obj.pollTitle = message.pollTitle;
    }
    if (message.reward !== 0) {
      obj.reward = Math.round(message.reward);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnpaidPollAnswerInfo>, I>>(base?: I): UnpaidPollAnswerInfo {
    return UnpaidPollAnswerInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnpaidPollAnswerInfo>, I>>(object: I): UnpaidPollAnswerInfo {
    const message = createBaseUnpaidPollAnswerInfo();
    message.did = object.did ?? "";
    message.pollId = object.pollId ?? 0;
    message.pollTitle = object.pollTitle ?? "";
    message.reward = object.reward ?? 0;
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
