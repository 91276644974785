import styles from "styles/common/modal/ErrorModal.module.scss";
import { Portal } from "../other";
import { useEffect } from "react";
import { getErrorMeg } from "api/errorHandling";

interface Props {
  mode?: "ALERT" | "CONFIRM";
  error: unknown;
  message: string;

  alertText?: string;
  onAlertHandler: () => void;

  confirmText?: string;
  onConfirmHandler?: () => void;
}

/**
 * ErrorModal - 에러 메세지를 모달 형식으로 보여줄 때 사용합니다.
 *
 * @component
 * @example
 * const props = {
 *  mode: "ALERT",
 *  error: error,
 *  message: "Something Broken"
 *  alertText: "확인"
 *  onAlertHandler: () => { console.log("do something"); }
 * }
 *
 * <ErrorModal {...props} />
 */
export default function ErrorModal({
  mode = "ALERT",
  error,
  message,
  alertText = "확인",
  onAlertHandler,
  confirmText,
  onConfirmHandler,
}: Props) {
  const code = error instanceof Error ? error.message : "f-unknown";

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(`ERROR: ${getErrorMeg(code)}`);
  }, [code]);

  return (
    <Portal>
      <article className={styles.overlay}>
        <div className={styles.box}>
          <div className={styles.content}>
            <h3>{message}</h3>
          </div>

          <div className={styles["btn-box"]}>
            {mode === "CONFIRM" && (
              <button type="button" onClick={onConfirmHandler}>
                {confirmText}
              </button>
            )}

            <button type="button" name="confirm" onClick={onAlertHandler}>
              {alertText}
            </button>
          </div>
        </div>
      </article>
    </Portal>
  );
}
