import { AxiosError } from "axios";
import ErrorCode from "constants/errorcode";
import { MiddlewareError } from "utils/errorPackage";

export const successHandling = (response: any) => {
  // if (typeof response.data === "string") {
  //     return new Error(ErrorCode.NOT_LOGIN);
  // }

  if (response.data.status === 400) {
    return new Error(ErrorCode.INVALID_ARGUMENT);
  }

  if (response.data.status === 401) {
    return new Error(ErrorCode.UNAUTHORIZED);
  }

  if (response.data.status === 405) {
    return new Error(ErrorCode.UNSUPPORTED_HTTPVERB);
  }

  if (response.data.status === 500) {
    return new Error(ErrorCode.INTERNAL_SERVER_ERROR);
  }

  return null;
};

export const failureHandling = (e: unknown) => {
  if (e instanceof AxiosError) {
    const statusMap: Record<string | number, string> = {
      400: ErrorCode.INVALID_ARGUMENT,
      401: ErrorCode.UNAUTHORIZED,
      404: ErrorCode.RESOURCE_NOT_FOUND,
      405: ErrorCode.UNSUPPORTED_HTTPVERB,
      500: ErrorCode.INTERNAL_SERVER_ERROR,
      default: ErrorCode.UNKNOWN_ERROR,
    };

    return new Error(e.response ? statusMap[e.response.status] || statusMap.default : statusMap.default);
  }

  if (e instanceof Error) {
    return new Error(e.message);
  }

  if (e instanceof MiddlewareError) {
    if (e.message && e.message.includes("existCodes")) {
      return new Error(e.message);
    }

    const middlewareStatus: Record<string | number, string> = {
      3022: ErrorCode.ERR_NOT_FOUND_DATA,
      3032: ErrorCode.ERR_NOT_DELETE,
      3033: ErrorCode.ERR_NOTICE_COUNT_EXCEED,
      3034: ErrorCode.ERR_EXCEED_NOTAFTER,
      3035: ErrorCode.ERR_NOT_BETWEEN_INVISIBLEPERIOD,
      3036: ErrorCode.ERR_EVENT_NOTICE_URL_FORMAT,
      3037: ErrorCode.ERR_NOT_UPDATE,
      3038: ErrorCode.ERR_PASSWORD_NOT_CHANGED,
      3039: ErrorCode.ERR_DATA_DUPLICATED,
      6005: ErrorCode.ERR_URL_FORMAT,
      6006: ErrorCode.ERR_INPUT_PARAMETER,
      7804: ErrorCode.ERR_ALREADY_QUICK_MENU,
      default: ErrorCode.UNKNOWN_ERROR,
    };

    return new Error(e.status ? middlewareStatus[e.status] || middlewareStatus.default : middlewareStatus.default);
  }

  return new Error(ErrorCode.UNKNOWN_ERROR);
};

const ERROR_MAP: Record<string, string> = {
  // common
  [ErrorCode.INVALID_ARGUMENT]: "API 전달 인자 확인 바랍니다.",
  [ErrorCode.RESOURCE_NOT_FOUND]: "endpoint 확인 바랍니다.",
  [ErrorCode.UNSUPPORTED_HTTPVERB]: "HTTP Method 확인 바랍니다.",
  [ErrorCode.INTERNAL_SERVER_ERROR]: "서버에 문제가 발생했습니다.",
  [ErrorCode.NOT_LOGIN]: "로그인 정보가 필요합니다.",
  [ErrorCode.ERR_NOT_FOUND_DATA]: "데이터를 찾을 수 없습니다.",
  [ErrorCode.ERR_NOT_DELETE]: "삭제 할 수 없습니다.",
  [ErrorCode.ERR_URL_FORMAT]: "URL 형식이 맞지 않습니다.",
  [ErrorCode.UNAUTHORIZED]: "정보를 조회할 수 없습니다.",
  [ErrorCode.REQUEST_TIMEOUT_EXCEEDED]: "요청시간이 초과 되었습니다",
  [ErrorCode.ACCESS_DENIED]: "권한이 없는 페이지 입니다.",
  [ErrorCode.ERR_INPUT_PARAMETER]: "입력한 정보가 잘못되었습니다.",
  [ErrorCode.UNKNOWN_ERROR]: `요청하신 작업을 수행하지 못했습니다.\n잠시 후 다시 시도해주세요`,

  // admin/notice
  [ErrorCode.ERR_UNREGISTER_NOTICE]: "신규 등록을 실패하였습니다.",
  [ErrorCode.ERR_GET_DETAIL_NOTICE]: "상세 정보를 불러오지 못했습니다.",
  [ErrorCode.ERR_NOTICE_COUNT_EXCEED]: "최대 활성화 수가 초과되었습니다.",
  [ErrorCode.ERR_EXCEED_NOTAFTER]: "현재 시간이 표출 일자를 초과하였습니다.",
  [ErrorCode.ERR_NOT_BETWEEN_INVISIBLEPERIOD]: "보지 않기 유형을 노출 일자 기간에 맞춰 설정해주세요.",
  [ErrorCode.ERR_EVENT_NOTICE_URL_FORMAT]: "랜딩 URL형태가 일치하지 않습니다.",

  // admin/users
  [ErrorCode.ERR_NOT_UPDATE]: "수정할 수 없습니다.",
  [ErrorCode.ERR_DATA_DUPLICATED]: "수정할 수 없습니다.",

  // password
  [ErrorCode.ERR_PASSWORD_NOT_CHANGED]: `동일한 비밀번호를 사용할 수 없습니다.\n새로운 비밀번호를 입력해 주세요.`,

  // quickMenu
  [ErrorCode.ERR_ALREADY_QUICK_MENU]: `이미 등록된 퀵메뉴의 이름이 존재합니다.\n기존 메뉴 정보를 수정하거나 다른 이름으로 등록해주세요`,

  default: `요청하신 작업을 수행하지 못했습니다.\n잠시 후 다시 시도해주세요`,
};

export const getErrorMeg = (meg: string) => ERROR_MAP[meg] || ERROR_MAP.default;
