import styles from "styles/common/other/Title.module.scss";
import { Link } from "react-router-dom";

interface Props {
  text: string;
  location?: { to: string; name: string }[];
}

export default function Title({ text, location }: Props) {
  return (
    <section className={styles.wrapper}>
      <h1>{text}</h1>
      <div className={styles.location}>
        {location?.map(({ to, name }) => (
          <div className={styles["link-box"]} key={`${to}-${name}`}>
            {to && <Link to={to}>{name}</Link>}
            {!to && <span>{name}</span>}
          </div>
        ))}
      </div>
    </section>
  );
}
