import { FormEventHandler, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Content, Footer, Panel, Table, Title } from "comp-lib";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { TBanner, TBannerGroup, TBannerSavaParam } from "types/banner";
import styles from "styles/pages/banner/groupBannerFinal.module.scss";
import useBannerTableData from "./useBannerTableData";
import TableCnt from "../TableCnt";
import BannerItem from "./BannerItem";
import { publishBannerGroup, saveBannerGroup } from "api/banner";
import Modal from "../@tmp/Modal";
import AlertModal from "../@tmp/AlertModal";

type TProps = {
  data: TBannerGroup;
};

export default function GroupBannerFinal({ data }: TProps) {
  const saveGroupMuta = useMutation(saveBannerGroup);
  const publishGroupMuta = useMutation(publishBannerGroup, {
    onError: () => {
      setPublishModal("");
    },
  });

  const [isModal, setIsModal] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState<TBanner | null>(null);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleModalClose = () => {
    setSelectedBanner(null);
    setIsModal(false);
  };

  const handleBannerEditOpen = (banner: TBanner) => {
    setSelectedBanner(banner);
    setIsModal(true);
  };

  const tagleCntTxt = `전체 ${data.bannerList.length}건`;
  const { columns, rows } = useBannerTableData(data.bannerList, handleBannerEditOpen);

  const [publishModal, setPublishModal] = useState<"confirm" | "noti" | "">("");
  const handlePublishModalOpen: FormEventHandler = (e) => {
    e.preventDefault();
    setPublishModal("confirm");
  };
  const handlePublishModalClose = () => setPublishModal("");

  const handleSubmit = async () => {
    await publishGroupMuta.mutateAsync(data?.resourceNo || -1);
    queryClient.invalidateQueries(["getBannerList"]);
    setPublishModal("noti");
  };

  const handleFinalNotiModalClose = () => {
    navigate("/event/banner");
    handlePublishModalClose();
  };

  const [isCopyModal, setIsCopyModal] = useState(false);
  const handleCopyModalOpen = () => setIsCopyModal(true);

  const handleCopy = async () => {
    const groupParam: TBannerSavaParam = {
      bannerList: data.bannerList,
      groupName: `${data.groupName}(복사)`,
    };

    await saveGroupMuta.mutateAsync({ data: groupParam, isFinal: false });
    queryClient.invalidateQueries(["getBannerList"]);
    handleCopyModalOpen();
  };

  const handleCopyModalClose = () => {
    setIsCopyModal(false);
    navigate("/event/banner");
  };

  return (
    <>
      <Content isWithFooter>
        <form id="banner-group" className={styles.wrapper} onSubmit={handlePublishModalOpen}>
          <Title text="배너 그룹 설정" />
          <Panel>
            <div className={styles.group_header}>
              <div className={styles.title}>{data.groupName}</div>

              <dl>
                <div>
                  <dt>생성일</dt>
                  <dd>{dayjs(data.createdAt).format("YYYY.MM.DD")}</dd>
                </div>
                <div>
                  <dt>저장일</dt>
                  <dd>{dayjs(data.updatedAt).format("YYYY.MM.DD")}</dd>
                </div>
              </dl>
            </div>

            <dl className={styles.group_content}>
              <div>
                <dt>상태</dt>
                <dd>{data.isPublished ? "게시중" : "최종 저장"}</dd>
              </div>
              <div>
                <dt>전체 노출수</dt>
                <dd>-</dd>
              </div>
              <div>
                <dt>누적 클릭수</dt>
                <dd>-</dd>
              </div>
              <div>
                <dt>평균 클릭수</dt>
                <dd>-</dd>
              </div>
              <div>
                <dt>평균 클릭률</dt>
                <dd>-</dd>
              </div>
            </dl>
          </Panel>

          <Title text="배너 목록" />
          <Panel>
            <div className={styles.table_header}>
              <TableCnt text={tagleCntTxt} />
            </div>

            <Table columns={columns} rows={rows} />
          </Panel>
        </form>

        <div className={styles.copy}>
          <Button type="button" mode="outlined" size="short" onClick={handleCopy}>
            그룹복사
          </Button>
        </div>
      </Content>

      <Footer>
        <Button as={Link} to="/event/banner" mode="lineGray" size="long">
          목록으로
        </Button>
        {!data.isPublished && (
          <Button type="submit" size="long" form="banner-group">
            게시하기
          </Button>
        )}
      </Footer>

      {isModal && selectedBanner && <BannerItem data={selectedBanner} onClose={handleModalClose} />}

      {publishModal === "confirm" && (
        <Modal
          message="배너 그룹을 게시하시겠습니까?"
          description={`‘확인' 클릭시 해당 배너 그룹이 앱에 노출됩니다`}
          onConfirm={handleSubmit}
          onClose={handlePublishModalClose}
        />
      )}
      {publishModal === "noti" && (
        <AlertModal message="해당 배너 그룹을 게시 중입니다." onConfirm={handleFinalNotiModalClose} />
      )}

      {isCopyModal && <AlertModal message="그룹이 복사되었습니다." onConfirm={handleCopyModalClose} />}

      {saveGroupMuta.isError && (
        <AlertModal message="서버에 문제가 발생했습니다." onConfirm={() => saveGroupMuta.reset()} />
      )}
      {publishGroupMuta.isError && (
        <AlertModal message="서버에 문제가 발생했습니다." onConfirm={() => publishGroupMuta.reset()} />
      )}
    </>
  );
}
