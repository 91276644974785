import { ComponentPropsWithRef, ElementType, ReactNode } from "react";
import cx from "classnames";
import styles from "./panel.module.scss";

type TProps<T extends ElementType> = {
  as?: T;
  children: ReactNode;
} & ComponentPropsWithRef<T>;

export default function Panel<T extends ElementType = "div">({ as, children, className, ...props }: TProps<T>) {
  const Element = as || "div";

  return (
    <Element {...props} className={cx(styles.wrapper, className)}>
      {children}
    </Element>
  );
}
