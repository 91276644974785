import { useCustomParams, FilterData } from "comp-lib";
import { TCompanyListItem } from "types/company";
import { companyStatusMap } from "./useTableNavData";

export default function usePromotionFilter(data: TCompanyListItem[]) {
  const result = data.map((item) => {
    return {
      ...item,
      enabledStatus: overrideStatus(item.enabled),
      search: `${item.target.name}${item.staffName}${item.did}`, // 다중 항목 검색을 위한 항목 추가
    };
  });

  const [{ status, keyword, pageNum }] = useCustomParams({
    keyword: "",
    status: "all",
    pageNum: "1",
  });

  const dataForTableNav = new FilterData(result)
    .sortDesc("target.created_at")
    .filterByKeyword("search", keyword)
    .getResult();

  const paramStatus = status === "all" ? "" : status;

  const filteredData = new FilterData(result)
    .sortDesc("target.created_at")
    .filterByKeyword("search", keyword)
    .filterByStatus("enabledStatus", paramStatus, companyStatusMap)
    .getResult();

  const len = filteredData.length;

  const dataWithNo = filteredData.map((item, idx) => ({
    ...item,
    no: len - idx,
  }));

  const dataForTableRows = new FilterData(dataWithNo).filterByPage(Number(pageNum)).getResult();

  const totalPages = Math.floor(filteredData.length / 10) + (filteredData.length % 10 && 1) || 1;

  return { dataForTableNav, dataForTableRows, totalPages };
}

function overrideStatus(enabled: boolean) {
  if (enabled) return 0; // 기업 활성
  return 1; // 기업 비활성
}
