import styles from "styles/pages/appPush/list/table/statusChip.module.scss";
import cx from "classnames";

type TStatusChipProps = {
  status: number;
};

// 0 발송중, 1 발송 성공, 2 발송 실패, 3 발송 예약
const statusMap: { [key: string]: string[] } = {
  0: ["발송", "sent"],
  1: ["발송", "sent"],
  2: ["발송", "sent"],
  3: ["예약", "reserved"],
};

export default function StatusChip({ status }: TStatusChipProps) {
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.chip, styles[statusMap[status][1]])}>{statusMap[status][0]}</div>
    </div>
  );
}
