import { ChangeEventHandler, useRef, useState } from "react";
import styles from "styles/pages/contentsManage/challenge/write/challengeRegisterImg.module.scss";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";
import { TChallengeContents } from "types/contentsManage/challenge";

export default function ChallengeRegisterImg() {
  const { control, setValue } = useFormContext<TChallengeContents>();

  const accept = ".jpg, .jpeg, .png";
  const allowedFileTypes = accept.split(", ").map((ext) => ext.replace(/^\./, "image/")); // e.g. ['image/jpg', 'image/png', ...]

  const fileRef = useRef<HTMLInputElement>(null);
  const imgData = useWatch({ control, name: "imgFile" });

  const [errorMsg, setErrorMsg] = useState("");

  const handleChangeImg: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.currentTarget.files?.[0];

    if (!file) {
      setErrorMsg("이미지 파일이 존재하지 않습니다. 다시 등록해주세요.");
      return;
    }

    if (accept && !allowedFileTypes.includes(file.type)) {
      setErrorMsg(`이미지 형식이 올바르지 않습니다.\n이미지는 ${accept} 형식만 가능합니다.`);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (event) => {
      const image = new Image();
      image.src = event.target?.result as string;

      image.onload = () => {
        const { width, height } = image;
        e.target.value = ""; // 동일한 파일 업로드 가능하도록 파일 정보 초기화
        if (width !== height) return setErrorMsg(`정사각형(1:1) 사이즈의\n이미지만 등록 가능합니다.`);
        if (width < 52) return setErrorMsg(`이미지 사이즈가 너무 작습니다. \n52*52px 이상의 이미지만 등록 가능합니다.`);
        if (width > 208)
          return setErrorMsg(`이미지 사이즈가 너무 큽니다. \n208*208px 이하의 이미지만 등록 가능합니다.`);
        setValue("imgFile", file);
      };
    };
  };

  const handleClickCancel = () => {
    if (!fileRef.current) return;

    setValue("imgFile", null);
    fileRef.current.value = "";
  };

  return (
    <div className={styles.wrapper}>
      <label htmlFor="imgFile" className={styles.file_btn}>
        이미지 선택
      </label>

      <Controller
        name="imgFile"
        control={control}
        rules={{ required: "대표 이미지를 등록해 주세요" }}
        render={() => <input type="file" id="imgFile" accept={accept} ref={fileRef} onChange={handleChangeImg} />}
      />

      {imgData && (
        <div className={styles.img_text_box}>
          <span> {typeof imgData !== "string" ? imgData.name : ""}</span>

          <button type="button" onClick={handleClickCancel}>
            &times;
          </button>
        </div>
      )}

      {errorMsg && <NotiModal onClose={() => setErrorMsg("")} message={errorMsg} />}
    </div>
  );
}
