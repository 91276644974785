import { Button, Content, ErrorModal, Footer, Panel, Title } from "comp-lib";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "styles/pages/contentsManage/vote/detail/spamDetail.module.scss";
import DetailInfo from "../@shared/DetailInfo";
import dayjs from "dayjs";
import { RadioEmtIcon, RadioSkyIcon } from "assets/svgs/vote";
import { useState } from "react";
import ConfirmModal from "pages/contentsManage/vote/@shared/modal/ConfirmModal";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";
import { useMutation } from "react-query";
import { patchSpamStatus } from "api/contentsManage/poll";
import { uint8ArrayToString } from "utils/uint8Array";

export default function SpamDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const isDisabled = !(data.spamStatus === 2);

  const [status, setStatus] = useState(0);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isNotiModal, setIsNotiModal] = useState(false);
  const [isError, setIsError] = useState(false);

  const { mutate } = useMutation(patchSpamStatus);

  const formattingStatus = (stat: number) => {
    if (stat === 2) return "대기";
    if (stat === 3) return "삭제 완료";
    if (stat === 4) return "삭제 미승인";
    return "";
  };

  const { commentInput }: { commentInput: string } = JSON.parse(uint8ArrayToString(data.spamReportContent));

  const dataList = [
    {
      name: "콘텐츠명",
      value: data?.pollTitle,
    },
    {
      name: "접수 일시",
      value: dayjs(data?.spamReportTime).format("YYYY.MM.DD HH:mm:ss"),
    },
    {
      name: "콘텐츠 URL",
      value: (
        <Link
          to={`${window.MYD_WEB_URL}/poll/detail/${data?.pollId}?admin=true`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${window.MYD_WEB_URL}/poll/detail/${data?.pollId}`}
        </Link>
      ),
    },
    {
      name: "댓글(답글)내용",
      value: commentInput,
    },
  ];

  const statusInfoList = [
    {
      name: "현재 상태",
      value: formattingStatus(data?.spamStatus),
    },
    {
      name: "필터",
      value: (
        <div className={styles.filter_box}>
          <button type="button" onClick={() => setStatus(3)} className={styles.filter_btn} disabled={isDisabled}>
            {status === 3 ? <RadioSkyIcon className={styles.radio} /> : <RadioEmtIcon className={styles.radio} />}
            <span>삭제</span>
          </button>

          <button type="button" onClick={() => setStatus(4)} className={styles.filter_btn} disabled={isDisabled}>
            {status === 4 ? <RadioSkyIcon className={styles.radio} /> : <RadioEmtIcon className={styles.radio} />}
            <span>삭제 미승인</span>
          </button>
        </div>
      ),
    },
  ];

  const getModalMsgByStatus = () => {
    // 삭제 완료
    if (status === 3) {
      return {
        message: "내역을 삭제하시겠습니까?",
        subMessage: `삭제할 경우 다른 사용자에게 댓글(답글)이 보이지 않으며,\n삭제 이후엔 철회할 수 없습니다`,
        confirmMessage: "삭제되었습니다.",
      };
    }

    // 삭제 미승인 (status === 4)
    return {
      message: "내역을 미승인 하시겠습니까?",
      subMessage: `미승인 할 경우 댓글(답글)은 그대로 유지됩니다\n한 번 상태를 결정한 이후엔 철회할 수 없습니다`,
      confirmMessage: "미승인 처리되었습니다.",
    };
  };

  const { message, subMessage, confirmMessage } = getModalMsgByStatus();

  const handleModalClose = () => setIsConfirmModal(false);

  const handleModalConfirm = () => {
    const body = {
      spamCommentIdList: data?.isReply ? [] : [data?.spamReportId],
      spamReplyIdList: data?.isReply ? [data?.spamReportId] : [],
      status,
    };
    mutate(body, {
      onSuccess: () => setIsNotiModal(true),
      onError: () => {
        setIsConfirmModal(false);
        setIsError(true);
      },
    });

    setIsConfirmModal(false);
  };

  return (
    <Content isWithFooter>
      <Title text="상세 정보" />

      <Panel>
        <DetailInfo dataList={dataList} />
      </Panel>

      <Panel className={styles.status_box}>
        <div className={styles.sub_title}>상태 결정</div>
        <DetailInfo dataList={statusInfoList} />
      </Panel>

      <Footer className={styles.footer}>
        <Button type="button" mode="outlined" size="long" onClick={() => navigate(-1)}>
          이전으로
        </Button>

        <Button
          type="button"
          size="long"
          onClick={() => (status ? setIsConfirmModal(true) : navigate(-1))}
          disabled={isDisabled || status === 0}
        >
          확인
        </Button>
      </Footer>

      {isConfirmModal && (
        <ConfirmModal
          message={message}
          subMessage={subMessage}
          onCancle={handleModalClose}
          onConfirm={handleModalConfirm}
        />
      )}

      {isNotiModal && <NotiModal onClose={() => navigate(`/contents/vote?tab=1`)} message={confirmMessage} />}

      {isError && <ErrorModal error={isError} onConfirmHandler={() => setIsError(false)} />}
    </Content>
  );
}
