import { useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { promotionStatusMap } from "./usePromotionFilter";
import { TPromotionListInfo } from "types/promotion";
import { StatusChip, TWithNo } from "comp-lib";

import styles from "styles/pages/promotion/list/promotionList.module.scss";

export default function useTableData(data: TWithNo<TPromotionListInfo>[]) {
  const [status, setStatus] = useState("");
  const [id, setId] = useState(0);

  const columns = [
    { text: "NO", width: "78px" },
    { text: "상태", width: "73px" },
    { text: "프로모션명" },
    { text: "진행기간", width: "189px" },
    { text: "코드", width: "201px" },
    { text: "참여자 수", width: "100px" },
    { text: "", width: "87px" },
  ];

  // 날짜 형식 지정
  const dateFormatting = (date: string) => dayjs(date).format("YYYY.MM.DD");

  // 상태 텍스트 형식 지정
  const statusFormatting = (stat: number, idx: number) => {
    const notStarted = stat === 1;
    const inProgress = stat === 2;

    const statusCondition = () => {
      if (notStarted) return "삭제";
      if (inProgress) return "철회";
      return "";
    };

    if (notStarted || inProgress) {
      const handleClick = () => {
        setStatus(String(stat));
        setId(idx);
      };

      return (
        <button type="button" onClick={handleClick} className={styles.btnChip}>
          {statusCondition()}
        </button>
      );
    }
    return "-";
  };

  const rows = data.map((item) => {
    const statusColor = ((stat: number) => {
      switch (stat) {
        case 1:
          return "yellow";
        case 2:
          return "blue";
        case 3:
        case 4:
          return "red";
        default:
          return "blue";
      }
    })(item.target.promotion_status);

    return [
      item.no,
      <StatusChip key={item.id} text={promotionStatusMap[item.target.promotion_status][0]} color={statusColor} />,
      <Link key={item.id} to={`/contents/promotion/detail/${item.id}`}>
        {item.title}
      </Link>,
      `${dateFormatting(item.target.promotion_not_before)} ~ ${dateFormatting(item.target.promotion_not_after)}`,
      item.target.promotion_code,
      (item.target.apply_count || 0).toLocaleString(),
      statusFormatting(item.target.promotion_status, item.id),
    ];
  });

  return { columns, rows, status, setStatus, id };
}
