import { Panel } from "comp-lib";

import dayjs from "dayjs";

import styles from "styles/pages/appPush/detail/info.module.scss";
import { TPushDetail } from "types/appPush";
import { downloadTxtFile } from "utils";
import { getDateFormat } from "utils/appPush";

type TProps = {
  data: TPushDetail;
};

const PURPOSE_MAP: { [key: string]: string } = {
  NOTICE: "일반 공지",
  MARKETING_ADVERTISE: "마케팅·광고",
};

const TYPE_MAP: { [key: string]: string } = {
  TOPIC: "Topic 전체 발송",
  TOKEN_ALL: "Token 전체 발송",
  TOKEN: "Token 대상 지정 발송",
};

const MARKETING_TYPE_MAP: { [key: string]: string } = {
  MARKETING_NOT_ALLOW: "마케팅 수신 거부",
  MARKETING_ALL: "주간 수신 동의자",
  MARKETING_NIGHTTIME: "주간· 야간 수신 동의자(21:00 ~ 08:00 발송) ",
  ALL: "마케팅 전체 동의",
};

const OS_MAP: { [key: string]: string } = {
  ALL: "전체",
  ANDROID: "안드로이드",
  IOS: "iOS",
};

const TARGET_TYPE_MAP: { [key: string]: string } = {
  OS: "OS 지정",
  PROPOSAL: "Proposal 지정",
  DID: "DID 지정",
  DID_FILE: "DID 지정",
};

export default function Info({ data }: TProps) {
  const {
    messagePurpose,
    agreeType,
    messageType,
    targetType,
    messagePlatformStatus,
    proposalId,
    createdAt,
    reservedTime,
    startTime,
    endTime,
    target,
  } = data.data.pushMessageDetail;

  const isTypeTokenTarget = messageType === "TOKEN";
  const isPurposeMarketing = messagePurpose === "MARKETING_ADVERTISE";

  const uploadDate = dayjs(createdAt.split("T")[0]).format("YYYYMMDD");
  const fileName = `uploaded file_${uploadDate}`;
  const formattedDidList = target
    ?.split(",")
    .map((item) => item.replace(/[\[\],"\s]/g, ""))
    .join(", ");

  const getTargetType = (type: string) => {
    switch (type) {
      case "OS":
        return ` / ${OS_MAP[messagePlatformStatus!]}`;
      case "PROPOSAL":
        return ` / 직접 입력 / ${proposalId}`;
      case "DID":
        return ` / 직접 입력 / ${formattedDidList}`;
      case "DID_FILE":
        return (
          <span>
            / 파일 첨부 /
            <button
              className={styles.fileNameBtn}
              type="button"
              onClick={() => downloadTxtFile(formattedDidList!, fileName)}
            >
              {fileName}
            </button>
          </span>
        );

      default:
        return null;
    }
  };

  if (!data) return null;

  return (
    <Panel>
      <h2 className={styles.sectionTitle}>푸시 발송 정보</h2>

      <section className={styles.config}>
        <div className={styles.categoryBox}>
          <span className={styles.category}>발송 목적</span>
          <span>
            {PURPOSE_MAP[messagePurpose]} {isPurposeMarketing && `/ ${MARKETING_TYPE_MAP[agreeType]}`}
          </span>
        </div>

        <div className={styles.categoryBox}>
          <span className={styles.category}>발송 방식</span>
          <span>{TYPE_MAP[messageType]}</span>
        </div>

        {isTypeTokenTarget && (
          <div className={styles.categoryBox}>
            <span className={styles.category}>발송 대상</span>
            <span className={styles.didList}>
              {TARGET_TYPE_MAP[targetType]}
              {getTargetType(targetType)}
            </span>
          </div>
        )}

        <div className={styles.categoryBox}>
          <span className={styles.category}>발송 시작일시</span>
          <span>{startTime ? getDateFormat(startTime) : getDateFormat(reservedTime)}</span>
        </div>

        <div className={styles.categoryBox}>
          <span className={styles.category}>발송 완료일시</span>
          <span>{endTime ? getDateFormat(endTime) : "-"}</span>
        </div>
      </section>
    </Panel>
  );
}
