import { RadioDisIcon, RadioSkyIcon } from "assets/svgs/vote";
import styles from "./radioGroup.module.scss";

type TProps = {
  name: string;
  dataList: {
    label: string;
    value: string;
  }[];
  value: string;
  onChange: (value: string) => void;
};

export default function RadioGroup({ name, dataList, value: checkValue, onChange }: TProps) {
  return (
    <div className={styles.wrapper}>
      {dataList.map(({ label, value }) => {
        const isCheck = checkValue === value;

        return (
          <label key={value} className={styles.radio_box}>
            {isCheck ? <RadioSkyIcon /> : <RadioDisIcon />}
            <span className={styles.label}>{label}</span>

            <input
              type="radio"
              id={value}
              name={name}
              value={value}
              onChange={(e) => onChange(e.currentTarget.value)}
              checked={isCheck}
              hidden
            />
          </label>
        );
      })}
    </div>
  );
}
