import styles from "./voteTitle.module.scss";

interface Props {
  text: string;
  page: number;
}

export default function VoteTitle({ text, page }: Props) {
  return (
    <section className={styles.wrapper}>
      <h1>{text}</h1>
      <span>{`(${page}/3)`}</span>
    </section>
  );
}
