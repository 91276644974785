import { useQuery } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Content, Footer, LoadingModal, Panel, Title } from "comp-lib";
import dayjs from "dayjs";

import RechargeActionBtn from "./RechargeActionBtn";
import { getRechargeItem } from "api/recharge";
import { RECHARGE_STATUS_MAP } from "constants/recharge";
import styles from "styles/pages/reward/rechargeDetail.module.scss";
import AlertModal from "./@tmp/AlertModal";

export default function RechargeDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isError, isLoading } = useQuery(["getRechargeItem", id], () => {
    if (!id) {
      navigate("/point/approve");
      throw new Error("해당 URL은 잘못되었습니다.");
    }

    return getRechargeItem(Number(id));
  });

  const handleError = () => {
    navigate(`/point/approve${location.search}`);
  };

  if (isError) {
    return <AlertModal message="서버에 문제가 발생했습니다." onConfirm={handleError} />;
  }

  if (!data || isLoading) return <LoadingModal />;

  const isRequest = RECHARGE_STATUS_MAP[data.status][0] === "승인요청";

  return (
    <>
      <Content className={styles.wrapper} isWithFooter>
        <Title text="포인트 요청 정보" />

        <Panel>
          <dl>
            <div>
              <dd>회사</dd>
              <dt>{data?.staff?.company?.name || "-"}</dt>
            </div>

            <div>
              <dd>이름</dd>
              <dt>{data?.staff?.name || "-"}</dt>
            </div>

            <div>
              <dd>요청 포인트</dd>
              <dt>{data.requestPoint.toLocaleString()} P</dt>
            </div>

            <div>
              <dd>요청 시간</dd>
              <dt>{data.createdAt ? dayjs(data.createdAt).format("YYYY.MM.DD HH:mm:ss") : "-"}</dt>
            </div>

            <div>
              <dd>지급 시간</dd>
              <dt>{data.statusChangedAt ? dayjs(data.statusChangedAt).format("YYYY.MM.DD HH:mm:ss") : "-"}</dt>
            </div>

            <div>
              <dd>휴대폰 번호</dd>
              <dt>{data?.staff?.phone || "-"}</dt>
            </div>

            <div>
              <dd>유선전화 번호</dd>
              <dt>{data?.staff?.tel || "-"}</dt>
            </div>

            <div>
              <dd>상태</dd>
              <dt>{RECHARGE_STATUS_MAP[data.status][0]}</dt>
            </div>
          </dl>
        </Panel>
      </Content>

      <Footer>
        {isRequest && <RechargeActionBtn id={Number(id)} />}
        {!isRequest && (
          <Button as={Link} to={`/point/approve${location.search}`} size="long">
            확인
          </Button>
        )}
      </Footer>
    </>
  );
}
