import { useMutation, useQueryClient } from "react-query";
import { useFormContext } from "react-hook-form";

import styles from "styles/pages/mydInquiry/detail/mydInquiryComment.module.scss";

import dayjs from "dayjs";
import { ErrorModal, Modal } from "comp-lib";

import ImgModal from "./ImgModal";
import CommentEditModal from "./CommentEditModal";
import useModal from "hooks/useModal";

import { deleteComment, editComment } from "api/mydInquiry";
import { ProfileBlackIcon, ProfileBlueIcon } from "assets/svgs/icon";
import useModalContents from "hooks/useModalContents";
import queryKey from "constants/queryKey";
import {
  TDeleteCommentRequest,
  TEditCommentRequest,
  TMydInquiryComment,
  TStatus,
} from "types/mydInquiry";
import { useState } from "react";
import useImgUpload from "hooks/useImgUpload";
import { TUploadImgRes } from "types/common";
import { MODAL_CONTENTS } from "constants/mydInquiry";

type TProps = {
  data: TMydInquiryComment;
  inquiryStatus: TStatus;
  isLastComment: boolean;
};

export default function MydInquiryComment({ data, inquiryStatus, isLastComment }: TProps) {
  const queryClient = useQueryClient();

  const isAdmin = data.commentType === "ANSWER";
  const isDone = inquiryStatus === "DONE";
  const isNew = !isAdmin && !isDone && isLastComment;
  const canEdit = isAdmin && !isDone && isLastComment;

  const [isImgModalOpen, onToggleImgModal] = useModal();
  const [isEditModalOpen, onToggleEditModal] = useModal();
  const [isModalOpen, onToggleModal] = useModal();
  const [isErrorModalOpen, onToggleErrorModal] = useState(false);

  const [modalContents, setModalContents] = useModalContents();

  const { getValues, setValue, resetField } = useFormContext();

  const getDateFormat = (originalDate: string) => {
    if (originalDate) {
      const dateTime = dayjs(originalDate).format("YYYY.MM.DD  HH:mm");

      return dateTime;
    }

    return "-";
  };

  const { mutateAsync: editCommentMuta, error: editEror } = useMutation(
    (arg: TEditCommentRequest) => editComment(arg),
    {
      onSuccess: () => {
        onToggleEditModal();

        setModalContents({
          ...MODAL_CONTENTS.EDIT_CONFIRM,
          handleClose: () => {
            onToggleModal();
            resetField("comment");
            resetField("commentImg");
            queryClient.invalidateQueries(queryKey.GET_MYD_INQUIRY_COMMENTS);
          },
        });

        onToggleModal();
      },

      onError: () => {
        onToggleEditModal();
      },
    },
  );

  const { mutate: deleteCommentMuta, error: deleteError } = useMutation(
    (arg: TDeleteCommentRequest) => deleteComment(arg),
    {
      onSuccess: () => {
        setModalContents({
          title: "삭제 완료",
          description: (
            <span className={styles.completeModal}>
              <div>'{data.contents}'</div>
              답변이 삭제되었습니다.
            </span>
          ),

          btnType: "confirm",
          handleClose: () => {
            onToggleModal();
            queryClient.invalidateQueries(queryKey.GET_MYD_INQUIRY_COMMENTS);
          },
        });
      },

      onError: () => {
        onToggleModal();
      },
    },
  );

  const { uploadImgMuta, uploadImgsMuta, registerImgMuta, imgError, imgsError, registerError } = useImgUpload();

  const handleEdit = async () => {
    try {
      const originalImgId = getValues("commentImg")
        .filter((e: any) => e.id)
        .map((img: { id: string; name: string }) => img.id);

      const newImgFile = getValues("commentImg").filter((img: any) => !img.id);

      // 삭제(새로 등록하는 이미지가 0개)
      if (newImgFile.length === 0) {
        setValue("commentImg", originalImgId);

        await editCommentMuta({
          id: data.inquiryId,
          commentId: data.commentId,
          contents: getValues("comment"),
          attachedFileIds: getValues("commentImg"),
        });
      }

      // 단일 업로드(새로 등록하는 이미지가 1개)
      if (newImgFile.length === 1) {
        const uploadedImg = await uploadImgMuta({ file: newImgFile[0].file });
        const registeredImg = await registerImgMuta({ fileId: uploadedImg.fileId });

        if (registeredImg.status === "REGISTERED") {
          const newArr = originalImgId.concat([registeredImg.fileId]);

          setValue("commentImg", newArr);

          await editCommentMuta({
            id: data.inquiryId,
            commentId: data.commentId,
            contents: getValues("comment"),
            attachedFileIds: getValues("commentImg"),
          });
        } else {
          throw new Error(`REGISTERED 상태의 이미지만 등록 가능합니다.`);
        }
      }

      // 다중 업로드(새로 등록하는 이미지가 2개 이상)
      if (newImgFile.length >= 2) {
        const formData = new FormData();
        const arr: string[] = [];

        newImgFile.forEach((img: { file: File; url: string }) => {
          formData.append("files", img.file);
        });

        const uploadedImgs = await uploadImgsMuta({ files: formData });

        const res = uploadedImgs.map((e: TUploadImgRes) => {
          return registerImgMuta({ fileId: e.fileId });
        });

        const registeredImg = await Promise.all(res);

        const isAllRegistered = registeredImg.every((item) => item.status === "REGISTERED");

        if (isAllRegistered) {
          uploadedImgs.map((e: TUploadImgRes) => {
            arr.push(e.fileId);

            const newArr = arr.concat(originalImgId);

            setValue("commentImg", newArr);
          });

          await editCommentMuta({
            id: data.inquiryId,
            commentId: data.commentId,
            contents: getValues("comment"),
            attachedFileIds: getValues("commentImg"),
          });
        } else {
          throw new Error(`REGISTERED 상태의 이미지만 등록 가능합니다.`);
        }
      }
    } catch (error) {
      onToggleErrorModal(true);

      throw new Error("답변 등록에 실패했습니다.");
    }
  };

  const handleDelete = () => {
    deleteCommentMuta({ id: data.inquiryId, commentId: data.commentId });
  };

  return (
    <>
      <div className={styles.comment}>
        <div className={styles.profile}>
          {isAdmin ? <ProfileBlackIcon /> : <ProfileBlueIcon />}
          <span>{isAdmin ? `운영자(${data.userId}) 답변` : "사용자 문의"}</span>
          <span>
            {getDateFormat(data.updatedAt)} {isNew && <span className={styles.new}>(new)</span>}
          </span>

          {canEdit && (
            <div className={styles.btns}>
              <button
                name="edit"
                type="button"
                onClick={() => {
                  setValue("comment", data.contents);
                  setValue("commentImg", data.attachedFileInfos || []);
                  onToggleEditModal();
                }}
              >
                수정
              </button>
              <span />
              <button
                name="delete"
                type="button"
                onClick={() => {
                  setModalContents({
                    ...MODAL_CONTENTS.DELETE_SUBMIT,
                    handleSubmit: handleDelete,
                    handleClose: onToggleModal,
                  });
                  onToggleModal();
                }}
              >
                삭제
              </button>
            </div>
          )}
        </div>

        <div className={styles.contents}>
          <div>{data.contents}</div>

          {data.attachedFileInfos && (
            <div className={styles.imgBox}>
              <span> 이미지:</span>

              {data.attachedFileInfos.map((img, idx) => (
                <div key={idx} className={styles.imgName}>
                  <button onClick={onToggleImgModal}>{img.name}</button>
                  {idx !== data.attachedFileInfos.length - 1 && <span>,</span>}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {isEditModalOpen && (
        <CommentEditModal
          data={data}
          handleSubmit={handleEdit}
          handleClose={() => {
            onToggleEditModal();
            resetField("comment");
            resetField("commentImg");
          }}
        />
      )}

      {isModalOpen && <Modal {...modalContents} />}

      {isErrorModalOpen && (
        <ErrorModal
          error={deleteError || editEror || imgError || imgsError || registerError}
          message={
            deleteError || editEror
              ? `수정 혹은 삭제가 불가한 답변입니다`
              : "이미지 등록에 실패했습니다.\n다시 시도해주세요."
          }
        />
      )}

      {isImgModalOpen && (
        <ImgModal serverImg={data.attachedFileInfos.map((img) => img.id)} onToggleModal={onToggleImgModal} />
      )}
    </>
  );
}
