import styles from "styles/pages/appPush/write/JSONResult.module.scss";
import { TOs } from "types/appPush";

function JsonResult({ title, body, moveTo, os }: { title: string; body: string; moveTo: string; os: TOs }) {
  const commonData = `"Command": 0,
         "AppUpdate": {
            "TargetVersion": 21010601,
            "IsMandatory": true,
       },
         "DataUpdate": {
            "Target": 0,
       },
         "DataCollect": {
            "Type": 1,
            "ProposalID": "*",
       },
         "MoveTo":${moveTo}`;

  const getPlaceholder = () => {
    const map = {
      ALL: `{
      notification: {
        ${title},
        ${body},
      },
      data: {
        ${commonData}
      },
    }`,
      ANDROID: `{
        android: {
          notification: {
            ${title},
            ${body},
          },
          priority: "HIGH",
          data: {
           ${commonData}
          },
       },
    }`,
      IOS: `{
      "apns": {
        "payload": {
          ${title},
          ${body},
        },
        "headers": {
          "apns-priority": "10",
        },
      },
      "data": {
        ${commonData}
      },
    }`,
    };

    return map[os];
  };

  return (
    <div className={styles.container}>
      <h2>JSON 데이터 만들기</h2>
      <textarea name="Content" className={styles.content} cols={70} rows={15} placeholder={getPlaceholder()} disabled />
    </div>
  );
}

export default JsonResult;
