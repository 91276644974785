import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { usePromotionFilter, useTableData, useTableNavData } from ".";
import queryKey from "constants/queryKey";
import useModal from "hooks/useModal";
import { getPromotionDelete, getPromotionList, getPromotionRevoke } from "api/promotion";
import {
  Table,
  Title,
  Pagination,
  DateSearchBox,
  TableNav,
  Content,
  Panel,
  Button,
  ErrorModal,
  LoadingModal,
  Modal,
} from "comp-lib";

import styles from "styles/pages/promotion/list/promotionList.module.scss";

export default function PromotionList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isConfirmModal, setIsConfirmModal] = useState("");
  const [isErrorShowing, toggleIsErrorShowing] = useModal();

  const {
    data = [],
    error: getError,
    isLoading,
  } = useQuery([queryKey.GET_PROMOTION_LIST], getPromotionList, {
    onError: () => toggleIsErrorShowing(),
  });

  const { dataForTableNav, dataForTableRows, totalPages } = usePromotionFilter(data);
  const { columns, rows, status, setStatus, id } = useTableData(dataForTableRows);
  const tableNavData = useTableNavData(dataForTableNav);

  const isDelete = status === "1"; // 삭제
  const isRevoke = status === "2"; // 철회

  // 프로모션 철회
  const { mutate: revokeMutation, error: revokeError } = useMutation(getPromotionRevoke);

  // 프로모션 삭제
  const { mutate: deleteMutation, error: deleteError } = useMutation(getPromotionDelete);

  const handleClickBtn = () => {
    const notStarted = isDelete && deleteMutation;
    const inProgress = isRevoke && revokeMutation;
    const mutation = notStarted || inProgress;

    if (mutation) {
      mutation(id, {
        onSuccess: () => {
          setIsConfirmModal(status);
          queryClient.invalidateQueries([queryKey.GET_PROMOTION_LIST]);
        },
        onError: () => {
          setStatus("");
          toggleIsErrorShowing();
        },
      });
    }
  };

  const handleError = () => {
    getError && navigate("/");
    toggleIsErrorShowing();
  };

  if (isLoading) return <LoadingModal />;

  return (
    <Content>
      <Title text="프로모션 목록" />

      <Panel className={styles.panel}>
        <div className={styles.tableTop}>
          <TableNav dataList={tableNavData} />
          <DateSearchBox />
        </div>

        <Table columns={columns} rows={rows} />

        <div className={styles.buttonBox}>
          <Button onClick={() => navigate("/contents/promotion/create")}>등록</Button>
        </div>

        <Pagination totalPages={totalPages} />
      </Panel>

      {/* Modals */}
      {status && (
        <Modal
          title={`${isRevoke ? "진행 중인 프로모션을 철회하시겠습니까?" : "예정 중인 프로모션을 삭제하시겠습니까?"}`}
          description={`${isRevoke ? "철회" : "삭제"}하실 경우 복구하실 수 없습니다.\n계속 진행하시겠습니까?`}
          cancelBtnName="아니오"
          submitBtnName={isRevoke ? "철회" : "삭제"}
          btnType="submit"
          handleClose={() => setStatus("")}
          handleSubmit={handleClickBtn}
        />
      )}

      {isConfirmModal && (
        <Modal
          description={`${isRevoke ? "철회" : "삭제"}가 완료되었습니다.`}
          confirmBtnName="닫기"
          btnType="confirm"
          handleClose={() => {
            setStatus("");
            setIsConfirmModal("");
          }}
        />
      )}

      {isErrorShowing && <ErrorModal error={getError || revokeError || deleteError} onConfirmHandler={handleError} />}
    </Content>
  );
}
