import { Fragment, useEffect } from "react";
import Portal from "../Portal";
import styles from "./notiModal.module.scss";

export type TProps = {
  message: string;
  onClose: () => void;
};

export default function NotiModal({ message, onClose }: TProps) {
  useEffect(() => {
    document.body.style.cssText = "overflow: hidden";
    return () => {
      document.body.style.cssText = "overflow: auto";
    };
  }, []);

  return (
    <Portal>
      <div className={styles.overlay}>
        <article className={styles.wrapper}>
          <div className={styles.content}>
            <p>
              {message.split("\n").map((line, index) => (
                <Fragment key={`msg-${index + 1}`}>
                  {line}
                  {index !== message.split("\n").length - 1 && <br />}
                </Fragment>
              ))}
            </p>
          </div>

          <div className={styles.btn_box}>
            <button type="button" name="confirm" onClick={onClose}>
              확인
            </button>
          </div>
        </article>
      </div>
    </Portal>
  );
}
