import styles from "styles/pages/appPush/write/input.module.scss";

interface Props {
  value: string;
  disabled: boolean;
  placeholder: string;
  width: string;
  onChange: () => void;
}

export default function Input({ value, disabled, placeholder, width, onChange }: Props) {
  return (
    <input
      className={styles.input}
      type="text"
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      style={{ width }}
      onChange={onChange}
    />
  );
}
