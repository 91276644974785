import { registImg, uploadImg } from "api/common";
import {
  createChallenge as createChallengeAPI,
  modifyChallenge as modifyChallengeAPI,
} from "api/contentsManage/challenge";
import { useMutation, useQueryClient } from "react-query";
import { TChallengeRegisterForm } from "types/contentsManage/challenge";
import { CHALLENGE_CATEGORY } from "../constants";
import queryKey from "constants/queryKey";
import { useLocation } from "react-router-dom";

export default function useCreateChallenge() {
  const queryClient = useQueryClient();
  const uploadImgMuta = useMutation((file: File) => uploadImg({ file }));
  const registImgMuta = useMutation((fileId: string) => registImg({ fileId }));
  const createChallengeMuta = useMutation(createChallengeAPI);
  const modifyChallengeMuta = useMutation(modifyChallengeAPI);

  const { pathname } = useLocation();
  const id = pathname.includes("modify") ? pathname.split("/").pop() || "" : null;

  const createChallenge = async (form: TChallengeRegisterForm) => {
    if (!form.imgFile) throw new Error("등록된 이미지 파일이 없습니다. \n잠시후에 다시 시도해주세요");

    let fileId = form.imageId;
    // 챌린지 수정 시 filename을 보여주기 위해 imgFile.size 가 0인 파일을 등록해둔다.
    // 챌린지 생성으로 인해 기존 이미지 id가 없거나(!fileId), 기존 이미지를 삭제하고 새로 등록한 파일인 경우(size !==0) 이미지 id를 업데이트한다.
    if (!fileId || (fileId && form.imgFile.size !== 0)) {
      try {
        const uploadRes = await uploadImgMuta.mutateAsync(form.imgFile);
        const registRes = await registImgMuta.mutateAsync(uploadRes.fileId);
        fileId = registRes.fileId;
      } catch (error) {
        console.error(error);
        throw new Error("이미지 파일 업로드 중 오류가 발생했습니다.");
      }
    }

    const { participantsCount, imgFile, rewardPointPerPerson, applyPointPerPerson, ...pickForm } = form;
    const payload = {
      ...pickForm,
      rewardPointPerPerson: rewardPointPerPerson * applyPointPerPerson,
      applyPointPerPerson,
      category: CHALLENGE_CATEGORY[form.category],
      type: "NORMAL" as const,
      imageId: fileId,
      minParticipantsCount: 10, // 추후 수정가능할 때 변경 (서버 미구현)
      maxParticipantsCount: form.participantsCount,
    };

    if (id !== null) {
      // 챌린지 수정
      const response = await modifyChallengeMuta.mutateAsync(
        { id, payload },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKey.GET_CHALLENGE_LIST);
            queryClient.invalidateQueries(queryKey.GET_CHALLENGE_COUNT);
          },
        },
      );
      return response.data;
    } else {
      const response = await createChallengeMuta.mutateAsync(payload, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey.GET_CHALLENGE_LIST);
          queryClient.invalidateQueries(queryKey.GET_CHALLENGE_COUNT);
        },
      });
      return response.data;
    }
  };
  return createChallenge;
}
