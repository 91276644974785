import styles from "styles/pages/reward/@shared/modal/confirmModal.module.scss";
import { ModalPortal } from "../other";

type Props = {
  isLoading?: boolean;
  title?: string;
  description: React.ReactNode;
  cancelBtnName?: string;
  submitBtnName?: string;
  confirmBtnName?: string;
  btnType: "submit" | "confirm";
  handleClose: () => void;
  handleSubmit?: () => void;
};

export default function ConfirmModal({
  isLoading = false,
  title,
  submitBtnName = "등록하기",
  cancelBtnName = "취소",
  confirmBtnName = "확인",
  description,
  btnType,
  handleClose,
  handleSubmit,
}: Props) {
  const btnEle = {
    submit: (
      <>
        <button type="button" name="cancel" onClick={handleClose}>
          {cancelBtnName}
        </button>
        <button type="button" onClick={handleSubmit}>
          {submitBtnName}
        </button>
      </>
    ),
    confirm: (
      <button type="button" onClick={handleClose}>
        {confirmBtnName}
      </button>
    ),
  }[btnType];

  return (
    <ModalPortal>
      <div className={styles.dialog}>
        {!isLoading && (
          <div className={styles.content}>
            <p>{title}</p>
            <div className={styles.description}>{description}</div>

            <div className={styles.buttonBox}>{btnEle}</div>
          </div>
        )}

        {/* {isLoading && <LoadingModal />} */}
      </div>
    </ModalPortal>
  );
}
