import { putStatusChallenge, postRevokeChallenge } from "api/contentsManage/challenge";
import queryKey from "constants/queryKey";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const useMutaButtonBox = (onError: (error: unknown) => void, clearConfirmModalSet: () => void) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const commonConfig = {
    onMutate: () => setIsLoading(true),
    onSettled: () => {
      setIsLoading(false);
      clearConfirmModalSet();
    },
    onError: onError,
  };

  const deleteChallengeMuta = useMutation(putStatusChallenge, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey.GET_CHALLENGE_LIST);
      queryClient.invalidateQueries(queryKey.GET_CHALLENGE_COUNT);
    },
    ...commonConfig,
  });

  const revokeChallengeMuta = useMutation(postRevokeChallenge, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey.GET_CHALLENGE_DETAIL);
    },
    ...commonConfig,
  });

  const settleChallengeMuta = useMutation(putStatusChallenge, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey.GET_CHALLENGE_DETAIL);
    },
    ...commonConfig,
  });

  return {
    deleteMuta: deleteChallengeMuta,
    revokeMuta: revokeChallengeMuta,
    settleMuta: settleChallengeMuta,
    isLoading,
  };
};

export default useMutaButtonBox;
