import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import styles from "styles/pages/mydInquiry/detail/mydInquiryDetail.module.scss";

import { Title, Content, Modal } from "comp-lib";

import Write from "./Write";
import MydInquiryJiraInfo from "./MydInquiryJiraInfo";
import MydInquiryInfo from "./MydInquiryInfo";
import MydInquiryComments from "./MydInquiryComments";
import MydInquiryFooter from "./MydInquiryFooter";
import { Loading } from "common/other";

import { QuestionIcon } from "assets/svgs/icon";
import { getMydInquiryComments, getMydInquiryDetail, getMydInquiryJiraIssue } from "api/mydInquiry";
import queryKey from "constants/queryKey";
import useModal from "hooks/useModal";
import useModalContents from "hooks/useModalContents";
import { TMydInquiryDetail } from "types/mydInquiry";
import { MODAL_CONTENTS } from "constants/mydInquiry";

type TForm = {
  imgFile: { file: File; url: string }[];
  imgId: string[];
  body: string;
  comment: string;
  commentImg: { id: string; name: string }[];
};

export default function MydInquiryDetail() {
  const methods = useForm<TForm>({
    defaultValues: {
      imgFile: [],
      imgId: [],
      body: "",
      comment: "",
      commentImg: [],
    },
  });

  const { id } = useParams();

  const [isCompleteChecked, setIsCompleteChecked] = useState(false);
  const [isModalOpen, onToggleModal] = useModal();
  const [modalContents, setModalContents] = useModalContents();

  const onChangeCheckBtn = () => {
    setIsCompleteChecked((prev) => !prev);
  };

  const {
    data = {
      id: 0,
      status: "REGISTERED",
      type: "NORMAL",
      title: "",
      contents: "",
      receivedAt: "",
      userId: "",
      attachedFileInfos: [],
    },
    isLoading,
  } = useQuery<TMydInquiryDetail>([queryKey.GET_MYD_INQUIRY_DETAIL], () => getMydInquiryDetail(Number(id)));

  const { data: jiraData } = useQuery([queryKey.GET_MYD_INQUIRY_JIRA_ISSUE], () => getMydInquiryJiraIssue(Number(id)), {
    onSuccess: () => {
      if (jiraData) {
        setModalContents({
          ...MODAL_CONTENTS.JIRA_UPDATE_CONFIRM,
          handleClose: () => {
            onToggleModal();
          },
        });
        onToggleModal();
      }
    },
  });

  const { data: commentsData = [] } = useQuery([queryKey.GET_MYD_INQUIRY_COMMENTS], () =>
    getMydInquiryComments(Number(id)),
  );

  const isDoneInquiry = data?.status === "DONE";

  if (isLoading) return <Loading />;

  return (
    <>
      <Content isWithFooter>
        <Title text="마이디 문의관리" subText="CS 관리 / 마이디 문의관리" />

        <MydInquiryJiraInfo inquiryData={data} jiraData={jiraData!} />
        <MydInquiryInfo inquiryData={data} />

        <div className={styles.desc}>
          <QuestionIcon />
          문의사항 내용이 JIRA에서 검토할 필요가 있는 경우 JIRA 이슈를 생성하여 확인할 수 있도록 안내합니다.
        </div>

        <FormProvider {...methods}>
          <MydInquiryComments inquiryData={data} commentsData={commentsData} />

          {!isDoneInquiry && <Write isCompleteChecked={isCompleteChecked} onChangeCheckBtn={onChangeCheckBtn} />}

          <MydInquiryFooter isDone={data?.status === "DONE"} isCompleteChecked={isCompleteChecked} />
        </FormProvider>
      </Content>

      {isModalOpen && <Modal {...modalContents} />}
    </>
  );
}
