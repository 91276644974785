import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Content, Title, Panel, Footer, Button, Modal, ErrorModal } from "comp-lib";

import GoodsRegisterContent from "./GoodsRegisterContent";
import { TGoodsFormData } from "types/goods";
import { createGoods } from "api/goods";

export default function GoodsRegister() {
  const location = useLocation();
  const navigate = useNavigate();

  const formMethods = useForm<TGoodsFormData>({
    defaultValues: {
      codes: [{ code: "" }],
    },
  });

  const createGoodsMut = useMutation(createGoods);

  const handleFormSubmit = async (_: TGoodsFormData) => {
    setIsModal(true);
  };

  const [isModal, setIsModal] = useState(false);
  const handleModalClose = () => setIsModal(false);
  const handleModalConfirm = async () => {
    setIsModal(false);
    await createGoodsMut.mutateAsync(formMethods.getValues("codes"));
    navigate(`/goods`);
  };

  const handleError = () => {
    createGoodsMut.reset();
    setIsModal(false);
  };

  const getExistCodes = (error: unknown) => {
    if (error instanceof Error) {
      const match = error.message.match(/existCodes:\[([^\]]+)\]/);
      return match ? match[1] : null;
    }

    return null;
  };

  return (
    <>
      <Content isWithFooter>
        <Title text="상품 등록" />

        <Panel>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} id="goods-register">
              <GoodsRegisterContent />
            </form>
          </FormProvider>
        </Panel>
      </Content>

      <Footer>
        <Button as={Link} to={`/goods${location.search}`} type="button" mode="lineGray" size="long">
          뒤로 가기
        </Button>
        <Button type="submit" size="long" form="goods-register">
          등록
        </Button>
      </Footer>

      {isModal && (
        <Modal
          btnType="submit"
          description={`상품 ${formMethods.getValues("codes").length}개를 등록하시겠습니까?`}
          handleClose={handleModalClose}
          handleSubmit={handleModalConfirm}
        />
      )}
      {createGoodsMut.isError && (
        <ErrorModal
          message={
            getExistCodes(createGoodsMut.error)
              ? `${getExistCodes(createGoodsMut.error)}는 이미 존재하는 코드입니다.`
              : "서버에 문제가 발생했습니다."
          }
          onConfirmHandler={handleError}
        />
      )}
    </>
  );
}
