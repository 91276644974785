import { Control, FieldPath, FieldValues, useController } from "react-hook-form";
import styles from "styles/pages/promotion/create/createUI/textInput.module.scss";

type TControl<T extends FieldValues> = React.ComponentProps<"input"> & {
  name: FieldPath<T>;
  control: Control<T>;
};

export default function TextInput<T extends FieldValues>({ control, name, ...props }: TControl<T>) {
  const {
    field: { value, onChange },
  } = useController({ name, control, rules: { required: "필수 항목을 모두 입력해 주세요." } });

  return <input type="text" value={value || ""} onChange={onChange} className={styles.textInput} {...props} />;
}
