import { ComponentProps, forwardRef } from "react";
import cx from "classnames";

import styles from "styles/pages/reward/@shared/input.module.scss";

type TInput = {} & ComponentProps<"input">;

const Input = forwardRef<HTMLInputElement, TInput>(function Input({ className, ...otherProps }: TInput, ref) {
  return <input className={cx(styles.input, className)} ref={ref} {...otherProps} />;
});

export default Input;
