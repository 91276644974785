import { ComponentProps, useEffect } from "react";

import { ModalPortal } from "pages/reward/@shared/other";
import styles from "styles/pages/reward/@shared/modal/notifyModal.module.scss";

type Props = {
  isImplemented: boolean;
  onImplementHandler?: () => void;
  timeout?: number;
} & ComponentProps<"div">;

/**
 * NotifyModal - 모달 형식으로 유저에게 전달하고 싶은 내용(작업)이 있을 때 사용. implement 될때까지 닫히지 않음.
 *
 * @component
 * @example
 * const props = {
 *  isImplemented: false, 
    onImplementHandler?: () => console.log(close))
 * }
 *
 * <NotifyModal {...props} />
 */
export default function NotifyModal({ isImplemented, onImplementHandler, children, timeout = 500 }: Props) {
  // 외부에서 작업 끝났다는 신호가 온후(isImplemented changed to true) -> onImplementHandler 를 300ms 뒤에 실행
  useEffect(() => {
    if (isImplemented) {
      setTimeout(() => {
        if (onImplementHandler) {
          onImplementHandler();
        }
      }, timeout);
    }
  }, [isImplemented, onImplementHandler, timeout]);

  return (
    <ModalPortal>
      <div className={styles.notify_modal}>
        <div className={styles.content}>{children}</div>
      </div>
    </ModalPortal>
  );
}
