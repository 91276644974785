import { Link, useLocation } from "react-router-dom";
import { StatusChip, TWithNo } from "comp-lib";
import { TTransactionItem } from "types/transaction";
import dayjs from "dayjs";
import { marketStatusMap } from "constants/transaction";

export default function useTableData(data: TWithNo<TTransactionItem>[]) {
  const columns = [
    { text: "NO", width: "62px" },
    { text: "상태", width: "80px" },
    { text: "기업/기관", width: "80px" },
    { text: "제목" },
    { text: "진행기간", width: "204px" },
    { text: "목표 응답자", width: "114px" },
    { text: "현재 응답자", width: "114px" },
    { text: "달성율", width: "80px" },
    { text: "제공 포인트", width: "80px" },
    { text: "차감 포인트", width: "80px" },
  ];

  const location = useLocation();

  const rows = data.map((item) => {
    const statusText = marketStatusMap[item.status][0];

    const statusColor = ((status: number) => {
      switch (status) {
        case 0:
          return "blue";
        case 1:
          return "yellow";
        case 2:
          return "orange";
        case 3:
        case 4:
          return "red";
        default:
          return "blue";
      }
    })(item.status);

    return [
      item.no,
      <StatusChip key={item.proposalId} text={statusText} color={statusColor} />,
      item.content.description.company.name,
      <Link key={`title-${item.proposalId}`} to={`detail/${item.proposalId}${location.search}`}>
        {item.title}
      </Link>,
      `${dayjs(item.notBefore).format("YYYY.MM.DD")}~${syncProgressPeriod(dayjs(item.notAfter)).format("YYYY.MM.DD")}`,
      `${item.maxIssuable.toLocaleString()} 명`,
      `${item.ticketCount.toLocaleString()} 명`,
      `${Math.round(item.responseRate * 100)}%`,
      `${item.depositPoint.toLocaleString()} P`,
      `${item.distributedPoint.toLocaleString()} P`,
    ];
  });

  return { columns, rows };
}

function syncProgressPeriod(date: dayjs.Dayjs, type?: "decode") {
  /** 거래 등록 시 진행기간이 23:59 까지여야 하기 때문에 서버에서 임의로 날짜를 + 1 해서 저장합니다.
   *  때문에 보여줄 때에는 서버에서 받아온 진행기간 notAfter에서 하루를 빼줘야 합니다.  */
  return date.subtract(type === "decode" ? -1 : 1, "day");
}
