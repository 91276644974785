/* eslint-disable no-alert */
import styles from "styles/common/input/FileInputMultiple.module.scss";
import { ChangeEventHandler, MouseEventHandler, useEffect, useRef, useState } from "react";
import { Controller, FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import useModal from "hooks/useModal";
import { Modal } from "comp-lib";
import cx from "classnames";

type TProps = React.ComponentProps<"input"> & {
  id: Path<FieldValues>;
  children?: string; // 버튼 내 텍스트
  isRequired?: boolean; // 필수값 여부
  accept?: string; // 가능 확장자
  sizeLimit?: number; // 이미지 사이즈 제한
  minWidth?: number; // px 사이즈 제한
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  maxLength?: number; // 파일 개수 제한
  disabled?: boolean; // 업로드 버튼 disabled
  onClick?: () => void; // 파일 이름 클릭 이벤트
};

/**
 * @params id - file id
 * @params children - text in button (default: '이미지 선택')
 * @params isRequired - must require (default: true)
 * @params accept - file extension (default: '.jpg, .jpeg, .png')
 * @params sizeLimit - size limit(mb)
 * @params minWidth, minHeight, maxWidth, maxHeight - size limit(px)
 * @params maxLength - number of files
 * @params disabled - isDisabled (default: true)
 * @params onClick - click file event
 *  */

export default function FileInputMultiple({
  id,
  children = "이미지 선택",
  isRequired = true,
  accept = ".jpg, .jpeg, .png",
  sizeLimit,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  maxLength,
  disabled,
  onClick,
}: TProps) {
  const { control, setValue, watch } = useFormContext();

  const allowedFileTypes = accept.split(", ").map((ext) => {
    const extension = ext.replace(/^\./, "");

    switch (extension) {
      case "pdf":
        return `application/${extension}`;

      case "jpg":
        return `image/${extension}`;

      case "png":
        return `image/${extension}`;

      case "jpeg":
        return `image/${extension}`;

      default:
        return `${extension}`;
    }
  });

  const fileRef = useRef<HTMLInputElement>(null);
  const data = useWatch({ control, name: id });

  const [isErrorModal, setIsErrorModal] = useModal();
  const [errorMsg, setErrorMsg] = useState("");

  const checkFileType = (allowedTypes: string[], type: string) => {
    if (!allowedTypes.includes(type)) {
      setIsErrorModal();
      setErrorMsg(`${accept}\n파일 형식만 업로드 가능합니다`);
      return false;
    }
    return true;
  };

  const handleChangeFile: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { files } = e.currentTarget;

    if (!files || !fileRef.current?.value) return; // length 체크 실패 후, 파일 선택 창 '취소' 클릭 시 에러 모달 뜨는 현상 방지

    // length 체크
    if (maxLength) {
      if (data.length + files.length > maxLength) {
        setIsErrorModal();
        setErrorMsg(`최대 ${maxLength}개까지 업로드 가능합니다.`);
        e.target.value = "";
        throw Error(`최대 ${maxLength}개까지 업로드 가능합니다.`);
      }
    }

    Object.entries(files).forEach(([key, value]) => {
      const file = value;

      // mb 체크
      if (sizeLimit) {
        const isUploadableSize = file.size <= megbytesToBytes(sizeLimit);
        const sizeLimitErrorMsg = `${sizeLimit}MB 이상의 파일은 올릴 수 없습니다.`;

        if (!isUploadableSize) {
          setIsErrorModal();
          setErrorMsg(sizeLimitErrorMsg);
          e.target.value = "";
          throw Error(sizeLimitErrorMsg);
        }
      }

      // accept 체크
      if (!checkFileType(allowedFileTypes, file.type)) {
        e.target.value = "";
        return;
      }

      const isImgFile = ["image/png", "image/jpg", "image/jpeg"].includes(file.type);

      if (!isImgFile) {
        setValue(e.target.id, [...watch(e.target.id), file]);
      }

      // px 체크
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (event) => {
        const image = new Image();

        image.src = event.target?.result as string;

        image.onload = () => {
          const { width, height } = image;

          if (minWidth && width < minWidth) {
            setIsErrorModal();
            setErrorMsg(`가로 ${minWidth}px 미만의 파일은 올릴 수 없습니다.`);
            e.target.value = "";
            throw Error(`가로 ${minWidth}px 미만의 파일은 올릴 수 없습니다.`);
          }

          if (minHeight && height < minHeight) {
            setIsErrorModal();
            setErrorMsg(`세로 ${minHeight}px 미만의 파일은 올릴 수 없습니다.`);
            e.target.value = "";
            throw Error(`세로 ${minHeight}px 미만의 파일은 올릴 수 없습니다.`);
          }

          if (maxWidth && width > maxWidth) {
            setIsErrorModal();
            setErrorMsg(`가로 ${maxWidth}px 초과의 파일은 올릴 수 없습니다.`);
            e.target.value = "";
            throw Error(`가로 ${maxWidth}px 초과의 파일은 올릴 수 없습니다.`);
          }

          if (maxHeight && height > maxHeight) {
            setIsErrorModal();
            setErrorMsg(`세로 ${maxHeight}px 초과의 파일은 올릴 수 없습니다.`);
            e.target.value = "";
            throw Error(`세로 ${maxHeight}px 미만의 파일은 올릴 수 없습니다.`);
          }

          setValue(e.target.id, [...watch(e.target.id), { file: file, url: reader.result }]);
          e.target.value = ""; // 동일 파일 등록 가능을 위함
        };
      };
    });
  };

  // 파일 업로드 삭제
  const handleClickCancel: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    if (!fileRef?.current) return;

    const targetId = e.currentTarget.id;

    const dataList = data;
    dataList.splice(targetId, 1);

    setValue(id, dataList);
    fileRef.current!.value = "";
  };

  return (
    <div className={styles.upload_box}>
      <label htmlFor={id} className={cx({ [styles.disabled]: disabled })}>
        {children}
      </label>

      <Controller
        name={id}
        control={control}
        rules={{ required: isRequired }}
        render={() => (
          <input
            type="file"
            id={id}
            ref={fileRef}
            accept={accept || "image/*"}
            disabled={disabled}
            onChange={handleChangeFile}
            multiple
          />
        )}
      />

      {data && data.length !== 0 && (
        <div className={cx(styles.file_btn_box)} onClick={onClick}>
          {data.map((item: any, idx: string) => {
            return (
              <div key={idx} className={styles.btn_box} onClick={onClick}>
                <span className={cx({ [styles.name_btn]: onClick })} onClick={onClick}>
                  {item.file ? item.file.name : item.name}
                </span>

                <button type="button" className={styles.cancel_btn} id={idx} onClick={handleClickCancel}>
                  &times;
                </button>
              </div>
            );
          })}
        </div>
      )}

      {isErrorModal && <Modal description={errorMsg} btnType="confirm" handleClose={setIsErrorModal} />}
    </div>
  );
}

function megbytesToBytes(megabytes: number) {
  return megabytes * 1024 * 1024;
}
