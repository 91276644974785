import { useEffect } from "react";
import { Button, Panel, Title } from "comp-lib";

import { Portal } from "common/other";
import { TBanner } from "types/banner";
import styles from "styles/pages/banner/bannerItem.module.scss";
import { PURPOSE_MAP } from "constants/banner";

type TProps = {
  data: TBanner;
  onClose: () => void;
};

export default function BannerItem({ data, onClose }: TProps) {
  useEffect(() => {
    document.body.style.cssText = "overflow: hidden";
    return () => {
      document.body.style.cssText = "overflow: auto";
    };
  }, []);

  return (
    <Portal>
      <div className={styles.overlay}>
        <div className={styles.wrapper}>
          <Title text="배너 조회" />

          <Panel>
            <dl className={styles.content}>
              <div>
                <dt>구분</dt>
                <dd>{getType(data)}</dd>
              </div>

              <div>
                <dt>디바이스</dt>
                <dd>{getDevice(data.device)}</dd>
              </div>

              <div>
                <dt>제목</dt>
                <dd>{data.title}</dd>
              </div>

              <div>
                <dt>
                  앱 상단 <br /> 네비게이션
                </dt>
                <dd>{data.isVisibleTitle === "true" ? "노출함" : "노출안함"}</dd>
              </div>

              {data.type === "1" && (
                <>
                  <div>
                    <dt>배너이미지</dt>
                    <dd>
                      <a href={data.imgFileStr} target="_blank" rel="noreferrer">
                        {data.imgFileStr}
                      </a>
                    </dd>
                  </div>

                  <div>
                    <dt>연결 URL</dt>
                    <dd>{data.url}</dd>
                  </div>

                  <div>
                    <dt>토큰 설정</dt>
                    <dd>{data.isToken ? "설정함" : "설정안함"}</dd>
                  </div>

                  <div>
                    <dt>노출 기간</dt>
                    <dd>
                      {data.startDateTime &&
                        data.endDateTime &&
                        `${data.startDateTime.replace("T", " ")} ~ ${data.endDateTime.replace("T", " ")}`}

                      {!data.startDateTime && !data.endDateTime && "-"}
                    </dd>
                  </div>
                </>
              )}
              {data.type === "2" && (
                <>
                  <div>
                    <dt>제안 ID</dt>
                    <dd>{data.proposalId}</dd>
                  </div>

                  <div>
                    <dt>배너이미지</dt>
                    <dd>
                      <a href={data.imgFileStr} target="_blank" rel="noreferrer">
                        {data.imgFileStr}
                      </a>
                    </dd>
                  </div>

                  <div>
                    <dt>노출 기간</dt>
                    <dd>
                      {data.startDateTime &&
                        data.endDateTime &&
                        `${data.startDateTime.replace("T", " ")} ~ ${data.endDateTime.replace("T", " ")}`}

                      {!data.startDateTime && !data.endDateTime && "-"}
                    </dd>
                  </div>
                </>
              )}
              {data.type === "3" && (
                <>
                  <div>
                    <dt>제안 목적</dt>
                    <dd>{data.purpose}</dd>
                  </div>

                  <div>
                    <dt>배너이미지</dt>
                    <dd>
                      <a href={data.imgFileStr} target="_blank" rel="noreferrer">
                        {data.imgFileStr}
                      </a>
                    </dd>
                  </div>

                  <div>
                    <dt>노출 기간</dt>
                    <dd>
                      {data.startDateTime &&
                        data.endDateTime &&
                        `${data.startDateTime.replace("T", " ")} ~ ${data.endDateTime.replace("T", " ")}`}

                      {!data.startDateTime && !data.endDateTime && "-"}
                    </dd>
                  </div>
                </>
              )}
            </dl>
          </Panel>

          <div className={styles.footer}>
            <Button type="button" size="long" onClick={onClose}>
              확인
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
}

function getDevice(num: string) {
  if (num === "2") {
    return "Android";
  }

  if (num === "3") {
    return "iOS";
  }

  return "ALL";
}

function getType(item: TBanner) {
  if (item.type === "2") {
    return "거래";
  }

  if (item.type === "3") {
    const purpose = item.purpose ? Number(item.purpose) : 0;
    return PURPOSE_MAP[purpose] || "기타";
  }

  return "일반";
}
