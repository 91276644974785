import { Button, ErrorModal, Footer, LoadingModal } from "comp-lib";
import { To, useLocation, useNavigate } from "react-router-dom";
import styles from "styles/pages/contentsManage/challenge/detail/buttonBox.module.scss";
import cx from "classnames";
import { useState } from "react";
import ConfirmModal from "../@shared/ConfirmModal";
import useMutaButtonBox from "./useMutaButtonBox";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";
import { TChallengeStatus } from "types/contentsManage/challenge";
import { challengeStatusMap } from "../constants";
import { useQueryClient } from "react-query";
import queryKey from "constants/queryKey";
import dayjs from "dayjs";

export default function ButtonBox({ status, endDate }: { status: TChallengeStatus; endDate: string }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const id = pathname.split("/").pop() || "";

  const [challengeRevokeReason, setChallengeRevokeReason] = useState("");
  const [notiMessage, setNotiMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [notiCloseNav, setNotiCloseNav] = useState<string | -1>();
  const [confirmModalSet, setConfirmModalSet] = useState<"등록삭제" | "등록수정" | "삭제" | "종료" | "정산하기" | null>(
    null,
  );

  const mutaOnError = (error: unknown) => {
    if (error instanceof Error && error?.message) return setErrorMessage(error.message);
    return setErrorMessage("요청을 수행하는 중 오류가 발생했습니다.");
  };
  const clearConfirmModalSet = () => setConfirmModalSet(null);

  const { deleteMuta, settleMuta, revokeMuta, isLoading } = useMutaButtonBox(mutaOnError, clearConfirmModalSet);

  const confirmModalProps = {
    등록삭제: {
      message: "등록정보를 삭제하시겠습니까?",
      subMessage: "진행 예정 단계에서만 삭제할 수 있습니다.",
      onConfirm: () =>
        deleteMuta.mutate(
          { id, status: "DELETED" },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(queryKey.GET_CHALLENGE_LIST);
              queryClient.invalidateQueries(queryKey.GET_CHALLENGE_COUNT);
              setNotiCloseNav(-1);
              setNotiMessage("등록정보를 삭제하였습니다.");
            },
          },
        ),
    },
    등록수정: {
      message: "등록정보를 수정하시겠습니까?",
      subMessage: "진행 예정 단계에서만 수정할 수 있습니다.",
      onConfirm: () => navigate(`/contents/challenge/modify/${id}`),
    },
    삭제: {
      message: "챌린지를 삭제 하시겠습니까?",
      subMessage: "취소된 챌린지를 삭제합니다.\n삭제된 챌린지는 복구가 불가능합니다.",
      onConfirm: () =>
        deleteMuta.mutate(
          { id, status: "DELETED" },
          {
            onSuccess: () => {
              setNotiCloseNav(-1);
              setNotiMessage("등록정보를 삭제하였습니다.");
              queryClient.invalidateQueries(queryKey.GET_CHALLENGE_LIST);
              queryClient.invalidateQueries(queryKey.GET_CHALLENGE_COUNT);
            },
          },
        ),
    },
    종료: {
      message: "챌린지를 종료 하시겠습니까?",
      subMessage: (
        <div className={styles.inputBox}>
          <p>{"진행중인 챌린지를 종료합니다.\n종료후 재개할 수 없으며, 챌린지는 종료일 기준으로\n완료처리됩니다."}</p>
          <textarea
            placeholder={"챌린지종료 사유를 입력하세요(최대30자)\n필수입력 아님"}
            value={challengeRevokeReason}
            onChange={(e) => setChallengeRevokeReason(e.target.value)}
            maxLength={30}
          />
        </div>
      ),
      onConfirm: () => revokeMuta.mutate({ id: id, reason: challengeRevokeReason }),
    },
    정산하기: {
      message: "챌린지 포인트 적용",
      subMessage: "챌린지 결과에 따른 포인트를 정산합니다.\n포인트는 72시간 이내에 적용됩니다.",
      onConfirm: () => settleMuta.mutate({ id, status: "CALCULATED" }),
    },
  };

  return (
    <Footer className={cx(styles.wrapper, styles[status])}>
      <Button size="long" mode="lineGray" onClick={() => navigate(-1)}>
        목록보기
      </Button>

      {status === "PREOPEN" && (
        <Button
          size="long"
          mode={"outlined"}
          onClick={() => setConfirmModalSet(challengeStatusMap(status).delete?.func || null)}
        >
          삭제
        </Button>
      )}

      {status !== "DELETED" && (
        <Button
          size="long"
          onClick={() => setConfirmModalSet(challengeStatusMap(status).next.func || null)}
          disabled={
            status === "FINISHED" ||
            (challengeStatusMap(status).next.label === "정산하기" && dayjs().isSameOrBefore(endDate, "day"))
          }
        >
          {challengeStatusMap(status).next.label}
        </Button>
      )}

      {confirmModalSet && <ConfirmModal onCancle={clearConfirmModalSet} {...confirmModalProps[confirmModalSet]} />}
      {notiMessage && (
        <NotiModal
          message={notiMessage}
          onClose={() => (notiCloseNav ? navigate(notiCloseNav as To) : setNotiMessage(""))}
        />
      )}
      {errorMessage && <ErrorModal message={errorMessage} onConfirmHandler={() => setErrorMessage("")} />}
      {isLoading && <LoadingModal />}
    </Footer>
  );
}
