import { RECHARGE_STATUS_MAP } from "constants/recharge";
import { TRechargeCounts } from "types/recharge";

export default function useTableNavData(data: TRechargeCounts) {
  return [
    {
      label: "전체",
      status: "ALL",
      count: data.countAll,
    },
    {
      label: RECHARGE_STATUS_MAP[0][0], // 요청
      status: RECHARGE_STATUS_MAP[0][1],
      count: data.countPending,
    },
    {
      label: RECHARGE_STATUS_MAP[1][0], // 완료
      status: RECHARGE_STATUS_MAP[1][1],
      count: data.countAccept,
    },
    {
      label: RECHARGE_STATUS_MAP[2][0], // 거절
      status: RECHARGE_STATUS_MAP[2][1],
      count: data.countReject,
    },
  ];
}
