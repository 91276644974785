import styles from "styles/pages/advertisement/detail/adminAdDetail.module.scss";
import { useQuery } from "react-query";
import { getAdminAdProposal } from "api/admin/advertisement";
import ButtonBox from "./AdButtonBox";
import AdDialog from "./AdDialog";
import { useState } from "react";
import SidebarLayout from "./adSidebar/AdSidebarLayout";
import dayjs from "dayjs";
import { statusFilter } from "../statusFilter";
import { Content, Title, LoadingModal, ErrorModal } from "comp-lib";

export default function AdminAdDetail() {
  const { data, isLoading, isError, error } = useQuery("getAdminAdProposal", () =>
    getAdminAdProposal(String(window.location.pathname.split("/").pop())),
  );
  const [dialogType, setDialogType] = useState<"confirm" | "reject" | "none">();
  const status = data?.advertisements ? statusFilter(data?.advertisements.status, data?.advertisements.approved) : 2;

  const onClickUrl: React.MouseEventHandler<HTMLButtonElement> = (e) => window.open(e.currentTarget.innerText);

  if (isLoading) return <LoadingModal />;
  if (isError) return <ErrorModal error={error || Error("데이터 거래 정보를 불러오는 데 실패하였습니다.")} />;

  return (
    <Content isWithFooter>
      <Title text="상세 정보" />
      <div className={styles.innerPannel}>
        <div className={styles.infoContainer}>
          <p>기업/기관명</p>
          <p>{data?.proposals.staff.company.name}</p>
          <p>담당자</p>
          <p>{data?.proposals.staff.name}</p>
          <p>광고 유형</p>
          <p>{data?.advertisements.type === "BANNER" ? "배너 광고" : data?.advertisements.type}</p>
          <p>노출 위치</p>
          <p>{`${data?.advertisements.loc === "DATA_MARKET" ? "자사 진행 거래" : data?.advertisements.loc} / ${
            data?.proposals.title
          }`}</p>
          <p>광고 제목</p>
          <p>{data?.advertisements.title}</p>
          <p>광고 기간</p>
          <p>
            {data?.advertisements.notBefore && data?.advertisements.notAfter
              ? `${dayjs(data?.advertisements.notBefore).format("YYYY.MM.DD")} ~ ${syncProgressPeriod(
                  dayjs(data?.advertisements.notAfter),
                ).format("YYYY.MM.DD")}`
              : "-"}
          </p>
          <p>배너 이미지</p>
          <button type="button" onClick={onClickUrl}>
            {data?.advertisements.fileUrl}
          </button>
          <p>랜딩 URL</p>
          {data?.advertisements.webUrl ? (
            <button type="button" onClick={onClickUrl}>
              {data.advertisements.webUrl}
            </button>
          ) : (
            "-"
          )}
        </div>
      </div>

      <ButtonBox
        onClickConfirm={() => setDialogType("confirm")}
        onClickReject={() => setDialogType("reject")}
        status={status}
      />

      {dialogType && (
        <AdDialog
          type={dialogType}
          handleClose={() => setDialogType("none")}
          advertiseData={{ title: data?.advertisements.title || "", notAfter: data?.advertisements.notAfter || "" }}
        />
      )}
      <SidebarLayout defaultOpen={false} adData={data} />
    </Content>
  );
}

function syncProgressPeriod(date: dayjs.Dayjs, type?: "decode") {
  /** 거래 등록 시 진행기간이 23:59 까지여야 하기 때문에 서버에서 임의로 날짜를 + 1 해서 저장합니다.
   *  때문에 보여줄 때에는 서버에서 받아온 진행기간 notAfter에서 하루를 빼줘야 합니다.  */
  return date.subtract(type === "decode" ? -1 : 1, "day");
}
