import { useFormContext } from "react-hook-form";
import styles from "styles/pages/contentsManage/challenge/write/writeContents.module.scss";
import { Panel } from "comp-lib";
import { ControllerDropdown } from "common/dropdown";
import { TChallengeContents } from "types/contentsManage/challenge";
import { CHALLENGE_CATEGORY } from "../constants";
import { RerollIcon } from "assets/svgs/icon";
import ChallengeRegisterImg from "./ChallengeRegisterImg";

export const WriteContents = ({ resetCaution }: { resetCaution: () => void }) => {
  const {
    register,
    control,
    trigger,
    formState: { errors },
  } = useFormContext<TChallengeContents>();

  return (
    <Panel className={styles.wrapper}>
      <section>
        <span className={styles.title} data-must aria-invalid={errors.category ? "true" : "false"}>
          카테고리
        </span>
        <ControllerDropdown
          name="category"
          dataList={Object.keys(CHALLENGE_CATEGORY)}
          width="300px"
          height="40px"
          mode="secondary"
          borderColor="soft"
        />
      </section>

      <section>
        <span className={styles.title} data-must aria-invalid={errors.title ? "true" : "false"}>
          챌린지 제목
        </span>
        <input
          type="text"
          placeholder="최대 입력 20자 (공백포함)"
          maxLength={20}
          {...register("title", {
            validate: {
              required: (text) => (text.trim() ? true : "콘텐츠 제목을 입력해주세요"),
              maxLength: (value) => value.length <= 20 || "최대 20자까지 입력해주세요",
            },
            onChange: () => trigger("title"),
          })}
        />
        {errors.title && <p className={styles.errorMsg}>{errors.title.message}</p>}
      </section>

      <section className={styles.content}>
        <span className={styles.title} data-must aria-invalid={errors.description ? "true" : "false"}>
          챌린지 설명(소제목)
        </span>
        <input
          type="text"
          placeholder="최대 입력 30자(공백포함)"
          maxLength={30}
          {...register("description", {
            validate: {
              required: (text) => (text.trim() ? true : "챌린지 설명을 입력해주세요"),
              maxLength: (value) => value.length <= 30 || "최대 30자까지 입력해주세요",
            },
            onChange: () => trigger("description"),
          })}
        />
        {errors.description && <p className={styles.errorMsg}>{errors.description.message}</p>}
      </section>

      <section className={styles.content}>
        <span className={styles.title} data-must aria-invalid={errors.proofMethod ? "true" : "false"}>
          챌린지 참여방법
        </span>
        <input
          type="text"
          placeholder="인증방법을 입력해주세요 최대 입력 30자(공백포함)"
          maxLength={30}
          {...register("proofMethod", {
            validate: {
              required: (text) => (text.trim() ? true : "챌린지 참여방법을 입력해주세요"),
              maxLength: (value) => value.length <= 30 || "최대 30자까지 입력해주세요",
            },
            onChange: () => trigger("proofMethod"),
          })}
        />
        {errors.proofMethod && <p className={styles.errorMsg}>{errors.proofMethod.message}</p>}
      </section>

      <section>
        <div className={styles.cautionLabel}>
          <span className={styles.title} data-must aria-invalid={errors.caution ? "true" : "false"}>
            유의사항
          </span>
          <span className={styles.titleDesc} aria-invalid={errors.caution ? "true" : "false"}>
            (챌린지 별 특별유의 사항 발생치 추가 및 수정)
          </span>
          <button type="button" onClick={resetCaution}>
            <RerollIcon width="24px" height="24px" />
            초기화하기
          </button>
        </div>
        <textarea
          placeholder="유의사항을 입력해주세요 최대 입력 1000자(공백포함)"
          {...register("caution", {
            validate: {
              required: (text) => (text.trim() ? true : "유의사항을 입력해주세요"),
              maxLength: (value) => value.length <= 1000 || "최대 1000자까지 입력해주세요",
            },
            onChange: () => trigger("caution"),
          })}
        ></textarea>
        {errors.caution && <p className={styles.errorMsg}>{errors.caution.message}</p>}
      </section>

      <section>
        <span className={styles.title} data-must aria-invalid={errors.imgFile ? "true" : "false"}>
          대표 이미지를 등록해 주세요
        </span>

        <div>
          <ChallengeRegisterImg />
        </div>

        <ul className={styles.imageDec}>
          <li>대표 이미지는 52*52px 이상 208*208px 이하의 정사각형(1:1)만 등록 가능합니다.</li>
          <li>파일 형식은 .jpg .jpeg .png 만 가능합니다.</li>
        </ul>
      </section>
    </Panel>
  );
};
