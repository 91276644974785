import { UseFormRegisterReturn } from "react-hook-form";

import styles from "styles/pages/login/iconInput.module.scss";
import { forwardRef } from "react";
import { EmailIcon, PasswordIcon, UserIcon } from "assets/svgs/icon";

type TProps = React.ComponentProps<"input"> & {
  type: "text" | "email" | "password";
  register?: UseFormRegisterReturn;
  iconType?: "text" | "email" | "password";
};

function IconInput(
  { type, iconType = type, placeholder, register, ...props }: TProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const iconMap = {
    text: <UserIcon />,
    email: <EmailIcon />,
    password: <PasswordIcon />,
  };

  return (
    <div className={styles.inputBox}>
      {iconMap[iconType]}
      <input ref={ref} type={type} placeholder={placeholder} {...register} {...props} />
    </div>
  );
}

export default forwardRef(IconInput);
