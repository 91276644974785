import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Button, Content, ErrorModal, Footer, Modal, Panel, Table, Title } from "comp-lib";
import { useNavigate } from "react-router-dom";

import TableCnt from "../TableCnt";
import BannerCreator from "./BannerCreator";
import useBannerTableData from "./useBannerTableData";
import { TBanner, TBannerGroup, TBannerSavaParam } from "types/banner";
import styles from "styles/pages/banner/bannerGroupCreator.module.scss";
import useBannerTableCnt from "./useBannerTableCnt";
import { saveBannerGroup } from "api/banner";

type TProps = {
  data?: TBannerGroup;
};

export default function BannerGroupCreator({ data }: TProps) {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    trigger,
  } = useForm<TBannerGroup>({
    defaultValues: data,
  });

  const saveGroupMuta = useMutation(saveBannerGroup, {
    onError: () => {
      setSaveModal("");
      setFinalModal("");
    },
  });

  const [isModal, setIsModal] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState<TBanner | null>(null);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleModalOpen = () => {
    setIsModal(true);
  };
  const handleModalClose = () => {
    setSelectedBanner(null);
    setIsModal(false);
  };
  const handleBannerCreate = (arg: TBanner) => {
    // type에 따라 데이터 정제
    let newBanner = { ...arg };

    if (arg.type === "2") {
      newBanner = {
        ...arg,
        url: undefined,
        isToken: undefined,
      };
    }

    if (arg.type === "3") {
      newBanner = {
        ...arg,
        url: undefined,
        isToken: undefined,
      };
    }

    const curBannerList = getValues("bannerList") || [];
    setValue("bannerList", [...curBannerList, newBanner]);
  };

  const handleBannerUpdate = (arg: TBanner) => {
    const curBannerList = getValues("bannerList") || [];
    const newBannerList = curBannerList.map((item) => {
      if (item.id === arg.id) {
        return arg;
      }

      return item;
    });

    setValue("bannerList", newBannerList);
  };

  const handleBannerEditOpen = (banner: TBanner) => {
    setSelectedBanner(banner);
    setIsModal(true);
  };

  const handleMoveBanner = (id: string, bannerList: TBanner[], direction: "up" | "down") => {
    const index = bannerList.findIndex((banner) => banner.id === id);
    if (index === -1) {
      return;
    }
    const newList = [...bannerList];
    if (direction === "up" && index > 0) {
      [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
    }

    if (direction === "down" && index < newList.length - 1) {
      [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
    }

    setValue("bannerList", newList);
  };

  const [removeModal, setRemoveModal] = useState<"confirm" | "noti" | "">("");
  const idRef = useRef("");

  const handleRemoveModalOpen = (id: string) => {
    setRemoveModal("confirm");
    idRef.current = id;
  };
  const handleRemoveModalClose = () => setRemoveModal("");

  const handleRemoveBanner = () => {
    const bannerList = getValues("bannerList");
    const newBannerList = bannerList.filter((item) => item.id !== idRef.current);
    setValue("bannerList", newBannerList);
    idRef.current = "";
    setRemoveModal("noti");
  };

  const tagleCntTxt = useBannerTableCnt(getValues("bannerList") || []);
  const { columns, rows } = useBannerTableData(
    watch("bannerList") || [],
    handleBannerEditOpen,
    handleMoveBanner,
    handleRemoveModalOpen,
  );

  const [saveModal, setSaveModal] = useState<"confirm" | "noti" | "">("");
  const handleSaveModalOpen = () => {
    const bannerGroup = getValues();
    if (!bannerGroup?.bannerList || bannerGroup.bannerList.length < 1) {
      handleSaveModalClose();
      setIsValidNoti(true);
      return;
    }

    setSaveModal("confirm");
  };
  const handleSaveModalClose = () => setSaveModal("");

  const handleInnerSubmit = async () => {
    const bannerGroup = getValues();
    await saveGroupMuta.mutateAsync({ data: bannerGroup, isFinal: false });
    queryClient.invalidateQueries(["getBannerList"]);
    setSaveModal("noti");
  };

  const handleSaveNotiModalClose = () => {
    navigate("/event/banner");
    handleSaveModalClose();
  };

  const [isValidNoti, setIsValidNoti] = useState(false);
  const handleValidClose = () => setIsValidNoti(false);

  const [isResetModal, setIsResetModal] = useState(false);
  const handleResetModalOpen = () => setIsResetModal(true);
  const handleResetModalClose = () => setIsResetModal(false);

  const handleReset = () => {
    reset({
      ...data,
      groupName: "",
      bannerList: [],
    });
    handleResetModalClose();
  };

  const [finalModal, setFinalModal] = useState<"confirm" | "noti" | "">("");
  const handleFinalModalOpen = async () => {
    const isNameValid = await trigger("groupName");
    if (!isNameValid) {
      return;
    }

    const bannerGroup = getValues();
    if (!bannerGroup?.bannerList || bannerGroup.bannerList.length < 1) {
      setIsValidNoti(true);
      return;
    }

    setFinalModal("confirm");
  };
  const handleFinalModalClose = () => setFinalModal("");

  const handleFinalSave = async () => {
    const result: TBannerSavaParam = {
      resourceNo: getValues("resourceNo"),
      groupName: getValues("groupName"),
      bannerList: getValues("bannerList"),
    };

    await saveGroupMuta.mutateAsync({ data: result, isFinal: true });
    queryClient.invalidateQueries(["getBannerList"]);
    setFinalModal("noti");
  };

  const handleFinalNotiModalClose = () => {
    navigate("/event/banner");
    handleFinalModalClose();
  };

  return (
    <>
      <Content isWithFooter>
        <form id="banner-group" className={styles.wrapper} onSubmit={handleSubmit(handleSaveModalOpen)}>
          <Title text="그룹 설정" />
          <Panel>
            <label className={styles.input_box} aria-invalid={errors.groupName ? "true" : "false"}>
              <div className={styles.group_title}>
                그룹명
                <span className={styles.required}>*</span>
              </div>

              <input
                type="text"
                placeholder="제목을 입력해주세요.(최대 40자까지 작성 가능합니다)"
                maxLength={40}
                className={styles.group_input}
                {...register("groupName", {
                  required: true,
                })}
              />
            </label>
          </Panel>

          <Title text="배너 목록" />
          <Panel>
            <div className={styles.table_header}>
              <TableCnt text={tagleCntTxt} />
            </div>

            <Table columns={columns} rows={rows} />

            {}

            <div className={styles.table_footer}>
              <Button type="button" size="medium" mode="outlined" onClick={handleModalOpen}>
                배너 추가
              </Button>
            </div>
          </Panel>
        </form>
      </Content>

      <Footer>
        <Button type="button" mode="outlined" size="long" onClick={handleResetModalOpen}>
          초기화
        </Button>
        <Button type="submit" size="long" form="banner-group">
          {data ? "수정하기" : "그룹 생성"}
        </Button>
        {data && (
          <Button type="button" size="long" onClick={handleFinalModalOpen}>
            최종 저장
          </Button>
        )}
      </Footer>

      {isModal && !selectedBanner && <BannerCreator onSubmit={handleBannerCreate} onClose={handleModalClose} />}
      {isModal && selectedBanner && (
        <BannerCreator data={selectedBanner} onSubmit={handleBannerUpdate} onClose={handleModalClose} />
      )}

      {removeModal === "confirm" && (
        <Modal
          title="해당 배너를 삭제하시겠습니까?"
          description="등록된 배너가 삭제됩니다."
          btnType="submit"
          handleSubmit={handleRemoveBanner}
          handleClose={handleRemoveModalClose}
        />
      )}
      {removeModal === "noti" && (
        <Modal btnType="confirm" description="배너가 삭제되었습니다." handleClose={handleRemoveModalClose} />
      )}

      {saveModal === "confirm" && (
        <Modal
          title={`배너 그룹을 ${data ? "수정" : "생성"}하시겠습니까?`}
          description={`배너 그룹이 ${
            data ? "수정" : "생성"
          }됩니다. \n [최종 저장]을 클릭해야 해당 그룹을 게시할 수 있습니다.`}
          btnType="submit"
          handleSubmit={handleInnerSubmit}
          handleClose={handleSaveModalClose}
        />
      )}
      {saveModal === "noti" && (
        <Modal
          description={`배너 그룹이 ${data ? "수정" : "생성"}되었습니다.`}
          btnType="confirm"
          handleClose={handleSaveNotiModalClose}
        />
      )}

      {isValidNoti && (
        <ErrorModal
          message={`등록된 배너가 없습니다.\n하나 이상의 배너를 등록해 주세요.`}
          onConfirmHandler={handleValidClose}
        />
      )}

      {isResetModal && (
        <Modal
          btnType="confirm"
          description="입력한 정보가 모두 초기화 됩니다"
          handleSubmit={handleReset}
          handleClose={handleResetModalClose}
        />
      )}

      {finalModal === "confirm" && (
        <Modal
          btnType="submit"
          title="배너 그룹을 최종 저장하시겠습니까?"
          description="최종 저장시 수정이 불가합니다."
          handleSubmit={handleFinalSave}
          handleClose={handleFinalModalClose}
        />
      )}
      {finalModal === "noti" && (
        <Modal btnType="confirm" description="최종 저장되었습니다." handleClose={handleFinalNotiModalClose} />
      )}

      {saveGroupMuta.isError && (
        <ErrorModal message="서버에 문제가 발생했습니다." onConfirmHandler={() => saveGroupMuta.reset()} />
      )}
    </>
  );
}
