import { Controller, useFormContext } from "react-hook-form";
import styles from "styles/pages/contentsManage/challenge/write/writeProcess.module.scss";
import { Panel } from "comp-lib";
import { TChallengeProcess } from "types/contentsManage/challenge";
import { ControllerDropdown } from "common/dropdown";
import ChallengeProgressDateBox from "./ChallengeProgressDateBox";
import ChallengeApplyDateBox from "./ChallengeApplyDateBox";
import { getCommasInNum } from "utils";
import { CHALLENGE_REWARD_POINT_MULTIPLIER_LIST } from "../constants";

export const WriteProcess = () => {
  const {
    register,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<TChallengeProcess>();

  const applyPointPerPersion = watch("applyPointPerPerson") || 0;
  const rewardPointPerPerson = applyPointPerPersion * (+watch("rewardPointPerPerson") || 2);
  const participantsCount = watch("participantsCount") || 0;

  return (
    <Panel className={styles.wrapper}>
      <section>
        <span
          className={styles.title}
          data-must
          aria-invalid={errors.applyStartDate || errors.applyEndDate ? "true" : "false"}
        >
          챌린지 모집 기간
        </span>
        <ChallengeApplyDateBox />
      </section>

      <section>
        <span className={styles.title} data-must aria-invalid={errors.startDate || errors.endDate ? "true" : "false"}>
          챌린지 진행 기간
        </span>
        <ChallengeProgressDateBox />
      </section>

      <section>
        <span className={styles.title} data-must aria-invalid={errors.participantsCount ? "true" : "false"}>
          모집 목표 인원
        </span>
        <div className={styles.inputWrapper}>
          <input
            type="number"
            onWheel={({ target }) => (target as HTMLElement).blur()}
            {...register("participantsCount", {
              onChange: () => trigger("participantsCount"),
              valueAsNumber: true,
              required: "모집 목표 인원을 입력해주세요",
              validate: {
                value: (number) => {
                  if (10 > number || 1000 < number) return "최소 10명 이상, 최대 1,000명 이하여야 합니다.";
                  if (number && !Number.isInteger(number)) return "소수점은 사용할 수 없습니다.";
                },
              },
            })}
          />
          <span>명</span>
          <p>(목표인원 설정 : 최소 10명 이상 ~최대 1,000명 이하)</p>
        </div>
        {errors.participantsCount && <p className={styles.errorMsg}>{errors.participantsCount.message}</p>}
      </section>

      <section>
        <span className={styles.title} data-must aria-invalid={errors.applyPointPerPerson ? "true" : "false"}>
          1인당 참가 포인트(차감)
        </span>
        <div className={styles.inputWrapper}>
          <input
            type="number"
            placeholder="100P 단위로 입력 (100P ~ 3,000P)"
            onWheel={({ target }) => (target as HTMLElement).blur()}
            {...register("applyPointPerPerson", {
              onChange: () => trigger("applyPointPerPerson"),
              valueAsNumber: true,
              required: "1인당 참가 포인트를 입력해주세요",
              validate: {
                value: (number) => {
                  if (number > 3000) return "3,000 포인트를 초과하여 설정할 수 없습니다.";
                  if (number < 100) return "100 포인트 미만으로 설정할 수 없습니다.";
                  if (number % 100 !== 0) return "100원 단위로만 입력 가능합니다.";
                },
              },
            })}
          />
          <span>P</span>
        </div>
        {errors.applyPointPerPerson && <p className={styles.errorMsg}>{errors.applyPointPerPerson.message}</p>}
      </section>

      <section>
        <div className={styles.titleWithDesc} data-must>
          <span className={styles.title} data-must>
            총 참가 포인트
          </span>
          <p>(목표인원 ✕ 1인당 성공시 지급 포인트), (실패시 총 참가포인트÷성공인원 지급)</p>
        </div>
        <span
          className={styles.point}
        >{`${getCommasInNum(watch("participantsCount") * watch("applyPointPerPerson") || 0)}P`}</span>
      </section>

      <section>
        <span className={styles.title} data-must aria-invalid={errors.rewardPointPerPerson ? "true" : "false"}>
          100% 성공시 1인당 지급 포인트
        </span>
        <div className={styles.inputWrapper}>
          <div>
            {getCommasInNum(applyPointPerPersion)}P<span>(1인당 참가 포인트)</span>
          </div>

          <span className={styles.operator}>✕</span>

          <ControllerDropdown
            name="rewardPointPerPerson"
            dataList={CHALLENGE_REWARD_POINT_MULTIPLIER_LIST}
            width="55px"
            mode="secondary"
            borderColor="soft"
            maxHeight="120px"
          />

          <div>{`배수   =`}</div>
          <div className={styles.resultPoint}>{getCommasInNum(rewardPointPerPerson)}P</div>
        </div>
        {errors.rewardPointPerPerson && <p className={styles.errorMsg}>{errors.rewardPointPerPerson.message}</p>}
      </section>

      <section>
        <div className={styles.titleWithDesc}>
          <span className={styles.title}>성공 시 총 사용 예정 포인트</span>
          <p>(목표인원 ✕ 1인당 성공시 지급 포인트)</p>
        </div>
        <span className={styles.point}>{`${getCommasInNum(participantsCount * rewardPointPerPerson)}P`}</span>
      </section>
    </Panel>
  );
};
