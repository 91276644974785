import { FilterData, useCustomParams } from "comp-lib";
import { SavedBuilableResource } from "protobuf/OpenApiServerV3";

export default function useFilterData(data: SavedBuilableResource[]) {
  // status 재정의
  const result = data.map((item) => {
    return {
      ...item,
      status: createStatus(item.isFinal, item.isPublished),
    };
  });

  const filteredData = new FilterData(result).sortDesc("createdAt").getResult();

  // 게시중인 상태를 맨 위로 올리기
  filteredData.sort((a, b) => {
    if (a.status === 2) {
      return -1;
    }

    if (b.status === 2) {
      return 1;
    }

    return 0;
  });

  const len = filteredData.length;
  const dataWithNo = filteredData.map((item, idx) => ({
    ...item,
    no: len - idx,
  }));

  const [{ pageNum }] = useCustomParams({
    pageNum: "1",
  });
  const dataForTableRows = new FilterData(dataWithNo).filterByPage(Number(pageNum)).getResult();
  const totalPages = Math.floor(filteredData.length / 10) + (filteredData.length % 10 && 1) || 1;

  return { dataForTableRows, totalPages };
}

// 0: ["임시저장", "DRAFT"],
// 1: ["최종저장", "FINAL_SAVED"]
// 2: ["게시중", "PUBLISHED"]
function createStatus(isFinal: boolean, isPublished: boolean) {
  if (isPublished) {
    return 2;
  }

  if (isFinal) {
    return 1;
  }

  return 0;
}
