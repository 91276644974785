import { TModalContent } from "types/mydInquiry";

export const STATUS_MAP: { [key: string]: string } = {
  DONE: "완료",
  REGISTERED: "접수",
  PROCESSING: "진행중",
  INREVIEW: "검토중",
};

export const TYPE: { [key: string]: string } = {
  NORMAL: "일반",
  ERROR: "오류 및 불편",
  QUESTION: "이용문의",
  REWARD: "적립",
};

export const MODAL_CONTENTS: { [key: string]: TModalContent } = {
  SEND_SUBMIT: { title: "전송하기", description: `등록한 답변이 사용자에게 전송됩니다.`, btnType: "submit" },
  SEND_CONFIRM: { title: "전송 완료", description: `등록한 답변이 사용자에게 전송되었습니다.`, btnType: "confirm" },
  COMPLETE_SUBMIT: { title: "완료하기", description: `해당 문의에 대해 이슈를 완료합니다.`, btnType: "submit" },
  COMPLETE_CONFIRM: { title: "해결 완료", description: `문의가 완료되었습니다.`, btnType: "confirm" },
  JIRA_UPDATE_CONFIRM: { title: "업데이트 완료", description: `JIRA 상태가 업데이트 되었습니다.`, btnType: "confirm" },
  JIRA_CREATE_CONFIRM: { title: "이슈 생성 완료", description: `JIRA 이슈가 생성되었습니다.`, btnType: "confirm" },
  EDIT_CONFIRM: { title: "수정 완료", description: `수정한 답변이 사용자에게 전송되었습니다.`, btnType: "confirm" },
  DELETE_SUBMIT: { title: "삭제하기", description: `선택한 답변을 삭제하시겠습니까?`, btnType: "submit" },
  GO_TO_LIST: {
    title: "목록이동",
    description: `등록한 답변내용이 모두 사라집니다.\n목록으로 이동하시겠습니까?`,
    btnType: "submit",
  },
};
