import styles from "styles/pages/promotion/create/createUI/labelInput.module.scss";

type TProps = React.ComponentProps<"input"> & {
  label: string;
  children: React.ReactNode;
};

export default function LabelInput({ label, children }: TProps) {
  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {label} <span>*</span>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
}
