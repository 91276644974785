import { TGetUserMail, TPasswordChange, TPasswordFind } from "types/login";
import { TAxiosReturn } from "types/common";
import { Axios } from "./base";
import { failureHandling } from "./errorHandling";

export const postPasswordFind = async ({ name, email }: TPasswordFind): Promise<{ result: boolean }> => {
  try {
    const { data } = await Axios.post<TAxiosReturn<{ result: boolean }>>("/api/password/find", {
      name,
      email,
    });

    return data.data;
  } catch {
    throw failureHandling(Error(`이름 또는 이메일 정보가 일치하지 않습니다.\n다시 입력해주세요.`));
  }
};

export const postPasswordChange = async ({
  password,
  passwordCheck,
}: TPasswordChange): Promise<{ result: boolean }> => {
  const enc = new URLSearchParams(window.location.search).get("enc");

  const { data } = await Axios.post<TAxiosReturn<{ result: boolean }>>("/api/password/change", {
    password,
    passwordCheck,
    enc,
  });

  return data.data;
};

export const postUserEmail = async (enc: string): Promise<string> => {
  const { data } = await Axios.post<TAxiosReturn<TGetUserMail>>("/api/password/email", { enc });

  return data.data.result;
};
