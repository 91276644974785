import styles from "styles/pages/manager/registerPage.module.scss";
import { Button, Content, Footer, Modal, Panel, Title, ErrorModal, LoadingModal } from "comp-lib";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { RadioBox } from "common/input";
import CheckboxForm from "./components/CheckboxForm";
import cx from "classnames";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_CHECKBOX_OPTIONS, ADMIN_ROLE_LIST, isAdmin } from "./managerHelper";
import { TModifyManagerStaff, TManagerListResponse } from "types/manager";
import { useMutation, useQueryClient } from "react-query";
import { modifyManagerStaff } from "api/admin/manager";
import queryKey from "constants/queryKey";
import ROLE from "constants/role";

export default function ModifyPage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<FieldValues & TModifyManagerStaff>({ mode: "all" });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogState, setDialogState] = useState(dialogMap.default);
  const navigate = useNavigate();
  const { pathname, state }: { pathname: string; state: TManagerListResponse } = useLocation();
  const queryClient = useQueryClient();

  const {
    mutate: updateStaff,
    isError,
    isLoading,
    error,
  } = useMutation(modifyManagerStaff, {
    onSuccess: () => {
      setDialogState({
        ...dialogMap.등록완료,
        handleClose: () => navigate(`/users/${id}`),
      });
      queryClient.invalidateQueries(queryKey.GET_OWN);
    },
    onError: () => setIsDialogOpen(false),
  });
  const id = pathname.split("/").pop();

  const onSubmitButton = (inputData: TModifyManagerStaff) => {
    handleSubmit(onSubmitButton);
    onClickSubmitButton(inputData);
  };

  const onClickSubmitButton = (inputData: TModifyManagerStaff) => {
    setIsDialogOpen(true);
    setDialogState({
      ...dialogMap.등록확인,
      handleClose: () => setIsDialogOpen(false),
      handleSubmit: () => {
        updateStaff({ ...inputData, staffId: state.id, email: state.email });
      },
    });
  };

  const onClickCancleButton = () => {
    setIsDialogOpen(true);
    setDialogState({
      ...dialogMap.취소확인,
      handleSubmit: () => setIsDialogOpen(false),
      handleClose: () => navigate(-1),
    });
  };

  return (
    <Content>
      <Title text="사용자 정보 수정" />
      <Panel className={styles.panel}>
        <div className={styles.innerPannel}>
          <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitButton)}>
            <div className={styles.mustLabel}>권한</div>
            <Controller
              defaultValue={isAdmin(state?.role) ? ROLE.SNP_ADMIN : ROLE.SNP_MANAGER}
              name="role"
              control={control}
              rules={{ required: "권한을 선택해주세요" }}
              render={({ field: { value, onChange } }) => {
                return (
                  <div className={styles.radioBoxWrapper}>
                    <RadioBox name="role" dataList={ADMIN_ROLE_LIST} value={value} handleCheck={onChange} />
                  </div>
                );
              }}
            />
            {!isAdmin(watch("role") ?? state?.role) && (
              <>
                <div className={styles.mustLabel}>접근 가능 메뉴</div>
                <Controller
                  name="authority"
                  control={control}
                  rules={{
                    validate: (value) => value.filter((v) => v !== "0").length > 0,
                  }}
                  defaultValue={state?.authority?.split(",") || []}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <CheckboxForm
                        name="authority"
                        value={value}
                        options={ADMIN_CHECKBOX_OPTIONS}
                        handleChange={onChange}
                        isError={!!errors.authority}
                        defaultValue={value}
                      />
                    );
                  }}
                />
              </>
            )}
            <div className={styles.mustLabel}>이름</div>
            <input
              defaultValue={state?.name}
              className={cx(styles.input, { [styles.error]: !!errors.staffName })}
              placeholder="이름을 입력해 주세요"
              {...register("staffName", { required: true })}
            />
            <div className={styles.mustLabel}>이메일주소</div>
            <div>{state?.email}</div>
            <div className={styles.mustLabel}>부서명</div>
            <input
              defaultValue={state?.department}
              className={cx(styles.input, { [styles.error]: !!errors.department })}
              placeholder="부서명을 입력해 주세요"
              {...register("department", { required: true })}
            />
            <div className={styles.mustLabel}>직급</div>
            <input
              defaultValue={state?.jobPosition}
              className={cx(styles.input, { [styles.error]: !!errors.jobPosition })}
              placeholder="직급을 입력해 주세요"
              {...register("jobPosition", { required: true })}
            />
            <div className={styles.mustLabel}>개별 연락처</div>
            <input
              defaultValue={state?.phone}
              className={cx(styles.input, { [styles.error]: !!errors.phone })}
              placeholder="- 를 제외한 숫자만 입력해 주세요"
              maxLength={11}
              {...register("phone", { required: true, pattern: /^[0-9]*$/ })}
            />
            <div className={styles.label}>직통 번호</div>
            <input
              defaultValue={state?.tel}
              className={cx(styles.input, { [styles.error]: !!errors.tel })}
              placeholder="- 를 제외한 숫자만 입력해 주세요"
              maxLength={11}
              {...register("tel", { required: false, pattern: /^[0-9]*$/ })}
            />
          </form>
        </div>
      </Panel>

      <Footer>
        <Button onClick={onClickCancleButton} mode="outlined" size="long" type="button" className={styles.cancleBtn}>
          취소
        </Button>
        <Button onClick={handleSubmit(onSubmitButton)} size="long">
          수정 등록
        </Button>
      </Footer>
      {isDialogOpen && <Modal {...dialogState} />}
      {!state && <ErrorModal error="" />}
      {isError && <ErrorModal error={error} />}
      {isLoading && <LoadingModal />}
    </Content>
  );
}

const dialogMap = {
  default: {
    title: "",
    description: "",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "confirm" as "confirm" | "submit",
    handleClose: () => {},
    handleSubmit: () => {},
  },
  등록확인: {
    title: "사용자 정보를 수정하시겠습니까?",
    description: "입력된 정보로 사용자가 수정됩니다",
    cancelBtnName: "취소",
    submitBtnName: "수정",
    confirmBtnName: "확인",
    btnType: "submit" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
  등록완료: {
    title: "",
    description: "사용자 정보 수정 등록이 완료되었습니다.",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "confirm" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
  취소확인: {
    title: "사용자 정보 수정을 취소하시겠습니까?",
    description: "지금까지 작성한 내용은 저장되지 않습니다.",
    cancelBtnName: "나가기",
    submitBtnName: "계속하기",
    confirmBtnName: "확인",
    btnType: "submit" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
  취소완료: {
    title: "",
    description: "",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "confirm" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
};
