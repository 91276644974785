import { deleteManagerStaff, getManagerDetail } from "api/admin/manager";
import { Button, Content, Footer, Modal, Panel, Title, ErrorModal } from "comp-lib";
import queryKey from "constants/queryKey";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "styles/pages/manager/detailPage.module.scss";
import { authorityMapping, isAdmin } from "./managerHelper";

export default function DetailPage() {
  const id = useLocation().pathname.split("/").pop();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState<"confirm" | "submit" | false>(false);

  const { data, isError, isLoading, error } = useQuery(queryKey.GET_ADMIN_MANAGER_DETAIL, () => getManagerDetail(id!));
  const { mutate: deleteManager, isError: isDeleteError } = useMutation(() => deleteManagerStaff(id!));

  const handledDialogMap = {
    confirm: {
      ...dialogMap.confirm,
      handleClose: () => setIsDialogOpen(false),
      handleSubmit: () => {
        deleteManager();
        setIsDialogOpen("submit");
      },
    },
    submit: {
      ...dialogMap.submit,
      handleClose: () => {
        navigate("/users", { replace: true });
        setIsDialogOpen(false);
      },
    },
  };

  return (
    <Content>
      <Title text="사용자 상세정보" />
      <Panel className={styles.panel}>
        {isLoading ? (
          <div />
        ) : (
          <div className={styles.infoWrapper}>
            <span>권한</span>
            <p>{data && isAdmin(data?.role) ? "관리자" : "운영자"}</p>
            {data && !isAdmin(data?.role) && <span>접근 가능 메뉴</span>}
            {data && !isAdmin(data?.role) && <p>{authorityMapping(data.authority) || "-"}</p>}
            <span>이름</span>
            <p>{data?.name || "-"}</p>
            <span>이메일 주소</span>
            <p>{data?.email || "-"}</p>
            <span>부서명</span>
            <p>{data?.department || "-"}</p>
            <span>직급</span>
            <p>{data?.jobPosition || "-"}</p>
            <span>개별 연락처</span>
            <p>{data?.phone || "-"}</p>
            <span>직통 번호</span>
            <p>{data?.tel || "-"}</p>
          </div>
        )}
        <div className={styles.buttonWrapper}>
          <Button mode="outlined" onClick={() => setIsDialogOpen("confirm")}>
            삭제
          </Button>
          <Link to={`/users/modify/${id}`} state={data}>
            <Button>수정</Button>
          </Link>
        </div>
      </Panel>
      {isDialogOpen && <Modal {...handledDialogMap[isDialogOpen]} />}

      <Footer>
        <Button size="long" onClick={() => navigate(-1)}>
          목록으로
        </Button>
      </Footer>
      {(isError || !id) && <ErrorModal error={Error("사용자 상세정보를 불러오는 중\n문제가 발생하였습니다")} />}
      {isDeleteError && <ErrorModal error={error} />}
    </Content>
  );
}

const dialogMap = {
  confirm: {
    title: "사용자 계정을 정말 삭제하시겠습니까?",
    description: "삭제된 계정은 복구할 수 없습니다.",
    cancelBtnName: "취소",
    submitBtnName: "확인",
    confirmBtnName: "확인",
    btnType: "submit" as "confirm" | "submit",
    handleClose: () => {},
    handleSubmit: () => {},
  },
  submit: {
    title: "",
    description: "사용자 계정이 삭제되었습니다.",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "confirm" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
};
