import { useFormContext } from "react-hook-form";

import styles from "styles/pages/mydInquiry/detail/commentEditModal.module.scss";

import { Button, Portal } from "comp-lib";

import ImgModal from "./ImgModal";
import { TextArea } from "common/input";
import InfoItem from "common/other/InfoItem";
import FileInputMultiple from "common/input/FileInputMultiple";

import useModal from "hooks/useModal";
import { getCommasInNum } from "utils";
import { TMydInquiryComment } from "types/mydInquiry";

type Props = {
  data: TMydInquiryComment;
  handleClose: () => void;
  handleSubmit: () => void;
};

type TServerImg = { id: string; name: string };

type TLocalImg = { file: File; url: string };

const MAX_LENGTH = 1000;

export default function CommentEditModal({ data, handleClose, handleSubmit }: Props) {
  const [isModalOpen, onToggleModal] = useModal();

  const { getValues, watch } = useFormContext();
  const serverImgArr = (getValues("commentImg") as TServerImg[]).filter(({ id }) => id).map(({ id }) => id);

  const localImgArr = (getValues("commentImg") as TLocalImg[]).map(({ url }) => url);

  const formData = watch();

  const commentImg = formData.commentImg.length === 0 ? null : formData.commentImg;

  const isSameBefore =
    formData.comment === data.contents && JSON.stringify(commentImg) === JSON.stringify(data.attachedFileInfos);

  return (
    <Portal>
      <div className={styles.dialog}>
        <div className={styles.content}>
          <p>답변 수정하기</p>

          <TextArea
            id="comment"
            maxLength={MAX_LENGTH}
            placeholder={`답변을 입력해 주세요 (최대 ${getCommasInNum(MAX_LENGTH)}자)`}
            required
          />

          <div className={styles.start}>
            <InfoItem
              isLast
              title="이미지 등록"
              value={
                <FileInputMultiple id="commentImg" sizeLimit={5} maxLength={3} onClick={onToggleModal}>
                  파일 선택
                </FileInputMultiple>
              }
            />
          </div>

          <div className={styles.buttonBox}>
            <Button type="button" mode="lineGray" onClick={handleClose}>
              취소
            </Button>
            <Button type="submit" onClick={handleSubmit} disabled={isSameBefore}>
              확인
            </Button>
          </div>
        </div>

        {isModalOpen && <ImgModal serverImg={serverImgArr} localImg={localImgArr} onToggleModal={onToggleModal} />}
      </div>
    </Portal>
  );
}
