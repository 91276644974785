import { TPushResponse, TPushDetail, TListParams, TPushList, TPushResult, TPushCheck } from "types/appPush";
import { Axios } from "./base";

import queryString from "query-string";

// 목록 조회
export const getPushList = async ({
  keyword,
  startDate,
  endDate,
  status,
  pageNo,
  pageSize,
}: TListParams): Promise<TPushList> => {
  if (status?.length! > 1) {
    const statusArr = queryString.stringify({ status });
    const response = await Axios.get(`/api/admin/app-push/list?${statusArr}`, {
      params: { keyword, startDate, endDate, pageNo, pageSize },
    });

    return response.data.data;
  }

  const response = await Axios.get(`/api/admin/app-push/list`, {
    params: { keyword, startDate, endDate, status, pageNo, pageSize },
  });

  return response.data.data;
};

// 상세 정보 조회
export const getPushDetail = async (id: number): Promise<TPushDetail> => {
  const response = await Axios.get(`/api/admin/app-push/detail/${id}`);

  return response.data;
};

// 등록
export const postPush = async ({ reqBody }: any): Promise<TPushResponse> => {
  const response = await Axios.post(`/api/admin/app-push/create`, reqBody, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data;
};

// CSV 다운로드
export const getPushResult = async (id: number): Promise<TPushResult> => {
  const response = await Axios.get(`/api/admin/app-push/result/${id}`);

  return response.data;
};

// 예약 취소(삭제)
export const deletePush = async (id: number): Promise<TPushResponse> => {
  const response = await Axios.delete(`/api/admin/app-push/delete/${id}`);

  return response.data;
};

export const checkPushStatus = async (status: string): Promise<TPushCheck> => {
  const response = await Axios.get(`/api/admin/app-push/check/${status}`);

  return response.data;
};
