import { ChangeEvent, ComponentProps } from "react";

type TProps = {
  value: string;
  onChange: (arg: string) => void;
} & Omit<ComponentProps<"input">, "onChange">;

const addComma = (value: string | number) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const delComma = (value: string) => value.replace(/,/gi, "");

export default function NumInput({ value, onChange, ...props }: TProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let onlyNums = e.currentTarget.value.replace(/[^0-9]/g, ""); // 숫자가 아닌 모든 문자를 제거
    onlyNums = onlyNums.replace(/^0+/, "");
    onChange(delComma(onlyNums));
  };

  return <input type="text" autoComplete="off" value={addComma(value)} onChange={handleChange} {...props} />;
}
