import styles from "styles/pages/advertisement/detail/adButtonBox.module.scss";
import { useNavigate } from "react-router-dom";
import { Button, Footer } from "comp-lib";

interface Props {
  onClickReject: () => void;
  onClickBack?: () => void;
  onClickConfirm: () => void;
  status: number;
}

export default function ButtonBox({ onClickReject, onClickBack, onClickConfirm, status }: Props) {
  const navigate = useNavigate();
  const hanldeClickBack = () => navigate(-1);

  return (
    <Footer>
      {status === 0 && (
        <Button type="button" size="long" onClick={onClickReject} mode="outlined">
          반려
        </Button>
      )}

      <Button type="button" size="long" className={styles.back} onClick={onClickBack || hanldeClickBack}>
        목록으로
      </Button>

      {status === 0 && (
        <Button type="button" size="long" onClick={onClickConfirm}>
          승인
        </Button>
      )}
    </Footer>
  );
}
