import { Button, TWithNo } from "comp-lib";
import { useState } from "react";
import { Link } from "react-router-dom";
import { TCompanyListItem } from "types/company";
import ManagerBtn from "../@shared/ManagerBtn";

export default function useTableData(data: TWithNo<TCompanyListItem>[]) {
  const [clickItem, setClickItem] = useState({ id: 0, name: "", enabled: true });

  const formattingBusinessType = (businessType: string) => {
    if (businessType === "0") return "국내";
    if (businessType === "1") return "해외";
    return "-";
  };

  const rows = data.map((item) => [
    item.no,
    formattingBusinessType(item.target.business_type),
    item.enabled ? (
      <Link to={`/company/info/${item.companyId}`} key={item.companyId}>
        {item.target.name}
      </Link>
    ) : (
      item.target.name
    ),

    item.did,
    <ManagerBtn
      key={item.companyId}
      staffId={item.staffId}
      staffName={item.staffName}
      status={item.status}
      enabled={item.enabled}
      companyName={item.target.name}
      companyId={item.companyId}
      staffEnabled={item.staffEnabled}
    />,
    item.enabled ? (
      <Button
        size="short"
        mode="outlined"
        style={{ width: "70px" }}
        onClick={() => setClickItem({ id: item.companyId, name: item.target.name, enabled: item.enabled })}
      >
        비활성화
      </Button>
    ) : (
      <Button
        size="short"
        style={{ width: "70px" }}
        onClick={() => setClickItem({ id: item.companyId, name: item.target.name, enabled: item.enabled })}
      >
        활성화
      </Button>
    ),
  ]);

  return { columns, rows, clickItem, setClickItem };
}

const columns = [
  { text: "NO", width: "49px" },
  { text: "구분", width: "95px" },
  { text: "기업명" },
  { text: "DID", width: "380px" },
  { text: "대표 담당자명", width: "154px" },
  { text: "상태", width: "118px" },
];
