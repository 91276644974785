import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import cx from "classnames";

import { RadioBox } from "common/input";
import CheckboxForm from "../manager/components/CheckboxForm";
import PasswordChange from "pages/mypage/PasswordChange";
import { Button, Content, Footer, Modal, Panel, Title, LoadingModal, ErrorModal } from "comp-lib";
import { ADMIN_CHECKBOX_OPTIONS, ADMIN_ROLE_LIST, isAdmin } from "../manager/managerHelper";
import { TModifyManagerStaff } from "types/manager";
import { getManagerDetail, modifyManagerStaff } from "api/admin/manager";
import { getCompanyDetail } from "api/company";
import queryKey from "constants/queryKey";
import useModal from "hooks/useModal";

import styles from "styles/pages/mypage/myInfo.module.scss";
import ROLE from "constants/role";

export default function MyInfo() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<FieldValues & TModifyManagerStaff>({ mode: "all" });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: companyDetailData } = useQuery([queryKey.GET_DETAIL_COMPANY], getCompanyDetail);
  const staffId = companyDetailData?.staffId as number;

  const { data } = useQuery([queryKey.GET_ADMIN_MANAGER_DETAIL, staffId], () => getManagerDetail(String(staffId)), {
    enabled: !!staffId,
  });

  const [dialogdata, setDialogdata] = useState(dialogMap.default);
  const [isDialogOpen, setIsDialogOpen] = useModal();
  const [isPwModal, setIsPwModal] = useModal();

  const {
    mutate: updateStaff,
    isError,
    isLoading,
    error,
  } = useMutation(modifyManagerStaff, {
    onSuccess: () => {
      setDialogdata({
        ...dialogMap.등록완료,
        handleClose: () => navigate(`/`),
      });
      queryClient.invalidateQueries(queryKey.GET_OWN);
    },
    onError: setIsDialogOpen,
  });

  const onSubmitButton = (inputData: TModifyManagerStaff) => {
    handleSubmit(onSubmitButton);
    onClickSubmitButton(inputData);
  };

  const onClickSubmitButton = (inputData: TModifyManagerStaff) => {
    setIsDialogOpen();
    setDialogdata({
      ...dialogMap.등록확인,
      handleClose: setIsDialogOpen,
      handleSubmit: () => {
        updateStaff({ ...inputData, staffId: data?.id!, email: data?.email! });
      },
    });
  };

  const onClickCancleButton = () => {
    setIsDialogOpen();
    setDialogdata({
      ...dialogMap.취소확인,
      handleSubmit: setIsDialogOpen,
      handleClose: () => navigate(-1),
    });
  };

  if (!data) return null;

  return (
    <Content>
      <Title text="사용자 정보 수정" />

      {data && (
        <Panel className={styles.panel}>
          <div className={styles.innerPannel}>
            <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitButton)}>
              <div className={styles.label}>권한</div>
              <Controller
                defaultValue={isAdmin(data.role!) ? ROLE.SNP_ADMIN : ROLE.SNP_MANAGER}
                name="role"
                control={control}
                rules={{ required: "권한을 선택해주세요" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <div className={styles.radioBoxWrapper}>
                      <RadioBox
                        name="role"
                        dataList={ADMIN_ROLE_LIST}
                        value={value}
                        handleCheck={onChange}
                        disabled={data?.role === ROLE.SNP_MANAGER}
                      />
                    </div>
                  );
                }}
              />
              {!isAdmin(watch("role") ?? data?.role) && (
                <>
                  <div className={styles.label}>접근 가능 메뉴</div>
                  <div className={cx(styles.checkBoxWrapper, { [styles.disabled]: data?.role === ROLE.SNP_MANAGER })}>
                    <Controller
                      name="authority"
                      control={control}
                      defaultValue={data?.authority?.split(",") || []}
                      rules={{
                        validate: (value) => {
                          if (isAdmin(data.role)) return value.filter((v) => v !== "0").length > 0;
                          return true;
                        },
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <CheckboxForm
                            name="authority"
                            value={value}
                            options={ADMIN_CHECKBOX_OPTIONS}
                            handleChange={onChange}
                            isError={!!errors.authority}
                            defaultValue={value}
                            disabled={data?.role === ROLE.SNP_MANAGER}
                          />
                        );
                      }}
                    />
                  </div>
                </>
              )}
              <div className={styles.mustLabel}>이름</div>
              <input
                defaultValue={data?.name}
                className={cx(styles.input, { [styles.error]: !!errors.staffName })}
                placeholder="이름을 입력해 주세요"
                {...register("staffName", { required: true })}
              />

              <div className={styles.label}>이메일주소</div>
              <div className={styles.value}>{data?.email}</div>

              <div className={styles.label}>비밀번호</div>
              <Button type="button" mode="outlined" size="short" onClick={setIsPwModal}>
                비밀번호 변경하기
              </Button>

              {isPwModal && <PasswordChange setIsPwModal={setIsPwModal} />}

              <div className={styles.mustLabel}>부서명</div>
              <input
                defaultValue={data?.department}
                className={cx(styles.input, { [styles.error]: !!errors.department })}
                placeholder="부서명을 입력해 주세요"
                {...register("department", { required: true })}
              />
              <div className={styles.mustLabel}>직급</div>
              <input
                defaultValue={data?.jobPosition}
                className={cx(styles.input, { [styles.error]: !!errors.jobPosition })}
                placeholder="직급을 입력해 주세요"
                {...register("jobPosition", { required: true })}
              />
              <div className={styles.mustLabel}>개별 연락처</div>
              <input
                defaultValue={data?.phone}
                className={cx(styles.input, { [styles.error]: !!errors.phone })}
                placeholder="- 를 제외한 숫자만 입력해 주세요"
                maxLength={11}
                {...register("phone", { required: true, pattern: /^[0-9]*$/ })}
              />
              <div className={styles.label}>직통 번호</div>
              <input
                defaultValue={data?.tel}
                className={cx(styles.input, { [styles.error]: !!errors.tel })}
                placeholder="- 를 제외한 숫자만 입력해 주세요"
                maxLength={11}
                {...register("tel", { required: false, pattern: /^[0-9]*$/ })}
              />
            </form>
          </div>
        </Panel>
      )}

      <Footer>
        <Button onClick={onClickCancleButton} mode="lineGray" size="long" type="button">
          취소
        </Button>
        <Button onClick={handleSubmit(onSubmitButton)} size="long">
          수정 등록
        </Button>
      </Footer>
      {isDialogOpen && <Modal {...dialogdata} />}
      {isError && <ErrorModal error={error} />}
      {isLoading && <LoadingModal />}
    </Content>
  );
}

const dialogMap = {
  default: {
    title: "",
    description: "",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "confirm" as "confirm" | "submit",
    handleClose: () => {},
    handleSubmit: () => {},
  },
  등록확인: {
    title: "사용자 정보를 수정하시겠습니까?",
    description: "입력된 정보로 사용자가 수정됩니다",
    cancelBtnName: "취소",
    submitBtnName: "수정",
    confirmBtnName: "확인",
    btnType: "submit" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
  등록완료: {
    title: "",
    description: "사용자 정보 수정 등록이 완료되었습니다.",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "confirm" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
  취소확인: {
    title: "사용자 정보 수정을 취소하시겠습니까?",
    description: "지금까지 작성한 내용은 저장되지 않습니다.",
    cancelBtnName: "나가기",
    submitBtnName: "계속하기",
    confirmBtnName: "확인",
    btnType: "submit" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
  취소완료: {
    title: "",
    description: "",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "confirm" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
};
