import { ReactNode } from "react";
import styles from "./tableList.module.scss";

interface Props {
  columns: (string | string[])[];
  dataList: (string | ReactNode)[][];
}
export default function TableList({ columns, dataList }: Props) {
  return (
    <table className={styles.wrapper}>
      <thead>
        <tr>
          {columns.map((column) => {
            if (column === "") return <td key={column} className={styles.blank} />;

            if (typeof column === "string") return <td key={column}>{column}</td>;
            return (
              <td key={column[0]} style={{ width: `${column[1]}` }}>
                {column[0]}
              </td>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {dataList.map((datas) => {
          const key = `table-${datas[0]}`;

          const tdList = datas.map((data, index) => {
            if (index === 0) return null;
            if (index > 8) return null; // 8커럼 이후는 그리지 않음
            const key2 = `${key}-${index}`;
            return <td key={key2}>{data}</td>;
          });
          return <tr key={key}>{tdList}</tr>;
        })}
      </tbody>
    </table>
  );
}
