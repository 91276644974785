import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { Title, Button, Footer, Content, Modal } from "comp-lib";

import styles from "styles/pages/appPush/detail/appPushDetail.module.scss";

import Info from "./Info";
import Message from "./Message";
import CurrStatus from "./CurrStatus";

import { deletePush, getPushDetail } from "api/appPush";
import queryKey from "constants/queryKey";

import useModal from "hooks/useModal";
import useModalContents from "hooks/useModalContents";

export default function AppPushDetail() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isModalOpen, handleToggleModal] = useModal();
  const [modalContents, setModalContents] = useModalContents();

  const { data, isLoading } = useQuery([queryKey.GET_PUSH_DETAIL], () => getPushDetail(Number(id)), {
    onSuccess: (res) => {
      if (res.status !== 200) {
        handleToggleModal();
      }

      if (res.data.pushMessageDetail.status === "RESERVATION") {
        setModalContents({
          title: "발송 예약을 취소하시겠습니까?",
          description: `취소 시 등록 내용을 복구할 수 없습니다.`,
          btnType: "submit",
          confirmBtnName: "예",
          handleSubmit: handleDelete,
          handleClose: handleToggleModal,
        });
      }
      if (res.data.pushMessageDetail.status === "SUCCESS") {
        setModalContents({
          title: "다운로드 가능한 데이터가 없습니다",
          description: `확인이 필요한 경우 담당자에게 문의해 주세요`,
          btnType: "confirm",
          handleClose: handleToggleModal,
        });
      }
    },
  });

  const isReservation = data?.data.pushMessageDetail.status === "RESERVATION";

  const deletePushMuta = useMutation(() => deletePush(Number(id)), {
    onSuccess: (res) => {
      if (res.status !== 200) {
        setModalContents({
          title: "예약 취소 불가",
          description: `예약 상태가 아니거나 존재하지 않는 푸시입니다.\n확인이 필요한 경우 담당자에게 문의해 주세요`,
          btnType: "confirm",
          handleClose: handleToggleModal,
        });
      } else {
        goToList();
      }
    },
  });

  const goToList = () => {
    navigate(-1);
  };

  const handleDelete = () => {
    deletePushMuta.mutate();
  };

  const changeModalContents = () => {
    setModalContents({
      title: "유효하지 않은 이미지입니다.",
      description: `확인이 필요한 경우 담당자에게 문의해 주세요`,
      btnType: "confirm",
      handleClose: handleToggleModal,
    });
  };

  if (!data || isLoading) return null;
  return (
    <>
      <Content isWithFooter>
        <Title text="상세 정보" />
        <Info data={data} />
        <Message data={data} changeModalContents={changeModalContents} onToggleModal={handleToggleModal} />
        <CurrStatus data={data} onToggleModal={handleToggleModal} />
      </Content>

      <Footer>
        {isReservation && (
          <Button onClick={handleToggleModal} className={styles.cancel} type="button" mode="outlined" size="long">
            예약 취소
          </Button>
        )}
        <Button onClick={goToList} className={styles.confirm} type="button" size="long">
          확인
        </Button>
      </Footer>

      {isModalOpen && <Modal {...modalContents} />}
    </>
  );
}
