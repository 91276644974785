export const TYPE_LIST = ["마이디 투표"];

export const CATEGORY_DATA_LIST = [
  // "전체",
  "IT/테크",
  "건강",
  "금융",
  "뷰티/패션",
  "사랑",
  "소비/앱테크",
  "이벤트",
  "이슈",
  "직장",
  "취미",
  "기타",
];

export const CATEGORY: { [key: number]: string } = {
  1: "전체",
  2: "IT/테크",
  3: "건강",
  4: "금융",
  5: "뷰티/패션",
  6: "사랑",
  7: "소비/앱테크",
  8: "이벤트",
  9: "이슈",
  10: "직장",
  11: "취미",
  99: "기타",
};

export const CATEGORY_DATA_MAP: { [key: string]: string } = {
  전체: "POLL_CATEGORY_ALL",
  "IT/테크": "POLL_CATEGORY_IT_TECH",
  건강: "POLL_CATEGORY_HEALTH",
  금융: "POLL_CATEGORY_FINANCE",
  "뷰티/패션": "POLL_CATEGORY_BEAUTY_FASHION",
  사랑: "POLL_CATEGORY_LOVE",
  "소비/앱테크": "POLL_CATEGORY_SPEND_APPTECH",
  이벤트: "POLL_CATEGORY_EVENT",
  이슈: "POLL_CATEGORY_ISSUE",
  직장: "POLL_CATEGORY_JOB",
  취미: "POLL_CATEGORY_HOBBY",
  기타: "POLL_CATEGORY_ETC",
};

export const QUE_CHOICE_MAP: { [key: string]: string } = {
  "단일 선택형": "1",
  "복수 선택형": "2",
  "정답 단일 선택형": "3",
  "정답 복수 선택형": "4",
  "주관식 단답형": "5",
};

export const QUE_TYPE_LIST = [
  {
    label: "단일 문항",
    value: "single",
  },
  {
    label: "다문항",
    value: "multi",
  },
];

export const TAB_ARR = [
  { idx: 0, title: "콘텐츠 관리" },
  { idx: 1, title: "신고 관리" },
];

export const MANAGE_CONTENTS = [
  {
    label: "전체",
    status: "all",
  },
  {
    label: "진행중",
    status: "inProgress",
  },
  {
    label: "예정",
    status: "notStarted",
  },
  {
    label: "종료",
    status: "ended",
  },
];

export const MANAGE_REPORTS = [
  {
    label: "전체",
    status: "all",
  },
  {
    label: "대기",
    status: "pending",
  },
  {
    label: "삭제완료",
    status: "approved",
  },
  {
    label: "삭제 미승인",
    status: "nonApproved",
  },
];

export const CONTENTS_DATA = [
  {
    id: 1,
    status: 1,
    category: "건강",
    title: "피자 vs 치킨",
    notBefore: "2023-03-01T00:00+09:00",
    notAfter: "2023-03-17T16:00+09:00",
    createDate: "2023-03-02T00:00+09:00",
    target: { createDate: "2023-03-02T00:00+09:00" },
    approved: true,
    totalCount: 15,
    totalPage: 3,
    point: 100,
    targetNum: 1000,
    contentsURL: "URL",
    respondent: 560,
  },
  {
    id: 2,
    status: 2,
    category: "IT",
    title: "마이디에 바라는 점은?",
    notBefore: "2023-05-05T00:00+09:00",
    notAfter: "2023-06-30T16:00+09:00",
    createDate: "2023-03-02T00:00+09:00",
    target: { createDate: "2023-03-03T00:00+09:00" },
    approved: true,
    totalCount: 5,
    totalPage: 1,
    point: 1000,
    targetNum: 2500,
    contentsURL: "URL",
    respondent: 126,
  },
  {
    id: 3,
    status: 3,
    category: "여가",
    title: "주말에 뭐해요?",
    notBefore: "2023-07-01T00:00+09:00",
    notAfter: "2023-07-17T16:00+09:00",
    createDate: "2023-03-02T00:00+09:00",
    target: { createDate: "2023-03-04T00:00+09:00" },
    approved: true,
    totalCount: 12,
    totalPage: 4,
    point: 1200,
    targetNum: 500,
    contentsURL: "URL",
    respondent: 0,
  },
  {
    id: 4,
    status: 1,
    category: "이슈",
    title: "요즘 관심있는 이슈는?",
    notBefore: "2023-07-18T00:00+09:00",
    notAfter: "2023-07-24T16:00+09:00",
    createDate: "2023-03-02T00:00+09:00",
    target: { createDate: "2023-03-05T00:00+09:00" },
    approved: true,
    totalCount: 12,
    totalPage: 4,
    point: 1200,
    targetNum: 500,
    contentsURL: "URL",
    respondent: 120,
  },
  {
    id: 5,
    status: 2,
    category: "취미",
    title: "나에게 취미란?",
    notBefore: "2023-07-30T00:00+09:00",
    notAfter: "2023-08-04T16:00+09:00",
    createDate: "2023-03-02T00:00+09:00",
    target: { createDate: "2023-03-06T00:00+09:00" },
    approved: true,
    totalCount: 12,
    totalPage: 4,
    point: 1200,
    targetNum: 1000,
    contentsURL: "URL",
    respondent: 800,
  },
  {
    id: 6,
    status: 3,
    category: "뷰티/패션",
    title: "내가 좋아하는 스타일은?",
    notBefore: "2023-08-05T00:00+09:00",
    notAfter: "2023-08-10T16:00+09:00",
    createDate: "2023-03-02T00:00+09:00",
    target: { createDate: "2023-03-07T00:00+09:00" },
    approved: true,
    totalCount: 12,
    totalPage: 4,
    point: 1200,
    targetNum: 500,
    contentsURL: "URL",
    respondent: 0,
  },
  {
    id: 7,
    status: 1,
    category: "사랑",
    title: "지금 보고싶은 사람은?",
    notBefore: "2023-08-12T00:00+09:00",
    notAfter: "2023-08-14T16:00+09:00",
    createDate: "2023-03-02T00:00+09:00",
    target: { createDate: "2023-03-08T00:00+09:00" },
    approved: true,
    totalCount: 12,
    totalPage: 4,
    point: 1200,
    targetNum: 1200,
    contentsURL: "URL",
    respondent: 1000,
  },
  {
    id: 8,
    status: 1,
    category: "소비/앱테크",
    title: "내가 사용하는 앱테크는 어플은?",
    notBefore: "2023-08-15T00:00+09:00",
    notAfter: "2023-08-30T16:00+09:00",
    createDate: "2023-03-02T00:00+09:00",
    target: { createDate: "2023-03-09T00:00+09:00" },
    approved: true,
    totalCount: 12,
    totalPage: 4,
    point: 1200,
    targetNum: 2000,
    contentsURL: "URL",
    respondent: 800,
  },
];

export const REPORT_DATA = [
  {
    id: 1,
    status: 4,
    notBefore: "2023-07-01T00:00+12:00",
    notAfter: "2023-07-17T16:00+09:00",
    createDate: "2023.01.02 00:12:00",
    title: "[이벤트] 친구추천하고 마이디 포인트 받자!",
    content:
      "신고관리 댓글 내용입니다. 20자까지만 보입니다. 호버시에 전문이 보입니다! 신고관리 댓글 내용입니다. 20자까지만 보입니다. 호버시에 전문이 보입니다.  ",
    target: { createDate: "2023-03-02T00:00+09:00" },
    approved: true,
  },
  {
    id: 2,
    status: 5,
    notBefore: "2023-04-05T00:00+14:00",
    notAfter: "2023-07-17T16:00+09:00",
    createDate: "2023.01.02 00:12:00",
    title: "같은 메시지 연속으로 보내는 도배 행위",
    content: "신고관리 댓글 내용입니다. 20자까지만 보입니다. 호버시에 전문이 보입니다.",
    target: { createDate: "2023-03-03T00:00+09:00" },
    approved: true,
  },
  {
    id: 3,
    status: 5,
    notBefore: "2023-03-01T00:00+18:00",
    notAfter: "2023-07-17T16:00+09:00",
    createDate: "2023.01.02 00:12:00",
    title: "욕설 및 타인에게 불편함을 주는 비방적 메시지",
    content: "신고관리 댓글 내용입니다. 20자까지만 보입니다. 호버시에 전문이 보입니다.",
    target: { createDate: "2023-03-04T00:00+09:00" },
    approved: true,
  },
];
