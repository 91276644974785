import { useWatch } from "react-hook-form";
import { useRecoilValue } from "recoil";

import MobileView from "./MobileView";

import { contPrFormAtom } from "recoil/vote";
import { CheckCirDisIcon, RadioDisIcon, RadioEmtIcon } from "assets/svgs/vote";
import { TQuestionForm } from "types/contentsManage/vote";
import styles from "styles/pages/contentsManage/vote/write/mobile/mobileViewQue.module.scss";

export default function MobileViewQue() {
  const { title, perPersonPnt, content, filter, imgFileString, startDate, endDate } = useRecoilValue(contPrFormAtom);
  const [answerList, descriptive, queType, question, correctAns] = useWatch({
    name: ["answerList", "descriptive", "queType", "question", "correct_ans"],
  });

  return (
    <MobileView
      data={{
        title,
        content,
        imgFileString,
        startDate,
        endDate,
        filter,
        perPersonPnt,
      }}
    >
      <ul className={styles.que_box}>
        <div className={styles.question}>
          Q1. {question || "질문을 입력해 주세요."}
          <span>
            {queType === "정답 복수 선택형" && correctAns?.match(/,/g)?.length
              ? `정답개수 ${correctAns.match(/,/g).length + 1}개`
              : ""}
          </span>
        </div>
        <li>
          {queType === "주관식 단답형" && (
            <div className={styles.descriptive}>{descriptive || `답변을 작성해 주세요`}</div>
          )}

          {queType !== "주관식 단답형" && (
            <ul className={styles.answer_box}>
              {(answerList as TQuestionForm["answerList"]).map((item, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`${item.value}-${index}`} className={styles.answer}>
                    {queType === "단일 선택형" && <RadioDisIcon />}
                    {queType === "복수 선택형" && <CheckCirDisIcon />}
                    {queType === "정답 단일 선택형" && <RadioEmtIcon />}
                    {queType === "정답 복수 선택형" && <CheckCirDisIcon />}

                    <span>{item.value || `선택지 ${index + 1} 입력해 주세요.`}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </li>
      </ul>
    </MobileView>
  );
}
