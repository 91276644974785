import React, { ReactNode } from "react";
import styles from "styles/pages/company/list/companyTable.module.scss";

type TProps = {
  columns: {
    text: string;
    width?: string;
  }[];
  rows: ReactNode[][];
};

export default function CompanyTable({ columns, rows }: TProps) {
  return (
    <table className={styles.wrapper}>
      <thead>
        <tr>
          {columns.map((column, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <th key={`${column.text}-${idx}`} style={{ width: column.width }}>
              {column.text}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, rIdx) => {
          const key = `${row[0]}-${rIdx}`;

          const tdList = row.map((cell, cellIdx) => {
            const key2 = `${key}-${cellIdx}`;
            return <td key={key2}>{cell}</td>;
          });

          const target = row[row.length - 1];
          let disabled = 0;

          if (React.isValidElement(target)) {
            if (target.props.children === "활성화") {
              disabled = 1;
            }
          }

          return (
            <tr key={key} className={disabled ? styles.disabled : ""}>
              {tdList}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
