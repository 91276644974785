import { FilterData, useCustomParams } from "comp-lib";
import dayjs from "dayjs";
import { marketStatusMap } from "constants/transaction";
import { TTransactionItem } from "types/transaction";

export default function useFilterData(data: TTransactionItem[]) {
  // 상태 재정의
  const result: TTransactionItem[] = data.map((item) => {
    return {
      ...item,
      status: overrideStatus({ status: item.status, startDate: item.notBefore, revokedAt: item.revokedAt }),
    };
  });

  const [{ from, to, keyword, company, status, pageNum }] = useCustomParams({
    from: "",
    to: "",
    keyword: "",
    company: "",
    status: "all",
    pageNum: "1",
  });

  const dataForTableNav = new FilterData(result)
    .sortDesc("createdAt")
    .filterByDate("notBefore", from, to)
    .filterByKeyword("content.description.company.name", company)
    .filterByKeyword("title", keyword)
    .getResult();

  const paramStatus = status === "all" ? "" : status;
  const filteredData = new FilterData(result)
    .sortDesc("createdAt")
    .filterByDate("notBefore", from, to)
    .filterByKeyword("title", keyword)
    .filterByKeyword("content.description.company.name", company)
    .filterByStatus("status", paramStatus, marketStatusMap)
    .getResult();

  const len = filteredData.length;
  const dataWithNo = filteredData.map((item, idx) => ({
    ...item,
    no: len - idx,
  }));

  const dataForTableRows = new FilterData(dataWithNo).filterByPage(Number(pageNum)).getResult();
  const totalPages = Math.floor(filteredData.length / 10) + (filteredData.length % 10 && 1) || 1;

  return { dataForTableNav, dataForTableRows, totalPages };
}

type TParam = {
  status: number;
  startDate: string;
  revokedAt: string;
};
export const isProgressNow = (start: dayjs.Dayjs) => {
  const nowDate = dayjs();
  return nowDate.isSameOrAfter(start);
};

/**
 * 0: ["진행중", "inProgress"]
 * 1: ["예정", "notStarted"]
 * 2: ["철회", "revoked"]
 * 3: ["종료", "ended"]
 * 4: ["종료", "ended"]
 */
function overrideStatus({ status, startDate, revokedAt }: TParam) {
  if (status === 0) {
    return isProgressNow(dayjs(startDate)) ? 0 : 1;
  }

  if (revokedAt && status === 2) {
    return 2;
  }

  return status;
}
