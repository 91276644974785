import { Content, Panel, Title, TableNav, Table, Pagination, ErrorModal, LoadingModal } from "comp-lib";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import useFilterData from "./useFilterData";
import useTableNavData from "./useTableNavData";
import useTableData from "./useTableData";
import { getTransList } from "api/transaction";
import styles from "styles/pages/transaction/transactionList.module.scss";
import SearchBox from "./@tmp/SearchBox";

export default function TransactionList() {
  const { data = [], isLoading, isFetching, isError, error } = useQuery(["getTransList"], getTransList);
  const navigate = useNavigate();

  const { dataForTableNav, dataForTableRows, totalPages } = useFilterData(data);
  const tableNavData = useTableNavData(dataForTableNav);
  const { columns, rows } = useTableData(dataForTableRows);

  const handleError = () => {
    navigate("/");
  };

  return (
    <Content>
      <Title text="데이터 거래" />

      <Panel>
        <div className={styles.header}>
          <TableNav dataList={tableNavData} />
          <SearchBox />
        </div>

        <Table columns={columns} rows={rows} />

        <div className={styles.footer} />

        <Pagination totalPages={totalPages} />
      </Panel>

      {(isLoading || isFetching) && <LoadingModal />}

      {isError && (
        <ErrorModal
          message={`데이터 거래 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`}
          onConfirmHandler={handleError}
        />
      )}
    </Content>
  );
}
