import { registImg, uploadImg, uploadImgs } from "api/common";

import { useMutation } from "react-query";

export default function useImgUpload() {
  const { mutateAsync: uploadImgMuta, error: imgError } = useMutation(({ file }: { file: File }) =>
    uploadImg({ file }),
  );

  const { mutateAsync: uploadImgsMuta, error: imgsError } = useMutation(({ files }: { files: FormData }) =>
    uploadImgs({ files }),
  );

  const { mutateAsync: registerImgMuta, error: registerError } = useMutation(({ fileId }: { fileId: string }) =>
    registImg({ fileId }),
  );

  return {
    uploadImgMuta,
    uploadImgsMuta,
    registerImgMuta,
    imgError,
    imgsError,
    registerError,
  };
}
