import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import QuestionItems from "./QuestionItems";
import CustomDropdown from "pages/contentsManage/vote/@shared/form/CustomDropdown";

import { AddIcon, MoveUpIcon, MoveDownIcon, DivPageIcon, TrashIcon } from "assets/svgs/vote";
import styles from "styles/pages/contentsManage/vote/write/multiQuestion.module.scss";
import { QUE_CHOICE_MAP } from "constants/vote";
import ConfirmModal from "../@shared/modal/ConfirmModal";
import { useState } from "react";
import NotiModal from "../@shared/modal/NotiModal";
import { getCommasInNum } from "utils";
import { TPollMultiQuestion } from "types/contentsManage/vote";

type TProps = {
  pageIndex: number;
  fieldLength: number;
  onAddPage: () => void;
  onRemovePage: () => void;
  isSelectAnswerType: boolean;
  handleIsSelectAnswerType: (type?: boolean, isReset?: boolean) => void;
  perAmount: number;
};

type TContent = {
  title: string;
  queType: string;
  answerList: [{ value: string }, { value: string }, { value: string }, { value: string }];
  descriptive: string;
  correct_ans: string;
};

export type TQuesstion = {
  contents: TContent[];
  pageId: "";
};

export default function MultiQuestion({
  pageIndex,
  fieldLength,
  onAddPage,
  onRemovePage,
  isSelectAnswerType,
  handleIsSelectAnswerType,
  perAmount,
}: TProps) {
  const {
    control,
    register,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [confirmState, setConfirmState] = useState({ isOpen: false, subMessage: "", onConfirm: () => {} });
  const [warnModalMsg, setWarnModalMsg] = useState("");
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: `question.${pageIndex}.contents`,
  });

  const handleAppend = () => {
    return fields.length < 100
      ? append({
          title: "",
          queType: isSelectAnswerType ? "정답 단일 선택형" : "단일 선택형",
          answerList: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
          descriptive: "",
          correct_ans: "",
        })
      : setWarnModalMsg("100개를 초과하여 문항을 만들 수 없습니다.");
  };

  const handleRemove = (index: number) => {
    if (fieldLength <= 1 && fields.length <= 1) {
      return;
    }

    remove(index);

    if (fields.length <= 1) {
      onRemovePage();
    }
  };

  const handleIsAnswerType = (type: string, onChange: Function) => {
    const isTargetAnswerType = /정답 (단일|복수) 선택형/.test(type);
    if (fieldLength + fields.length > 2 && isSelectAnswerType !== isTargetAnswerType) {
      setConfirmState({
        isOpen: true,
        subMessage: isSelectAnswerType
          ? "정답 선택형 투표는 모두 초기화 됩니다."
          : "정답 선택형이 아닌 투표는 모두 초기화 됩니다.",

        onConfirm: () => {
          onChange(type);
          handleIsSelectAnswerType(isTargetAnswerType, true);
          setConfirmState({ isOpen: false, subMessage: "", onConfirm: () => {} });
        },
      });
      return;
    }
    onChange(type);
    handleIsSelectAnswerType(isTargetAnswerType);
  };

  return (
    <>
      {fields.map((item, index) => {
        const name = `question.${pageIndex}.contents`;
        const QuestionNumber = (getValues("question") as TPollMultiQuestion)
          .map((v) => v.contents.length)
          .reduce((prev, curr, idx) => prev + (pageIndex > idx ? curr : 0), index + 1);
        return (
          <div className={styles.content} key={item.id}>
            <div className={styles.title_box}>
              <div className={styles.sub_title}>Q{QuestionNumber}</div>
              {/정답 (단일|복수) 선택형/.test(watch(`${name}.${index}.queType`)) && (
                <div className={styles.que_point}>
                  <span>포인트 금액 책정</span>
                  <input
                    type="number"
                    placeholder="해당 질문에 할당할 포인트를 입력해 주세요"
                    onKeyDown={(e) => ["-", "+", "e", "."].includes(e.key) && e.preventDefault()}
                    onWheel={({ target }) => (target as HTMLElement).blur()}
                    {...register(`${name}.${index}.point`)}
                  />
                  <p> {`/ ${perAmount ? getCommasInNum(perAmount) : 0} P`}</p>
                </div>
              )}
            </div>

            <div className={styles.que}>
              <input
                type="text"
                placeholder="질문을 입력해 주세요 (최대 100자)"
                maxLength={100}
                className={styles.que_input}
                aria-invalid={getError(errors, `${name}.${index}.title`) ? "true" : "false"}
                {...register(`${name}.${index}.title`, {
                  validate: { required: (v) => !!v.trim().length },
                })}
              />

              <Controller
                name={`${name}.${index}.queType`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CustomDropdown
                    dataList={Object.keys(QUE_CHOICE_MAP)}
                    value={value}
                    size="small"
                    onChange={(e) => handleIsAnswerType(e, onChange)}
                  />
                )}
              />
            </div>

            {watch(`${name}.${index}.queType`) === "주관식 단답형" && (
              <>
                <textarea
                  className={styles.descriptive}
                  rows={3}
                  placeholder={`답변창에 표시될 가이드를 작성해 주세요 (최대 50자)\n미작성 시 “답변을 입력해 주세요 (글자수 최대 20자 이하)”가 보여집니다`}
                  aria-invalid={getError(errors, `${name}.${index}.descriptive`) ? "true" : "false"}
                  maxLength={50}
                  {...register(`${name}.${index}.descriptive`, {
                    maxLength: {
                      value: 50,
                      message: "최대 50자까지 입력해주세요.",
                    },
                  })}
                />
                {getError(errors, `${name}.${index}.descriptive`)?.type === "maxLength" && (
                  <p className={styles.errorMeg}>{getError(errors, `${name}.${index}.descriptive`).message}</p>
                )}
              </>
            )}

            {watch(`${name}.${index}.queType`) !== "주관식 단답형" && (
              <QuestionItems
                queType={watch(`${name}.${index}.queType`)}
                name={`${name}.${index}.answerList`}
                queNum={`${name}.${index}`}
              />
            )}

            <hr />

            <ul className={styles.menu}>
              <li className={styles.menuBtn}>
                <button type="button" onClick={handleAppend}>
                  <AddIcon />
                </button>
                <p className={styles.arrowBox}>질문 추가</p>
              </li>
              <li className={styles.menuBtn}>
                <button
                  type="button"
                  onClick={() => {
                    if (index === 0) {
                      return;
                    }

                    swap(index - 1, index);
                  }}
                >
                  <MoveUpIcon />
                </button>
                <p className={styles.arrowBox}>위로 이동</p>
              </li>
              <li className={styles.menuBtn}>
                <button
                  type="button"
                  onClick={() => {
                    if (index + 1 === fields.length) {
                      return;
                    }

                    swap(index, index + 1);
                  }}
                >
                  <MoveDownIcon />
                </button>
                <p className={styles.arrowBox}>아래로 이동</p>
              </li>
              {index + 1 === fields.length && (
                <li className={styles.menuBtn}>
                  <button type="button" onClick={onAddPage}>
                    <DivPageIcon />
                  </button>
                  <p className={styles.arrowBox}>페이지 나누기</p>
                </li>
              )}
              <li className={styles.menuBtn}>
                <button type="button" onClick={() => handleRemove(index)}>
                  <TrashIcon />
                </button>
                <p className={styles.arrowBox}>삭제하기</p>
              </li>
            </ul>
          </div>
        );
      })}
      {confirmState.isOpen && (
        <ConfirmModal
          message={
            isSelectAnswerType
              ? "정답 선택형이 아닌 타입으로 변경하시겠습니까?"
              : "정답 선택형 타입으로 변경하시겠습니까?"
          }
          subMessage={confirmState.subMessage}
          onCancle={() => setConfirmState({ isOpen: false, subMessage: "", onConfirm: () => {} })}
          onConfirm={confirmState.onConfirm}
        />
      )}
      {warnModalMsg && <NotiModal message={warnModalMsg} onClose={() => setWarnModalMsg("")} />}
    </>
  );
}

function getError(errors: any, path: string) {
  return path.split(".").reduce((acc: any, key: string) => {
    if (acc && typeof acc === "object") {
      return acc[key];
    }
    return undefined;
  }, errors);
}
