import { useState } from "react";
import cx from "classnames";
import { getErrorMeg } from "api/errorHandling";
import { Portal, Button } from "comp-lib";
import styles from "./errorModal.module.scss";

type Props = {
  title?: string;
  error?: unknown;
  buttonText?: string;
  onConfirmHandler?: () => void;
  message?: string;
};

export default function ErrorModal({ title, error, buttonText = "확인", onConfirmHandler, message }: Props) {
  const [isClick, setIsClick] = useState(false);

  const errorMessage = error instanceof Error && error.message;

  const isUnknowError = errorMessage === getErrorMeg("default");

  const handleClickConfirm = () => {
    onConfirmHandler?.();
    setIsClick(true);
  };

  if (!onConfirmHandler && isClick) return null;

  return (
    <Portal>
      <div className={styles.errorModal}>
        <div className={styles.content}>
          <div>{isUnknowError && !message ? "일시적 오류발생" : title}</div>
          <p className={cx({ [styles.isTitle]: title })}>{message || errorMessage}</p>

          <Button type="button" name="confirm" onClick={handleClickConfirm}>
            {buttonText}
          </Button>
        </div>
      </div>
    </Portal>
  );
}
