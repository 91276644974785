/* eslint-disable jsx-a11y/label-has-associated-control */
import { Controller, useFormContext, useWatch } from "react-hook-form";
import DateBox from "./DateBox";
import NumInput from "pages/contentsManage/vote/@shared/form/NumInput";

import { TProgress } from "types/contentsManage/vote";
import styles from "styles/pages/contentsManage/vote/write/progress.module.scss";

const addComma = (value: string | number) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export default function Progress() {
  const {
    control,
    formState: { errors },
  } = useFormContext<TProgress>();

  const [perPersonPnt, goalPeopleCnt] = useWatch({
    name: ["perPersonPnt", "goalPeopleCnt"],
  });

  const balanceBefore = 5000000;
  const point = Number(perPersonPnt) * Number(goalPeopleCnt);

  return (
    <div className={styles.wrapper}>
      <div className={styles.label} aria-invalid={errors.startDate || errors.endDate ? "true" : "false"}>
        <div className={styles.title}>
          진행 기간 <sup>*</sup>
        </div>

        <DateBox />
      </div>

      <label aria-invalid={errors.perPersonPnt ? "true" : "false"}>
        <div className={styles.title}>
          1인당 지급 포인트 <sup>*</sup>
        </div>

        <Controller
          name="perPersonPnt"
          control={control}
          render={({ field: { value, onChange } }) => (
            <NumInput value={value ? value.toString() : ""} placeholder="0" onChange={onChange} maxLength={7} />
          )}
          rules={{
            validate: (value) =>
              (!!Number(value) && value < 1000000) || "지급 포인트가 올바르지 않습니다.\n지급 포인트를 확인해 주세요",
            required: "1인당 지급 포인트를 입력해주세요",
          }}
        />
        <span className={styles.unit}>P</span>
      </label>

      <label aria-invalid={errors.goalPeopleCnt ? "true" : "false"}>
        <div className={styles.title}>
          목표 인원 <sup>*</sup>
        </div>

        <Controller
          name="goalPeopleCnt"
          control={control}
          render={({ field: { value, onChange } }) => (
            <NumInput value={value ? value.toString() : ""} placeholder="0" onChange={onChange} maxLength={6} />
          )}
          rules={{
            validate: (value) =>
              (value !== 0 && value < 100000) || "목표 인원이 올바르지 않습니다\n목표 인원을 확인해 주세요",
            required: "목표 인원을 입력해주세요",
          }}
        />
        <span className={styles.unit}>명</span>
      </label>

      <div className={styles.label}>
        <div className={styles.title}>총 사용 예정 포인트</div>

        <div className={styles.point}>{addComma(point)} P</div>
      </div>
    </div>
  );
}
