import { useEffect } from "react";
import styles from "styles/common/modal/Modal.module.scss";
import { Loading, Portal } from "../other";

/**
 * LoadingModal - query or mutation 상태가 pending 일 경우 사용합니다. (그 외 사용 가능)
 *
 * @component
 * @example
 * if (isLoading) {
 *   return <LoadingModal />;
 * }
 */
export default function LoadingModal() {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <Portal>
      <article className={styles.overlay}>
        <Loading />
      </article>
    </Portal>
  );
}
