import cx from "classnames";

import { LeftArrowIcon } from "assets/svgs/icon";
import { TrashIcon } from "assets/svgs/vote";
import { TBanner } from "types/banner";
import styles from "styles/pages/banner/tableData.module.scss";
import { PURPOSE_MAP } from "constants/banner";

export default function useBannerTableData(
  data: TBanner[],
  onOpenEditBanner: (banner: TBanner) => void,
  onMoveBanner: (id: string, bannerList: TBanner[], direction: "up" | "down") => void,
  onRemoveBanner: (id: string) => void,
) {
  const columns = [
    { text: "순서", width: "62px" },
    { text: "구분", width: "80px" },
    { text: "배너", width: "286px" },
    { text: "배너명" },
    { text: "디바이스", width: "100px" },
    { text: "노출 기간", width: "200px" },
    { text: "이동", width: "120px" },
    { text: "", width: "80px" },
  ];

  const newData = getDataWithNo(data);

  const rows = newData.map((item) => {
    const typeTag = (() => {
      if (item.type === "2") {
        return "거래";
      }

      if (item.type === "3") {
        const purpose = item.purpose ? Number(item.purpose) : 0;
        return PURPOSE_MAP[purpose] || "기타";
      }
      return "일반";
    })();

    const imgTag = (() => {
      if (!item.imgFileStr) {
        return <div className={styles.img_box}>-</div>;
      }

      return (
        <div className={styles.img_box}>
          <img key={`img-${item.id}`} src={item.imgFileStr} alt="banner" />
        </div>
      );
    })();

    const dateTag = (() => {
      if (!item.startDateTime || !item.endDateTime) {
        return "-";
      }

      return `${item.startDateTime.split("T")[0]} ~ ${item.endDateTime.split("T")[0]}`;
    })();

    return [
      item.no,
      typeTag,
      imgTag,
      <button
        type="button"
        key={`title-${item.id}`}
        className={styles.btn_title}
        onClick={() => onOpenEditBanner(item)}
      >
        {item.title}
      </button>,
      getDevice(item.device),
      dateTag,
      <div key={`move-${item.id}`} className={styles.move_box}>
        <button type="button" onClick={() => onMoveBanner(item.id, data, "up")}>
          <LeftArrowIcon className={cx(styles.arrow, styles.up)} />
        </button>
        <button type="button" onClick={() => onMoveBanner(item.id, data, "down")}>
          <LeftArrowIcon className={cx(styles.arrow, styles.down)} />
        </button>
      </div>,
      <button key={`del-${item.id}`} type="button" onClick={() => onRemoveBanner(item.id)}>
        <TrashIcon className={styles.trash} />
      </button>,
    ];
  });

  return { columns, rows };
}

function getDataWithNo<T extends any[]>(dataList: T): (T extends (infer U)[] ? U & { no: number } : never)[] {
  return dataList.map((item, idx) => ({
    ...item,
    no: idx + 1,
  }));
}

function getDevice(num: string) {
  if (num === "2") {
    return "Android";
  }

  if (num === "3") {
    return "iOS";
  }

  return "ALL";
}
