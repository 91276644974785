import { ChangeEventHandler } from "react";
import { Controller, useFormContext } from "react-hook-form";

import styles from "styles/pages/appPush/write/setSendInfo.module.scss";

import dayjs from "dayjs";

import RequiredMark from "./RequiredMark";
import RadioBoxAppPush from "./RadioBoxAppPush";

import {
  DID_TARGET,
  AGREE_TYPE,
  OS_TARGET,
  // POINT_TARGET,
  PROPOSAL_TARGET,
  PUSH_DATE,
  PUSH_PURPOSE,
  TARGET_TYPE,
  MSG_TYPE,
} from "constants/appPush";
import { TPushFormValue } from "types/appPush";
import RegisterTxtFile from "./RegisterTxtFile";
import Input from "./Input";

function SetSendInfo() {
  const { control, watch, setValue, resetField } = useFormContext<TPushFormValue>();

  const isReserved = watch("reserveTime") === "RESERVATION";

  const handleDateChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue("date", e.currentTarget.value);

    resetField("hour");
    resetField("minute");
  };

  const resetInput = () => {
    resetField("targetInput");
    resetField("targetValue");
    resetField("messagePlatformStatus");
    setValue("jsonOrigin", ""); // 발송대상 값 변경 시 새로운 JSON OS 가져오기 위함
  };

  const handleHour: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setValue("hour", e.target.value);
  };

  const handleMinute: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setValue("minute", e.target.value);
  };

  return (
    <section className={styles.config}>
      <h2>푸시 발송 설정</h2>

      <Controller
        name="messagePurpose"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <div className={styles.multiCategoryBox}>
            <RequiredMark className={styles.category}>발송 목적</RequiredMark>
            <div className={styles.subCategoryBoxContainer}>
              <RadioBoxAppPush
                resetInput={() => resetField("agreeType")}
                name="messagePurpose"
                dataList={PUSH_PURPOSE}
                value={value}
                onChange={onChange}
              />

              {watch().messagePurpose === "MARKETING_ADVERTISE" && (
                <>
                  <div className={styles.line} />
                  <Controller
                    name="agreeType"
                    control={control}
                    rules={{ required: true }}
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    render={({ field: { value, onChange } }) => (
                      <div className={styles.subCategoryBox}>
                        <RadioBoxAppPush
                          name="agreeType"
                          dataList={AGREE_TYPE.slice(0, 2)}
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    )}
                  />
                </>
              )}
            </div>
          </div>
        )}
      />

      <Controller
        name="messageType"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <div className={styles.categoryBox}>
            <RequiredMark className={styles.category}>발송 방식</RequiredMark>
            <RadioBoxAppPush
              name="messageType"
              dataList={MSG_TYPE}
              value={value}
              onChange={onChange}
              resetInput={resetInput}
            />
          </div>
        )}
      />

      {watch().messageType === "TOKEN" && (
        <Controller
          name="targetType"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <div className={styles.multiCategoryBox}>
              <RequiredMark className={styles.category}>발송 대상</RequiredMark>
              <div className={styles.subCategoryBoxContainer}>
                <RadioBoxAppPush
                  name="targetType"
                  dataList={TARGET_TYPE}
                  value={value}
                  onChange={onChange}
                  resetInput={resetInput}
                />
                <div className={styles.line} />

                {watch().targetType === "OS" && (
                  <Controller
                    name="messagePlatformStatus"
                    control={control}
                    rules={{ required: true }}
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    render={({ field: { value, onChange } }) => (
                      <div className={styles.subCategoryBox}>
                        <RadioBoxAppPush
                          name="messagePlatformStatus"
                          dataList={OS_TARGET}
                          value={value}
                          onChange={onChange}
                          resetInput={resetInput}
                        />
                      </div>
                    )}
                  />
                )}

                {watch().targetType === "PROPOSAL" && (
                  <Controller
                    name="proposalId"
                    control={control}
                    rules={{ required: true }}
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    render={({ field: { value, onChange } }) => (
                      <div className={styles.subCategoryBox}>
                        <RadioBoxAppPush
                          name="proposalId"
                          dataList={PROPOSAL_TARGET}
                          value={value}
                          onChange={onChange}
                          resetInput={resetInput}
                        />
                        <Controller
                          name="targetInput"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value: inputValue, onChange: onChangeInput } }) => (
                            <Input
                              placeholder="입력한 Proposal ID에 참여한 사용자에게 푸시를 발송합니다."
                              value={watch().targetType === "PROPOSAL" ? inputValue! : ""}
                              disabled={watch().proposalId !== "PROPOSAL_ID"}
                              width="490px"
                              onChange={onChangeInput}
                            />
                          )}
                        />
                      </div>
                    )}
                  />
                )}

                {watch().targetType === "DID" && (
                  <Controller
                    name="targetValue"
                    control={control}
                    rules={{ required: true }}
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    render={({ field: { value, onChange } }) => (
                      <div className={styles.subCategoryBox_did}>
                        <div className={styles.columnRadioBox}>
                          <div className={styles.itemBox}>
                            <div className={styles.radio}>
                              <RadioBoxAppPush
                                name="targetValue"
                                dataList={DID_TARGET.slice(0, 1)}
                                value={value}
                                onChange={onChange}
                                resetInput={resetInput}
                              />
                            </div>
                            <Controller
                              name="targetInput"
                              control={control}
                              rules={{ required: true }}
                              render={({ field: { onChange: onChangeInput } }) => (
                                <Input
                                  placeholder="DID가 여러 개인 경우 쉼표(,) 또는 공백으로 구분해 주세요. (3개 이상일 경우 파일 첨부 권장)"
                                  value={watch().targetValue === "DID" ? watch().targetInput! : ""}
                                  disabled={watch().targetValue !== "DID"}
                                  width="490px"
                                  onChange={onChangeInput}
                                />
                              )}
                            />
                          </div>

                          <div className={styles.itemBox}>
                            <div className={styles.radio}>
                              <RadioBoxAppPush
                                name="targetValue"
                                dataList={DID_TARGET.slice(1, 2)}
                                value={value}
                                onChange={onChange}
                                resetInput={resetInput}
                              />
                            </div>
                            <Controller
                              name="targetInput"
                              control={control}
                              rules={{ required: true }}
                              render={({ field: { onChange: onChangeInput } }) => (
                                <RegisterTxtFile
                                  onChange={onChangeInput}
                                  disabled={watch().targetValue !== "DID_FILE"}
                                />
                              )}
                            />
                          </div>

                          {/* TODO: 마이디 개편 후 사용 예정 */}
                          {/* <div className={styles.itemBox}>
                            <div className={styles.radio}>
                              <RadioBoxAppPush
                                name="targetValue"
                                dataList={DID_TARGET.slice(2, 3)}
                                value={value}
                                onChange={onChange}
                                resetInput={resetInput}
                              />
                            </div>
                            <Controller
                              name="targetInput"
                              control={control}
                              rules={{ required: isRequired }}
                              render={({ field: { onChange: onChangeInput } }) => (
                                <select
                                  disabled={watch().targetValue !== "POINT_EXPIRATION"}
                                  name="targetInput"
                                  onChange={(e) => onChangeInput(e.currentTarget.value)}
                                >
                                  {POINT_TARGET.map((option) => {
                                    return <option key={option.label}>{option.label}</option>;
                                  })}
                                </select>
                              )}
                            />
                          </div>

                          <div className={styles.itemBox}>
                            <RadioBoxAppPush
                              name="targetValue"
                              dataList={DID_TARGET.slice(3, 4)}
                              value={value}
                              onChange={onChange}
                              resetInput={resetInput}
                            />
                          </div> */}
                        </div>
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
          )}
        />
      )}

      <Controller
        name="reserveTime"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <div className={styles.categoryBox}>
            <RequiredMark className={styles.category}>발송 일시</RequiredMark>
            <RadioBoxAppPush name="reserveTime" dataList={PUSH_DATE} value={value} onChange={onChange} />

            <input
              name="date"
              type="date"
              min={dayjs().format("YYYY-MM-DD")}
              value={isReserved ? watch().date : ""}
              onChange={handleDateChange}
              disabled={!isReserved}
            />

            <select name="hour" onChange={handleHour} className={styles.select} disabled={!isReserved}>
              {HOUR_MAPS.map((hour, idx) => (
                <option value={hour} key={idx}>
                  {hour}
                </option>
              ))}
            </select>
            <span className={styles.time}>시</span>

            <select name="minute" onChange={handleMinute} className={styles.select} disabled={!isReserved}>
              {MINUTE_MAPS.map((minute, idx) => (
                <option value={minute} key={idx}>
                  {minute}
                </option>
              ))}
            </select>
            <span className={styles.time}>분</span>
          </div>
        )}
      />
    </section>
  );
}

export default SetSendInfo;

const HOUR_MAPS = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const MINUTE_MAPS = ["00", "30"];
