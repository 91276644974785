import {
  BuildableResourceListReqeust,
  BuildableResourceListResponse,
  BuildableResourcePublishRequest,
  BuildableResourcePublishResponse,
  BuildableResourceSaveRequest,
  BuildableResourceSaveResponse,
  BuildableResourceType,
  HomeBannerCard,
} from "protobuf/OpenApiServerV3";
import { fetchProtobuf, getAccessData } from "./baseFetch";
import { TBannerSavaParam } from "types/banner";
import { uploadApi } from "./common";
import { CommonStatusCode } from "protobuf/CommonStatusCode";

export const getBannerList = async () => {
  const request = BuildableResourceListReqeust.create({
    buildableResourceType: [BuildableResourceType.HOME_BANNER],
    pageNum: 0,
    pageSize: 500,
  });
  const protobuf = BuildableResourceListReqeust.encode(request).finish();

  const result = await fetchProtobuf({
    url: "/admin/buildable-resource/list",
    data: protobuf,
  });

  const res = BuildableResourceListResponse.decode(result);
  errorHandling(res.statusCode);

  return res.savedBuilableResource;
};

type TSaveBannerGroupParam = {
  data: TBannerSavaParam;
  isFinal: boolean;
};

// 그룹 생성, 그룹 수정, 최종 저장
export const saveBannerGroup = async ({ data, isFinal }: TSaveBannerGroupParam) => {
  const newHomBannerList = data.bannerList.map((item) => {
    const result: HomeBannerCard = {
      type: Number(item.type),
      supportedPlatforms: Number(item.device),
      title: item.title,
      titleVisible: item.isVisibleTitle === "true",
      bannerImageUrl: item.imgFileStr,
      landingPageUrl: item.url,
      requiresAccessTokenInGetMethod: item.isToken && item.isToken === "true",
      notBefore: item.startDateTime,
      notAfter: item.endDateTime,
      enabled: true,
      proposalId: item?.proposalId,
      purpose: isNaN(Number(item?.purpose)) ? undefined : Number(item.purpose),
    };

    return result;
  });

  const request = BuildableResourceSaveRequest.create({
    title: data.groupName,
    homeBanner: {
      cards: newHomBannerList,
    },
    isFinal, // 생성, 수정 구분자
    isHidden: false,
    resourceType: BuildableResourceType.HOME_BANNER,
    resourceNo: data?.resourceNo,
  });

  const protobuf = BuildableResourceSaveRequest.encode(request).finish();

  const result = await fetchProtobuf({
    url: "/admin/buildable-resource/save",
    data: protobuf,
  });

  const res = BuildableResourceSaveResponse.decode(result);
  errorHandling(res.statusCode);

  return res.savedBuilableResource;
};

export const publishBannerGroup = async (resourceNo: number) => {
  if (resourceNo < 0) {
    throw new Error("resourceNo가 잘못되었습니다.");
  }

  const request = BuildableResourcePublishRequest.create({
    resourceNo,
  });
  const protobuf = BuildableResourcePublishRequest.encode(request).finish();

  const result = await fetchProtobuf({
    url: "/admin/buildable-resource/publish",
    data: protobuf,
  });

  const res = BuildableResourcePublishResponse.decode(result);
  errorHandling(res.code);

  return res;
};

export const removeBannerGroup = async (resourceNo: number) => {
  if (resourceNo < 0) {
    throw new Error("배너 그룹 번호가 잘못되었습니다.");
  }

  const request = BuildableResourceSaveRequest.create({
    isHidden: true,
    resourceNo,
    resourceType: BuildableResourceType.HOME_BANNER,
  });
  const protobuf = BuildableResourceSaveRequest.encode(request).finish();

  const result = await fetchProtobuf({
    url: "/admin/buildable-resource/save",
    data: protobuf,
  });

  const res = BuildableResourceSaveResponse.decode(result);
  errorHandling(res.statusCode);

  return res.savedBuilableResource;
};

export const uploadImg = async (file: File) => {
  const { did, token } = await getAccessData();

  const formData = new FormData();
  formData.append("file", file);
  formData.append("did", did);

  return uploadApi(token, formData, `${window.API_URL}/b2b/advertise/images`);
};

const errorHandling = (status: number) => {
  if (status === CommonStatusCode.STATUS_SUCCESS) {
    return;
  }

  throw new Error(`CommonStatusCode ${status}`);
};
