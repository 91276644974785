import { Link, useLocation } from "react-router-dom";
import { StatusChip, useCustomParams } from "comp-lib";
import dayjs from "dayjs";
import { TRechargeItem } from "types/recharge";
import { RECHARGE_STATUS_MAP } from "constants/recharge";

export default function useTableData(data: TRechargeItem[], totalElements: number) {
  const location = useLocation();
  const [{ pageNum }] = useCustomParams({ pageNum: "1" });

  const columns = [
    { text: "NO", width: "62px" },
    { text: "구분", width: "100px" },
    { text: "회사" },
    { text: "이름", width: "200px" },
    { text: "요청 포인트", width: "150px" },
    { text: "요청 시간", width: "200px" },
    { text: "지급 시간", width: "200px" },
  ];

  const rows = data.map((item, idx) => {
    const statusText = RECHARGE_STATUS_MAP[item.status][0];

    const statusColor = ((status: number) => {
      switch (status) {
        case 0:
          return "gray";
        case 1:
          return "blue";
        case 2:
          return "red";
        default:
          return "gray";
      }
    })(item.status);

    const companyName = item?.staff?.company?.name ? item.staff.company.name : "";
    const name = item?.staff?.name || "";
    const createdAt = item.createdAt ? dayjs(item.createdAt).format("YYYY.MM.DD HH:mm:ss") : "";
    const statusChangedAt = item.statusChangedAt ? dayjs(item.statusChangedAt).format("YYYY.MM.DD HH:mm:ss") : "";

    return [
      totalElements - idx - (Number(pageNum) - 1) * 10,
      <StatusChip key={item.id} text={statusText} color={statusColor} />,
      companyName && (
        <Link key={`company-${item.id}`} to={`detail/${item.id}${location.search}`}>
          {companyName}
        </Link>
      ),
      name,
      `${item.requestPoint.toLocaleString()} P`,
      createdAt,
      statusChangedAt,
    ];
  });

  return { columns, rows };
}
