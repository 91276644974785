import axios from "axios";
import ErrorCode from "constants/errorcode";
import { MiddlewareError } from "utils/errorPackage";
import { failureHandling, getErrorMeg, successHandling } from "./errorHandling";
import { getToken, setToken } from "utils/access";

let isAuthErrorRetry = false;

export const Axios = axios.create({
  // timeout: 10000,
});

Axios.interceptors.request.use(
  async (config) => {
    const accessToken = await getToken();
    if (accessToken === null) {
      window.location.href = "/login?invalid-token";

      return config;
    }

    config.headers["X-Auth-Token"] = accessToken;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Axios.interceptors.response.use(
  (response) => {
    isAuthErrorRetry = false;
    return response;
  },
  (error) => {
    if (error.response?.status === 401 && !isAuthErrorRetry) {
      isAuthErrorRetry = true;
      setToken(null);
      return Axios(error.config);
    }
    isAuthErrorRetry = false;

    if (error.code === "ECONNABORTED") {
      alert(getErrorMeg(ErrorCode.REQUEST_TIMEOUT_EXCEEDED)); // eslint-disable-line no-alert
      return Promise.reject(error);
    }

    // middleware error
    if (error.response.data.status % 1000 > 0) {
      throw failureHandling(new MiddlewareError(error.response.data));
    }

    throw failureHandling(error);
  },
);
