import { Modal } from "comp-lib";
import useModal from "hooks/useModal";
import useModalContents from "hooks/useModalContents";
import { ChangeEventHandler, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import styles from "styles/pages/appPush/write/registerImg.module.scss";
import { TPushFormValue } from "types/appPush";

import { checkFileType } from "utils/appPush";

type TProps = {
  onChange: (arg: string) => void;
  disabled: boolean;
};

export default function RegisterTxtFile({ onChange, disabled }: TProps) {
  const { setValue } = useFormContext<TPushFormValue>();

  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [isModalOpen, handleToggleModal] = useModal();
  const [modalContents, setModalContents] = useModalContents();

  const fileRef = useRef<HTMLInputElement>(null);

  const handleChangeFile: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.currentTarget.files?.[0];
    if (!file) {
      return;
    }

    if (!checkFileType("text", file.type)) {
      setModalContents({
        title: "파일 등록 불가",
        description: `파일 확장자를 확인해주세요. TXT 파일만 등록 가능합니다.`,
        btnType: "confirm",
        handleClose: handleToggleModal,
      });

      handleToggleModal();

      e.target.value = "";
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const fileString = reader.result?.toString() || "";

      onChange(fileString);

      setFileName(file.name);
      setSelectedFile(file);
      setValue("didListFile", file);
    };
    reader.readAsText(file);
  };

  const handleClickCancel = () => {
    if (!fileRef.current) return;
    fileRef.current.value = "";
    setFileName("");
  };

  const openFile = () => {
    if (selectedFile && checkFileType("text", selectedFile.type)) {
      const url = URL.createObjectURL(selectedFile);
      window.open(url, "_blank");
    }
  };

  if (disabled && fileName && fileRef) {
    handleClickCancel();
  }

  return (
    <div>
      <div className={styles.wrapper}>
        <input
          type="file"
          id="textFile"
          accept="text/plain"
          ref={fileRef}
          disabled={disabled}
          onChange={handleChangeFile}
        />

        <label htmlFor="textFile" className={styles.file_btn}>
          파일 선택
        </label>

        {!fileName && (
          <span className={styles.notice}>
            TXT 파일만 등록 가능 / DID가 여러 개인 경우 쉼표(,) 또는 공백(space) 또는 줄바꿈(enter)으로 구분
          </span>
        )}

        {fileName && (
          <div className={styles.img_text_box}>
            <button type="button" onClick={openFile}>
              {fileName}
            </button>

            <button type="button" onClick={handleClickCancel}>
              &times;
            </button>
          </div>
        )}
      </div>

      {isModalOpen && <Modal {...modalContents} />}
    </div>
  );
}
