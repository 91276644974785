import { CATEGORY_DATA_MAP, QUE_CHOICE_MAP } from "constants/vote";
import { PollRegistRequest, PollReport } from "protobuf/OpenApiServerV3";
import { PollInfoCategory } from "protobuf/OpenApiServerV3DataTypes";
import { TContPrForm, TPollMultiQuestion, TPollQuestionData, TQuestionForm } from "types/contentsManage/vote";

type TFormatSubmitForm = {
  form: TContPrForm;
  imgLink?: string;
  state: PollReport;
  questionAnswer: unknown;
  correctAns: string;
};
export const formatPollRegistForm = ({
  form,
  imgLink,
  questionAnswer,
  state,
  correctAns,
}: TFormatSubmitForm): PollRegistRequest => {
  const formAnswer = JSON.stringify(questionAnswer);
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(formAnswer);

  return {
    pollId: state?.pollId || "",
    pollType: 1,
    multiYn: form.question === "multi",
    category: PollInfoCategory[
      CATEGORY_DATA_MAP[form.category] as unknown as PollInfoCategory
    ] as unknown as PollInfoCategory,
    title: form.title,
    contents: form.content,
    questions: new Uint8Array(uint8Array),
    pagesQuestionsCnt:
      form.question === "multi"
        ? `${(questionAnswer as unknown as TPollMultiQuestion[]).length}|${(
            questionAnswer as unknown as TPollMultiQuestion[]
          ).reduce((acc, arr) => acc + arr.length, 0)}`
        : "1|1",
    imgLink: imgLink || "",
    perAmount: form.perPersonPnt,
    maxIssuable: form.goalPeopleCnt,
    startDate: `${form.startDate} 00:00:00`,
    endDate: `${form.endDate} 00:00:00`,
    endNotSet: form.endDate === "2999-12-31",
    correctAns,
  };
};

type TFormatSingleQuestionAnswer = { question: TQuestionForm };
export const formatSingleQuestionAnswer = ({ question }: TFormatSingleQuestionAnswer): TPollQuestionData[][] => {
  const questionAnswer = [
    [
      {
        question_id: "1",
        question_type: QUE_CHOICE_MAP[question.queType],
        title: question.question,
        ans:
          QUE_CHOICE_MAP[question.queType] === "5"
            ? [question.descriptive]
            : question.answerList.map(({ value }) => value),
        correct_ans: question.correct_ans,
      },
    ],
  ];
  return questionAnswer;
};

export const formatMultiQuestionAnswer = (question: TPollMultiQuestion) => {
  let questionId = 0;
  const questionAnswer = question.map((page) =>
    page.contents.map((answer) => {
      questionId++;
      return {
        question_id: String(questionId),
        question_type: QUE_CHOICE_MAP[answer.queType],
        title: answer.title,
        ans:
          QUE_CHOICE_MAP[answer.queType] === "5" ? [answer.descriptive] : answer.answerList.map(({ value }) => value),
        correct_ans: /정답 (단일|복수) 선택형/.test(answer.queType) ? answer.correct_ans : "",
      };
    }),
  );
  return questionAnswer;
};

export const formatCorrectAns = (question: TPollMultiQuestion): string => {
  const ansArray = question.flatMap((page) => page.contents.map((answer) => answer.correct_ans));
  const pointArray = question.flatMap((page) => page.contents.map((answer) => answer.point));
  if (pointArray.filter((v) => v).length === 0) return "";
  return JSON.stringify({
    ans: ansArray.join("|"),
    point: pointArray.join("|"),
  });
};
