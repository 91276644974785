import { FormEventHandler, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, DateRange } from "comp-lib";

import { RerollIcon } from "assets/svgs/icon";

import useDateRange from "hooks/useDateRange";
import styles from "./searchBox.module.scss";

export default function SearchBox() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { endDate, onChangeDate, startDate, resetDate } = useDateRange();
  const [keywordValue, setKeywordValue] = useState("");
  const [company, setCompany] = useState("");

  const handleSearchSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    setSearchParams((params) => {
      keywordValue ? params.set("keyword", keywordValue) : params.delete("keyword");
      company ? params.set("company", company) : params.delete("company");
      params.set("pageNum", "1");
      return params;
    });
  };

  const handleResetDate = () => {
    searchParams.delete("from");
    searchParams.delete("to");
    searchParams.delete("keyword");
    searchParams.delete("company");
    searchParams.set("pageNum", "1");
    resetDate();
    setKeywordValue("");
    setCompany("");
    setSearchParams(searchParams);
  };

  const handleKeywordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setKeywordValue(e.target.value);
  };

  const handleCompanyChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCompany(e.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.dateBox} onSubmit={handleSearchSubmit}>
        <DateRange startDate={startDate} endDate={endDate} onChange={onChangeDate} setParams />

        <input placeholder="기업/기관명" className={styles.company} value={company} onChange={handleCompanyChange} />

        <input
          placeholder="제목을 입력하세요"
          className={styles.searchKeyword}
          value={keywordValue}
          onChange={handleKeywordChange}
        />

        <div className={styles.searchWrapper}>
          <Button type="submit" size="short" onClick={handleSearchSubmit}>
            검색
          </Button>
          {(searchParams.get("keyword") ||
            searchParams.get("from") ||
            searchParams.get("to") ||
            searchParams.get("company")) && (
            <button type="button" className={styles.searchClearBtn} onClick={handleResetDate}>
              <RerollIcon width="24px" height="24px" />
              초기화하기
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
