import dayjs from "dayjs";
import { TOs } from "types/appPush";

export const readDidFile = (file: File) => {
  const reader = new FileReader();
  return new Promise<string>((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsText(file);
  });
};

export const getDateFormat = (originalDate: string) => {
  if (originalDate) {
    const dateTime = dayjs(originalDate).format("YYYY.MM.DD  HH:mm:ss");

    return dateTime;
  }

  return "-";
};

export const getMsgJson = ({ title, body, os, moveTo }: { title: string; body: string; os: TOs; moveTo: string }) => {
  const commonData = {
    Command: 0,
    AppUpdate: {
      TargetVersion: 21010601,
      IsMandatory: true,
    },
    DataUpdate: {
      Target: 0,
    },
    DataCollect: {
      Type: 1,
      ProposalID: "*",
    },
    MoveTo: moveTo,
  };

  const data = {
    ALL: {
      notification: {
        title,
        body,
      },
      data: {
        ...commonData,
      },
    },
    ANDROID: {
      android: {
        notification: {
          title,
          body,
        },
        priority: "HIGH",
        data: {
          ...commonData,
        },
      },
    },
    IOS: {
      apns: {
        payload: {
          title,
          body,
        },
        headers: {
          "apns-priority": "10",
        },
      },
      data: {
        ...commonData,
      },
    },
  };
  return data[os];
};

export const checkFileType = (acceptType: string, fileType: string) => {
  if (acceptType === "text") {
    if (fileType === "text/plain") {
      return true;
    }
  }

  if (acceptType === "image") {
    if (fileType === "image/jpg" || fileType === "image/jpeg" || fileType === "image/png") {
      return true;
    }
  }

  return false;
};
