import styles from "styles/pages/mydInquiry/list/table/statusChip.module.scss";
import cx from "classnames";

type TProps = {
  status: string;
};

const STATUS_MAP: { [key: string]: string[] } = {
  DONE: ["완료", "ended"],
  REGISTERED: ["접수", "created"],
  PROCESSING: ["진행중", "inProgress"],
  INREVIEW: ["검토중", "inReview"],
};

export default function StatusChip({ status }: TProps) {
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.chip, styles[STATUS_MAP[status][1]])}>{STATUS_MAP[status][0]}</div>
    </div>
  );
}
