import { getChallengeList, getChallengeStatusCount } from "api/contentsManage/challenge";
import queryKey from "constants/queryKey";
import { useQuery } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import { challengeNavData } from "../constants";
import { useState } from "react";

export const useChallengeList = () => {
  const [searchParams] = useSearchParams();
  const [countData, setCountData] = useState(challengeNavData());

  const { search } = useLocation();
  const {
    data: listData,
    isLoading: listLoading,
    isError: listError,
  } = useQuery([queryKey.GET_CHALLENGE_LIST, search], () => getChallengeList(searchParams), {
    cacheTime: 5 * 60 * 1000,
  });
  const { isLoading: countLoading, isError: countError } = useQuery(
    [queryKey.GET_CHALLENGE_COUNT, search],
    () => getChallengeStatusCount(searchParams),
    {
      onSuccess: (res) => {
        const result = challengeNavData();
        res.forEach(({ status, count }) => {
          result.ALL.num += count;
          if (status === "DELETED") {
            result.CANCELED.num += count;
          } else if (Object.keys(result).includes(status)) {
            result[status].num += count;
          } else {
            result.ENDED.num += count;
          }
        });
        setCountData(result);
      },
      cacheTime: 5 * 60 * 1000,
    },
  );
  return {
    listData,
    countData,
    isLoading: listLoading || countLoading,
    isError: listError || countError,
  };
};
