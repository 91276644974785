import { ComponentProps } from "react";
import { CheckboxDisIcon, CheckboxEmtIcon } from "assets/svgs/vote";
import styles from "styles/pages/contentsManage/challenge/report/checkBox.module.scss";
import cx from "classnames";

type TProps = ComponentProps<"input"> & {
  text?: string;
};

export default function CheckBox({ text, checked, ...props }: TProps) {
  return (
    <label className={cx(styles.wrapper, { [styles.disabled]: props.disabled })}>
      {checked ? <CheckboxDisIcon /> : <CheckboxEmtIcon />}
      <span className={styles.label}>{text}</span>

      <input type="checkbox" {...props} hidden />
    </label>
  );
}
