import { translateTargetMap } from "constants/market";

export const formatDataType = (collectData: string[]) => {
  if (!collectData) return "";
  if (collectData.length === 1) return translateTargetMap[collectData[0]] || "";
  if (collectData.length > 1) {
    if (
      collectData.length === collectData.filter((data) => ["medicalCheckup", "medicationRecord"].includes(data)).length
    ) {
      return "건강";
    }
    if (
      collectData.length ===
      collectData.filter((data) => ["bankTransaction", "card", "securities", "insurance"].includes(data)).length
    ) {
      return "금융";
    }
    if (
      collectData.length ===
      collectData.filter((data) => ["googleYoutubeKeyword", "shoppingMallOrder"].includes(data)).length
    ) {
      return "생활";
    }
    return "결합";
  }
  return "";
};
