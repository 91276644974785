import { TPromotionListInfo } from "types/promotion";

const statusMap: Record<number, string[]> = {
  0: ["예정", "notStarted"],
  1: ["진행중", "inProgress"],
  2: ["종료", "ended"],
};

export default function useTableNavData(data: TPromotionListInfo[]) {
  return [
    {
      label: "전체",
      status: "all",
      count: data.length,
    },
    {
      label: "예정",
      status: statusMap[0][1], // notStarted
      count: data.filter((el) => el.target.promotion_status === 1).length,
    },
    {
      label: "진행중",
      status: statusMap[1][1], // inProgress
      count: data.filter((el) => el.target.promotion_status === 2).length,
    },
    {
      label: "종료",
      status: statusMap[2][1], // ended
      count: data.filter((el) => el.target.promotion_status === 3 || el.target.promotion_status === 4).length,
    },
  ];
}
