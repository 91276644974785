import dayjs from "dayjs";
import { Dropdown } from "common/dropdown";
import styles from "styles/pages/contentsManage/challenge/detail/ImagePopup/imagePopup.module.scss";
import { Button, Panel } from "comp-lib";
import "dayjs/locale/ko";
import { useRef, useState } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useQuery } from "react-query";
import queryKey from "constants/queryKey";
import { getChallengeImage } from "api/contentsManage/challenge";
import { useLocation } from "react-router-dom";
dayjs.locale("ko");

type TProps = {
  onClose: () => void;
  initialDate: string;
  dateList: string[];
};

const dateFormat = (date: string) => dayjs(date).format("YYYY년 M월 D일 ddd요일");
const dateOrigin = (input: string) => {
  const result = input.replace(/년|월/, "-").replace(/년|월/, "-");
  return dayjs(result.replace(/[^0-9-]/g, "")).format("YYYY-MM-DD");
};

export default function ImagePopup({ onClose, initialDate, dateList }: TProps) {
  const [date, setDate] = useState<string>(dateFormat(initialDate));

  const { pathname } = useLocation();
  const id = pathname.split("/").pop() || "";
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClose);

  const { data } = useQuery([queryKey.GET_CHALLENGE_IMAGE, date], () =>
    getChallengeImage({ id, proofDate: dateOrigin(date) }),
  );

  return (
    <div className={styles.wrapper} ref={ref}>
      <Dropdown
        dataList={dateList.map((v) => dateFormat(v))}
        handleChange={(v) => setDate(v)}
        value={date}
        className={styles.popup_dropdown}
      />
      <Panel className={styles.panel}>
        {data
          ?.filter((v) => v.createdAt && v.proofFileId) // 잘못된 서버값으로 인한 임시 필터링
          ?.map(({ createdAt, nickName, proofFileId }, idx) => {
            return (
              <div className={styles.list} key={idx}>
                <img src={`/api/file-service/download-file/${proofFileId}`} alt="챌린지 이미지" />
                <span>{nickName}</span>
                <span>{createdAt.replace(/-/g, ".").replace("T", " ")}</span>
              </div>
            );
          })}
      </Panel>
      <Button size="medium" className={styles.button} onClick={onClose}>
        확인
      </Button>
    </div>
  );
}
