import { TManagerListResponse } from "types/manager";
import styles from "styles/pages/manager/listPage.module.scss";
import { Link } from "react-router-dom";
import { ReactNode } from "react";
import { isAdmin } from "../managerHelper";

export const createManagerTableRows = (data?: TManagerListResponse[]): ReactNode[][] => {
  if (!data || data.length === 0) return [[]];
  const rowLength = data.length;
  return data.map((v, index) => [
    rowLength - index,
    <div className={isAdmin(v.role) ? styles.chipWhite : styles.chipBlue} key={10}>
      {isAdmin(v.role) ? "관리자" : "운영자"}
    </div>,
    <Link to={`${v.id}`} key={v.id}>
      {v.name}
    </Link>,
    v.department || "-",
    v.jobPosition || "-",
    v.email || "-",
    v.phone || "-",
  ]);
};

export const createManagerTableNav = (data?: TManagerListResponse[]) => {
  const allLength = data?.length || 0;
  const adminLength = data?.filter(({ role }) => isAdmin(role)).length || 0;
  return [
    {
      label: "전체",
      status: "all",
      count: allLength,
      numeral: "",
    },
    {
      label: "관리자",
      status: "admin",
      count: adminLength,
    },
    {
      label: "운영자",
      status: "manager",
      count: allLength - adminLength,
    },
  ];
};
export const createManagerTableColumn = [
  { text: "No", width: "84px" },
  { text: "구분", width: "160px" },
  { text: "이름", width: "126px" },
  { text: "부서명", width: "170px" },
  { text: "직급", width: "126px" },
  { text: "이메일 주소", width: "224px" },
  { text: "개별 연락처", width: "184px" },
];
