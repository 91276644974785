import { ReactNode, useState } from "react";
import cx from "classnames";
import { DoubleAngleBracketIcon as Arrow } from "assets/svgs/icon";
import styles from "styles/pages/contentsManage/vote/write/mobile/sidebarLayout.module.scss";

type TProps = {
  children: ReactNode;
};

export default function SidebarLayout({ children }: TProps) {
  const [isOpen, setIsOpen] = useState(false);
  const handleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={cx(styles.wrapper, { [styles.wrapperOpen]: isOpen })}>
      <button type="button" className={styles.sidebarOpenBtn} onClick={handleSidebar}>
        <Arrow className={cx({ [styles.closeArrow]: isOpen })} />
      </button>
      <div className={styles.innerWrapper}>{children}</div>
    </div>
  );
}
