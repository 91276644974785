import { useState } from "react";
import { DateInput } from "comp-lib";
import dayjs from "dayjs";
import { Dropdown } from "common/dropdown";
import styles from "styles/pages/banner/dateTime.module.scss";

type TProps = {
  value?: string;
  onChange: (arg: string) => void;
};

export default function DateTime({ value, onChange }: TProps) {
  const [date, setDate] = useState<Date | null>(value ? new Date(value) : null);
  const [time, setTime] = useState(value ? `${Number(value.split("T")[1].slice(0, 2))}시` : "0시");

  const handleDateChange = (arg: Date | null) => {
    setDate(arg);

    if (!arg) {
      onChange("");
      return;
    }
    const tmpTime = time.replace("시", "");
    const newTime = formatHour(Number(tmpTime));

    onChange(`${formatDate(arg)}T${newTime}:00:00+09:00`);
  };
  const handleTimeChange = (arg: string) => {
    setTime(arg);

    if (!date) {
      onChange("");
      return;
    }
    const tmpTime = arg.replace("시", "");
    const newTime = formatHour(Number(tmpTime));

    onChange(`${formatDate(date)}T${newTime}:00:00+09:00`);
  };

  return (
    <div className={styles.wrapper}>
      <DateInput value={date} onChange={handleDateChange} />

      <Dropdown
        dataList={Array.from({ length: 24 }, (_, i) => `${i}시`)}
        value={time}
        handleChange={handleTimeChange}
        height="36px"
        width="206px"
        maxHeight="320px"
        mode="secondary"
        borderColor="soft"
      />
    </div>
  );
}

function formatDate(argDate: Date) {
  return dayjs(argDate).format("YYYY-MM-DD");
}

function formatHour(hour: number) {
  return hour < 10 ? `0${hour}` : `${hour}`;
}
