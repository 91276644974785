import { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import ko from "date-fns/locale/ko";
import { CustomHeader, CustomInput } from "./DatePicker";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./datePicker.module.scss";
import Button from "../Button";

type TProps = {
  value?: Date | null;
  onChange?: (arg: Date | null) => void;
  defaultValue?: Date;
};

export default function DateInput({ value: controlledValue, onChange: controlledOnChange, defaultValue }: TProps) {
  const [uncontrolledValue, setUncontrolledValue] = useState<Date | null>(defaultValue || null);
  const calRef = useRef<DatePicker | null>(null);

  const handleChange = (argDate: Date | null) => {
    if (controlledOnChange) {
      controlledOnChange(argDate);
      return;
    }

    setUncontrolledValue(argDate);
  };
  const handleClose = () => {
    calRef.current && calRef.current.setOpen(false);
  };

  const handleCancel = () => {
    handleChange(null);
    handleClose();
  };

  const displayValue = controlledValue || uncontrolledValue;

  return (
    <div className={styles["react-datepicker"]}>
      <DatePicker
        ref={calRef}
        className={styles["react-datepicker"]}
        minDate={new Date()}
        onChange={handleChange}
        selected={displayValue}
        value={formatDate(displayValue)}
        customInput={<CustomInput mode="dateInput" />}
        renderCustomHeader={(headerProps) => <CustomHeader {...headerProps} />}
        locale={ko}
      >
        <div className={styles.btn_box}>
          <Button type="button" size="small" onClick={handleCancel}>
            삭제
          </Button>
          <Button type="button" size="small" onClick={handleClose}>
            닫기
          </Button>
        </div>
      </DatePicker>
    </div>
  );
}

const formatDate = (date: Date | null) => {
  return date ? dayjs(date).format("YYYY.MM.DD") : "";
};
