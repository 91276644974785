import { useState } from "react";

export type TModalContent = {
  title?: string;
  description: React.ReactNode;
  cancelBtnName?: string;
  submitBtnName?: string;
  confirmBtnName?: string;
  btnType: "submit" | "confirm";
  handleClose: () => void;
  handleSubmit?: () => void;
};

export default function useModalContents(): [TModalContent, React.Dispatch<React.SetStateAction<TModalContent>>] {
  const [modalContents, setModalContents] = useState<TModalContent>({
    title: "",
    description: "",
    cancelBtnName: "",
    submitBtnName: "",
    confirmBtnName: "",
    btnType: "confirm",
    handleClose: () => {},
    handleSubmit: () => {},
  });

  return [modalContents, setModalContents];
}
