import { Axios } from "./base";

import {
  TCreateIssueRequest,
  TCreateIssueResponse,
  TMydInquiryJiraIssue,
  TMydInquiryDetail,
  TMydInquiryListReqeust,
  TMydInquiryListResponse,
  TMydInquiryComment,
  TPostCommentRequest,
  TMydInquiryComplete,
  TEditCommentRequest,
  TCommentResponse,
  TDeleteCommentRequest,
  TMydInquiryListCountRequest,
  TMydInquiryListCountResponse,
} from "types/mydInquiry";

// 문의 목록 조회
export const getMydInquiryList = async ({
  pageable,
  status,
  keyword,
}: TMydInquiryListReqeust): Promise<TMydInquiryListResponse> => {
  const { page } = pageable;

  const response = await Axios.get(`/api/admin/inquiries`, {
    params: { keyword, status, page, size: 10 },
  });

  return response.data.data;
};

// 문의 목록 count 조회
export const getMydInquiryListCount = async ({
  status,
  keyword,
}: TMydInquiryListCountRequest): Promise<TMydInquiryListCountResponse> => {
  const response = await Axios.get(`/api/admin/inquiries/status`, {
    params: { keyword, status },
  });

  return response.data.data;
};

// 문의 상세 조회
export const getMydInquiryDetail = async (id: number): Promise<TMydInquiryDetail> => {
  const response = await Axios.get(`/api/admin/inquiries/${id}`);

  return response.data.data;
};

// 문의 완료
export const completeMydInquiry = async (id: number): Promise<TMydInquiryComplete> => {
  const response = await Axios.put(`/api/admin/inquiries/${id}`);

  return response.data.data;
};

// JIRA 이슈 조회
export const getMydInquiryJiraIssue = async (id: number): Promise<TMydInquiryJiraIssue> => {
  const response = await Axios.get(`/api/admin/inquiries/${id}/jira`);

  return response.data.data;
};

// 답변 조회
export const getMydInquiryComments = async (id: number): Promise<TMydInquiryComment[]> => {
  const response = await Axios.get(`/api/admin/inquiries/${id}/comments`);

  return response.data.data;
};

// 답변 등록
export const postComment = async ({
  id,
  contents,
  attachedFileIds,
}: TPostCommentRequest): Promise<TCommentResponse> => {
  const response = await Axios.post(`/api/admin/inquiries/${id}/comments`, { contents, attachedFileIds });

  return response.data.data;
};

// 답변 수정
export const editComment = async ({
  id,
  commentId,
  contents,
  attachedFileIds,
}: TEditCommentRequest): Promise<TCommentResponse> => {
  const response = await Axios.put(`/api/admin/inquiries/${id}/comments/${commentId}`, { contents, attachedFileIds });

  return response.data.data;
};

// 답변 삭제
export const deleteComment = async ({ id, commentId }: TDeleteCommentRequest): Promise<TCommentResponse> => {
  const response = await Axios.delete(`/api/admin/inquiries/${id}/comments/${commentId}`);

  return response.data.data;
};

// JIRA 이슈 생성
export const createIssue = async ({ id, title, description }: TCreateIssueRequest): Promise<TCreateIssueResponse> => {
  const response = await Axios.post(`/api/admin/inquiries/${id}/jira?title=${title}&description=${description}`);

  return response.data.data;
};

// IMG 업로드(단일)
export const uploadImg = async ({ file }: { file: File }): Promise<any> => {
  const response = await Axios.post(
    `/api/file-service/attached-file`,
    { file },
    { headers: { "Content-Type": "multipart/form-data" } },
  );

  return response.data.data;
};

// IMG 업로드(다중)
export const uploadImgs = async ({ files }: { files: FormData }): Promise<any> => {
  const response = await Axios.post(`/api/file-service/attached-files`, files, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data.data;
};
