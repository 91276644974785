import { Content, LoadingModal } from "comp-lib";
import styles from "styles/pages/contentsManage/challenge/detail/challengeDetail.module.scss";
import { useLocation } from "react-router-dom";
import DetailInfo from "./DetailInfo";
import DetailProgressSetting from "./DetailProgressSetting";
import DetailProgressList from "./DetailProgressList";
import DetailProgressStatus from "./DetailProgressStatus";
import ButtonBox from "./ButtonBox";
import { useQuery } from "react-query";
import queryKey from "constants/queryKey";
import { getChallengeDetail, getChallengeStatistics } from "api/contentsManage/challenge";
import { ErrorContentPage } from "../@shared/ErrorContentPage";

export default function ChallengeDetail() {
  const { pathname } = useLocation();
  const id = pathname.split("/").pop() || "";
  const { data, isLoading } = useQuery([queryKey.GET_CHALLENGE_DETAIL], () => getChallengeDetail(id));
  const { data: statData, isLoading: isStatLoading } = useQuery([queryKey.GET_CHALLENGE_STATISTICS], () =>
    getChallengeStatistics(id),
  );

  if (isLoading || isStatLoading) return <LoadingModal />;
  if (!data || !statData)
    return (
      <ErrorContentPage
        back
        reload
        message={
          "해당 챌린지를 불러오지 못했습니다.\n챌린지 정보가 올바른지 확인해 주시고, 문제가 반복될 경우 CS센터에 문의해 주세요."
        }
      />
    );
  return (
    <Content className={styles.wrapper} isWithFooter>
      <DetailInfo data={data} />
      {["PREOPEN", "CANCELED"].includes(data.status) ? (
        <DetailProgressSetting data={data} />
      ) : (
        <>
          <DetailProgressStatus data={data} statData={statData} />
          <DetailProgressList participant={data?.currentParticipantsCount} />
        </>
      )}
      <ButtonBox status={data.status} endDate={data.endDate} />
    </Content>
  );
}
