import { MouseEventHandler, SetStateAction, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { useMutation, useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import StatusChip from "./list/StatusChip";
import VoteTableTop from "./list/VoteTableTop";
import ListCheckBox from "./list/ListCheckBox";
import Tab from "./list/Tab";

import { useFilterContentsTableData, useFilterContentsTableNav } from "./list/useFilterContentsTable";
import { useFilterReportTableData, useFilterReportTableNav } from "./list/useFilterReportTable";

import { Loading } from "common/other";
import ConfirmModal from "pages/contentsManage/vote/@shared/modal/ConfirmModal";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";
import TableList from "pages/contentsManage/vote/@shared/table/TableList";

import { PollInfoItem, SpamInfo } from "protobuf/OpenApiServerV3";
import { getPollList, getSpamList, patchSpamStatus } from "api/contentsManage/poll";
import queryKey from "constants/queryKey";
import { adPageAtom, contPrFormAtom } from "recoil/vote";
import { formatDuration, getCommasInNum } from "utils";
import { uint8ArrayToString } from "utils/uint8Array";

import { MANAGE_CONTENTS, MANAGE_REPORTS, CATEGORY } from "constants/vote";

import styles from "styles/pages/contentsManage/vote/vote.module.scss";
import { Content, ErrorModal, Title, Pagination } from "comp-lib";

dayjs.extend(isBetween);

export default function Vote() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();

  const tabIdx = searchParams.get("tab") || "0";
  const status = searchParams.get("status");
  const isContents = tabIdx !== "1";

  const pageNum = Number(searchParams.get("pageNum") || 1);

  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isNotiModal, setIsNotiModal] = useState(false);
  const [checkedBtns, setCheckedBtns] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);
  const [isReply, setIsReply] = useState(false);

  const setPageInfo = useSetRecoilState(adPageAtom);
  const resetAtom = useResetRecoilState(contPrFormAtom);

  const { data = [], isLoading } = useQuery([queryKey.GET_POLL_LIST], getPollList, { enabled: tabIdx === "0" });
  const { data: spamData = [], isLoading: isSpamLoading } = useQuery([queryKey.GET_SPAM_LIST], getSpamList, {
    enabled: tabIdx === "1",
  });

  const { mutate } = useMutation(patchSpamStatus);

  const result = data?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  const reportResult = spamData?.sort((a, b) => (a.spamReportTime < b.spamReportTime ? 1 : -1));

  const {
    filteredData,
    size,
    totalPages: contentsTotalPages,
  } = useFilterContentsTableData(result, isContents ? MANAGE_CONTENTS : MANAGE_REPORTS, isContents);

  const {
    filteredData: filteredReport,
    size: reportSize,
    totalPages: reportTotalPages,
  } = useFilterReportTableData(reportResult, isContents ? MANAGE_CONTENTS : MANAGE_REPORTS);

  const navData = useFilterContentsTableNav({ nav: MANAGE_CONTENTS, size });
  const reportNavData = useFilterReportTableNav({ nav: MANAGE_REPORTS, reportSize });

  const handleClickTitle: MouseEventHandler<HTMLButtonElement> = (e) => {
    const detailId = e.currentTarget.id;

    if (!filteredData || !filteredReport) return;

    if (isContents) {
      navigate(`/contents/vote/detail/${detailId}`, {
        state: filteredData.find((el) => el.pollId === detailId),
      });
    } else {
      navigate(`/contents/vote/spam/${detailId}`, {
        state: filteredReport.find((el) => String(el.spamNum) === detailId),
      });
    }

    setPageInfo({ status: status!, pageNum });
  };

  const handleDeleteBtn = () => {
    setIsConfirmModal(true);
  };

  const handleConfirmModalClose = () => setIsConfirmModal(false);

  const handleNotiModalClose = () => setIsNotiModal(false);

  const handleModalConfirm = () => {
    const body = {
      spamCommentIdList: isReply ? [] : checkedBtns,
      spamReplyIdList: isReply ? checkedBtns : [],
      status: 3,
    };

    mutate(body, {
      onSuccess: () => {
        setIsConfirmModal(false);
        setIsNotiModal(true);
        setCheckedBtns([]);
        queryClient.invalidateQueries([queryKey.GET_SPAM_LIST]);
      },
      onError: () => {
        setIsConfirmModal(false);
        setIsError(true);
      },
    });
  };

  const handleRegisterNav = () => {
    resetAtom();
    navigate("/contents/vote/writeContPr");
  };

  const handleCheckedBtns = (btns: SetStateAction<string[]>, reply: boolean) => {
    setCheckedBtns(btns);
    setIsReply(reply);
  };

  const contentsTBody = filteredData
    ?.slice((pageNum - 1) * 10, pageNum * 10)
    ?.map((content: PollInfoItem, idx: number) => {
      const { participant, maxIssuable, pollId, title, category, startDate, endDate } = content;

      const calcRate = ((participant || 0) / maxIssuable) * 100;
      const responseRate = calcRate === Math.round(calcRate) ? calcRate : calcRate.toFixed(2); // 응답률이 나누어 떨어지지 않는 경우에만 소수점 2자리까지 표시

      return [
        idx + 1,
        // No
        filteredData.length - idx - (pageNum - 1) * 10,
        // 상태
        <StatusChip status={content.status} key={pollId} type="content" />,
        // 카테고리
        <span key={pollId}>{CATEGORY[category]}</span>,
        // 제목
        <button type="button" id={pollId} key={pollId} onClick={(e) => handleClickTitle(e)}>
          {title || "-"}
        </button>,
        // 진행 기간
        startDate ? formatDuration(startDate, endDate) : "-",
        // 응답률
        <span key={pollId}>{`${responseRate}%`}</span>,
      ];
    });

  const reportTBody = filteredReport?.slice((pageNum - 1) * 10, pageNum * 10)?.map((report: SpamInfo, idx: number) => {
    const { commentInput }: { commentInput: string } = JSON.parse(uint8ArrayToString(report.spamReportContent));

    return [
      idx + 1,
      // 선택
      <ListCheckBox
        handleCheckedBtns={(btns) => handleCheckedBtns(btns, report.isReply)}
        checkedBtns={checkedBtns}
        key={report.spamNum}
        value={report.spamReportId}
        id={report.spamReportId}
        disabled={!(+report.spamStatus === 2)}
      />,
      // NO
      filteredReport.length - idx - (pageNum - 1) * 10,
      // 상태
      <StatusChip status={report.spamStatus} key={report.spamNum} type="spam" />,
      // 접수 일시
      report.spamReportTime ? dayjs(report.spamReportTime).format("YYYY.MM.DD HH:mm:ss") : "-",
      // 콘텐츠명
      <button type="button" id={String(report.spamNum)} key={report.spamNum} onClick={(e) => handleClickTitle(e)}>
        {report.pollTitle || "-"}
      </button>,
      // 댓글 내용
      <span className={styles.comment} key={report.spamNum}>
        {/* 호버 전 */}
        {commentInput && commentInput?.length <= 20 ? commentInput : `${commentInput?.slice(0, 20)}...`}
        {/* 호버 시  */}
        {tabIdx === "1" && <div className={styles.hoverBox}>{commentInput}</div>}
      </span>,
      getCommasInNum(report.reportCount || 0),
    ];
  });

  const contentsTableData = {
    columns: [
      ["NO", "78px"],
      ["상태", "106px"],
      ["카테고리", "140px"],
      ["제목", "316px"],
      ["진행 기간", "316px"],
      ["응답률", "140px"],
    ],
    dataList: contentsTBody || [],
  };

  const reportTableData = {
    columns: [
      ["선택", "80px"],
      ["NO", "78px"],
      ["상태", "106px"],
      ["접수 일시", "240px"],
      ["콘텐츠명", "280px"],
      ["댓글(답글) 내용", "240px"],
      ["신고건수", "61px"],
    ],
    dataList: reportTBody || [],
  };

  const totalPages = isContents ? contentsTotalPages : reportTotalPages;

  return (
    <Content>
      <Title text="마이디 투표 목록" />

      {isLoading || isSpamLoading ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <Tab />

          <div className={styles.contentContainer}>
            <VoteTableTop data={isContents ? navData : reportNavData} isContents={isContents} />

            <TableList
              columns={isContents ? contentsTableData.columns : reportTableData.columns}
              dataList={isContents ? contentsTableData.dataList : reportTableData.dataList}
            />

            <div className={styles.write_box}>
              {isContents ? (
                <button type="button" onClick={handleRegisterNav}>
                  등록
                </button>
              ) : (
                <button disabled={checkedBtns.length === 0} type="button" onClick={handleDeleteBtn}>
                  삭제하기
                </button>
              )}
            </div>

            <Pagination totalPages={totalPages} />
          </div>
        </div>
      )}

      {isConfirmModal && (
        <ConfirmModal
          message="내역을 삭제하시겠습니까?"
          subMessage="삭제할 경우 다른 사용자에게 댓글(답글)이 보이지 않습니다"
          onCancle={handleConfirmModalClose}
          onConfirm={handleModalConfirm}
          confirmText="확인"
        />
      )}

      {isNotiModal && <NotiModal message="삭제되었습니다." onClose={handleNotiModalClose} />}

      {isError && <ErrorModal error={isError} onConfirmHandler={() => setIsError(false)} />}
    </Content>
  );
}
