import { ChangeEventHandler } from "react";

import styles from "styles/pages/appPush/write/radioBoxAppPush.module.scss";

interface Props {
  name: string;
  dataList: {
    value: string;
    label: string;
  }[];
  value: any;
  disabled?: boolean;
  onChange: (value: any) => void;
  resetInput?: () => void;
}

export default function RadioBoxAppPush({ name, dataList, value: checked, disabled, onChange, resetInput }: Props) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    resetInput?.();
    onChange(e.currentTarget.value);
  };

  return (
    <div className={styles.wrapper}>
      {dataList.map(({ value, label }) => {
        return (
          <div key={value + label} className={styles.radio}>
            <input
              type="radio"
              id={value + label}
              name={name}
              value={value}
              checked={checked === value}
              disabled={disabled}
              onChange={handleChange}
            />
            <label htmlFor={value + label}>{label}</label>
          </div>
        );
      })}
    </div>
  );
}
