import { Checkbox } from "comp-lib";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { TGoodsList } from "types/goods";
import { getJsonProperty } from "utils";
import styles from "styles/pages/goods/tableData.module.scss";

export default function useTableData(data: TGoodsList) {
  const [selectedItems, setSelectedItems] = useState<[number, string][]>([]);
  const location = useLocation();

  const columns = [
    { text: "선택", width: "50px" },
    { text: "코드", width: "150px" },
    { text: "이미지", width: "250px" },
    { text: "이름" },
    { text: "가격", width: "200px" },
    { text: "상태", width: "100px" },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.currentTarget;
    const item: [number, string] = [Number(name), value];

    if (checked) {
      setSelectedItems((prev) => [...prev, item]);
      return;
    }

    setSelectedItems((prev) => prev.filter(([idx]) => idx !== item[0]));
  };

  const rows = data.map(({ code, price, status, info }, idx) => [
    <Checkbox key={code} name={String(idx)} value={code} onChange={handleChange} />,
    code,
    <img
      key={`img-${code}`}
      src={getJsonProperty(info, "mmsGoodsImg") as string}
      alt="goods"
      className={styles.goods_img}
    />,
    <Link key={`link-${code}`} to={`detail/${code}${location.search}`}>
      {getJsonProperty(info, "goodsName") as string}
    </Link>,
    price.toLocaleString(),
    status,
  ]);

  return { columns, rows, selectedItems, resetSelectedItems: () => setSelectedItems([]) };
}
