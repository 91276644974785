import { useFieldArray, useFormContext } from "react-hook-form";
import styles from "styles/pages/goods/goodsRegisterContent.module.scss";
import { TGoodsFormData } from "types/goods";
import { XIcon } from "assets/svgs/vote";
import { Button } from "comp-lib";

export default function GoodsRegisterContent() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<TGoodsFormData>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "codes",
  });

  const handleAddClick = () => append({ code: "" });

  const handleDelClick = (idx: number) => {
    if (idx < 1) {
      return;
    }

    remove(idx);
  };

  return (
    <ul className={styles.wrapper}>
      {fields.map((item, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`goods-${idx}`}>
          <label className={styles.input_goods}>
            <span>상품 코드 {idx + 1}</span>

            <input
              type="text"
              placeholder="상품코드를 입력해주세요."
              className={styles.que_input}
              autoComplete="off"
              aria-invalid={errors?.codes?.[idx]?.code ? "true" : "false"}
              {...register(`codes.${idx}.code` as const, {
                required: true,
              })}
            />
          </label>

          <button type="button" name="del" onClick={() => handleDelClick(idx)}>
            <XIcon />
          </button>
        </li>
      ))}

      <li className={styles.add_box}>
        <Button type="button" mode="outlined" size="normal" onClick={handleAddClick}>
          상품코드 추가
        </Button>
      </li>
    </ul>
  );
}
