import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import { utils, writeFile } from "xlsx";
import { Button, Panel } from "comp-lib";

import styles from "styles/pages/appPush/detail/currStatus.module.scss";

import { getPushResult } from "api/appPush";
import { TExcelData, TPushDetail } from "types/appPush";
import { getCommasInNum } from "utils";
import { useMutation } from "react-query";

type TProps = {
  data: TPushDetail;
  onToggleModal: () => void;
};

const STATUS_MAP: { [key: string]: string } = {
  PENDING: "발송 중",
  SUCCESS: "발송 성공",
  FAIL: "발송 실패",
  RESERVATION: "발송 예약",
};

export default function CurrStatus({ data, onToggleModal }: TProps) {
  const { id } = useParams();

  const { title, status, messageType, sendCount, successCount, clickCount } = data.data.pushMessageDetail;

  const canDownloadCSV = status === "SUCCESS" && messageType !== "TOPIC";

  const successRate = Math.floor((successCount / sendCount) * 100) || 0;

  const createExcelFormatData = (pushData: TExcelData[]) => {
    if (!pushData) return null;

    return pushData.map((item: TExcelData) => {
      const 발송완료일시 = item.endTime;
      const DID = item.did;
      const 발송상태 = item.result;

      return { 발송완료일시, DID, 발송상태 };
    });
  };

  const resultPushMuta = useMutation(() => getPushResult(Number(id)), {
    onSuccess: (res) => {
      if (res.status === 404) {
        onToggleModal();
      }
    },
  });

  const getExcelData = async () => {
    const response = await resultPushMuta.mutateAsync();

    return createExcelFormatData(response.data.pushMessageResult);
  };

  // sheetName 조건 : 31자 제한, : \ / ? * [ ] 미포함
  const getValidTitle = (str: string) => {
    return str.replace(/[:\/?*\[\]]/g, "_").substring(0, 20);
  };

  const handleDownLoad = async () => {
    const todayDate = dayjs().format("YYYYMMDD");
    const excelData = await getExcelData();

    if (excelData) {
      const sheet = utils.json_to_sheet(excelData);
      const sheetWithoutData = utils.aoa_to_sheet([["발송완료일시", "DID", "발송상태"]]);
      const workbook = utils.book_new();
      utils.book_append_sheet(
        workbook,
        excelData.length === 0 ? sheetWithoutData : sheet,
        `${todayDate}_${getValidTitle(title)}`,
      );
      writeFile(workbook, `${todayDate}_${getValidTitle(title)}.xlsx`);
    }
  };

  return (
    <Panel>
      <h2 className={styles.sectionTitle}>발송 성과</h2>

      <section className={styles.config}>
        <div className={styles.categoryBox}>
          <span className={styles.category}>발송 상태</span>
          <span>{STATUS_MAP[status]}</span>
        </div>

        <div className={styles.categoryBox}>
          <span className={styles.category}>발송 수</span>
          <span>{getCommasInNum(sendCount)}</span>
        </div>

        <div className={styles.categoryBox}>
          <span className={styles.category}>발송 성공률</span>

          <div
            className={styles.statusBar}
            style={{
              backgroundImage: `linear-gradient(to right, #1ab9de ${successRate}%, #f0f0f0 0%, #f0f0f0 100%)`,
            }}
          >
            <div className={styles.percent} style={{ left: `${successRate}%` }}>
              {successRate}%
            </div>
          </div>

          <span className={styles.number}>
            {getCommasInNum(successCount!)} / {getCommasInNum(sendCount!)}
          </span>
        </div>

        <div className={styles.categoryBox}>
          <span className={styles.category}>푸시 클릭 수</span>
          <span>{getCommasInNum(clickCount)}</span>
        </div>

        {/* TODO: 마이디 개편 후 사용 예정 */}
        {/* <div className={styles.categoryBox}>
          <span className={styles.category}>푸시 클릭률</span>
          <div
            className={styles.statusBar}
            style={{
              backgroundImage: `linear-gradient(to right, #1ab9de ${successRate}%, #f0f0f0 0%, #f0f0f0 100%)`,
            }}
          >
            <div className={styles.percent} style={{ left: `${successRate}%` }}>
              {successRate}%
            </div>
          </div>

          <span className={styles.number}>
            {getCommasInNum(successCount!)} / {getCommasInNum(sendCount!)}
          </span>
        </div> */}

        {canDownloadCSV && (
          <Button className={styles.csvBtn} onClick={handleDownLoad}>
            CSV 다운로드
          </Button>
        )}
      </section>
    </Panel>
  );
}
