import {
  Content,
  Panel,
  Title,
  TableNav,
  Table,
  Pagination,
  useCustomParams,
  ErrorModal,
  LoadingModal,
} from "comp-lib";
import { useQuery } from "react-query";
import { getRechargeList } from "api/recharge";
import styles from "styles/pages/reward/rechargeList.module.scss";
import useTableNavData from "./useTableNavData";
import useTableData from "./useTableData";
import { useNavigate } from "react-router-dom";

const DEFAULT_DATA = {
  requestList: {
    content: [],
    totalElements: 0,
  },
  pagination: {
    totalPage: 1,
    totalCount: 1,
  },
  requestCounts: {
    countAccept: 0,
    countPending: 0,
    countReject: 0,
    countAll: 0,
  },
};

export default function RechargeList() {
  const [{ pageNum, status }] = useCustomParams({
    pageNum: "1",
    status: "ALL",
  });

  const {
    data = DEFAULT_DATA,
    isError,
    isLoading,
    isFetching,
  } = useQuery(["getRechargeList", pageNum, status], () => getRechargeList({ pageNum: Number(pageNum), status }), {
    keepPreviousData: true,
  });

  const tableNavData = useTableNavData(data.requestCounts);
  const { columns, rows } = useTableData(data.requestList.content, data.requestList.totalElements);

  const navigate = useNavigate();
  const handleError = () => {
    navigate("/");
  };

  return (
    <>
      <Content>
        <Title text="포인트 충전 요청 목록" />

        <Panel>
          <div className={styles.header}>
            <TableNav dataList={tableNavData} />
          </div>

          <Table columns={columns} rows={rows} />

          <div className={styles.footer}>
            <Pagination totalPages={data.pagination.totalPage} />
          </div>
        </Panel>
      </Content>

      {(isLoading || isFetching) && <LoadingModal />}
      {isError && (
        <ErrorModal
          message={`포인트 충전 요청 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`}
          onConfirmHandler={handleError}
        />
      )}
    </>
  );
}
