import { AccessRes } from "types/common";
import { getUserRole } from "./common";
import { getToken, setToken } from "utils/access";

type TFetchParam = {
  url: string;
  init?: RequestInit;
  data?: Uint8Array;
};

export const fetchProtobuf = async ({ url, init, data }: TFetchParam, retryCnt = 1): Promise<Uint8Array> => {
  const accessToken = await getToken();
  if (accessToken === null) {
    window.location.href = "/login?invalid-token";
    return new Uint8Array(); // 타입스크립트를 위한 리턴
  }

  try {
    const res = await fetch(`/api${url}`, {
      ...init,
      method: init?.method || "POST",
      headers: {
        "Content-Type": "application/x-protobuf", // 프로토콜 버퍼의 MIME 타입
        Accept: "application/x-protobuf",
        "X-Auth-Token": accessToken,
        ...init?.headers,
      },
      body: data,
    });

    // 401 에러 발생 시 한 번 더 시도
    if (res.status === 401 && retryCnt > 0) {
      setToken(null);
      return fetchProtobuf({ url, init, data }, retryCnt - 1);
    }

    const buffer = (await checkStatus(res)) && (await res.arrayBuffer());
    const result = new Uint8Array(await buffer);

    return result;
  } catch (error) {
    throw error;
  }
};

export const getAccessData = (() => {
  let accessData: AccessRes | null = null;

  return async () => {
    if (accessData) {
      return accessData;
    }

    const result = await getUserRole();
    accessData = result;

    return accessData;
  };
})();

const checkStatus = (response: any) => {
  if (!response.ok) {
    throw new Error(`HTTP ${response.status} - ${response.statusText}`);
  }
  return response;
};
