import styles from "styles/common/other/InfoItem.module.scss";

import cx from "classnames";

type TProps = {
  title: string;
  value: React.ReactNode;
  isLast?: boolean;
};

export default function InfoItem({ title, value, isLast }: TProps) {
  return (
    <div className={cx(styles.wrapper, { [styles.isLast]: isLast })}>
      <span className={styles.title}>{title}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
}
