import { Portal } from "comp-lib";
import { ErrorModal } from "./components";
import { TQuickMenuItem } from "types/quickMenu";
import styles from "styles/pages/quickMenu/quickMenuPreview.module.scss";

type TProps = {
  handleClick: () => void;
  data: TQuickMenuItem[];
};

export default function QuickMenuPreview({ handleClick, data }: TProps) {
  const sortedItems = data.filter((el) => el.applyProd).sort((a, b) => a.seq - b.seq);

  return (
    <>
      {sortedItems.length !== 0 ? (
        <Portal>
          <div className={styles.wrapper} onClick={handleClick} role="presentation">
            <div className={styles.container} onClick={(e) => e.stopPropagation()} role="presentation">
              {sortedItems.map(({ menuNo, menuImg, menuName }) => (
                <div key={menuNo} className={styles.item_box}>
                  <div>
                    <img src={menuImg} alt={menuName} />
                  </div>
                  <span>{menuName}</span>
                </div>
              ))}
            </div>
          </div>
        </Portal>
      ) : (
        <ErrorModal
          message={`표시할 퀵메뉴 아이콘이 없습니다.\n미리보기는 상용 버전의 노출 설정 기준으로 보여집니다.`}
          onConfirmHandler={handleClick}
        />
      )}
    </>
  );
}
