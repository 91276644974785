import { useFormContext } from "react-hook-form";

import styles from "styles/pages/appPush/write/preview/previewContent.module.scss";

import PreviewIOS from "./PreviewIOS";
import PreviewAOS from "./PreviewAOS";

import { TPushFormValue } from "types/appPush";

export default function PreviewContent() {
  const { watch } = useFormContext<TPushFormValue>();

  const isOSTarget = watch().targetType === "OS";

  const isTargetIOS = watch().messagePlatformStatus === "IOS";

  const isTargetAOS = watch().messagePlatformStatus === "ANDROID";

  return (
    <div className={styles.wrapper}>
      {(!isOSTarget || (!isTargetIOS && !isTargetAOS)) && (
        <>
          <PreviewIOS />
          <PreviewAOS />
        </>
      )}

      {isOSTarget && isTargetIOS && <PreviewIOS />}
      {isOSTarget && isTargetAOS && <PreviewAOS />}
    </div>
  );
}
