import { Link, useLocation, useSearchParams } from "react-router-dom";
import cx from "classnames";
import { LeftArrowIcon, RightArrowIcon } from "assets/svgs/icon";
import styles from "styles/pages/contentsManage/challenge/list/pagination.module.scss";
import { Dropdown } from "common/dropdown";

type TProps = {
  totalPages: number;
  totalElements: number;
};

export default function Pagination({ totalPages, totalElements }: TProps) {
  const pageRange = 5;
  const { search } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNum = Number(searchParams.get("pageNum")) || 1;
  const pageSize = Number(searchParams.get("size")) || 10;

  const { startNumberOfPageRange, endNumberOfPageRange, isPrev, isNext } = getPageData({
    pageNum,
    totalPages,
    pageRange,
  });

  // location.search에 맞춰 쿼리스트링을 리턴
  const nextQuery = (num: number, base?: number) => {
    const containsPageNum = /(pageNum=)(\d+)/g.test(search);
    if (!search) return `?pageNum=${base ? base + num : num}`;
    if (containsPageNum) return search.replace(/(pageNum=)(\d+)/g, `pageNum=${base ? base + num : num}`);
    return `${search}&pageNum=${num}`;
  };

  const handleChangeSize = (sizeParam: string) => {
    const size = sizeParam.slice(0, -4);
    const nextPageNum = // size 변경 시 보여줄 수 없는 페이지를 보여주지 않도록 변경
      Math.ceil(totalElements / Number(size)) < pageNum ? Math.ceil(totalElements / Number(size)) : pageNum;
    setSearchParams(
      (params) => {
        params.set("size", size);
        params.set("pageNum", String(nextPageNum));
        return params;
      },
      { replace: true },
    );
  };

  return (
    <section className={styles.wrapper}>
      <Link to={nextQuery(-1, startNumberOfPageRange)} className={cx(styles.prev, { [styles.disabled]: !isPrev })}>
        <LeftArrowIcon stroke={isPrev ? "#595959" : "#bfbfbf"} />
      </Link>

      {range(startNumberOfPageRange, endNumberOfPageRange).map((item) => (
        <Link key={item} to={nextQuery(item)} className={cx({ [styles.active]: item === pageNum })}>
          {item}
        </Link>
      ))}

      <Link to={nextQuery(endNumberOfPageRange, 1)} className={cx(styles.next, { [styles.disabled]: !isNext })}>
        <RightArrowIcon stroke={isNext ? "#595959" : "#bfbfbf"} />
      </Link>

      <Dropdown
        dataList={Array.from({ length: 5 }, (_, i) => `${(i + 1) * 10}개 보기`)}
        handleChange={handleChangeSize}
        value={`${pageSize}개 보기`}
        mode="secondary"
        width="116px"
        height="32px"
      ></Dropdown>
    </section>
  );
}

function range(start: number, end: number) {
  const array = [];
  for (let i = start; i <= end; ++i) {
    array.push(i);
  }
  return array;
}

function getPageData({ pageNum, totalPages, pageRange }: { pageNum: number; totalPages: number; pageRange: number }) {
  const startNumberOfPageRange = Math.trunc((pageNum - 1) / pageRange) * pageRange + 1;
  const tmpEnd = startNumberOfPageRange + (pageRange - 1);
  const endNumberOfPageRange = tmpEnd > totalPages ? totalPages : tmpEnd;

  const isPrev = startNumberOfPageRange > pageRange;
  const isNext = endNumberOfPageRange < totalPages;

  return { startNumberOfPageRange, endNumberOfPageRange, isPrev, isNext };
}
