import styles from "styles/pages/promotion/detail/infoItem.module.scss";

type TProps = {
  children: React.ReactNode | string;
  label: string;
};

export default function InfoItem({ children, label }: TProps) {
  return (
    <div className={styles.contentBox}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{children}</div>
    </div>
  );
}
