/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import { MouseEventHandler, ReactNode, useState } from "react";

import styles from "styles/pages/contentsManage/vote/write/mobile/mobileView.module.scss";

import { ArrowLeftIcon, ChatIcon, PeopleIcon } from "assets/svgs/skeleton";
import { ShareIcon } from "assets/svgs/vote";

import DOMPurify from "dompurify";

type TProps = {
  data: {
    title: string;
    content: string;
    imgFileString: File | string;
    startDate: string;
    endDate: string;
    filter: {
      gender: string;
      age: string;
      area: string;
    };
    perPersonPnt: number;
  };
  children?: ReactNode;
};

const addComma = (value: string | number) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export default function MobileView({ data, children }: TProps) {
  const { title, imgFileString, startDate, endDate, perPersonPnt, content } = data;

  const haveCdnImgUrl = typeof imgFileString === "string";

  const [img, setImg] = useState("");

  const handleFormatImg = (imgFile: File) => {
    if (!imgFile) return;

    const reader = new FileReader();

    reader.onloadend = () => {
      const fileString = reader.result?.toString() || "";
      setImg(fileString);
      return fileString;
    };

    reader.readAsDataURL(imgFile);
  };

  if (!haveCdnImgUrl) handleFormatImg(imgFileString);

  const onClickHref: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target instanceof HTMLAnchorElement) {
      e.preventDefault();
      const currHref = window.location.href.split("/").slice(0, -1).join("/");
      const targetHref = e.target.href;
      const href = targetHref.includes(currHref) ? `https://${targetHref.slice(currHref.length)}` : targetHref;
      window.open(href, "_blank");
    }
  };

  return (
    <article className={styles.wrapper}>
      <div className={styles.header}>
        <ArrowLeftIcon />
        마이디 투표
      </div>

      <div className={styles.main}>
        <h1>{title || "제목을 입력해주세요"}</h1>

        <div className={styles.info}>
          <span className={styles.date}>기간 : {startDate ? `${startDate} ~ ${endDate}` : "기간을 설정해 주세요"}</span>
          <span className={styles.point}>{perPersonPnt ? `${addComma(perPersonPnt)}P` : "포인트"}</span>
        </div>

        <div className={styles.img_box}>
          {imgFileString ? (
            <img src={haveCdnImgUrl ? imgFileString : img} alt="preview" />
          ) : (
            `이미지가\n표시되는 영역입니다.`
          )}
        </div>

        <div className={styles.content}>
          {!content && "본문 내용이 표시되는 영역입니다."}
          {content && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              className={styles.viewer}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
              onClick={onClickHref}
            />
          )}
        </div>

        <div className={styles.stats}>
          <div className={styles.statsLeft}>
            <div className={styles.icon}>
              <PeopleIcon />0
            </div>

            <div className={styles.icon}>
              <ChatIcon />0
            </div>
          </div>
          <ShareIcon />
        </div>

        {children}
      </div>

      <div className={styles.btn_box}>
        <button type="button" disabled name="submit">
          {perPersonPnt ? `참여하고 ${addComma(perPersonPnt)}P 받기` : `참여하고 포인트 받기`}
        </button>
      </div>
    </article>
  );
}
