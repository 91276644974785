import { ComponentProps } from "react";
import { CheckboxDisIcon, CheckboxSkyIcon } from "assets/svgs/vote";
import styles from "./checkBox.module.scss";

type TProps = ComponentProps<"input"> & {
  text: string;
};

export default function CheckBox({ text, checked, ...props }: TProps) {
  return (
    <label className={styles.wrapper}>
      {checked ? <CheckboxSkyIcon /> : <CheckboxDisIcon />}
      <span className={styles.label}>{text}</span>

      <input type="checkbox" {...props} hidden />
    </label>
  );
}
