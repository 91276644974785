import styles from "styles/pages/manager/registerPage.module.scss";
import { Button, Content, Footer, Modal, Panel, Title, ErrorModal, LoadingModal } from "comp-lib";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { RadioBox } from "common/input";
import CheckboxForm from "./components/CheckboxForm";
import cx from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { createManagerStaff } from "api/admin/manager";
import { TCreateManagerStaff } from "types/manager";
import { ADMIN_CHECKBOX_OPTIONS, ADMIN_ROLE_LIST } from "./managerHelper";
import ROLE from "constants/role";

export default function RegisterPage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<FieldValues & TCreateManagerStaff>({ mode: "all" });
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogState, setDialogState] = useState(dialogMap.default);

  const {
    mutate: createStaff,
    isError,
    error,
    isLoading,
  } = useMutation(createManagerStaff, {
    onSuccess: () => setDialogState({ ...dialogMap.등록완료, handleClose: () => navigate("/users") }),
    onError: () => setIsDialogOpen(false),
  });

  const onSubmitButton = (inputData: TCreateManagerStaff) => {
    handleSubmit(onSubmitButton);
    submitCreateStaff(inputData);
  };

  const submitCreateStaff = (inputData: TCreateManagerStaff) => {
    setIsDialogOpen(true);
    setDialogState({
      ...dialogMap.등록확인,
      handleClose: () => setIsDialogOpen(false),
      handleSubmit: () => {
        createStaff(inputData);
      },
    });
  };

  const onClickCancleButton = () => {
    setIsDialogOpen(true);
    setDialogState({
      ...dialogMap.취소확인,
      handleSubmit: () => setIsDialogOpen(false),
      handleClose: () => navigate("/users"),
    });
  };

  return (
    <Content>
      <Title text="사용자 등록" />
      <Panel className={styles.panel}>
        <div className={styles.innerPannel}>
          <p className={styles.subTitle}>※ 계정 생성 시 입력한 이메일 주소로 비밀번호 설정 링크가 발송됩니다.</p>
          <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitButton)}>
            <div className={styles.mustLabel}>권한</div>
            <Controller
              defaultValue={ROLE.SNP_ADMIN}
              name="role"
              control={control}
              rules={{ required: "권한을 선택해주세요" }}
              render={({ field: { value, onChange } }) => {
                return (
                  <div className={styles.radioBoxWrapper}>
                    <RadioBox name="role" dataList={ADMIN_ROLE_LIST} value={value} handleCheck={onChange} />
                  </div>
                );
              }}
            />
            {watch("role") === ROLE.SNP_MANAGER && <div className={styles.mustLabel}>접근 가능 메뉴</div>}
            {watch("role") === ROLE.SNP_MANAGER && (
              <Controller
                name="authority"
                control={control}
                rules={{ required: "메뉴를 선택해주세요" }}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => {
                  return (
                    <CheckboxForm
                      name="authority"
                      value={value}
                      options={ADMIN_CHECKBOX_OPTIONS}
                      handleChange={onChange}
                      isError={!!errors.authority}
                    />
                  );
                }}
              />
            )}
            <div className={styles.mustLabel}>이름</div>
            <input
              className={cx(styles.input, { [styles.error]: !!errors.staffName })}
              placeholder="이름을 입력해 주세요"
              {...register("staffName", { required: true })}
            />
            <div className={styles.mustLabel}>이메일주소</div>
            <input
              className={cx(styles.input, { [styles.error]: !!errors.email })}
              placeholder="이메일주소를 입력해 주세요"
              {...register("email", { required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
            />
            <div className={styles.mustLabel}>부서명</div>
            <input
              className={cx(styles.input, { [styles.error]: !!errors.department })}
              placeholder="부서명을 입력해 주세요"
              {...register("department", { required: true })}
            />
            <div className={styles.mustLabel}>직급</div>
            <input
              className={cx(styles.input, { [styles.error]: !!errors.jobPosition })}
              placeholder="직급을 입력해 주세요"
              {...register("jobPosition", { required: true })}
            />
            <div className={styles.mustLabel}>개별 연락처</div>
            <input
              className={cx(styles.input, { [styles.error]: !!errors.phone })}
              placeholder="- 를 제외한 숫자만 입력해 주세요"
              maxLength={11}
              {...register("phone", { required: true, pattern: /^[0-9]*$/ })}
            />
            <div className={styles.label}>직통 번호</div>
            <input
              className={cx(styles.input, { [styles.error]: !!errors.tel })}
              placeholder="- 를 제외한 숫자만 입력해 주세요"
              maxLength={11}
              {...register("tel", { required: false, pattern: /^[0-9]*$/ })}
            />
          </form>
        </div>
      </Panel>

      <Footer>
        <Button onClick={onClickCancleButton} mode="outlined" size="long" type="button" className={styles.cancleBtn}>
          취소
        </Button>
        <Button onClick={handleSubmit(onSubmitButton)} size="long">
          등록
        </Button>
      </Footer>
      {isDialogOpen && <Modal {...dialogState} />}
      {isError && <ErrorModal error={error} />}
      {isLoading && <LoadingModal />}
    </Content>
  );
}

const dialogMap = {
  default: {
    title: "",
    description: "",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "confirm" as "confirm" | "submit",
    handleClose: () => {},
    handleSubmit: () => {},
  },
  등록확인: {
    title: "해당 사용자를 등록하시겠습니까?",
    description: "입력된 정보로 사용자가 등록됩니다",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "submit" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
  등록완료: {
    title: "사용자 등록이 완료되었습니다",
    description: "등록된 이메일 주소로 비밀번호 설정 링크가 발송됩니다.\n비밀번호 설정 링크는 24 시간 후 만료됩니다.",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "confirm" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
  취소확인: {
    title: "사용자 등록을 취소하시겠습니까?",
    description: "지금까지 작성한 내용은 저장되지 않습니다.",
    cancelBtnName: "나가기",
    submitBtnName: "계속하기",
    confirmBtnName: "확인",
    btnType: "submit" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
  취소완료: {
    title: "",
    description: "",
    cancelBtnName: "취소",
    submitBtnName: "등록",
    confirmBtnName: "확인",
    btnType: "confirm" as const,
    handleClose: () => {},
    handleSubmit: () => {},
  },
};
