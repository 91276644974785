import { useEffect, useState, ChangeEventHandler, FormEventHandler } from "react";
import cx from "classnames";
import { useSearchParams } from "react-router-dom";
import { DefaultBtn } from "common/button";
import styles from "./searchKeywordInput.module.scss";

type TProps = {
  placeholder: string;
  withoutIcon?: boolean;
};

export default function SearchKeywordInput({ placeholder, withoutIcon = false }: TProps) {
  const [value, setValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const searchKeyword = searchParams.get("searchKeyword") || "";

  useEffect(() => {
    setValue(searchKeyword);
  }, [searchKeyword]);

  const handleChangeSearch: ChangeEventHandler<HTMLInputElement> = (e) => setValue(e.currentTarget.value);

  const handleSearchSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    setSearchParams((prev) => {
      value ? prev.set("searchKeyword", value?.replace(/\s/g, "")) : prev.delete("searchKeyword");
      prev.set("pageNum", "1");
      return prev;
    });
  };

  return (
    <form className={styles.wrapper} onSubmit={handleSearchSubmit}>
      <div className={cx(styles.topBox, { [styles.gapStyle]: withoutIcon })}>
        <div className={styles.inputBox}>
          <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={handleChangeSearch}
            className={cx({ [styles.withoutIconStyle]: withoutIcon })}
          />
        </div>

        <div className={styles.boxContainer}>
          <DefaultBtn type="submit" width="100px" height="36px" onClick={handleSearchSubmit}>
            검색
          </DefaultBtn>
        </div>
      </div>
    </form>
  );
}
