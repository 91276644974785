import styles from "styles/common/input/SelectInput.module.scss";
import { ChangeEventHandler } from "react";

interface Props {
  name: string;
  dataList: {
    label: string;
    value: any;
  }[];
  handleCheck: (value: any) => void;
}

export default function SelectInput({ name, dataList, handleCheck }: Props) {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => handleCheck(e.currentTarget.value);

  return (
    <select className={styles.wrapper} name={name} onChange={handleChange}>
      {dataList.map(({ label, value }) => (
        <option key={`${name}_${value}`} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
}
