import { useSearchParams } from "react-router-dom";

import styles from "styles/pages/contentsManage/vote/vote.module.scss";
import cx from "classnames";

import { TAB_ARR } from "constants/vote";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

export default function Tab() {
  const [searchParams, setSearchParams] = useSearchParams();

  const tabIdx = searchParams.get("tab");
  const isContents = tabIdx !== "1";

  const handleClickTab = (idx: number) => {
    if (isNaN(idx)) return;
    searchParams.set("tab", String(idx));
    searchParams.set("status", "all");
    searchParams.set("pageNum", "1");
    searchParams.delete("searchKeyword");
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.tabContainer}>
      {TAB_ARR.map((item) => (
        <button
          type="button"
          key={item.idx}
          onClick={() => handleClickTab(item.idx)}
          className={cx(
            styles.tabItem,
            isContents === Boolean(item.idx) || styles.focused,
            item.idx === 0 ? styles.left : styles.right,
          )}
        >
          {item.title}
        </button>
      ))}
    </div>
  );
}
