import { useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import cx from "classnames";
import dayjs from "dayjs";

import { Button, Content, Footer, Panel } from "comp-lib";
import CheckBox from "./CheckBox";
import Pagination from "../list/Pagination";
import queryKey from "constants/queryKey";
import { getChallengeReportList } from "api/contentsManage/challenge";
import ReportButtonBox from "./ReportButtonBox";
import { Loading } from "common/other";
import BlockButton from "./BlockButton";
import { TSelectedReport } from "types/contentsManage/challenge";
import useOnClickOutside from "hooks/useOnClickOutside";

import { DeleteIcon } from "assets/svgs/quickMenu";
import styles from "styles/pages/contentsManage/challenge/report/challengeReport.module.scss";

export default function ChallengeReport() {
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const { current } = useRef(state);

  const [selected, setSelected] = useState<TSelectedReport>([]);
  const [fileId, setFileId] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEnlarged, setIsEnlarged] = useState(false); // 이미지 확대 여부
  const [errorImgId, setErrorImgId] = useState("");

  const { data, isLoading, isError } = useQuery(
    [queryKey.GET_CHALLENGE_REPORT_LIST, searchParams.get("pageNum"), searchParams.get("size")],
    () => getChallengeReportList(searchParams),
  );

  const handleReportSelect = ({ id, proofId }: { id: number; proofId: number }) => {
    setSelected((prev) => {
      const isSelected = prev.some((v) => v.id === id);

      if (isSelected) {
        return prev.filter((v) => v.id !== id);
      } else {
        return [...prev, { id, proofId }];
      }
    });
  };

  const selectedReset = () => setSelected([]);

  const handleCloseImgPopup = () => {
    setIsPopupOpen(false);
    setIsEnlarged(false);
  };

  const handleClickImg = (fileId: string) => {
    setFileId(fileId);
    setIsPopupOpen(!isPopupOpen);
  };

  useOnClickOutside(ref, handleCloseImgPopup);

  return (
    <Content>
      <Panel className={styles.wrapper}>
        <div className={styles.header}>
          <h1>그룹루틴 챌린지 이미지 신고관리</h1>
          <div>
            <div className={styles.filter}>
              <span data-active={true}>
                전체 <strong>{data?.totalElements}개</strong>
              </span>
            </div>
            <ReportButtonBox
              selected={selected}
              selectedReset={selectedReset}
              disabled={!["RECRUITING", "PROCEEDING"].includes(current)}
            />
          </div>
        </div>

        <div className={styles.contents}>
          <div className={styles.contentTop}>
            <div>선택</div>
            <div>접수일시</div>
            <div>등록 DID</div>
            <div>신고 이미지</div>
          </div>

          {!isError && isLoading ? (
            <Loading />
          ) : (
            <div className={styles.contentItems}>
              {data?.content.map(({ id, proofId, accusedAt, participantDid, proofFileId, status }) => {
                return (
                  <div className={cx(styles.item)} key={proofId}>
                    <div>
                      <CheckBox
                        onClick={() => handleReportSelect({ id, proofId })}
                        checked={selected.map((v) => v.id).includes(id)}
                        disabled={status === "CLOSED"}
                      ></CheckBox>
                    </div>
                    <div>{dayjs(accusedAt).format("YYYY.MM.DD HH:mm:ss")}</div>
                    <div>{participantDid}</div>
                    <div>
                      <img
                        src={`/api/file-service/download-file/${proofFileId}`}
                        onClick={() => proofFileId !== errorImgId && handleClickImg(proofFileId)}
                        onError={() => setErrorImgId(proofFileId)}
                        alt="report image"
                        style={{ cursor: proofFileId === errorImgId ? "not-allowed" : "zoom-in" }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <BlockButton selected={selected} selectedReset={selectedReset} />
          <Pagination totalPages={data?.totalPages || 1} totalElements={data?.totalElements || 0} />
        </div>
      </Panel>
      <Footer className={styles.btn_box}>
        <Button size="long" onClick={() => navigate("/contents/challenge")}>
          챌린지 목록보기
        </Button>
      </Footer>

      {isPopupOpen && (
        <div className={styles.popup} ref={ref}>
          <div>
            <img
              src={`/api/file-service/download-file/${fileId}`}
              onClick={() => setIsEnlarged(!isEnlarged)}
              alt="report image"
              className={cx({ [styles.enlarged]: isEnlarged })}
            />
            <DeleteIcon onClick={handleCloseImgPopup} />
          </div>
        </div>
      )}
    </Content>
  );
}
