import { ChangeEventHandler, useEffect, useRef, useState } from "react";

import { useFormContext } from "react-hook-form";
import { Modal } from "comp-lib";

import styles from "styles/pages/appPush/write/registerImg.module.scss";

import ImgModal from "../@shared/Modal";

import { checkFileType } from "utils/appPush";
import { TPushFormValue } from "types/appPush";
import useModal from "hooks/useModal";
import useModalContents from "hooks/useModalContents";

type TProps = {
  onChange: (arg: string) => void;
};

export default function RegisterImg({ onChange }: TProps) {
  const { setValue, getValues } = useFormContext<TPushFormValue>();

  const [fileName, setFileName] = useState("");

  const [isErorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isModalOpen, handleToggleModal] = useModal();
  const [modalContents, setModalContents] = useModalContents();

  const selectedFile = getValues("imageFile");
  const fileRef = useRef<HTMLInputElement>(null);

  const handleChangeImg: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const MAX_SIZE = 300 * 1024; // 300KB
    const file = e.currentTarget.files?.[0];

    if (!file) {
      return;
    }

    if (!checkFileType("image", file.type)) {
      setModalContents({
        title: "이미지 등록 불가",
        description: `파일 확장자를 확인해주세요.\nJPG, JPEG, PNG 파일만 등록 가능합니다.\n\n`,
        btnType: "confirm",
        handleClose: onToggleErrorModal,
      });

      handleToggleModal();
      onToggleErrorModal();

      return;
    }

    if (file.size > MAX_SIZE) {
      setModalContents({
        title: "이미지 등록 불가",
        description: `파일 크기를 확인해주세요.\n해당 파일은 300KB를 초과하였습니다.`,
        btnType: "confirm",
        handleClose: onToggleErrorModal,
      });

      handleToggleModal();
      onToggleErrorModal();

      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (event) => {
      const image = new Image();
      image.src = event.target?.result as string;

      image.onload = () => {
        const { width, height } = image;

        if (width / 2 !== height) {
          setModalContents({
            title: "이미지 등록 불가",
            description: `이미지 비율을 확인해주세요.\n해당 파일은 2:1 비율에 맞지 않습니다.`,
            btnType: "confirm",
            handleClose: onToggleErrorModal,
          });

          handleToggleModal();
          onToggleErrorModal();

          return;
        }

        const fileString = reader.result?.toString() || "";
        setFileName(file.name);
        setValue("imageFile", file);
        onChange(fileString);

        handleToggleModal();
      };
    };
  };

  const handleClickCancel = () => {
    setValue("imageFile", "");
    setValue("imagePath", "");
    setFileName("");
  };

  const openFile = () => {
    if (selectedFile && checkFileType("image", selectedFile.type)) {
      const url = URL.createObjectURL(selectedFile);
      window.open(url, "_blank");
    }
  };

  const onToggleErrorModal = () => {
    setIsErrorModalOpen((prev) => !prev);
  };

  useEffect(() => {
    if (getValues("imageFile") === "") setFileName("");
  }, [getValues("imageFile")]);

  return (
    <div className={styles.wrapper}>
      <button onClick={handleToggleModal} className={styles.file_btn} type="button">
        파일 선택
      </button>

      {!fileName && (
        <span className={styles.notice}>
          JPG, JPEG, PNG 파일만 등록 가능 / 이미지 비율 2:1(ex. 360x180px) 및 용량 300KB 미만
        </span>
      )}

      {fileName && (
        <div className={styles.img_text_box}>
          <button type="button" onClick={openFile}>
            {fileName}
          </button>
          <button type="button" onClick={handleClickCancel}>
            &times;
          </button>
        </div>
      )}

      {isModalOpen && (
        <ImgModal
          title="이미지 업로드 관련 유의사항"
          description={
            <div className={styles.description}>
              <span>*다음의 조건을 충족하지 않을 경우 등록되지 않습니다.</span>
              <span className={styles.redText}>이미지: 2:1 비율(ex.360x180px)</span>
              <span className={styles.redText}>용량: 300KB 미만</span>
            </div>
          }
          confirmBtnName={
            <div className={styles.btn}>
              <label className={styles.label} htmlFor="imageFile">
                확인
              </label>
              <input
                type="file"
                id="imageFile"
                accept=".jpg, .jpeg, .png"
                ref={fileRef}
                className={styles.input}
                onChange={handleChangeImg}
              />
            </div>
          }
          btnType="confirm"
          handleClose={handleToggleModal}
        />
      )}

      {isErorModalOpen && <Modal {...modalContents} />}
    </div>
  );
}
