import { Fragment, ReactNode, useEffect } from "react";
import styles from "styles/pages/contentsManage/challenge/@shared/confirmModal.module.scss";
import { Portal } from "common/other";
import cx from "classnames";

export type TProps = {
  message: string;
  onCancle: () => void;
  onConfirm: () => void;
  confirmText?: string;
  subMessage?: ReactNode;
  isWarning?: boolean;
};

export default function ConfirmModal({
  message,
  subMessage,
  onCancle,
  onConfirm,
  confirmText = "확인",
  isWarning,
}: TProps) {
  useEffect(() => {
    document.body.style.cssText = "overflow: hidden";
    return () => {
      document.body.style.cssText = "overflow: auto";
    };
  }, []);

  return (
    <Portal>
      <div className={styles.overlay}>
        <article className={styles.wrapper}>
          <div className={styles.content}>
            <p className={cx({ [styles.red]: isWarning })}>{message}</p>

            <div className={cx(styles.sub, { [styles.red]: isWarning })}>{subMessage}</div>
          </div>

          <div className={cx(styles.btn_box, { [styles.warn_btn]: isWarning })}>
            <button type="button" name="cancel" onClick={onCancle}>
              취소
            </button>
            <button type="button" name="confirm" onClick={onConfirm}>
              {confirmText}
            </button>
          </div>
        </article>
      </div>
    </Portal>
  );
}
