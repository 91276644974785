import { Link, useLocation } from "react-router-dom";
import { Button, StatusChip } from "comp-lib";
import { BANNER_STATUS_MAP } from "constants/banner";
import { SavedBuilableResource } from "protobuf/OpenApiServerV3";

type TTableData = SavedBuilableResource & {
  no: number;
  status: number;
};

export default function useTableData(data: TTableData[], onRemoveGroup: (no: number) => void) {
  const columns = [
    { text: "NO", width: "62px" },
    { text: "상태", width: "80px" },
    { text: "배너 그룹" },
    { text: "등록수", width: "120px" },
    { text: "전체 노출수", width: "120px" },
    { text: "누적 클릭수", width: "120px" },
    { text: "평균 클릭수", width: "120px" },
    { text: "평균 클릭률", width: "120px" },
    { text: "", width: "100px" },
  ];

  const location = useLocation();

  const rows = data.map((item) => {
    const statusText = BANNER_STATUS_MAP[item.status][0];

    const statusColor = ((status: number) => {
      switch (status) {
        case 0:
          return "gray";
        case 1:
          return "yellow";
        case 2:
          return "blue";
        default:
          return "gray";
      }
    })(item.status);

    const delBtn = ((isFinal: boolean) => {
      if (isFinal) {
        return "";
      }

      return (
        <Button
          key={`del-${item.resourceId}`}
          type="button"
          size="small"
          mode="outlined"
          onClick={() => onRemoveGroup(item.resourceNo)}
        >
          삭제
        </Button>
      );
    })(item.isFinal);

    return [
      item.no,
      <StatusChip key={item.resourceId} text={statusText} color={statusColor} />,
      <Link
        key={`title-${item.resourceId}`}
        to={`detail/${item.resourceNo}${location.search}`}
        state={{ groupBanner: item }}
      >
        {item.title}
      </Link>,
      item.homeBanner?.cards.length || 0,
      "0",
      "0",
      "0",
      "0 %",
      delBtn,
    ];
  });

  return { columns, rows };
}
