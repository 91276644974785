import { AxiosError } from "axios";
import { Axios } from "../base";
import { TCreateManagerStaff, TManagerListResponse, TModifyManagerStaff } from "types/manager";
import ErrorCode from "constants/errorcode";
import ROLE from "constants/role";

export const getManagerList = async (): Promise<TManagerListResponse[]> => {
  try {
    const { data } = await Axios.get(`/api/admin/manager/index`);

    return data.data.staffList;
  } catch (error) {
    if ((error as AxiosError).message === ErrorCode.ERR_NOT_UPDATE) throw Error("허용되지 않은 권한의 사용자 입니다.");
    if ((error as AxiosError).message === ErrorCode.ERR_NOT_FOUND_DATA) throw Error("데이터를 찾을 수 없습니다.");
    throw Error(`사용자 관리 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`);
  }
};

export const getManagerDetail = async (id: string): Promise<TManagerListResponse> => {
  const { data } = await Axios.get(`/api/admin/manager/info?staffId=${id}`);
  return data.data.staff;
};

export const createManagerStaff = async (payload: TCreateManagerStaff): Promise<boolean> => {
  try {
    const { data } = await Axios.post(`/api/admin/manager/create`, {
      ...payload,
      authority: payload.role === ROLE.SNP_ADMIN ? "0" : payload.authority.join(","),
    });
    return data?.result;
  } catch (error) {
    if ((error as AxiosError).message === ErrorCode.ERR_DATA_DUPLICATED)
      throw Error("이미 사용중인 이메일입니다.\n다른 이메일을 사용해주세요");
    if ((error as AxiosError).message === ErrorCode.ERR_NOT_UPDATE) throw Error("허용되지 않은 권한의 사용자 입니다.");
    if ((error as AxiosError).message === ErrorCode.ERR_NOT_FOUND_DATA) throw Error("데이터를 찾을 수 없습니다.");
    throw Error("사용자 정보를 등록하는 중\n문제가 발생하였습니다");
  }
};

export const modifyManagerStaff = async (payload: TModifyManagerStaff): Promise<boolean> => {
  try {
    const { data } = await Axios.post(`/api/admin/manager/modify`, {
      ...payload,
      authority: payload.role === "ADMIN" ? "0" : payload.authority.filter((v) => v !== "0").join(","),
    });

    return data?.data?.result === "true";
  } catch (error) {
    if ((error as AxiosError).message === ErrorCode.ERR_NOT_UPDATE) throw Error("허용되지 않은 권한의 사용자 입니다.");
    if ((error as AxiosError).message === ErrorCode.ERR_NOT_FOUND_DATA) throw Error("데이터를 찾을 수 없습니다.");
    throw Error("사용자 정보를 수정하는 중\n문제가 발생하였습니다");
  }
};

export const deleteManagerStaff = async (id: string): Promise<boolean> => {
  try {
    const { data } = await Axios.get(`/api/admin/manager/delete?staffId=${id}`);

    return data?.data?.result === "true";
  } catch (error) {
    if ((error as AxiosError).message === ErrorCode.ERR_NOT_UPDATE) throw Error("허용되지 않은 권한의 사용자 입니다.");
    if ((error as AxiosError).message === ErrorCode.ERR_NOT_FOUND_DATA) throw Error("데이터를 찾을 수 없습니다.");
    throw Error("사용자를 삭제하는 중\n문제가 발생하였습니다");
  }
};
