import cx from "classnames";
import styles from "styles/pages/contentsManage/challenge/list/table/statusChip.module.scss";
import { CHALLENGE_STATUS_MAP } from "../../constants";

const statusMap: { [key: string]: string } = {
  ...CHALLENGE_STATUS_MAP,
  PROMOTION: "기업", // 기업 생성 챌린지 제목을 위한 상태
};

export default function StatusChip({ status }: { status: string }) {
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.chip, styles[statusMap[status]])}>{statusMap[status]}</div>
    </div>
  );
}
