import { getChallengeDetail } from "api/contentsManage/challenge";
import { useQuery } from "react-query";
import { CHALLENGE_CATEGORY_ENG, DEFAULT_CAUTION } from "../constants";
import queryKey from "constants/queryKey";
import { useParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

export default function useChallengeDefaultValues() {
  const { id } = useParams();
  const { data: detailData, isLoading } = useQuery([queryKey.GET_CHALLENGE_LIST], () => getChallengeDetail(id!), {
    enabled: !!id,
  });

  // File 명을 보여주기 위해 size=0인 임시 파일데이터를 넣어준다.
  const tempFile = detailData?.fileName
    ? new File([new Blob([""], { type: "image/png" })], detailData?.fileName, { type: "image/png" })
    : null;

  const handledAddDate = (day: number, defaultDate?: string) => dayjs(defaultDate).add(day, "day").format("YYYY-MM-DD");

  const modifyPointMultiplier = detailData && detailData.rewardPointPerPerson / detailData.applyPointPerPerson;

  return {
    isModify: !!id,
    isLoading: id ? isLoading : false,
    defaultValues: {
      category: CHALLENGE_CATEGORY_ENG[detailData?.category || "PROUD"],
      title: detailData?.title || "",
      description: detailData?.description || "",
      proofMethod: detailData?.proofMethod || "",
      caution: detailData?.caution || DEFAULT_CAUTION,
      imageId: detailData?.imageId,
      imgFile: detailData?.imageId ? tempFile : null,
      participantsCount: detailData?.maxParticipantsCount || undefined,
      applyPointPerPerson: detailData?.applyPointPerPerson || undefined,
      rewardPointPerPerson: id ? modifyPointMultiplier : detailData?.rewardPointPerPerson || 2,
      startDate: detailData?.startDate || handledAddDate(detailData?.applyEndDate ? 1 : 4, detailData?.applyEndDate),
      endDate: detailData?.endDate || handledAddDate(detailData?.applyEndDate ? 4 : 8, detailData?.applyEndDate),
      applyStartDate: detailData?.applyStartDate || handledAddDate(1),
      applyEndDate: detailData?.applyEndDate || handledAddDate(3),
    },
  };
}
