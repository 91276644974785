import { Button, Content, Footer, LoadingModal, Title } from "comp-lib";
import styles from "styles/pages/contentsManage/challenge/write/challengeWrite.module.scss";
import { WriteContents } from "./WriteContents";
import { WriteProcess } from "./WriteProcess";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { TChallengeRegisterForm } from "types/contentsManage/challenge";
import { useEffect, useState } from "react";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";
import ConfirmModal from "../@shared/ConfirmModal";
import useCreateChallenge from "./useCreateChallenge";
import useChallengeDefaultValues from "./useChallengeDefaultValues";

export default function ChallengeWrite() {
  const navigate = useNavigate();
  const [confirmModalSet, setConfirmModalSet] = useState<"create" | "reset" | null>(null);
  const [notiMessage, setNotiMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const createChallenge = useCreateChallenge();

  const { defaultValues, isLoading: isModifyLoading, isModify } = useChallengeDefaultValues();
  const formMethods = useForm<TChallengeRegisterForm>({ defaultValues });
  const { handleSubmit, resetField, setValue } = formMethods;

  useEffect(() => {
    // 수정 시 기존 데이터 입력
    if (!defaultValues?.title) return;
    const prevValues = Object.entries(defaultValues);
    prevValues.forEach(([key, value]: [any, unknown]) => setValue(key, value));
  }, [isModifyLoading]);

  const onSubmit = (e: TChallengeRegisterForm) => {
    setIsLoading(true);
    createChallenge(e)
      .then(() => {
        setNotiMessage(`챌린지 ${isModify ? "수정" : "등록"}을 완료하였습니다.`);
      })
      .catch((err: Error) => {
        setNotiMessage(err?.message || "그룹루틴 챌린지 등록 중 오류가 발생했습니다.\n다시 시도해 주세요.");
      })
      .finally(() => setIsLoading(false));
  };

  const onError = (e: any) => {
    const errorMessage = [];
    for (let key in e) {
      errorMessage.push(e[key].message);
    }
    setNotiMessage(errorMessage[0]);
  };

  const onCloseNotiModal = () => {
    if (/^챌린지 (등록|수정)을 완료하였습니다\.$/.test(notiMessage)) navigate("/contents/challenge", { replace: true });
    setNotiMessage("");
  };

  const handleResetCaution = () => setConfirmModalSet("reset");

  const confirmModalProps = {
    create: {
      message: "챌린지 등록",
      subMessage: "챌린지를 등록합니다.",
      onConfirm: (e?: TChallengeRegisterForm) => {
        if (!e) throw Error("챌린지 등록 중 오류가 발생했습니다.\n챌린지 등록에 필요한 데이터가 전달되지 않았습니다.");
        onSubmit(e);
      },
    },
    reset: {
      message: "초기화 하기",
      subMessage: "수정된 내용이 초기화되며\n이전 기본 내용으로 복원됩니다.",
      onConfirm: () => {
        resetField("caution");
        setConfirmModalSet(null);
      },
    },
  };

  return (
    <FormProvider {...formMethods}>
      <Content as="form" className={styles.wrapper} onSubmit={handleSubmit(onSubmit, onError)} isWithFooter>
        <Title text={`그룹루틴 챌린지 ${isModify ? "수정" : "등록"}`} />
        <WriteContents resetCaution={handleResetCaution} />
        <Title text="진행 설정" />
        <WriteProcess />
        <Footer className={styles.btn_box}>
          <Button size="long" mode="lineGray" type="button" onClick={() => navigate(-1)}>
            취소
          </Button>
          <Button size="long" type="submit">
            {isModify ? "수정" : "등록"}
          </Button>
        </Footer>
      </Content>
      {confirmModalSet !== null && (
        <ConfirmModal onCancle={() => setConfirmModalSet(null)} {...confirmModalProps[confirmModalSet]} />
      )}
      {notiMessage && <NotiModal message={notiMessage} onClose={onCloseNotiModal} />}
      {(isLoading || isModifyLoading) && <LoadingModal />}
    </FormProvider>
  );
}
