import { Fragment, useEffect } from "react";
import Portal from "../Portal";
import styles from "./confirmModal.module.scss";

export type TProps = {
  message: string;
  subMessage?: string;
  onCancle: () => void;
  onConfirm: () => void;
  confirmText?: string;
};

export default function ConfirmModal({ message, subMessage, onCancle, onConfirm, confirmText = "확인" }: TProps) {
  useEffect(() => {
    document.body.style.cssText = "overflow: hidden";
    return () => {
      document.body.style.cssText = "overflow: auto";
    };
  }, []);

  return (
    <Portal>
      <div className={styles.overlay}>
        <article className={styles.wrapper}>
          <div className={styles.content}>
            <p>
              {message.split("\n").map((line, index) => (
                <Fragment key={`msg-${index + 1}`}>
                  {line}
                  {index !== message.split("\n").length - 1 && <br />}
                </Fragment>
              ))}
            </p>

            <p className={styles.sub}>
              {subMessage?.split("\n").map((line, index) => (
                <Fragment key={`msg-${index + 1}`}>
                  {line}
                  {index !== subMessage.split("\n").length - 1 && <br />}
                </Fragment>
              ))}
            </p>
          </div>

          <div className={styles.btn_box}>
            <button type="button" name="cancel" onClick={onCancle}>
              취소
            </button>
            <button type="button" name="confirm" onClick={onConfirm}>
              {confirmText}
            </button>
          </div>
        </article>
      </div>
    </Portal>
  );
}
