import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";

import { setB2BEnv, setPrimaryColor } from "utils";
import { App } from "./App";

// import { worker } from "mock/browser";

import "styles/config/index.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 0,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

setB2BEnv(); // B2B URL 설정
setPrimaryColor();

// if (window.location.hostname === "localhost") {
//   worker.start({
//     onUnhandledRequest: "bypass",
//   });
// }

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>,
);
