import styles from "styles/pages/notice/info.module.scss";
import { Title } from "common/other";
import { DetailNav, invisiblePeriod, moveDstArr, noticeTypeList, platform, priority } from "constants/notice";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getNoticeInfo, setNoticeActivated } from "api/admin/notice";
import dayjs from "dayjs";
import { getErrorMeg } from "api/errorHandling";
import { MiddlewareError } from "utils/errorPackage";
import { ErrorModal, LoadingModal } from "common/modal";
import { Button, Footer } from "comp-lib";

export default function NoticeDetail() {
  const { id } = useParams();

  const navigate = useNavigate();

  const { data, error, isError, isLoading, isFetching } = useQuery(["getNoticeInfo", Number(id)], async () =>
    getNoticeInfo(Number(id)),
  );

  const activatedMutation = useMutation(setNoticeActivated);

  const onActivatedHandler = () => {
    activatedMutation.mutate(Number(id), {
      onSuccess: (res) => {
        if (res) navigate(0);
      },
    });
  };

  const getMoveTo = (moveTo: string) => {
    if (moveTo === "") {
      return {
        moveType: "",
        moveDst: "",
      };
    }

    const { moveType, moveDst } = JSON.parse(moveTo);

    return {
      moveType: moveType === "TAB" ? "탭" : "URL",
      moveDst: moveType === "TAB" ? moveDstArr.filter((move) => move.value === moveDst)[0].label : moveDst,
    };
  };

  if (isLoading || isFetching) return <LoadingModal />;

  if (isError) {
    return (
      <ErrorModal
        error={error}
        message={getErrorMeg((error as MiddlewareError).message)}
        onAlertHandler={() => navigate("/message/homePopup")}
      />
    );
  }

  return (
    <article>
      {activatedMutation.isError && (
        <ErrorModal
          error={activatedMutation.error}
          message={getErrorMeg((activatedMutation.error as MiddlewareError).message)}
          onAlertHandler={() => navigate("/message/homePopup")}
        />
      )}
      {activatedMutation.isLoading && <LoadingModal />}

      <Title text="상세 정보" location={DetailNav} />

      <section className={styles.wrapper}>
        <p className={styles.title}>
          {data?.title}
          <span>신청일 {dayjs(data!.createdAt).format("YYYY.MM.DD")}</span>
        </p>

        {data?.imageName && (
          <div className={styles.info}>
            <p>이미지</p>
            <p>
              <a href={data.imageUrl} target="_blank" rel="noreferrer">
                {data?.imageName}
              </a>
            </p>
          </div>
        )}

        {data?.noticeType === "system" && (
          <div className={styles.info}>
            <p>내용</p>
            <p>{data.body}</p>
          </div>
        )}

        <div className={styles.info}>
          <p>노출 기간</p>
          <p>
            {`${dayjs(data!.notBefore).format("YYYY.MM.DD HH:mm:ss")} ~ ${dayjs(data!.notAfter).format(
              "YYYY.MM.DD HH:mm:ss",
            )}`}
          </p>
        </div>

        <div className={styles.info}>
          <p>OS 타겟팅</p>
          <p>{platform.filter((p) => p.value === data?.platform)[0].label}</p>
        </div>

        <div className={styles.info}>
          <p>공지 유형</p>
          <p>{noticeTypeList.filter((type) => type.value === data?.noticeType)[0].label}</p>
        </div>

        {data?.noticeType === "event" && (
          <div className={styles.info}>
            <p>이동 유형</p>
            <p>{getMoveTo(data.moveTo || "").moveType}</p>
          </div>
        )}

        {data?.noticeType === "event" && (
          <div className={styles.info}>
            <p>이동 위치</p>
            {getMoveTo(data.moveTo || "").moveType === "URL" ? (
              <p>
                <a href={getMoveTo(data.moveTo || "").moveDst} target="_blank" rel="noreferrer">
                  {getMoveTo(data.moveTo || "").moveDst}
                </a>
              </p>
            ) : (
              <p>{getMoveTo(data.moveTo || "").moveDst}</p>
            )}
          </div>
        )}

        {data?.noticeType === "event" && (
          <div className={styles.info}>
            <p>노출 순서</p>
            <p>{priority.filter((p) => p.value === data?.priority)[0]?.label || data?.priority}</p>
          </div>
        )}

        {data?.noticeType !== "system" && (
          <div className={styles.info}>
            <p>보지 않기 유형</p>
            <p>
              {invisiblePeriod.filter((i) => i.value === `vP_${data?.invisiblePeriod}`)[0]?.label ||
                data?.invisiblePeriod}
            </p>
          </div>
        )}
      </section>

      <Footer>
        {data?.status === 0 ? (
          <>
            <Button size="long" mode="outlined" onClick={() => navigate(-1)}>
              취소
            </Button>
            <Button type="submit" size="long" onClick={onActivatedHandler}>
              승인
            </Button>
          </>
        ) : (
          <Button size="long" onClick={() => navigate(-1)}>
            확인
          </Button>
        )}
      </Footer>
    </article>
  );
}
