import { useState } from "react";
import { useWatch } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { ArrowLeftIcon } from "assets/svgs/skeleton";

import { contPrFormAtom } from "recoil/vote";

import { CheckCirDisIcon, RadioDisIcon, RadioEmtIcon } from "assets/svgs/vote";
import MobileView from "./MobileView";
import styles from "styles/pages/contentsManage/vote/write/mobile/mobileViewMultiQue.module.scss";

type TForm = {
  question: {
    pageId: string;
    contents: {
      title: string;
      correct_ans: string;
      queType: string;
      answerList: {
        value: string;
      }[];
      descriptive: string;
    }[];
  }[];
};

export default function MobileViewMultiQue() {
  const { title, perPersonPnt, content, filter, imgFileString, startDate, endDate } = useRecoilValue(contPrFormAtom);
  const question: TForm["question"] = useWatch({
    name: "question",
  });

  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [startIdx, setStartIdx] = useState(1);
  const maxPageNum = question.length;

  const handleNextPage = () => {
    const newPageNum = currentPageNum + 1;

    if (newPageNum > maxPageNum) {
      return;
    }
    setStartIdx((prev) => prev + question[currentPageNum - 1].contents.length);
    setCurrentPageNum(newPageNum);
  };

  const handlePrevPage = () => {
    const newPageNum = currentPageNum - 1;

    if (newPageNum < 1) {
      return;
    }

    setStartIdx((prev) => prev - question[newPageNum - 1].contents.length);
    setCurrentPageNum(newPageNum);
  };

  return (
    <MobileView
      data={{
        title,
        content,
        imgFileString,
        startDate,
        endDate,
        filter,
        perPersonPnt,
      }}
    >
      <ul className={styles.que_box}>
        {question[currentPageNum - 1].contents.map((item, index) => {
          const correctNumber = item.queType === "정답 복수 선택형" && item.correct_ans?.match(/,/g)?.length;
          const answerNumber = correctNumber ? `정답개수 ${correctNumber + 1}개` : "";
          return (
            <li key={`mobile-que-${index + 1}`}>
              <div className={styles.question}>
                {`Q${startIdx + index}`}. {item.title || "질문을 입력해 주세요."}
                <span>{answerNumber}</span>
              </div>

              {item.queType === "주관식 단답형" && (
                <div className={styles.descriptive}>{item.descriptive || `답변을 작성해 주세요`}</div>
              )}

              {item.queType !== "주관식 단답형" && (
                <ul className={styles.answer_box}>
                  {item.answerList.map((subItem, subIndex) => {
                    return (
                      <li key={`mobile-answer-${subIndex + 1}`} className={styles.answer}>
                        {item.queType === "단일 선택형" && <RadioDisIcon />}
                        {item.queType === "복수 선택형" && <CheckCirDisIcon />}
                        {item.queType === "정답 단일 선택형" && <RadioEmtIcon />}
                        {item.queType === "정답 복수 선택형" && <CheckCirDisIcon />}

                        <span>{subItem.value || `선택지 ${subIndex + 1} 입력해 주세요.`}</span>
                      </li>
                    );
                  })}
                </ul>
              )}

              <hr key={`mobile-hr-${index + 1}`} />
            </li>
          );
        })}
      </ul>

      {maxPageNum > 1 && (
        <div className={styles.pagination}>
          <button type="button" onClick={handlePrevPage}>
            <ArrowLeftIcon />
          </button>

          <span className={styles.txt}>
            {currentPageNum}/{maxPageNum}
          </span>

          <button type="button" onClick={handleNextPage}>
            <ArrowLeftIcon className={styles.rightIcon} />
          </button>
        </div>
      )}
    </MobileView>
  );
}
