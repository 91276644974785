import { Axios } from "api/base";
import { isAxiosError } from "axios";
import dayjs from "dayjs";
import { challengeNavStatusMap } from "pages/contentsManage/challenge/constants";
import {
  TChallengeAccuseListRes,
  TChallengeCountResponse,
  TChallengeImage,
  TChallengeImageList,
  TChallengeListRes,
  TCreateChallengeRequest,
  TCreateChallengeResponse,
  TCreateChallengeStatistics,
  TSelectedReport,
} from "types/contentsManage/challenge";

// 그룹루틴 챌린지 목록 조회
export const getChallengeList = async (searchParam?: URLSearchParams): Promise<TChallengeListRes> => {
  try {
    const params = {
      page: Number(searchParam?.get("pageNum")) >= 1 ? Number(searchParam?.get("pageNum")) - 1 : 0,
      size: Number(searchParam?.get("size")) || 10,
      keyword: searchParam?.get("searchKeyword") || undefined,
      searchStartDate: searchParam?.get("from") ? dayjs(searchParam?.get("from")).format("YYYY-MM-DD") : undefined,
      searchEndDate: searchParam?.get("to") ? dayjs(searchParam?.get("to")).format("YYYY-MM-DD") : undefined,
      statusList: challengeNavStatusMap[searchParam?.get("status") || "all"],
      sort: "startDate,createdAt,DESC",
    };
    const { data } = await Axios.get(`/api/challenge/challenges`, {
      params,
    });
    return data.data.groupRoutineChallengeListItem;
  } catch {
    throw Error("그룹루틴 챌린지 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};
// 그룹루틴 챌린지 상세조회
export const getChallengeDetail = async (id: string): Promise<TCreateChallengeResponse> => {
  try {
    const { data } = await Axios.get(`/api/challenge/challenges/${id}`);
    return data.data;
  } catch {
    throw Error("그룹루틴 챌린지 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};
// 그룹루틴 챌린지 갯수 조회
export const getChallengeStatusCount = async (searchParam?: URLSearchParams): Promise<TChallengeCountResponse> => {
  try {
    const params = {
      keyword: searchParam?.get("searchKeyword") || undefined,
      searchStartDate: searchParam?.get("from") ? dayjs(searchParam?.get("from")).format("YYYY-MM-DD") : undefined,
      searchEndDate: searchParam?.get("to") ? dayjs(searchParam?.get("to")).format("YYYY-MM-DD") : undefined,
    };
    const { data } = await Axios.get(`/api/challenge/challenges/status-count`, {
      params: params,
    });
    return data.data;
  } catch {
    throw Error("그룹루틴 챌린지 갯수 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};

// 그룹루틴 챌린지 진행현황 조회
export const getChallengeStatistics = async (id: string): Promise<TCreateChallengeStatistics> => {
  try {
    const { data } = await Axios.get(`/api/challenge/challenges/${id}/statistics`);
    return data.data;
  } catch {
    throw Error("그룹루틴 챌린지 진행현황 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};

// 그룹루틴 챌린지 생성
export const createChallenge = async (payload: TCreateChallengeRequest) => {
  try {
    const { data } = await Axios.post(`/api/challenge/challenges`, {
      ...payload,
    });
    return data.data;
  } catch {
    throw Error("그룹루틴 챌린지 생성 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};

// 그룹루틴 챌린지 수정
export const modifyChallenge = async ({ id, payload }: { id: string; payload: TCreateChallengeRequest }) => {
  try {
    const { data } = await Axios.put(`/api/challenge/challenges/${id}`, {
      ...payload,
    });
    return data.data;
  } catch {
    throw Error("그룹루틴 챌린지 수정 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};

// 그룹루틴 챌린지 상태 변경 (정산, 삭제)
export const putStatusChallenge = async ({ id, status }: { id: string; status: "CALCULATED" | "DELETED" }) => {
  try {
    const { data } = await Axios.put(`/api/challenge/challenges/${id}/status/${status}`);
    return data.data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.data.message) throw Error(error.response?.data.message);
    throw Error(
      `그룹루틴 챌린지 ${status === "CALCULATED" ? "정산" : "삭제"} 중 오류가 발생했습니다.\n다시 시도해 주세요.`,
    );
  }
};

// 그룹루틴 챌린지 종료
export const postRevokeChallenge = async ({ id, reason }: { id: string; reason?: string }) => {
  try {
    const { data } = await Axios.put(`/api/challenge/challenges/${id}/revoke`, {
      reason,
    });
    return data.data;
  } catch {
    throw Error("그룹루틴 챌린지 종료 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};

// 그룹루틴 챌린지별 진행 내역 이미지 보기
export const getChallengeImageList = async (id: string): Promise<TChallengeImageList[]> => {
  try {
    const { data } = await Axios(`/api/challenge/challenges/${id}/proof-history`);
    return data.data;
  } catch {
    throw Error("그룹루틴 챌린지 이미지 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};

// 그룹루틴 챌린지 일별 진행 내역 이미지 보기
export const getChallengeImage = async ({
  id,
  proofDate,
}: {
  id: string;
  proofDate: string;
}): Promise<TChallengeImage[]> => {
  try {
    const { data } = await Axios(`/api/challenge/challenges/${id}/proof-image`, {
      params: {
        proofDate,
      },
    });
    return data.data;
  } catch {
    throw Error("그룹루틴 챌린지 이미지 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};

/** 그룹루틴 챌린지 이미지 신고관리 */

// 그룹루틴 챌린지 이미지 신고목록 조회
export const getChallengeReportList = async (searchParam?: URLSearchParams): Promise<TChallengeAccuseListRes> => {
  try {
    const params = {
      challengeId: searchParam?.get("id") || undefined,
      page: Number(searchParam?.get("pageNum")) >= 1 ? Number(searchParam?.get("pageNum")) - 1 : 0,
      size: Number(searchParam?.get("size")) || 10,
      sort: "accusedAt,DESC",
    };
    const { data } = await Axios.get(`/api/challenge/accuses`, { params });
    return data.data.challengeAccuseList;
  } catch {
    throw Error("이미지 신고목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};

// 그룹루틴 챌린지 이미지 상태 변경
export const putChallengeReport = async ({
  status,
  list,
}: {
  status: "cancel" | "accept" | "block";
  list: TSelectedReport;
}) => {
  try {
    const { data } = await Axios.put(
      `/api/challenge/accuses/${status === "block" ? "BLOCK_MEMBER" : `${status.toUpperCase()}_PROOF`}`,
      {
        proofIdList: list,
      },
    );
    return data.data;
  } catch {
    throw Error("신고된 이미지 처리 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};
