import cx from "classnames";
import styles from "styles/pages/contentsManage/vote/statusChip.module.scss";

type TStatusChipProps = {
  status: number;
  type: "content" | "spam";
};

const voteStatusMap: { [key: number]: string[] } = {
  2: ["예정", "notStarted"],
  3: ["진행중", "inProgress"],
  4: ["진행중", "inProgress"],
  7: ["종료", "ended"],
  12: ["대기", "pending"],
  13: ["삭제완료", "approved"],
  14: ["삭제 미승인", "nonApproved"],
};

export default function StatusChip({ status, type }: TStatusChipProps) {
  const statusFilter = () => {
    return type === "spam" ? status + 10 : status;
  };

  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.chip, styles[`status${statusFilter()}`])}>{voteStatusMap[statusFilter()][0]}</div>
    </div>
  );
}
