import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import styles from "styles/pages/mydInquiry/detail/mydInquiryJiraInfo.module.scss";

import { ErrorModal, Modal, Panel } from "comp-lib";

import useModal from "hooks/useModal";
import InfoItem from "common/other/InfoItem";

import { createIssue } from "api/mydInquiry";
import { TMydInquiryDetail, TMydInquiryJiraIssue } from "types/mydInquiry";
import queryKey from "constants/queryKey";
import useModalContents from "hooks/useModalContents";
import { MODAL_CONTENTS } from "constants/mydInquiry";

type TProps = {
  inquiryData: TMydInquiryDetail;
  jiraData?: TMydInquiryJiraIssue;
};

export default function MydInquiryJiraInfo({ inquiryData, jiraData }: TProps) {
  const queryClient = useQueryClient();

  const [isModalOpen, onToggleModal] = useModal();
  const [modalContents, setModalContents] = useModalContents();
  const { id } = useParams();

  const isDoneInquiry = inquiryData?.status === "DONE";

  const { mutate: createIssueMuta, error: createJiraError } = useMutation(
    (arg: { id: number; title: string; description: string }) => createIssue(arg),
    {
      onSuccess: () => {
        setModalContents({
          ...MODAL_CONTENTS.JIRA_CREATE_CONFIRM,
          handleClose: () => {
            onToggleModal();
            queryClient.invalidateQueries(queryKey.GET_MYD_INQUIRY_JIRA_ISSUE);
            queryClient.invalidateQueries(queryKey.GET_MYD_INQUIRY_DETAIL);
          },
        });

        onToggleModal();
      },
    },
  );

  const handleJiraCreate = () => {
    const jiraIssueInfo = {
      id: Number(id),
      title: inquiryData.title,
      description: inquiryData.contents,
    };

    createIssueMuta(jiraIssueInfo);
  };

  const handleJiraUpdate = () => {
    queryClient.invalidateQueries(queryKey.GET_MYD_INQUIRY_JIRA_ISSUE);
  };

  return (
    <>
      <Panel className={styles.panel}>
        {jiraData && (
          <InfoItem
            title="JIRA 상태"
            value={
              <div className={styles.btnBox}>
                {jiraData && jiraData?.status}
                {jiraData.status !== "완료" && (
                  <button type="button" className={styles.lineBtn} onClick={handleJiraUpdate}>
                    JIRA 상태 업데이트
                  </button>
                )}
              </div>
            }
          />
        )}

        <InfoItem
          isLast
          title="JIRA 이슈 생성"
          value={
            jiraData ? (
              <button type="button" className={styles.urlBtn} onClick={() => window.open(jiraData.url, "_blank")}>
                {jiraData.url}
              </button>
            ) : (
              <div className={styles.btnBox}>
                없음
                {!isDoneInquiry && (
                  <button type="button" className={styles.lineBtn} onClick={handleJiraCreate}>
                    JIRA 이슈 생성하기
                  </button>
                )}
              </div>
            )
          }
        />
      </Panel>

      {isModalOpen && <Modal {...modalContents} />}

      {createJiraError && (
        <ErrorModal error={createJiraError} message={`JIRA 이슈 생성에 실패했습니다.\n다시 시도해주세요`} />
      )}
    </>
  );
}
