import { MouseEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";

import SidebarLayout from "./mobile/SidebarLayout";
import MobileViewQue from "./mobile/MobileViewQue";
import CustomDropdown from "pages/contentsManage/vote/@shared/form/CustomDropdown";
import ConfirmModal from "pages/contentsManage/vote/@shared/modal/ConfirmModal";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";
import { formatPollRegistForm, formatSingleQuestionAnswer } from "./pollFormatter";

import { contPrFormAtom } from "recoil/vote";
import { createPoll } from "api/contentsManage/poll";
import { uploadImgApi } from "api/common";
import { useAccessRole } from "hooks/useAccessRole";
import { PollRegistRequest } from "protobuf/OpenApiServerV3";
import { QUE_CHOICE_MAP } from "constants/vote";
import { TQuestionForm } from "types/contentsManage/vote";
import VoteTitle from "../@shared/VoteTitle";
import { uint8ArrayToString } from "utils/uint8Array";
import { Footer, LoadingModal } from "comp-lib";

import { XIcon, RadioDisIcon, CheckCirDisIcon, RadioEmtIcon, RadioSkyIcon, CheckCirSkyIcon } from "assets/svgs/vote";
import styles from "styles/pages/contentsManage/vote/write/question.module.scss";

export default function Question() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [warnModalMsg, setWarnModalMsg] = useState("");
  const [isNotiModal, setIsNotiModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const contPrForm = useRecoilValue(contPrFormAtom);
  const { data: accessRole } = useAccessRole();
  const { mutate } = useMutation((data: PollRegistRequest) => createPoll(data), {
    onSuccess: () => setIsNotiModal(true),
    onError: (err: Error) => setWarnModalMsg(err?.message || "투표 생성 중 오류가 발생했습니다"),
    onMutate: () => {
      setIsLoading(true);
      setIsConfirmModal(false);
    },
    onSettled: () => setIsLoading(false),
  });

  const { mutateAsync: uploadImgMutation } = useMutation(uploadImgApi);

  const formMethods = useForm<TQuestionForm>({
    defaultValues: {
      question: "",
      answerList: [{ value: "" }, { value: "" }],
      descriptive: "",
      correct_ans: "",
      queType: "단일 선택형",
    },
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "answerList",
  });

  // 저장된 질문설정 데이터를 불러온다.
  useEffect(() => {
    if (!state || state.multiYn) return;

    const prevQuestions = JSON.parse(uint8ArrayToString(state.questions));
    const prevQuestion = prevQuestions[0][0];
    const prevQueType = Object.keys(QUE_CHOICE_MAP)[Number(prevQuestion?.question_type) - 1];

    reset({
      question: prevQuestion.title,
      answerList: prevQuestion.ans.map((v: string) => {
        return { value: v };
      }),
      descriptive: prevQueType === "주관식 단답형" ? prevQuestion.ans[0] : "",
      correct_ans: prevQuestion?.correct_ans && prevQuestion.correct_ans,
      queType: prevQueType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleCustomDropdown = (value: string) => {
    setValue("correct_ans", "");
    setValue("queType", value);
  };

  const onSubmit = () => {
    if (getValues("queType") === "정답 단일 선택형" && getValues("correct_ans") === "") {
      setWarnModalMsg("반드시 1개의 정답을 지정해주세요");
    } else if (getValues("queType") === "정답 복수 선택형" && getValues("correct_ans").split(",").length < 2) {
      setWarnModalMsg("반드시 2개 이상의 정답을 지정해주세요");
    } else setIsConfirmModal(true);
  };

  const handleAddField = () => {
    return fields.length < 30 ? append({ value: "" }) : setWarnModalMsg("선택지는 30개를 초과할 수 없습니다");
  };

  const handlePrevNav = () => {
    const questionAnswer = formatSingleQuestionAnswer({ question: getValues() });

    const pollRegistForm = formatPollRegistForm({
      form: contPrForm,
      state,
      questionAnswer,
      correctAns: /정답 (단일|복수) 선택형/.test(getValues("queType"))
        ? JSON.stringify({ ans: questionAnswer[0][0].correct_ans, point: String(contPrForm.perPersonPnt) })
        : "",
    });
    navigate(`/contents/vote/writeContPr${state ? `?pollId=${state.pollId}` : ""}`, { state: pollRegistForm });
  };

  const handleAnsRemove = (index: number) => {
    if (index <= 1) return; // 선택지 최소 2개 이상
    const correctAns = getValues("correct_ans").split(",");
    const isCorrectAns = correctAns.includes(String(index + 1));
    // 정답인 선택지라면 정답목록에서 해당 선택지 삭제
    isCorrectAns && correctAns.splice(correctAns.indexOf(String(index + 1)), 1);
    const targetIndex = isCorrectAns ? index + 1 : index;
    const calculatedCorrectAns = correctAns.map((v) => (Number(v) > targetIndex ? String(Number(v) - 1) : v));
    remove(index);
    setValue("correct_ans", calculatedCorrectAns.join(","));
  };

  // 정답 선택형
  const handleSetAnswer = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget.id;
    if (getValues("queType") === "정답 단일 선택형") return setValue("correct_ans", target);
    const prev = getValues("correct_ans");
    const arr = prev ? prev.split(",") : [];
    arr.includes(target) ? arr.splice(arr.indexOf(target), 1) : arr.push(target);
    const result = arr.sort((a, b) => Number(a) - Number(b)).join(",");
    return setValue("correct_ans", result);
  };

  const handleModalClose = () => setIsConfirmModal(false);

  const handleModalConfirm = async () => {
    const questionAnswer = formatSingleQuestionAnswer({ question: getValues() });
    const { imgFileString } = contPrForm;

    const pollRegistForm = formatPollRegistForm({
      form: contPrForm,
      state,
      questionAnswer,
      correctAns: /정답 (단일|복수) 선택형/.test(getValues("queType"))
        ? JSON.stringify({ ans: questionAnswer[0][0].correct_ans, point: String(contPrForm.perPersonPnt) })
        : "",
    });

    // upload new image file
    if (typeof imgFileString !== "string") {
      if (!accessRole?.token) return;

      const body = { did: accessRole.did, token: accessRole.token, file: imgFileString };
      await uploadImgMutation(body, {
        onSuccess: (el) => {
          mutate({ ...pollRegistForm, imgLink: el.fullUrlPath });
        },
        onError: () => {
          setWarnModalMsg(`이미지 등록 중 오류가 발생했습니다.\n이미지 파일을 다시 등록해주세요.`);
        },
      });
    } else {
      mutate(pollRegistForm);
    }
  };

  const handleNotiModalClose = () => {
    setIsNotiModal(false);
    navigate("/contents/vote");
  };
  const handleWarnModalMsgClose = () => {
    setWarnModalMsg("");
    setIsConfirmModal(false);
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <VoteTitle text="질문 설정" page={3} />

        <div className={styles.content}>
          <div className={styles.sub_title}>Q1</div>

          <div className={styles.que}>
            <input
              type="text"
              placeholder="질문을 입력해 주세요 (최대 100자)"
              maxLength={100}
              className={styles.que_input}
              aria-invalid={errors.question ? "true" : "false"}
              {...register("question", {
                validate: { required: (v) => !!v.trim().length },
              })}
            />

            <CustomDropdown
              dataList={Object.keys(QUE_CHOICE_MAP)}
              value={watch("queType")}
              size="small"
              onChange={handleCustomDropdown}
            />
          </div>

          {watch("queType") === "주관식 단답형" && (
            <>
              <textarea
                className={styles.descriptive}
                rows={3}
                aria-invalid={errors.descriptive ? "true" : "false"}
                maxLength={50}
                placeholder={`답변창에 표시될 가이드를 작성해 주세요 (최대 50자)\n미작성 시 “답변을 입력해 주세요 (글자수 최대 20자 이하)”가 보여집니다`}
                {...register("descriptive", {
                  maxLength: {
                    value: 50,
                    message: "최대 50자까지 입력해주세요.",
                  },
                })}
              />
              {errors.descriptive?.type === "maxLength" && (
                <p className={styles.errorMeg}>{errors.descriptive.message}</p>
              )}
            </>
          )}

          {getValues("queType") !== "주관식 단답형" && (
            <ul className={styles.answer}>
              {fields.map((item, index) => {
                const targetId = String(index + 1);
                return (
                  <li key={item.id}>
                    {getValues("queType") === "단일 선택형" && <RadioDisIcon />}
                    {getValues("queType") === "복수 선택형" && <CheckCirDisIcon />}
                    {getValues("queType") === "정답 단일 선택형" && (
                      <button onClick={handleSetAnswer} type="button" id={targetId} tabIndex={-1}>
                        {watch("correct_ans").split(",").includes(targetId) ? <RadioSkyIcon /> : <RadioEmtIcon />}
                      </button>
                    )}
                    {getValues("queType") === "정답 복수 선택형" && (
                      <button onClick={handleSetAnswer} type="button" id={targetId} tabIndex={-1}>
                        {watch("correct_ans").split(",").includes(targetId) ? <CheckCirSkyIcon /> : <CheckCirDisIcon />}
                      </button>
                    )}

                    <input
                      type="text"
                      placeholder={`선택지 ${index + 1} 입력해 주세요. (최대 100자)`}
                      className={styles.que_input}
                      autoComplete="off"
                      maxLength={100}
                      aria-invalid={errors?.answerList?.[index]?.value ? "true" : "false"}
                      {...register(`answerList.${index}.value` as const, {
                        validate: { required: (v) => !!v.trim().length },
                      })}
                    />

                    <button type="button" name="del" onClick={() => handleAnsRemove(index)} tabIndex={-1}>
                      <XIcon />
                    </button>
                  </li>
                );
              })}

              <li>
                {getValues("queType") === "단일 선택형" && <RadioDisIcon />}
                {getValues("queType") === "복수 선택형" && <CheckCirDisIcon />}
                {/정답 (단일|복수) 선택형/.test(getValues("queType")) && <RadioEmtIcon />}

                <button type="button" className={styles.que_input} onClick={handleAddField}>
                  선택지 추가
                </button>
              </li>
            </ul>
          )}
        </div>
      </form>

      <Footer className={styles.btn_box}>
        <button type="button" className={styles.out} onClick={handlePrevNav}>
          이전으로
        </button>
        <button type="submit" className={styles.next} onClick={handleSubmit(onSubmit)}>
          등록
        </button>
      </Footer>

      <SidebarLayout>
        <MobileViewQue />
      </SidebarLayout>

      {isConfirmModal && (
        <ConfirmModal
          message={`[1문항] ${contPrForm.title}`}
          subMessage="콘텐츠가 마이디 투표에 등록됩니다."
          onCancle={handleModalClose}
          onConfirm={handleModalConfirm}
        />
      )}

      {isNotiModal && <NotiModal onClose={handleNotiModalClose} message="등록되었습니다." />}
      {warnModalMsg && <NotiModal message={warnModalMsg} onClose={handleWarnModalMsgClose} />}
      {isLoading && <LoadingModal />}
    </FormProvider>
  );
}
