import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import RenewalSideMenu from "pages/RenewalSideMenu";
import { Modal } from "comp-lib";

import { LogoutIcon, MyPageIcon } from "assets/svgs/skeleton";
import styles from "styles/pages/adminOutlet.module.scss";
import { Axios } from "api/base";
import { setToken } from "utils/access";

type TProps = {
  children: ReactNode;
};

export default function Layout({ children }: TProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLogout, setIsLogout] = useState(false);

  const handleLogout = async () => {
    queryClient.clear();
    await Axios.post("/api/auth/sign-out");

    setToken(null);
    navigate("/login", { replace: true });
  };

  return (
    <>
      {
        <div className={styles.wrapper}>
          {/** 2024 개편 어드민 메뉴 사이드바 */}
          <RenewalSideMenu />

          <div className={styles.content}>
            <header>
              <button type="button" onClick={() => navigate("/mypage")}>
                마이페이지 <MyPageIcon />
              </button>

              <button type="button" onClick={() => setIsLogout(true)}>
                로그아웃 <LogoutIcon />
              </button>
            </header>

            <main>{children}</main>
          </div>

          {isLogout && (
            <Modal
              description="로그아웃 하시겠습니까?"
              btnType="submit"
              submitBtnName="예"
              cancelBtnName="아니오"
              handleClose={() => setIsLogout(false)}
              handleSubmit={handleLogout}
            />
          )}
        </div>
      }
    </>
  );
}
