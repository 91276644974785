import { Dispatch, SetStateAction } from "react";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  MouseSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import QuickMenuItem from "./QuickMenuItem";
import { TQuickMenuItem } from "types/quickMenu";
import styles from "styles/pages/quickMenu/quickMenuList.module.scss";

type TProps = {
  items: TQuickMenuItem[];
  setItems: Dispatch<SetStateAction<TQuickMenuItem[]>>;
  menuNameArr: string[];
};

export default function QuickMenuDnd({ items, setItems, menuNameArr }: TProps) {
  const { setNodeRef } = useDroppable({ id: "quickMenuList" });
  const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }));
  const menuNoList = items.map((item) => item.menuNo);

  // 메뉴 순서 변경 이벤트
  const moveItem = (active: DragEndEvent["active"], over: DragEndEvent["over"]) => {
    if (!over) return;

    const activeData = active.data.current;
    const overData = over.data.current;

    if (!activeData || !overData) return;

    const activeIndex = activeData.sortable.index;
    const overIndex = overData.sortable.index;

    setItems((items: TQuickMenuItem[]) => {
      const newItems = arrayMove(items, activeIndex, overIndex);
      return newItems.map((item, idx) => ({ ...item, seq: idx + 1 }));
    });
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    moveItem(active, over);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      moveItem(active, over);
    }
  };

  return (
    <DndContext sensors={sensors} onDragOver={handleDragOver} onDragEnd={handleDragEnd}>
      <SortableContext items={menuNoList} strategy={rectSortingStrategy}>
        <div className={styles.dnd_container} ref={setNodeRef}>
          {items.map((row, i) => (
            <QuickMenuItem key={row.menuNo} index={i} row={row} setItems={setItems} menuNameArr={menuNameArr} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}
