import { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "styles/common/table/tableTop/Search.module.scss";
import searchImg from "assets/imgs/icon/icon-search.png";

type TProps = {
  placeholder?: string;
  searchIcon?: boolean;
};

export default function Search({ placeholder = "제목을 입력하세요", searchIcon = true }: TProps) {
  const [value, setValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get("keyword") || "";

  useEffect(() => {
    setValue(keyword);
  }, [keyword]);

  const handleChangeSearch: ChangeEventHandler<HTMLInputElement> = (e) => setValue(e.currentTarget.value);
  const handleSubmitSearch: FormEventHandler = (e) => {
    e.preventDefault();
    const newSearchParams = new URLSearchParams();
    if (value !== "") {
      newSearchParams.set("keyword", value);
    }
    setSearchParams(newSearchParams);
  };

  return (
    <form className={styles.wrapper} onSubmit={handleSubmitSearch}>
      <div className={styles["input-box"]}>
        {searchIcon ? <img src={searchImg} alt="search" /> : ""}
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleChangeSearch}
          style={{
            padding: searchIcon ? "" : "3px 16px",
          }}
        />
      </div>
      <button type="submit" name="search">
        검색
      </button>
    </form>
  );
}
