import { MouseEventHandler, useState } from "react";
import cx from "classnames";
import styles from "styles/common/navigation/Dropdown.module.scss";
import { Control, FieldPath, FieldValues, useController } from "react-hook-form";
import { Modal } from "comp-lib";

type Props<T extends FieldValues> = {
  dataList: (string | number)[];
  width?: string;
  height?: string;
  fontSize?: string;
  maxHeight?: string;
  disabled?: boolean;
  borderColor?: "default" | "deep" | "none" | "soft";
  mode?: "primary" | "secondary" | "third";
  optionTxtPosition?: "start" | "end" | "center";
  placeholder?: string;
  name: FieldPath<T>;
  control?: Control<T>;
};

/**
 * react-hook-form controller로 value를 받아오는 경우 사용하는 Dropdown 컴포넌트
 */
export default function ControllerDropdown<T extends FieldValues>({
  dataList,
  width,
  height,
  fontSize = "14px",
  borderColor = "default",
  disabled = false,
  maxHeight,
  mode = "primary",
  optionTxtPosition = "start",
  placeholder,
  name,
  control,
}: Props<T>) {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    rules: { required: "필수 항목을 모두 입력해 주세요." },
  });

  const [isActive, setIsActive] = useState(false);

  const handleClose = () => setIsActive(false);

  const handleOpenOrClose = () => setIsActive((prev) => !prev);

  const handleOptionChange: MouseEventHandler<HTMLButtonElement> = (e) => {
    onChange(String(e.currentTarget.textContent));
    setIsActive(false);
  };

  return (
    <div className={cx(styles.wrapper, styles[borderColor], styles[mode])} style={{ width, fontSize }}>
      <button
        type="button"
        name="selected"
        onClick={handleOpenOrClose}
        onBlur={handleClose}
        style={{ height }}
        disabled={disabled}
        className={cx({ [styles.placeholder]: !value })}
      >
        {value || placeholder}
      </button>

      {isActive && dataList.length !== 0 && (
        <ul className={cx(styles.option, styles[mode])} style={{ maxHeight }}>
          {dataList.map((data) => (
            <li key={data}>
              <button type="button" onMouseDown={handleOptionChange} style={{ textAlign: optionTxtPosition }}>
                {data}
              </button>
            </li>
          ))}
        </ul>
      )}

      {isActive && dataList.length === 0 && (
        <Modal description="목록이 존재하지 않습니다." btnType="confirm" handleClose={handleClose} />
      )}
    </div>
  );
}
