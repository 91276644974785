import { Checkbox } from "comp-lib";
import { useState } from "react";

type Props = {
  options: {
    label: string;
    value: string;
  }[];
  value: any;
  name: string;
  handleChange: (value: any) => void;
  isError?: boolean;
  defaultValue?: string[];
  disabled?: boolean;
};

export default function CheckboxForm({
  options,
  name,
  value,
  handleChange,
  isError,
  defaultValue = [],
  disabled,
}: Props) {
  const [checkboxValue, setCheckboxValue] = useState<string[]>(defaultValue);
  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;
    const handleValue = checkboxValue.includes(targetValue)
      ? checkboxValue.filter((v) => v !== targetValue)
      : [...checkboxValue, targetValue];
    handleChange(handleValue);
    setCheckboxValue(handleValue);
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {options.map((option, index) => {
        const key = `${option}-${index}`;
        return (
          <label
            style={{
              display: "flex",
              gap: "10px",
              color: isError ? "#fc596c" : "",
            }}
            key={key}
          >
            <Checkbox
              value={option.value}
              name={name}
              onChange={onHandleChange}
              checked={value.includes(option.value)}
              disabled={disabled}
            />
            {option.label}
          </label>
        );
      })}
    </div>
  );
}
