import { deleteGoods, getGoodsDetail } from "api/goods";
import { Content, Title, Panel, Footer, Button, Modal, ErrorModal } from "comp-lib";
import { useMutation, useQuery } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import JsonView from "@uiw/react-json-view";
import styles from "styles/pages/goods/goodsDetail.module.scss";
import { getJsonProperty } from "utils";
import { useState } from "react";

const DEFAULT_GOODS = {
  code: "",
  info: "{}",
  price: 0,
  status: 0,
};

export default function GoodsDetail() {
  const { code: paramsCode } = useParams();
  const { data = DEFAULT_GOODS, isError } = useQuery(
    ["getGoodsDetail", paramsCode],
    () => getGoodsDetail(paramsCode as string),
    {
      enabled: !!paramsCode,
    },
  );
  const location = useLocation();
  const navigate = useNavigate();
  const delGoodsMut = useMutation(deleteGoods);

  const { code, info, price, status } = data;
  const jsonData = JSON.parse(info);

  const [isModal, setIsModal] = useState(false);
  const handleDelClick = () => setIsModal(true);
  const handleModalClose = () => setIsModal(false);
  const handleModalConfirm = async () => {
    await delGoodsMut.mutateAsync([code]);
    navigate(`/goods`);
    setIsModal(false);
  };

  const handleError = () => {
    navigate("/goods");
  };

  const handleDelError = () => {
    delGoodsMut.reset();
    setIsModal(false);
  };

  return (
    <>
      <Content isWithFooter>
        <Title text="상세 정보" />

        <Panel>
          <dl className={styles.wrapper}>
            <div>
              <dt>상품코드</dt>
              <dd>{code}</dd>
            </div>

            <div>
              <dt>상품이름</dt>
              <dd>{getJsonProperty(info, "goodsName") as string}</dd>
            </div>

            <div>
              <dt>상품가격</dt>
              <dd>{price.toLocaleString()} 원</dd>
            </div>

            <div>
              <dt>상품상태</dt>
              <dd>{status}</dd>
            </div>

            <div className={styles.info}>
              <dt>상품정보</dt>
              <dd>
                <JsonView value={jsonData} displayObjectSize={false} displayDataTypes={false} />
              </dd>
            </div>

            <div className={styles.img_box}>
              <img src={getJsonProperty(info, "mmsGoodsImg") as string} alt="goods" className={styles.goods_img} />
            </div>
          </dl>
        </Panel>
      </Content>

      <Footer>
        <Button as={Link} to={`/goods${location.search}`} type="button" mode="lineGray" size="long">
          뒤로 가기
        </Button>
        <Button type="button" size="long" onClick={handleDelClick}>
          삭제
        </Button>
      </Footer>

      {isModal && (
        <Modal
          btnType="submit"
          description="상품을 삭제하시겠습니까?"
          handleClose={handleModalClose}
          handleSubmit={handleModalConfirm}
        />
      )}
      {isError && <ErrorModal message="서버에 문제가 발생했습니다." onConfirmHandler={handleError} />}
      {delGoodsMut.isError && <ErrorModal message="서버에 문제가 발생했습니다." onConfirmHandler={handleDelError} />}
    </>
  );
}
