import { ReactNode } from "react";
import ReactDom from "react-dom";

interface Props {
  children: ReactNode;
}

export default function Portal({ children }: Props) {
  return ReactDom.createPortal(children, document.getElementById("modal") as HTMLElement);
}
