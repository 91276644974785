import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useResetRecoilState } from "recoil";
import { useMutation, useQuery } from "react-query";

import DetailInfo from "pages/contentsManage/vote/@shared/DetailInfo";
import ProgressBar from "pages/contentsManage/vote/@shared/ProgressBar";
import ConfirmModal from "pages/contentsManage/vote/@shared/modal/ConfirmModal";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";

import { getCommasInNum, syncProgressPeriod } from "utils";
import { contPrFormAtom } from "recoil/vote";
import { getPollDetail, patchPollStatus } from "api/contentsManage/poll";
import queryKey from "constants/queryKey";
import { CATEGORY } from "constants/vote";
import { Button, Content, Footer, Title } from "comp-lib";

import styles from "styles/pages/contentsManage/vote/detail/index.module.scss";

export default function VoteDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const info = location.state;

  const { data } = useQuery([queryKey.GET_POLL_DETAIL, info.pollId], () => getPollDetail(info.pollId));

  const { mutate } = useMutation(patchPollStatus);

  const pollReport = data?.pollReport;

  const canEndPoll = pollReport?.status === 2 || pollReport?.status === 3 || pollReport?.status === 4;

  const totalPage = pollReport?.pagesQuestionsCnt?.split("|")[0];
  const totalCount = pollReport?.pagesQuestionsCnt?.split("|")[1];

  const responseRate =
    pollReport?.participants &&
    pollReport?.maxIssuable &&
    Math.round(((pollReport?.participants || 0) / (pollReport?.maxIssuable || 0)) * 100);

  const [isModal, setIsModal] = useState(false);
  const [isNotiModal, setIsNotiModal] = useState(false);
  const [warnModalMsg, setWarnModalMsg] = useState("");

  const resetAtom = useResetRecoilState(contPrFormAtom);

  const handleListNav = () => navigate(-1);
  const handleClick = () => setIsModal(true);
  const handleModalClose = () => setIsModal(false);
  const handleModalConfirm = () => {
    if (type === "수정") {
      resetAtom();
      navigate(`/contents/vote/writeContPr?pollId=${info.pollId}`);
    } else {
      // type === '종료'
      const body = { pollId: pollReport?.pollId || "", status: 7 };

      mutate(body, {
        onSuccess: () => setIsNotiModal(true),
        onError: (err) => setWarnModalMsg((err as Error).message),
      });
    }

    setIsModal(false);
  };

  const handleNotiModalClose = () => {
    setIsNotiModal(false);
    navigate("/contents/vote");
  };
  const handleWarnModalMsgClose = () => setWarnModalMsg("");

  const getModalMsgByStatus = () => {
    // 예정
    if (pollReport?.status === 2) {
      return {
        type: "수정",
        message: "등록정보를 수정하시겠습니까?",
        subMessage: "진행 예정 단계에서만 수정할 수 있습니다.",
        confirmMessage: "",
      };
    }

    // 진행중: data?.status === 3 || data?.status === 4
    return {
      type: "종료",
      message: "진행 중인 콘텐츠를 종료하시겠습니까?",
      subMessage: "종료 후 재개할 수 없습니다.",
      confirmMessage: "종료되었습니다.",
    };
  };

  const { type, message, subMessage, confirmMessage } = getModalMsgByStatus();

  const dataList = [
    {
      name: "제목",
      value: pollReport?.title,
    },
    {
      name: "문항 수",
      value: pollReport?.multiYn ? `${totalPage} 페이지 / ${totalCount} 문항` : "단일 문항",
    },
    {
      name: "카테고리",
      value: CATEGORY[pollReport?.category || 1],
    },
    {
      name: "진행 기간",
      value: `${dayjs(pollReport?.startDate).format("YYYY.MM.DD")} ~ ${syncProgressPeriod(
        dayjs(pollReport?.endDate),
      ).format("YYYY.MM.DD")}`,
    },
    {
      name: "1인당 지급 포인트",
      value: `${getCommasInNum(pollReport?.perAmount || 0)} P`,
    },
    {
      name: "목표 인원수",
      value: `${getCommasInNum(pollReport?.maxIssuable || 0)} 명`,
    },
    {
      name: "총 사용 예정 포인트",
      value: `${getCommasInNum((pollReport?.perAmount || 0) * (pollReport?.maxIssuable || 0))} P`,
    },
    {
      name: "콘텐츠 URL",
      value: (
        <Link
          to={`${window.MYD_WEB_URL}/poll/detail/${pollReport?.pollId}?admin=true`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${window.MYD_WEB_URL}/poll/detail/${pollReport?.pollId}`}
        </Link>
      ),
    },
  ];

  const resDataList = [
    {
      name: "응답률",
      value: <ProgressBar value={responseRate || 0} />,
    },
    {
      name: "응답 인원수",
      value: `${getCommasInNum(pollReport?.participants || 0)} 명`,
    },
    {
      name: "차감 포인트",
      value: `${getCommasInNum(data?.exhaustionPoint || 0)} P`,
    },
  ];

  return (
    <Content>
      <div className={styles.wrapper}>
        <Title text="상세 정보" />

        {data && (
          <>
            <div className={styles.content}>
              <div className={styles.inner}>
                <DetailInfo dataList={dataList} />
              </div>
            </div>

            {type !== "수정" && (
              <div className={styles.content}>
                <div className={styles.title}>
                  응답 현황
                  <div className={styles.title_btn_box}>
                    <Link
                      to={`${window.MYD_WEB_URL}/poll/detail/${pollReport?.pollId}?resultLink=true&admin=true`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button">결과 링크</button>
                    </Link>
                    {/* <button type="button">엑셀 다운로드</button> */}
                  </div>
                </div>

                <DetailInfo dataList={resDataList} />
              </div>
            )}
          </>
        )}
      </div>

      <Footer>
        <Button type="button" mode="outlined" size="long" onClick={handleListNav}>
          확인
        </Button>

        {canEndPoll && (
          <Button type="submit" size="long" onClick={handleClick}>
            {type}
          </Button>
        )}
      </Footer>

      {isModal && (
        <ConfirmModal
          message={message}
          subMessage={subMessage}
          onCancle={handleModalClose}
          onConfirm={handleModalConfirm}
        />
      )}

      {isNotiModal && type !== "수정" && <NotiModal onClose={handleNotiModalClose} message={confirmMessage} />}
      {warnModalMsg && <NotiModal message={warnModalMsg} onClose={handleWarnModalMsgClose} />}
    </Content>
  );
}
