import { useQuery } from "react-query";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { getToken } from "utils/access";
import Layout from "Layout";
import queryKey from "constants/queryKey";
import OutletErrorBoundary from "pages/OutletErrorBoundary";

export default function PrivateRoute() {
  const { data } = useQuery(queryKey.PRIVATE_ROUTE_TOKEN, getToken);
  const { pathname } = useLocation();

  if (data === undefined) return null;
  if (data === null) return <Navigate to="/login" replace />;
  return (
    <Layout>
      <OutletErrorBoundary key={pathname}>
        <Outlet />
      </OutletErrorBoundary>
    </Layout>
  );
}
