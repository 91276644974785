import axios from "axios";

let token: string | null = null;
let refreshTokenPromise: Promise<string | null> | null = null;

export const getToken = async () => {
  // console.log("getToken", token);
  if (!token) {
    // 갱신 중인 경우 기존의 Promise 사용
    if (!refreshTokenPromise) {
      refreshTokenPromise = refreshToken();
    }

    const newToken = await refreshTokenPromise;
    setToken(newToken);
    refreshTokenPromise = null;
  }
  return token;
};

export const setToken = (argToken: string | null) => {
  token = argToken;
};

export const refreshToken = async () => {
  try {
    const response = await axios.post(`/api/auth/refresh`);
    return response.data.data.accessToken;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400 || error.response?.status === 401) {
        return null;
      }
    }
    throw error;
  }
};
