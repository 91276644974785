import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Panel, Title, Button, Content, Modal, Pagination, useCustomParams, TableNav } from "comp-lib";

import styles from "styles/pages/appPush/appPush.module.scss";

import useTableData from "./list/table/useTableData";

import { useMutation, useQuery } from "react-query";
import queryKey from "constants/queryKey";
import { checkPushStatus, getPushList } from "api/appPush";
import Table from "./list/table/Table";
import DateSearchBox from "./list/dateSearchBox/DateSearchBox";
import LoadingModal from "./@shared/LoadingModal";
import useModal from "hooks/useModal";

type TStatus = "ALL" | "SUCCESS" | "RESERVATION";

export default function AppPush() {
  const navigate = useNavigate();

  const [pendingPushTitle, setPendingPushTitle] = useState("");

  const [isModalOpen, handleToggleModal] = useModal();

  const [{ pageNum, status, from, to, searchKeyword }] = useCustomParams({
    pageNum: "1",
    status: "",
    from: "",
    to: "",
    searchKeyword: "",
  });

  const { data, isLoading } = useQuery(
    [queryKey.GET_PUSH_LIST, pageNum, status, searchKeyword, from, to],
    () =>
      getPushList({
        pageNo: pageNum,
        status: status === "ALL" ? "" : status,
        startDate: from,
        endDate: to,
        keyword: searchKeyword,
      }),
    {
      keepPreviousData: true,
    },
  );
  const totalCount = {
    ALL: data?.pushMessageCount.totalCount ?? 0,
    SUCCESS: data?.pushMessageCount.sendCount ?? 0,
    RESERVATION: data?.pushMessageCount.reservationCount ?? 0,
  };

  const checkPendingPush = useMutation(() => checkPushStatus("PENDING"), {
    onSuccess: (res) => {
      const hasPendingPush = res.data.length !== 0;

      if (hasPendingPush) {
        const title = res.data.map((push: any) => push.title).join(`,\n`);

        setPendingPushTitle(title);
        handleToggleModal();
      } else {
        navigate("/message/appPush/write");
      }
    },
  });

  const length = status ? totalCount[status as TStatus] : totalCount.ALL;
  const { columns, rows } = useTableData({ data: data?.pushMessageList.content!, length, pageNo: pageNum });

  const handleRegister = () => {
    checkPendingPush.mutate();
  };

  if (isLoading) {
    return <LoadingModal />;
  }

  return (
    <Content>
      <Title text="앱 푸시(PUSH 메시지)" />

      <Panel className={styles.panel}>
        <div className={styles.header}>
          <TableNav dataList={formatNav(totalCount)} />
          <DateSearchBox />
        </div>

        <Table columns={columns} rows={rows} />

        <div className={styles.footer}>
          <Button type="button" onClick={handleRegister}>
            신규 등록
          </Button>
        </div>

        <Pagination totalPages={data?.pushMessageList.totalPages!} />
      </Panel>

      {isModalOpen && (
        <Modal
          title={pendingPushTitle}
          description="발송 중입니다. 완료 후 다시 시도해 주세요."
          btnType="confirm"
          handleClose={handleToggleModal}
        />
      )}
    </Content>
  );
}

export const formatNav = (data: { ALL: number; SUCCESS: number; RESERVATION: number }) => {
  return [
    {
      label: "전체",
      status: "ALL",
      count: data.ALL,
    },
    {
      label: "발송",
      status: "SUCCESS",
      count: data.SUCCESS,
    },
    {
      label: "예약",
      status: "RESERVATION",
      count: data.RESERVATION,
    },
  ];
};
