import { useQuery } from "react-query";
import { Outlet, useNavigate } from "react-router-dom";
import { getUserInfo } from "api/common";
import { Modal } from "comp-lib";
import queryKey from "constants/queryKey";
import ROLE from "constants/role";
import { isAdmin } from "./manager/managerHelper";

type TProps = {
  authority:
    | "충전승인"
    | "기업관리"
    | "데이터거래"
    | "광고"
    | "프로모션"
    | "콘텐츠관리"
    | "커뮤니케이션"
    | "상품관리"
    | "ADMIN";
};

const AUTHORITY_MAP = {
  충전승인: "1",
  기업관리: "2",
  데이터거래: "3",
  광고: "4",
  프로모션: "5",
  콘텐츠관리: "6",
  커뮤니케이션: "7",
  상품관리: "8",
  ADMIN: "0",
};

export default function MenuAccess({ authority }: TProps) {
  const navigate = useNavigate();

  const { data } = useQuery(queryKey.GET_OWN, getUserInfo, {
    staleTime: 3600 * 1000,
    cacheTime: 3600 * 1000,
  });

  if (!data) return null;

  if (isAdmin(data.roles)) return <Outlet />;
  // if (authority === "ADMIN" && isAdmin(data.roles)) return <Outlet />;
  if (data.authority.split(",").includes(AUTHORITY_MAP[authority])) return <Outlet />;
  return (
    <Modal btnType="confirm" description="접근 권한이 없습니다." handleClose={() => navigate("/", { replace: true })} />
  );
}
