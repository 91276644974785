import { useQuery } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Content, Footer, Panel, Title } from "comp-lib";
import dayjs from "dayjs";

import { getTransDetail } from "api/transaction";
import styles from "styles/pages/transaction/transactionDetail.module.scss";
import LoadingModal from "pages/appPush/@shared/LoadingModal";
import AlertModal from "./@tmp/AlertModal";

export default function TransactionDetail() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { data, isLoading, isFetching, isError } = useQuery(["getTransDetail", id], () => {
    if (!id) {
      // id가 없는 경우에 여기로 오지않는다. 리액트 라우터에서 path로 제한되기 때문에
      navigate("/service/transaction");
      throw new Error("해당 url가 유효하지 않습니다.");
    }

    return getTransDetail(id);
  });

  const handleError = () => {
    navigate(`/service/transaction${location.search}`);
  };

  if (isError) {
    return <AlertModal message="서버에 문제가 발생했습니다." onConfirm={handleError} />;
  }

  if (isFetching || isLoading) {
    return <LoadingModal />;
  }
  if (!data) {
    return null;
  }

  const age = (() => {
    if (data.ageGroup === "all" || !data.ageGroup) {
      return "연령 전체";
    }

    const [start, end] = data.ageGroup.split("-");

    return `${start}세-${end}세`;
  })();

  const gender = (() => {
    if (data.gender === "all" || !data.gender) {
      return "성별 전체";
    }

    return data.gender === "female" ? "여성" : "남성";
  })();

  const region = (() => {
    if (data.regionOfResidence === "전체" || !data.regionOfResidence) {
      return "지역 전체";
    }

    const newRegion = data.regionOfResidence.replace(/-전체/g, "").split(",").join(",  ");

    return newRegion;
  })();

  return (
    <>
      <Content className={styles.wrapper} isWithFooter>
        <Title text="상세 정보" />

        <Panel>
          <h2>{data.title}</h2>

          <dl>
            <div>
              <dd>기업/기관</dd>
              <dt>{data.staff.company.name}</dt>
            </div>

            <div>
              <dd>썸네일</dd>
              <dt>
                <a href={data.contentObject.imageURL.banner} target="_blank" rel="noreferrer" className={styles.link}>
                  {data.contentObject.imageURL.banner.split("/").pop()}
                </a>
              </dt>
            </div>

            <div>
              <dd>진행기간</dd>
              <dt>
                {dayjs(data.notBefore).format("YYYY.MM.DD HH:mm")} ~
                {syncProgressPeriod(dayjs(data.notAfter)).format("YYYY.MM.DD")} 23:59
              </dt>
            </div>

            <div>
              <dd>사용자 타켓팅</dd>
              <dt>
                {age}, {gender}, {region}
              </dt>
            </div>

            <div>
              <dd>데이터 타켓팅</dd>
              <dt>{data.dataTargets.join(",  ")}</dt>
            </div>

            <div>
              <dd>수집 데이터</dd>
              <dt>{data.selectDataList.join(",  ")}</dt>
            </div>

            <div>
              <dd>데이터 수집기간</dd>
              <dt>{data.selectRangeText}</dt>
            </div>

            <div>
              <dd>1인당 지급 포인트</dd>
              <dt>{data.rewardPolicyAmount.toLocaleString()} P</dt>
            </div>

            <div>
              <dd>목표인원 수</dd>
              <dt>{data.maxIssuable.toLocaleString()} 명</dt>
            </div>

            <div>
              <dd>예상 차감 포인트</dd>
              <dt>{(data.rewardPolicyAmount * data.maxIssuable).toLocaleString()} P</dt>
            </div>

            <div>
              <dd>거래 목적</dd>
              <dt>{data.contentObject.description.detail}</dt>
            </div>
          </dl>
        </Panel>
      </Content>

      <Footer>
        <Button as={Link} to={`/service/transaction${location.search}`} size="long">
          목록으로
        </Button>
      </Footer>
    </>
  );
}

function syncProgressPeriod(date: dayjs.Dayjs, type?: "decode") {
  /** 거래 등록 시 진행기간이 23:59 까지여야 하기 때문에 서버에서 임의로 날짜를 + 1 해서 저장합니다.
   *  때문에 보여줄 때에는 서버에서 받아온 진행기간 notAfter에서 하루를 빼줘야 합니다.  */
  return date.subtract(type === "decode" ? -1 : 1, "day");
}
