export const ListNav = [
  {
    to: "",
    name: "홈",
  },
  {
    to: "/message/homePopup",
    name: "앱 공지",
  },
  {
    to: "",
    name: "목록",
  },
];

export const ContentNav = [
  {
    to: "",
    name: "홈",
  },
  {
    to: "/message/homePopup",
    name: "앱 공지",
  },
  {
    to: "",
    name: "신규등록",
  },
];

export const DetailNav = [
  {
    to: "",
    name: "홈",
  },
  {
    to: "/message/homePopup",
    name: "앱 공지",
  },
  {
    to: "",
    name: "상세 정보",
  },
];

export const platform = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Android",
    value: "aos",
  },
  {
    label: "iOS",
    value: "ios",
  },
];

export const noticeTypeList = [
  {
    label: "시스템 공지",
    value: "system",
  },
  {
    label: "사전 공지",
    value: "service",
  },
  {
    label: "이벤트 공지",
    value: "event",
  },
];

export const moveType = [
  {
    label: "탭",
    value: "TAB",
  },
  {
    label: "URL",
    value: "URL",
  },
];

export const moveDstArr = [
  {
    label: "거래",
    value: "0",
  },
  {
    label: "연결",
    value: "1",
  },
  {
    label: "조회",
    value: "2",
  },
  {
    label: "포인트몰",
    value: "3",
  },
];

export const invisiblePeriod = [
  {
    label: "오늘 보지 않기",
    value: "vP_1",
  },
  {
    label: "3일 보지 않기",
    value: "vP_3",
  },
  {
    label: "7일 보지 않기",
    value: "vP_7",
  },
  {
    label: "다시 보지 않기",
    value: "vP_0",
  },
];

export const statusLabel = [
  {
    status: "pending",
    label: "비활성화",
  },
  {
    status: "activated",
    label: "활성화",
  },
  {
    status: "revoked",
    label: "삭제",
  },
  {
    status: "expired",
    label: "종료",
  },
];

export const priority = [
  {
    label: "1순위",
    value: 1,
  },
  {
    label: "2순위",
    value: 2,
  },
  {
    label: "3순위",
    value: 3,
  },
  {
    label: "4순위",
    value: 4,
  },
  {
    label: "5순위",
    value: 5,
  },
  {
    label: "6순위",
    value: 6,
  },
  {
    label: "7순위",
    value: 7,
  },
  {
    label: "8순위",
    value: 8,
  },
  {
    label: "9순위",
    value: 9,
  },
  {
    label: "10순위",
    value: 10,
  },
];
