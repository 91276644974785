import styles from "./progressBar.module.scss";

type TProps = {
  value: number;
};

export default function ProgressBar({ value = 0 }: TProps) {
  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: `linear-gradient(to right, #1ab9de ${value}%, #f0f0f0 0%, #f0f0f0 100%)`,
      }}
    >
      <div className={styles.tooltip} style={{ left: `${Math.min(value, 100)}%` }}>
        {value}%
      </div>
    </div>
  );
}
