import { MouseEvent, useState } from "react";
import styles from "styles/pages/contentsManage/challenge/report/reportButtonBox.module.scss";
import { Button, ErrorModal, LoadingModal } from "comp-lib";
import ConfirmModal from "../@shared/ConfirmModal";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";
import { useMutation, useQueryClient } from "react-query";
import { putChallengeReport } from "api/contentsManage/challenge";
import queryKey from "constants/queryKey";
import { CHALLENGE_REPORT_REQ_MSG, CHALLENGE_REPORT_RES_MSG } from "../constants";
import { TSelectedReport } from "types/contentsManage/challenge";

export default function ReportButtonBox({
  selected,
  selectedReset,
  disabled,
}: {
  selected: TSelectedReport;
  selectedReset: () => void;
  disabled?: boolean;
}) {
  const [modalSet, setModalSet] = useState<"cancel" | "accept" | null>(null);
  const [notiMessage, setNotiMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(putChallengeReport, {
    onSuccess: (_, { status }) => {
      setNotiMessage(CHALLENGE_REPORT_RES_MSG[status]);
      queryClient.invalidateQueries(queryKey.GET_CHALLENGE_REPORT_LIST);
      selectedReset();
    },
    onError: (err: Error) => setErrorMessage(err?.message || "요청을 수행하는 중 오류가 발생했습니다."),
    onSettled: () => setModalSet(null),
  });

  const handleClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement & { id: "cancel" | "accept" };
    if (selected.length) {
      setModalSet(target.id);
    } else setNotiMessage("하나 이상의 이미지를 선택해주세요");
  };

  const confirmModalProps = {
    cancel: {
      message: "인증취소",
      subMessage: CHALLENGE_REPORT_REQ_MSG.cancel,
      onConfirm: () => mutate({ list: selected, status: "cancel" }),
    },
    accept: {
      message: "신고반려",
      subMessage: CHALLENGE_REPORT_REQ_MSG.accept,
      onConfirm: () => mutate({ list: selected, status: "accept" }),
    },
  };

  return (
    <div className={styles.wrapper}>
      <Button onClick={handleClickButton} id="cancel" disabled={disabled}>
        인증취소
      </Button>
      <Button onClick={handleClickButton} id="accept" disabled={disabled}>
        신고반려
      </Button>
      {notiMessage && <NotiModal message={notiMessage} onClose={() => setNotiMessage("")} />}
      {errorMessage && <ErrorModal message={errorMessage} onConfirmHandler={() => setErrorMessage("")} />}
      {isLoading && <LoadingModal />}
      {modalSet !== null && <ConfirmModal onCancle={() => setModalSet(null)} {...confirmModalProps[modalSet]} />}
    </div>
  );
}
