import { CATEGORY_DATA_LIST, TYPE_LIST } from "constants/vote";
import { atom } from "recoil";
import { TContPrForm } from "types/contentsManage/vote";

export const contPrFormAtom = atom<TContPrForm>({
  key: "contPrFormAtom",
  default: {
    type: TYPE_LIST[0],
    question: "",
    category: CATEGORY_DATA_LIST[0],
    title: "",
    content: "",
    imgFileString: "",
    startDate: "",
    endDate: "",
    filter: {
      gender: "",
      age: "",
      area: "",
    },
    perPersonPnt: 0,
    goalPeopleCnt: 0,
  },
});

export const adPageAtom = atom({
  key: "adPageAdminAtom",
  default: {
    pageNum: 1,
    status: "",
  },
});
