import { useWatch } from "react-hook-form";
import MobileView from "./MobileView";
import { TContPrForm } from "types/contentsManage/vote";

export default function ContPrMobile() {
  const [anyTitle, anyContent, anyImgFileString, anyStartDate, anyEndDate, anyFilter, anyPerPersonPnt] = useWatch({
    name: ["title", "content", "imgFileString", "startDate", "endDate", "filter", "perPersonPnt"],
  });

  const title = anyTitle as TContPrForm["title"];
  const content = anyContent as TContPrForm["content"];
  const imgFileString = anyImgFileString as TContPrForm["imgFileString"];
  const startDate = anyStartDate as TContPrForm["startDate"];
  const endDate = anyEndDate as TContPrForm["endDate"];
  const filter = anyFilter as TContPrForm["filter"];
  const perPersonPnt = anyPerPersonPnt as TContPrForm["perPersonPnt"];

  return (
    <MobileView
      data={{
        title,
        content,
        imgFileString,
        startDate,
        endDate,
        filter,
        perPersonPnt,
      }}
    />
  );
}
