import { useEffect } from "react";
import { Button } from "comp-lib";
import { Portal } from "common/other";
import styles from "./modal.module.scss";

type TProps = {
  message: string;
  onConfirm: () => void;
};

export default function AlertModal({ message, onConfirm }: TProps) {
  useEffect(() => {
    document.body.style.cssText = "overflow: hidden";
    return () => {
      document.body.style.cssText = "overflow: auto";
    };
  }, []);

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Portal>
      <div className={styles.overlay}>
        <article className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.message}>{message}</div>
          </div>

          <div className={styles.btn_box}>
            <Button type="button" size="short" onClick={handleConfirm}>
              확인
            </Button>
          </div>
        </article>
      </div>
    </Portal>
  );
}
