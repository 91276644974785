import { useEffect } from "react";
import styles from "./loadingModal.module.scss";

import Portal from "./Portal";

export default function LoadingModal() {
  useEffect(() => {
    document.body.style.cssText = "overflow: hidden";
    return () => {
      document.body.style.cssText = "overflow: auto";
    };
  }, []);

  return (
    <Portal>
      <div className={styles.indicator} />
    </Portal>
  );
}
