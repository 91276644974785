import Dropdown from "./dropdown";
import { ArrowLeftIcon, CheckCirBlackIcon, DescIcon, RadioBlackIcon, RadioEmtBlackIcon } from "assets/svgs/vote";
import styles from "./customDropdown.module.scss";
import cx from "classnames";

type TProps = {
  value: string;
  dataList: string[];
  onChange: (value: string) => void;
  size?: "small" | "medium";
};

export default function CustomDropdown({ dataList, value, onChange, size = "medium" }: TProps) {
  return (
    <Dropdown value={value} onChange={onChange} className={styles.wrapper}>
      <Dropdown.Trigger className={cx(styles.trigger, styles[size])}>
        {value === "단일 선택형" && <RadioBlackIcon />}
        {value === "복수 선택형" && <CheckCirBlackIcon />}
        {value === "주관식 단답형" && <DescIcon />}
        {value === "정답 단일 선택형" && <RadioEmtBlackIcon width={20} />}
        {value === "정답 복수 선택형" && <CheckCirBlackIcon />}
        <div>{value}</div>

        <div className={styles.icon}>
          <ArrowLeftIcon />
        </div>
      </Dropdown.Trigger>

      <Dropdown.Options className={styles.options}>
        {dataList.map((data) => (
          <div className={styles.optionBox} key={data}>
            {data === "단일 선택형" && <RadioBlackIcon />}
            {data === "복수 선택형" && <CheckCirBlackIcon />}
            {data === "주관식 단답형" && <DescIcon />}
            {data === "정답 단일 선택형" && <RadioEmtBlackIcon width={20} />}
            {data === "정답 복수 선택형" && <CheckCirBlackIcon />}
            <Dropdown.Option key={data} value={data} className={styles.option} />
          </div>
        ))}
      </Dropdown.Options>
    </Dropdown>
  );
}
