import { Controller, useFormContext } from "react-hook-form";

import { Button } from "comp-lib";

import EmojiPicker from "./EmojiPicker";

import styles from "styles/pages/appPush/write/setMessage.module.scss";

import RequiredMark from "./RequiredMark";
import RadioBoxAppPush from "./RadioBoxAppPush";
import Input from "./Input";

import RegisterImg from "./RegisterImg";
import { MOVE_TO } from "constants/appPush";
import { getMsgJson } from "utils/appPush";

const TITLE_MAX_LENGTH = 30;
const BODY_MAX_LENGTH = 50;
const BODY_MAX_AOS = 10;

function SetMessage() {
  const {
    control,
    watch,
    setValue,
    resetField,
    getValues,
    formState: { errors },
  } = useFormContext();

  const resetInput = () => {
    resetField("moveToUrl");
  };

  const handleSetValue = (type: string, emoji: any) => {
    const prevValue = watch()[type];
    const newValue = prevValue.concat(emoji.native);

    setValue(type, newValue);
  };

  const isMsgTypeMarketingAd = getValues("messageType") === "MARKETING_ADVERTISE";

  const handleCreateClick = () => {
    const formattedBody = isMsgTypeMarketingAd
      ? `(광고) ${getValues("body")}\n*수신거부 : MY > 푸시 알람 설정`
      : `${getValues("body")}\n*수신거부 : 기기 내 설정 > 알림`;

    const json = getMsgJson({
      title: watch("title"),
      body: formattedBody,
      os: watch("messagePlatformStatus") || "ALL",
      moveTo: watch("moveToUrl") !== "" ? watch("moveToUrl") : watch("moveTo"),
    });

    setValue("jsonOrigin", JSON.stringify(json));
  };

  const handleResetClick = () => {
    resetField("title");
    resetField("body");
    resetField("imagePath");
    setValue("imageFile", "");
    resetField("moveTo");
    setValue("jsonOrigin", "");
  };

  return (
    <section className={styles.create}>
      <h2>메시지 설정</h2>
      <div className={styles.form}>
        <div className={styles.titleBox}>
          <label className={styles.category} htmlFor="title">
            <RequiredMark className={styles.category}>제목(title)</RequiredMark>
          </label>

          <Controller
            name="title"
            control={control}
            rules={{
              validate: {
                required: (value) => value.trim().length !== 0,
              },
            }}
            render={({ field: { onChange } }) => (
              <div className={styles.inputBox}>
                <input
                  aria-invalid={!!errors.title}
                  className={errors.title ? styles.error : ""}
                  style={{ position: "relative" }}
                  type="text"
                  name="title"
                  id="title"
                  maxLength={TITLE_MAX_LENGTH}
                  placeholder={`제목을 입력해 주세요 (최대 ${TITLE_MAX_LENGTH}자)`}
                  onChange={onChange}
                  value={watch().title}
                />

                <EmojiPicker
                  type="title"
                  handleSetValue={handleSetValue}
                  text={watch("title")}
                  maxLength={TITLE_MAX_LENGTH}
                />
              </div>
            )}
          />
        </div>

        <div className={styles.bodyBox}>
          <label className={styles.category} htmlFor="body">
            <RequiredMark className={styles.category}>내용(body)</RequiredMark>
          </label>

          <Controller
            name="body"
            control={control}
            rules={{
              validate: {
                required: (value) => value.trim().length !== 0,
              },
            }}
            render={({ field: { onChange } }) => (
              <div className={styles.inputBox}>
                <textarea
                  aria-invalid={!!errors.body}
                  className={errors.body ? styles.error : ""}
                  style={{ position: "relative" }}
                  name="body"
                  id="body"
                  maxLength={BODY_MAX_LENGTH}
                  value={watch().body}
                  onChange={onChange}
                  placeholder={`내용을 입력하세요 (최대 ${BODY_MAX_LENGTH}자)\n이미지 포함 본문 경우 지원되지 않는 폰 기준\n본문 ${BODY_MAX_AOS}자 내외로 입력 추천 `}
                />
                <EmojiPicker
                  type="body"
                  handleSetValue={handleSetValue}
                  text={watch("body")}
                  maxLength={BODY_MAX_LENGTH}
                />
              </div>
            )}
          />
        </div>

        <div className={styles.img}>
          <label className={styles.category} htmlFor="image">
            이미지(image)
          </label>

          <Controller
            name="imagePath"
            control={control}
            render={({ field: { onChange } }) => <RegisterImg onChange={onChange} />}
          />
        </div>

        <div className={styles.moveToBox}>
          <RequiredMark className={styles.category}>이동 위치(move to)</RequiredMark>

          <Controller
            name="moveTo"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => {
              return (
                <div className={styles.subCategoryBoxContainer}>
                  <div className={styles.subCategoryBox}>
                    <span className={styles.subCategory}>Navigation</span>
                    <RadioBoxAppPush
                      name="moveTo"
                      dataList={MOVE_TO.slice(0, 4)}
                      value={value}
                      onChange={onChange}
                      resetInput={resetInput}
                    />
                  </div>

                  <div className={styles.subCategoryBox}>
                    <span className={styles.subCategory}>Others</span>
                    <RadioBoxAppPush
                      name="moveTo"
                      dataList={MOVE_TO.slice(4, 7)}
                      value={value}
                      onChange={onChange}
                      resetInput={resetInput}
                    />
                  </div>

                  {/* TODO: 마이디 개편 후 사용 예정 */}
                  {/* <div className={styles.subCategoryBox}>
                    <span className={styles.subCategory}>Contents</span>
                    <div className={styles.itemBox}>
                      <div className={styles.itemBox}>
                        <RadioBoxAppPush
                          name="moveTo"
                          dataList={MOVE_TO.slice(8, 9)}
                          value={value}
                          onChange={onChange}
                          resetInput={resetInput}
                        />
                        <Controller
                          name="moveToUrl"
                          control={control}
                          rules={{ required: watch("moveTo") === MOVE_TO.slice(8, 9)[0].value }}
                          render={({ field: { value: inputValue, onChange: onChangeInput } }) => (
                            <Input
                              placeholder="No."
                              value={watch("moveTo") === MOVE_TO.slice(8, 9)[0].value ? inputValue : ""}
                              disabled={watch("moveTo") !== MOVE_TO.slice(8, 9)[0].value}
                              width="96px"
                              onChange={onChangeInput}
                            />
                          )}
                        />
                      </div>
                      <div className={styles.itemBox}>
                        <RadioBoxAppPush
                          name="moveTo"
                          dataList={MOVE_TO.slice(9, 10)}
                          value={value}
                          onChange={onChange}
                          resetInput={resetInput}
                        />
                        <Controller
                          name="moveToUrl"
                          control={control}
                          rules={{ required: watch("moveTo") === MOVE_TO.slice(9, 10)[0].value }}
                          render={({ field: { value: inputValue, onChange: onChangeInput } }) => (
                            <Input
                              placeholder="No."
                              value={watch("moveTo") === MOVE_TO.slice(9, 10)[0].value ? inputValue : ""}
                              disabled={watch("moveTo") !== MOVE_TO.slice(9, 10)[0].value}
                              width="96px"
                              onChange={onChangeInput}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div> */}

                  {/* <div className={styles.subCategoryBox}>
                    <span className={styles.subCategory}>Page</span>
                    <div className={styles.itemBox}>
                      <RadioBoxAppPush
                        name="moveToage"
                        dataList={MOVE_TO.slice(6)}
                        value={value}
                        onChange={onChange}
                        resetInput={resetInput}
                      />
                      <Controller
                        name="moveToUrl"
                        control={control}
                        rules={{ required: watch("moveTo") === MOVE_TO.slice(6)[0].value }}
                        render={({ field: { value: inputValue, onChange: onChangeInput } }) => (
                          <Input
                            placeholder="마이디 내 랜딩 페이지 URL을 입력하세요(‘https://’ 포함)"
                            value={watch("moveTo") === MOVE_TO.slice(6)[0].value ? inputValue : ""}
                            disabled={watch("moveTo") !== MOVE_TO.slice(6)[0].value}
                            width="674px"
                            onChange={onChangeInput}
                          />
                        )}
                      />
                    </div>
                  </div> */}
                </div>
              );
            }}
          />
        </div>

        <div className={styles.btnBox}>
          <Button onClick={handleResetClick} className={styles.cancel} size="long" type="button">
            초기화
          </Button>

          <Button type="button" onClick={handleCreateClick} size="long">
            JSON 만들기
          </Button>
        </div>
      </div>
    </section>
  );
}

export default SetMessage;
