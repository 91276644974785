import { useState } from "react";
import styles from "styles/pages/contentsManage/challenge/report/blockButton.module.scss";
import { Button, ErrorModal, LoadingModal } from "comp-lib";
import ConfirmModal from "../@shared/ConfirmModal";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";
import { useMutation, useQueryClient } from "react-query";
import { putChallengeReport } from "api/contentsManage/challenge";
import queryKey from "constants/queryKey";
import { CHALLENGE_REPORT_REQ_MSG, CHALLENGE_REPORT_RES_MSG } from "../constants";
import { TSelectedReport } from "types/contentsManage/challenge";

export default function BlockButton({
  selected,
  selectedReset,
}: {
  selected: TSelectedReport;
  selectedReset: () => void;
}) {
  const [modalSet, setModalSet] = useState<"block" | null>(null);
  const [notiMessage, setNotiMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(putChallengeReport, {
    onSuccess: (_, { status }) => {
      setNotiMessage(CHALLENGE_REPORT_RES_MSG[status]);
      queryClient.invalidateQueries(queryKey.GET_CHALLENGE_REPORT_LIST);
      selectedReset();
    },
    onError: (err: Error) => setErrorMessage(err?.message || "요청을 수행하는 중 오류가 발생했습니다."),
    onSettled: () => setModalSet(null),
  });

  const handleClickButton = () => {
    selected.length ? setModalSet("block") : setNotiMessage("하나 이상의 이미지를 선택해주세요");
  };

  return (
    <div className={styles.wrapper}>
      <Button onClick={handleClickButton} mode="lineGray" size="short">
        차단하기
      </Button>
      {notiMessage && <NotiModal message={notiMessage} onClose={() => setNotiMessage("")} />}
      {errorMessage && <ErrorModal message={errorMessage} onConfirmHandler={() => setErrorMessage("")} />}
      {isLoading && <LoadingModal />}
      {modalSet !== null && (
        <ConfirmModal
          onCancle={() => setModalSet(null)}
          message={"차단하기"}
          subMessage={CHALLENGE_REPORT_REQ_MSG.block}
          onConfirm={() => mutate({ list: selected, status: "block" })}
          isWarning={true}
          confirmText={"차단"}
        />
      )}
    </div>
  );
}
