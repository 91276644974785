import { Outlet } from "react-router-dom";
import styles from "styles/pages/notice/legacyOutlet.module.scss";

export default function LegacyOutlet() {
  return (
    <div className={styles.wrapper}>
      <Outlet />
    </div>
  );
}
