/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import Long from "long";

export const protobufPackage = "io.snplab.myd.protocol.v3.schema";

/**
 * Reward policy type for multi-consuming case
 * REWARD_FOR_EACH_CONSUME is by default; REWARD_WHEN_COMPLETED is for multi-consuming data-market.
 */
export enum RewardPolicyType {
  /** REWARD_BEGIN - placeholder for 0 */
  REWARD_BEGIN = 0,
  /** REWARD_FOR_EACH_CONSUME - reward for each consume */
  REWARD_FOR_EACH_CONSUME = 1,
  /** REWARD_WHEN_COMPLETED - reward after "maxConsumeCount" consumes */
  REWARD_WHEN_COMPLETED = 2,
  UNRECOGNIZED = -1,
}

export function rewardPolicyTypeFromJSON(object: any): RewardPolicyType {
  switch (object) {
    case 0:
    case "REWARD_BEGIN":
      return RewardPolicyType.REWARD_BEGIN;
    case 1:
    case "REWARD_FOR_EACH_CONSUME":
      return RewardPolicyType.REWARD_FOR_EACH_CONSUME;
    case 2:
    case "REWARD_WHEN_COMPLETED":
      return RewardPolicyType.REWARD_WHEN_COMPLETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RewardPolicyType.UNRECOGNIZED;
  }
}

export function rewardPolicyTypeToJSON(object: RewardPolicyType): string {
  switch (object) {
    case RewardPolicyType.REWARD_BEGIN:
      return "REWARD_BEGIN";
    case RewardPolicyType.REWARD_FOR_EACH_CONSUME:
      return "REWARD_FOR_EACH_CONSUME";
    case RewardPolicyType.REWARD_WHEN_COMPLETED:
      return "REWARD_WHEN_COMPLETED";
    case RewardPolicyType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ProposalStatus {
  /** PROPOSAL_GLOBAL_BEGIN - place holder for 0 */
  PROPOSAL_GLOBAL_BEGIN = 0,
  PROPOSAL_REGISTERED = 1,
  PROPOSAL_ACTIVATED = 2,
  PROPOSAL_TICKET_UNAVAILABLE = 3,
  PROPOSAL_COMPLETED = 4,
  /** PROPOSAL_FINISHED - to classify finished proposal */
  PROPOSAL_FINISHED = 5,
  /** PROPOSAL_UNKNOWN - for middleware repository state */
  PROPOSAL_UNKNOWN = 6,
  UNRECOGNIZED = -1,
}

export function proposalStatusFromJSON(object: any): ProposalStatus {
  switch (object) {
    case 0:
    case "PROPOSAL_GLOBAL_BEGIN":
      return ProposalStatus.PROPOSAL_GLOBAL_BEGIN;
    case 1:
    case "PROPOSAL_REGISTERED":
      return ProposalStatus.PROPOSAL_REGISTERED;
    case 2:
    case "PROPOSAL_ACTIVATED":
      return ProposalStatus.PROPOSAL_ACTIVATED;
    case 3:
    case "PROPOSAL_TICKET_UNAVAILABLE":
      return ProposalStatus.PROPOSAL_TICKET_UNAVAILABLE;
    case 4:
    case "PROPOSAL_COMPLETED":
      return ProposalStatus.PROPOSAL_COMPLETED;
    case 5:
    case "PROPOSAL_FINISHED":
      return ProposalStatus.PROPOSAL_FINISHED;
    case 6:
    case "PROPOSAL_UNKNOWN":
      return ProposalStatus.PROPOSAL_UNKNOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProposalStatus.UNRECOGNIZED;
  }
}

export function proposalStatusToJSON(object: ProposalStatus): string {
  switch (object) {
    case ProposalStatus.PROPOSAL_GLOBAL_BEGIN:
      return "PROPOSAL_GLOBAL_BEGIN";
    case ProposalStatus.PROPOSAL_REGISTERED:
      return "PROPOSAL_REGISTERED";
    case ProposalStatus.PROPOSAL_ACTIVATED:
      return "PROPOSAL_ACTIVATED";
    case ProposalStatus.PROPOSAL_TICKET_UNAVAILABLE:
      return "PROPOSAL_TICKET_UNAVAILABLE";
    case ProposalStatus.PROPOSAL_COMPLETED:
      return "PROPOSAL_COMPLETED";
    case ProposalStatus.PROPOSAL_FINISHED:
      return "PROPOSAL_FINISHED";
    case ProposalStatus.PROPOSAL_UNKNOWN:
      return "PROPOSAL_UNKNOWN";
    case ProposalStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ProposalClientStatus {
  /** PROPOSAL_CLIENT_BEGIN - place holder for 0 */
  PROPOSAL_CLIENT_BEGIN = 0,
  PROPOSAL_ISSUABLE = 1,
  PROPOSAL_ISSUING = 2,
  PROPOSAL_ISSUED = 3,
  PROPOSAL_NOT_ISSUABLE = 4,
  PROPOSAL_REWARDED = 5,
  UNRECOGNIZED = -1,
}

export function proposalClientStatusFromJSON(object: any): ProposalClientStatus {
  switch (object) {
    case 0:
    case "PROPOSAL_CLIENT_BEGIN":
      return ProposalClientStatus.PROPOSAL_CLIENT_BEGIN;
    case 1:
    case "PROPOSAL_ISSUABLE":
      return ProposalClientStatus.PROPOSAL_ISSUABLE;
    case 2:
    case "PROPOSAL_ISSUING":
      return ProposalClientStatus.PROPOSAL_ISSUING;
    case 3:
    case "PROPOSAL_ISSUED":
      return ProposalClientStatus.PROPOSAL_ISSUED;
    case 4:
    case "PROPOSAL_NOT_ISSUABLE":
      return ProposalClientStatus.PROPOSAL_NOT_ISSUABLE;
    case 5:
    case "PROPOSAL_REWARDED":
      return ProposalClientStatus.PROPOSAL_REWARDED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProposalClientStatus.UNRECOGNIZED;
  }
}

export function proposalClientStatusToJSON(object: ProposalClientStatus): string {
  switch (object) {
    case ProposalClientStatus.PROPOSAL_CLIENT_BEGIN:
      return "PROPOSAL_CLIENT_BEGIN";
    case ProposalClientStatus.PROPOSAL_ISSUABLE:
      return "PROPOSAL_ISSUABLE";
    case ProposalClientStatus.PROPOSAL_ISSUING:
      return "PROPOSAL_ISSUING";
    case ProposalClientStatus.PROPOSAL_ISSUED:
      return "PROPOSAL_ISSUED";
    case ProposalClientStatus.PROPOSAL_NOT_ISSUABLE:
      return "PROPOSAL_NOT_ISSUABLE";
    case ProposalClientStatus.PROPOSAL_REWARDED:
      return "PROPOSAL_REWARDED";
    case ProposalClientStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DailyQuestionAnswerType {
  /** DAILY_QUESTION_ANSWER_TYPE_BEGIN - place holder for 0 */
  DAILY_QUESTION_ANSWER_TYPE_BEGIN = 0,
  DAILY_QUESTION_ANSWER_SINGLE = 1,
  DAILY_QUESTION_ANSWER_MULTI = 2,
  UNRECOGNIZED = -1,
}

export function dailyQuestionAnswerTypeFromJSON(object: any): DailyQuestionAnswerType {
  switch (object) {
    case 0:
    case "DAILY_QUESTION_ANSWER_TYPE_BEGIN":
      return DailyQuestionAnswerType.DAILY_QUESTION_ANSWER_TYPE_BEGIN;
    case 1:
    case "DAILY_QUESTION_ANSWER_SINGLE":
      return DailyQuestionAnswerType.DAILY_QUESTION_ANSWER_SINGLE;
    case 2:
    case "DAILY_QUESTION_ANSWER_MULTI":
      return DailyQuestionAnswerType.DAILY_QUESTION_ANSWER_MULTI;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DailyQuestionAnswerType.UNRECOGNIZED;
  }
}

export function dailyQuestionAnswerTypeToJSON(object: DailyQuestionAnswerType): string {
  switch (object) {
    case DailyQuestionAnswerType.DAILY_QUESTION_ANSWER_TYPE_BEGIN:
      return "DAILY_QUESTION_ANSWER_TYPE_BEGIN";
    case DailyQuestionAnswerType.DAILY_QUESTION_ANSWER_SINGLE:
      return "DAILY_QUESTION_ANSWER_SINGLE";
    case DailyQuestionAnswerType.DAILY_QUESTION_ANSWER_MULTI:
      return "DAILY_QUESTION_ANSWER_MULTI";
    case DailyQuestionAnswerType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DailyQuestionViewType {
  /** DAILY_QUESTION_VIEW_TYPE_BEGIN - place holder for 0 */
  DAILY_QUESTION_VIEW_TYPE_BEGIN = 0,
  DAILY_QUESTION_VIEW_CHOICE = 1,
  UNRECOGNIZED = -1,
}

export function dailyQuestionViewTypeFromJSON(object: any): DailyQuestionViewType {
  switch (object) {
    case 0:
    case "DAILY_QUESTION_VIEW_TYPE_BEGIN":
      return DailyQuestionViewType.DAILY_QUESTION_VIEW_TYPE_BEGIN;
    case 1:
    case "DAILY_QUESTION_VIEW_CHOICE":
      return DailyQuestionViewType.DAILY_QUESTION_VIEW_CHOICE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DailyQuestionViewType.UNRECOGNIZED;
  }
}

export function dailyQuestionViewTypeToJSON(object: DailyQuestionViewType): string {
  switch (object) {
    case DailyQuestionViewType.DAILY_QUESTION_VIEW_TYPE_BEGIN:
      return "DAILY_QUESTION_VIEW_TYPE_BEGIN";
    case DailyQuestionViewType.DAILY_QUESTION_VIEW_CHOICE:
      return "DAILY_QUESTION_VIEW_CHOICE";
    case DailyQuestionViewType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EnterpriseRewardType {
  TYPE_ENTERPRISE_REWARD_BEGIN = 0,
  /** ENTERPRISE_REWARD_FROM_SERVER - points will be awarded through the scheduler in server */
  ENTERPRISE_REWARD_FROM_SERVER = 1,
  UNRECOGNIZED = -1,
}

export function enterpriseRewardTypeFromJSON(object: any): EnterpriseRewardType {
  switch (object) {
    case 0:
    case "TYPE_ENTERPRISE_REWARD_BEGIN":
      return EnterpriseRewardType.TYPE_ENTERPRISE_REWARD_BEGIN;
    case 1:
    case "ENTERPRISE_REWARD_FROM_SERVER":
      return EnterpriseRewardType.ENTERPRISE_REWARD_FROM_SERVER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EnterpriseRewardType.UNRECOGNIZED;
  }
}

export function enterpriseRewardTypeToJSON(object: EnterpriseRewardType): string {
  switch (object) {
    case EnterpriseRewardType.TYPE_ENTERPRISE_REWARD_BEGIN:
      return "TYPE_ENTERPRISE_REWARD_BEGIN";
    case EnterpriseRewardType.ENTERPRISE_REWARD_FROM_SERVER:
      return "ENTERPRISE_REWARD_FROM_SERVER";
    case EnterpriseRewardType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CodeType {
  TYPE_ENTERPRISE_CODE_BEGIN = 0,
  /** ONE_ENTERPRISE_CODE_PER_PROMOTION - single promotion code */
  ONE_ENTERPRISE_CODE_PER_PROMOTION = 1,
  /** ONE_ENTERPRISE_CODE_PER_PARTICIPANT - single promotion code per participant */
  ONE_ENTERPRISE_CODE_PER_PARTICIPANT = 2,
  UNRECOGNIZED = -1,
}

export function codeTypeFromJSON(object: any): CodeType {
  switch (object) {
    case 0:
    case "TYPE_ENTERPRISE_CODE_BEGIN":
      return CodeType.TYPE_ENTERPRISE_CODE_BEGIN;
    case 1:
    case "ONE_ENTERPRISE_CODE_PER_PROMOTION":
      return CodeType.ONE_ENTERPRISE_CODE_PER_PROMOTION;
    case 2:
    case "ONE_ENTERPRISE_CODE_PER_PARTICIPANT":
      return CodeType.ONE_ENTERPRISE_CODE_PER_PARTICIPANT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CodeType.UNRECOGNIZED;
  }
}

export function codeTypeToJSON(object: CodeType): string {
  switch (object) {
    case CodeType.TYPE_ENTERPRISE_CODE_BEGIN:
      return "TYPE_ENTERPRISE_CODE_BEGIN";
    case CodeType.ONE_ENTERPRISE_CODE_PER_PROMOTION:
      return "ONE_ENTERPRISE_CODE_PER_PROMOTION";
    case CodeType.ONE_ENTERPRISE_CODE_PER_PARTICIPANT:
      return "ONE_ENTERPRISE_CODE_PER_PARTICIPANT";
    case CodeType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum VoIPCallState {
  /** VOIP_BEGIN - placeholder for 0 */
  VOIP_BEGIN = 0,
  RINGING = 1,
  CONNECTED = 2,
  CLOSED = 3,
  UNRECOGNIZED = -1,
}

export function voIPCallStateFromJSON(object: any): VoIPCallState {
  switch (object) {
    case 0:
    case "VOIP_BEGIN":
      return VoIPCallState.VOIP_BEGIN;
    case 1:
    case "RINGING":
      return VoIPCallState.RINGING;
    case 2:
    case "CONNECTED":
      return VoIPCallState.CONNECTED;
    case 3:
    case "CLOSED":
      return VoIPCallState.CLOSED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VoIPCallState.UNRECOGNIZED;
  }
}

export function voIPCallStateToJSON(object: VoIPCallState): string {
  switch (object) {
    case VoIPCallState.VOIP_BEGIN:
      return "VOIP_BEGIN";
    case VoIPCallState.RINGING:
      return "RINGING";
    case VoIPCallState.CONNECTED:
      return "CONNECTED";
    case VoIPCallState.CLOSED:
      return "CLOSED";
    case VoIPCallState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum VoIPSignalAction {
  /** ACTION_BEGIN - placeholder for 0 */
  ACTION_BEGIN = 0,
  /** ACTION_START - signaling */
  ACTION_START = 1,
  ACTION_ANSWER = 2,
  ACTION_HANGUP = 3,
  ACTION_CLOSED_BY_SERVER = 4,
  /** ACTION_SEND_P2P - for media channel */
  ACTION_SEND_P2P = 11,
  /** ACTION_INFORM_MEDIA_CONNECTED - informing media connected */
  ACTION_INFORM_MEDIA_CONNECTED = 12,
  /** ACTION_FETCH_MESSAGES - fetch messages */
  ACTION_FETCH_MESSAGES = 13,
  UNRECOGNIZED = -1,
}

export function voIPSignalActionFromJSON(object: any): VoIPSignalAction {
  switch (object) {
    case 0:
    case "ACTION_BEGIN":
      return VoIPSignalAction.ACTION_BEGIN;
    case 1:
    case "ACTION_START":
      return VoIPSignalAction.ACTION_START;
    case 2:
    case "ACTION_ANSWER":
      return VoIPSignalAction.ACTION_ANSWER;
    case 3:
    case "ACTION_HANGUP":
      return VoIPSignalAction.ACTION_HANGUP;
    case 4:
    case "ACTION_CLOSED_BY_SERVER":
      return VoIPSignalAction.ACTION_CLOSED_BY_SERVER;
    case 11:
    case "ACTION_SEND_P2P":
      return VoIPSignalAction.ACTION_SEND_P2P;
    case 12:
    case "ACTION_INFORM_MEDIA_CONNECTED":
      return VoIPSignalAction.ACTION_INFORM_MEDIA_CONNECTED;
    case 13:
    case "ACTION_FETCH_MESSAGES":
      return VoIPSignalAction.ACTION_FETCH_MESSAGES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VoIPSignalAction.UNRECOGNIZED;
  }
}

export function voIPSignalActionToJSON(object: VoIPSignalAction): string {
  switch (object) {
    case VoIPSignalAction.ACTION_BEGIN:
      return "ACTION_BEGIN";
    case VoIPSignalAction.ACTION_START:
      return "ACTION_START";
    case VoIPSignalAction.ACTION_ANSWER:
      return "ACTION_ANSWER";
    case VoIPSignalAction.ACTION_HANGUP:
      return "ACTION_HANGUP";
    case VoIPSignalAction.ACTION_CLOSED_BY_SERVER:
      return "ACTION_CLOSED_BY_SERVER";
    case VoIPSignalAction.ACTION_SEND_P2P:
      return "ACTION_SEND_P2P";
    case VoIPSignalAction.ACTION_INFORM_MEDIA_CONNECTED:
      return "ACTION_INFORM_MEDIA_CONNECTED";
    case VoIPSignalAction.ACTION_FETCH_MESSAGES:
      return "ACTION_FETCH_MESSAGES";
    case VoIPSignalAction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum VoIPP2PMessageType {
  /** P2P_BEGIN - placeholder for 0 */
  P2P_BEGIN = 0,
  WEBRTC_OFFER = 1,
  WEBRTC_ANSWER = 2,
  WEBRTC_CANDIDATE = 3,
  UNRECOGNIZED = -1,
}

export function voIPP2PMessageTypeFromJSON(object: any): VoIPP2PMessageType {
  switch (object) {
    case 0:
    case "P2P_BEGIN":
      return VoIPP2PMessageType.P2P_BEGIN;
    case 1:
    case "WEBRTC_OFFER":
      return VoIPP2PMessageType.WEBRTC_OFFER;
    case 2:
    case "WEBRTC_ANSWER":
      return VoIPP2PMessageType.WEBRTC_ANSWER;
    case 3:
    case "WEBRTC_CANDIDATE":
      return VoIPP2PMessageType.WEBRTC_CANDIDATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VoIPP2PMessageType.UNRECOGNIZED;
  }
}

export function voIPP2PMessageTypeToJSON(object: VoIPP2PMessageType): string {
  switch (object) {
    case VoIPP2PMessageType.P2P_BEGIN:
      return "P2P_BEGIN";
    case VoIPP2PMessageType.WEBRTC_OFFER:
      return "WEBRTC_OFFER";
    case VoIPP2PMessageType.WEBRTC_ANSWER:
      return "WEBRTC_ANSWER";
    case VoIPP2PMessageType.WEBRTC_CANDIDATE:
      return "WEBRTC_CANDIDATE";
    case VoIPP2PMessageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum VoIPP2PMessageDirection {
  /** DIRECTION_BEGIN - placeholder for 0 */
  DIRECTION_BEGIN = 0,
  TO_CALLEE = 1,
  TO_CALLER = 2,
  UNRECOGNIZED = -1,
}

export function voIPP2PMessageDirectionFromJSON(object: any): VoIPP2PMessageDirection {
  switch (object) {
    case 0:
    case "DIRECTION_BEGIN":
      return VoIPP2PMessageDirection.DIRECTION_BEGIN;
    case 1:
    case "TO_CALLEE":
      return VoIPP2PMessageDirection.TO_CALLEE;
    case 2:
    case "TO_CALLER":
      return VoIPP2PMessageDirection.TO_CALLER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VoIPP2PMessageDirection.UNRECOGNIZED;
  }
}

export function voIPP2PMessageDirectionToJSON(object: VoIPP2PMessageDirection): string {
  switch (object) {
    case VoIPP2PMessageDirection.DIRECTION_BEGIN:
      return "DIRECTION_BEGIN";
    case VoIPP2PMessageDirection.TO_CALLEE:
      return "TO_CALLEE";
    case VoIPP2PMessageDirection.TO_CALLER:
      return "TO_CALLER";
    case VoIPP2PMessageDirection.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PreregisteredPromotionStatus {
  PREREGISTERED_PROMOTION_STATUS_BEGIN = 0,
  AVAILABLE = 1,
  PUBLISHED = 2,
  USED = 3,
  UNRECOGNIZED = -1,
}

export function preregisteredPromotionStatusFromJSON(object: any): PreregisteredPromotionStatus {
  switch (object) {
    case 0:
    case "PREREGISTERED_PROMOTION_STATUS_BEGIN":
      return PreregisteredPromotionStatus.PREREGISTERED_PROMOTION_STATUS_BEGIN;
    case 1:
    case "AVAILABLE":
      return PreregisteredPromotionStatus.AVAILABLE;
    case 2:
    case "PUBLISHED":
      return PreregisteredPromotionStatus.PUBLISHED;
    case 3:
    case "USED":
      return PreregisteredPromotionStatus.USED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PreregisteredPromotionStatus.UNRECOGNIZED;
  }
}

export function preregisteredPromotionStatusToJSON(object: PreregisteredPromotionStatus): string {
  switch (object) {
    case PreregisteredPromotionStatus.PREREGISTERED_PROMOTION_STATUS_BEGIN:
      return "PREREGISTERED_PROMOTION_STATUS_BEGIN";
    case PreregisteredPromotionStatus.AVAILABLE:
      return "AVAILABLE";
    case PreregisteredPromotionStatus.PUBLISHED:
      return "PUBLISHED";
    case PreregisteredPromotionStatus.USED:
      return "USED";
    case PreregisteredPromotionStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PollType {
  POLL_TYPE_BEGIN = 0,
  MYD_POLL = 1,
  UNRECOGNIZED = -1,
}

export function pollTypeFromJSON(object: any): PollType {
  switch (object) {
    case 0:
    case "POLL_TYPE_BEGIN":
      return PollType.POLL_TYPE_BEGIN;
    case 1:
    case "MYD_POLL":
      return PollType.MYD_POLL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PollType.UNRECOGNIZED;
  }
}

export function pollTypeToJSON(object: PollType): string {
  switch (object) {
    case PollType.POLL_TYPE_BEGIN:
      return "POLL_TYPE_BEGIN";
    case PollType.MYD_POLL:
      return "MYD_POLL";
    case PollType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PollInfoCategory {
  POLL_INFO_CATEGORY_BEGIN = 0,
  POLL_CATEGORY_ALL = 1,
  POLL_CATEGORY_IT_TECH = 2,
  POLL_CATEGORY_HEALTH = 3,
  POLL_CATEGORY_FINANCE = 4,
  POLL_CATEGORY_BEAUTY_FASHION = 5,
  POLL_CATEGORY_LOVE = 6,
  POLL_CATEGORY_SPEND_APPTECH = 7,
  POLL_CATEGORY_EVENT = 8,
  POLL_CATEGORY_ISSUE = 9,
  POLL_CATEGORY_JOB = 10,
  POLL_CATEGORY_HOBBY = 11,
  POLL_CATEGORY_ETC = 99,
  UNRECOGNIZED = -1,
}

export function pollInfoCategoryFromJSON(object: any): PollInfoCategory {
  switch (object) {
    case 0:
    case "POLL_INFO_CATEGORY_BEGIN":
      return PollInfoCategory.POLL_INFO_CATEGORY_BEGIN;
    case 1:
    case "POLL_CATEGORY_ALL":
      return PollInfoCategory.POLL_CATEGORY_ALL;
    case 2:
    case "POLL_CATEGORY_IT_TECH":
      return PollInfoCategory.POLL_CATEGORY_IT_TECH;
    case 3:
    case "POLL_CATEGORY_HEALTH":
      return PollInfoCategory.POLL_CATEGORY_HEALTH;
    case 4:
    case "POLL_CATEGORY_FINANCE":
      return PollInfoCategory.POLL_CATEGORY_FINANCE;
    case 5:
    case "POLL_CATEGORY_BEAUTY_FASHION":
      return PollInfoCategory.POLL_CATEGORY_BEAUTY_FASHION;
    case 6:
    case "POLL_CATEGORY_LOVE":
      return PollInfoCategory.POLL_CATEGORY_LOVE;
    case 7:
    case "POLL_CATEGORY_SPEND_APPTECH":
      return PollInfoCategory.POLL_CATEGORY_SPEND_APPTECH;
    case 8:
    case "POLL_CATEGORY_EVENT":
      return PollInfoCategory.POLL_CATEGORY_EVENT;
    case 9:
    case "POLL_CATEGORY_ISSUE":
      return PollInfoCategory.POLL_CATEGORY_ISSUE;
    case 10:
    case "POLL_CATEGORY_JOB":
      return PollInfoCategory.POLL_CATEGORY_JOB;
    case 11:
    case "POLL_CATEGORY_HOBBY":
      return PollInfoCategory.POLL_CATEGORY_HOBBY;
    case 99:
    case "POLL_CATEGORY_ETC":
      return PollInfoCategory.POLL_CATEGORY_ETC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PollInfoCategory.UNRECOGNIZED;
  }
}

export function pollInfoCategoryToJSON(object: PollInfoCategory): string {
  switch (object) {
    case PollInfoCategory.POLL_INFO_CATEGORY_BEGIN:
      return "POLL_INFO_CATEGORY_BEGIN";
    case PollInfoCategory.POLL_CATEGORY_ALL:
      return "POLL_CATEGORY_ALL";
    case PollInfoCategory.POLL_CATEGORY_IT_TECH:
      return "POLL_CATEGORY_IT_TECH";
    case PollInfoCategory.POLL_CATEGORY_HEALTH:
      return "POLL_CATEGORY_HEALTH";
    case PollInfoCategory.POLL_CATEGORY_FINANCE:
      return "POLL_CATEGORY_FINANCE";
    case PollInfoCategory.POLL_CATEGORY_BEAUTY_FASHION:
      return "POLL_CATEGORY_BEAUTY_FASHION";
    case PollInfoCategory.POLL_CATEGORY_LOVE:
      return "POLL_CATEGORY_LOVE";
    case PollInfoCategory.POLL_CATEGORY_SPEND_APPTECH:
      return "POLL_CATEGORY_SPEND_APPTECH";
    case PollInfoCategory.POLL_CATEGORY_EVENT:
      return "POLL_CATEGORY_EVENT";
    case PollInfoCategory.POLL_CATEGORY_ISSUE:
      return "POLL_CATEGORY_ISSUE";
    case PollInfoCategory.POLL_CATEGORY_JOB:
      return "POLL_CATEGORY_JOB";
    case PollInfoCategory.POLL_CATEGORY_HOBBY:
      return "POLL_CATEGORY_HOBBY";
    case PollInfoCategory.POLL_CATEGORY_ETC:
      return "POLL_CATEGORY_ETC";
    case PollInfoCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum QuestionType {
  QUESTION_TYPE_BEGIN = 0,
  QUESTION_SINGLE = 1,
  QUESTION_PLURAL = 2,
  QUESTION_CORRECT_ANSWER = 3,
  QUESTION_CORRECT_MULTI_ANSWER = 4,
  QUESTION_DESCRIPTION = 5,
  UNRECOGNIZED = -1,
}

export function questionTypeFromJSON(object: any): QuestionType {
  switch (object) {
    case 0:
    case "QUESTION_TYPE_BEGIN":
      return QuestionType.QUESTION_TYPE_BEGIN;
    case 1:
    case "QUESTION_SINGLE":
      return QuestionType.QUESTION_SINGLE;
    case 2:
    case "QUESTION_PLURAL":
      return QuestionType.QUESTION_PLURAL;
    case 3:
    case "QUESTION_CORRECT_ANSWER":
      return QuestionType.QUESTION_CORRECT_ANSWER;
    case 4:
    case "QUESTION_CORRECT_MULTI_ANSWER":
      return QuestionType.QUESTION_CORRECT_MULTI_ANSWER;
    case 5:
    case "QUESTION_DESCRIPTION":
      return QuestionType.QUESTION_DESCRIPTION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QuestionType.UNRECOGNIZED;
  }
}

export function questionTypeToJSON(object: QuestionType): string {
  switch (object) {
    case QuestionType.QUESTION_TYPE_BEGIN:
      return "QUESTION_TYPE_BEGIN";
    case QuestionType.QUESTION_SINGLE:
      return "QUESTION_SINGLE";
    case QuestionType.QUESTION_PLURAL:
      return "QUESTION_PLURAL";
    case QuestionType.QUESTION_CORRECT_ANSWER:
      return "QUESTION_CORRECT_ANSWER";
    case QuestionType.QUESTION_CORRECT_MULTI_ANSWER:
      return "QUESTION_CORRECT_MULTI_ANSWER";
    case QuestionType.QUESTION_DESCRIPTION:
      return "QUESTION_DESCRIPTION";
    case QuestionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PollInfoStatus {
  POLL_INFO_TYPE_BEGIN = 0,
  POLL_INFO_TEMPORARY = 1,
  POLL_INFO_PENDING = 2,
  POLL_INFO_ACTIVE = 3,
  POLL_INFO_FINISHED = 4,
  POLL_INFO_DELETE = 5,
  POLL_INFO_REVOKE = 6,
  POLL_INFO_HIDDEN = 7,
  UNRECOGNIZED = -1,
}

export function pollInfoStatusFromJSON(object: any): PollInfoStatus {
  switch (object) {
    case 0:
    case "POLL_INFO_TYPE_BEGIN":
      return PollInfoStatus.POLL_INFO_TYPE_BEGIN;
    case 1:
    case "POLL_INFO_TEMPORARY":
      return PollInfoStatus.POLL_INFO_TEMPORARY;
    case 2:
    case "POLL_INFO_PENDING":
      return PollInfoStatus.POLL_INFO_PENDING;
    case 3:
    case "POLL_INFO_ACTIVE":
      return PollInfoStatus.POLL_INFO_ACTIVE;
    case 4:
    case "POLL_INFO_FINISHED":
      return PollInfoStatus.POLL_INFO_FINISHED;
    case 5:
    case "POLL_INFO_DELETE":
      return PollInfoStatus.POLL_INFO_DELETE;
    case 6:
    case "POLL_INFO_REVOKE":
      return PollInfoStatus.POLL_INFO_REVOKE;
    case 7:
    case "POLL_INFO_HIDDEN":
      return PollInfoStatus.POLL_INFO_HIDDEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PollInfoStatus.UNRECOGNIZED;
  }
}

export function pollInfoStatusToJSON(object: PollInfoStatus): string {
  switch (object) {
    case PollInfoStatus.POLL_INFO_TYPE_BEGIN:
      return "POLL_INFO_TYPE_BEGIN";
    case PollInfoStatus.POLL_INFO_TEMPORARY:
      return "POLL_INFO_TEMPORARY";
    case PollInfoStatus.POLL_INFO_PENDING:
      return "POLL_INFO_PENDING";
    case PollInfoStatus.POLL_INFO_ACTIVE:
      return "POLL_INFO_ACTIVE";
    case PollInfoStatus.POLL_INFO_FINISHED:
      return "POLL_INFO_FINISHED";
    case PollInfoStatus.POLL_INFO_DELETE:
      return "POLL_INFO_DELETE";
    case PollInfoStatus.POLL_INFO_REVOKE:
      return "POLL_INFO_REVOKE";
    case PollInfoStatus.POLL_INFO_HIDDEN:
      return "POLL_INFO_HIDDEN";
    case PollInfoStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SpamStatus {
  SPAM_STATUS_BEGIN = 0,
  SPAM_STATUS_NONE = 1,
  SPAM_STATUS_REPORTED = 2,
  SPAM_STATUS_APPROVED = 3,
  SPAM_STATUS_NONAPPROVED = 4,
  UNRECOGNIZED = -1,
}

export function spamStatusFromJSON(object: any): SpamStatus {
  switch (object) {
    case 0:
    case "SPAM_STATUS_BEGIN":
      return SpamStatus.SPAM_STATUS_BEGIN;
    case 1:
    case "SPAM_STATUS_NONE":
      return SpamStatus.SPAM_STATUS_NONE;
    case 2:
    case "SPAM_STATUS_REPORTED":
      return SpamStatus.SPAM_STATUS_REPORTED;
    case 3:
    case "SPAM_STATUS_APPROVED":
      return SpamStatus.SPAM_STATUS_APPROVED;
    case 4:
    case "SPAM_STATUS_NONAPPROVED":
      return SpamStatus.SPAM_STATUS_NONAPPROVED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SpamStatus.UNRECOGNIZED;
  }
}

export function spamStatusToJSON(object: SpamStatus): string {
  switch (object) {
    case SpamStatus.SPAM_STATUS_BEGIN:
      return "SPAM_STATUS_BEGIN";
    case SpamStatus.SPAM_STATUS_NONE:
      return "SPAM_STATUS_NONE";
    case SpamStatus.SPAM_STATUS_REPORTED:
      return "SPAM_STATUS_REPORTED";
    case SpamStatus.SPAM_STATUS_APPROVED:
      return "SPAM_STATUS_APPROVED";
    case SpamStatus.SPAM_STATUS_NONAPPROVED:
      return "SPAM_STATUS_NONAPPROVED";
    case SpamStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ReactionCode {
  Poll_Reaction_BEGIN = 0,
  LIKE = 1,
  CHEER = 2,
  FUN = 3,
  SAD = 4,
  UNRECOGNIZED = -1,
}

export function reactionCodeFromJSON(object: any): ReactionCode {
  switch (object) {
    case 0:
    case "Poll_Reaction_BEGIN":
      return ReactionCode.Poll_Reaction_BEGIN;
    case 1:
    case "LIKE":
      return ReactionCode.LIKE;
    case 2:
    case "CHEER":
      return ReactionCode.CHEER;
    case 3:
    case "FUN":
      return ReactionCode.FUN;
    case 4:
    case "SAD":
      return ReactionCode.SAD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ReactionCode.UNRECOGNIZED;
  }
}

export function reactionCodeToJSON(object: ReactionCode): string {
  switch (object) {
    case ReactionCode.Poll_Reaction_BEGIN:
      return "Poll_Reaction_BEGIN";
    case ReactionCode.LIKE:
      return "LIKE";
    case ReactionCode.CHEER:
      return "CHEER";
    case ReactionCode.FUN:
      return "FUN";
    case ReactionCode.SAD:
      return "SAD";
    case ReactionCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PollRewardStatus {
  POLL_REWARD_STATUS_BEGIN = 0,
  POLL_REWARD_STATUS_REPORTED = 1,
  POLL_REWARD_STATUS_APPROVED = 2,
  POLL_REWARD_STATUS_ERROR = 3,
  POLL_REWARD_EXCEED_MAX_ISSUABLE = 4,
  POLL_REWARD_ZERO_POINT = 5,
  UNRECOGNIZED = -1,
}

export function pollRewardStatusFromJSON(object: any): PollRewardStatus {
  switch (object) {
    case 0:
    case "POLL_REWARD_STATUS_BEGIN":
      return PollRewardStatus.POLL_REWARD_STATUS_BEGIN;
    case 1:
    case "POLL_REWARD_STATUS_REPORTED":
      return PollRewardStatus.POLL_REWARD_STATUS_REPORTED;
    case 2:
    case "POLL_REWARD_STATUS_APPROVED":
      return PollRewardStatus.POLL_REWARD_STATUS_APPROVED;
    case 3:
    case "POLL_REWARD_STATUS_ERROR":
      return PollRewardStatus.POLL_REWARD_STATUS_ERROR;
    case 4:
    case "POLL_REWARD_EXCEED_MAX_ISSUABLE":
      return PollRewardStatus.POLL_REWARD_EXCEED_MAX_ISSUABLE;
    case 5:
    case "POLL_REWARD_ZERO_POINT":
      return PollRewardStatus.POLL_REWARD_ZERO_POINT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PollRewardStatus.UNRECOGNIZED;
  }
}

export function pollRewardStatusToJSON(object: PollRewardStatus): string {
  switch (object) {
    case PollRewardStatus.POLL_REWARD_STATUS_BEGIN:
      return "POLL_REWARD_STATUS_BEGIN";
    case PollRewardStatus.POLL_REWARD_STATUS_REPORTED:
      return "POLL_REWARD_STATUS_REPORTED";
    case PollRewardStatus.POLL_REWARD_STATUS_APPROVED:
      return "POLL_REWARD_STATUS_APPROVED";
    case PollRewardStatus.POLL_REWARD_STATUS_ERROR:
      return "POLL_REWARD_STATUS_ERROR";
    case PollRewardStatus.POLL_REWARD_EXCEED_MAX_ISSUABLE:
      return "POLL_REWARD_EXCEED_MAX_ISSUABLE";
    case PollRewardStatus.POLL_REWARD_ZERO_POINT:
      return "POLL_REWARD_ZERO_POINT";
    case PollRewardStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface RewardPolicy {
  amount: number;
  tokenDuration?: string | undefined;
  marginRate?: number | undefined;
  marginBeneficiary?: string | undefined;
  policyType: RewardPolicyType;
}

export interface Company {
  address: string;
  ceo: string;
  name: string;
  registrationNo: string;
  tel: string;
}

export interface ContentDescription {
  company?: Company | undefined;
  detail?: string | undefined;
  title: string;
  privacyPolicy?: string | undefined;
}

export interface ContentImageURL {
  banner?: string | undefined;
  icon?: string | undefined;
  landing?: string | undefined;
}

export interface ContentLDPConfig {
  formula: number;
  privacyBudget: number;
}

export interface ContentLDPTargetBin {
  bin: number[];
}

export interface ContentLDPTarget {
  path: string;
  selectQuery: string;
  binType: string;
  bins: ContentLDPTargetBin[];
  title: string;
  labels: string[];
}

export interface ContentAdvertisementGoods {
  name: string;
  manufacturer: string;
  price: number;
}

export interface ContentAdvertisement {
  type: number;
  displayArea: number;
  moreInfo: string;
  goods: ContentAdvertisementGoods | undefined;
}

export interface ContentPrivacyPolicyThirdParty {
  processing: number;
  name: string;
  purpose: string;
}

export interface ContentPrivacyPolicy {
  identifier: number;
  thirdParty?: ContentPrivacyPolicyThirdParty | undefined;
}

export interface ContentEventViewTransmit {
  buttonText: string;
}

export interface ContentEventView {
  target: string;
  transmit: ContentEventViewTransmit | undefined;
}

export interface ContentEvent {
  genre: string;
  view: ContentEventView | undefined;
}

export interface ContentPanel {
  baseUrl: string;
  params: string[];
}

export interface Content {
  contentDescription?: ContentDescription | undefined;
  contentImageUrl?: ContentImageURL | undefined;
  contentLdpConfig?: ContentLDPConfig | undefined;
  contentPurchase?: ContentPurchase | undefined;
  contentAdvertisement?: ContentAdvertisement | undefined;
  contentLdpTarget: ContentLDPTarget[];
  contentEvent?: ContentEvent | undefined;
  contentPanel?: ContentPanel | undefined;
  contentPrivacyPolicy?: ContentPrivacyPolicy | undefined;
}

export interface ContentPurchase {
  category: number[];
  maskingOptions: number[];
  selectQuery?: string | undefined;
  selectRange?: string | undefined;
  uploadUrl?: string | undefined;
}

export interface DailyQuestionOption {
  value: string;
  description?: string | undefined;
  imageUrl?: string | undefined;
}

export interface DailyQuestionContent {
  /** image url */
  imageUrl: string;
  /** view type of the DQ */
  viewType: DailyQuestionViewType;
  /** answer type of the DQ */
  answerType: DailyQuestionAnswerType;
  /** title of the DQ */
  title: string;
  /** description of the DQ */
  description: string;
  /** DB index of the DQ */
  questionId: number;
  /** category of the DQ */
  questionCategory: string;
  /** number of answers can be checked */
  maxCheck: number;
  /** all options */
  options: DailyQuestionOption[];
}

export interface InviteFriendContent {
  /** image url */
  imageUrl: string;
  /** title of the InviteFriend */
  title: string;
  /** description of the InviteFriend */
  description: string;
  /** Number of usable per invitation code */
  maxCountPerInvitationCode: number;
  /** Code for unlimited use */
  unlimitedCodeList: string[];
  /** true to give point to host */
  giveRewardToHost: boolean;
  /** landing url */
  landingUrl: string;
}

export interface AttendanceContent {
  /** image url */
  imageUrl: string;
  /** title of the proposal */
  title: string;
  /** description of the proposal */
  description: string;
  /** amount for a daily mission */
  dailyMissionReward: number;
  /** amount for a weekly mission */
  weeklyMissionReward: number;
  /** landing url */
  landingUrl: string;
}

export interface ProposalStatic {
  /** proposal identifiers */
  proposalId: string;
  proposalAddress: string;
  owner: string;
  purpose: number;
  /** basic properties */
  notBefore: string;
  /** RFC 3339 */
  notAfter: string;
  /** false: visible by default, true: hidden by default */
  hidden: boolean;
  /** data-market specific categories */
  category: number[];
  /** client specific data */
  condition?: string | undefined;
  filter?: string | undefined;
  content?:
    | Content
    | undefined;
  /** 0 for infinity */
  maxIssuable?: number | undefined;
  maxConsumeCount?:
    | number
    | undefined;
  /** 0 for no restriction */
  minConsumeInterval?: number | undefined;
  rewardPolicy?:
    | RewardPolicy
    | undefined;
  /** RFC 3339 */
  ticketValidFrom?: string | undefined;
  ticketValidityInSeconds?:
    | number
    | undefined;
  /** RFC 3339 */
  ticketValidUntil?:
    | string
    | undefined;
  /** proposal specific data */
  dailyQuestionContent?: DailyQuestionContent | undefined;
  inviteFriendContent?: InviteFriendContent | undefined;
  attendanceContent?: AttendanceContent | undefined;
  enterprisePromotionContent?: EnterprisePromotionContent | undefined;
}

export interface ProposalActiveGlobal {
  /** proposal status; same status for all client */
  proposalStatus: ProposalStatus;
  /** timestamp for events */
  registeredAt?:
    | string
    | undefined;
  /** RFC 3339, from blockchain */
  activatedAt?:
    | string
    | undefined;
  /** RFC 3339, from blockchain */
  revokedAt?:
    | string
    | undefined;
  /** RFC 3339, from blockchain */
  droughtAt?:
    | string
    | undefined;
  /** RFC 3339, from blockchain */
  completedAt?:
    | string
    | undefined;
  /** RFC 3339, from middleware */
  updatedAt?:
    | string
    | undefined;
  /** RFC 3339, from middleware */
  deactivatedAt?:
    | string
    | undefined;
  /** Daily question only */
  totalIssued?: number | undefined;
}

export interface ProposalDataUploadStatus {
  oneTimeId: string;
  /** RFC 3339 */
  oneTimeIdRequestedAt?:
    | string
    | undefined;
  /** RFC 3339, Middleware */
  dataUploadRequestedAt?:
    | string
    | undefined;
  /** RFC 3339 */
  dataUploadedAt?: string | undefined;
}

/**
 * ProposalActiveClient for the proposal-control-proposal.
 * A list of visible proposal for a specific DID
 * This is designed for the did-targeting from server.
 */
export interface ProposalFiltering {
  visibleProposals: string[];
  forbiddenProposals: string[];
}

export interface ProposalActiveClient {
  /** proposal status only for this device; replaces ticket */
  proposalClientStatus: ProposalClientStatus;
  /** timestamp for events */
  issueRequestedAt?:
    | string
    | undefined;
  /** RFC 3339, from blockchain */
  issuedAt?:
    | string
    | undefined;
  /**
   * rewarded completely; from blockchain
   * empty if there is any reward can be received before TicketValidFrom + TicketDuration
   */
  rewardedCompletelyAt?:
    | string
    | undefined;
  /** for data market */
  uploadStatus: ProposalDataUploadStatus[];
  /** ticket id to upload data */
  ticketId?:
    | string
    | undefined;
  /** proposal control proposal */
  filtering?:
    | ProposalFiltering
    | undefined;
  /** v2 ticket not uploadable */
  ticketFromMyd2: boolean;
  /** promotion reward & participant code's history */
  enterprisePromotionClient?: EnterprisePromotionClient | undefined;
}

export interface Proposal {
  proposalId: string;
  static?: ProposalStatic | undefined;
  activeGlobal?: ProposalActiveGlobal | undefined;
  activeClient?: ProposalActiveClient | undefined;
}

export interface EnterprisePromotionContent {
  /** this content's id. 1 based sequential number (1, 2, 3 ...) */
  contentId: number;
  /** promotion's code type (1: single promotion code per promotion, 2: single promotion code per participant) */
  codeType: CodeType;
  /** promotion code for company */
  promotionCode: string;
  /** reward item */
  promotionMission: EnterprisePromotionMission[];
  /** all of reward point */
  sumOfRewardPoint: number;
}

export interface EnterprisePromotionMission {
  /** description of mission */
  title: string;
  /** compensation due date */
  compensationDueDate: string;
  /** reward point */
  rewardAmount: number;
  /** mission period */
  missionPeriod: string;
  /** reward item of mission */
  rewardItem: EnterprisePromotionRewardItem[];
  /** reward type of mission */
  rewardType: EnterpriseRewardType;
}

export interface EnterprisePromotionRewardItem {
  /** query for Elasticsearch includes condition of reward */
  rewardConstraint: RewardConstraint | undefined;
}

export interface RewardConstraint {
  /** ELS boolean query syntax */
  must: ElasticSearchSingleCondition[];
  mustNot: ElasticSearchSingleCondition[];
  should: ElasticSearchSingleCondition[];
  minimumShouldMatch: number;
  /** proposal purpose */
  purpose: string;
}

export interface ElasticSearchQueryResult {
  longResult?: number | undefined;
  booleanResult?: boolean | undefined;
  stringresult?: string | undefined;
}

export interface ElasticSearchSingleCondition {
  /** resource id */
  queryTemplateId: string;
  /** target value */
  expectedValue: ElasticSearchQueryResult | undefined;
}

export interface EnterprisePromotionClient {
  /** hashing data of "birth date" + "name" + "phone number" */
  hash: Uint8Array;
  proposalId: string;
  companyDid: string;
  /** reward history */
  enterprisePromotionRewardHistory: EnterprisePromotionRewardHistory[];
  /** participant codes history */
  enterprisePromotionCodeHistory: EnterprisePromotionCodeHistory[];
}

export interface EnterprisePromotionRewardHistory {
  /** bought item id */
  itemId: number;
  /** content id */
  contentId: number;
  did: string;
  /** reward date */
  rewardedAt: string;
  /** reward point */
  rewardAmount: number;
  /** reward history status */
  status: number;
}

export interface EnterprisePromotionCodeHistory {
  /** participant code, not the promotion code of company */
  promotionCode?:
    | string
    | undefined;
  /** this hash uses instead of participant code */
  hash?: Uint8Array | undefined;
  did: string;
  /** date of promotion applied */
  appliedAt: string;
  /** code history status */
  status: number;
}

/**
 * Reward transaction content for the app
 * Every fields in this message would be shown to the end user
 */
export interface RewardTransactionContentForApp {
  /**
   * amount received to the account
   * it can be any value under 0 or above 0
   */
  amount: number;
  /** RFC3339 date time when the transaction is created */
  createdAt: string;
  /** category to show. for example, "Daily question", "Data market" */
  category: string;
  /** title to show. It can be a proposal title or goods name */
  title: string;
}

export interface VoIPSignalRequest {
  /** requestor did */
  did: string;
  /** call room ID; empty string for ACTION_START */
  callRoomId: string;
  /** call action */
  action: VoIPSignalAction;
  /** sequence number of the last received message; 0 to fetch all */
  lastSeq: number;
  /** sequence number of the message (set by server) */
  seq?: number | undefined;
  callTitle?: string | undefined;
  callerDid: string;
  /** only for ACTION_START */
  calleeDid?:
    | string
    | undefined;
  /** only for ACTION_SEND_P2P */
  direction?:
    | VoIPP2PMessageDirection
    | undefined;
  /** only for ACTION_SEND_P2P */
  p2pMessageType?:
    | VoIPP2PMessageType
    | undefined;
  /** only for ACTION_SEND_P2P */
  p2pMessage?: Uint8Array | undefined;
}

export interface VoIPSignalResponse {
  room: VoIPRoomDescription | undefined;
  requests: VoIPSignalRequest[];
}

export interface VoIPRoomDescription {
  roomId: string;
  callerDid: string;
  calleeDid: string;
  callState: VoIPCallState;
  callTitle?:
    | string
    | undefined;
  /** RFC-3339 */
  createdAt: string;
  /** RFC-3339 */
  callerMediaConnected: string;
  /** RFC-3339 */
  calleeMediaConnected: string;
  /** RFC-3339 */
  closedAt: string;
}

function createBaseRewardPolicy(): RewardPolicy {
  return { amount: 0, tokenDuration: undefined, marginRate: undefined, marginBeneficiary: undefined, policyType: 0 };
}

export const RewardPolicy = {
  encode(message: RewardPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== 0) {
      writer.uint32(8).uint32(message.amount);
    }
    if (message.tokenDuration !== undefined) {
      writer.uint32(18).string(message.tokenDuration);
    }
    if (message.marginRate !== undefined) {
      writer.uint32(29).float(message.marginRate);
    }
    if (message.marginBeneficiary !== undefined) {
      writer.uint32(42).string(message.marginBeneficiary);
    }
    if (message.policyType !== 0) {
      writer.uint32(32).int32(message.policyType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardPolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardPolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.amount = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tokenDuration = reader.string();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.marginRate = reader.float();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.marginBeneficiary = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.policyType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RewardPolicy {
    return {
      amount: isSet(object.amount) ? globalThis.Number(object.amount) : 0,
      tokenDuration: isSet(object.tokenDuration) ? globalThis.String(object.tokenDuration) : undefined,
      marginRate: isSet(object.marginRate) ? globalThis.Number(object.marginRate) : undefined,
      marginBeneficiary: isSet(object.marginBeneficiary) ? globalThis.String(object.marginBeneficiary) : undefined,
      policyType: isSet(object.policyType) ? rewardPolicyTypeFromJSON(object.policyType) : 0,
    };
  },

  toJSON(message: RewardPolicy): unknown {
    const obj: any = {};
    if (message.amount !== 0) {
      obj.amount = Math.round(message.amount);
    }
    if (message.tokenDuration !== undefined) {
      obj.tokenDuration = message.tokenDuration;
    }
    if (message.marginRate !== undefined) {
      obj.marginRate = message.marginRate;
    }
    if (message.marginBeneficiary !== undefined) {
      obj.marginBeneficiary = message.marginBeneficiary;
    }
    if (message.policyType !== 0) {
      obj.policyType = rewardPolicyTypeToJSON(message.policyType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardPolicy>, I>>(base?: I): RewardPolicy {
    return RewardPolicy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RewardPolicy>, I>>(object: I): RewardPolicy {
    const message = createBaseRewardPolicy();
    message.amount = object.amount ?? 0;
    message.tokenDuration = object.tokenDuration ?? undefined;
    message.marginRate = object.marginRate ?? undefined;
    message.marginBeneficiary = object.marginBeneficiary ?? undefined;
    message.policyType = object.policyType ?? 0;
    return message;
  },
};

function createBaseCompany(): Company {
  return { address: "", ceo: "", name: "", registrationNo: "", tel: "" };
}

export const Company = {
  encode(message: Company, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.ceo !== "") {
      writer.uint32(18).string(message.ceo);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.registrationNo !== "") {
      writer.uint32(34).string(message.registrationNo);
    }
    if (message.tel !== "") {
      writer.uint32(42).string(message.tel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Company {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompany();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ceo = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.registrationNo = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tel = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Company {
    return {
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      ceo: isSet(object.ceo) ? globalThis.String(object.ceo) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      registrationNo: isSet(object.registrationNo) ? globalThis.String(object.registrationNo) : "",
      tel: isSet(object.tel) ? globalThis.String(object.tel) : "",
    };
  },

  toJSON(message: Company): unknown {
    const obj: any = {};
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.ceo !== "") {
      obj.ceo = message.ceo;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.registrationNo !== "") {
      obj.registrationNo = message.registrationNo;
    }
    if (message.tel !== "") {
      obj.tel = message.tel;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Company>, I>>(base?: I): Company {
    return Company.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Company>, I>>(object: I): Company {
    const message = createBaseCompany();
    message.address = object.address ?? "";
    message.ceo = object.ceo ?? "";
    message.name = object.name ?? "";
    message.registrationNo = object.registrationNo ?? "";
    message.tel = object.tel ?? "";
    return message;
  },
};

function createBaseContentDescription(): ContentDescription {
  return { company: undefined, detail: undefined, title: "", privacyPolicy: undefined };
}

export const ContentDescription = {
  encode(message: ContentDescription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.company !== undefined) {
      Company.encode(message.company, writer.uint32(10).fork()).ldelim();
    }
    if (message.detail !== undefined) {
      writer.uint32(18).string(message.detail);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.privacyPolicy !== undefined) {
      writer.uint32(34).string(message.privacyPolicy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentDescription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentDescription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.company = Company.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.detail = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.privacyPolicy = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentDescription {
    return {
      company: isSet(object.company) ? Company.fromJSON(object.company) : undefined,
      detail: isSet(object.detail) ? globalThis.String(object.detail) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      privacyPolicy: isSet(object.privacyPolicy) ? globalThis.String(object.privacyPolicy) : undefined,
    };
  },

  toJSON(message: ContentDescription): unknown {
    const obj: any = {};
    if (message.company !== undefined) {
      obj.company = Company.toJSON(message.company);
    }
    if (message.detail !== undefined) {
      obj.detail = message.detail;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.privacyPolicy !== undefined) {
      obj.privacyPolicy = message.privacyPolicy;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentDescription>, I>>(base?: I): ContentDescription {
    return ContentDescription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentDescription>, I>>(object: I): ContentDescription {
    const message = createBaseContentDescription();
    message.company = (object.company !== undefined && object.company !== null)
      ? Company.fromPartial(object.company)
      : undefined;
    message.detail = object.detail ?? undefined;
    message.title = object.title ?? "";
    message.privacyPolicy = object.privacyPolicy ?? undefined;
    return message;
  },
};

function createBaseContentImageURL(): ContentImageURL {
  return { banner: undefined, icon: undefined, landing: undefined };
}

export const ContentImageURL = {
  encode(message: ContentImageURL, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.banner !== undefined) {
      writer.uint32(10).string(message.banner);
    }
    if (message.icon !== undefined) {
      writer.uint32(18).string(message.icon);
    }
    if (message.landing !== undefined) {
      writer.uint32(26).string(message.landing);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentImageURL {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentImageURL();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.banner = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.icon = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.landing = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentImageURL {
    return {
      banner: isSet(object.banner) ? globalThis.String(object.banner) : undefined,
      icon: isSet(object.icon) ? globalThis.String(object.icon) : undefined,
      landing: isSet(object.landing) ? globalThis.String(object.landing) : undefined,
    };
  },

  toJSON(message: ContentImageURL): unknown {
    const obj: any = {};
    if (message.banner !== undefined) {
      obj.banner = message.banner;
    }
    if (message.icon !== undefined) {
      obj.icon = message.icon;
    }
    if (message.landing !== undefined) {
      obj.landing = message.landing;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentImageURL>, I>>(base?: I): ContentImageURL {
    return ContentImageURL.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentImageURL>, I>>(object: I): ContentImageURL {
    const message = createBaseContentImageURL();
    message.banner = object.banner ?? undefined;
    message.icon = object.icon ?? undefined;
    message.landing = object.landing ?? undefined;
    return message;
  },
};

function createBaseContentLDPConfig(): ContentLDPConfig {
  return { formula: 0, privacyBudget: 0 };
}

export const ContentLDPConfig = {
  encode(message: ContentLDPConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.formula !== 0) {
      writer.uint32(8).int32(message.formula);
    }
    if (message.privacyBudget !== 0) {
      writer.uint32(17).double(message.privacyBudget);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentLDPConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentLDPConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.formula = reader.int32();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.privacyBudget = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentLDPConfig {
    return {
      formula: isSet(object.formula) ? globalThis.Number(object.formula) : 0,
      privacyBudget: isSet(object.privacyBudget) ? globalThis.Number(object.privacyBudget) : 0,
    };
  },

  toJSON(message: ContentLDPConfig): unknown {
    const obj: any = {};
    if (message.formula !== 0) {
      obj.formula = Math.round(message.formula);
    }
    if (message.privacyBudget !== 0) {
      obj.privacyBudget = message.privacyBudget;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentLDPConfig>, I>>(base?: I): ContentLDPConfig {
    return ContentLDPConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentLDPConfig>, I>>(object: I): ContentLDPConfig {
    const message = createBaseContentLDPConfig();
    message.formula = object.formula ?? 0;
    message.privacyBudget = object.privacyBudget ?? 0;
    return message;
  },
};

function createBaseContentLDPTargetBin(): ContentLDPTargetBin {
  return { bin: [] };
}

export const ContentLDPTargetBin = {
  encode(message: ContentLDPTargetBin, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.bin) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentLDPTargetBin {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentLDPTargetBin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.bin.push(reader.int32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.bin.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentLDPTargetBin {
    return { bin: globalThis.Array.isArray(object?.bin) ? object.bin.map((e: any) => globalThis.Number(e)) : [] };
  },

  toJSON(message: ContentLDPTargetBin): unknown {
    const obj: any = {};
    if (message.bin?.length) {
      obj.bin = message.bin.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentLDPTargetBin>, I>>(base?: I): ContentLDPTargetBin {
    return ContentLDPTargetBin.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentLDPTargetBin>, I>>(object: I): ContentLDPTargetBin {
    const message = createBaseContentLDPTargetBin();
    message.bin = object.bin?.map((e) => e) || [];
    return message;
  },
};

function createBaseContentLDPTarget(): ContentLDPTarget {
  return { path: "", selectQuery: "", binType: "", bins: [], title: "", labels: [] };
}

export const ContentLDPTarget = {
  encode(message: ContentLDPTarget, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(10).string(message.path);
    }
    if (message.selectQuery !== "") {
      writer.uint32(18).string(message.selectQuery);
    }
    if (message.binType !== "") {
      writer.uint32(26).string(message.binType);
    }
    for (const v of message.bins) {
      ContentLDPTargetBin.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    for (const v of message.labels) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentLDPTarget {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentLDPTarget();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selectQuery = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.binType = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bins.push(ContentLDPTargetBin.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.labels.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentLDPTarget {
    return {
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      selectQuery: isSet(object.selectQuery) ? globalThis.String(object.selectQuery) : "",
      binType: isSet(object.binType) ? globalThis.String(object.binType) : "",
      bins: globalThis.Array.isArray(object?.bins) ? object.bins.map((e: any) => ContentLDPTargetBin.fromJSON(e)) : [],
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ContentLDPTarget): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.selectQuery !== "") {
      obj.selectQuery = message.selectQuery;
    }
    if (message.binType !== "") {
      obj.binType = message.binType;
    }
    if (message.bins?.length) {
      obj.bins = message.bins.map((e) => ContentLDPTargetBin.toJSON(e));
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentLDPTarget>, I>>(base?: I): ContentLDPTarget {
    return ContentLDPTarget.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentLDPTarget>, I>>(object: I): ContentLDPTarget {
    const message = createBaseContentLDPTarget();
    message.path = object.path ?? "";
    message.selectQuery = object.selectQuery ?? "";
    message.binType = object.binType ?? "";
    message.bins = object.bins?.map((e) => ContentLDPTargetBin.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    return message;
  },
};

function createBaseContentAdvertisementGoods(): ContentAdvertisementGoods {
  return { name: "", manufacturer: "", price: 0 };
}

export const ContentAdvertisementGoods = {
  encode(message: ContentAdvertisementGoods, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.manufacturer !== "") {
      writer.uint32(18).string(message.manufacturer);
    }
    if (message.price !== 0) {
      writer.uint32(24).int32(message.price);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentAdvertisementGoods {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentAdvertisementGoods();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.price = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentAdvertisementGoods {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
    };
  },

  toJSON(message: ContentAdvertisementGoods): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.price !== 0) {
      obj.price = Math.round(message.price);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentAdvertisementGoods>, I>>(base?: I): ContentAdvertisementGoods {
    return ContentAdvertisementGoods.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentAdvertisementGoods>, I>>(object: I): ContentAdvertisementGoods {
    const message = createBaseContentAdvertisementGoods();
    message.name = object.name ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.price = object.price ?? 0;
    return message;
  },
};

function createBaseContentAdvertisement(): ContentAdvertisement {
  return { type: 0, displayArea: 0, moreInfo: "", goods: undefined };
}

export const ContentAdvertisement = {
  encode(message: ContentAdvertisement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.displayArea !== 0) {
      writer.uint32(16).int32(message.displayArea);
    }
    if (message.moreInfo !== "") {
      writer.uint32(26).string(message.moreInfo);
    }
    if (message.goods !== undefined) {
      ContentAdvertisementGoods.encode(message.goods, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentAdvertisement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentAdvertisement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.displayArea = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.moreInfo = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.goods = ContentAdvertisementGoods.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentAdvertisement {
    return {
      type: isSet(object.type) ? globalThis.Number(object.type) : 0,
      displayArea: isSet(object.displayArea) ? globalThis.Number(object.displayArea) : 0,
      moreInfo: isSet(object.moreInfo) ? globalThis.String(object.moreInfo) : "",
      goods: isSet(object.goods) ? ContentAdvertisementGoods.fromJSON(object.goods) : undefined,
    };
  },

  toJSON(message: ContentAdvertisement): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = Math.round(message.type);
    }
    if (message.displayArea !== 0) {
      obj.displayArea = Math.round(message.displayArea);
    }
    if (message.moreInfo !== "") {
      obj.moreInfo = message.moreInfo;
    }
    if (message.goods !== undefined) {
      obj.goods = ContentAdvertisementGoods.toJSON(message.goods);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentAdvertisement>, I>>(base?: I): ContentAdvertisement {
    return ContentAdvertisement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentAdvertisement>, I>>(object: I): ContentAdvertisement {
    const message = createBaseContentAdvertisement();
    message.type = object.type ?? 0;
    message.displayArea = object.displayArea ?? 0;
    message.moreInfo = object.moreInfo ?? "";
    message.goods = (object.goods !== undefined && object.goods !== null)
      ? ContentAdvertisementGoods.fromPartial(object.goods)
      : undefined;
    return message;
  },
};

function createBaseContentPrivacyPolicyThirdParty(): ContentPrivacyPolicyThirdParty {
  return { processing: 0, name: "", purpose: "" };
}

export const ContentPrivacyPolicyThirdParty = {
  encode(message: ContentPrivacyPolicyThirdParty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processing !== 0) {
      writer.uint32(8).int32(message.processing);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.purpose !== "") {
      writer.uint32(26).string(message.purpose);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentPrivacyPolicyThirdParty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentPrivacyPolicyThirdParty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.processing = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.purpose = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentPrivacyPolicyThirdParty {
    return {
      processing: isSet(object.processing) ? globalThis.Number(object.processing) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      purpose: isSet(object.purpose) ? globalThis.String(object.purpose) : "",
    };
  },

  toJSON(message: ContentPrivacyPolicyThirdParty): unknown {
    const obj: any = {};
    if (message.processing !== 0) {
      obj.processing = Math.round(message.processing);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.purpose !== "") {
      obj.purpose = message.purpose;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentPrivacyPolicyThirdParty>, I>>(base?: I): ContentPrivacyPolicyThirdParty {
    return ContentPrivacyPolicyThirdParty.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentPrivacyPolicyThirdParty>, I>>(
    object: I,
  ): ContentPrivacyPolicyThirdParty {
    const message = createBaseContentPrivacyPolicyThirdParty();
    message.processing = object.processing ?? 0;
    message.name = object.name ?? "";
    message.purpose = object.purpose ?? "";
    return message;
  },
};

function createBaseContentPrivacyPolicy(): ContentPrivacyPolicy {
  return { identifier: 0, thirdParty: undefined };
}

export const ContentPrivacyPolicy = {
  encode(message: ContentPrivacyPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== 0) {
      writer.uint32(8).int32(message.identifier);
    }
    if (message.thirdParty !== undefined) {
      ContentPrivacyPolicyThirdParty.encode(message.thirdParty, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentPrivacyPolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentPrivacyPolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.identifier = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.thirdParty = ContentPrivacyPolicyThirdParty.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentPrivacyPolicy {
    return {
      identifier: isSet(object.identifier) ? globalThis.Number(object.identifier) : 0,
      thirdParty: isSet(object.thirdParty) ? ContentPrivacyPolicyThirdParty.fromJSON(object.thirdParty) : undefined,
    };
  },

  toJSON(message: ContentPrivacyPolicy): unknown {
    const obj: any = {};
    if (message.identifier !== 0) {
      obj.identifier = Math.round(message.identifier);
    }
    if (message.thirdParty !== undefined) {
      obj.thirdParty = ContentPrivacyPolicyThirdParty.toJSON(message.thirdParty);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentPrivacyPolicy>, I>>(base?: I): ContentPrivacyPolicy {
    return ContentPrivacyPolicy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentPrivacyPolicy>, I>>(object: I): ContentPrivacyPolicy {
    const message = createBaseContentPrivacyPolicy();
    message.identifier = object.identifier ?? 0;
    message.thirdParty = (object.thirdParty !== undefined && object.thirdParty !== null)
      ? ContentPrivacyPolicyThirdParty.fromPartial(object.thirdParty)
      : undefined;
    return message;
  },
};

function createBaseContentEventViewTransmit(): ContentEventViewTransmit {
  return { buttonText: "" };
}

export const ContentEventViewTransmit = {
  encode(message: ContentEventViewTransmit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.buttonText !== "") {
      writer.uint32(10).string(message.buttonText);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentEventViewTransmit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentEventViewTransmit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.buttonText = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentEventViewTransmit {
    return { buttonText: isSet(object.buttonText) ? globalThis.String(object.buttonText) : "" };
  },

  toJSON(message: ContentEventViewTransmit): unknown {
    const obj: any = {};
    if (message.buttonText !== "") {
      obj.buttonText = message.buttonText;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentEventViewTransmit>, I>>(base?: I): ContentEventViewTransmit {
    return ContentEventViewTransmit.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentEventViewTransmit>, I>>(object: I): ContentEventViewTransmit {
    const message = createBaseContentEventViewTransmit();
    message.buttonText = object.buttonText ?? "";
    return message;
  },
};

function createBaseContentEventView(): ContentEventView {
  return { target: "", transmit: undefined };
}

export const ContentEventView = {
  encode(message: ContentEventView, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.target !== "") {
      writer.uint32(10).string(message.target);
    }
    if (message.transmit !== undefined) {
      ContentEventViewTransmit.encode(message.transmit, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentEventView {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentEventView();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.target = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transmit = ContentEventViewTransmit.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentEventView {
    return {
      target: isSet(object.target) ? globalThis.String(object.target) : "",
      transmit: isSet(object.transmit) ? ContentEventViewTransmit.fromJSON(object.transmit) : undefined,
    };
  },

  toJSON(message: ContentEventView): unknown {
    const obj: any = {};
    if (message.target !== "") {
      obj.target = message.target;
    }
    if (message.transmit !== undefined) {
      obj.transmit = ContentEventViewTransmit.toJSON(message.transmit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentEventView>, I>>(base?: I): ContentEventView {
    return ContentEventView.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentEventView>, I>>(object: I): ContentEventView {
    const message = createBaseContentEventView();
    message.target = object.target ?? "";
    message.transmit = (object.transmit !== undefined && object.transmit !== null)
      ? ContentEventViewTransmit.fromPartial(object.transmit)
      : undefined;
    return message;
  },
};

function createBaseContentEvent(): ContentEvent {
  return { genre: "", view: undefined };
}

export const ContentEvent = {
  encode(message: ContentEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.genre !== "") {
      writer.uint32(10).string(message.genre);
    }
    if (message.view !== undefined) {
      ContentEventView.encode(message.view, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.genre = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.view = ContentEventView.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentEvent {
    return {
      genre: isSet(object.genre) ? globalThis.String(object.genre) : "",
      view: isSet(object.view) ? ContentEventView.fromJSON(object.view) : undefined,
    };
  },

  toJSON(message: ContentEvent): unknown {
    const obj: any = {};
    if (message.genre !== "") {
      obj.genre = message.genre;
    }
    if (message.view !== undefined) {
      obj.view = ContentEventView.toJSON(message.view);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentEvent>, I>>(base?: I): ContentEvent {
    return ContentEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentEvent>, I>>(object: I): ContentEvent {
    const message = createBaseContentEvent();
    message.genre = object.genre ?? "";
    message.view = (object.view !== undefined && object.view !== null)
      ? ContentEventView.fromPartial(object.view)
      : undefined;
    return message;
  },
};

function createBaseContentPanel(): ContentPanel {
  return { baseUrl: "", params: [] };
}

export const ContentPanel = {
  encode(message: ContentPanel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.baseUrl !== "") {
      writer.uint32(10).string(message.baseUrl);
    }
    for (const v of message.params) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentPanel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentPanel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.baseUrl = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.params.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentPanel {
    return {
      baseUrl: isSet(object.baseUrl) ? globalThis.String(object.baseUrl) : "",
      params: globalThis.Array.isArray(object?.params) ? object.params.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ContentPanel): unknown {
    const obj: any = {};
    if (message.baseUrl !== "") {
      obj.baseUrl = message.baseUrl;
    }
    if (message.params?.length) {
      obj.params = message.params;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentPanel>, I>>(base?: I): ContentPanel {
    return ContentPanel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentPanel>, I>>(object: I): ContentPanel {
    const message = createBaseContentPanel();
    message.baseUrl = object.baseUrl ?? "";
    message.params = object.params?.map((e) => e) || [];
    return message;
  },
};

function createBaseContent(): Content {
  return {
    contentDescription: undefined,
    contentImageUrl: undefined,
    contentLdpConfig: undefined,
    contentPurchase: undefined,
    contentAdvertisement: undefined,
    contentLdpTarget: [],
    contentEvent: undefined,
    contentPanel: undefined,
    contentPrivacyPolicy: undefined,
  };
}

export const Content = {
  encode(message: Content, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contentDescription !== undefined) {
      ContentDescription.encode(message.contentDescription, writer.uint32(10).fork()).ldelim();
    }
    if (message.contentImageUrl !== undefined) {
      ContentImageURL.encode(message.contentImageUrl, writer.uint32(18).fork()).ldelim();
    }
    if (message.contentLdpConfig !== undefined) {
      ContentLDPConfig.encode(message.contentLdpConfig, writer.uint32(26).fork()).ldelim();
    }
    if (message.contentPurchase !== undefined) {
      ContentPurchase.encode(message.contentPurchase, writer.uint32(34).fork()).ldelim();
    }
    if (message.contentAdvertisement !== undefined) {
      ContentAdvertisement.encode(message.contentAdvertisement, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.contentLdpTarget) {
      ContentLDPTarget.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.contentEvent !== undefined) {
      ContentEvent.encode(message.contentEvent, writer.uint32(58).fork()).ldelim();
    }
    if (message.contentPanel !== undefined) {
      ContentPanel.encode(message.contentPanel, writer.uint32(66).fork()).ldelim();
    }
    if (message.contentPrivacyPolicy !== undefined) {
      ContentPrivacyPolicy.encode(message.contentPrivacyPolicy, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Content {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contentDescription = ContentDescription.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.contentImageUrl = ContentImageURL.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contentLdpConfig = ContentLDPConfig.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.contentPurchase = ContentPurchase.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.contentAdvertisement = ContentAdvertisement.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.contentLdpTarget.push(ContentLDPTarget.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.contentEvent = ContentEvent.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.contentPanel = ContentPanel.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.contentPrivacyPolicy = ContentPrivacyPolicy.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Content {
    return {
      contentDescription: isSet(object.contentDescription)
        ? ContentDescription.fromJSON(object.contentDescription)
        : undefined,
      contentImageUrl: isSet(object.contentImageUrl) ? ContentImageURL.fromJSON(object.contentImageUrl) : undefined,
      contentLdpConfig: isSet(object.contentLdpConfig) ? ContentLDPConfig.fromJSON(object.contentLdpConfig) : undefined,
      contentPurchase: isSet(object.contentPurchase) ? ContentPurchase.fromJSON(object.contentPurchase) : undefined,
      contentAdvertisement: isSet(object.contentAdvertisement)
        ? ContentAdvertisement.fromJSON(object.contentAdvertisement)
        : undefined,
      contentLdpTarget: globalThis.Array.isArray(object?.contentLdpTarget)
        ? object.contentLdpTarget.map((e: any) => ContentLDPTarget.fromJSON(e))
        : [],
      contentEvent: isSet(object.contentEvent) ? ContentEvent.fromJSON(object.contentEvent) : undefined,
      contentPanel: isSet(object.contentPanel) ? ContentPanel.fromJSON(object.contentPanel) : undefined,
      contentPrivacyPolicy: isSet(object.contentPrivacyPolicy)
        ? ContentPrivacyPolicy.fromJSON(object.contentPrivacyPolicy)
        : undefined,
    };
  },

  toJSON(message: Content): unknown {
    const obj: any = {};
    if (message.contentDescription !== undefined) {
      obj.contentDescription = ContentDescription.toJSON(message.contentDescription);
    }
    if (message.contentImageUrl !== undefined) {
      obj.contentImageUrl = ContentImageURL.toJSON(message.contentImageUrl);
    }
    if (message.contentLdpConfig !== undefined) {
      obj.contentLdpConfig = ContentLDPConfig.toJSON(message.contentLdpConfig);
    }
    if (message.contentPurchase !== undefined) {
      obj.contentPurchase = ContentPurchase.toJSON(message.contentPurchase);
    }
    if (message.contentAdvertisement !== undefined) {
      obj.contentAdvertisement = ContentAdvertisement.toJSON(message.contentAdvertisement);
    }
    if (message.contentLdpTarget?.length) {
      obj.contentLdpTarget = message.contentLdpTarget.map((e) => ContentLDPTarget.toJSON(e));
    }
    if (message.contentEvent !== undefined) {
      obj.contentEvent = ContentEvent.toJSON(message.contentEvent);
    }
    if (message.contentPanel !== undefined) {
      obj.contentPanel = ContentPanel.toJSON(message.contentPanel);
    }
    if (message.contentPrivacyPolicy !== undefined) {
      obj.contentPrivacyPolicy = ContentPrivacyPolicy.toJSON(message.contentPrivacyPolicy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Content>, I>>(base?: I): Content {
    return Content.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Content>, I>>(object: I): Content {
    const message = createBaseContent();
    message.contentDescription = (object.contentDescription !== undefined && object.contentDescription !== null)
      ? ContentDescription.fromPartial(object.contentDescription)
      : undefined;
    message.contentImageUrl = (object.contentImageUrl !== undefined && object.contentImageUrl !== null)
      ? ContentImageURL.fromPartial(object.contentImageUrl)
      : undefined;
    message.contentLdpConfig = (object.contentLdpConfig !== undefined && object.contentLdpConfig !== null)
      ? ContentLDPConfig.fromPartial(object.contentLdpConfig)
      : undefined;
    message.contentPurchase = (object.contentPurchase !== undefined && object.contentPurchase !== null)
      ? ContentPurchase.fromPartial(object.contentPurchase)
      : undefined;
    message.contentAdvertisement = (object.contentAdvertisement !== undefined && object.contentAdvertisement !== null)
      ? ContentAdvertisement.fromPartial(object.contentAdvertisement)
      : undefined;
    message.contentLdpTarget = object.contentLdpTarget?.map((e) => ContentLDPTarget.fromPartial(e)) || [];
    message.contentEvent = (object.contentEvent !== undefined && object.contentEvent !== null)
      ? ContentEvent.fromPartial(object.contentEvent)
      : undefined;
    message.contentPanel = (object.contentPanel !== undefined && object.contentPanel !== null)
      ? ContentPanel.fromPartial(object.contentPanel)
      : undefined;
    message.contentPrivacyPolicy = (object.contentPrivacyPolicy !== undefined && object.contentPrivacyPolicy !== null)
      ? ContentPrivacyPolicy.fromPartial(object.contentPrivacyPolicy)
      : undefined;
    return message;
  },
};

function createBaseContentPurchase(): ContentPurchase {
  return { category: [], maskingOptions: [], selectQuery: undefined, selectRange: undefined, uploadUrl: undefined };
}

export const ContentPurchase = {
  encode(message: ContentPurchase, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.category) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.maskingOptions) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.selectQuery !== undefined) {
      writer.uint32(26).string(message.selectQuery);
    }
    if (message.selectRange !== undefined) {
      writer.uint32(34).string(message.selectRange);
    }
    if (message.uploadUrl !== undefined) {
      writer.uint32(42).string(message.uploadUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentPurchase {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentPurchase();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.category.push(reader.int32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.category.push(reader.int32());
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.maskingOptions.push(reader.int32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.maskingOptions.push(reader.int32());
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selectQuery = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.selectRange = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.uploadUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContentPurchase {
    return {
      category: globalThis.Array.isArray(object?.category) ? object.category.map((e: any) => globalThis.Number(e)) : [],
      maskingOptions: globalThis.Array.isArray(object?.maskingOptions)
        ? object.maskingOptions.map((e: any) => globalThis.Number(e))
        : [],
      selectQuery: isSet(object.selectQuery) ? globalThis.String(object.selectQuery) : undefined,
      selectRange: isSet(object.selectRange) ? globalThis.String(object.selectRange) : undefined,
      uploadUrl: isSet(object.uploadUrl) ? globalThis.String(object.uploadUrl) : undefined,
    };
  },

  toJSON(message: ContentPurchase): unknown {
    const obj: any = {};
    if (message.category?.length) {
      obj.category = message.category.map((e) => Math.round(e));
    }
    if (message.maskingOptions?.length) {
      obj.maskingOptions = message.maskingOptions.map((e) => Math.round(e));
    }
    if (message.selectQuery !== undefined) {
      obj.selectQuery = message.selectQuery;
    }
    if (message.selectRange !== undefined) {
      obj.selectRange = message.selectRange;
    }
    if (message.uploadUrl !== undefined) {
      obj.uploadUrl = message.uploadUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContentPurchase>, I>>(base?: I): ContentPurchase {
    return ContentPurchase.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContentPurchase>, I>>(object: I): ContentPurchase {
    const message = createBaseContentPurchase();
    message.category = object.category?.map((e) => e) || [];
    message.maskingOptions = object.maskingOptions?.map((e) => e) || [];
    message.selectQuery = object.selectQuery ?? undefined;
    message.selectRange = object.selectRange ?? undefined;
    message.uploadUrl = object.uploadUrl ?? undefined;
    return message;
  },
};

function createBaseDailyQuestionOption(): DailyQuestionOption {
  return { value: "", description: undefined, imageUrl: undefined };
}

export const DailyQuestionOption = {
  encode(message: DailyQuestionOption, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    if (message.imageUrl !== undefined) {
      writer.uint32(26).string(message.imageUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DailyQuestionOption {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyQuestionOption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyQuestionOption {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : undefined,
      imageUrl: isSet(object.imageUrl) ? globalThis.String(object.imageUrl) : undefined,
    };
  },

  toJSON(message: DailyQuestionOption): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    if (message.imageUrl !== undefined) {
      obj.imageUrl = message.imageUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyQuestionOption>, I>>(base?: I): DailyQuestionOption {
    return DailyQuestionOption.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyQuestionOption>, I>>(object: I): DailyQuestionOption {
    const message = createBaseDailyQuestionOption();
    message.value = object.value ?? "";
    message.description = object.description ?? undefined;
    message.imageUrl = object.imageUrl ?? undefined;
    return message;
  },
};

function createBaseDailyQuestionContent(): DailyQuestionContent {
  return {
    imageUrl: "",
    viewType: 0,
    answerType: 0,
    title: "",
    description: "",
    questionId: 0,
    questionCategory: "",
    maxCheck: 0,
    options: [],
  };
}

export const DailyQuestionContent = {
  encode(message: DailyQuestionContent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageUrl !== "") {
      writer.uint32(10).string(message.imageUrl);
    }
    if (message.viewType !== 0) {
      writer.uint32(16).int32(message.viewType);
    }
    if (message.answerType !== 0) {
      writer.uint32(24).int32(message.answerType);
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.questionId !== 0) {
      writer.uint32(48).uint32(message.questionId);
    }
    if (message.questionCategory !== "") {
      writer.uint32(58).string(message.questionCategory);
    }
    if (message.maxCheck !== 0) {
      writer.uint32(64).uint32(message.maxCheck);
    }
    for (const v of message.options) {
      DailyQuestionOption.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DailyQuestionContent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyQuestionContent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.viewType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.answerType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.questionId = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.questionCategory = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.maxCheck = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.options.push(DailyQuestionOption.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyQuestionContent {
    return {
      imageUrl: isSet(object.imageUrl) ? globalThis.String(object.imageUrl) : "",
      viewType: isSet(object.viewType) ? dailyQuestionViewTypeFromJSON(object.viewType) : 0,
      answerType: isSet(object.answerType) ? dailyQuestionAnswerTypeFromJSON(object.answerType) : 0,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      questionId: isSet(object.questionId) ? globalThis.Number(object.questionId) : 0,
      questionCategory: isSet(object.questionCategory) ? globalThis.String(object.questionCategory) : "",
      maxCheck: isSet(object.maxCheck) ? globalThis.Number(object.maxCheck) : 0,
      options: globalThis.Array.isArray(object?.options)
        ? object.options.map((e: any) => DailyQuestionOption.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DailyQuestionContent): unknown {
    const obj: any = {};
    if (message.imageUrl !== "") {
      obj.imageUrl = message.imageUrl;
    }
    if (message.viewType !== 0) {
      obj.viewType = dailyQuestionViewTypeToJSON(message.viewType);
    }
    if (message.answerType !== 0) {
      obj.answerType = dailyQuestionAnswerTypeToJSON(message.answerType);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.questionId !== 0) {
      obj.questionId = Math.round(message.questionId);
    }
    if (message.questionCategory !== "") {
      obj.questionCategory = message.questionCategory;
    }
    if (message.maxCheck !== 0) {
      obj.maxCheck = Math.round(message.maxCheck);
    }
    if (message.options?.length) {
      obj.options = message.options.map((e) => DailyQuestionOption.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyQuestionContent>, I>>(base?: I): DailyQuestionContent {
    return DailyQuestionContent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyQuestionContent>, I>>(object: I): DailyQuestionContent {
    const message = createBaseDailyQuestionContent();
    message.imageUrl = object.imageUrl ?? "";
    message.viewType = object.viewType ?? 0;
    message.answerType = object.answerType ?? 0;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.questionId = object.questionId ?? 0;
    message.questionCategory = object.questionCategory ?? "";
    message.maxCheck = object.maxCheck ?? 0;
    message.options = object.options?.map((e) => DailyQuestionOption.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInviteFriendContent(): InviteFriendContent {
  return {
    imageUrl: "",
    title: "",
    description: "",
    maxCountPerInvitationCode: 0,
    unlimitedCodeList: [],
    giveRewardToHost: false,
    landingUrl: "",
  };
}

export const InviteFriendContent = {
  encode(message: InviteFriendContent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageUrl !== "") {
      writer.uint32(10).string(message.imageUrl);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.maxCountPerInvitationCode !== 0) {
      writer.uint32(32).uint32(message.maxCountPerInvitationCode);
    }
    for (const v of message.unlimitedCodeList) {
      writer.uint32(42).string(v!);
    }
    if (message.giveRewardToHost === true) {
      writer.uint32(48).bool(message.giveRewardToHost);
    }
    if (message.landingUrl !== "") {
      writer.uint32(58).string(message.landingUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteFriendContent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteFriendContent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxCountPerInvitationCode = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.unlimitedCodeList.push(reader.string());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.giveRewardToHost = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.landingUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteFriendContent {
    return {
      imageUrl: isSet(object.imageUrl) ? globalThis.String(object.imageUrl) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      maxCountPerInvitationCode: isSet(object.maxCountPerInvitationCode)
        ? globalThis.Number(object.maxCountPerInvitationCode)
        : 0,
      unlimitedCodeList: globalThis.Array.isArray(object?.unlimitedCodeList)
        ? object.unlimitedCodeList.map((e: any) => globalThis.String(e))
        : [],
      giveRewardToHost: isSet(object.giveRewardToHost) ? globalThis.Boolean(object.giveRewardToHost) : false,
      landingUrl: isSet(object.landingUrl) ? globalThis.String(object.landingUrl) : "",
    };
  },

  toJSON(message: InviteFriendContent): unknown {
    const obj: any = {};
    if (message.imageUrl !== "") {
      obj.imageUrl = message.imageUrl;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.maxCountPerInvitationCode !== 0) {
      obj.maxCountPerInvitationCode = Math.round(message.maxCountPerInvitationCode);
    }
    if (message.unlimitedCodeList?.length) {
      obj.unlimitedCodeList = message.unlimitedCodeList;
    }
    if (message.giveRewardToHost === true) {
      obj.giveRewardToHost = message.giveRewardToHost;
    }
    if (message.landingUrl !== "") {
      obj.landingUrl = message.landingUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteFriendContent>, I>>(base?: I): InviteFriendContent {
    return InviteFriendContent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InviteFriendContent>, I>>(object: I): InviteFriendContent {
    const message = createBaseInviteFriendContent();
    message.imageUrl = object.imageUrl ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.maxCountPerInvitationCode = object.maxCountPerInvitationCode ?? 0;
    message.unlimitedCodeList = object.unlimitedCodeList?.map((e) => e) || [];
    message.giveRewardToHost = object.giveRewardToHost ?? false;
    message.landingUrl = object.landingUrl ?? "";
    return message;
  },
};

function createBaseAttendanceContent(): AttendanceContent {
  return { imageUrl: "", title: "", description: "", dailyMissionReward: 0, weeklyMissionReward: 0, landingUrl: "" };
}

export const AttendanceContent = {
  encode(message: AttendanceContent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageUrl !== "") {
      writer.uint32(10).string(message.imageUrl);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.dailyMissionReward !== 0) {
      writer.uint32(32).uint64(message.dailyMissionReward);
    }
    if (message.weeklyMissionReward !== 0) {
      writer.uint32(40).uint64(message.weeklyMissionReward);
    }
    if (message.landingUrl !== "") {
      writer.uint32(50).string(message.landingUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttendanceContent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttendanceContent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.dailyMissionReward = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.weeklyMissionReward = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.landingUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttendanceContent {
    return {
      imageUrl: isSet(object.imageUrl) ? globalThis.String(object.imageUrl) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      dailyMissionReward: isSet(object.dailyMissionReward) ? globalThis.Number(object.dailyMissionReward) : 0,
      weeklyMissionReward: isSet(object.weeklyMissionReward) ? globalThis.Number(object.weeklyMissionReward) : 0,
      landingUrl: isSet(object.landingUrl) ? globalThis.String(object.landingUrl) : "",
    };
  },

  toJSON(message: AttendanceContent): unknown {
    const obj: any = {};
    if (message.imageUrl !== "") {
      obj.imageUrl = message.imageUrl;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.dailyMissionReward !== 0) {
      obj.dailyMissionReward = Math.round(message.dailyMissionReward);
    }
    if (message.weeklyMissionReward !== 0) {
      obj.weeklyMissionReward = Math.round(message.weeklyMissionReward);
    }
    if (message.landingUrl !== "") {
      obj.landingUrl = message.landingUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttendanceContent>, I>>(base?: I): AttendanceContent {
    return AttendanceContent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttendanceContent>, I>>(object: I): AttendanceContent {
    const message = createBaseAttendanceContent();
    message.imageUrl = object.imageUrl ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.dailyMissionReward = object.dailyMissionReward ?? 0;
    message.weeklyMissionReward = object.weeklyMissionReward ?? 0;
    message.landingUrl = object.landingUrl ?? "";
    return message;
  },
};

function createBaseProposalStatic(): ProposalStatic {
  return {
    proposalId: "",
    proposalAddress: "",
    owner: "",
    purpose: 0,
    notBefore: "",
    notAfter: "",
    hidden: false,
    category: [],
    condition: undefined,
    filter: undefined,
    content: undefined,
    maxIssuable: undefined,
    maxConsumeCount: undefined,
    minConsumeInterval: undefined,
    rewardPolicy: undefined,
    ticketValidFrom: undefined,
    ticketValidityInSeconds: undefined,
    ticketValidUntil: undefined,
    dailyQuestionContent: undefined,
    inviteFriendContent: undefined,
    attendanceContent: undefined,
    enterprisePromotionContent: undefined,
  };
}

export const ProposalStatic = {
  encode(message: ProposalStatic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId !== "") {
      writer.uint32(10).string(message.proposalId);
    }
    if (message.proposalAddress !== "") {
      writer.uint32(154).string(message.proposalAddress);
    }
    if (message.owner !== "") {
      writer.uint32(18).string(message.owner);
    }
    if (message.purpose !== 0) {
      writer.uint32(24).uint32(message.purpose);
    }
    if (message.notBefore !== "") {
      writer.uint32(34).string(message.notBefore);
    }
    if (message.notAfter !== "") {
      writer.uint32(42).string(message.notAfter);
    }
    if (message.hidden === true) {
      writer.uint32(144).bool(message.hidden);
    }
    writer.uint32(50).fork();
    for (const v of message.category) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.condition !== undefined) {
      writer.uint32(58).string(message.condition);
    }
    if (message.filter !== undefined) {
      writer.uint32(66).string(message.filter);
    }
    if (message.content !== undefined) {
      Content.encode(message.content, writer.uint32(74).fork()).ldelim();
    }
    if (message.maxIssuable !== undefined) {
      writer.uint32(136).uint64(message.maxIssuable);
    }
    if (message.maxConsumeCount !== undefined) {
      writer.uint32(88).uint32(message.maxConsumeCount);
    }
    if (message.minConsumeInterval !== undefined) {
      writer.uint32(128).uint64(message.minConsumeInterval);
    }
    if (message.rewardPolicy !== undefined) {
      RewardPolicy.encode(message.rewardPolicy, writer.uint32(98).fork()).ldelim();
    }
    if (message.ticketValidFrom !== undefined) {
      writer.uint32(106).string(message.ticketValidFrom);
    }
    if (message.ticketValidityInSeconds !== undefined) {
      writer.uint32(112).uint32(message.ticketValidityInSeconds);
    }
    if (message.ticketValidUntil !== undefined) {
      writer.uint32(122).string(message.ticketValidUntil);
    }
    if (message.dailyQuestionContent !== undefined) {
      DailyQuestionContent.encode(message.dailyQuestionContent, writer.uint32(162).fork()).ldelim();
    }
    if (message.inviteFriendContent !== undefined) {
      InviteFriendContent.encode(message.inviteFriendContent, writer.uint32(170).fork()).ldelim();
    }
    if (message.attendanceContent !== undefined) {
      AttendanceContent.encode(message.attendanceContent, writer.uint32(178).fork()).ldelim();
    }
    if (message.enterprisePromotionContent !== undefined) {
      EnterprisePromotionContent.encode(message.enterprisePromotionContent, writer.uint32(186).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalStatic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalStatic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.proposalAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.owner = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.purpose = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.notBefore = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.notAfter = reader.string();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.hidden = reader.bool();
          continue;
        case 6:
          if (tag === 48) {
            message.category.push(reader.uint32());

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.category.push(reader.uint32());
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.condition = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.filter = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.content = Content.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.maxIssuable = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.maxConsumeCount = reader.uint32();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.minConsumeInterval = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.rewardPolicy = RewardPolicy.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.ticketValidFrom = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.ticketValidityInSeconds = reader.uint32();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.ticketValidUntil = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.dailyQuestionContent = DailyQuestionContent.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.inviteFriendContent = InviteFriendContent.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.attendanceContent = AttendanceContent.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.enterprisePromotionContent = EnterprisePromotionContent.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalStatic {
    return {
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
      proposalAddress: isSet(object.proposalAddress) ? globalThis.String(object.proposalAddress) : "",
      owner: isSet(object.owner) ? globalThis.String(object.owner) : "",
      purpose: isSet(object.purpose) ? globalThis.Number(object.purpose) : 0,
      notBefore: isSet(object.notBefore) ? globalThis.String(object.notBefore) : "",
      notAfter: isSet(object.notAfter) ? globalThis.String(object.notAfter) : "",
      hidden: isSet(object.hidden) ? globalThis.Boolean(object.hidden) : false,
      category: globalThis.Array.isArray(object?.category) ? object.category.map((e: any) => globalThis.Number(e)) : [],
      condition: isSet(object.condition) ? globalThis.String(object.condition) : undefined,
      filter: isSet(object.filter) ? globalThis.String(object.filter) : undefined,
      content: isSet(object.content) ? Content.fromJSON(object.content) : undefined,
      maxIssuable: isSet(object.maxIssuable) ? globalThis.Number(object.maxIssuable) : undefined,
      maxConsumeCount: isSet(object.maxConsumeCount) ? globalThis.Number(object.maxConsumeCount) : undefined,
      minConsumeInterval: isSet(object.minConsumeInterval) ? globalThis.Number(object.minConsumeInterval) : undefined,
      rewardPolicy: isSet(object.rewardPolicy) ? RewardPolicy.fromJSON(object.rewardPolicy) : undefined,
      ticketValidFrom: isSet(object.ticketValidFrom) ? globalThis.String(object.ticketValidFrom) : undefined,
      ticketValidityInSeconds: isSet(object.ticketValidityInSeconds)
        ? globalThis.Number(object.ticketValidityInSeconds)
        : undefined,
      ticketValidUntil: isSet(object.ticketValidUntil) ? globalThis.String(object.ticketValidUntil) : undefined,
      dailyQuestionContent: isSet(object.dailyQuestionContent)
        ? DailyQuestionContent.fromJSON(object.dailyQuestionContent)
        : undefined,
      inviteFriendContent: isSet(object.inviteFriendContent)
        ? InviteFriendContent.fromJSON(object.inviteFriendContent)
        : undefined,
      attendanceContent: isSet(object.attendanceContent)
        ? AttendanceContent.fromJSON(object.attendanceContent)
        : undefined,
      enterprisePromotionContent: isSet(object.enterprisePromotionContent)
        ? EnterprisePromotionContent.fromJSON(object.enterprisePromotionContent)
        : undefined,
    };
  },

  toJSON(message: ProposalStatic): unknown {
    const obj: any = {};
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.proposalAddress !== "") {
      obj.proposalAddress = message.proposalAddress;
    }
    if (message.owner !== "") {
      obj.owner = message.owner;
    }
    if (message.purpose !== 0) {
      obj.purpose = Math.round(message.purpose);
    }
    if (message.notBefore !== "") {
      obj.notBefore = message.notBefore;
    }
    if (message.notAfter !== "") {
      obj.notAfter = message.notAfter;
    }
    if (message.hidden === true) {
      obj.hidden = message.hidden;
    }
    if (message.category?.length) {
      obj.category = message.category.map((e) => Math.round(e));
    }
    if (message.condition !== undefined) {
      obj.condition = message.condition;
    }
    if (message.filter !== undefined) {
      obj.filter = message.filter;
    }
    if (message.content !== undefined) {
      obj.content = Content.toJSON(message.content);
    }
    if (message.maxIssuable !== undefined) {
      obj.maxIssuable = Math.round(message.maxIssuable);
    }
    if (message.maxConsumeCount !== undefined) {
      obj.maxConsumeCount = Math.round(message.maxConsumeCount);
    }
    if (message.minConsumeInterval !== undefined) {
      obj.minConsumeInterval = Math.round(message.minConsumeInterval);
    }
    if (message.rewardPolicy !== undefined) {
      obj.rewardPolicy = RewardPolicy.toJSON(message.rewardPolicy);
    }
    if (message.ticketValidFrom !== undefined) {
      obj.ticketValidFrom = message.ticketValidFrom;
    }
    if (message.ticketValidityInSeconds !== undefined) {
      obj.ticketValidityInSeconds = Math.round(message.ticketValidityInSeconds);
    }
    if (message.ticketValidUntil !== undefined) {
      obj.ticketValidUntil = message.ticketValidUntil;
    }
    if (message.dailyQuestionContent !== undefined) {
      obj.dailyQuestionContent = DailyQuestionContent.toJSON(message.dailyQuestionContent);
    }
    if (message.inviteFriendContent !== undefined) {
      obj.inviteFriendContent = InviteFriendContent.toJSON(message.inviteFriendContent);
    }
    if (message.attendanceContent !== undefined) {
      obj.attendanceContent = AttendanceContent.toJSON(message.attendanceContent);
    }
    if (message.enterprisePromotionContent !== undefined) {
      obj.enterprisePromotionContent = EnterprisePromotionContent.toJSON(message.enterprisePromotionContent);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalStatic>, I>>(base?: I): ProposalStatic {
    return ProposalStatic.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalStatic>, I>>(object: I): ProposalStatic {
    const message = createBaseProposalStatic();
    message.proposalId = object.proposalId ?? "";
    message.proposalAddress = object.proposalAddress ?? "";
    message.owner = object.owner ?? "";
    message.purpose = object.purpose ?? 0;
    message.notBefore = object.notBefore ?? "";
    message.notAfter = object.notAfter ?? "";
    message.hidden = object.hidden ?? false;
    message.category = object.category?.map((e) => e) || [];
    message.condition = object.condition ?? undefined;
    message.filter = object.filter ?? undefined;
    message.content = (object.content !== undefined && object.content !== null)
      ? Content.fromPartial(object.content)
      : undefined;
    message.maxIssuable = object.maxIssuable ?? undefined;
    message.maxConsumeCount = object.maxConsumeCount ?? undefined;
    message.minConsumeInterval = object.minConsumeInterval ?? undefined;
    message.rewardPolicy = (object.rewardPolicy !== undefined && object.rewardPolicy !== null)
      ? RewardPolicy.fromPartial(object.rewardPolicy)
      : undefined;
    message.ticketValidFrom = object.ticketValidFrom ?? undefined;
    message.ticketValidityInSeconds = object.ticketValidityInSeconds ?? undefined;
    message.ticketValidUntil = object.ticketValidUntil ?? undefined;
    message.dailyQuestionContent = (object.dailyQuestionContent !== undefined && object.dailyQuestionContent !== null)
      ? DailyQuestionContent.fromPartial(object.dailyQuestionContent)
      : undefined;
    message.inviteFriendContent = (object.inviteFriendContent !== undefined && object.inviteFriendContent !== null)
      ? InviteFriendContent.fromPartial(object.inviteFriendContent)
      : undefined;
    message.attendanceContent = (object.attendanceContent !== undefined && object.attendanceContent !== null)
      ? AttendanceContent.fromPartial(object.attendanceContent)
      : undefined;
    message.enterprisePromotionContent =
      (object.enterprisePromotionContent !== undefined && object.enterprisePromotionContent !== null)
        ? EnterprisePromotionContent.fromPartial(object.enterprisePromotionContent)
        : undefined;
    return message;
  },
};

function createBaseProposalActiveGlobal(): ProposalActiveGlobal {
  return {
    proposalStatus: 0,
    registeredAt: undefined,
    activatedAt: undefined,
    revokedAt: undefined,
    droughtAt: undefined,
    completedAt: undefined,
    updatedAt: undefined,
    deactivatedAt: undefined,
    totalIssued: undefined,
  };
}

export const ProposalActiveGlobal = {
  encode(message: ProposalActiveGlobal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalStatus !== 0) {
      writer.uint32(8).int32(message.proposalStatus);
    }
    if (message.registeredAt !== undefined) {
      writer.uint32(18).string(message.registeredAt);
    }
    if (message.activatedAt !== undefined) {
      writer.uint32(26).string(message.activatedAt);
    }
    if (message.revokedAt !== undefined) {
      writer.uint32(34).string(message.revokedAt);
    }
    if (message.droughtAt !== undefined) {
      writer.uint32(42).string(message.droughtAt);
    }
    if (message.completedAt !== undefined) {
      writer.uint32(50).string(message.completedAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(66).string(message.updatedAt);
    }
    if (message.deactivatedAt !== undefined) {
      writer.uint32(74).string(message.deactivatedAt);
    }
    if (message.totalIssued !== undefined) {
      writer.uint32(56).uint32(message.totalIssued);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalActiveGlobal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalActiveGlobal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.proposalStatus = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.registeredAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.activatedAt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.revokedAt = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.droughtAt = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.completedAt = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deactivatedAt = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.totalIssued = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalActiveGlobal {
    return {
      proposalStatus: isSet(object.proposalStatus) ? proposalStatusFromJSON(object.proposalStatus) : 0,
      registeredAt: isSet(object.registeredAt) ? globalThis.String(object.registeredAt) : undefined,
      activatedAt: isSet(object.activatedAt) ? globalThis.String(object.activatedAt) : undefined,
      revokedAt: isSet(object.revokedAt) ? globalThis.String(object.revokedAt) : undefined,
      droughtAt: isSet(object.droughtAt) ? globalThis.String(object.droughtAt) : undefined,
      completedAt: isSet(object.completedAt) ? globalThis.String(object.completedAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : undefined,
      deactivatedAt: isSet(object.deactivatedAt) ? globalThis.String(object.deactivatedAt) : undefined,
      totalIssued: isSet(object.totalIssued) ? globalThis.Number(object.totalIssued) : undefined,
    };
  },

  toJSON(message: ProposalActiveGlobal): unknown {
    const obj: any = {};
    if (message.proposalStatus !== 0) {
      obj.proposalStatus = proposalStatusToJSON(message.proposalStatus);
    }
    if (message.registeredAt !== undefined) {
      obj.registeredAt = message.registeredAt;
    }
    if (message.activatedAt !== undefined) {
      obj.activatedAt = message.activatedAt;
    }
    if (message.revokedAt !== undefined) {
      obj.revokedAt = message.revokedAt;
    }
    if (message.droughtAt !== undefined) {
      obj.droughtAt = message.droughtAt;
    }
    if (message.completedAt !== undefined) {
      obj.completedAt = message.completedAt;
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt;
    }
    if (message.deactivatedAt !== undefined) {
      obj.deactivatedAt = message.deactivatedAt;
    }
    if (message.totalIssued !== undefined) {
      obj.totalIssued = Math.round(message.totalIssued);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalActiveGlobal>, I>>(base?: I): ProposalActiveGlobal {
    return ProposalActiveGlobal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalActiveGlobal>, I>>(object: I): ProposalActiveGlobal {
    const message = createBaseProposalActiveGlobal();
    message.proposalStatus = object.proposalStatus ?? 0;
    message.registeredAt = object.registeredAt ?? undefined;
    message.activatedAt = object.activatedAt ?? undefined;
    message.revokedAt = object.revokedAt ?? undefined;
    message.droughtAt = object.droughtAt ?? undefined;
    message.completedAt = object.completedAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.deactivatedAt = object.deactivatedAt ?? undefined;
    message.totalIssued = object.totalIssued ?? undefined;
    return message;
  },
};

function createBaseProposalDataUploadStatus(): ProposalDataUploadStatus {
  return {
    oneTimeId: "",
    oneTimeIdRequestedAt: undefined,
    dataUploadRequestedAt: undefined,
    dataUploadedAt: undefined,
  };
}

export const ProposalDataUploadStatus = {
  encode(message: ProposalDataUploadStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.oneTimeId !== "") {
      writer.uint32(10).string(message.oneTimeId);
    }
    if (message.oneTimeIdRequestedAt !== undefined) {
      writer.uint32(18).string(message.oneTimeIdRequestedAt);
    }
    if (message.dataUploadRequestedAt !== undefined) {
      writer.uint32(26).string(message.dataUploadRequestedAt);
    }
    if (message.dataUploadedAt !== undefined) {
      writer.uint32(34).string(message.dataUploadedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalDataUploadStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalDataUploadStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.oneTimeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.oneTimeIdRequestedAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dataUploadRequestedAt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dataUploadedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalDataUploadStatus {
    return {
      oneTimeId: isSet(object.oneTimeId) ? globalThis.String(object.oneTimeId) : "",
      oneTimeIdRequestedAt: isSet(object.oneTimeIdRequestedAt)
        ? globalThis.String(object.oneTimeIdRequestedAt)
        : undefined,
      dataUploadRequestedAt: isSet(object.dataUploadRequestedAt)
        ? globalThis.String(object.dataUploadRequestedAt)
        : undefined,
      dataUploadedAt: isSet(object.dataUploadedAt) ? globalThis.String(object.dataUploadedAt) : undefined,
    };
  },

  toJSON(message: ProposalDataUploadStatus): unknown {
    const obj: any = {};
    if (message.oneTimeId !== "") {
      obj.oneTimeId = message.oneTimeId;
    }
    if (message.oneTimeIdRequestedAt !== undefined) {
      obj.oneTimeIdRequestedAt = message.oneTimeIdRequestedAt;
    }
    if (message.dataUploadRequestedAt !== undefined) {
      obj.dataUploadRequestedAt = message.dataUploadRequestedAt;
    }
    if (message.dataUploadedAt !== undefined) {
      obj.dataUploadedAt = message.dataUploadedAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalDataUploadStatus>, I>>(base?: I): ProposalDataUploadStatus {
    return ProposalDataUploadStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalDataUploadStatus>, I>>(object: I): ProposalDataUploadStatus {
    const message = createBaseProposalDataUploadStatus();
    message.oneTimeId = object.oneTimeId ?? "";
    message.oneTimeIdRequestedAt = object.oneTimeIdRequestedAt ?? undefined;
    message.dataUploadRequestedAt = object.dataUploadRequestedAt ?? undefined;
    message.dataUploadedAt = object.dataUploadedAt ?? undefined;
    return message;
  },
};

function createBaseProposalFiltering(): ProposalFiltering {
  return { visibleProposals: [], forbiddenProposals: [] };
}

export const ProposalFiltering = {
  encode(message: ProposalFiltering, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.visibleProposals) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.forbiddenProposals) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalFiltering {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalFiltering();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.visibleProposals.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.forbiddenProposals.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalFiltering {
    return {
      visibleProposals: globalThis.Array.isArray(object?.visibleProposals)
        ? object.visibleProposals.map((e: any) => globalThis.String(e))
        : [],
      forbiddenProposals: globalThis.Array.isArray(object?.forbiddenProposals)
        ? object.forbiddenProposals.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ProposalFiltering): unknown {
    const obj: any = {};
    if (message.visibleProposals?.length) {
      obj.visibleProposals = message.visibleProposals;
    }
    if (message.forbiddenProposals?.length) {
      obj.forbiddenProposals = message.forbiddenProposals;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalFiltering>, I>>(base?: I): ProposalFiltering {
    return ProposalFiltering.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalFiltering>, I>>(object: I): ProposalFiltering {
    const message = createBaseProposalFiltering();
    message.visibleProposals = object.visibleProposals?.map((e) => e) || [];
    message.forbiddenProposals = object.forbiddenProposals?.map((e) => e) || [];
    return message;
  },
};

function createBaseProposalActiveClient(): ProposalActiveClient {
  return {
    proposalClientStatus: 0,
    issueRequestedAt: undefined,
    issuedAt: undefined,
    rewardedCompletelyAt: undefined,
    uploadStatus: [],
    ticketId: undefined,
    filtering: undefined,
    ticketFromMyd2: false,
    enterprisePromotionClient: undefined,
  };
}

export const ProposalActiveClient = {
  encode(message: ProposalActiveClient, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalClientStatus !== 0) {
      writer.uint32(8).int32(message.proposalClientStatus);
    }
    if (message.issueRequestedAt !== undefined) {
      writer.uint32(18).string(message.issueRequestedAt);
    }
    if (message.issuedAt !== undefined) {
      writer.uint32(26).string(message.issuedAt);
    }
    if (message.rewardedCompletelyAt !== undefined) {
      writer.uint32(34).string(message.rewardedCompletelyAt);
    }
    for (const v of message.uploadStatus) {
      ProposalDataUploadStatus.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.ticketId !== undefined) {
      writer.uint32(50).string(message.ticketId);
    }
    if (message.filtering !== undefined) {
      ProposalFiltering.encode(message.filtering, writer.uint32(58).fork()).ldelim();
    }
    if (message.ticketFromMyd2 === true) {
      writer.uint32(64).bool(message.ticketFromMyd2);
    }
    if (message.enterprisePromotionClient !== undefined) {
      EnterprisePromotionClient.encode(message.enterprisePromotionClient, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalActiveClient {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalActiveClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.proposalClientStatus = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.issueRequestedAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.issuedAt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rewardedCompletelyAt = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.uploadStatus.push(ProposalDataUploadStatus.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ticketId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.filtering = ProposalFiltering.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.ticketFromMyd2 = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.enterprisePromotionClient = EnterprisePromotionClient.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalActiveClient {
    return {
      proposalClientStatus: isSet(object.proposalClientStatus)
        ? proposalClientStatusFromJSON(object.proposalClientStatus)
        : 0,
      issueRequestedAt: isSet(object.issueRequestedAt) ? globalThis.String(object.issueRequestedAt) : undefined,
      issuedAt: isSet(object.issuedAt) ? globalThis.String(object.issuedAt) : undefined,
      rewardedCompletelyAt: isSet(object.rewardedCompletelyAt)
        ? globalThis.String(object.rewardedCompletelyAt)
        : undefined,
      uploadStatus: globalThis.Array.isArray(object?.uploadStatus)
        ? object.uploadStatus.map((e: any) => ProposalDataUploadStatus.fromJSON(e))
        : [],
      ticketId: isSet(object.ticketId) ? globalThis.String(object.ticketId) : undefined,
      filtering: isSet(object.filtering) ? ProposalFiltering.fromJSON(object.filtering) : undefined,
      ticketFromMyd2: isSet(object.ticketFromMyd2) ? globalThis.Boolean(object.ticketFromMyd2) : false,
      enterprisePromotionClient: isSet(object.enterprisePromotionClient)
        ? EnterprisePromotionClient.fromJSON(object.enterprisePromotionClient)
        : undefined,
    };
  },

  toJSON(message: ProposalActiveClient): unknown {
    const obj: any = {};
    if (message.proposalClientStatus !== 0) {
      obj.proposalClientStatus = proposalClientStatusToJSON(message.proposalClientStatus);
    }
    if (message.issueRequestedAt !== undefined) {
      obj.issueRequestedAt = message.issueRequestedAt;
    }
    if (message.issuedAt !== undefined) {
      obj.issuedAt = message.issuedAt;
    }
    if (message.rewardedCompletelyAt !== undefined) {
      obj.rewardedCompletelyAt = message.rewardedCompletelyAt;
    }
    if (message.uploadStatus?.length) {
      obj.uploadStatus = message.uploadStatus.map((e) => ProposalDataUploadStatus.toJSON(e));
    }
    if (message.ticketId !== undefined) {
      obj.ticketId = message.ticketId;
    }
    if (message.filtering !== undefined) {
      obj.filtering = ProposalFiltering.toJSON(message.filtering);
    }
    if (message.ticketFromMyd2 === true) {
      obj.ticketFromMyd2 = message.ticketFromMyd2;
    }
    if (message.enterprisePromotionClient !== undefined) {
      obj.enterprisePromotionClient = EnterprisePromotionClient.toJSON(message.enterprisePromotionClient);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalActiveClient>, I>>(base?: I): ProposalActiveClient {
    return ProposalActiveClient.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProposalActiveClient>, I>>(object: I): ProposalActiveClient {
    const message = createBaseProposalActiveClient();
    message.proposalClientStatus = object.proposalClientStatus ?? 0;
    message.issueRequestedAt = object.issueRequestedAt ?? undefined;
    message.issuedAt = object.issuedAt ?? undefined;
    message.rewardedCompletelyAt = object.rewardedCompletelyAt ?? undefined;
    message.uploadStatus = object.uploadStatus?.map((e) => ProposalDataUploadStatus.fromPartial(e)) || [];
    message.ticketId = object.ticketId ?? undefined;
    message.filtering = (object.filtering !== undefined && object.filtering !== null)
      ? ProposalFiltering.fromPartial(object.filtering)
      : undefined;
    message.ticketFromMyd2 = object.ticketFromMyd2 ?? false;
    message.enterprisePromotionClient =
      (object.enterprisePromotionClient !== undefined && object.enterprisePromotionClient !== null)
        ? EnterprisePromotionClient.fromPartial(object.enterprisePromotionClient)
        : undefined;
    return message;
  },
};

function createBaseProposal(): Proposal {
  return { proposalId: "", static: undefined, activeGlobal: undefined, activeClient: undefined };
}

export const Proposal = {
  encode(message: Proposal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId !== "") {
      writer.uint32(10).string(message.proposalId);
    }
    if (message.static !== undefined) {
      ProposalStatic.encode(message.static, writer.uint32(18).fork()).ldelim();
    }
    if (message.activeGlobal !== undefined) {
      ProposalActiveGlobal.encode(message.activeGlobal, writer.uint32(26).fork()).ldelim();
    }
    if (message.activeClient !== undefined) {
      ProposalActiveClient.encode(message.activeClient, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Proposal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.static = ProposalStatic.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.activeGlobal = ProposalActiveGlobal.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.activeClient = ProposalActiveClient.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Proposal {
    return {
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
      static: isSet(object.static) ? ProposalStatic.fromJSON(object.static) : undefined,
      activeGlobal: isSet(object.activeGlobal) ? ProposalActiveGlobal.fromJSON(object.activeGlobal) : undefined,
      activeClient: isSet(object.activeClient) ? ProposalActiveClient.fromJSON(object.activeClient) : undefined,
    };
  },

  toJSON(message: Proposal): unknown {
    const obj: any = {};
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.static !== undefined) {
      obj.static = ProposalStatic.toJSON(message.static);
    }
    if (message.activeGlobal !== undefined) {
      obj.activeGlobal = ProposalActiveGlobal.toJSON(message.activeGlobal);
    }
    if (message.activeClient !== undefined) {
      obj.activeClient = ProposalActiveClient.toJSON(message.activeClient);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Proposal>, I>>(base?: I): Proposal {
    return Proposal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Proposal>, I>>(object: I): Proposal {
    const message = createBaseProposal();
    message.proposalId = object.proposalId ?? "";
    message.static = (object.static !== undefined && object.static !== null)
      ? ProposalStatic.fromPartial(object.static)
      : undefined;
    message.activeGlobal = (object.activeGlobal !== undefined && object.activeGlobal !== null)
      ? ProposalActiveGlobal.fromPartial(object.activeGlobal)
      : undefined;
    message.activeClient = (object.activeClient !== undefined && object.activeClient !== null)
      ? ProposalActiveClient.fromPartial(object.activeClient)
      : undefined;
    return message;
  },
};

function createBaseEnterprisePromotionContent(): EnterprisePromotionContent {
  return { contentId: 0, codeType: 0, promotionCode: "", promotionMission: [], sumOfRewardPoint: 0 };
}

export const EnterprisePromotionContent = {
  encode(message: EnterprisePromotionContent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contentId !== 0) {
      writer.uint32(8).uint32(message.contentId);
    }
    if (message.codeType !== 0) {
      writer.uint32(16).int32(message.codeType);
    }
    if (message.promotionCode !== "") {
      writer.uint32(26).string(message.promotionCode);
    }
    for (const v of message.promotionMission) {
      EnterprisePromotionMission.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.sumOfRewardPoint !== 0) {
      writer.uint32(40).uint64(message.sumOfRewardPoint);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnterprisePromotionContent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnterprisePromotionContent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.contentId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.codeType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.promotionCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.promotionMission.push(EnterprisePromotionMission.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.sumOfRewardPoint = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnterprisePromotionContent {
    return {
      contentId: isSet(object.contentId) ? globalThis.Number(object.contentId) : 0,
      codeType: isSet(object.codeType) ? codeTypeFromJSON(object.codeType) : 0,
      promotionCode: isSet(object.promotionCode) ? globalThis.String(object.promotionCode) : "",
      promotionMission: globalThis.Array.isArray(object?.promotionMission)
        ? object.promotionMission.map((e: any) => EnterprisePromotionMission.fromJSON(e))
        : [],
      sumOfRewardPoint: isSet(object.sumOfRewardPoint) ? globalThis.Number(object.sumOfRewardPoint) : 0,
    };
  },

  toJSON(message: EnterprisePromotionContent): unknown {
    const obj: any = {};
    if (message.contentId !== 0) {
      obj.contentId = Math.round(message.contentId);
    }
    if (message.codeType !== 0) {
      obj.codeType = codeTypeToJSON(message.codeType);
    }
    if (message.promotionCode !== "") {
      obj.promotionCode = message.promotionCode;
    }
    if (message.promotionMission?.length) {
      obj.promotionMission = message.promotionMission.map((e) => EnterprisePromotionMission.toJSON(e));
    }
    if (message.sumOfRewardPoint !== 0) {
      obj.sumOfRewardPoint = Math.round(message.sumOfRewardPoint);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnterprisePromotionContent>, I>>(base?: I): EnterprisePromotionContent {
    return EnterprisePromotionContent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EnterprisePromotionContent>, I>>(object: I): EnterprisePromotionContent {
    const message = createBaseEnterprisePromotionContent();
    message.contentId = object.contentId ?? 0;
    message.codeType = object.codeType ?? 0;
    message.promotionCode = object.promotionCode ?? "";
    message.promotionMission = object.promotionMission?.map((e) => EnterprisePromotionMission.fromPartial(e)) || [];
    message.sumOfRewardPoint = object.sumOfRewardPoint ?? 0;
    return message;
  },
};

function createBaseEnterprisePromotionMission(): EnterprisePromotionMission {
  return { title: "", compensationDueDate: "", rewardAmount: 0, missionPeriod: "", rewardItem: [], rewardType: 0 };
}

export const EnterprisePromotionMission = {
  encode(message: EnterprisePromotionMission, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.compensationDueDate !== "") {
      writer.uint32(18).string(message.compensationDueDate);
    }
    if (message.rewardAmount !== 0) {
      writer.uint32(24).uint64(message.rewardAmount);
    }
    if (message.missionPeriod !== "") {
      writer.uint32(34).string(message.missionPeriod);
    }
    for (const v of message.rewardItem) {
      EnterprisePromotionRewardItem.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.rewardType !== 0) {
      writer.uint32(48).int32(message.rewardType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnterprisePromotionMission {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnterprisePromotionMission();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.compensationDueDate = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.rewardAmount = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.missionPeriod = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.rewardItem.push(EnterprisePromotionRewardItem.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.rewardType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnterprisePromotionMission {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      compensationDueDate: isSet(object.compensationDueDate) ? globalThis.String(object.compensationDueDate) : "",
      rewardAmount: isSet(object.rewardAmount) ? globalThis.Number(object.rewardAmount) : 0,
      missionPeriod: isSet(object.missionPeriod) ? globalThis.String(object.missionPeriod) : "",
      rewardItem: globalThis.Array.isArray(object?.rewardItem)
        ? object.rewardItem.map((e: any) => EnterprisePromotionRewardItem.fromJSON(e))
        : [],
      rewardType: isSet(object.rewardType) ? enterpriseRewardTypeFromJSON(object.rewardType) : 0,
    };
  },

  toJSON(message: EnterprisePromotionMission): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.compensationDueDate !== "") {
      obj.compensationDueDate = message.compensationDueDate;
    }
    if (message.rewardAmount !== 0) {
      obj.rewardAmount = Math.round(message.rewardAmount);
    }
    if (message.missionPeriod !== "") {
      obj.missionPeriod = message.missionPeriod;
    }
    if (message.rewardItem?.length) {
      obj.rewardItem = message.rewardItem.map((e) => EnterprisePromotionRewardItem.toJSON(e));
    }
    if (message.rewardType !== 0) {
      obj.rewardType = enterpriseRewardTypeToJSON(message.rewardType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnterprisePromotionMission>, I>>(base?: I): EnterprisePromotionMission {
    return EnterprisePromotionMission.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EnterprisePromotionMission>, I>>(object: I): EnterprisePromotionMission {
    const message = createBaseEnterprisePromotionMission();
    message.title = object.title ?? "";
    message.compensationDueDate = object.compensationDueDate ?? "";
    message.rewardAmount = object.rewardAmount ?? 0;
    message.missionPeriod = object.missionPeriod ?? "";
    message.rewardItem = object.rewardItem?.map((e) => EnterprisePromotionRewardItem.fromPartial(e)) || [];
    message.rewardType = object.rewardType ?? 0;
    return message;
  },
};

function createBaseEnterprisePromotionRewardItem(): EnterprisePromotionRewardItem {
  return { rewardConstraint: undefined };
}

export const EnterprisePromotionRewardItem = {
  encode(message: EnterprisePromotionRewardItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rewardConstraint !== undefined) {
      RewardConstraint.encode(message.rewardConstraint, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnterprisePromotionRewardItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnterprisePromotionRewardItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rewardConstraint = RewardConstraint.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnterprisePromotionRewardItem {
    return {
      rewardConstraint: isSet(object.rewardConstraint) ? RewardConstraint.fromJSON(object.rewardConstraint) : undefined,
    };
  },

  toJSON(message: EnterprisePromotionRewardItem): unknown {
    const obj: any = {};
    if (message.rewardConstraint !== undefined) {
      obj.rewardConstraint = RewardConstraint.toJSON(message.rewardConstraint);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnterprisePromotionRewardItem>, I>>(base?: I): EnterprisePromotionRewardItem {
    return EnterprisePromotionRewardItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EnterprisePromotionRewardItem>, I>>(
    object: I,
  ): EnterprisePromotionRewardItem {
    const message = createBaseEnterprisePromotionRewardItem();
    message.rewardConstraint = (object.rewardConstraint !== undefined && object.rewardConstraint !== null)
      ? RewardConstraint.fromPartial(object.rewardConstraint)
      : undefined;
    return message;
  },
};

function createBaseRewardConstraint(): RewardConstraint {
  return { must: [], mustNot: [], should: [], minimumShouldMatch: 0, purpose: "" };
}

export const RewardConstraint = {
  encode(message: RewardConstraint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.must) {
      ElasticSearchSingleCondition.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.mustNot) {
      ElasticSearchSingleCondition.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.should) {
      ElasticSearchSingleCondition.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.minimumShouldMatch !== 0) {
      writer.uint32(32).uint32(message.minimumShouldMatch);
    }
    if (message.purpose !== "") {
      writer.uint32(42).string(message.purpose);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardConstraint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardConstraint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.must.push(ElasticSearchSingleCondition.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mustNot.push(ElasticSearchSingleCondition.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.should.push(ElasticSearchSingleCondition.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.minimumShouldMatch = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.purpose = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RewardConstraint {
    return {
      must: globalThis.Array.isArray(object?.must)
        ? object.must.map((e: any) => ElasticSearchSingleCondition.fromJSON(e))
        : [],
      mustNot: globalThis.Array.isArray(object?.mustNot)
        ? object.mustNot.map((e: any) => ElasticSearchSingleCondition.fromJSON(e))
        : [],
      should: globalThis.Array.isArray(object?.should)
        ? object.should.map((e: any) => ElasticSearchSingleCondition.fromJSON(e))
        : [],
      minimumShouldMatch: isSet(object.minimumShouldMatch) ? globalThis.Number(object.minimumShouldMatch) : 0,
      purpose: isSet(object.purpose) ? globalThis.String(object.purpose) : "",
    };
  },

  toJSON(message: RewardConstraint): unknown {
    const obj: any = {};
    if (message.must?.length) {
      obj.must = message.must.map((e) => ElasticSearchSingleCondition.toJSON(e));
    }
    if (message.mustNot?.length) {
      obj.mustNot = message.mustNot.map((e) => ElasticSearchSingleCondition.toJSON(e));
    }
    if (message.should?.length) {
      obj.should = message.should.map((e) => ElasticSearchSingleCondition.toJSON(e));
    }
    if (message.minimumShouldMatch !== 0) {
      obj.minimumShouldMatch = Math.round(message.minimumShouldMatch);
    }
    if (message.purpose !== "") {
      obj.purpose = message.purpose;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardConstraint>, I>>(base?: I): RewardConstraint {
    return RewardConstraint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RewardConstraint>, I>>(object: I): RewardConstraint {
    const message = createBaseRewardConstraint();
    message.must = object.must?.map((e) => ElasticSearchSingleCondition.fromPartial(e)) || [];
    message.mustNot = object.mustNot?.map((e) => ElasticSearchSingleCondition.fromPartial(e)) || [];
    message.should = object.should?.map((e) => ElasticSearchSingleCondition.fromPartial(e)) || [];
    message.minimumShouldMatch = object.minimumShouldMatch ?? 0;
    message.purpose = object.purpose ?? "";
    return message;
  },
};

function createBaseElasticSearchQueryResult(): ElasticSearchQueryResult {
  return { longResult: undefined, booleanResult: undefined, stringresult: undefined };
}

export const ElasticSearchQueryResult = {
  encode(message: ElasticSearchQueryResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.longResult !== undefined) {
      writer.uint32(8).uint64(message.longResult);
    }
    if (message.booleanResult !== undefined) {
      writer.uint32(16).bool(message.booleanResult);
    }
    if (message.stringresult !== undefined) {
      writer.uint32(26).string(message.stringresult);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ElasticSearchQueryResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElasticSearchQueryResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.longResult = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.booleanResult = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.stringresult = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ElasticSearchQueryResult {
    return {
      longResult: isSet(object.longResult) ? globalThis.Number(object.longResult) : undefined,
      booleanResult: isSet(object.booleanResult) ? globalThis.Boolean(object.booleanResult) : undefined,
      stringresult: isSet(object.stringresult) ? globalThis.String(object.stringresult) : undefined,
    };
  },

  toJSON(message: ElasticSearchQueryResult): unknown {
    const obj: any = {};
    if (message.longResult !== undefined) {
      obj.longResult = Math.round(message.longResult);
    }
    if (message.booleanResult !== undefined) {
      obj.booleanResult = message.booleanResult;
    }
    if (message.stringresult !== undefined) {
      obj.stringresult = message.stringresult;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ElasticSearchQueryResult>, I>>(base?: I): ElasticSearchQueryResult {
    return ElasticSearchQueryResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ElasticSearchQueryResult>, I>>(object: I): ElasticSearchQueryResult {
    const message = createBaseElasticSearchQueryResult();
    message.longResult = object.longResult ?? undefined;
    message.booleanResult = object.booleanResult ?? undefined;
    message.stringresult = object.stringresult ?? undefined;
    return message;
  },
};

function createBaseElasticSearchSingleCondition(): ElasticSearchSingleCondition {
  return { queryTemplateId: "", expectedValue: undefined };
}

export const ElasticSearchSingleCondition = {
  encode(message: ElasticSearchSingleCondition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.queryTemplateId !== "") {
      writer.uint32(10).string(message.queryTemplateId);
    }
    if (message.expectedValue !== undefined) {
      ElasticSearchQueryResult.encode(message.expectedValue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ElasticSearchSingleCondition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElasticSearchSingleCondition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.queryTemplateId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.expectedValue = ElasticSearchQueryResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ElasticSearchSingleCondition {
    return {
      queryTemplateId: isSet(object.queryTemplateId) ? globalThis.String(object.queryTemplateId) : "",
      expectedValue: isSet(object.expectedValue) ? ElasticSearchQueryResult.fromJSON(object.expectedValue) : undefined,
    };
  },

  toJSON(message: ElasticSearchSingleCondition): unknown {
    const obj: any = {};
    if (message.queryTemplateId !== "") {
      obj.queryTemplateId = message.queryTemplateId;
    }
    if (message.expectedValue !== undefined) {
      obj.expectedValue = ElasticSearchQueryResult.toJSON(message.expectedValue);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ElasticSearchSingleCondition>, I>>(base?: I): ElasticSearchSingleCondition {
    return ElasticSearchSingleCondition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ElasticSearchSingleCondition>, I>>(object: I): ElasticSearchSingleCondition {
    const message = createBaseElasticSearchSingleCondition();
    message.queryTemplateId = object.queryTemplateId ?? "";
    message.expectedValue = (object.expectedValue !== undefined && object.expectedValue !== null)
      ? ElasticSearchQueryResult.fromPartial(object.expectedValue)
      : undefined;
    return message;
  },
};

function createBaseEnterprisePromotionClient(): EnterprisePromotionClient {
  return {
    hash: new Uint8Array(0),
    proposalId: "",
    companyDid: "",
    enterprisePromotionRewardHistory: [],
    enterprisePromotionCodeHistory: [],
  };
}

export const EnterprisePromotionClient = {
  encode(message: EnterprisePromotionClient, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hash.length !== 0) {
      writer.uint32(10).bytes(message.hash);
    }
    if (message.proposalId !== "") {
      writer.uint32(18).string(message.proposalId);
    }
    if (message.companyDid !== "") {
      writer.uint32(26).string(message.companyDid);
    }
    for (const v of message.enterprisePromotionRewardHistory) {
      EnterprisePromotionRewardHistory.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.enterprisePromotionCodeHistory) {
      EnterprisePromotionCodeHistory.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnterprisePromotionClient {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnterprisePromotionClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.hash = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.companyDid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.enterprisePromotionRewardHistory.push(
            EnterprisePromotionRewardHistory.decode(reader, reader.uint32()),
          );
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.enterprisePromotionCodeHistory.push(EnterprisePromotionCodeHistory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnterprisePromotionClient {
    return {
      hash: isSet(object.hash) ? bytesFromBase64(object.hash) : new Uint8Array(0),
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
      companyDid: isSet(object.companyDid) ? globalThis.String(object.companyDid) : "",
      enterprisePromotionRewardHistory: globalThis.Array.isArray(object?.enterprisePromotionRewardHistory)
        ? object.enterprisePromotionRewardHistory.map((e: any) => EnterprisePromotionRewardHistory.fromJSON(e))
        : [],
      enterprisePromotionCodeHistory: globalThis.Array.isArray(object?.enterprisePromotionCodeHistory)
        ? object.enterprisePromotionCodeHistory.map((e: any) => EnterprisePromotionCodeHistory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EnterprisePromotionClient): unknown {
    const obj: any = {};
    if (message.hash.length !== 0) {
      obj.hash = base64FromBytes(message.hash);
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.companyDid !== "") {
      obj.companyDid = message.companyDid;
    }
    if (message.enterprisePromotionRewardHistory?.length) {
      obj.enterprisePromotionRewardHistory = message.enterprisePromotionRewardHistory.map((e) =>
        EnterprisePromotionRewardHistory.toJSON(e)
      );
    }
    if (message.enterprisePromotionCodeHistory?.length) {
      obj.enterprisePromotionCodeHistory = message.enterprisePromotionCodeHistory.map((e) =>
        EnterprisePromotionCodeHistory.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnterprisePromotionClient>, I>>(base?: I): EnterprisePromotionClient {
    return EnterprisePromotionClient.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EnterprisePromotionClient>, I>>(object: I): EnterprisePromotionClient {
    const message = createBaseEnterprisePromotionClient();
    message.hash = object.hash ?? new Uint8Array(0);
    message.proposalId = object.proposalId ?? "";
    message.companyDid = object.companyDid ?? "";
    message.enterprisePromotionRewardHistory =
      object.enterprisePromotionRewardHistory?.map((e) => EnterprisePromotionRewardHistory.fromPartial(e)) || [];
    message.enterprisePromotionCodeHistory =
      object.enterprisePromotionCodeHistory?.map((e) => EnterprisePromotionCodeHistory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEnterprisePromotionRewardHistory(): EnterprisePromotionRewardHistory {
  return { itemId: 0, contentId: 0, did: "", rewardedAt: "", rewardAmount: 0, status: 0 };
}

export const EnterprisePromotionRewardHistory = {
  encode(message: EnterprisePromotionRewardHistory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.itemId !== 0) {
      writer.uint32(8).uint32(message.itemId);
    }
    if (message.contentId !== 0) {
      writer.uint32(16).uint32(message.contentId);
    }
    if (message.did !== "") {
      writer.uint32(26).string(message.did);
    }
    if (message.rewardedAt !== "") {
      writer.uint32(34).string(message.rewardedAt);
    }
    if (message.rewardAmount !== 0) {
      writer.uint32(40).uint32(message.rewardAmount);
    }
    if (message.status !== 0) {
      writer.uint32(48).uint32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnterprisePromotionRewardHistory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnterprisePromotionRewardHistory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.itemId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.contentId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.did = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rewardedAt = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.rewardAmount = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnterprisePromotionRewardHistory {
    return {
      itemId: isSet(object.itemId) ? globalThis.Number(object.itemId) : 0,
      contentId: isSet(object.contentId) ? globalThis.Number(object.contentId) : 0,
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      rewardedAt: isSet(object.rewardedAt) ? globalThis.String(object.rewardedAt) : "",
      rewardAmount: isSet(object.rewardAmount) ? globalThis.Number(object.rewardAmount) : 0,
      status: isSet(object.status) ? globalThis.Number(object.status) : 0,
    };
  },

  toJSON(message: EnterprisePromotionRewardHistory): unknown {
    const obj: any = {};
    if (message.itemId !== 0) {
      obj.itemId = Math.round(message.itemId);
    }
    if (message.contentId !== 0) {
      obj.contentId = Math.round(message.contentId);
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.rewardedAt !== "") {
      obj.rewardedAt = message.rewardedAt;
    }
    if (message.rewardAmount !== 0) {
      obj.rewardAmount = Math.round(message.rewardAmount);
    }
    if (message.status !== 0) {
      obj.status = Math.round(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnterprisePromotionRewardHistory>, I>>(
    base?: I,
  ): EnterprisePromotionRewardHistory {
    return EnterprisePromotionRewardHistory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EnterprisePromotionRewardHistory>, I>>(
    object: I,
  ): EnterprisePromotionRewardHistory {
    const message = createBaseEnterprisePromotionRewardHistory();
    message.itemId = object.itemId ?? 0;
    message.contentId = object.contentId ?? 0;
    message.did = object.did ?? "";
    message.rewardedAt = object.rewardedAt ?? "";
    message.rewardAmount = object.rewardAmount ?? 0;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseEnterprisePromotionCodeHistory(): EnterprisePromotionCodeHistory {
  return { promotionCode: undefined, hash: undefined, did: "", appliedAt: "", status: 0 };
}

export const EnterprisePromotionCodeHistory = {
  encode(message: EnterprisePromotionCodeHistory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.promotionCode !== undefined) {
      writer.uint32(10).string(message.promotionCode);
    }
    if (message.hash !== undefined) {
      writer.uint32(18).bytes(message.hash);
    }
    if (message.did !== "") {
      writer.uint32(26).string(message.did);
    }
    if (message.appliedAt !== "") {
      writer.uint32(34).string(message.appliedAt);
    }
    if (message.status !== 0) {
      writer.uint32(40).uint32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnterprisePromotionCodeHistory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnterprisePromotionCodeHistory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.promotionCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hash = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.did = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.appliedAt = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnterprisePromotionCodeHistory {
    return {
      promotionCode: isSet(object.promotionCode) ? globalThis.String(object.promotionCode) : undefined,
      hash: isSet(object.hash) ? bytesFromBase64(object.hash) : undefined,
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      appliedAt: isSet(object.appliedAt) ? globalThis.String(object.appliedAt) : "",
      status: isSet(object.status) ? globalThis.Number(object.status) : 0,
    };
  },

  toJSON(message: EnterprisePromotionCodeHistory): unknown {
    const obj: any = {};
    if (message.promotionCode !== undefined) {
      obj.promotionCode = message.promotionCode;
    }
    if (message.hash !== undefined) {
      obj.hash = base64FromBytes(message.hash);
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.appliedAt !== "") {
      obj.appliedAt = message.appliedAt;
    }
    if (message.status !== 0) {
      obj.status = Math.round(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnterprisePromotionCodeHistory>, I>>(base?: I): EnterprisePromotionCodeHistory {
    return EnterprisePromotionCodeHistory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EnterprisePromotionCodeHistory>, I>>(
    object: I,
  ): EnterprisePromotionCodeHistory {
    const message = createBaseEnterprisePromotionCodeHistory();
    message.promotionCode = object.promotionCode ?? undefined;
    message.hash = object.hash ?? undefined;
    message.did = object.did ?? "";
    message.appliedAt = object.appliedAt ?? "";
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseRewardTransactionContentForApp(): RewardTransactionContentForApp {
  return { amount: 0, createdAt: "", category: "", title: "" };
}

export const RewardTransactionContentForApp = {
  encode(message: RewardTransactionContentForApp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== 0) {
      writer.uint32(8).sint64(message.amount);
    }
    if (message.createdAt !== "") {
      writer.uint32(26).string(message.createdAt);
    }
    if (message.category !== "") {
      writer.uint32(34).string(message.category);
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardTransactionContentForApp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardTransactionContentForApp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.amount = longToNumber(reader.sint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.category = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RewardTransactionContentForApp {
    return {
      amount: isSet(object.amount) ? globalThis.Number(object.amount) : 0,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      category: isSet(object.category) ? globalThis.String(object.category) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
    };
  },

  toJSON(message: RewardTransactionContentForApp): unknown {
    const obj: any = {};
    if (message.amount !== 0) {
      obj.amount = Math.round(message.amount);
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardTransactionContentForApp>, I>>(base?: I): RewardTransactionContentForApp {
    return RewardTransactionContentForApp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RewardTransactionContentForApp>, I>>(
    object: I,
  ): RewardTransactionContentForApp {
    const message = createBaseRewardTransactionContentForApp();
    message.amount = object.amount ?? 0;
    message.createdAt = object.createdAt ?? "";
    message.category = object.category ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseVoIPSignalRequest(): VoIPSignalRequest {
  return {
    did: "",
    callRoomId: "",
    action: 0,
    lastSeq: 0,
    seq: undefined,
    callTitle: undefined,
    callerDid: "",
    calleeDid: undefined,
    direction: undefined,
    p2pMessageType: undefined,
    p2pMessage: undefined,
  };
}

export const VoIPSignalRequest = {
  encode(message: VoIPSignalRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.callRoomId !== "") {
      writer.uint32(18).string(message.callRoomId);
    }
    if (message.action !== 0) {
      writer.uint32(24).int32(message.action);
    }
    if (message.lastSeq !== 0) {
      writer.uint32(32).uint32(message.lastSeq);
    }
    if (message.seq !== undefined) {
      writer.uint32(80).uint32(message.seq);
    }
    if (message.callTitle !== undefined) {
      writer.uint32(90).string(message.callTitle);
    }
    if (message.callerDid !== "") {
      writer.uint32(98).string(message.callerDid);
    }
    if (message.calleeDid !== undefined) {
      writer.uint32(106).string(message.calleeDid);
    }
    if (message.direction !== undefined) {
      writer.uint32(112).int32(message.direction);
    }
    if (message.p2pMessageType !== undefined) {
      writer.uint32(120).int32(message.p2pMessageType);
    }
    if (message.p2pMessage !== undefined) {
      writer.uint32(130).bytes(message.p2pMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VoIPSignalRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVoIPSignalRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.callRoomId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.action = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lastSeq = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.seq = reader.uint32();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.callTitle = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.callerDid = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.calleeDid = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.direction = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.p2pMessageType = reader.int32() as any;
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.p2pMessage = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VoIPSignalRequest {
    return {
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      callRoomId: isSet(object.callRoomId) ? globalThis.String(object.callRoomId) : "",
      action: isSet(object.action) ? voIPSignalActionFromJSON(object.action) : 0,
      lastSeq: isSet(object.lastSeq) ? globalThis.Number(object.lastSeq) : 0,
      seq: isSet(object.seq) ? globalThis.Number(object.seq) : undefined,
      callTitle: isSet(object.callTitle) ? globalThis.String(object.callTitle) : undefined,
      callerDid: isSet(object.callerDid) ? globalThis.String(object.callerDid) : "",
      calleeDid: isSet(object.calleeDid) ? globalThis.String(object.calleeDid) : undefined,
      direction: isSet(object.direction) ? voIPP2PMessageDirectionFromJSON(object.direction) : undefined,
      p2pMessageType: isSet(object.p2pMessageType) ? voIPP2PMessageTypeFromJSON(object.p2pMessageType) : undefined,
      p2pMessage: isSet(object.p2pMessage) ? bytesFromBase64(object.p2pMessage) : undefined,
    };
  },

  toJSON(message: VoIPSignalRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.callRoomId !== "") {
      obj.callRoomId = message.callRoomId;
    }
    if (message.action !== 0) {
      obj.action = voIPSignalActionToJSON(message.action);
    }
    if (message.lastSeq !== 0) {
      obj.lastSeq = Math.round(message.lastSeq);
    }
    if (message.seq !== undefined) {
      obj.seq = Math.round(message.seq);
    }
    if (message.callTitle !== undefined) {
      obj.callTitle = message.callTitle;
    }
    if (message.callerDid !== "") {
      obj.callerDid = message.callerDid;
    }
    if (message.calleeDid !== undefined) {
      obj.calleeDid = message.calleeDid;
    }
    if (message.direction !== undefined) {
      obj.direction = voIPP2PMessageDirectionToJSON(message.direction);
    }
    if (message.p2pMessageType !== undefined) {
      obj.p2pMessageType = voIPP2PMessageTypeToJSON(message.p2pMessageType);
    }
    if (message.p2pMessage !== undefined) {
      obj.p2pMessage = base64FromBytes(message.p2pMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VoIPSignalRequest>, I>>(base?: I): VoIPSignalRequest {
    return VoIPSignalRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VoIPSignalRequest>, I>>(object: I): VoIPSignalRequest {
    const message = createBaseVoIPSignalRequest();
    message.did = object.did ?? "";
    message.callRoomId = object.callRoomId ?? "";
    message.action = object.action ?? 0;
    message.lastSeq = object.lastSeq ?? 0;
    message.seq = object.seq ?? undefined;
    message.callTitle = object.callTitle ?? undefined;
    message.callerDid = object.callerDid ?? "";
    message.calleeDid = object.calleeDid ?? undefined;
    message.direction = object.direction ?? undefined;
    message.p2pMessageType = object.p2pMessageType ?? undefined;
    message.p2pMessage = object.p2pMessage ?? undefined;
    return message;
  },
};

function createBaseVoIPSignalResponse(): VoIPSignalResponse {
  return { room: undefined, requests: [] };
}

export const VoIPSignalResponse = {
  encode(message: VoIPSignalResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.room !== undefined) {
      VoIPRoomDescription.encode(message.room, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.requests) {
      VoIPSignalRequest.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VoIPSignalResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVoIPSignalResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.room = VoIPRoomDescription.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.requests.push(VoIPSignalRequest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VoIPSignalResponse {
    return {
      room: isSet(object.room) ? VoIPRoomDescription.fromJSON(object.room) : undefined,
      requests: globalThis.Array.isArray(object?.requests)
        ? object.requests.map((e: any) => VoIPSignalRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: VoIPSignalResponse): unknown {
    const obj: any = {};
    if (message.room !== undefined) {
      obj.room = VoIPRoomDescription.toJSON(message.room);
    }
    if (message.requests?.length) {
      obj.requests = message.requests.map((e) => VoIPSignalRequest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VoIPSignalResponse>, I>>(base?: I): VoIPSignalResponse {
    return VoIPSignalResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VoIPSignalResponse>, I>>(object: I): VoIPSignalResponse {
    const message = createBaseVoIPSignalResponse();
    message.room = (object.room !== undefined && object.room !== null)
      ? VoIPRoomDescription.fromPartial(object.room)
      : undefined;
    message.requests = object.requests?.map((e) => VoIPSignalRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVoIPRoomDescription(): VoIPRoomDescription {
  return {
    roomId: "",
    callerDid: "",
    calleeDid: "",
    callState: 0,
    callTitle: undefined,
    createdAt: "",
    callerMediaConnected: "",
    calleeMediaConnected: "",
    closedAt: "",
  };
}

export const VoIPRoomDescription = {
  encode(message: VoIPRoomDescription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roomId !== "") {
      writer.uint32(10).string(message.roomId);
    }
    if (message.callerDid !== "") {
      writer.uint32(18).string(message.callerDid);
    }
    if (message.calleeDid !== "") {
      writer.uint32(26).string(message.calleeDid);
    }
    if (message.callState !== 0) {
      writer.uint32(32).int32(message.callState);
    }
    if (message.callTitle !== undefined) {
      writer.uint32(42).string(message.callTitle);
    }
    if (message.createdAt !== "") {
      writer.uint32(50).string(message.createdAt);
    }
    if (message.callerMediaConnected !== "") {
      writer.uint32(58).string(message.callerMediaConnected);
    }
    if (message.calleeMediaConnected !== "") {
      writer.uint32(66).string(message.calleeMediaConnected);
    }
    if (message.closedAt !== "") {
      writer.uint32(74).string(message.closedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VoIPRoomDescription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVoIPRoomDescription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.callerDid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.calleeDid = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.callState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.callTitle = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.callerMediaConnected = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.calleeMediaConnected = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.closedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VoIPRoomDescription {
    return {
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      callerDid: isSet(object.callerDid) ? globalThis.String(object.callerDid) : "",
      calleeDid: isSet(object.calleeDid) ? globalThis.String(object.calleeDid) : "",
      callState: isSet(object.callState) ? voIPCallStateFromJSON(object.callState) : 0,
      callTitle: isSet(object.callTitle) ? globalThis.String(object.callTitle) : undefined,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      callerMediaConnected: isSet(object.callerMediaConnected) ? globalThis.String(object.callerMediaConnected) : "",
      calleeMediaConnected: isSet(object.calleeMediaConnected) ? globalThis.String(object.calleeMediaConnected) : "",
      closedAt: isSet(object.closedAt) ? globalThis.String(object.closedAt) : "",
    };
  },

  toJSON(message: VoIPRoomDescription): unknown {
    const obj: any = {};
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.callerDid !== "") {
      obj.callerDid = message.callerDid;
    }
    if (message.calleeDid !== "") {
      obj.calleeDid = message.calleeDid;
    }
    if (message.callState !== 0) {
      obj.callState = voIPCallStateToJSON(message.callState);
    }
    if (message.callTitle !== undefined) {
      obj.callTitle = message.callTitle;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.callerMediaConnected !== "") {
      obj.callerMediaConnected = message.callerMediaConnected;
    }
    if (message.calleeMediaConnected !== "") {
      obj.calleeMediaConnected = message.calleeMediaConnected;
    }
    if (message.closedAt !== "") {
      obj.closedAt = message.closedAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VoIPRoomDescription>, I>>(base?: I): VoIPRoomDescription {
    return VoIPRoomDescription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VoIPRoomDescription>, I>>(object: I): VoIPRoomDescription {
    const message = createBaseVoIPRoomDescription();
    message.roomId = object.roomId ?? "";
    message.callerDid = object.callerDid ?? "";
    message.calleeDid = object.calleeDid ?? "";
    message.callState = object.callState ?? 0;
    message.callTitle = object.callTitle ?? undefined;
    message.createdAt = object.createdAt ?? "";
    message.callerMediaConnected = object.callerMediaConnected ?? "";
    message.calleeMediaConnected = object.calleeMediaConnected ?? "";
    message.closedAt = object.closedAt ?? "";
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
