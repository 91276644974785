import { ComponentPropsWithRef, ElementType, ReactNode } from "react";
import cx from "classnames";
import styles from "./button.module.scss";

type TProps<T extends ElementType> = {
  as?: T;
  children: ReactNode;
  size?: "long" | "normal" | "medium" | "short" | "small";
  mode?: "default" | "outlined" | "lineGray";
} & ComponentPropsWithRef<T>;

export default function Button<T extends ElementType = "button">({
  as,
  children,
  size = "normal",
  mode = "default",
  className,
  ...props
}: TProps<T>) {
  const Element = as || "button";

  return (
    <Element className={cx(styles.wrapper, styles[size], styles[mode], className)} {...props}>
      {children}
    </Element>
  );
}
