import { MouseEvent, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import NotiModal from "pages/contentsManage/vote/@shared/modal/NotiModal";

import { XIcon, RadioDisIcon, CheckCirDisIcon, RadioEmtIcon, RadioSkyIcon, CheckCirSkyIcon } from "assets/svgs/vote";
import styles from "styles/pages/contentsManage/vote/write/multiQuestion.module.scss";

type TProps = {
  queType: "단일 선택형" | "복수 선택형" | "주관식 단답형" | "정답 단일 선택형" | "정답 복수 선택형";
  name: string;
  queNum: string;
};

export default function QuestionItems({ queType, name, queNum }: TProps) {
  const {
    control,
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const [warnModalMsg, setWarnModalMsg] = useState("");
  const handleItemRemove = (index: number) => {
    if (index <= 1) return; // 선택지 최소 2개 이상
    const correctAns = getValues(`${queNum}.correct_ans`).split(",");
    const isCorrectAns = correctAns.includes(String(index + 1));
    // 정답인 선택지라면 정답목록에서 해당 선택지 삭제
    isCorrectAns && correctAns.splice(correctAns.indexOf(String(index + 1)), 1);
    const targetIndex = isCorrectAns ? index + 1 : index;
    const calculatedCorrectAns = correctAns.map((v: string) => (Number(v) > targetIndex ? String(Number(v) - 1) : v));
    remove(index);
    setValue(`${queNum}.correct_ans`, calculatedCorrectAns.join(","));
  };

  const handleAnswerList = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget.id;
    if (queType === "정답 단일 선택형") {
      setValue(`${queNum}.correct_ans`, target);
    } else {
      const arr = getValues(`${queNum}.correct_ans`) ? getValues(`${queNum}.correct_ans`).split(",") : [];
      arr.includes(target) ? arr.splice(arr.indexOf(target), 1) : arr.push(target);
      const answers = arr.sort((a: string, b: string) => Number(a) - Number(b)).join(",");
      setValue(`${queNum}.correct_ans`, answers);
    }
  };

  const handleAddField = () => {
    return fields.length < 30 ? append({ value: "" }) : setWarnModalMsg("선택지는 30개를 초과할 수 없습니다");
  };

  useEffect(() => {
    // 정답 데이터를 불러온다.
    let question = getValues();
    const breadcrumb = queNum.split(".");
    for (const a of breadcrumb) {
      question = question[a];
    }
    if (!question.correct_ans) return;
    const correctAns =
      question.queType === "정답 단일 선택형" ? question.correct_ans.split(",")[0] : question.correct_ans;
    setValue(`${queNum}.correct_ans`, correctAns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queType]);

  return (
    <>
      <ul className={styles.answer}>
        {fields.map((item, index) => {
          const targetId = String(index + 1);
          return (
            <li key={item.id}>
              {queType === "단일 선택형" && <RadioDisIcon />}
              {queType === "복수 선택형" && <CheckCirDisIcon />}
              {queType === "정답 단일 선택형" && (
                <button onClick={handleAnswerList} type="button" name={item.id} id={targetId} tabIndex={-1}>
                  {watch(`${queNum}.correct_ans`).split(",").includes(targetId) ? <RadioSkyIcon /> : <RadioEmtIcon />}
                </button>
              )}
              {queType === "정답 복수 선택형" && (
                <button onClick={handleAnswerList} type="button" name={item.id} id={targetId} tabIndex={-1}>
                  {watch(`${queNum}.correct_ans`).split(",").includes(targetId) ? (
                    <CheckCirSkyIcon />
                  ) : (
                    <CheckCirDisIcon />
                  )}
                </button>
              )}
              <input
                type="text"
                placeholder={`선택지 ${index + 1} 입력해 주세요. (최대 100자)`}
                className={styles.que_input}
                maxLength={100}
                autoComplete="off"
                aria-invalid={getError(errors, `${name}.${index}.value`) ? "true" : "false"}
                {...register(`${name}.${index}.value` as const, {
                  validate: { required: (v) => !!v.trim().length },
                })}
              />

              <button type="button" name="del" onClick={() => handleItemRemove(index)} tabIndex={-1}>
                <XIcon />
              </button>
            </li>
          );
        })}

        <li>
          {queType === "단일 선택형" && <RadioDisIcon />}
          {queType === "복수 선택형" && <CheckCirDisIcon />}
          {/정답 (단일|복수) 선택형/.test(queType) && (
            <button type="button">
              <RadioEmtIcon />
            </button>
          )}

          <button type="button" className={styles.que_input} onClick={handleAddField}>
            선택지 추가
          </button>
        </li>
      </ul>
      {warnModalMsg && <NotiModal message={warnModalMsg} onClose={() => setWarnModalMsg("")} />}
    </>
  );
}

function getError(errors: any, path: string) {
  return path.split(".").reduce((acc: any, key: string) => {
    if (acc && typeof acc === "object") {
      return acc[key];
    }
    return undefined;
  }, errors);
}
