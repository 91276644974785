import queryKey from "constants/queryKey";
import { useQuery } from "react-query";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "utils/access";

export default function PublicRoute() {
  const { data } = useQuery(queryKey.PUBLIC_ROUTE_TOKEN, getToken);

  return (
    <>
      {data === undefined && null}
      {data === null && <Outlet />}
      {data && <Navigate to="/" replace />}
    </>
  );
}
