import { MouseEventHandler, useState } from "react";
import cx from "classnames";
import styles from "styles/common/navigation/Dropdown.module.scss";
import { Control, FieldPath, FieldValues, useController } from "react-hook-form";
import { Modal } from "comp-lib";
import classNames from "classnames";

interface Props {
  dataList: string[];
  value?: string;
  handleChange?: (value: string) => void;
  width?: string;
  height?: string;
  fontSize?: string;
  maxHeight?: string;
  disabled?: boolean;
  borderColor?: "default" | "deep" | "none" | "soft";
  mode?: "primary" | "secondary" | "third";
  optionTxtPosition?: "start" | "end" | "center";
  className?: string;
}

/**
 * useState로 value를 받아오는 경우 사용하는 Dropdown 컴포넌트
 */
export default function Dropdown<T extends FieldValues>({
  dataList,
  value,
  handleChange,
  width,
  height,
  fontSize = "14px",
  borderColor = "default",
  disabled = false,
  maxHeight,
  mode = "primary",
  optionTxtPosition = "start",
  className,
}: Props) {
  const [isActive, setIsActive] = useState(false);

  const handleClose = () => setIsActive(false);

  const handleOptionChange: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (handleChange) {
      handleChange(String(e.currentTarget.textContent));
      setIsActive(false);
    }
  };

  const handleOpenOrClose = () => setIsActive((prev) => !prev);

  return (
    <div className={cx(styles.wrapper, styles[borderColor], styles[mode], className)} style={{ width, fontSize }}>
      <button
        type="button"
        name="selected"
        onClick={handleOpenOrClose}
        onBlur={handleClose}
        style={{ height }}
        disabled={disabled}
        className={cx({ [styles.placeholder]: !value })}
      >
        {value}
      </button>

      {isActive && dataList.length !== 0 && (
        <ul className={cx(styles.option, styles[mode])} style={{ maxHeight }}>
          {dataList.map((data) => (
            <li key={data}>
              <button type="button" onMouseDown={handleOptionChange} style={{ textAlign: optionTxtPosition }}>
                {data}
              </button>
            </li>
          ))}
        </ul>
      )}

      {isActive && dataList.length === 0 && (
        <Modal description="목록이 존재하지 않습니다." btnType="confirm" handleClose={handleClose} />
      )}
    </div>
  );
}
