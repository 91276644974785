import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import { Button, ErrorModal, Modal } from "comp-lib";
import { getCompanyManagerRestore } from "api/admin/company";
import useModal from "hooks/useModal";
import queryKey from "constants/queryKey";

type TProps = {
  staffId?: number;
  staffName?: string;
  status?: number;
  enabled: boolean;
  companyName: string;
  companyId: number;
  staffEnabled?: boolean;
};

export default function ManagerBtn({
  staffId,
  staffName,
  status,
  enabled,
  companyName,
  companyId,
  staffEnabled,
}: TProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [isCheckModal, setIsCheckModal] = useModal();

  const navigateManagerCreate = () => navigate("/company/manager/create", { state: { companyName, companyId } });

  // 기업의 대표담당자 복구 api 연동
  const { mutate: restoreMutate, error: restoreError } = useMutation(getCompanyManagerRestore, {
    onSuccess: () => {
      setIsCheckModal();
      queryClient.invalidateQueries([queryKey.GET_ADMIN_COMPANY_LIST]);
      queryClient.invalidateQueries([queryKey.GET_ADMIN_COMPANY_INFO]);
    },
    onError: setIsErrorShowing,
  });

  // 기업의 대표담당자를 한번도 등록 하지 않은 경우
  if (!staffId && !staffName && !status) {
    return (
      <Button size="short" mode="outlined" onClick={navigateManagerCreate} disabled={!enabled}>
        담당자 등록
      </Button>
    );
  }

  // 대표 담당자가 휴면 계정 && 기업 비활성 상태인 경우
  if (!staffEnabled && !enabled) return staffName;

  // 기업 활성 상태
  if (enabled) {
    // 대표담당자가 활성 상태인 경우
    if (staffEnabled) return <Link to={`/company/manager/info/${staffId}`}>{staffName}</Link>;

    // 대표담당자가 휴면 계정인 경우
    if (!staffEnabled) {
      return (
        <>
          <Button type="button" key={staffId} size="short" mode="outlined" onClick={setIsCheckModal}>
            담당자 복구
          </Button>

          {isCheckModal && (
            <Modal
              title="대표 담당자 계정을 복구하시겠습니까?"
              description={`기존 대표 담당자 계정을 복구하거나 새로운 대표 담당자를 등록할 수 있습니다. (*신규 등록 시 비밀번호 설정 필요).`}
              btnType="submit"
              cancelBtnName="신규 등록"
              submitBtnName="확인"
              handleClose={() => navigate("/company/manager/create", { state: { companyName, companyId, staffId } })}
              handleSubmit={() => restoreMutate(staffId!)}
            />
          )}

          {isErrorShowing && <ErrorModal error={restoreError} onConfirmHandler={setIsErrorShowing} />}
        </>
      );
    }
  }
}
