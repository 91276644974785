export const statusMap: Record<number, string[]> = {
  0: ["성공", "success"],
  1: ["실패", "fail"],
};

export default function useTableNavData(data: { did: string; status: number }[]) {
  return [
    {
      label: "전체",
      status: "all",
      count: data.length,
      numeral: "",
    },
    {
      label: "성공",
      status: statusMap[0][1],
      count: data.filter((el) => el.status === 0).length,
    },
    {
      label: "실패",
      status: statusMap[1][1],
      count: data.filter((el) => el.status === 1).length,
    },
  ];
}
