import { FormEventHandler, useState } from "react";

import { Button } from "comp-lib";

import styles from "styles/pages/mydInquiry/searchBox.module.scss";

import { RerollIcon } from "assets/svgs/icon";
import { useSearchParams } from "react-router-dom";

export default function SearchBox() {
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get("keyword") || undefined;

  const [keywordValue, setKeywordValue] = useState(keyword);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setKeywordValue(e.target.value);
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    if (keywordValue) {
      searchParams.set("keyword", keywordValue);
      searchParams.set("pageNum", "1");
      setSearchParams(searchParams);
    } else {
      handleReset();
    }
  };

  const handleReset = () => {
    setKeywordValue("");

    searchParams.delete("keyword");
    searchParams.set("pageNum", "1");
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit}>
        <input placeholder="DID, 단어 검색" value={keywordValue || ""} onChange={handleChange} />

        <div className={styles.btnBox}>
          <Button type="submit" size="short">
            검색
          </Button>

          {keyword && (
            <button type="button" className={styles.resetBtn} onClick={handleReset}>
              <RerollIcon />
              초기화하기
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
