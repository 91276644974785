import { useMutation, useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Content,
  Title,
  Panel,
  Table,
  Pagination,
  Button,
  useCustomParams,
  Modal,
  ErrorModal,
  LoadingModal,
} from "comp-lib";
import useTableData from "./useTableData";

import { deleteGoods, getGoodsList } from "api/goods";
import styles from "styles/pages/goods/goodsList.module.scss";
import { useState } from "react";

const DEFAULT_GOODS_LIST = {
  goodsList: [],
  pagination: {
    totalPage: 1,
  },
};

export default function GoodsList() {
  const [{ pageNum }] = useCustomParams({
    pageNum: "1",
  });

  const {
    data = DEFAULT_GOODS_LIST,
    isError,
    refetch,
    isLoading,
  } = useQuery(["getGoodsList", pageNum], () => getGoodsList(Number(pageNum)), {
    keepPreviousData: true,
    onSuccess: () => {
      resetSelectedItems();
    },
  });
  const location = useLocation();
  const delGoodsMut = useMutation(deleteGoods);

  const { columns, rows, selectedItems, resetSelectedItems } = useTableData(data.goodsList);

  const [isModal, setIsModal] = useState(false);
  const handleDelClick = () => setIsModal(true);
  const handleModalClose = () => setIsModal(false);
  const handleModalConfirm = async () => {
    const codeArr = selectedItems.map((item) => item[1]);
    await delGoodsMut.mutateAsync(codeArr);
    refetch();
    setIsModal(false);
  };

  const navigate = useNavigate();
  const handleError = () => {
    navigate("/");
  };

  const handleDelError = () => {
    delGoodsMut.reset();
    setIsModal(false);
  };

  if (isLoading) return <LoadingModal />;

  return (
    <Content>
      <Title text="상품 관리" />

      <Panel>
        <Table columns={columns} rows={rows} activeRows={selectedItems.map((item) => item[0])} />

        <div className={styles.footer}>
          <Button
            type="button"
            size="medium"
            mode="outlined"
            disabled={selectedItems.length < 1}
            onClick={handleDelClick}
          >
            삭제
          </Button>
          <Button as={Link} to={`register${location.search}`} size="medium">
            등록
          </Button>
        </div>

        <Pagination totalPages={data.pagination.totalPage} />
      </Panel>

      {isModal && (
        <Modal
          btnType="submit"
          description={`상품 ${selectedItems.length}개를 삭제하시겠습니까?`}
          handleClose={handleModalClose}
          handleSubmit={handleModalConfirm}
        />
      )}

      {isError && (
        <ErrorModal
          message={`상품 관리 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`}
          onConfirmHandler={handleError}
        />
      )}
      {delGoodsMut.isError && <ErrorModal message="서버에 문제가 발생했습니다." onConfirmHandler={handleDelError} />}
    </Content>
  );
}
