import { ProgressBar } from "common/other";
import styles from "styles/pages/contentsManage/challenge/detail/detailProgressStatus.module.scss";
import { Panel } from "comp-lib";
import { getCommasInNum } from "utils";
import { TCreateChallengeResponse, TCreateChallengeStatistics } from "types/contentsManage/challenge";

export default function DetailProgressStatus({
  data,
  statData,
}: {
  data: TCreateChallengeResponse;
  statData: TCreateChallengeStatistics;
}) {
  const allApplyPoint = data.applyPointPerPerson * data.currentParticipantsCount;
  const successPoint = data.rewardPointPerPerson * data.currentParticipantsCount;
  const successPercent = Math.round(((statData.count || 0) / (data.currentParticipantsCount || 1)) * 100);
  const failPoint = Math.floor(allApplyPoint / (statData.count || 1));
  const expectPoint = `${successPercent < 100 ? getCommasInNum(allApplyPoint) : getCommasInNum(successPoint)} P`;

  return (
    <Panel className={styles.wrapper}>
      <h2>진행 현황</h2>
      <div className={styles.progressBar}>
        <label>전체성공률</label>
        <ProgressBar value={successPercent} color="sky" />
      </div>

      <div className={styles.personInfo}>
        <label>성공인원</label>
        <p>{`${getCommasInNum(statData.count || 0)} / ${getCommasInNum(data.currentParticipantsCount || 0)} 명`}</p>
      </div>

      <div className={styles.pointInfo}>
        <div>
          <label className={styles.gridItem}>실패시 1인당 지급포인트</label>
          <p className={styles.gridItem}>{successPercent < 100 ? `${getCommasInNum(failPoint)} P` : "-"}</p>
        </div>
        <div>
          <label className={styles.gridItem}>총 예상 지급포인트</label>
          <p className={styles.gridItem}>{statData.count ? expectPoint : "-"}</p>
        </div>
      </div>
    </Panel>
  );
}
