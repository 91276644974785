import styles from "styles/pages/advertisement/detail/adDialog.module.scss";
import {
  acceptAdminAdProposal,
  getAdminAdProposal,
  getAdminAdProposalList,
  rejectAdminAdProposal,
} from "api/admin/advertisement";
import { LoadingModal } from "common/modal";
import { Portal } from "common/other";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

type Props = {
  type: "reject" | "confirm" | "none";
  handleClose: () => void;
  advertiseData: {
    title: string;
    notAfter: string;
  };
};

export default function AdDialog({ type, handleClose, advertiseData }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState<
    "confirm" | "confirmComplete" | "rejectForm" | "reject" | "rejectComplete" | "expire" | "error" | "none"
  >(type);
  const navigate = useNavigate();
  const rejectFormRef = useRef<HTMLTextAreaElement>(null);
  const { data: listData, isLoading: listIsLoading } = useQuery("getAdminAdProposalList", getAdminAdProposalList);

  const id = String(window.location.pathname.split("/").pop());
  const { mutate: acceptMutate, isLoading: acceptIsLoading } = useMutation(() => acceptAdminAdProposal(id), {
    onSuccess: () => setProgress("confirmComplete"),
    onError: (err: Error) => {
      err?.message === "유효하지 않은 거래 프로포절 입니다." ? setProgress("expire") : setProgress("error");
    },
  });
  const { mutate: rejectMutate, isLoading: rejectIsLoading } = useMutation(
    () => rejectAdminAdProposal({ id, reason: rejectFormRef.current?.value || "" }),
    {
      onSuccess: () => setProgress("rejectComplete"),
      onError: () => setProgress("error"),
    },
  );

  const onHandleClose = () => {
    setProgress("none");
    handleClose();
  };

  const onClickConfirm = async () => {
    setIsLoading(true);
    try {
      const res = await getAdminAdProposal(id);
      if (
        dayjs().isAfter(advertiseData.notAfter) ||
        res.advertisements.status === 2 ||
        (
          listData?.index.filter(
            (data) => data.proposalId === res.proposals.proposalId && data.approved && data.status === 0, // 승인된 광고
          ) || []
        ).length
      ) {
        setProgress("expire");
      } else acceptMutate();
    } finally {
      setProgress("expire");
      setIsLoading(false);
    }
  };
  const onClickConfirmComplete = () => {
    onHandleClose();
    navigate("/event/advertise");
  };
  const onClickReject = () => rejectMutate();

  const onClickRejectComplete = () => {
    onHandleClose();
    navigate("/event/advertise");
  };

  const dialogEl = () => {
    if (progress === "error") {
      return (
        <div className={styles.content}>
          <p className={styles.bold}>{advertiseData.title}</p>
          <p>{`거래를 ${type === "confirm" ? "승인" : "반려"}하는 데 실패하였습니다.\n다시 시도해 주세요.`}</p>
          <div className={styles.buttonBox}>
            <button type="button" onClick={onHandleClose}>
              확인
            </button>
          </div>
        </div>
      );
    }
    if (
      type === "confirm" &&
      progress !== "confirmComplete" &&
      progress !== "expire" &&
      progress !== "rejectForm" &&
      progress !== "rejectComplete"
    )
      return (
        <div className={styles.content}>
          <p>승인하시겠습니까?</p>
          <p className={styles.bold}>{advertiseData.title}</p>
          <p>광고요청을 승인합니다.</p>

          <div className={styles.buttonBox}>
            <button type="button" name="cancel" onClick={handleClose}>
              취소
            </button>
            <button type="button" onClick={onClickConfirm}>
              확인
            </button>
          </div>
        </div>
      );
    if (progress === "confirmComplete")
      return (
        <div className={styles.content}>
          <p>승인되었습니다.</p>
          <p>마이디 앱에 광고 배너가 노출됩니다.</p>

          <div className={styles.buttonBox}>
            <button type="button" onClick={onClickConfirmComplete}>
              확인
            </button>
          </div>
        </div>
      );
    if ((type === "reject" && progress !== "rejectComplete") || progress === "rejectForm")
      return (
        <div className={styles.content}>
          <p>광고 요청을 반려하시겠습니까?</p>
          <textarea className={styles.rejectTextarea} ref={rejectFormRef} placeholder="반려 사유를 입력해 주세요" />
          <div className={styles.buttonBox}>
            <button type="button" name="cancel" onClick={onHandleClose}>
              취소
            </button>
            <button type="button" onClick={onClickReject}>
              확인
            </button>
          </div>
        </div>
      );
    if (progress === "rejectComplete")
      return (
        <div className={styles.content}>
          <p className={styles.bold}>{advertiseData.title}</p>
          <p>광고요청을 반려했습니다.</p>

          <div className={styles.buttonBox}>
            <button type="button" onClick={onClickRejectComplete}>
              확인
            </button>
          </div>
        </div>
      );
    if (progress === "expire")
      return (
        <div className={styles.content}>
          <p>{"거래 프로포절이 유효하지 않아 광고를 승인할 수 없습니다.\n광고 요청을 반려합니다."}</p>
          <div className={styles.buttonBox}>
            <button type="button" name="cancel" onClick={onHandleClose}>
              취소
            </button>
            <button type="button" onClick={() => setProgress("rejectForm")}>
              확인
            </button>
          </div>
        </div>
      );
    return <div />;
  };
  if (type === "none") return <div />;

  return (
    <Portal>
      <div className={styles.dialog}>
        {isLoading || acceptIsLoading || rejectIsLoading || listIsLoading ? <LoadingModal /> : dialogEl()}
      </div>
    </Portal>
  );
}
