import { Fragment, useEffect } from "react";
import { Button } from "comp-lib";
import { Portal } from "common/other";
import styles from "./modal.module.scss";

type TProps = {
  message: string;
  description?: string;
  onClose: () => void;
  onConfirm: () => void;
};

export default function Modal({ message, description, onClose, onConfirm }: TProps) {
  useEffect(() => {
    document.body.style.cssText = "overflow: hidden";
    return () => {
      document.body.style.cssText = "overflow: auto";
    };
  }, []);

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Portal>
      <div className={styles.overlay}>
        <article className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.message}>{message}</div>

            {description && (
              <div className={styles.description}>
                <p>
                  {description.split("\n").map((line, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={`msg-${index}`}>
                      {line}
                      {index !== description.split("\n").length - 1 && <br />}
                    </Fragment>
                  ))}
                </p>
              </div>
            )}
          </div>

          <div className={styles.btn_box}>
            <Button type="button" size="short" mode="outlined" onClick={handleCancel}>
              취소
            </Button>
            <Button type="button" size="short" onClick={handleConfirm}>
              확인
            </Button>
          </div>
        </article>
      </div>
    </Portal>
  );
}
