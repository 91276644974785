import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Controller, FieldErrors, FieldValues, useForm } from "react-hook-form";

import { LabelInput } from "./createUI";
import { RadioBox } from "common/input";
import useModal from "hooks/useModal";
import { postAdminNewCompany } from "api/admin/company";
import { Title, Content, Panel, Footer, Button, Modal, ErrorModal } from "comp-lib";

import styles from "styles/pages/company/create/companyCreate.module.scss";
import { onKeyDownPreventOnClick } from "utils";

export default function CompanyCreate() {
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");
  const [isConfirmModal, setIsConfirmModal] = useModal();
  const [isCancelModal, setIsCancelModal] = useModal();
  const [isCheckModal, setIsCheckModal] = useModal();
  const [isError, setIsErrorShowing] = useModal();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FieldValues>({ mode: "onChange" });

  // 기업 신규 등록 api 연동
  const { mutate: postNewMutation, error } = useMutation(postAdminNewCompany);

  // 기업 정보 등록
  const handleCreateCompany = (input: FieldValues) => {
    const { name, businessType, piiUrl } = input;
    const body = { name, businessType, piiUrl };

    postNewMutation(body, { onSuccess: setIsConfirmModal, onError: setIsErrorShowing });
  };

  // 필수값 & URL 유효성 검사 에러 모달
  const handleErrorModal = (err: FieldErrors) => {
    for (const fieldName in err) {
      if (err[fieldName]) {
        setErrorMsg(String(err[fieldName]?.message));
      }
    }
    setIsCheckModal();
  };

  return (
    <Content isWithFooter>
      <Title text="기업 등록" />

      <Panel>
        <form className={styles.panel} onSubmit={handleSubmit(handleCreateCompany, handleErrorModal)}>
          <div className={styles.subTitle}>기업 정보</div>

          <LabelInput
            label="기업명"
            id="name"
            placeholder="기업명을 입력하세요"
            type="text"
            register={register}
            isNull={!!errors.name}
          />

          <div className={styles.typeRadioBox}>
            <div className={styles.typeLabel}>
              소재지<span>*</span>
            </div>
            <div className={styles.typeController}>
              <Controller
                defaultValue="0"
                name="businessType"
                control={control}
                rules={{ required: "소재지를 선택해주세요" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <RadioBox name="businessType" dataList={businessTypeList} value={value} handleCheck={onChange} />
                  );
                }}
              />
            </div>
          </div>

          <LabelInput
            label="데이터 수집 서버 URL"
            id="piiUrl"
            placeholder="데이터를 수집할 서버 주소를 입력하세요"
            type="text"
            register={register}
            isNull={!!errors.piiUrl}
            validate="url"
          />
        </form>
      </Panel>

      <Footer>
        <Button size="long" mode="lineGray" onClick={setIsCancelModal}>
          취소
        </Button>
        <Button
          size="long"
          type="submit"
          onClick={handleSubmit(handleCreateCompany, handleErrorModal)}
          {...onKeyDownPreventOnClick}
        >
          등록
        </Button>
      </Footer>

      {isCancelModal && (
        <Modal
          title="기업 등록을 취소하시겠습니까?"
          description="지금까지 작성한 내용은 저장되지 않습니다."
          btnType="submit"
          cancelBtnName="나가기"
          submitBtnName="계속하기"
          handleClose={() => navigate(-1)}
          handleSubmit={setIsCancelModal}
        />
      )}

      {isConfirmModal && (
        <Modal
          title="기업 등록이 완료되었습니다"
          description="목록에서 확인 후 해당 기업의 대표 담당자를 등록해 주세요."
          btnType="confirm"
          submitBtnName="확인"
          handleClose={() => navigate("/company")}
        />
      )}

      {isCheckModal && <Modal description={errorMsg} btnType="confirm" handleClose={setIsCheckModal} />}

      {isError && <ErrorModal error={error} onConfirmHandler={setIsErrorShowing} />}
    </Content>
  );
}

export const businessTypeList = [
  {
    label: "국내",
    value: "0",
  },
  {
    label: "해외",
    value: "1",
  },
];
