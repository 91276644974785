export const PUSH_PURPOSE = [
  { value: "MARKETING_ADVERTISE", label: "마케팅·광고" },
  { value: "NOTICE", label: "일반 공지" },
];

export const MSG_TYPE = [
  { value: "TOPIC", label: "Topic 전체 발송" },
  { value: "TOKEN_ALL", label: "Token 전체 발송" },
  { value: "TOKEN", label: "Token 대상 지정 발송" },
];

export const AGREE_TYPE = [
  { value: "MARKETING_ALL", label: "주간 수신 동의자" },
  { value: "MARKETING_NIGHTTIME", label: "주간·야간 수신 동의자 (21:00 ~ 08:00 발송)" },
];

export const OS_TARGET = [
  { value: "ANDROID", label: "Android" },
  { value: "IOS", label: "iOS" },
];

export const PROPOSAL_TARGET = [{ value: "PROPOSAL_ID", label: "직접 입력" }];

export const DID_TARGET = [
  { value: "DID", label: "직접 입력" },
  { value: "DID_FILE", label: "파일 첨부" },
  // { value: "POINT_EXPIRATION", text: "pointExpiration", label: "포인트 소멸 그룹" },
  // { value: "NEW_USER", text: "newUser", label: "신규 유저 그룹" },
];

// export const POINT_TARGET = [
//   { value: "30days", text: "30days", label: "소멸 30일 전 사용자" },
//   { value: "10days", text: "10days", label: "소멸 10일 전 사용자" },
//   { value: "1day", text: "1day", label: "소멸 1일 전 사용자" },
// ];

export const TARGET_TYPE = [
  { value: "OS", label: "OS 지정" },
  { value: "PROPOSAL", label: "Proposal 지정" },
  { value: "DID", label: "DID 지정" },
];

export const PUSH_DATE = [
  { value: "NOW", label: "즉시 발송" },
  { value: "RESERVATION", label: "예약 발송" },
];

export const MOVE_TO = [
  { value: "0", label: "거래 메인" },
  { value: "1", label: "연결 메인" },
  { value: "2", label: "조회 메인" },
  { value: "3", label: "포인트몰 메인" },
  { value: "4", label: "MY" },
  { value: "5", label: "출석체크" },
  { value: "6", label: "마이디 투표", url: "" },
  // { value: "faq", label: "문의하기" },
  // { value: "point", label: "포인트 상세내역" },
  // { value: "notice", label: "공지사항", url: "" },
  // { value: "moveToCustom", label: "직접 입력", url: "" },
];

export const MOCK_DEFAULT_FORM = {
  messagePurpose: "", // 발송 목적
  agreeType: "", // 주간 야간 수신 동의
  messageType: "", // 발송 방식
  targetType: "", // 발송 대상
  messagePlatformStatus: "", // os
  proposalId: "", // proposal
  didListFile: "", // did 파일
  didList: "", // did 직접 입력
  userGroup: "", // 사용자 그룹
  reserveTime: "", // 발송 시각
  title: "", // 제목
  body: "", // 내용
  imagePath: "", // 이미지
  moveTo: "", // 이동 위치
  jsonOrigin: "", // json 만들기

  targetInput: "", // 프론트 값 저장용
  targetValue: "", // 프론트 값 저장용
  date: "", // 프론트 값 저장용
  hour: "00", // 프론트 값 저장용
  minute: "00", // 프론트 값 저장용
  moveToUrl: "", // 프론트 값 저장용
};
