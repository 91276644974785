import { TCompanyListItem, TCompanyDetailInfo, TAdminNewCompany, TCompanyList, TManagerDetail } from "types/company";
import { Axios } from "../base";
import { AxiosError } from "axios";
import ErrorCode from "constants/errorcode";

// ADMIN 기업 목록 조회
export const getAdminCompanyList = async (): Promise<TCompanyListItem[]> => {
  try {
    const { data } = await Axios.get(`/api/admin/company/index`);
    return data.data?.companyList;
  } catch {
    throw Error("기업 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.");
  }
};

// ADMIN 기업 신규 등록
export const postAdminNewCompany = async (companyCreate: TAdminNewCompany) => {
  const { data } = await Axios.post(`/api/admin/company/v2/new`, { companyCreate });
  return data;
};

// ADMIN 기업 정보 수정
export const modifyAdminCompany = async (companyModify: TAdminNewCompany) => {
  const { data } = await Axios.post(`/api/admin/company/v2/modify`, { companyModify });
  return data;
};

// ADMIN 기업 상세 정보 조회
export const getAdminCompanyDetail = async (id: number): Promise<TCompanyDetailInfo> => {
  const { data } = await Axios.get(`/api/admin/company/v2/info`, { params: { id } });
  return data.data;
};

// ADMIN 기업 비활성화
export const changeAdminDisabledCompany = async (id: number) => {
  const { data } = await Axios.get(`/api/admin/company/v2/disable`, { params: { id } });
  return data;
};

// ADMIN 기업 비활성화
export const changeAdminEnabledCompany = async (id: number) => {
  const { data } = await Axios.get(`/api/admin/company/v2/enable`, { params: { id } });
  return data;
};

// 대표 담당자 상세 정보 조회
export const getStaffDetail = async (id: string): Promise<TManagerDetail> => {
  const { data } = await Axios.get(`/api/manager/v2/info`, { params: { id } });
  return data.data.staff;
};

// 담당자 계정 등록
export const postUserData = async (staffCreate: any) => {
  try {
    const { data } = await Axios.post(`/api/manager/v2/new`, { staffCreate });
    return data.data.resultStaff;
  } catch (error) {
    if ((error as AxiosError).message === ErrorCode.ERR_DATA_DUPLICATED) {
      throw Error("이미 존재하는 이메일 계정입니다.\n다른 이메일 주소를 입력하거나 CS센터에 문의해 주세요.");
    }

    if ((error as AxiosError).message === "이미 대표 담당자가 존재합니다.") {
      throw Error("이미 대표 담당자가 존재합니다.");
    }

    throw error;
  }
};

// 기업 목록 조회
export const getCompanyList = async (): Promise<TCompanyList[]> => {
  const { data } = await Axios.get(`/api/admin/company/list`);
  return data.data.companyList;
};

// 기업 대표 담당자 계정 복구
export const getCompanyManagerRestore = async (staffId: number) => {
  const { data } = await Axios.get(`/api/admin/company/restore`, { params: { staffId } });
  return data;
};

// 가입 링크 이메일 재전송
export const getEmailResend = async (email: string) => {
  const { data } = await Axios.get(`/api/admin/company/resend`, { params: { email } });
  return data;
};

// 신규 대표담당자 등록시 기존 대표담당자 삭제(status 2로 변경)
export const getManagerRemove = async (staffId: number) => {
  const { data } = await Axios.get(`/api/admin/company/manager/remove`, { params: { staffId } });
  return data;
};
