import { AdminLogoIcon } from "assets/svgs/skeleton";
import {
  BuildingIcon,
  ChatIcon,
  CoinIcon,
  DashboardIcon,
  NoteIcon,
  ProfileIcon,
  SettingIcon,
  StoreIcon,
  TargetIcon,
  YoutubeIcon,
} from "assets/svgs/skeleton/menu";
import { NavLink } from "react-router-dom";
import styles from "styles/pages/renewalSideMenu.module.scss";
import NavAccordionMenu from "./NavAccordionMenu";

// TODO: 추후 작업 아이템에 따른 route path가 정해지면 해당 path로 변경하면 됩니다

/**
 * 2024 개편되는 메뉴로만 구성된 리뉴얼 사이드바
 */
export default function RenewalSideMenu() {
  const activeStyle = (isActive: boolean) => {
    return isActive ? `${styles.menuLink} ${styles.activeLink}` : styles.menuLink;
  };

  return (
    <aside className={styles.wrapper}>
      <div className={styles.logo}>
        <AdminLogoIcon width={232} />
      </div>

      <nav>
        <ul>
          <li>
            <NavLink to="/" end className={({ isActive }) => activeStyle(isActive)}>
              <DashboardIcon /> <span>대시보드</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/company" className={({ isActive }) => activeStyle(isActive)}>
              <BuildingIcon /> <span>기업 관리</span>
            </NavLink>
          </li>

          <li>
            <NavAccordionMenu
              title={{
                name: "포인트 관리",
                icon: <CoinIcon />,
              }}
              content={[
                {
                  path: "/point/approve",
                  name: "포인트 승인관리",
                },
                {
                  path: "/point/mint-burn",
                  name: "포인트 보상·소멸",
                },
                // {
                //   path: "/point/refund",
                //   name: "포인트 환불관리",
                // },
              ]}
            />
          </li>

          <li>
            <NavAccordionMenu
              title={{
                name: "서비스 관리",
                icon: <NoteIcon />,
              }}
              content={[
                // {
                //   path: "/service/dataConnection",
                //   name: "데이터 연결",
                // },
                {
                  path: "/service/transaction",
                  name: "데이터 거래",
                },
                {
                  path: "/service/quickMenu",
                  name: "퀵메뉴 관리",
                },
              ]}
            />
          </li>

          <li>
            <NavAccordionMenu
              title={{
                name: "컨텐츠 관리",
                icon: <TargetIcon />,
              }}
              content={[
                {
                  path: "/contents/vote",
                  name: "마이디 투표",
                },
                {
                  path: "/contents/promotion",
                  name: "타켓 프로모션",
                },
                {
                  path: "/contents/challenge",
                  name: "그룹루틴 챌린지",
                },
              ]}
            />
          </li>

          <li>
            <NavAccordionMenu
              title={{
                name: "메시지 관리",
                icon: <ChatIcon />,
              }}
              content={[
                {
                  path: "/message/appPush",
                  name: "앱푸시(App push)",
                },
                {
                  path: "/message/homePopup",
                  name: "홈팝업",
                },
                // {
                //   path: "/message/appNotice",
                //   name: "앱공지사항",
                // },
              ]}
            />
          </li>

          <li>
            <NavAccordionMenu
              title={{
                name: "광고이벤트",
                icon: <YoutubeIcon />,
              }}
              content={[
                {
                  path: "/event/banner",
                  name: "배너관리",
                },
                {
                  path: "/event/advertise",
                  name: "광고관리",
                },
              ]}
            />
          </li>

          <li>
            <NavAccordionMenu
              title={{
                name: "CS 관리",
                icon: <ProfileIcon />,
              }}
              content={[
                {
                  path: "/cs/mydInquiry",
                  name: "마이디 문의관리",
                },
                // {
                //   path: "/cs/companyInquiry",
                //   name: "기업문의관리",
                // },
                // {
                //   path: "/cs/companyNotice",
                //   name: "기업공지관리",
                // },
              ]}
            />
          </li>

          <li>
            <NavLink to="/goods" className={({ isActive }) => activeStyle(isActive)}>
              <StoreIcon /> <span>포인트몰 관리</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/users" className={({ isActive }) => activeStyle(isActive)}>
              <SettingIcon /> <span>운영자 관리</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
}
