import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { ErrorModal2, LoadingModal } from "common/modal";
import { TPasswordChange, TPasswordFind } from "types/login";
import { isPasswordValid } from "utils/validation";
import useModal from "hooks/useModal";
import { usePassword } from "./usePassword";

import styles from "styles/pages/login/password.module.scss";
import { MyDAdminLogoIcon } from "assets/svgs";
import { Modal } from "comp-lib";
import { LoginPasswordIcon } from "assets/svgs/icon";

export default function Password() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isPathContainFind = /find/gi.test(pathname);

  const [isShowing, setIsShowing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState<Error>();
  const { register, handleSubmit, getValues } = useForm<TPasswordFind & TPasswordChange>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      passwordCheck: "",
    },
  });
  const [isErrorShowing, setIsErrorShowing] = useModal();

  const { data, passwordFindMutate, passwordChangeMutate, userEmailMutate, isLoading, error } = usePassword();

  const enc = new URLSearchParams(window.location.search).get("enc") || "";

  const passwordMutate = () => {
    const password = getValues("password");
    const passwordCheck = getValues("passwordCheck");

    if (isPathContainFind) {
      passwordFindMutate(
        { name: getValues("name"), email: getValues("email") },
        {
          onSuccess: () => setIsSuccess(true),
          onError: () => setIsErrorShowing(),
        },
      );
    } else if (!isPathContainFind) {
      if (!isPasswordValid(password) || !isPasswordValid(passwordCheck)) {
        setErrorMsg(Error(`길이는 8자 이상이며,\n대/소문자, 숫자, 특수문자를 1개이상 포함시켜야 합니다.`));
        setIsErrorShowing();
      } else if (password !== passwordCheck) {
        setErrorMsg(Error(`비밀번호가 일치하지 않습니다.`));
        setIsErrorShowing();
      } else {
        passwordChangeMutate(
          { password, passwordCheck },
          {
            onSuccess: () => {
              setIsSuccess(true);
            },
            onError: () => {
              setIsErrorShowing();
            },
          },
        );
      }
    }
  };

  const handleSubmitForm = () => {
    if (!isPathContainFind) {
      passwordMutate();
      return;
    }

    setIsShowing(true);
  };

  const handleModalConfirm = () => {
    setIsErrorShowing();
    setErrorMsg(undefined);
    setIsShowing(false);
  };

  useEffect(() => {
    if (!isPathContainFind) {
      userEmailMutate(enc, {
        onError: () => setIsErrorShowing(),
      });
    }
  }, [isPathContainFind, enc, userEmailMutate]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className={styles.container}>
              <div className={styles.logo}>
                <MyDAdminLogoIcon />
                <span>Admin</span>
              </div>
              <div className={styles.info}>
                <LoginPasswordIcon />
                <h2>비밀번호 {isPathContainFind ? "찾기" : "변경하기"}</h2>
                <p>
                  {isPathContainFind
                    ? "비밀번호를 잊어버리셨나요?\n비밀번호 찾기를 위해 이름과 가입한 이메일을 입력해 주세요"
                    : "비밀번호 변경을 위해 새로운 비밀번호를 입력해 주세요"}
                </p>
              </div>

              {isPathContainFind ? (
                <>
                  <input type="text" placeholder="이름을 입력하세요" {...register("name", { required: true })} />
                  <input
                    type="email"
                    placeholder="가입한 이메일을 입력하세요"
                    {...register("email", { required: true })}
                  />
                </>
              ) : (
                <>
                  <input type="password" placeholder="새로운 비밀번호" {...register("password", { required: true })} />
                  <input
                    type="password"
                    placeholder="비밀번호 확인"
                    {...register("passwordCheck", { required: true })}
                  />
                </>
              )}

              <div className={styles.btnBox}>
                <div>
                  <button type="button" onClick={() => navigate("/Login")} className={styles.cancelBtn}>
                    취소
                  </button>
                </div>
                <div>
                  <button type="submit">비밀번호 {isPathContainFind ? "찾기" : "변경"}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {isShowing && !isErrorShowing && !isLoading && (
        <Modal
          btnType={"submit"}
          submitBtnName="발송"
          title={"비밀번호 변경 이메일 발송"}
          description={`입력하신 이메일 아이디로\n비밀번호 변경 링크가 포함된 이메일이 발송됩니다`}
          handleClose={() => setIsShowing(false)}
          handleSubmit={passwordMutate}
        />
      )}
      {isSuccess && (
        <Modal
          btnType={"confirm"}
          title={isPathContainFind ? "비밀번호 찾기" : "비밀번호 변경"}
          description={
            isPathContainFind
              ? "가입하신 이메일로 임시 비밀번호를 보냈습니다.\n임시비밀번호로 로그인 후 비밀번호 변경을 해주세요."
              : "변경된 비밀번호로 재로그인 부탁드립니다."
          }
          handleClose={() => navigate("/Login")}
        />
      )}
      {isErrorShowing && <ErrorModal2 error={errorMsg || error} onConfirmHandler={handleModalConfirm} />}
      {isLoading && <LoadingModal />}
    </>
  );
}
