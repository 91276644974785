import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import DateRange from "common/input/DatePicker";
import useDateRange from "hooks/useDateRange";
import { RerollIcon } from "assets/svgs/icon";

import styles from "styles/pages/contentsManage/vote/voteTableTop.module.scss";

import VoteTableNav from "./VoteTableNav";
import SearchKeywordInput from "../@shared/SearchKeywordInput";

type TDataInfo = {
  num: number;
  status: number | string;
};

type TProps = {
  data: { [key: string]: TDataInfo };
  isContents: boolean;
};

export default function VoteTableTop({ data, isContents }: TProps) {
  dayjs.extend(isBetween);
  const { endDate, onChangeDate, startDate, resetDate } = useDateRange();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get("searchKeyword");

  const handleResetDate = () => {
    resetDate();
    setSearchParams((params) => {
      params.delete("searchKeyword");
      return params;
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <VoteTableNav data={data} />
        <div className={styles.searchContainer}>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDate}
            setParams
            placeholder={isContents ? "진행 시작일 기준 날짜 검색" : "접수 일시 기준 날짜 검색"}
          />
          <SearchKeywordInput placeholder="검색어를 입력하세요" />
        </div>
      </div>

      <div className={styles.resetContainer}>
        {startDate || searchText ? (
          <button type="button" className={styles.searchClearBtn} onClick={handleResetDate}>
            <RerollIcon width="24px" height="24px" />
            초기화하기
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
