/* eslint-disable no-alert */
import { uploadNoticeFileApi, writeNoticeContent } from "api/admin/notice";
import { getErrorMeg } from "api/errorHandling";
import { Title } from "common/other";
import { ErrorModal, LoadingModal, Modal } from "comp-lib";
import { ContentNav } from "constants/notice";
import dayjs from "dayjs";
import { useAccessRole } from "hooks/useAccessRole";
import useModal from "hooks/useModal";
import Content from "pages/notice/Content";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import styles from "styles/pages/notice/write.module.scss";
import { TGetAccessRole } from "types/login";
import { NoticeData } from "types/notice";
import { MiddlewareError } from "utils/errorPackage";

export default function Write() {
  const navigate = useNavigate();
  const accessInfo = useAccessRole().data as TGetAccessRole;

  const [isShowing, handleToggleModal] = useModal();
  const [isError, setIsError] = useState(false);
  const [validateCheckMsg, setValidateCheckMsg] = useState("");

  const methods = useForm<NoticeData>({
    mode: "onChange",
    defaultValues: {
      platform: "all",
      noticeType: "system",
      priority: 1,
      moveTo: {
        moveType: "TAB",
        moveDst: "0",
      },
      invisiblePeriod: "vP_1",
    },
  });

  const { control } = methods;
  const noticeType = useWatch({ control, name: "noticeType" });

  const { mutate: writeMutate, error, isLoading } = useMutation(writeNoticeContent);

  const { mutateAsync: uploadImgMutation, error: uploadImgError } = useMutation(uploadNoticeFileApi);

  const onSubmitHandler = async (data: NoticeData) => {
    if (
      data.notBefore.date < dayjs().format("YYYY-MM-DD") ||
      data.notAfter.date < dayjs().format("YYYY-MM-DD") ||
      data.notBefore.date > data.notAfter.date ||
      Number(data.notBefore.h) > 23 ||
      Number(data.notAfter.h) > 23 ||
      Number(data.notBefore.m) > 59 ||
      Number(data.notAfter.m) > 59
    ) {
      alert("노출 기간을 다시 설정해주세요.");
      return;
    }

    [data.notBefore, data.notAfter].forEach((time) => {
      time.h = (time.h || "0").length === 1 ? `0${time.h || "0"}` : time.h;
      time.m = (time.m || "0").length === 1 ? `0${time.m || "0"}` : time.m;
    });

    switch (data.noticeType) {
      case "system":
        delete data.img;
        delete data.moveTo;
        delete data.invisiblePeriod;
        delete data.priority;

        break;

      case "service":
        delete data.moveTo;
        delete data.body;
        delete data.priority;

        data.invisiblePeriod = Number(data.invisiblePeriod!.toString().replace("vP_", ""));

        break;

      case "event":
        delete data.body;

        data.invisiblePeriod = Number(data.invisiblePeriod!.toString().replace("vP_", ""));

        break;
    }

    if (data.img) {
      if (!accessInfo.did || !accessInfo.token) return;

      const typeFileImg = data.img as File;
      const body = { did: accessInfo.did, token: accessInfo.token, file: typeFileImg, noticeType };

      await uploadImgMutation(body, {
        onSuccess: (el) => {
          writeMutate(
            { ...data, img: { filename: typeFileImg.name, url: el.fullUrlPath } },
            {
              onSuccess: handleToggleModal,
              onError: () => setIsError(true),
            },
          );
        },
      });
    } else {
      writeMutate(data, {
        onSuccess: handleToggleModal,
        onError: () => setIsError(true),
      });
    }
  };

  const handleConfirmModal = () => {
    handleToggleModal();
    navigate("/message/homePopup");
  };

  return (
    <article>
      <Title text="신규 등록" location={ContentNav} />

      <div className={styles.wrapper}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmitHandler)} method="post">
            <Content />
          </form>
        </FormProvider>
      </div>

      {isLoading && <LoadingModal />}

      {isError && (
        <ErrorModal
          error={error || uploadImgError}
          message={getErrorMeg((error as MiddlewareError).message)}
          onConfirmHandler={() => setIsError(false)}
        />
      )}

      {validateCheckMsg && (
        <Modal description={validateCheckMsg} btnType="confirm" handleClose={() => setValidateCheckMsg("")} />
      )}

      {isShowing && (
        <Modal
          title="신규 등록이 완료되었습니다"
          description="앱 공지 목록에서 확인 가능합니다"
          btnType="confirm"
          handleClose={handleConfirmModal}
        />
      )}
    </article>
  );
}
