import styles from "styles/pages/mydInquiry/detail/mydInquiryInfo.module.scss";

import { Panel } from "comp-lib";

import useModal from "hooks/useModal";
import InfoItem from "common/other/InfoItem";
import ImgModal from "./ImgModal";

import { TMydInquiryDetail } from "types/mydInquiry";
import { STATUS_MAP, TYPE } from "constants/mydInquiry";

type TProps = {
  inquiryData: TMydInquiryDetail;
};

export default function MydInquiryInfo({ inquiryData }: TProps) {
  const [isImgModalOpen, onToggleImgModal] = useModal();

  return (
    <>
      <Panel className={styles.panel}>
        <InfoItem title="문의 유형" value={`${TYPE[inquiryData.type]}`} />
        <InfoItem title="진행 상태" value={`${STATUS_MAP[inquiryData.status]}`} />
        <InfoItem isLast={!inquiryData.attachedFileInfos} title="사용자 DID" value={inquiryData.userId} />

        {inquiryData.attachedFileInfos && (
          <InfoItem
            isLast
            title="등록된 이미지"
            value={inquiryData.attachedFileInfos.map((img, idx) => (
              <button key={idx} type="button" className={styles.fileNameBtn} onClick={onToggleImgModal}>
                {img.name}
                {inquiryData.attachedFileInfos.length - 1 !== idx && <span>,</span>}
              </button>
            ))}
          />
        )}
      </Panel>

      {isImgModalOpen && (
        <ImgModal serverImg={inquiryData.attachedFileInfos.map((img) => img.id)} onToggleModal={onToggleImgModal} />
      )}
    </>
  );
}
