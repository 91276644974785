import { ReactNode } from "react";
import styles from "styles/pages/contentsManage/challenge/list/table/tableList.module.scss";
import { ErrorContentPage } from "../../@shared/ErrorContentPage";

interface Props {
  columns: (string | string[])[];
  dataList: (string | ReactNode)[][];
  isError: boolean;
}
export default function TableList({ columns, dataList, isError }: Props) {
  if (isError)
    return (
      <ErrorContentPage
        message={
          "목록을 불러오던 중 오류가 발생했습니다.\n다시 시도하시고 동일한 문제가 반복될 경우 CS센터에 문의해 주세요."
        }
      />
    );
  return (
    <table className={styles.wrapper}>
      <thead>
        <tr>
          {columns.map((column) => {
            if (column === "") return <td key={column} className={styles.blank} />;

            if (typeof column === "string") return <td key={column}>{column}</td>;
            return (
              <td key={column[0]} style={{ width: `${column[1]}` }}>
                {column[0]}
              </td>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {dataList.map((datas) => {
          const key = `table-${datas[0]}`;

          const tdList = datas.map((data, index) => {
            if (index === 0) return null;
            const key2 = `${key}-${index}`;
            return <td key={key2}>{data}</td>;
          });
          return <tr key={key}>{tdList}</tr>;
        })}
      </tbody>
    </table>
  );
}
