import styles from "./checkInput.module.scss";
import cx from "classnames";
import { CheckCircleComponent } from "common/checkbox/CheckCircleComponent";

interface Props {
  name: string;
  text: React.ReactNode | string;
  value: boolean;
  handleCheck: () => void;
  disabled?: boolean;
  mode?: "square" | "circle";
}

export default function CheckInput({ name, text, value, handleCheck, disabled = false, mode = "square" }: Props) {
  return (
    <div className={cx(styles.wrapper, styles[mode], { [styles.active]: value })}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={cx(styles.checkBox, styles[mode], { [styles.active]: value })} onClick={handleCheck}>
        <input type="checkbox" id={name} name={name} checked={value} onChange={handleCheck} disabled={disabled} />
        {value ? (
          <CheckCircleComponent />
        ) : (
          <CheckCircleComponent bgColor="white" borderColor="#d9d9d9" strokeColor="#d9d9d9" />
        )}
      </div>
      <label htmlFor={name}>{text}</label>
    </div>
  );
}
