import cx from "classnames";
import styles from "styles/pages/advertisement/statusChip.module.scss";
import { statusFilter } from "./statusFilter";

type TStatusChipProps = {
  status: number;
  approved: boolean;
};

const statusMap: { [key: number]: string[] } = {
  0: ["대기", "progress"],
  1: ["승인", "accept"],
  2: ["반려", "reject"],
};

export default function StatusChip({ status, approved }: TStatusChipProps) {
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.chip, styles[`status${statusFilter(status, approved)}`])}>
        {statusMap[statusFilter(status, approved)][0]}
      </div>
    </div>
  );
}
