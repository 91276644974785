export const BANNER_STATUS_MAP: Record<number, [string, string]> = {
  0: ["임시저장", "DRAFT"],
  1: ["최종저장", "FINAL_SAVED"],
  2: ["게시중", "PUBLISHED"],
};

export const PURPOSE_MAP: Record<number, string> = {
  0: "기타",
  600: "패널 모집",
  601: "친구 추천",
  602: "출석 체크",
};
