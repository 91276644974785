import { useSearchParams } from "react-router-dom";

/**
 * useSearchParams API 이용
 */
export default function useCustomParams<T extends Record<string, string>>(
  defaultParams: T
): [T, (newParams: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const state: T = { ...defaultParams };

  for (const key in defaultParams) {
    if (key in defaultParams) {
      state[key] = (searchParams.get(key) || defaultParams[key]) as T[typeof key];
    }
  }

  const setState = (newParams: T) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    for (const key in newParams) {
      if (key in newParams) {
        newSearchParams.set(key, newParams[key]);
      }
    }

    setSearchParams(newSearchParams);
  };

  return [state, setState];
}
