import { useState } from "react";
import { Link } from "react-router-dom";

import StatusChip from "./StatusChip";

import { TContent } from "types/appPush";
import { getDateFormat } from "utils/appPush";

const PURPOSE_MAP: { [key: string]: string } = {
  MARKETING_ADVERTISE: "마케팅·광고",
  NOTICE: "일반 공지",
};

type TProps = {
  data: TContent[];
  length: number;
  pageNo: string;
};

export default function useTableData({ data, length, pageNo }: TProps) {
  const [isQuestionModal, setIsQuestionModal] = useState("");

  const columns = [
    { text: "NO", width: "70px" },
    { text: "상태", width: "80px" },
    { text: "제목", width: "323px" },
    { text: "발송 목적", width: "150px" },
    { text: "발송 시작일시", width: "186px" },
    { text: "발송 완료일시", width: "186px" },
    { text: "발송률", width: "120px" },
  ];

  const dataWithNo = data?.map((arg, idx) => ({
    ...arg,
    no: length - idx - 10 * (Number(pageNo) - 1),
  }));

  const rows = dataWithNo?.map((item) => {
    const completedRate = Math.floor((item.successCount / item.sendCount) * 100) || 0;

    return [
      item.no,
      <StatusChip status={item.status} key={item.id} />,
      <Link key={item.id} to={`/message/appPush/detail/${item.id}`}>
        {item.title}
      </Link>,
      PURPOSE_MAP[item.purpose],
      item.startTime ? getDateFormat(item.startTime) : getDateFormat(item.reservedTime),
      item.endTime ? getDateFormat(item.endTime) : "-",
      `${completedRate}%`,
    ];
  });

  return { columns, rows, isQuestionModal, setIsQuestionModal };
}
