import { PollInfoItem, SpamInfo } from "protobuf/OpenApiServerV3";

export default function StatusFilter(data: PollInfoItem | SpamInfo) {
  const { status } = data as PollInfoItem;
  const { spamStatus } = data as SpamInfo;

  // 콘텐츠 관리
  if (status === 2) return "notStarted";
  if (status === 3 || status === 4) return "inProgress";
  if (status === 7) return "ended";

  // 신고 관리
  if (spamStatus === 2) return "pending";
  if (spamStatus === 3) return "approved";
  if (spamStatus === 4) return "nonApproved";

  return "";
}
