import styles from "styles/pages/appPush/write/preview/previewModal.module.scss";

import Portal from "../../@shared/Portal";
import { XIcon } from "assets/svgs/vote";
import { useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";

type TProps = {
  children: React.ReactNode;
  onTogglePreviewModal: () => void;
};

export default function PreviewModal({ children, onTogglePreviewModal }: TProps) {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => onTogglePreviewModal());

  return (
    <Portal>
      <div className={styles.overlay}>
        <div ref={ref} className={styles.wrapper}>
          <header>
            <h2>발송 메시지 미리보기</h2>
            <XIcon onClick={onTogglePreviewModal} />
          </header>
          {children}
        </div>
      </div>
    </Portal>
  );
}
