import { TQuickMenuItem } from "types/quickMenu";
import { Axios } from "./base";
import { AxiosError } from "axios";
import ErrorCode from "constants/errorcode";
import { getErrorMeg } from "./errorHandling";

// [GET] 퀵메뉴 리스트 조회
export const getQuickMenuList = async (): Promise<TQuickMenuItem[]> => {
  const { data } = await Axios.get(`/api/admin/v3/quick-menu/items`);
  return data.data?.quickMenuInfoList;
};

type TUpdateQuickList = {
  menuNo: number;
  applyDevelop: boolean;
  applyProd: boolean;
  seq: number;
}[];

// [PUT] 퀵메뉴 리스트 정보 수정
export const updateQuickMenuList = async (list: TUpdateQuickList) => {
  const { data } = await Axios.put(`/api/admin/v3/quick-menu/items`, { quickMenuList: list });
  return data;
};

type TPostQuickMenu = {
  menuName: string;
  menuImg: string;
  seq: number;
};

// [POST] 퀵메뉴 신규 등록
export const postQuickMenu = async (item: TPostQuickMenu) => {
  try {
    const { data } = await Axios.post(`/api/admin/v3/quick-menu/items`, item);
    return data;
  } catch (error) {
    if (error instanceof Error) {
      if ((error as AxiosError).message === ErrorCode.INVALID_ARGUMENT) {
        throw Error(getErrorMeg(ErrorCode.ERR_ALREADY_QUICK_MENU));
      }
    }
  }
};

type TUpdateQuickMenu = {
  id: number;
  menuName: string;
  menuImg: string;
  seq: number;
};

// [POST] 퀵메뉴 정보 수정
export const updateQuickMenu = async (item: TUpdateQuickMenu) => {
  try {
    const { id, menuName, menuImg, seq } = item;
    const { data } = await Axios.put(`/api/admin/v3/quick-menu/items/${id}`, { menuName, menuImg, seq });
    return data;
  } catch (error) {
    if (error instanceof Error) {
      if ((error as AxiosError).message === ErrorCode.INVALID_ARGUMENT) {
        throw Error(getErrorMeg(ErrorCode.ERR_ALREADY_QUICK_MENU));
      }
    }
  }
};

// [DELETE] 퀵메뉴 정보 삭제
export const deleteQuickMenu = async (id: number) => {
  const { data } = await Axios.delete(`/api/admin/v3/quick-menu/items/${id}`);
  return data;
};
