import { Title } from "common/other";
import Table from "common/table/Table";
import styles from "styles/pages/advertisement/advertisement.module.scss";
import { useQuery } from "react-query";
import { getAdminAdProposalList } from "api/admin/advertisement";
import useDataToTable from "./useDataToTable";
import { Pagination } from "common/navigation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DefaultBtn } from "common/button";
import DateRange from "common/input/DatePicker";
import useDateRange from "hooks/useDateRange";
import { RerollIcon } from "assets/svgs/icon";
import { useState } from "react";
import TableNav from "common/table/TableNav";
import { useFilterAdTableData, useFilterAdTableNav } from "./useFilterAdTable";
import { ErrorModal, LoadingModal } from "comp-lib";

export default function Advertisement() {
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useQuery("getAdminAdProposalList", getAdminAdProposalList);
  const [searchParams, setSearchParams] = useSearchParams();
  const [keywordValue, setKeywordValue] = useState("");

  const { index } = data || {};

  const { filteredData, size, totalPages } = useFilterAdTableData(index, navMap);
  const tableNavData = useFilterAdTableNav(navMap, size);
  const { dataList, columns } = useDataToTable(filteredData);

  const { endDate, onChangeDate, startDate, resetDate } = useDateRange();

  const handleKeywordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setKeywordValue(e.currentTarget.value);
  };

  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchParams((params) => {
      if (keywordValue) {
        params.set("keyword", keywordValue);
        params.set("pageNum", "1");
      } else {
        params.delete("keyword");
      }
      return params;
    });
  };

  const handleResetDate = () => {
    resetDate();
    setKeywordValue("");
    setSearchParams((params) => {
      params.delete("keyword");
      return params;
    });
  };

  if (isLoading) return <LoadingModal />;

  return (
    <article>
      <Title text="광고 요청 목록" />
      <div className={styles.innerPannel}>
        <div className={styles.tableHeader}>
          <TableNav data={tableNavData} />
          <form className={styles.tableHeaderSearch} onSubmit={onSubmitForm} id="search">
            <DateRange
              startDate={startDate}
              endDate={endDate}
              onChange={onChangeDate}
              setParams
              placeholder={"노출 시작일 기준 날짜 검색"}
            />
            <input
              placeholder="검색어를 입력하세요"
              className={styles.searchKeyword}
              value={keywordValue}
              onChange={handleKeywordChange}
            />
            <DefaultBtn width="100px" height="36px" type="submit">
              검색
            </DefaultBtn>
          </form>
        </div>
        <div className={styles.resetContainer}>
          {(searchParams.get("keyword") || searchParams.get("from") || searchParams.get("to")) && (
            <button type="button" onClick={handleResetDate}>
              <RerollIcon width="24px" height="24px" />
              초기화하기
            </button>
          )}
        </div>
        <Table columns={columns} dataList={dataList || []} />
        <div className={styles.paginationWrapper}>
          <Pagination data={{ totalPages, pageSize: 1 }} />
        </div>
      </div>

      {isError && (
        <ErrorModal
          error={error}
          message={`광고 요청 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`}
          onConfirmHandler={() => navigate("/")}
        />
      )}
    </article>
  );
}

const navMap = [
  {
    label: "전체",
    status: "all",
  },
  {
    label: "대기",
    status: "progress",
  },
  {
    label: "승인",
    status: "accept",
  },
  {
    label: "반려",
    status: "reject",
  },
];
