/* eslint-disable react/display-name */
import { ComponentPropsWithRef, forwardRef } from "react";
import cx from "classnames";
import styles from "./radio.module.scss";

type TProps = Omit<ComponentPropsWithRef<"input">, "type"> & {};

const Radio = forwardRef<HTMLInputElement, TProps>(({ className, ...props }, ref) => {
  return (
    <div className={cx(styles.wrapper, className)}>
      <input type="radio" {...props} ref={ref} />

      <div className={styles.checkmark}>
        <div className={styles.inner} />
      </div>
    </div>
  );
});

export default Radio;
