import { useFormContext } from "react-hook-form";

import styles from "styles/pages/appPush/write/preview/previewIOS.module.scss";

import { MyDLogoIcon } from "assets/svgs";

import { TPushFormValue } from "types/appPush";

export default function PreviewIOS() {
  const { watch } = useFormContext<TPushFormValue>();

  const { title, body, imagePath, messagePurpose } = watch();

  const isPurposeMarketing = messagePurpose === "MARKETING_ADVERTISE";

  return (
    <div className={styles.wrapper}>
      <h3>아이폰 미리보기</h3>

      <section className={styles.section}>
        <div className={styles.box}>
          <MyDLogoIcon className={styles.logo} />

          <div className={styles.content}>
            <div className={styles.header}>
              <h2 className={styles.title}>{title}</h2>
              <span>오후 6:00</span>
            </div>

            <div className={styles.body_top}>
              {isPurposeMarketing ? (
                <p>
                  (광고) {body}
                  <br />* 수신거부 : 홈 &gt; MY &gt; 푸시 알람 설정
                </p>
              ) : (
                <p>{body}</p>
              )}

              {imagePath && (
                <div className={styles.imgWrapper}>
                  <img className={styles.img} src={imagePath} alt="preview" />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {imagePath && (
        <section className={styles.section}>
          <div className={styles.box}>
            <MyDLogoIcon className={styles.logo} />

            <div className={styles.content}>
              <div className={styles.header}>
                <h2>마이디</h2>
                <span>오후 6:00</span>
              </div>

              <div className={styles.body_bottom}>
                <h2 className={styles.title}>{title}</h2>
                {isPurposeMarketing ? (
                  <p>
                    (광고) {body}
                    <br />* 수신거부 : 홈 &gt; MY &gt; 푸시 알람 설정
                  </p>
                ) : (
                  <p>{body}</p>
                )}
              </div>
            </div>
          </div>

          <img className={styles.bigImg} src={imagePath} alt="preview" />
        </section>
      )}
    </div>
  );
}
