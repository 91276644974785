import { Control, FieldValues, useController } from "react-hook-form";
import { TQuickVersion } from "types/quickMenu";
import styles from "./toggleBtn.module.scss";

type TProps = {
  name: string;
  defaultValue: TQuickVersion;
  control: Control<FieldValues>;
  mode: "applyDevelop" | "applyProd";
};

export default function ToggleBtn({ name, defaultValue, control, mode }: TProps) {
  const {
    field: { value, onChange },
  } = useController({ name, control, defaultValue });

  const handleClick = () => {
    onChange({ ...value, [mode]: !value[mode] }, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <button type="button" className={styles.container} onClick={handleClick}>
      <input type="checkbox" className={styles.checkbox} checked={value[mode]} readOnly />
      <div className={styles.switch} />
    </button>
  );
}
