import { FormEventHandler, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { RerollIcon } from "assets/svgs/icon";

import useDateRange from "hooks/useDateRange";
import styles from "./dateSearchBox.module.scss";
import DateRange from "../input/DatePicker";
import Button from "../Button";

/**
 * 날짜 필터 컴포넌트
 */
export default function DateSearchBox() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { endDate, onChangeDate, startDate, resetDate } = useDateRange();
  const [keywordValue, setKeywordValue] = useState("");

  const handleSearchSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    setSearchParams((params) => {
      keywordValue ? params.set("keyword", keywordValue) : params.delete("keyword");
      params.set("pageNum", "1");
      return params;
    });
  };

  const handleResetDate = () => {
    searchParams.delete("from");
    searchParams.delete("to");
    searchParams.delete("keyword");
    searchParams.set("pageNum", "1");
    resetDate();
    setKeywordValue("");
    setSearchParams(searchParams);
  };

  const handleKeywordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setKeywordValue(e.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.dateBox} onSubmit={handleSearchSubmit}>
        <DateRange
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDate}
          setParams
          placeholder={"진행 시작일 기준 날짜 검색"}
        />

        <input
          placeholder="검색어를 입력하세요"
          className={styles.searchKeyword}
          value={keywordValue}
          onChange={handleKeywordChange}
        />

        <div className={styles.searchWrapper}>
          <Button type="submit" size="short" onClick={handleSearchSubmit}>
            검색
          </Button>
          {(searchParams.get("keyword") || searchParams.get("from") || searchParams.get("to")) && (
            <button type="button" className={styles.searchClearBtn} onClick={handleResetDate}>
              <RerollIcon width="24px" height="24px" />
              초기화하기
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
