import dayjs from "dayjs";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import InfoItem from "./InfoItem";
import queryKey from "constants/queryKey";
import useModal from "hooks/useModal";
import { getEmailResend, getStaffDetail } from "api/admin/company";
import { Content, Title, Panel, Footer, Button, Modal, LoadingModal } from "comp-lib";
import { TManagerDetail } from "types/company";

import styles from "styles/pages/company/detail/staffInfo.module.scss";

export default function StaffInfo() {
  const navigate = useNavigate();
  const { id = "" } = useParams();

  const [isNoticeModal, setIsNoticeModal] = useModal();

  // 대표담당자 상세 정보 조회 api 연동
  const { data, isLoading } = useQuery([queryKey.GET_STAFF_DETAIL, id], () => getStaffDetail(id));

  const { name, role, department, jobPosition, email, phone, tel, createdAt, updatedAt, company } =
    (data as TManagerDetail) || {};

  const { mutate, isLoading: isSendLoading } = useMutation(getEmailResend, { onSuccess: setIsNoticeModal });

  if (isLoading || isSendLoading) return <LoadingModal />;

  return (
    <Content isWithFooter>
      <Title text="대표 담당자 상세 정보" />

      {data && (
        <Panel className={styles.panel}>
          <div className={styles.infoWrapper}>
            <InfoItem label="소속 기업명">{company.name}</InfoItem>

            <InfoItem label="이름">{name}</InfoItem>

            <InfoItem label="권한">{role === "MANAGER" ? "대표 담당자" : ""}</InfoItem>

            <InfoItem label="부서명">{department}</InfoItem>

            <InfoItem label="직급">{jobPosition}</InfoItem>

            <InfoItem label="이메일 주소">
              {email}
              <Button mode="outlined" onClick={() => mutate(email)}>
                가입 링크 재발송
              </Button>
            </InfoItem>

            <InfoItem label="개별 연락처">{phone}</InfoItem>

            <InfoItem label="직통 번호">{tel}</InfoItem>
          </div>

          <div className={styles.dateInfoWrapper}>
            <div>최초등록 {dayjs(createdAt).format("YYYY.MM.DD")}</div>
            <div>업데이트 {dayjs(updatedAt).format("YYYY.MM.DD")}</div>
          </div>
        </Panel>
      )}

      <Footer>
        <Button size="long" mode="lineGray" onClick={() => navigate(-1)}>
          뒤로가기
        </Button>
      </Footer>

      {isNoticeModal && (
        <Modal
          description="해당 이메일로 가입 링크를 재발송하였습니다."
          btnType="confirm"
          handleClose={setIsNoticeModal}
        />
      )}
    </Content>
  );
}
